import React from 'react';

import { Stack } from '@chakra-ui/react';

import { maskMoney } from 'services/masks.service';
import { ListSettlementResponseDTO } from '../../../clients';
import { format, parse } from 'date-fns';
import Text from 'components/Text';

const PaymentInfo: (data: ListSettlementResponseDTO) => JSX.Element = (data: ListSettlementResponseDTO) => {
	const date = data.settlement_day ? format(parse(data.settlement_day, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : 'N/A';
	return (
		<Stack
			px={8}
			color='primary'
			direction={['column', 'row']}
			bg='white'
			w='100%'
			py={5}
			justifyContent='space-between'
			rounded='md'
			boxShadow='md'
		>
			<Stack spacing={[0, 2]}>
				<Text id='identifier-text' fontWeight='bold'>Identificador</Text>
				<Text id='data-identifier-text'>{data.id}</Text>
			</Stack>

			<Stack spacing={[0, 2]}>
				<Text id='payment-day-text' fontWeight='bold'>Data do Pagamento</Text>
				<Text id='data-payment-day-text'>{date}</Text>
			</Stack>

			<Stack spacing={[0, 2]}>
				<Text id='payment-amount-text' fontWeight='bold'>Valor do Pagamento</Text>
				<Text id='data-payment-amount-text'>R${maskMoney(data.total / 100)}</Text>
			</Stack>
		</Stack>
	);
};

export default PaymentInfo;
