import React from 'react';

import CreatePayerWrapper from './CreatePayerWrapper';
import { requiredFields } from './CreatePayerWrapper/types/requiredFields.type';

type PayerWrapperType = {
	selectedPayer: any;
	setSelectedPayer(param: any): void;
	setFieldValue: any;
	errors: any;
	requiredFields: requiredFields;
};

const PayerWrapper: React.FC<PayerWrapperType> = ({ selectedPayer, setSelectedPayer, setFieldValue, errors, requiredFields }) => {
	React.useEffect(() => {
		if (selectedPayer) {
			setFieldValue('payer.address.street', selectedPayer?.address?.street);
			setFieldValue('payer.address.district', selectedPayer?.address?.district);
			setFieldValue('payer.address.city', selectedPayer?.address?.city);
			setFieldValue('payer.address.zipcode', selectedPayer?.address?.zipcode);
			setFieldValue('payer.address.number', selectedPayer?.address?.number);
			setFieldValue('payer.address.state', selectedPayer?.address?.state);
			setFieldValue('payer.address.complement', selectedPayer?.address?.complement ? selectedPayer?.address?.complement : 'N/A');
			setFieldValue('payer.name', selectedPayer?.name);
			setFieldValue('payer.document', selectedPayer?.document);
			setFieldValue('payer.email', selectedPayer?.email);
			setFieldValue('payer.phone', selectedPayer?.phone);
			return;
		}

		setFieldValue('payer.address.street', '');
		setFieldValue('payer.address.district', '');
		setFieldValue('payer.address.city', '');
		setFieldValue('payer.address.zipcode', '');
		setFieldValue('payer.address.number', '');
		setFieldValue('payer.address.state', '');
		setFieldValue('payer.address.complement', '');
		setFieldValue('payer.name', '');
		setFieldValue('payer.document', '');
		setFieldValue('payer.email', '');
		setFieldValue('payer.phone', '');
	}, 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[selectedPayer]
);

	return (
		<CreatePayerWrapper
			setSelectedPayer={setSelectedPayer}
			selectedPayer={selectedPayer}
			setFieldValue={setFieldValue}
			errors={errors}
			requiredFields={requiredFields}
		/>
	);
};

export default PayerWrapper;
