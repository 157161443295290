/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAddressDto,
    GetAddressDtoFromJSON,
    GetAddressDtoFromJSONTyped,
    GetAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSalePayerDto
 */
export interface GetSalePayerDto {
    /**
     * 
     * @type {string}
     * @memberof GetSalePayerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalePayerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalePayerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalePayerDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalePayerDto
     */
    created_at: string;
    /**
     * 
     * @type {GetAddressDto}
     * @memberof GetSalePayerDto
     */
    address: GetAddressDto;
    /**
     * 
     * @type {string}
     * @memberof GetSalePayerDto
     */
    phone: string;
}

export function GetSalePayerDtoFromJSON(json: any): GetSalePayerDto {
    return GetSalePayerDtoFromJSONTyped(json, false);
}

export function GetSalePayerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalePayerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'document': json['document'],
        'email': json['email'],
        'created_at': json['created_at'],
        'address': GetAddressDtoFromJSON(json['address']),
        'phone': json['phone'],
    };
}

export function GetSalePayerDtoToJSON(value?: GetSalePayerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'document': value.document,
        'email': value.email,
        'created_at': value.created_at,
        'address': GetAddressDtoToJSON(value.address),
        'phone': value.phone,
    };
}


