/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FailedTransfersDto,
    FailedTransfersDtoFromJSON,
    FailedTransfersDtoFromJSONTyped,
    FailedTransfersDtoToJSON,
    ShippingReturnDto,
    ShippingReturnDtoFromJSON,
    ShippingReturnDtoFromJSONTyped,
    ShippingReturnDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UploadReturnFileResponseDto
 */
export interface UploadReturnFileResponseDto {
    /**
     * 
     * @type {ShippingReturnDto}
     * @memberof UploadReturnFileResponseDto
     */
    shipping_return: ShippingReturnDto;
    /**
     * 
     * @type {Array<FailedTransfersDto>}
     * @memberof UploadReturnFileResponseDto
     */
    failed_transfers: Array<FailedTransfersDto>;
}

export function UploadReturnFileResponseDtoFromJSON(json: any): UploadReturnFileResponseDto {
    return UploadReturnFileResponseDtoFromJSONTyped(json, false);
}

export function UploadReturnFileResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadReturnFileResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shipping_return': ShippingReturnDtoFromJSON(json['shipping_return']),
        'failed_transfers': ((json['failed_transfers'] as Array<any>).map(FailedTransfersDtoFromJSON)),
    };
}

export function UploadReturnFileResponseDtoToJSON(value?: UploadReturnFileResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipping_return': ShippingReturnDtoToJSON(value.shipping_return),
        'failed_transfers': ((value.failed_transfers as Array<any>).map(FailedTransfersDtoToJSON)),
    };
}


