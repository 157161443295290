/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
    ShopperDTO,
    ShopperDTOFromJSON,
    ShopperDTOFromJSONTyped,
    ShopperDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBilletHybridDto
 */
export interface CreateBilletHybridDto {
    /**
     * Valor
     * @type {number}
     * @memberof CreateBilletHybridDto
     */
    value: number;
    /**
     * CPF ou CNPJ
     * @type {string}
     * @memberof CreateBilletHybridDto
     */
    social_security_number: string;
    /**
     * Descrição do boleto
     * @type {string}
     * @memberof CreateBilletHybridDto
     */
    shopper_statement: string;
    /**
     * Descrição
     * @type {string}
     * @memberof CreateBilletHybridDto
     */
    description: string;
    /**
     * Data de vencimento
     * @type {Date}
     * @memberof CreateBilletHybridDto
     */
    delivery_date: Date;
    /**
     * Dados do cliente
     * @type {ShopperDTO}
     * @memberof CreateBilletHybridDto
     */
    shopper: ShopperDTO;
    /**
     * Há divisão de pagamento
     * @type {boolean}
     * @memberof CreateBilletHybridDto
     */
    has_split_rules?: boolean;
    /**
     * Divisão de pagamento
     * @type {Array<CreateSplitRulesDto>}
     * @memberof CreateBilletHybridDto
     */
    split_rules?: Array<CreateSplitRulesDto>;
    /**
     * Há confirmação de pagamento
     * @type {boolean}
     * @memberof CreateBilletHybridDto
     */
    confirmation_required?: boolean;
    /**
     * Número do protocolo
     * @type {string}
     * @memberof CreateBilletHybridDto
     */
    protocol?: string;
    /**
     * Instruções para o boleto(caso vazio será usado o campo description)
     * @type {string}
     * @memberof CreateBilletHybridDto
     */
    instructions?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateBilletHybridDto
     */
    metadata?: object;
}

export function CreateBilletHybridDtoFromJSON(json: any): CreateBilletHybridDto {
    return CreateBilletHybridDtoFromJSONTyped(json, false);
}

export function CreateBilletHybridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBilletHybridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'social_security_number': json['social_security_number'],
        'shopper_statement': json['shopper_statement'],
        'description': json['description'],
        'delivery_date': (new Date(json['delivery_date'])),
        'shopper': ShopperDTOFromJSON(json['shopper']),
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function CreateBilletHybridDtoToJSON(value?: CreateBilletHybridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'social_security_number': value.social_security_number,
        'shopper_statement': value.shopper_statement,
        'description': value.description,
        'delivery_date': (value.delivery_date.toISOString()),
        'shopper': ShopperDTOToJSON(value.shopper),
        'has_split_rules': value.has_split_rules,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
        'confirmation_required': value.confirmation_required,
        'protocol': value.protocol,
        'instructions': value.instructions,
        'metadata': value.metadata,
    };
}


