/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SellerCheckout
 */
export interface SellerCheckout {
    /**
     * 
     * @type {string}
     * @memberof SellerCheckout
     */
    key: SellerCheckoutKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SellerCheckout
     */
    custom_block: string;
}

/**
* @export
* @enum {string}
*/
export enum SellerCheckoutKeyEnum {
    Pix = 'pix',
    Billet = 'billet',
    CreditCard = 'credit_card'
}

export function SellerCheckoutFromJSON(json: any): SellerCheckout {
    return SellerCheckoutFromJSONTyped(json, false);
}

export function SellerCheckoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerCheckout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'custom_block': json['custom_block'],
    };
}

export function SellerCheckoutToJSON(value?: SellerCheckout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'custom_block': value.custom_block,
    };
}


