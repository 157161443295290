import { BreadcrumbItem, BreadcrumbLink, Center, chakra, Flex, Stack, Tooltip, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { BiExport } from 'react-icons/bi';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useToasts } from 'react-toast-notifications';

import { format } from 'date-fns';
import { Formik } from 'formik';

import { CreateExportMonthlyAlertsListDto, ListTransactionsAlertsRequest, MonthlyTransactionAlertsApi } from '../../clients';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import ExportFileSuccessModal from '../../containers/ExportFileSuccessModal';
import Loader from '../../containers/Loader';
import Paper from '../../containers/Paper';
import RangeDatePicker from '../../containers/RangeDatePicker';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import IPeriod from '../../interfaces/period.interface';
import { getApiAuthConfig } from '../../services/api.service';
import AlertsTable from './components/AlertsTable';

const MonthlyTransactionsAlerts: React.FC = () => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const { currentSeller } = useCurrentSeller();
	const [isLoading, setIsLoading] = React.useState(false);
	const [exportExcelIsLoading, setExportExcelIsLoading] = React.useState(false);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState(false);
	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [monthlyAlerts, setMonthlyAlerts] = React.useState<Array<string>>([]);

	const { addToast } = useToasts();

	const initialValues = {
		startDate: format(period[0].startDate, 'yyyy-MM-dd'),
		endDate: format(period[0].endDate, 'yyyy-MM-dd'),
	};

	const fetchMonthlyAlerts = React.useCallback(async () => {
		setIsLoading(true);

		const requestParams: ListTransactionsAlertsRequest = {
			startDate: format(period[0].startDate, 'yyyy-MM-dd'),
			endDate: format(period[0].endDate, 'yyyy-MM-dd'),
			currentPage: pagination.currentPage,
			limit: pagination.limit,
		};

		if (currentSeller) {
			requestParams.sellerId = currentSeller?.id;
		}

		const apiConfig = getApiAuthConfig();
		const monthlyTransactionsAlertsApi = new MonthlyTransactionAlertsApi(apiConfig);

		try {
			const response = await monthlyTransactionsAlertsApi.listTransactionsAlerts(requestParams);

			setMonthlyAlerts(response.results);
			setTotalPages(response.total_pages);

			setIsLoading(false);
		} catch (error) {
			addToast('Erro ao buscar os dados', { appearance: 'error', autoDismiss: true });
		}
	}, [pagination, currentSeller, addToast, period]);

	const handleExportMonthlyAlertsReport = async () => {
		setExportExcelIsLoading(true);

		try {
			const apiConfig = getApiAuthConfig();
			const monthlyAlertsApi = new MonthlyTransactionAlertsApi(apiConfig);

			const params: CreateExportMonthlyAlertsListDto = {
				start_date: format(period[0].startDate, 'yyyy-MM-dd'),
				end_date: format(period[0].endDate, 'yyyy-MM-dd'),
			};

			if (currentSeller) {
				params.seller_id = currentSeller.id;
			}

			await monthlyAlertsApi.createMonthlyAlertsListExport({ createExportMonthlyAlertsListDto: params });
			setOpenExportSuccessModal(true);
		} catch (error) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportExcelIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchMonthlyAlerts();
	}, [fetchMonthlyAlerts]);

	return (
		<>
			<Flex justifyContent='space-between' flexDirection={['column', 'row']}>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink>Alertas</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold' cursor='unset'>
							Alertas Mensais
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>

				<Button
					id='export-to-excel-button'
					bgColor='primary'
					isDisabled={exportExcelIsLoading}
					isLoading={exportExcelIsLoading}
					onClick={() => handleExportMonthlyAlertsReport()}
					leftIcon={<BiExport size={20} />}
					order={[1, 0]}
					disabled={!monthlyAlerts.length}
				>
					Exportar para Excel
				</Button>
			</Flex>

			<Paper id='monthly-transactions-alerts-export-files-paper-text' p={6}>
				<Flex flexDirection='column' justifyContent='center' h='100%'>
					<Loader isOpen={isLoading} />

					<ExportFileSuccessModal
						showCloseButton
						isOpen={openExportSuccessModal}
						onOkClick={() => {
							setOpenExportSuccessModal(false);
						}}
						onClose={() => {
							setOpenExportSuccessModal(false);
						}}
					/>

					<Formik initialValues={initialValues} onSubmit={fetchMonthlyAlerts}>
						{({ handleSubmit, setFieldValue }) => {
							return (
								<form onSubmit={handleSubmit}>
									<Flex mb={3} flexDirection='row'>
										<Stack
											direction={isMobile ? 'column' : 'row'}
											justifyContent={isMobile ? 'center' : 'space-between'}
											w='200%'
											spacing={4}
										>
											<Stack direction={['column', 'column', 'row']}>
												<Center position='relative'>
													<RangeDatePicker
														period={period}
														setPeriod={setPeriod}
														setFieldValue={setFieldValue}
														handleSubmit={handleSubmit}
														backgroundColor='primary'
														py='5'
													/>
													<Tooltip label='Data do Alerta' placement='top' hasArrow>
														<chakra.span>
															<IoInformationCircleOutline size='1.3rem' color='primary' />
														</chakra.span>
													</Tooltip>
												</Center>
											</Stack>
										</Stack>
									</Flex>
								</form>
							);
						}}
					</Formik>
				</Flex>
			</Paper>
			<Paper id='alerts-table-paper-text'>
				<AlertsTable data={monthlyAlerts} pagination={pagination} setPagination={setPagination} totalPages={totalPages} />
			</Paper>
		</>
	);
};

export default MonthlyTransactionsAlerts;
