import { HStack } from '@chakra-ui/react';
import Text from 'components/Text';
import { CellContent, HeaderTitle, SimpleTable } from 'containers/SimpleTable';
import React from 'react';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';
import { shippingReturnStatus } from 'services/enums.service';
import { maskFullDate } from 'services/masks.service';

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({ data, setPagination, pagination, totalPages, ...rest }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='id-title' column={column}>
						ID
					</HeaderTitle>
				),
				accessor: 'id',
				Cell: ({ cell: { value, row, column } }) => <CellContent id={`${row.index}-${column.id}-cell`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='filename-title' column={column}>
						Nome do Arquivo
					</HeaderTitle>
				),
				accessor: 'filename',
				Cell: ({ cell: { value, row, column } }) => <CellContent id={`${row.index}-${column.id}-cell`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='user-name-title' column={column}>
						Enviado por
					</HeaderTitle>
				),
				accessor: 'user_name',
				Cell: ({ cell: { value, row, column } }) => <CellContent id={`${row.index}-${column.id}-cell`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='status-title' column={column}>
						Status
					</HeaderTitle>
				),
				accessor: 'status',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>
						<HStack>
							<Text id={`${row.index}-${column.id}-icon-text`}>{iconStatusLiteral[value]}</Text>
							<Text id={`${row.index}-${column.id}-text`}>{shippingReturnStatus[value] ?? 'N/A'}</Text>
						</HStack>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='created-at-title' column={column}>
						Data de envio
					</HeaderTitle>
				),
				accessor: 'created_at',
				Cell: ({ cell: { value, row, column } }) => <CellContent id={`${row.index}-${column.id}-cell`}>{maskFullDate(value)}</CellContent>,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <SimpleTable columns={columns} data={data} setPagination={setPagination} pagination={pagination} totalPages={totalPages} />;
};
