import React from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import {
	Flex,
	FormControl,
	FormLabel,
	Stack,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Divider,
	CheckboxGroup,
	useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import ReactQuill from 'react-quill';

import Loader from '../../containers/Loader';
import Paper from '../../containers/Paper';

import { getApiAuthConfig } from '../../services/api.service';
import { round } from '../../services/math.service';
import yup from '../../services/yup.service';

import { GetByIdRequest, ProtocolApi, UpdateProtocolRequest } from '../../clients';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormDatePickerInput from 'components/Form/FormDatePickerInput';
import FormSelect from 'components/Form/FormSelect';
import ChangeHistoryTable from './components/ChangeHistoryTable';

import { useCurrentSeller } from '../../contexts/SellerProvider';
import Checkbox from '../../components/Checkbox';
import Text from '../../components/Text';

const EditProtocol: React.FC = () => {
	const { currentSeller } = useCurrentSeller();
	const { id } = useParams();
	const { addToast } = useToasts();
	const history = useHistory();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [isLoading, setIsLoading] = React.useState(false);
	const [protocol, setProtocol] = React.useState<any>(undefined);
	const [initialValues, setInitalValues] = React.useState<any>({
		number: null,
		created_at: new Date(),
		presenter_name: null,
		value_cents: round(Number(protocol?.value_cents) / 100),
		status: null,
		status_date: new Date(),
		payer_email: null,
		payment_link: null,
		due_date: new Date(),
		alert_date: new Date(),
		payment_method: null,
		payment_type: null,
		email_content: '',
		has_email_notification: null,
		has_payment_link: null,
		payer_name: null,
		payment_methods: [],
	});

	const apiConfig = getApiAuthConfig();
	const protocolApi = new ProtocolApi(apiConfig);

	const getPaymentMethods = () => {
		let methods = ['card', 'billet', 'pix'];

		if (currentSeller && currentSeller?.no_payment_methods && Array.isArray(currentSeller?.no_payment_methods)) {
			if (currentSeller.no_payment_methods.includes('billet')) {
				methods = methods.filter((method) => method !== 'billet');
			}

			if (currentSeller.no_payment_methods.includes('pix')) {
				methods = methods.filter((method) => method !== 'pix');
			}
		}
		return methods;
	};

	const schema = yup.object().shape({
		has_email_notification: yup.boolean(),
		payer_email: yup
			.string()
			.email()
			.when('has_email_notification', {
				is: (has_email_notification) => has_email_notification === true,
				then: yup.string().email().required('Campo obrigatório'),
			}),
		email_content: yup.string().when('has_email_notification', {
			is: (has_email_notification) => has_email_notification === true,
			then: yup.string().required('Campo obrigatório'),
		}),
		value_cents: yup.number().when('has_email_notification', {
			is: (has_email_notification) => has_email_notification === true,
			then: yup.number().required('Ao enviar email o valor é obrigatório.'),
		}),
	});

	const getPrococol = async () => {
		setIsLoading(true);

		try {
			const getByIdParams: GetByIdRequest = { id };

			const protocol = await protocolApi.getById(getByIdParams);

			setProtocol(protocol);

			setInitalValues({
				number: protocol?.number,
				created_at: protocol?.created_at ? new Date(protocol.created_at) : new Date(),
				presenter_name: protocol?.presenter_name,
				value_cents: round(Number(protocol?.value_cents) / 100),
				status: protocol?.status,
				status_date: protocol?.status_date ? new Date(protocol.status_date) : new Date(),
				payer_email: protocol?.payer_email,
				payment_link: protocol?.payment_link,
				due_date: protocol?.due_date ? new Date(protocol.due_date) : new Date(),
				alert_date: protocol?.alert_date ? new Date(protocol.alert_date) : new Date(),
				payment_method: protocol?.payment_method,
				payment_type: protocol?.payment_type,
				email_content: protocol?.email_content ?? '',
				has_email_notification: protocol?.has_email_notification,
				has_payment_link: protocol?.has_payment_link,
				payer_name: protocol?.payment_link?.payer_name,
				payment_methods: getPaymentMethods(),
			});

			setIsLoading(false);
		} catch (error) {
			addToast('Erro ao buscar protocolo', {
				appearance: 'error',
				autoDismiss: true,
			});

			setIsLoading(false);
		}
	};

	const handleEditProtocol = async (values) => {
		const { created_at, status_date, due_date, value_cents } = values;

		const payload: UpdateProtocolRequest = {
			id: id,
			updateProtocolDto: {
				creation_date: created_at.toISOString(),
				status_date: status_date.toISOString(),
				due_date: due_date.toISOString(),
				value_cents: round(Number(value_cents) * 100),
				status: values.status,
				payment_method: values.payment_method,
				has_email_notification: values.has_email_notification,
				payer_email: values.has_email_notification ? values.payer_email : undefined,
				email_content: values.has_email_notification ? values.email_content : undefined,
				has_payment_link: values.has_payment_link,
				payment_link: {
					payer_name: values.payer_name,
					payment_methods: values.payment_methods,
				},
			},
		};

		try {
			setIsLoading(true);

			await protocolApi.updateProtocol(payload);

			addToast('Protocolo editado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao editar protocolo', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const navigateToProtocols = () => {
		history.push(`/admin/protocolos`);
	};

	React.useEffect(() => {
		getPrococol();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex direction='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb separator={<ChevronRightIcon />} color='darkGrey' mb='4'>
				<BreadcrumbItem>
					<Text id='resume-text' fontSize='md'>
						Resumo
					</Text>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md' href='/admin/protocolos'>
						Gestão de protocolos
					</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<Text id='edit-text' fontSize='md' fontWeight='semibold'>
						Editar
					</Text>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper id='edit-protocol-formik-paper-text' p={6}>
				<Flex flexDirection='column'>
					<>
						<Formik enableReinitialize validationSchema={schema} initialValues={initialValues} onSubmit={handleEditProtocol}>
							{({ handleSubmit, values, setFieldValue }) => {
								return (
									<form onSubmit={handleSubmit} style={{ width: '100%' }}>
										<Stack spacing={3} color={`primary`}>
											<Flex justifyContent='space-between' mb='4'>
												<Button
													bgColor='transparent'
													border='1px solid'
													size='md'
													color='primary'
													disabled={isLoading}
													onClick={navigateToProtocols}
												>
													Voltar
												</Button>
												<Button bgColor='primary' size='md' color='white' disabled={isLoading} type='submit' isLoading={isLoading}>
													Salvar Protocolo
												</Button>
											</Flex>
											<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
												<FormControl>
													<FormLabel id='edit-protocol-date-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Data do Protocolo
													</FormLabel>
													<FormDatePickerInput id='edit-protocol-date-picker-input' name='created_at' />
													<FormErrorMessage id='edit-protocol-create-at-form-error-text' name='created_at' />
												</FormControl>
												<FormControl>
													<FormLabel id='edit-protocol-due-date-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Data de Vencimento do Protocolo
													</FormLabel>
													<FormDatePickerInput id='edit-protocol-due-date-picker-input' name='due_date' />
													<FormErrorMessage id='edit-protocol-due-date-form-error-text' name='due_date' />
												</FormControl>
												<FormControl id='status'>
													<FormLabel id='edit-protocol-status-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Status
													</FormLabel>
													<FormSelect id='edit-protocol-status-form-select' name='status'>
														<option value=''></option>
														<option value='pending'>Pendente</option>
														<option value='alert'>Alerta</option>
														<option value='paid'>Pago</option>
														<option value='overdued'>Vencido sem pagamento</option>
														<option value='canceled'>Cancelado</option>
													</FormSelect>
												</FormControl>
											</Stack>
											<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
												<FormControl>
													<FormLabel id='edit-protocol-status-date-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Data do Status
													</FormLabel>
													<FormDatePickerInput id='edit-protocol-status-date-picker-input' name='status_date' />
													<FormErrorMessage id='edit-protocol-status-date-form-error-text' name='status_date' />
												</FormControl>
												<FormControl id='payment_method'>
													<FormLabel id='edit-protocol-payment-method-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Forma de Pagamento
													</FormLabel>
													<FormSelect id='edit-protocol-payment-method-form-select' name='payment_method'>
														<option value=''></option>
														<option value='credit'>Crédito</option>
														<option value='credit_in_installments'>Crédito Parcelado</option>
														<option value='debit'>Débito</option>
														<option value='billet'>Boleto</option>
														<option value='pix'>Pix</option>
														<option value='transference'>Transferência</option>
														<option value='cash'>Dinheiro</option>
														<option value='bank_check'>Cheque Bancário</option>
													</FormSelect>
												</FormControl>
											</Stack>
											<Divider orientation='horizontal' my={4} />
											<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
												<FormControl>
													<FormLabel id='edit-protocol-number-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														N° do Protocolo
													</FormLabel>
													<FormInput id='edit-protocol-number-form-input' name='number' isDisabled />
												</FormControl>
												<FormControl>
													<FormLabel id='edit-protocol-presenter-name-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Nome do Apresentante
													</FormLabel>
													<FormInput id='edit-protocol-presenter-name-form-input' name='presenter_name' isDisabled />
												</FormControl>
												<FormControl isRequired={values.has_email_notification}>
													<FormLabel id='edit-protocol-value-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Valor Total do Serviço
													</FormLabel>
													<FormCurrencyInput
														id='edit-protocol-value-cents-form-currency-input'
														name='value_cents'
														borderColor={`darkGrey`}
													/>
												</FormControl>
											</Stack>
											<Stack isInline spacing={4}>
												<Checkbox
													id='send-notification-email-checkbox'
													name='has_email_notification'
													isChecked={values.has_email_notification}
													color={`darkGrey`}
													fontSize={`md`}
													fontWeight={`medium`}
													onChange={(e) => {
														setFieldValue('has_email_notification', e.target.checked);
													}}
												>
													Enviar Email?
												</Checkbox>
											</Stack>
											<Stack direction='column' spacing={4}>
												{values.has_email_notification && (
													<>
														<Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
															<FormControl isRequired>
																<FormLabel id='edit-protocol-payer-name-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
																	Nome Completo
																</FormLabel>
																<FormInput id='edit-protocol-payer-name-form-input' name='payer_name' />
																<FormErrorMessage id='edit-protocol-payer-name-form-error-text' name='payer_name' />
															</FormControl>
															<FormControl isRequired>
																<FormLabel id='edit-protocol-payer-email-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
																	E-mail
																</FormLabel>
																<FormInput id='edit-protocol-payer-email-form-input' name='payer_email' />
															</FormControl>
														</Stack>
														<Stack>
															<FormControl>
																<FormLabel id='edit-email-content-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
																	Corpo do Email
																</FormLabel>
																<ReactQuill
																	theme='snow'
																	value={values.email_content}
																	onChange={(value) => {
																		setFieldValue('email_content', value);
																	}}
																/>
															</FormControl>
														</Stack>
													</>
												)}
												<Stack mt='4' isInline={protocol?.has_payment_link} spacing={4}>
													<Checkbox
														id='send-payment-link-email-checkbox'
														name='has_payment_link'
														isChecked={values.has_payment_link}
														color={`darkGrey`}
														fontSize={`md`}
														fontWeight={`medium`}
														isDisabled={protocol?.has_payment_link || !values.has_email_notification}
														onChange={(e) => {
															setFieldValue('has_payment_link', e.target.checked);
														}}
													>
														Enviar Link de Pagamento?
													</Checkbox>
													{protocol?.has_payment_link && values?.payment_link?.sale?.id ? (
														<Button
															onClick={() => {
																history.push(`/admin/sales-history/${values.payment_link.sale.id}`);
															}}
															bgColor={`white`}
															border='1px solid'
															borderColor={`primary`}
															color={`primary`}
															fontWeight={`medium`}
															px='4'
															height='auto'
														>
															Ir Para Venda
														</Button>
													) : (
														!protocol?.has_payment_link &&
														values.has_payment_link && (
															<FormControl>
																<Stack>
																	<Text id='payment-method-text' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
																		Formas de pagamento
																	</Text>
																	<CheckboxGroup
																		colorScheme='green'
																		value={values.payment_methods}
																		onChange={(value) => setFieldValue('payment_methods', value)}
																	>
																		<Stack isInline spacing={4}>
																			<Checkbox
																				id='card-payment-method-checkbox'
																				value='card'
																				color={`darkGrey`}
																				fontSize={`md`}
																				fontWeight={`medium`}
																			>
																				Cartão
																			</Checkbox>
																			{!currentSeller?.no_payment_methods?.includes('billet') && (
																				<Checkbox
																					id='billet-payment-method-checkbox'
																					value='billet'
																					color={`darkGrey`}
																					fontSize={`md`}
																					fontWeight={`medium`}
																				>
																					Boleto
																				</Checkbox>
																			)}
																			{!currentSeller?.no_payment_methods?.includes('pix') && (
																				<Checkbox
																					id='pix-payment-method-checkbox'
																					value='pix'
																					color={`darkGrey`}
																					fontSize={`md`}
																					fontWeight={`medium`}
																				>
																					Pix
																				</Checkbox>
																			)}
																		</Stack>
																	</CheckboxGroup>
																</Stack>
															</FormControl>
														)
													)}
												</Stack>
											</Stack>
										</Stack>
									</form>
								);
							}}
						</Formik>
					</>
				</Flex>
			</Paper>

			<Flex mt='8'>
				<Text id='history-text' color={`darkGrey`} fontSize={`lg`} fontWeight={`bold`}>
					Histórico de Alterações
				</Text>
			</Flex>

			<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
				<Flex overflowX='auto'>
					<ChangeHistoryTable data={protocol?.change_history || []} />
				</Flex>
			</Flex>
		</Flex>
	);
};

export default EditProtocol;
