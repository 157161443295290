/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CostOption,
    CostOptionFromJSON,
    CostOptionFromJSONTyped,
    CostOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CostPlan
 */
export interface CostPlan {
    /**
     * 
     * @type {string}
     * @memberof CostPlan
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CostPlan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CostPlan
     */
    description: string;
    /**
     * 
     * @type {Array<CostOption>}
     * @memberof CostPlan
     */
    options?: Array<CostOption>;
}

export function CostPlanFromJSON(json: any): CostPlan {
    return CostPlanFromJSONTyped(json, false);
}

export function CostPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': json['description'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(CostOptionFromJSON)),
    };
}

export function CostPlanToJSON(value?: CostPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(CostOptionToJSON)),
    };
}


