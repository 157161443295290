import { List, ListItem, ListIcon } from '@chakra-ui/react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import { useToasts } from 'react-toast-notifications';

type FailedTransfersLIstTypes = {
	seller_name: string;
	code: string;
	value: string;
	cnab_id: string;
}

const FailedTransfersList: React.FC<FailedTransfersLIstTypes> = (props) => {
	const { addToast } = useToasts();
	const { seller_name, code, value, cnab_id } = props;
	return (
		<List borderWidth={1} borderColor='lightgray' borderRadius={3} marginTop={5}>
			<ListItem padding={1} borderRadius={3} backgroundColor='lightgray' alignItems='center' display='flex' justifyContent='space-between'>
				Cartório: {seller_name}
				<CopyToClipboard
					text={seller_name}
					onCopy={() => {
						addToast('Nome do cartório copiado com sucesso', {
							appearance: 'success',
							autoDismiss: true,
						});
					}}
				>
					<ListIcon cursor='pointer' as={MdContentCopy} color='#008B8B' />
				</CopyToClipboard>
			</ListItem>
			<ListItem padding={1} borderRadius={3} alignItems='center' display='flex' justifyContent='space-between'>
				Código: {code}
				<CopyToClipboard
					text={code}
					onCopy={() => {
						addToast('Código do cartório copiado com sucesso', {
							appearance: 'success',
							autoDismiss: true,
						});
					}}
				>
					<ListIcon cursor='pointer' as={MdContentCopy} color='#008B8B' />
				</CopyToClipboard>
			</ListItem>
			<ListItem padding={1} borderRadius={3} backgroundColor='lightgray' alignItems='center' display='flex' justifyContent='space-between'>
				{value}
				<CopyToClipboard
					text={value}
					onCopy={() => {
						addToast('Valor copiado com sucesso', {
							appearance: 'success',
							autoDismiss: true,
						});
					}}
				>
					<ListIcon cursor='pointer' as={MdContentCopy} color='#008B8B' />
				</CopyToClipboard>
			</ListItem>
			<ListItem padding={1} borderRadius={3} alignItems='center' display='flex' justifyContent='space-between'>
				Cnab_id: {cnab_id}
				<CopyToClipboard
					text={cnab_id}
					onCopy={() => {
						addToast('Cnab_id do lote copiado com sucesso', {
							appearance: 'success',
							autoDismiss: true,
						});
					}}
				>
					<ListIcon cursor='pointer' as={MdContentCopy} color='#008B8B' />
				</CopyToClipboard>
			</ListItem>
		</List>
	);
};

export default FailedTransfersList;
