/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateBilletDto,
    CreateBilletDtoFromJSON,
    CreateBilletDtoFromJSONTyped,
    CreateBilletDtoToJSON,
    CreatePaymentLinkPayerResponseDto,
    CreatePaymentLinkPayerResponseDtoFromJSON,
    CreatePaymentLinkPayerResponseDtoFromJSONTyped,
    CreatePaymentLinkPayerResponseDtoToJSON,
    CreatePaymentLinkSaleResponseDto,
    CreatePaymentLinkSaleResponseDtoFromJSON,
    CreatePaymentLinkSaleResponseDtoFromJSONTyped,
    CreatePaymentLinkSaleResponseDtoToJSON,
    CreatePaymentLinkSellerResponseDto,
    CreatePaymentLinkSellerResponseDtoFromJSON,
    CreatePaymentLinkSellerResponseDtoFromJSONTyped,
    CreatePaymentLinkSellerResponseDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePaymentLinkResponseDto
 */
export interface CreatePaymentLinkResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentLinkResponseDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    payer_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    payer_document: string;
    /**
     * 
     * @type {CreatePaymentLinkPayerResponseDto}
     * @memberof CreatePaymentLinkResponseDto
     */
    payer: CreatePaymentLinkPayerResponseDto;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    status: CreatePaymentLinkResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    formatted_status: string;
    /**
     * 
     * @type {CreatePaymentLinkSaleResponseDto}
     * @memberof CreatePaymentLinkResponseDto
     */
    sale: CreatePaymentLinkSaleResponseDto;
    /**
     * 
     * @type {CreatePaymentLinkSellerResponseDto}
     * @memberof CreatePaymentLinkResponseDto
     */
    seller: CreatePaymentLinkSellerResponseDto;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    expires_in: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    qrcode_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePaymentLinkResponseDto
     */
    payment_methods?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePaymentLinkResponseDto
     */
    pre_capture: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreatePaymentLinkResponseDto
     */
    metadata: object;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkResponseDto
     */
    note?: string;
    /**
     * 
     * @type {CreateBilletDto}
     * @memberof CreatePaymentLinkResponseDto
     */
    billet: CreateBilletDto;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePaymentLinkResponseDto
     */
    is_edit?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreatePaymentLinkResponseDtoStatusEnum {
    Active = 'active',
    PendingCapture = 'pending_capture',
    Expired = 'expired',
    Canceled = 'canceled',
    Paid = 'paid'
}

export function CreatePaymentLinkResponseDtoFromJSON(json: any): CreatePaymentLinkResponseDto {
    return CreatePaymentLinkResponseDtoFromJSONTyped(json, false);
}

export function CreatePaymentLinkResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentLinkResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'description': json['description'],
        'url': json['url'],
        'email': json['email'],
        'phone': json['phone'],
        'token': json['token'],
        'payer_name': json['payer_name'],
        'payer_document': json['payer_document'],
        'payer': CreatePaymentLinkPayerResponseDtoFromJSON(json['payer']),
        'status': json['status'],
        'formatted_status': json['formatted_status'],
        'sale': CreatePaymentLinkSaleResponseDtoFromJSON(json['sale']),
        'seller': CreatePaymentLinkSellerResponseDtoFromJSON(json['seller']),
        'created_at': json['created_at'],
        'expires_in': json['expires_in'],
        'qrcode_url': !exists(json, 'qrcode_url') ? undefined : json['qrcode_url'],
        'payment_methods': !exists(json, 'payment_methods') ? undefined : json['payment_methods'],
        'pre_capture': json['pre_capture'],
        'metadata': json['metadata'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'billet': CreateBilletDtoFromJSON(json['billet']),
        'is_edit': !exists(json, 'is_edit') ? undefined : json['is_edit'],
    };
}

export function CreatePaymentLinkResponseDtoToJSON(value?: CreatePaymentLinkResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'description': value.description,
        'url': value.url,
        'email': value.email,
        'phone': value.phone,
        'token': value.token,
        'payer_name': value.payer_name,
        'payer_document': value.payer_document,
        'payer': CreatePaymentLinkPayerResponseDtoToJSON(value.payer),
        'status': value.status,
        'formatted_status': value.formatted_status,
        'sale': CreatePaymentLinkSaleResponseDtoToJSON(value.sale),
        'seller': CreatePaymentLinkSellerResponseDtoToJSON(value.seller),
        'created_at': value.created_at,
        'expires_in': value.expires_in,
        'qrcode_url': value.qrcode_url,
        'payment_methods': value.payment_methods,
        'pre_capture': value.pre_capture,
        'metadata': value.metadata,
        'note': value.note,
        'billet': CreateBilletDtoToJSON(value.billet),
        'is_edit': value.is_edit,
    };
}


