import * as React from 'react';

import { InputGroup, InputProps, InputRightElement, Spinner } from '@chakra-ui/react';
import { Field } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import InputMask from 'react-input-mask';

import Input from '../Input';

import { ListSellerPayerRequest, SellersApi } from '../../clients';
import { getApiAuthConfig } from '../../services/api.service';
import { useCurrentSeller } from '../../contexts/SellerProvider';

type FormCpfCnpjInputProps = InputProps & {
	onFilled?: (value: string) => void;
	onUnfilled?: (value: string) => void;
	beforeSearch?: () => void;
	afterSearch?: () => void;
	onSearchFailed?: () => void;
	onSearchResults?: (data) => void;
	setSelectedPayer?: (param: any) => void;
	isSearchPayer: boolean;
	id: string;
};

const FormCpfCnpjInput: React.FC<FormCpfCnpjInputProps> = (props: FormCpfCnpjInputProps) => {
	const [isFilled, setIsFilled] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

	const { currentSeller } = useCurrentSeller();

	const handleSearchPayerFormSubmit = async (props) => {
		const apiConfig = getApiAuthConfig();
		const listSellerPayersRequest: ListSellerPayerRequest = {
			sellerId: currentSeller?.id!,
			payerDocument: props,
		};
		const sellerApi = new SellersApi(apiConfig);
		const response = await sellerApi.listSellerPayer(listSellerPayersRequest);
		return response;
	};

	const debounced = useDebouncedCallback(async (value) => {
		const parsedCpfOrCnpj = value.replace(/\D+/gi, '');

		setIsLoading(true);

		try {
			const data = await handleSearchPayerFormSubmit(parsedCpfOrCnpj);

			props.setSelectedPayer && props.setSelectedPayer(data);
			props.onSearchResults && props.onSearchResults(data);
		} catch (e) {
			props.onSearchFailed && props.onSearchFailed();
		} finally {
			setIsLoading(false);
		}
		props.afterSearch && props.afterSearch();
	}, 1000);

	React.useEffect(
		() => () => {
			debounced.flush();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debounced]
	);

	const { onFilled, onUnfilled, onSearchFailed, onSearchResults, beforeSearch, afterSearch, isSearchPayer, ...parsedProps } = props;

	return (
		<Field {...parsedProps}>
			{({ form, field }) => {
				return (
					<InputMask
						borderColor={`darkGrey`}
						mask={field.value && field.value.replace(/\D+/gi, '').toString().length > 11 ? '99.999.999/9999-99' : '999.999.999-999'}
						maskChar={null}
						value={field.value}
						onBlur={form.handleBlur}
						{...(parsedProps.placeholder && { placeholder: parsedProps.placeholder })}
						onChange={(e) => {
							props.onChange && props.onChange(e);
							form.setFieldValue(field.name, e.target.value);

							const parsedValue = e.target.value.replace(/\D+/gi, '');

							if (isSearchPayer) {
								if (parsedValue.length === 14) {
									debounced.callback(e.target.value);
									if (!isFilled) {
										setIsFilled(true);

										props.onFilled && props.onFilled(parsedValue);
									}
								} else if (parsedValue.length === 11) {
									if (!isFilled) {
										setIsFilled(true);

										props.onFilled && props.onFilled(parsedValue);
										debounced.callback(e.target.value);
									}
								} else {
									setIsFilled(false);

									props.onUnfilled && props.onUnfilled(parsedValue);
								}
							}
						}}
						{...parsedProps}
					>
						{(inputProps) => {
							return (
								<InputGroup rounded={`lg`}>
									<Input id={props.id} type='text' {...inputProps} />
									{isLoading && (
										<InputRightElement
											pb={2}
											children={<Spinner thickness='1px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='sm' p={2} mb={2} />}
										/>
									)}
								</InputGroup>
							);
						}}
					</InputMask>
				);
			}}
		</Field>
	);
};

export default FormCpfCnpjInput;
