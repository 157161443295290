import yup from 'services/yup.service';

const createSalePlanSchema = yup.object().shape({
	name: yup.string().required('Campo obrigatório'),
	description: yup.string().required('Campo obrigatório'),
	type: yup.string().required('Campo obrigatório'),
	payment_method: yup.string().required('Campo obrigatório'),
	merchant_id: yup.string().required('Campo obrigatório'),
	acquirer_id: yup.string().required('Campo obrigatório'),
	settlement_plan_id: yup.string().required('Campo obrigatório'),
	cost_plan_id: yup.string().required('Campo obrigatório'),
	payment_types: yup.array().of(
		yup.object().shape({
			code: yup.string().required('Campo obrigatório'),
			fee: yup.number('Campo Numérico').required('Campo obrigatório').typeError('Campo Numérico'),
			description: yup.string().required('Campo obrigatório'),
			installments: yup.number('Campo Numérico').required('Campo obrigatório').typeError('Campo Numérico'),
			teto: yup.number('Campo Numérico').typeError('Campo Numérico'),
			piso: yup.number('Campo Numérico').typeError('Campo Numérico'),
			operation_mode: yup.string().required('Campo obrigatório'),
		})
	),
});

export default createSalePlanSchema;
