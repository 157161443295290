import React from 'react';
import { SimpleGrid, GridItem, Heading, Divider } from '@chakra-ui/react';

import BankForm from './BankForm';
import OwnerForm from './OwnerForm';
import SellerForm from './SellerForm';
import AddressForm from './AddressForm';
import ResponsibleForm from './ResponsibleForm';
import { GetMerchantDto, GetResponsibleDto } from '../../../clients';

type Props = {
  errors: any;
  values: any;
  setFieldValue: any;
  merchants: GetMerchantDto[];
  salesPlans: any;
  isStatusDisabled: boolean;
  isCnsSearchEnabled: boolean;
  responsibleSupports: GetResponsibleDto[];
  responsibleCommercials: GetResponsibleDto[];
};

const DataForm: React.FC<Props> = ({
  setFieldValue,
  values,
  merchants,
  salesPlans,
  isStatusDisabled,
  isCnsSearchEnabled,
  errors,
  responsibleSupports,
  responsibleCommercials,
}) => {
  return (
    <SimpleGrid
      columns={{base: 1, md: 6, xl: 12}}
      color='gray.700'
      gap={4}
    >
      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}}>
        <SellerForm
          salesPlans={salesPlans}
          merchants={merchants}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          isStatusDisabled={isStatusDisabled}
          isCnsSearchEnabled={isCnsSearchEnabled}
        />
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
        <Heading color='gray.700' as='h6' size='sm' py='1%'>
          Endereço
        </Heading>
        <Divider />
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
        <AddressForm
          onSearchResults={(data) => {
            setFieldValue('address.street', data.logradouro || '');
            setFieldValue('address.district', data.bairro || '');
            setFieldValue('address.city', data.localidade || '');
            setFieldValue('address.state', data.uf || '');
            setFieldValue('address.complement', data.complemento || '');
            setFieldValue('address.number', '');
          }}
          errors={errors}
        />
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
        <Heading color='gray.700' as='h6' size='sm' py='1%'>
          Dados Bancários
        </Heading>
        <Divider />
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
        <BankForm errors={errors} />
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
        <Heading color='gray.700' as='h6' size='sm' py='1%'>
          Proprietário
        </Heading>
        <Divider />
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
        <OwnerForm
          errors={errors}
        />
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
        <Heading color='gray.700' as='h6' size='sm' py='1%'>
          Responsáveis
        </Heading>
        <Divider />
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
        <ResponsibleForm
          supports={responsibleSupports}
          commercials={responsibleCommercials}
          errors={errors}
        />
      </GridItem>
    </SimpleGrid>
  );
};

export default DataForm;