/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreatePaperRollOrderDto,
    CreatePaperRollOrderDtoFromJSON,
    CreatePaperRollOrderDtoToJSON,
    GetPaperRollOrderDto,
    GetPaperRollOrderDtoFromJSON,
    GetPaperRollOrderDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    SentPaperRollOrderDto,
    SentPaperRollOrderDtoFromJSON,
    SentPaperRollOrderDtoToJSON,
} from '../models';

export interface CreateNewPaperRollOrderRequest {
    id: string;
    createPaperRollOrderDto: CreatePaperRollOrderDto;
}

export interface GetExportedOrdersRequest {
    startDate: string;
    endDate: string;
    status?: GetExportedOrdersStatusEnum;
    sellerId?: string;
}

export interface GetOrdersRequest {
    startDate: string;
    endDate: string;
    limit: number;
    currentPage: number;
    status?: GetOrdersStatusEnum;
    sellerId?: string;
}

export interface SentPaperRollOrderRequest {
    id: string;
    sentPaperRollOrderDto: SentPaperRollOrderDto;
}

/**
 * 
 */
export class PaperRollOrderApi extends runtime.BaseAPI {

    /**
     * Criar uma nova solicitação de bobina.
     */
    async createNewPaperRollOrderRaw(requestParameters: CreateNewPaperRollOrderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createNewPaperRollOrder.');
        }

        if (requestParameters.createPaperRollOrderDto === null || requestParameters.createPaperRollOrderDto === undefined) {
            throw new runtime.RequiredError('createPaperRollOrderDto','Required parameter requestParameters.createPaperRollOrderDto was null or undefined when calling createNewPaperRollOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/paper-roll-order/create/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaperRollOrderDtoToJSON(requestParameters.createPaperRollOrderDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Criar uma nova solicitação de bobina.
     */
    async createNewPaperRollOrder(requestParameters: CreateNewPaperRollOrderRequest): Promise<void> {
        await this.createNewPaperRollOrderRaw(requestParameters);
    }

    /**
     * Exportar relatório
     */
    async getExportedOrdersRaw(requestParameters: GetExportedOrdersRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getExportedOrders.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getExportedOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['seller_id'] = requestParameters.sellerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/paper-roll-order/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exportar relatório
     */
    async getExportedOrders(requestParameters: GetExportedOrdersRequest): Promise<Blob> {
        const response = await this.getExportedOrdersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retorna todas as ordens.
     */
    async getOrdersRaw(requestParameters: GetOrdersRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getOrders.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getOrders.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getOrders.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['seller_id'] = requestParameters.sellerId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/paper-roll-order/get-all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Retorna todas as ordens.
     */
    async getOrders(requestParameters: GetOrdersRequest): Promise<PaginatedDto> {
        const response = await this.getOrdersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Confirmar o envio da bobina.
     */
    async sentPaperRollOrderRaw(requestParameters: SentPaperRollOrderRequest): Promise<runtime.ApiResponse<GetPaperRollOrderDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sentPaperRollOrder.');
        }

        if (requestParameters.sentPaperRollOrderDto === null || requestParameters.sentPaperRollOrderDto === undefined) {
            throw new runtime.RequiredError('sentPaperRollOrderDto','Required parameter requestParameters.sentPaperRollOrderDto was null or undefined when calling sentPaperRollOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/paper-roll-order/sent/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SentPaperRollOrderDtoToJSON(requestParameters.sentPaperRollOrderDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaperRollOrderDtoFromJSON(jsonValue));
    }

    /**
     * Confirmar o envio da bobina.
     */
    async sentPaperRollOrder(requestParameters: SentPaperRollOrderRequest): Promise<GetPaperRollOrderDto> {
        const response = await this.sentPaperRollOrderRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetExportedOrdersStatusEnum {
    Pending = 'pending',
    Sent = 'sent',
    NotSent = 'not_sent'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOrdersStatusEnum {
    Pending = 'pending',
    Sent = 'sent',
    NotSent = 'not_sent'
}
