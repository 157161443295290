/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSellerPosDto
 */
export interface UpdateSellerPosDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerPosDto
     */
    seller_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerPosDto
     */
    terminal_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerPosDto
     */
    acquirer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerPosDto
     */
    status?: UpdateSellerPosDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSellerPosDto
     */
    is_android?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UpdateSellerPosDtoStatusEnum {
    Active = 'active',
    Broken = 'broken',
    Canceled = 'canceled',
    Inventory = 'inventory',
    StockForRepair = 'stock_for_repair',
    Repair = 'repair',
    Lost = 'lost'
}

export function UpdateSellerPosDtoFromJSON(json: any): UpdateSellerPosDto {
    return UpdateSellerPosDtoFromJSONTyped(json, false);
}

export function UpdateSellerPosDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSellerPosDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
        'terminal_id': !exists(json, 'terminal_id') ? undefined : json['terminal_id'],
        'acquirer_id': !exists(json, 'acquirer_id') ? undefined : json['acquirer_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'is_android': !exists(json, 'is_android') ? undefined : json['is_android'],
    };
}

export function UpdateSellerPosDtoToJSON(value?: UpdateSellerPosDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seller_id': value.seller_id,
        'terminal_id': value.terminal_id,
        'acquirer_id': value.acquirer_id,
        'status': value.status,
        'is_android': value.is_android,
    };
}


