/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SplitsDto
 */
export interface SplitsDto {
    /**
     * 
     * @type {string}
     * @memberof SplitsDto
     */
    sale_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SplitsDto
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof SplitsDto
     */
    seller_name: string;
    /**
     * 
     * @type {number}
     * @memberof SplitsDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof SplitsDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof SplitsDto
     */
    noCost: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SplitsDto
     */
    rejected: boolean;
}

export function SplitsDtoFromJSON(json: any): SplitsDto {
    return SplitsDtoFromJSONTyped(json, false);
}

export function SplitsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SplitsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'seller_id': json['seller_id'],
        'seller_name': json['seller_name'],
        'amount': json['amount'],
        'description': json['description'],
        'noCost': json['noCost'],
        'rejected': json['rejected'],
    };
}

export function SplitsDtoToJSON(value?: SplitsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sale_id': value.sale_id,
        'seller_id': value.seller_id,
        'seller_name': value.seller_name,
        'amount': value.amount,
        'description': value.description,
        'noCost': value.noCost,
        'rejected': value.rejected,
    };
}


