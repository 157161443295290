/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerDTO,
    CustomerDTOFromJSON,
    CustomerDTOFromJSONTyped,
    CustomerDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePixPaymentDto
 */
export interface CreatePixPaymentDto {
    /**
     * Valor em centavos
     * @type {number}
     * @memberof CreatePixPaymentDto
     */
    amount_cents: number;
    /**
     * Descrição
     * @type {string}
     * @memberof CreatePixPaymentDto
     */
    description: string;
    /**
     * Cliente
     * @type {CustomerDTO}
     * @memberof CreatePixPaymentDto
     */
    customer: CustomerDTO;
    /**
     * 
     * @type {string}
     * @memberof CreatePixPaymentDto
     */
    sale_id?: string;
    /**
     * Celcoin ID
     * @type {string}
     * @memberof CreatePixPaymentDto
     */
    celcoin_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePixPaymentDto
     */
    protest_link?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatePixPaymentDto
     */
    amount_fee?: number;
}

export function CreatePixPaymentDtoFromJSON(json: any): CreatePixPaymentDto {
    return CreatePixPaymentDtoFromJSONTyped(json, false);
}

export function CreatePixPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePixPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'description': json['description'],
        'customer': CustomerDTOFromJSON(json['customer']),
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'celcoin_id': !exists(json, 'celcoin_id') ? undefined : json['celcoin_id'],
        'protest_link': !exists(json, 'protest_link') ? undefined : json['protest_link'],
        'amount_fee': !exists(json, 'amount_fee') ? undefined : json['amount_fee'],
    };
}

export function CreatePixPaymentDtoToJSON(value?: CreatePixPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'description': value.description,
        'customer': CustomerDTOToJSON(value.customer),
        'sale_id': value.sale_id,
        'celcoin_id': value.celcoin_id,
        'protest_link': value.protest_link,
        'amount_fee': value.amount_fee,
    };
}


