/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentLink,
    PaymentLinkFromJSON,
    PaymentLinkFromJSONTyped,
    PaymentLinkToJSON,
    ProtocolDueDateUpdateHistory,
    ProtocolDueDateUpdateHistoryFromJSON,
    ProtocolDueDateUpdateHistoryFromJSONTyped,
    ProtocolDueDateUpdateHistoryToJSON,
    ProtocolStatusUpdateHistory,
    ProtocolStatusUpdateHistoryFromJSON,
    ProtocolStatusUpdateHistoryFromJSONTyped,
    ProtocolStatusUpdateHistoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProtocolDto
 */
export interface GetProtocolDto {
    /**
     * 
     * @type {Array<object>}
     * @memberof GetProtocolDto
     */
    change_history: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    seller_id: string;
    /**
     * 
     * @type {number}
     * @memberof GetProtocolDto
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    presenter_name: string;
    /**
     * 
     * @type {number}
     * @memberof GetProtocolDto
     */
    value_cents?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    status: GetProtocolDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    status_date: string;
    /**
     * 
     * @type {number}
     * @memberof GetProtocolDto
     */
    status_date_unix_time: number;
    /**
     * 
     * @type {Array<ProtocolStatusUpdateHistory>}
     * @memberof GetProtocolDto
     */
    status_update_history: Array<ProtocolStatusUpdateHistory>;
    /**
     * 
     * @type {boolean}
     * @memberof GetProtocolDto
     */
    has_email_notification: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    payer_email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    email_content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProtocolDto
     */
    has_payment_link: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    payment_link_id?: string;
    /**
     * 
     * @type {PaymentLink}
     * @memberof GetProtocolDto
     */
    payment_link?: PaymentLink;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    due_date: string;
    /**
     * 
     * @type {object}
     * @memberof GetProtocolDto
     */
    due_date_unix_time: object;
    /**
     * 
     * @type {Array<ProtocolDueDateUpdateHistory>}
     * @memberof GetProtocolDto
     */
    due_date_update_history: Array<ProtocolDueDateUpdateHistory>;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    alert_date?: string;
    /**
     * 
     * @type {object}
     * @memberof GetProtocolDto
     */
    alert_date_unix_time?: object;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    payment_method?: GetProtocolDtoPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    payment_type: GetProtocolDtoPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof GetProtocolDto
     */
    created_at_unix_time: number;
    /**
     * 
     * @type {number}
     * @memberof GetProtocolDto
     */
    created_at_time: number;
    /**
     * 
     * @type {string}
     * @memberof GetProtocolDto
     */
    updated_at?: string;
}

/**
* @export
* @enum {string}
*/
export enum GetProtocolDtoStatusEnum {
    Pending = 'pending',
    Alert = 'alert',
    Paid = 'paid',
    Overdued = 'overdued',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum GetProtocolDtoPaymentMethodEnum {
    Credit = 'credit',
    CreditInInstallments = 'credit_in_installments',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Transference = 'transference',
    Cash = 'cash',
    BankCheck = 'bank_check'
}/**
* @export
* @enum {string}
*/
export enum GetProtocolDtoPaymentTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    Others = 'others'
}

export function GetProtocolDtoFromJSON(json: any): GetProtocolDto {
    return GetProtocolDtoFromJSONTyped(json, false);
}

export function GetProtocolDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProtocolDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'change_history': json['change_history'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'seller_id': json['seller_id'],
        'number': json['number'],
        'presenter_name': json['presenter_name'],
        'value_cents': !exists(json, 'value_cents') ? undefined : json['value_cents'],
        'status': json['status'],
        'status_date': json['status_date'],
        'status_date_unix_time': json['status_date_unix_time'],
        'status_update_history': ((json['status_update_history'] as Array<any>).map(ProtocolStatusUpdateHistoryFromJSON)),
        'has_email_notification': json['has_email_notification'],
        'payer_email': !exists(json, 'payer_email') ? undefined : json['payer_email'],
        'email_content': !exists(json, 'email_content') ? undefined : json['email_content'],
        'has_payment_link': json['has_payment_link'],
        'payment_link_id': !exists(json, 'payment_link_id') ? undefined : json['payment_link_id'],
        'payment_link': !exists(json, 'payment_link') ? undefined : PaymentLinkFromJSON(json['payment_link']),
        'due_date': json['due_date'],
        'due_date_unix_time': json['due_date_unix_time'],
        'due_date_update_history': ((json['due_date_update_history'] as Array<any>).map(ProtocolDueDateUpdateHistoryFromJSON)),
        'alert_date': !exists(json, 'alert_date') ? undefined : json['alert_date'],
        'alert_date_unix_time': !exists(json, 'alert_date_unix_time') ? undefined : json['alert_date_unix_time'],
        'payment_method': !exists(json, 'payment_method') ? undefined : json['payment_method'],
        'payment_type': json['payment_type'],
        'created_at': json['created_at'],
        'created_at_unix_time': json['created_at_unix_time'],
        'created_at_time': json['created_at_time'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function GetProtocolDtoToJSON(value?: GetProtocolDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'change_history': value.change_history,
        'id': value.id,
        'seller_id': value.seller_id,
        'number': value.number,
        'presenter_name': value.presenter_name,
        'value_cents': value.value_cents,
        'status': value.status,
        'status_date': value.status_date,
        'status_date_unix_time': value.status_date_unix_time,
        'status_update_history': ((value.status_update_history as Array<any>).map(ProtocolStatusUpdateHistoryToJSON)),
        'has_email_notification': value.has_email_notification,
        'payer_email': value.payer_email,
        'email_content': value.email_content,
        'has_payment_link': value.has_payment_link,
        'payment_link_id': value.payment_link_id,
        'payment_link': PaymentLinkToJSON(value.payment_link),
        'due_date': value.due_date,
        'due_date_unix_time': value.due_date_unix_time,
        'due_date_update_history': ((value.due_date_update_history as Array<any>).map(ProtocolDueDateUpdateHistoryToJSON)),
        'alert_date': value.alert_date,
        'alert_date_unix_time': value.alert_date_unix_time,
        'payment_method': value.payment_method,
        'payment_type': value.payment_type,
        'created_at': value.created_at,
        'created_at_unix_time': value.created_at_unix_time,
        'created_at_time': value.created_at_time,
        'updated_at': value.updated_at,
    };
}


