import { Flex } from '@chakra-ui/react';
import React from 'react';
import Button from '../../../components/Button';
import { usePaperRollOrder } from '../context/PaperRollOrderContext';
import { BiExport } from 'react-icons/bi';

export const ExportButton: React.FC = () => {
	const { getExportedOrders, exportedLoading } = usePaperRollOrder();
	return (
		<Flex>
			<Button id='export-exel-button' bgColor='primary' onClick={getExportedOrders} isLoading={exportedLoading} leftIcon={<BiExport size={20} />}>
				Exportar para Excel
			</Button>
		</Flex>
	);
};
