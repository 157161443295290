import React from 'react';

import { Flex, Box } from '@chakra-ui/react';

import Button from '../../../components/Button';
import Select from '../../../components/Select';
import NewModal from 'containers/NewModal';
import Text from '../../../components/Text';

type PosModalProps = {
	confirmPayment: boolean;
	setSelectedPOS(POS: string): void;
	handlePayment(): void;
	setConfirmPayment(open: boolean): void;
	listPOS: any;
	resetForm(): void;
	isLoading: boolean;
	setSelectedPayer(param: any): void;
};

const PosModal: React.FC<PosModalProps> = ({
	confirmPayment,
	setSelectedPOS,
	handlePayment,
	setConfirmPayment,
	listPOS,
	resetForm,
	setSelectedPayer,
	isLoading,
}) => {
	React.useEffect(() => {
		setSelectedPOS([...listPOS].shift()?.terminal_id!);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listPOS]);

	return (
		<NewModal isOpen={confirmPayment} onClose={() => setConfirmPayment(!confirmPayment)} isCentered>
			<Box borderRadius='md' className='modal__select-pos-modal'>
				<Flex py={4} px={12} flexDirection='column'>
					<Text id='finished-pos-sale-text' align='center' color='darkGrey' fontSize='md' fontWeight='bold'>
						Favor finalizar a venda na máquina
					</Text>

					<Select
						id='modal-pos-select'
						my='4'
						bgColor='transparent'
						color='black'
						borderColor='black'
						{...(listPOS.length > 1 && {
							onChange: (event) => {
								setSelectedPOS(event.target.value);
							},
						})}
						height='10'
						className='select__select-pos'
					>
						{listPOS.map((pos) => (
							<option value={pos.terminal_id} key={pos.id}>
								{pos.terminal_id}
							</option>
						))}
					</Select>

					<Button
						id='interrupt-sale-button'
						bgColor='red.400'
						color='white'
						borderColor='black'
						isFullWidth
						onClick={() => {
							setConfirmPayment(!confirmPayment);
						}}
						className='button__interrupt-sale'
					>
						Interromper venda
					</Button>

					<Button
						id='generate-sale-ok-button'
						mt='4'
						bgColor='primary'
						color='white'
						borderColor='black'
						isFullWidth
						onClick={async () => {
							await handlePayment();
							setConfirmPayment(!confirmPayment);
							setSelectedPayer({});
							resetForm();
						}}
						isLoading={isLoading}
						className='button__generate-sale-ok'
					>
						OK
					</Button>
				</Flex>
			</Box>
		</NewModal>
	);
};

export default PosModal;
