/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSettlementOptionDto,
    GetSettlementOptionDtoFromJSON,
    GetSettlementOptionDtoFromJSONTyped,
    GetSettlementOptionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSettlementPlanDto
 */
export interface GetSettlementPlanDto {
    /**
     * 
     * @type {string}
     * @memberof GetSettlementPlanDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSettlementPlanDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSettlementPlanDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetSettlementPlanDto
     */
    code: GetSettlementPlanDtoCodeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetSettlementPlanDto
     */
    anticipation_receivables: boolean;
    /**
     * 
     * @type {Array<GetSettlementOptionDto>}
     * @memberof GetSettlementPlanDto
     */
    options?: Array<GetSettlementOptionDto>;
}

/**
* @export
* @enum {string}
*/
export enum GetSettlementPlanDtoCodeEnum {
    D0 = 'D0',
    D1 = 'D1',
    D30 = 'D30'
}

export function GetSettlementPlanDtoFromJSON(json: any): GetSettlementPlanDto {
    return GetSettlementPlanDtoFromJSONTyped(json, false);
}

export function GetSettlementPlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSettlementPlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'code': json['code'],
        'anticipation_receivables': json['anticipation_receivables'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(GetSettlementOptionDtoFromJSON)),
    };
}

export function GetSettlementPlanDtoToJSON(value?: GetSettlementPlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'code': value.code,
        'anticipation_receivables': value.anticipation_receivables,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(GetSettlementOptionDtoToJSON)),
    };
}


