/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePixPaymentResponseDTO
 */
export interface CreatePixPaymentResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatePixPaymentResponseDTO
     */
    resultCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePixPaymentResponseDTO
     */
    qrCodeData: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePixPaymentResponseDTO
     */
    url: string;
}

export function CreatePixPaymentResponseDTOFromJSON(json: any): CreatePixPaymentResponseDTO {
    return CreatePixPaymentResponseDTOFromJSONTyped(json, false);
}

export function CreatePixPaymentResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePixPaymentResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': json['resultCode'],
        'qrCodeData': json['qrCodeData'],
        'url': json['url'],
    };
}

export function CreatePixPaymentResponseDTOToJSON(value?: CreatePixPaymentResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'qrCodeData': value.qrCodeData,
        'url': value.url,
    };
}


