/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateExportProtocolListDto,
    CreateExportProtocolListDtoFromJSON,
    CreateExportProtocolListDtoToJSON,
    CreateProtocolDto,
    CreateProtocolDtoFromJSON,
    CreateProtocolDtoToJSON,
    CreateSellerProtocolDto,
    CreateSellerProtocolDtoFromJSON,
    CreateSellerProtocolDtoToJSON,
    GetDueDateDto,
    GetDueDateDtoFromJSON,
    GetDueDateDtoToJSON,
    GetProtocolDto,
    GetProtocolDtoFromJSON,
    GetProtocolDtoToJSON,
    GetProtocolsQueryDto,
    GetProtocolsQueryDtoFromJSON,
    GetProtocolsQueryDtoToJSON,
    GetSellerProtocolsQueryDto,
    GetSellerProtocolsQueryDtoFromJSON,
    GetSellerProtocolsQueryDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    UpdateProtocolDto,
    UpdateProtocolDtoFromJSON,
    UpdateProtocolDtoToJSON,
} from '../models';

export interface CreateProtocolRequest {
    createProtocolDto: CreateProtocolDto;
}

export interface CreateProtocolV2Request {
    sellerId: string;
    createSellerProtocolDto: CreateSellerProtocolDto;
}

export interface CreateProtocolsListExportRequestRequest {
    createExportProtocolListDto: CreateExportProtocolListDto;
}

export interface GetByIdRequest {
    id: string;
}

export interface GetDueDateBySellerRequest {
    sellerId: string;
    dateStart: string;
}

export interface GetPaginatedProtocolsBySellerRequest {
    getProtocolsQueryDto: GetProtocolsQueryDto;
}

export interface GetPaginatedProtocolsBySellerV2Request {
    sellerId: string;
    getSellerProtocolsQueryDto: GetSellerProtocolsQueryDto;
}

export interface UpdateProtocolRequest {
    id: string;
    updateProtocolDto: UpdateProtocolDto;
}

/**
 * 
 */
export class ProtocolApi extends runtime.BaseAPI {

    /**
     * Em alternativa a esta rota, utilize a [v2](#/Protocol/createProtocolV2)
     * Criar protocolo
     */
    async createProtocolRaw(requestParameters: CreateProtocolRequest): Promise<runtime.ApiResponse<GetProtocolDto>> {
        if (requestParameters.createProtocolDto === null || requestParameters.createProtocolDto === undefined) {
            throw new runtime.RequiredError('createProtocolDto','Required parameter requestParameters.createProtocolDto was null or undefined when calling createProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protocol`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProtocolDtoToJSON(requestParameters.createProtocolDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtocolDtoFromJSON(jsonValue));
    }

    /**
     * Em alternativa a esta rota, utilize a [v2](#/Protocol/createProtocolV2)
     * Criar protocolo
     */
    async createProtocol(requestParameters: CreateProtocolRequest): Promise<GetProtocolDto> {
        const response = await this.createProtocolRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar protocolo
     */
    async createProtocolV2Raw(requestParameters: CreateProtocolV2Request): Promise<runtime.ApiResponse<GetProtocolDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createProtocolV2.');
        }

        if (requestParameters.createSellerProtocolDto === null || requestParameters.createSellerProtocolDto === undefined) {
            throw new runtime.RequiredError('createSellerProtocolDto','Required parameter requestParameters.createSellerProtocolDto was null or undefined when calling createProtocolV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/protocol/seller/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerProtocolDtoToJSON(requestParameters.createSellerProtocolDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtocolDtoFromJSON(jsonValue));
    }

    /**
     * Criar protocolo
     */
    async createProtocolV2(requestParameters: CreateProtocolV2Request): Promise<GetProtocolDto> {
        const response = await this.createProtocolV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação da lista de protocolos
     */
    async createProtocolsListExportRequestRaw(requestParameters: CreateProtocolsListExportRequestRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.createExportProtocolListDto === null || requestParameters.createExportProtocolListDto === undefined) {
            throw new runtime.RequiredError('createExportProtocolListDto','Required parameter requestParameters.createExportProtocolListDto was null or undefined when calling createProtocolsListExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protocol/list/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportProtocolListDtoToJSON(requestParameters.createExportProtocolListDto),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gerar uma requisição de exportação da lista de protocolos
     */
    async createProtocolsListExportRequest(requestParameters: CreateProtocolsListExportRequestRequest): Promise<object> {
        const response = await this.createProtocolsListExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter todos protocolos
     */
    async getAllProtocolsRaw(): Promise<runtime.ApiResponse<Array<GetProtocolDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protocol`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProtocolDtoFromJSON));
    }

    /**
     * Obter todos protocolos
     */
    async getAllProtocols(): Promise<Array<GetProtocolDto>> {
        const response = await this.getAllProtocolsRaw();
        return await response.value();
    }

    /**
     * Obter protocolo pelo ID
     */
    async getByIdRaw(requestParameters: GetByIdRequest): Promise<runtime.ApiResponse<GetProtocolDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protocol/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtocolDtoFromJSON(jsonValue));
    }

    /**
     * Obter protocolo pelo ID
     */
    async getById(requestParameters: GetByIdRequest): Promise<GetProtocolDto> {
        const response = await this.getByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter data de vencimento considerando os criterios do Estabelecimento.
     */
    async getDueDateBySellerRaw(requestParameters: GetDueDateBySellerRequest): Promise<runtime.ApiResponse<GetDueDateDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getDueDateBySeller.');
        }

        if (requestParameters.dateStart === null || requestParameters.dateStart === undefined) {
            throw new runtime.RequiredError('dateStart','Required parameter requestParameters.dateStart was null or undefined when calling getDueDateBySeller.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = requestParameters.dateStart;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protocol/due-date/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDueDateDtoFromJSON(jsonValue));
    }

    /**
     * Obter data de vencimento considerando os criterios do Estabelecimento.
     */
    async getDueDateBySeller(requestParameters: GetDueDateBySellerRequest): Promise<GetDueDateDto> {
        const response = await this.getDueDateBySellerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter todos protocolos por Seller paginado
     */
    async getPaginatedProtocolsBySellerRaw(requestParameters: GetPaginatedProtocolsBySellerRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.getProtocolsQueryDto === null || requestParameters.getProtocolsQueryDto === undefined) {
            throw new runtime.RequiredError('getProtocolsQueryDto','Required parameter requestParameters.getProtocolsQueryDto was null or undefined when calling getPaginatedProtocolsBySeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protocol/getPaginatedBySeller`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProtocolsQueryDtoToJSON(requestParameters.getProtocolsQueryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Obter todos protocolos por Seller paginado
     */
    async getPaginatedProtocolsBySeller(requestParameters: GetPaginatedProtocolsBySellerRequest): Promise<PaginatedDto> {
        const response = await this.getPaginatedProtocolsBySellerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter todos protocolos por Seller paginado
     */
    async getPaginatedProtocolsBySellerV2Raw(requestParameters: GetPaginatedProtocolsBySellerV2Request): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getPaginatedProtocolsBySellerV2.');
        }

        if (requestParameters.getSellerProtocolsQueryDto === null || requestParameters.getSellerProtocolsQueryDto === undefined) {
            throw new runtime.RequiredError('getSellerProtocolsQueryDto','Required parameter requestParameters.getSellerProtocolsQueryDto was null or undefined when calling getPaginatedProtocolsBySellerV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/protocol/getPaginatedBySeller/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetSellerProtocolsQueryDtoToJSON(requestParameters.getSellerProtocolsQueryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Obter todos protocolos por Seller paginado
     */
    async getPaginatedProtocolsBySellerV2(requestParameters: GetPaginatedProtocolsBySellerV2Request): Promise<PaginatedDto> {
        const response = await this.getPaginatedProtocolsBySellerV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar protocolo
     */
    async updateProtocolRaw(requestParameters: UpdateProtocolRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProtocol.');
        }

        if (requestParameters.updateProtocolDto === null || requestParameters.updateProtocolDto === undefined) {
            throw new runtime.RequiredError('updateProtocolDto','Required parameter requestParameters.updateProtocolDto was null or undefined when calling updateProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protocol/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProtocolDtoToJSON(requestParameters.updateProtocolDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualizar protocolo
     */
    async updateProtocol(requestParameters: UpdateProtocolRequest): Promise<void> {
        await this.updateProtocolRaw(requestParameters);
    }

}
