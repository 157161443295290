/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CurrencyEnum {
    Brl = 'BRL',
    Usd = 'USD'
}

export function CurrencyEnumFromJSON(json: any): CurrencyEnum {
    return CurrencyEnumFromJSONTyped(json, false);
}

export function CurrencyEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyEnum {
    return json as CurrencyEnum;
}

export function CurrencyEnumToJSON(value?: CurrencyEnum | null): any {
    return value as any;
}

