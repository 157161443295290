/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRules,
    CreateSplitRulesFromJSON,
    CreateSplitRulesFromJSONTyped,
    CreateSplitRulesToJSON,
    GetBilletDataDto,
    GetBilletDataDtoFromJSON,
    GetBilletDataDtoFromJSONTyped,
    GetBilletDataDtoToJSON,
    GetBilletPayerAddressDto,
    GetBilletPayerAddressDtoFromJSON,
    GetBilletPayerAddressDtoFromJSONTyped,
    GetBilletPayerAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetBilletV3Dto
 */
export interface GetBilletV3Dto {
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    sale_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    acquirer_reference?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    expires_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    formatted_type?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    formatted_description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    email?: string;
    /**
     * 
     * @type {object}
     * @memberof GetBilletV3Dto
     */
    original_amount?: object;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    status?: GetBilletV3DtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    seller_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    seller_document?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    payer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    payer_email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletV3Dto
     */
    payer_document?: string;
    /**
     * 
     * @type {GetBilletPayerAddressDto}
     * @memberof GetBilletV3Dto
     */
    payer_address?: GetBilletPayerAddressDto;
    /**
     * 
     * @type {GetBilletDataDto}
     * @memberof GetBilletV3Dto
     */
    billet_data?: GetBilletDataDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetBilletV3Dto
     */
    has_split_rules?: boolean;
    /**
     * 
     * @type {Array<CreateSplitRules>}
     * @memberof GetBilletV3Dto
     */
    split_rules?: Array<CreateSplitRules>;
    /**
     * 
     * @type {object}
     * @memberof GetBilletV3Dto
     */
    metadata: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBilletV3Dto
     */
    errors?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum GetBilletV3DtoStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}

export function GetBilletV3DtoFromJSON(json: any): GetBilletV3Dto {
    return GetBilletV3DtoFromJSONTyped(json, false);
}

export function GetBilletV3DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBilletV3Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'url': json['url'],
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'acquirer_reference': !exists(json, 'acquirer_reference') ? undefined : json['acquirer_reference'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'expires_at': json['expires_at'],
        'barcode': json['barcode'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'formatted_type': !exists(json, 'formatted_type') ? undefined : json['formatted_type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'formatted_description': !exists(json, 'formatted_description') ? undefined : json['formatted_description'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'original_amount': !exists(json, 'original_amount') ? undefined : json['original_amount'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'seller_name': !exists(json, 'seller_name') ? undefined : json['seller_name'],
        'seller_document': !exists(json, 'seller_document') ? undefined : json['seller_document'],
        'payer_name': !exists(json, 'payer_name') ? undefined : json['payer_name'],
        'payer_email': !exists(json, 'payer_email') ? undefined : json['payer_email'],
        'payer_document': !exists(json, 'payer_document') ? undefined : json['payer_document'],
        'payer_address': !exists(json, 'payer_address') ? undefined : GetBilletPayerAddressDtoFromJSON(json['payer_address']),
        'billet_data': !exists(json, 'billet_data') ? undefined : GetBilletDataDtoFromJSON(json['billet_data']),
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesFromJSON)),
        'metadata': json['metadata'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    };
}

export function GetBilletV3DtoToJSON(value?: GetBilletV3Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
        'sale_id': value.sale_id,
        'acquirer_reference': value.acquirer_reference,
        'created_at': value.created_at,
        'expires_at': value.expires_at,
        'barcode': value.barcode,
        'type': value.type,
        'formatted_type': value.formatted_type,
        'description': value.description,
        'formatted_description': value.formatted_description,
        'email': value.email,
        'original_amount': value.original_amount,
        'status': value.status,
        'seller_name': value.seller_name,
        'seller_document': value.seller_document,
        'payer_name': value.payer_name,
        'payer_email': value.payer_email,
        'payer_document': value.payer_document,
        'payer_address': GetBilletPayerAddressDtoToJSON(value.payer_address),
        'billet_data': GetBilletDataDtoToJSON(value.billet_data),
        'has_split_rules': value.has_split_rules,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesToJSON)),
        'metadata': value.metadata,
        'errors': value.errors,
    };
}


