/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResponsibleGoalDto,
    ResponsibleGoalDtoFromJSON,
    ResponsibleGoalDtoFromJSONTyped,
    ResponsibleGoalDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateGoalDto
 */
export interface CreateGoalDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGoalDto
     */
    reference_date: string;
    /**
     * 
     * @type {number}
     * @memberof CreateGoalDto
     */
    business_days: number;
    /**
     * 
     * @type {number}
     * @memberof CreateGoalDto
     */
    overall_goal: number;
    /**
     * 
     * @type {Array<ResponsibleGoalDto>}
     * @memberof CreateGoalDto
     */
    responsibles: Array<ResponsibleGoalDto>;
}

export function CreateGoalDtoFromJSON(json: any): CreateGoalDto {
    return CreateGoalDtoFromJSONTyped(json, false);
}

export function CreateGoalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateGoalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference_date': json['reference_date'],
        'business_days': json['business_days'],
        'overall_goal': json['overall_goal'],
        'responsibles': ((json['responsibles'] as Array<any>).map(ResponsibleGoalDtoFromJSON)),
    };
}

export function CreateGoalDtoToJSON(value?: CreateGoalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference_date': value.reference_date,
        'business_days': value.business_days,
        'overall_goal': value.overall_goal,
        'responsibles': ((value.responsibles as Array<any>).map(ResponsibleGoalDtoToJSON)),
    };
}


