/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAddressDto,
    GetAddressDtoFromJSON,
    GetAddressDtoFromJSONTyped,
    GetAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePaymentLinkSellerResponseDto
 */
export interface CreatePaymentLinkSellerResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSellerResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSellerResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSellerResponseDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSellerResponseDto
     */
    document: string;
    /**
     * 
     * @type {GetAddressDto}
     * @memberof CreatePaymentLinkSellerResponseDto
     */
    address: GetAddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkSellerResponseDto
     */
    phone: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePaymentLinkSellerResponseDto
     */
    no_payment_methods?: Array<string>;
}

export function CreatePaymentLinkSellerResponseDtoFromJSON(json: any): CreatePaymentLinkSellerResponseDto {
    return CreatePaymentLinkSellerResponseDtoFromJSONTyped(json, false);
}

export function CreatePaymentLinkSellerResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentLinkSellerResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'document': json['document'],
        'address': GetAddressDtoFromJSON(json['address']),
        'phone': json['phone'],
        'no_payment_methods': !exists(json, 'no_payment_methods') ? undefined : json['no_payment_methods'],
    };
}

export function CreatePaymentLinkSellerResponseDtoToJSON(value?: CreatePaymentLinkSellerResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'document': value.document,
        'address': GetAddressDtoToJSON(value.address),
        'phone': value.phone,
        'no_payment_methods': value.no_payment_methods,
    };
}


