import React, { ReactNode } from 'react';
import { Box, Flex, SimpleGrid, UnorderedList, useBreakpointValue } from '@chakra-ui/react';
import { useMonthlyCalendar } from '@zach.codes/react-calendar';

import { isSameDay, format, getDay } from 'date-fns';

import daysInWeek from '../DaysInWeek';
import Text from 'components/Text';

type ExtraMonthData = { date: Date };

type OmittedDaysProps = {
	days: Date[];
	omitDays?: number[];
};

const handleOmittedDays = ({ days, omitDays }: OmittedDaysProps) => {
	let headings = daysInWeek;
	let daysToRender = days;

	if (omitDays) {
		headings = daysInWeek.filter((day) => !omitDays.includes(day.day));
		daysToRender = days.filter((day) => !omitDays.includes(getDay(day)));
	}

	let firstDayOfMonth = getDay(daysToRender[0]) as number;
	if (omitDays) {
		const subtractOmittedDays = omitDays.filter((day) => day < firstDayOfMonth).length;
		firstDayOfMonth -= subtractOmittedDays;
	}
	const padding = new Array(firstDayOfMonth).fill(0);

	return { headings, daysToRender, padding };
};

type MonthlyBodyProps<MonthData> = {
	// eslint-disable-next-line react/require-default-props
	omitDays?: number[];
	events: (MonthData & ExtraMonthData)[];

	renderDay: (data: (MonthData & ExtraMonthData)[]) => ReactNode;
};

export default function MonthlyBody<MonthData>({ omitDays, events, renderDay }: MonthlyBodyProps<MonthData>): JSX.Element {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const { days } = useMonthlyCalendar();
	const { headings, daysToRender, padding } = handleOmittedDays({
		days,
		omitDays,
	});

	return (
		<SimpleGrid columns={7} spacing={0} borderWidth='1px' borderEnd='none' borderBottom='none' borderColor='gray.400' rounded='sm'>
			{headings.map((day) => (
				<Box key={day.day} height='auto' textAlign='right' pr={['0', '3']} borderBottom='1px' borderEnd='1px' borderColor='gray.400'>
					<Text id='day-label-text' fontSize='sm' fontWeight='bold'>
						{isMobile ? day.label.slice(0, 1) : day.label}
					</Text>
				</Box>
			))}

			{padding.map((_, index) => (
				<Box key={index} borderBottom='1px' borderEnd='1px' borderColor='gray.400' />
			))}

			{daysToRender.map((day) => {
				const dayData = events.filter((data) => isSameDay(data.date, day));
				const dayNumber = format(day, 'd');

				return (
					<Box key={day.toISOString()} minH='5rem' p={['0', '3']} borderBottom='1px' borderEnd='1px' borderColor='gray.400'>
						<Flex justifyContent='flex-end'>
							<Text id='day-number-text' fontSize='md' fontWeight='bold'>
								{dayNumber}
							</Text>
						</Flex>

						<UnorderedList margin='0'>{renderDay(dayData)}</UnorderedList>
					</Box>
				);
			})}
		</SimpleGrid>
	);
}
