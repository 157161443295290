/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CapturePaymentDto
 */
export interface CapturePaymentDto {
    /**
     * 
     * @type {number}
     * @memberof CapturePaymentDto
     */
    amount?: number;
    /**
     * 
     * @type {Array<CreateSplitRulesDto>}
     * @memberof CapturePaymentDto
     */
    split_rules?: Array<CreateSplitRulesDto>;
}

export function CapturePaymentDtoFromJSON(json: any): CapturePaymentDto {
    return CapturePaymentDtoFromJSONTyped(json, false);
}

export function CapturePaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CapturePaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
    };
}

export function CapturePaymentDtoToJSON(value?: CapturePaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
    };
}


