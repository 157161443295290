import React from 'react';
import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { BsEyeFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages }) => {
	const history = useHistory();
	const columns = React.useMemo(() => {
		let cols: any = [];

		return cols.concat([
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='id-header-title-styled' column={column}>
						ID
					</HeaderTitleStyled>
				),
				accessor: 'id',
				id: 'id',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContentStyled id={`${index}-${id}-cell`} textAlign={`center`}>
						{value}
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='name-header-title-styled' column={column}>
						Nome
					</HeaderTitleStyled>
				),
				accessor: 'name',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='description-header-title-styled' column={column}>
						Descrição
					</HeaderTitleStyled>
				),
				accessor: 'description',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled id='id-view-header-title-styled' column={column}></HeaderTitleStyled>,
				accessor: 'id',
				id: 'view',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<Button id={`${index}-${id}-button`} background='transparent' onClick={() => history.push(`/admin/planos-de-custo/${value}`)}>
						<Icon id={`${index}-${id}-bs-eye-icon`} as={BsEyeFill} boxSize={5} />
					</Button>
				),
			},
		]);
	}, [history]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};
