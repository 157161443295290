import React from 'react';
import { Th, Table, Tr, Td } from '@chakra-ui/react';
import { maskMoney } from '../../../services/masks.service';

function ResponsibleRow({ responsibleInfos, index }) {
	return (
		<Tr id={`${index}-tr`} fontSize='sm'>
			<Td id={`${index}-name-td`} textAlign='center'>{responsibleInfos?.name}</Td>
			<Td id={`${index}-goal-td`} textAlign='center'>R$ {maskMoney(responsibleInfos?.goal / 100)}</Td>
		</Tr>
	);
}

function ResponsiblesTable({ data }) {
	return (
		<Table>
			<Tr id='responsible-table-tr'>
				<Th id='name-th' textAlign='center'>Nome</Th>
				<Th id='goal-th' textAlign='center'>Meta</Th>
			</Tr>
			{data?.subRows.map((responsible, index) => (
				<ResponsibleRow responsibleInfos={responsible} index={index} />
			))}
		</Table>
	);
}

export default ResponsiblesTable;
