/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExportAssignmentTransactionAlertsDto
 */
export interface CreateExportAssignmentTransactionAlertsDto {
    /**
     * Formato: yyyy-mm-dd
     * @type {string}
     * @memberof CreateExportAssignmentTransactionAlertsDto
     */
    start_date?: string;
    /**
     * Formato: yyyy-mm-dd
     * @type {string}
     * @memberof CreateExportAssignmentTransactionAlertsDto
     */
    end_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportAssignmentTransactionAlertsDto
     */
    sale_description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportAssignmentTransactionAlertsDto
     */
    extension?: CreateExportAssignmentTransactionAlertsDtoExtensionEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateExportAssignmentTransactionAlertsDtoExtensionEnum {
    Xls = 'xls',
    Csv = 'csv',
    Json = 'json',
    Pdf = 'pdf'
}

export function CreateExportAssignmentTransactionAlertsDtoFromJSON(json: any): CreateExportAssignmentTransactionAlertsDto {
    return CreateExportAssignmentTransactionAlertsDtoFromJSONTyped(json, false);
}

export function CreateExportAssignmentTransactionAlertsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExportAssignmentTransactionAlertsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start_date': !exists(json, 'start_date') ? undefined : json['start_date'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'sale_description': !exists(json, 'sale_description') ? undefined : json['sale_description'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
    };
}

export function CreateExportAssignmentTransactionAlertsDtoToJSON(value?: CreateExportAssignmentTransactionAlertsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.start_date,
        'end_date': value.end_date,
        'sale_description': value.sale_description,
        'extension': value.extension,
    };
}


