/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCostOptionDto
 */
export interface GetCostOptionDto {
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetCostOptionDto
     */
    fixed_amount: number;
    /**
     * 
     * @type {number}
     * @memberof GetCostOptionDto
     */
    bank_fixed_amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    type: GetCostOptionDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    type_status: GetCostOptionDtoTypeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    brand: GetCostOptionDtoBrandEnum;
    /**
     * 
     * @type {number}
     * @memberof GetCostOptionDto
     */
    brand_fee: number;
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    brand_fee_type?: GetCostOptionDtoBrandFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetCostOptionDto
     */
    installments: number;
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    exchange_fee_type: GetCostOptionDtoExchangeFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetCostOptionDto
     */
    exchange_fee: number;
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    anticipation_fee_type?: GetCostOptionDtoAnticipationFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetCostOptionDto
     */
    anticipation_fee: number;
    /**
     * 
     * @type {string}
     * @memberof GetCostOptionDto
     */
    markup_fee_type?: GetCostOptionDtoMarkupFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetCostOptionDto
     */
    markup_fee: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetCostOptionDto
     */
    is_cap: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetCostOptionDto
     */
    exchange_cap: number;
}

/**
* @export
* @enum {string}
*/
export enum GetCostOptionDtoTypeEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    PixPos = 'pix_pos'
}/**
* @export
* @enum {string}
*/
export enum GetCostOptionDtoTypeStatusEnum {
    Pending = 'pending',
    Paid = 'paid'
}/**
* @export
* @enum {string}
*/
export enum GetCostOptionDtoBrandEnum {
    Visa = 'visa',
    Elo = 'elo',
    Mc = 'mc',
    Amex = 'amex',
    Hipercard = 'hipercard',
    Default = 'default'
}/**
* @export
* @enum {string}
*/
export enum GetCostOptionDtoBrandFeeTypeEnum {
    Fixed = 'fixed',
    Percent = 'percent'
}/**
* @export
* @enum {string}
*/
export enum GetCostOptionDtoExchangeFeeTypeEnum {
    Fixed = 'fixed',
    Percent = 'percent'
}/**
* @export
* @enum {string}
*/
export enum GetCostOptionDtoAnticipationFeeTypeEnum {
    Fixed = 'fixed',
    Percent = 'percent'
}/**
* @export
* @enum {string}
*/
export enum GetCostOptionDtoMarkupFeeTypeEnum {
    Fixed = 'fixed',
    Percent = 'percent'
}

export function GetCostOptionDtoFromJSON(json: any): GetCostOptionDto {
    return GetCostOptionDtoFromJSONTyped(json, false);
}

export function GetCostOptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostOptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fixed_amount': json['fixed_amount'],
        'bank_fixed_amount': json['bank_fixed_amount'],
        'description': json['description'],
        'type': json['type'],
        'type_status': json['type_status'],
        'brand': json['brand'],
        'brand_fee': json['brand_fee'],
        'brand_fee_type': !exists(json, 'brand_fee_type') ? undefined : json['brand_fee_type'],
        'installments': json['installments'],
        'exchange_fee_type': json['exchange_fee_type'],
        'exchange_fee': json['exchange_fee'],
        'anticipation_fee_type': !exists(json, 'anticipation_fee_type') ? undefined : json['anticipation_fee_type'],
        'anticipation_fee': json['anticipation_fee'],
        'markup_fee_type': !exists(json, 'markup_fee_type') ? undefined : json['markup_fee_type'],
        'markup_fee': json['markup_fee'],
        'is_cap': json['is_cap'],
        'exchange_cap': json['exchange_cap'],
    };
}

export function GetCostOptionDtoToJSON(value?: GetCostOptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fixed_amount': value.fixed_amount,
        'bank_fixed_amount': value.bank_fixed_amount,
        'description': value.description,
        'type': value.type,
        'type_status': value.type_status,
        'brand': value.brand,
        'brand_fee': value.brand_fee,
        'brand_fee_type': value.brand_fee_type,
        'installments': value.installments,
        'exchange_fee_type': value.exchange_fee_type,
        'exchange_fee': value.exchange_fee,
        'anticipation_fee_type': value.anticipation_fee_type,
        'anticipation_fee': value.anticipation_fee,
        'markup_fee_type': value.markup_fee_type,
        'markup_fee': value.markup_fee,
        'is_cap': value.is_cap,
        'exchange_cap': value.exchange_cap,
    };
}


