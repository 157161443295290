/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet'
}

export function OperationEnumFromJSON(json: any): OperationEnum {
    return OperationEnumFromJSONTyped(json, false);
}

export function OperationEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationEnum {
    return json as OperationEnum;
}

export function OperationEnumToJSON(value?: OperationEnum | null): any {
    return value as any;
}

