/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesHistoryReportPermissions
 */
export interface SalesHistoryReportPermissions {
    /**
     * 
     * @type {string}
     * @memberof SalesHistoryReportPermissions
     */
    report: SalesHistoryReportPermissionsReportEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesHistoryReportPermissions
     */
    fields: Array<SalesHistoryReportPermissionsFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesHistoryReportPermissions
     */
    denied_fields: Array<SalesHistoryReportPermissionsDeniedFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesHistoryReportPermissions
     */
    allowed_fields: Array<SalesHistoryReportPermissionsAllowedFieldsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum SalesHistoryReportPermissionsReportEnum {
    SalesHistory = 'sales_history',
    SettlementBatchTransaction = 'settlement_batch_transaction',
    SettlementBatchSale = 'settlement_batch_sale',
    BillPaymentSales = 'bill_payment_sales'
}/**
* @export
* @enum {string}
*/
export enum SalesHistoryReportPermissionsFieldsEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    SplitDescription = 'split_description'
}/**
* @export
* @enum {string}
*/
export enum SalesHistoryReportPermissionsDeniedFieldsEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    SplitDescription = 'split_description'
}/**
* @export
* @enum {string}
*/
export enum SalesHistoryReportPermissionsAllowedFieldsEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    SplitDescription = 'split_description'
}

export function SalesHistoryReportPermissionsFromJSON(json: any): SalesHistoryReportPermissions {
    return SalesHistoryReportPermissionsFromJSONTyped(json, false);
}

export function SalesHistoryReportPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesHistoryReportPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'report': json['report'],
        'fields': json['fields'],
        'denied_fields': json['denied_fields'],
        'allowed_fields': json['allowed_fields'],
    };
}

export function SalesHistoryReportPermissionsToJSON(value?: SalesHistoryReportPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report': value.report,
        'fields': value.fields,
        'denied_fields': value.denied_fields,
        'allowed_fields': value.allowed_fields,
    };
}


