/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CardDTO,
    CardDTOFromJSON,
    CardDTOFromJSONTyped,
    CardDTOToJSON,
    CustomerDTO,
    CustomerDTOFromJSON,
    CustomerDTOFromJSONTyped,
    CustomerDTOToJSON,
    InstallmentPlanDTO,
    InstallmentPlanDTOFromJSON,
    InstallmentPlanDTOFromJSONTyped,
    InstallmentPlanDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePaymentDto
 */
export interface CreatePaymentDto {
    /**
     * Valor em centavos
     * @type {number}
     * @memberof CreatePaymentDto
     */
    amount_cents: number;
    /**
     * Descrição
     * @type {string}
     * @memberof CreatePaymentDto
     */
    description: string;
    /**
     * Informações do cartão
     * @type {CardDTO}
     * @memberof CreatePaymentDto
     */
    card_attributes: CardDTO;
    /**
     * Parcelamento
     * @type {InstallmentPlanDTO}
     * @memberof CreatePaymentDto
     */
    installment_plan: InstallmentPlanDTO;
    /**
     * Cliente
     * @type {CustomerDTO}
     * @memberof CreatePaymentDto
     */
    customer: CustomerDTO;
    /**
     * Pré captura
     * @type {boolean}
     * @memberof CreatePaymentDto
     */
    pre_capture?: boolean;
    /**
     * Forma de pagamento
     * @type {string}
     * @memberof CreatePaymentDto
     */
    form_payment?: CreatePaymentDtoFormPaymentEnum;
    /**
     * Url de redirecionamento de sucesso (exclusivo 3DS)
     * @type {string}
     * @memberof CreatePaymentDto
     */
    success_return_url?: string;
    /**
     * Url de redirecionamento de erro (exclusivo 3DS)
     * @type {string}
     * @memberof CreatePaymentDto
     */
    error_return_url?: string;
    /**
     * Assinatura
     * @type {boolean}
     * @memberof CreatePaymentDto
     */
    recurrence?: boolean;
    /**
     * Data de cobrança assinatura
     * @type {number}
     * @memberof CreatePaymentDto
     */
    recurrence_day?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentDto
     */
    sale_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePaymentDto
     */
    protest_link?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentDto
     */
    amount_fee?: number;
}

/**
* @export
* @enum {string}
*/
export enum CreatePaymentDtoFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Pending = 'pending',
    PixPos = 'pix_pos'
}

export function CreatePaymentDtoFromJSON(json: any): CreatePaymentDto {
    return CreatePaymentDtoFromJSONTyped(json, false);
}

export function CreatePaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'description': json['description'],
        'card_attributes': CardDTOFromJSON(json['card_attributes']),
        'installment_plan': InstallmentPlanDTOFromJSON(json['installment_plan']),
        'customer': CustomerDTOFromJSON(json['customer']),
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
        'form_payment': !exists(json, 'form_payment') ? undefined : json['form_payment'],
        'success_return_url': !exists(json, 'success_return_url') ? undefined : json['success_return_url'],
        'error_return_url': !exists(json, 'error_return_url') ? undefined : json['error_return_url'],
        'recurrence': !exists(json, 'recurrence') ? undefined : json['recurrence'],
        'recurrence_day': !exists(json, 'recurrence_day') ? undefined : json['recurrence_day'],
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'protest_link': !exists(json, 'protest_link') ? undefined : json['protest_link'],
        'amount_fee': !exists(json, 'amount_fee') ? undefined : json['amount_fee'],
    };
}

export function CreatePaymentDtoToJSON(value?: CreatePaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'description': value.description,
        'card_attributes': CardDTOToJSON(value.card_attributes),
        'installment_plan': InstallmentPlanDTOToJSON(value.installment_plan),
        'customer': CustomerDTOToJSON(value.customer),
        'pre_capture': value.pre_capture,
        'form_payment': value.form_payment,
        'success_return_url': value.success_return_url,
        'error_return_url': value.error_return_url,
        'recurrence': value.recurrence,
        'recurrence_day': value.recurrence_day,
        'sale_id': value.sale_id,
        'protest_link': value.protest_link,
        'amount_fee': value.amount_fee,
    };
}


