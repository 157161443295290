/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettlementBatchSalesReport
 */
export interface SettlementBatchSalesReport {
    /**
     * 
     * @type {string}
     * @memberof SettlementBatchSalesReport
     */
    report: SettlementBatchSalesReportReportEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettlementBatchSalesReport
     */
    fields: Array<SettlementBatchSalesReportFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettlementBatchSalesReport
     */
    denied_fields: Array<SettlementBatchSalesReportDeniedFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettlementBatchSalesReport
     */
    allowed_fields: Array<SettlementBatchSalesReportAllowedFieldsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum SettlementBatchSalesReportReportEnum {
    SalesHistory = 'sales_history',
    SettlementBatchTransaction = 'settlement_batch_transaction',
    SettlementBatchSale = 'settlement_batch_sale',
    BillPaymentSales = 'bill_payment_sales'
}/**
* @export
* @enum {string}
*/
export enum SettlementBatchSalesReportFieldsEnum {
    OrderNumber = 'order_number',
    Id = 'id',
    SettlementStatus = 'settlement_status',
    Description = 'description',
    FormPayment = 'form_payment',
    Type = 'type',
    OriginalAmount = 'original_amount',
    Fee = 'fee',
    Amount = 'amount',
    Payer = 'payer',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    SaleId = 'sale_id'
}/**
* @export
* @enum {string}
*/
export enum SettlementBatchSalesReportDeniedFieldsEnum {
    OrderNumber = 'order_number',
    Id = 'id',
    SettlementStatus = 'settlement_status',
    Description = 'description',
    FormPayment = 'form_payment',
    Type = 'type',
    OriginalAmount = 'original_amount',
    Fee = 'fee',
    Amount = 'amount',
    Payer = 'payer',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    SaleId = 'sale_id'
}/**
* @export
* @enum {string}
*/
export enum SettlementBatchSalesReportAllowedFieldsEnum {
    OrderNumber = 'order_number',
    Id = 'id',
    SettlementStatus = 'settlement_status',
    Description = 'description',
    FormPayment = 'form_payment',
    Type = 'type',
    OriginalAmount = 'original_amount',
    Fee = 'fee',
    Amount = 'amount',
    Payer = 'payer',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    SaleId = 'sale_id'
}

export function SettlementBatchSalesReportFromJSON(json: any): SettlementBatchSalesReport {
    return SettlementBatchSalesReportFromJSONTyped(json, false);
}

export function SettlementBatchSalesReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementBatchSalesReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'report': json['report'],
        'fields': json['fields'],
        'denied_fields': json['denied_fields'],
        'allowed_fields': json['allowed_fields'],
    };
}

export function SettlementBatchSalesReportToJSON(value?: SettlementBatchSalesReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report': value.report,
        'fields': value.fields,
        'denied_fields': value.denied_fields,
        'allowed_fields': value.allowed_fields,
    };
}


