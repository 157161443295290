/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePaymentLinkFromProtocolDto
 */
export interface CreatePaymentLinkFromProtocolDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkFromProtocolDto
     */
    payer_name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePaymentLinkFromProtocolDto
     */
    payment_methods: Array<string>;
}

export function CreatePaymentLinkFromProtocolDtoFromJSON(json: any): CreatePaymentLinkFromProtocolDto {
    return CreatePaymentLinkFromProtocolDtoFromJSONTyped(json, false);
}

export function CreatePaymentLinkFromProtocolDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentLinkFromProtocolDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payer_name': json['payer_name'],
        'payment_methods': json['payment_methods'],
    };
}

export function CreatePaymentLinkFromProtocolDtoToJSON(value?: CreatePaymentLinkFromProtocolDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payer_name': value.payer_name,
        'payment_methods': value.payment_methods,
    };
}


