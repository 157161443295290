/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentType,
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSellerSalePlanDto
 */
export interface GetSellerSalePlanDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerSalePlanDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerSalePlanDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerSalePlanDto
     */
    description: string;
    /**
     * 
     * @type {Array<PaymentType>}
     * @memberof GetSellerSalePlanDto
     */
    payment_types?: Array<PaymentType>;
}

export function GetSellerSalePlanDtoFromJSON(json: any): GetSellerSalePlanDto {
    return GetSellerSalePlanDtoFromJSONTyped(json, false);
}

export function GetSellerSalePlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerSalePlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'payment_types': !exists(json, 'payment_types') ? undefined : ((json['payment_types'] as Array<any>).map(PaymentTypeFromJSON)),
    };
}

export function GetSellerSalePlanDtoToJSON(value?: GetSellerSalePlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'payment_types': value.payment_types === undefined ? undefined : ((value.payment_types as Array<any>).map(PaymentTypeToJSON)),
    };
}


