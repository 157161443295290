/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
    CustomerDTO,
    CustomerDTOFromJSON,
    CustomerDTOFromJSONTyped,
    CustomerDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePosPixPaymentDto
 */
export interface CreatePosPixPaymentDto {
    /**
     * Valor em centavos
     * @type {number}
     * @memberof CreatePosPixPaymentDto
     */
    amount_cents: number;
    /**
     * Id do Terminal POS
     * @type {string}
     * @memberof CreatePosPixPaymentDto
     */
    terminal_id?: string;
    /**
     * Descrição
     * @type {string}
     * @memberof CreatePosPixPaymentDto
     */
    description?: string;
    /**
     * Cliente
     * @type {CustomerDTO}
     * @memberof CreatePosPixPaymentDto
     */
    customer?: CustomerDTO;
    /**
     * 
     * @type {string}
     * @memberof CreatePosPixPaymentDto
     */
    sale_id?: string;
    /**
     * Celcoin ID
     * @type {string}
     * @memberof CreatePosPixPaymentDto
     */
    celcoin_id?: string;
    /**
     * Data de vencimento do pix
     * @type {string}
     * @memberof CreatePosPixPaymentDto
     */
    delivery_date?: string;
    /**
     * Há divisão de pagamento
     * @type {boolean}
     * @memberof CreatePosPixPaymentDto
     */
    has_split_rules?: boolean;
    /**
     * Divisão de pagamento
     * @type {Array<CreateSplitRulesDto>}
     * @memberof CreatePosPixPaymentDto
     */
    split_rules?: Array<CreateSplitRulesDto>;
}

export function CreatePosPixPaymentDtoFromJSON(json: any): CreatePosPixPaymentDto {
    return CreatePosPixPaymentDtoFromJSONTyped(json, false);
}

export function CreatePosPixPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePosPixPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'terminal_id': !exists(json, 'terminal_id') ? undefined : json['terminal_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'customer': !exists(json, 'customer') ? undefined : CustomerDTOFromJSON(json['customer']),
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'celcoin_id': !exists(json, 'celcoin_id') ? undefined : json['celcoin_id'],
        'delivery_date': !exists(json, 'delivery_date') ? undefined : json['delivery_date'],
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
    };
}

export function CreatePosPixPaymentDtoToJSON(value?: CreatePosPixPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'terminal_id': value.terminal_id,
        'description': value.description,
        'customer': CustomerDTOToJSON(value.customer),
        'sale_id': value.sale_id,
        'celcoin_id': value.celcoin_id,
        'delivery_date': value.delivery_date,
        'has_split_rules': value.has_split_rules,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
    };
}


