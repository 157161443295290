/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetMerchantPermissionDto,
    GetMerchantPermissionDtoFromJSON,
    GetMerchantPermissionDtoFromJSONTyped,
    GetMerchantPermissionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMerchantReportsPermissionsDto
 */
export interface GetMerchantReportsPermissionsDto {
    /**
     * 
     * @type {GetMerchantPermissionDto}
     * @memberof GetMerchantReportsPermissionsDto
     */
    sales_history?: GetMerchantPermissionDto;
    /**
     * 
     * @type {GetMerchantPermissionDto}
     * @memberof GetMerchantReportsPermissionsDto
     */
    settlement_batch_transaction?: GetMerchantPermissionDto;
    /**
     * 
     * @type {GetMerchantPermissionDto}
     * @memberof GetMerchantReportsPermissionsDto
     */
    settlement_batch_sale?: GetMerchantPermissionDto;
}

export function GetMerchantReportsPermissionsDtoFromJSON(json: any): GetMerchantReportsPermissionsDto {
    return GetMerchantReportsPermissionsDtoFromJSONTyped(json, false);
}

export function GetMerchantReportsPermissionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMerchantReportsPermissionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sales_history': !exists(json, 'sales_history') ? undefined : GetMerchantPermissionDtoFromJSON(json['sales_history']),
        'settlement_batch_transaction': !exists(json, 'settlement_batch_transaction') ? undefined : GetMerchantPermissionDtoFromJSON(json['settlement_batch_transaction']),
        'settlement_batch_sale': !exists(json, 'settlement_batch_sale') ? undefined : GetMerchantPermissionDtoFromJSON(json['settlement_batch_sale']),
    };
}

export function GetMerchantReportsPermissionsDtoToJSON(value?: GetMerchantReportsPermissionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sales_history': GetMerchantPermissionDtoToJSON(value.sales_history),
        'settlement_batch_transaction': GetMerchantPermissionDtoToJSON(value.settlement_batch_transaction),
        'settlement_batch_sale': GetMerchantPermissionDtoToJSON(value.settlement_batch_sale),
    };
}


