/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponsePosPasswordDto
 */
export interface ResponsePosPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePosPasswordDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePosPasswordDto
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePosPasswordDto
     */
    created_user_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePosPasswordDto
     */
    updated_user_name?: string;
    /**
     * 
     * @type {Date}
     * @memberof ResponsePosPasswordDto
     */
    created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ResponsePosPasswordDto
     */
    updated_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ResponsePosPasswordDto
     */
    deleted_at?: Date;
}

export function ResponsePosPasswordDtoFromJSON(json: any): ResponsePosPasswordDto {
    return ResponsePosPasswordDtoFromJSONTyped(json, false);
}

export function ResponsePosPasswordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponsePosPasswordDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'seller_id': json['seller_id'],
        'created_user_name': !exists(json, 'created_user_name') ? undefined : json['created_user_name'],
        'updated_user_name': !exists(json, 'updated_user_name') ? undefined : json['updated_user_name'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'])),
    };
}

export function ResponsePosPasswordDtoToJSON(value?: ResponsePosPasswordDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'seller_id': value.seller_id,
        'created_user_name': value.created_user_name,
        'updated_user_name': value.updated_user_name,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at.toISOString()),
    };
}


