/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateAddressDto,
    CreateAddressDtoFromJSON,
    CreateAddressDtoFromJSONTyped,
    CreateAddressDtoToJSON,
    CreateBankAccountDto,
    CreateBankAccountDtoFromJSON,
    CreateBankAccountDtoFromJSONTyped,
    CreateBankAccountDtoToJSON,
    ProtocolCustomizations,
    ProtocolCustomizationsFromJSON,
    ProtocolCustomizationsFromJSONTyped,
    ProtocolCustomizationsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateSellerV2Dto
 */
export interface CreateSellerV2Dto {
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    document: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    document_ofac?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    document_onu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    document_rf?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    state_registration?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    settlement_type: CreateSellerV2DtoSettlementTypeEnum;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof CreateSellerV2Dto
     */
    address: CreateAddressDto;
    /**
     * 
     * @type {CreateBankAccountDto}
     * @memberof CreateSellerV2Dto
     */
    bank_account: CreateBankAccountDto;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    monthly_billing?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    covenant?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    is_split?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    secondary_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    tertiary_phone?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSellerV2Dto
     */
    absorb_costs?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    type: CreateSellerV2DtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    merchant_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    sale_plan_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    pix_pos?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    acquirer?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    billet_provider?: CreateSellerV2DtoBilletProviderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    is_assignor?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSellerV2Dto
     */
    no_payment_methods?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    has_split_rules?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    confirmation_payment_limit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    confirmation_payment_limit_days?: number;
    /**
     * 
     * @type {ProtocolCustomizations}
     * @memberof CreateSellerV2Dto
     */
    protocol_customizations?: ProtocolCustomizations;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    pix_provider?: CreateSellerV2DtoPixProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    secondary_email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    tertiary_email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSellerV2Dto
     */
    responsibles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    cns?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    cnae?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    assignment?: CreateSellerV2DtoAssignmentEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    owner_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    owner_document?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    owner_document_ofac?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    owner_document_onu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    owner_document_pep?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    owner_document_ref?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateSellerV2Dto
     */
    owner_birth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    frontier?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    owner_mother_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    erp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    three_ds_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    last_semestral_invoicing?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    min_three_ds?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    three_ds_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    risk_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    min_risk?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    risk_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    billet_code?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerV2Dto
     */
    card_payer_check?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    card_payer_check_min_value?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    extract_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    accreditation_status?: CreateSellerV2DtoAccreditationStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerV2Dto
     */
    celcoin_value?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerV2Dto
     */
    celcoin_fee_type?: CreateSellerV2DtoCelcoinFeeTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateSellerV2DtoSettlementTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}/**
* @export
* @enum {string}
*/
export enum CreateSellerV2DtoTypeEnum {
    Transaction = 'transaction',
    Registry = 'registry',
    Group = 'group',
    RestrictedSeller = 'restricted_seller',
    Creditor = 'creditor'
}/**
* @export
* @enum {string}
*/
export enum CreateSellerV2DtoBilletProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau'
}/**
* @export
* @enum {string}
*/
export enum CreateSellerV2DtoPixProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau'
}/**
* @export
* @enum {string}
*/
export enum CreateSellerV2DtoAssignmentEnum {
    LegalEntity = 'legal_entity',
    Civil = 'civil',
    Protest = 'protest',
    Note = 'note',
    Properties = 'properties'
}/**
* @export
* @enum {string}
*/
export enum CreateSellerV2DtoAccreditationStatusEnum {
    Active = 'active',
    ActiveAndOwnershipChange = 'active_and_ownership_change',
    Cancelled = 'cancelled',
    InactiveByOwnershipChange = 'inactive_by_ownership_change'
}/**
* @export
* @enum {string}
*/
export enum CreateSellerV2DtoCelcoinFeeTypeEnum {
    Percentage = 'percentage',
    Real = 'real'
}

export function CreateSellerV2DtoFromJSON(json: any): CreateSellerV2Dto {
    return CreateSellerV2DtoFromJSONTyped(json, false);
}

export function CreateSellerV2DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSellerV2Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'document': json['document'],
        'document_ofac': !exists(json, 'document_ofac') ? undefined : json['document_ofac'],
        'document_onu': !exists(json, 'document_onu') ? undefined : json['document_onu'],
        'document_rf': !exists(json, 'document_rf') ? undefined : json['document_rf'],
        'state_registration': !exists(json, 'state_registration') ? undefined : json['state_registration'],
        'code': json['code'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'settlement_type': json['settlement_type'],
        'address': CreateAddressDtoFromJSON(json['address']),
        'bank_account': CreateBankAccountDtoFromJSON(json['bank_account']),
        'monthly_billing': !exists(json, 'monthly_billing') ? undefined : json['monthly_billing'],
        'covenant': !exists(json, 'covenant') ? undefined : json['covenant'],
        'is_split': !exists(json, 'is_split') ? undefined : json['is_split'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'secondary_phone': !exists(json, 'secondary_phone') ? undefined : json['secondary_phone'],
        'tertiary_phone': !exists(json, 'tertiary_phone') ? undefined : json['tertiary_phone'],
        'absorb_costs': !exists(json, 'absorb_costs') ? undefined : json['absorb_costs'],
        'type': json['type'],
        'merchant_id': json['merchant_id'],
        'sale_plan_id': json['sale_plan_id'],
        'pix_pos': !exists(json, 'pix_pos') ? undefined : json['pix_pos'],
        'acquirer': !exists(json, 'acquirer') ? undefined : json['acquirer'],
        'billet_provider': !exists(json, 'billet_provider') ? undefined : json['billet_provider'],
        'is_assignor': !exists(json, 'is_assignor') ? undefined : json['is_assignor'],
        'no_payment_methods': !exists(json, 'no_payment_methods') ? undefined : json['no_payment_methods'],
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'confirmation_payment_limit': !exists(json, 'confirmation_payment_limit') ? undefined : json['confirmation_payment_limit'],
        'confirmation_payment_limit_days': !exists(json, 'confirmation_payment_limit_days') ? undefined : json['confirmation_payment_limit_days'],
        'protocol_customizations': !exists(json, 'protocol_customizations') ? undefined : ProtocolCustomizationsFromJSON(json['protocol_customizations']),
        'pix_provider': !exists(json, 'pix_provider') ? undefined : json['pix_provider'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'secondary_email': !exists(json, 'secondary_email') ? undefined : json['secondary_email'],
        'tertiary_email': !exists(json, 'tertiary_email') ? undefined : json['tertiary_email'],
        'responsibles': !exists(json, 'responsibles') ? undefined : json['responsibles'],
        'cns': !exists(json, 'cns') ? undefined : json['cns'],
        'cnae': !exists(json, 'cnae') ? undefined : json['cnae'],
        'assignment': !exists(json, 'assignment') ? undefined : json['assignment'],
        'owner_name': !exists(json, 'owner_name') ? undefined : json['owner_name'],
        'owner_document': !exists(json, 'owner_document') ? undefined : json['owner_document'],
        'owner_document_ofac': !exists(json, 'owner_document_ofac') ? undefined : json['owner_document_ofac'],
        'owner_document_onu': !exists(json, 'owner_document_onu') ? undefined : json['owner_document_onu'],
        'owner_document_pep': !exists(json, 'owner_document_pep') ? undefined : json['owner_document_pep'],
        'owner_document_ref': !exists(json, 'owner_document_ref') ? undefined : json['owner_document_ref'],
        'owner_birth': !exists(json, 'owner_birth') ? undefined : (new Date(json['owner_birth'])),
        'frontier': !exists(json, 'frontier') ? undefined : json['frontier'],
        'owner_mother_name': !exists(json, 'owner_mother_name') ? undefined : json['owner_mother_name'],
        'erp': !exists(json, 'erp') ? undefined : json['erp'],
        'three_ds_active': !exists(json, 'three_ds_active') ? undefined : json['three_ds_active'],
        'last_semestral_invoicing': !exists(json, 'last_semestral_invoicing') ? undefined : json['last_semestral_invoicing'],
        'min_three_ds': !exists(json, 'min_three_ds') ? undefined : json['min_three_ds'],
        'three_ds_value': !exists(json, 'three_ds_value') ? undefined : json['three_ds_value'],
        'risk_active': !exists(json, 'risk_active') ? undefined : json['risk_active'],
        'min_risk': !exists(json, 'min_risk') ? undefined : json['min_risk'],
        'risk_value': !exists(json, 'risk_value') ? undefined : json['risk_value'],
        'billet_code': !exists(json, 'billet_code') ? undefined : json['billet_code'],
        'card_payer_check': !exists(json, 'card_payer_check') ? undefined : json['card_payer_check'],
        'card_payer_check_min_value': !exists(json, 'card_payer_check_min_value') ? undefined : json['card_payer_check_min_value'],
        'extract_name': !exists(json, 'extract_name') ? undefined : json['extract_name'],
        'accreditation_status': !exists(json, 'accreditation_status') ? undefined : json['accreditation_status'],
        'celcoin_value': !exists(json, 'celcoin_value') ? undefined : json['celcoin_value'],
        'celcoin_fee_type': !exists(json, 'celcoin_fee_type') ? undefined : json['celcoin_fee_type'],
    };
}

export function CreateSellerV2DtoToJSON(value?: CreateSellerV2Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'document': value.document,
        'document_ofac': value.document_ofac,
        'document_onu': value.document_onu,
        'document_rf': value.document_rf,
        'state_registration': value.state_registration,
        'code': value.code,
        'created_at': value.created_at,
        'settlement_type': value.settlement_type,
        'address': CreateAddressDtoToJSON(value.address),
        'bank_account': CreateBankAccountDtoToJSON(value.bank_account),
        'monthly_billing': value.monthly_billing,
        'covenant': value.covenant,
        'is_split': value.is_split,
        'phone': value.phone,
        'secondary_phone': value.secondary_phone,
        'tertiary_phone': value.tertiary_phone,
        'absorb_costs': value.absorb_costs,
        'type': value.type,
        'merchant_id': value.merchant_id,
        'sale_plan_id': value.sale_plan_id,
        'pix_pos': value.pix_pos,
        'acquirer': value.acquirer,
        'billet_provider': value.billet_provider,
        'is_assignor': value.is_assignor,
        'no_payment_methods': value.no_payment_methods,
        'has_split_rules': value.has_split_rules,
        'confirmation_payment_limit': value.confirmation_payment_limit,
        'confirmation_payment_limit_days': value.confirmation_payment_limit_days,
        'protocol_customizations': ProtocolCustomizationsToJSON(value.protocol_customizations),
        'pix_provider': value.pix_provider,
        'email': value.email,
        'secondary_email': value.secondary_email,
        'tertiary_email': value.tertiary_email,
        'responsibles': value.responsibles,
        'cns': value.cns,
        'cnae': value.cnae,
        'assignment': value.assignment,
        'owner_name': value.owner_name,
        'owner_document': value.owner_document,
        'owner_document_ofac': value.owner_document_ofac,
        'owner_document_onu': value.owner_document_onu,
        'owner_document_pep': value.owner_document_pep,
        'owner_document_ref': value.owner_document_ref,
        'owner_birth': value.owner_birth === undefined ? undefined : (value.owner_birth.toISOString()),
        'frontier': value.frontier,
        'owner_mother_name': value.owner_mother_name,
        'erp': value.erp,
        'three_ds_active': value.three_ds_active,
        'last_semestral_invoicing': value.last_semestral_invoicing,
        'min_three_ds': value.min_three_ds,
        'three_ds_value': value.three_ds_value,
        'risk_active': value.risk_active,
        'min_risk': value.min_risk,
        'risk_value': value.risk_value,
        'billet_code': value.billet_code,
        'card_payer_check': value.card_payer_check,
        'card_payer_check_min_value': value.card_payer_check_min_value,
        'extract_name': value.extract_name,
        'accreditation_status': value.accreditation_status,
        'celcoin_value': value.celcoin_value,
        'celcoin_fee_type': value.celcoin_fee_type,
    };
}


