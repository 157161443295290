import React from 'react';

import { Box, Flex, Spacer, VStack, Text } from '@chakra-ui/react';
import { maskMoney } from 'services/masks.service';


export default ({ data, isBackoffice }) => {
	return (
		<VStack spacing='3' color='white' py='6' alignItems='flex-start'>
			<Flex direction='column'>
				<Text id='total-financial-volume-text' fontSize={['sm', null, null, null, 'md']}>
					Valor Líquido Transacionado
				</Text>
				<Text id='data-total-financial-volume-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
					R$ {data && data.total_financial_volume ? maskMoney(data.total_financial_volume / 100) : maskMoney(0)}
				</Text>
			</Flex>

			<Flex direction='column'>
				<Text id='total-gross-volume-text' fontSize={['sm', null, null, null, 'md']}>
					Valor Bruto Transacionado
				</Text>
				<Text id='data-total-gross-volume-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
					R$ {data && data.total_gross_volume ? maskMoney(data.total_gross_volume / 100) : maskMoney(0)}
				</Text>
			</Flex>

			<Flex direction='column'>
				<Text id='total-sales-number-text' fontSize={['sm', null, null, null, 'md']}>
					Número de Vendas
				</Text>
				<Text id='data-total-sales-number-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
					{data && data.total_sales_number ? data.total_sales_number : 0}
				</Text>
			</Flex>

			{isBackoffice && (
				<Flex direction='column'>
					<Text id='total-sellers-text' fontSize={['sm', null, null, null, 'md']}>
						Número de Clientes
					</Text>
					<Text id='data-total-sellers-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						{data && data.total_sellers ? data.total_sellers : 0}
					</Text>
				</Flex>
			)}

			<Flex direction='column'>
				<Text id='average-ticket-text' fontSize={['sm', null, null, null, 'md']}>
					Ticket Médio
				</Text>
				<Text id='data-average-ticket-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
					R$ {data && data.average_ticket ? maskMoney(data.average_ticket / 100) : maskMoney(0)}
				</Text>
			</Flex>

			<Flex direction='row' alignItems='flex-end' width={'100%'}>
				<Box>
					<Text id='physical-gross-volume-text' fontSize={['sm', null, null, null, 'md']}>
						Vendas Físicas (Bruto)
					</Text>
					<Text id='data-physical-gross-volume-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						R$ {data && data.physical_gross_volume ? maskMoney(data.physical_gross_volume / 100) : maskMoney(0)}
					</Text>
				</Box>
				<Spacer />
				<Box>
					<Text id='physical-financial-volume-text' fontSize={['sm', null, null, null, 'md']}>
						Vendas Físicas (Líquido)
					</Text>
					<Text id='data-physical-financial-volume-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						R$ {data && data.physical_financial_volume ? maskMoney(data.physical_financial_volume / 100) : maskMoney(0)}
					</Text>
				</Box>
			</Flex>

			<Flex direction='row' alignItems='flex-end' width={'100%'}>
				<Box>
					<Text id='online-gross-volume-text' fontSize={['sm', null, null, null, 'md']}>
						Vendas Online (Bruto)
					</Text>
					<Text id='data-online-gross-volume-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						R$ {data && data.online_gross_volume ? maskMoney(data.online_gross_volume / 100) : maskMoney(0)}
					</Text>
				</Box>
				<Spacer />
				<Box>
					<Text id='online-financial-volume-text' fontSize={['sm', null, null, null, 'md']}>
						Vendas Online (Líquido)
					</Text>
					<Text id='data-online-financial-volume-text' fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						R$ {data && data.online_financial_volume ? maskMoney(data.online_financial_volume / 100) : maskMoney(0)}
					</Text>
				</Box>
			</Flex>
		</VStack>
	);
};
