import * as yup from 'yup';

import { cpf, cnpj } from 'cpf-cnpj-validator';

yup.addMethod(yup.string, 'isCpf', function (errorMessage) {
	return this.test(`is-cpf`, errorMessage, function (value) {  
		const { createError } = this;
			
    return (value && cpf.isValid(value)) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isCnpj', function (errorMessage) {
  return this.test(`is-cnpj`, errorMessage, function (value) {
		const { createError } = this;

    return (value && cnpj.isValid(value)) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isCpfOrCnpj', function (errorMessage) {
  return this.test(`is-cpf-or-cnpj`, errorMessage, function (value) {
		const { createError } = this;

    return (value && (cpf.isValid(value) || cnpj.isValid(value))) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isPhone', function (errorMessage) {
  return this.test(`is-phone`, errorMessage, function (value) {
		const { createError } = this;

    return (value && value.replace(/\D+/gi, '').length >= 10) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isCellphone', function (errorMessage) {
  return this.test(`is-phone`, errorMessage, function (value) {
		const { createError } = this;

    return (value && value.replace(/\D+/gi, '').length >= 11) || createError({ message: errorMessage });
  });
});

yup.addMethod(yup.string, 'isCpfOrCnpjOptional', function (errorMessage) {
	return this.test(`is-cpf-or-cnpj-optional`, errorMessage, function (value) {
		const { createError } = this;

		if (!value) {
			return true;
		}

		return (value && (cpf.isValid(value) || cnpj.isValid(value))) || createError({ message: errorMessage });
	});
});

yup.addMethod(yup.string, 'isCompleteName', function (errorMessage) {
  return this.test(`is-complete-name`, errorMessage, function (value) {
    const {createError} = this;

    const trimName = value?.trim();
		const name = trimName?.split(' ');
		const valid = name && name[1] ? true : false;
    return (value && valid) || createError({ message: errorMessage })
  })
})
export default yup;