/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateMerchantDto,
    CreateMerchantDtoFromJSON,
    CreateMerchantDtoToJSON,
    CreateMerchantUserDto,
    CreateMerchantUserDtoFromJSON,
    CreateMerchantUserDtoToJSON,
    GetMerchantDto,
    GetMerchantDtoFromJSON,
    GetMerchantDtoToJSON,
    GetMerchantSalePlanDto,
    GetMerchantSalePlanDtoFromJSON,
    GetMerchantSalePlanDtoToJSON,
    GetMerchantUserDto,
    GetMerchantUserDtoFromJSON,
    GetMerchantUserDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    UpdateMerchantDto,
    UpdateMerchantDtoFromJSON,
    UpdateMerchantDtoToJSON,
    UpdateMerchantUserDto,
    UpdateMerchantUserDtoFromJSON,
    UpdateMerchantUserDtoToJSON,
} from '../models';

export interface CreateMerchantRequest {
    createMerchantDto: CreateMerchantDto;
}

export interface CreateMerchantUserRequest {
    merchantId: string;
    createMerchantUserDto: CreateMerchantUserDto;
}

export interface EditMerchantRequest {
    merchantId: string;
    updateMerchantDto: UpdateMerchantDto;
}

export interface GetMerchantRequest {
    merchantId: string;
}

export interface ListAllMerchantUsersRequest {
    limit: number;
    currentPage: number;
    sort?: ListAllMerchantUsersSortEnum;
}

export interface ListMerchantSalesPlansRequest {
    merchantId: string;
}

export interface ListMerchantUsersRequest {
    limit: number;
    currentPage: number;
    merchantId: string;
    sort?: ListMerchantUsersSortEnum;
}

export interface UpdateMerchantUserRequest {
    merchantId: string;
    userId: string;
    updateMerchantUserDto: UpdateMerchantUserDto;
}

/**
 * 
 */
export class MerchantsApi extends runtime.BaseAPI {

    /**
     * Criar Merchant
     */
    async createMerchantRaw(requestParameters: CreateMerchantRequest): Promise<runtime.ApiResponse<GetMerchantDto>> {
        if (requestParameters.createMerchantDto === null || requestParameters.createMerchantDto === undefined) {
            throw new runtime.RequiredError('createMerchantDto','Required parameter requestParameters.createMerchantDto was null or undefined when calling createMerchant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMerchantDtoToJSON(requestParameters.createMerchantDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMerchantDtoFromJSON(jsonValue));
    }

    /**
     * Criar Merchant
     */
    async createMerchant(requestParameters: CreateMerchantRequest): Promise<GetMerchantDto> {
        const response = await this.createMerchantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar usuário do merchant
     */
    async createMerchantUserRaw(requestParameters: CreateMerchantUserRequest): Promise<runtime.ApiResponse<GetMerchantUserDto>> {
        if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
            throw new runtime.RequiredError('merchantId','Required parameter requestParameters.merchantId was null or undefined when calling createMerchantUser.');
        }

        if (requestParameters.createMerchantUserDto === null || requestParameters.createMerchantUserDto === undefined) {
            throw new runtime.RequiredError('createMerchantUserDto','Required parameter requestParameters.createMerchantUserDto was null or undefined when calling createMerchantUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants/{merchantId}/users`.replace(`{${"merchantId"}}`, encodeURIComponent(String(requestParameters.merchantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMerchantUserDtoToJSON(requestParameters.createMerchantUserDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMerchantUserDtoFromJSON(jsonValue));
    }

    /**
     * Criar usuário do merchant
     */
    async createMerchantUser(requestParameters: CreateMerchantUserRequest): Promise<GetMerchantUserDto> {
        const response = await this.createMerchantUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Editar Merchant
     */
    async editMerchantRaw(requestParameters: EditMerchantRequest): Promise<runtime.ApiResponse<GetMerchantDto>> {
        if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
            throw new runtime.RequiredError('merchantId','Required parameter requestParameters.merchantId was null or undefined when calling editMerchant.');
        }

        if (requestParameters.updateMerchantDto === null || requestParameters.updateMerchantDto === undefined) {
            throw new runtime.RequiredError('updateMerchantDto','Required parameter requestParameters.updateMerchantDto was null or undefined when calling editMerchant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants/{merchantId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(requestParameters.merchantId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMerchantDtoToJSON(requestParameters.updateMerchantDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMerchantDtoFromJSON(jsonValue));
    }

    /**
     * Editar Merchant
     */
    async editMerchant(requestParameters: EditMerchantRequest): Promise<GetMerchantDto> {
        const response = await this.editMerchantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter merchant pelo ID
     */
    async getMerchantRaw(requestParameters: GetMerchantRequest): Promise<runtime.ApiResponse<GetMerchantDto>> {
        if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
            throw new runtime.RequiredError('merchantId','Required parameter requestParameters.merchantId was null or undefined when calling getMerchant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants/{merchantId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(requestParameters.merchantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMerchantDtoFromJSON(jsonValue));
    }

    /**
     * Obter merchant pelo ID
     */
    async getMerchant(requestParameters: GetMerchantRequest): Promise<GetMerchantDto> {
        const response = await this.getMerchantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lista todos usuários do tipo merchant
     */
    async listAllMerchantUsersRaw(requestParameters: ListAllMerchantUsersRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listAllMerchantUsers.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listAllMerchantUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Lista todos usuários do tipo merchant
     */
    async listAllMerchantUsers(requestParameters: ListAllMerchantUsersRequest): Promise<PaginatedDto> {
        const response = await this.listAllMerchantUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar planos de vendas do estabelecimento principal
     */
    async listMerchantSalesPlansRaw(requestParameters: ListMerchantSalesPlansRequest): Promise<runtime.ApiResponse<Array<GetMerchantSalePlanDto>>> {
        if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
            throw new runtime.RequiredError('merchantId','Required parameter requestParameters.merchantId was null or undefined when calling listMerchantSalesPlans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants/{merchantId}/sales-plans`.replace(`{${"merchantId"}}`, encodeURIComponent(String(requestParameters.merchantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetMerchantSalePlanDtoFromJSON));
    }

    /**
     * Listar planos de vendas do estabelecimento principal
     */
    async listMerchantSalesPlans(requestParameters: ListMerchantSalesPlansRequest): Promise<Array<GetMerchantSalePlanDto>> {
        const response = await this.listMerchantSalesPlansRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lista os usuários do merchant
     */
    async listMerchantUsersRaw(requestParameters: ListMerchantUsersRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listMerchantUsers.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listMerchantUsers.');
        }

        if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
            throw new runtime.RequiredError('merchantId','Required parameter requestParameters.merchantId was null or undefined when calling listMerchantUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants/{merchantId}/users`.replace(`{${"merchantId"}}`, encodeURIComponent(String(requestParameters.merchantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Lista os usuários do merchant
     */
    async listMerchantUsers(requestParameters: ListMerchantUsersRequest): Promise<PaginatedDto> {
        const response = await this.listMerchantUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar estabelecimentos principais
     */
    async listMerchantsRaw(): Promise<runtime.ApiResponse<Array<GetMerchantDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetMerchantDtoFromJSON));
    }

    /**
     * Listar estabelecimentos principais
     */
    async listMerchants(): Promise<Array<GetMerchantDto>> {
        const response = await this.listMerchantsRaw();
        return await response.value();
    }

    /**
     * Atualizar usuário do merchant
     */
    async updateMerchantUserRaw(requestParameters: UpdateMerchantUserRequest): Promise<runtime.ApiResponse<GetMerchantUserDto>> {
        if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
            throw new runtime.RequiredError('merchantId','Required parameter requestParameters.merchantId was null or undefined when calling updateMerchantUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateMerchantUser.');
        }

        if (requestParameters.updateMerchantUserDto === null || requestParameters.updateMerchantUserDto === undefined) {
            throw new runtime.RequiredError('updateMerchantUserDto','Required parameter requestParameters.updateMerchantUserDto was null or undefined when calling updateMerchantUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/merchants/{merchantId}/users/{userId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(requestParameters.merchantId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMerchantUserDtoToJSON(requestParameters.updateMerchantUserDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMerchantUserDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar usuário do merchant
     */
    async updateMerchantUser(requestParameters: UpdateMerchantUserRequest): Promise<GetMerchantUserDto> {
        const response = await this.updateMerchantUserRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListAllMerchantUsersSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListMerchantUsersSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
