const initialValues = {
	name: '',
	description: '',
	options: [
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 1.1,
			description: 'Boleto Gerado',
			type: 'billet',
			type_status: 'pending',
			installments: 1,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 1.1,
			description: 'Boleto Pago',
			type: 'billet',
			type_status: 'paid',
			installments: 1,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0.25,
			description: 'PIX Gerado',
			type: 'pix',
			type_status: 'pending',
			installments: 1,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0.25,
			description: 'PIX Pago',
			type: 'pix',
			type_status: 'paid',
			installments: 1,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Débito',
			type: 'debit',
			installments: 1,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 0.3,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 0,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 1x',
			type: 'credit',
			installments: 1,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 1.6,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 0.74,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 2x',
			type: 'credit',
			installments: 2,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.14,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 1.1,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 3x',
			type: 'credit',
			installments: 3,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.14,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 1.47,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 4x',
			type: 'credit',
			installments: 4,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.14,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 1.83,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 5x',
			type: 'credit',
			installments: 5,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.14,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 2.19,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 6x',
			type: 'credit',
			installments: 6,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.14,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 2.55,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 7x',
			type: 'credit',
			installments: 7,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.54,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 2.88,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 8x',
			type: 'credit',
			installments: 8,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.54,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 3.24,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 9x',
			type: 'credit',
			installments: 9,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.54,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 3.59,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 10x',
			type: 'credit',
			installments: 10,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.54,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 3.94,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 11x',
			type: 'credit',
			installments: 11,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.54,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 4.28,
			is_cap: false,
		},
		{
			fixed_amount: 0.17,
			bank_fixed_amount: 0,
			description: 'Crédito 12x',
			type: 'credit',
			installments: 12,
			brand: 'default',
			brand_fee_type: 'percent',
			brand_fee: 2.54,
			markup_fee_type: 'percent',
			markup_fee: 0,
			exchange_fee_type: 'percent',
			exchange_fee: 0,
			anticipation_fee_type: 'percent',
			anticipation_fee: 4.63,
			is_cap: false,
		},
	],
};

export default initialValues;
