/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetCostOptionDto,
    GetCostOptionDtoFromJSON,
    GetCostOptionDtoFromJSONTyped,
    GetCostOptionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCostPlanDto
 */
export interface GetCostPlanDto {
    /**
     * 
     * @type {string}
     * @memberof GetCostPlanDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostPlanDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostPlanDto
     */
    description: string;
    /**
     * 
     * @type {Array<GetCostOptionDto>}
     * @memberof GetCostPlanDto
     */
    options?: Array<GetCostOptionDto>;
}

export function GetCostPlanDtoFromJSON(json: any): GetCostPlanDto {
    return GetCostPlanDtoFromJSONTyped(json, false);
}

export function GetCostPlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostPlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(GetCostOptionDtoFromJSON)),
    };
}

export function GetCostPlanDtoToJSON(value?: GetCostPlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(GetCostOptionDtoToJSON)),
    };
}


