import React from 'react';

import { InputProps } from '@chakra-ui/react';
import { Field } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { NumberInputProps } from '../NumberInput';

import Input from '../Input';

type FormDebouncedInputProps = InputProps &
	NumberInputProps & {
		as?: React.FC;
		debounce?: number;
		afterDebounce?: (value: string) => void;
		maxWidth?: string;
		id: string
	};

const FormDebouncedInput: React.FC<FormDebouncedInputProps> = (props: FormDebouncedInputProps) => {
	let Component = Input;

	if (props.as) {
		Component = props.as;
	}
	const { as, afterDebounce, debounce, ...fieldProps } = props;

	const debounced = useDebouncedCallback(async (value) => {
		props.afterDebounce && props.afterDebounce(value);
	}, props.debounce || 1000);

	React.useEffect(
		() => () => {
			debounced.flush();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debounced]
	);

	return (
		<Field {...fieldProps}>
			{({ form, field }) => {
				return (
					<Component
						{...field}
						{...fieldProps}
						onBlur={form.handleBlur}
						onChange={(e) => {
							props.onChange && props.onChange(e);
							form.setFieldValue(field.name, e.target.value);

							debounced.callback(e.target.value);
						}}
						borderColor='darkGrey'
						max-width={props.maxWidth || ''}
						onKeyDown={(e) => {
							if (/^[a-zA-Z]$/.test(e.key)) {
									e.preventDefault();
						}
					}}
					/>
				);
			}}
		</Field>
	);
};

export default FormDebouncedInput;
