import { Flex, IconButton } from '@chakra-ui/react';
import { useMonthlyCalendar } from '@zach.codes/react-calendar';
import React from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { format, addMonths, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Title from 'components/Title';
import { useTheme } from 'contexts/ThemeProvider';

const MonthlyNav: React.FC = () => {
	const { currentMonth, onCurrentMonthChange } = useMonthlyCalendar();
	const { theme } = useTheme();

	return (
		<Flex justifyContent='space-between'>
			<Flex alignItems='flex-end'>
				<Title id='current-month-title-text' fontWeight='bold'>
					{format(currentMonth, 'LLLL yyyy', { locale: ptBR }).toUpperCase()}
				</Title>
			</Flex>

			<Flex>
				<IconButton
					id='current-month-change-sub-icon-button'
					aria-label='left arrow'
					variant='outline'
					borderWidth={2}
					borderColor={theme?.primary_color || 'black'}
					borderRadius='5px'
					mr='3'
					icon={<MdKeyboardArrowLeft color={theme?.primary_color || 'black'} size='1.8rem' />}
					_hover={{ opacity: 0.5 }}
					onClick={() => onCurrentMonthChange(subMonths(currentMonth, 1))}
				/>

				<IconButton
					id='current-month-change-add-icon-button'
					aria-label='left arrow'
					variant='outline'
					borderWidth={2}
					borderColor={theme?.primary_color || 'black'}
					borderRadius='5px'
					icon={<MdKeyboardArrowRight color={theme?.primary_color || 'black'} size='1.8rem' />}
					_hover={{ opacity: 0.5 }}
					onClick={() => onCurrentMonthChange(addMonths(currentMonth, 1))}
				/>
			</Flex>
		</Flex>
	);
};

export default MonthlyNav;
