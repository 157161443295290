/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SellerCheckout,
    SellerCheckoutFromJSON,
    SellerCheckoutFromJSONTyped,
    SellerCheckoutToJSON,
} from './';

/**
 * 
 * @export
 * @interface SellerCheckouts
 */
export interface SellerCheckouts {
    /**
     * 
     * @type {SellerCheckout}
     * @memberof SellerCheckouts
     */
    billet?: SellerCheckout;
    /**
     * 
     * @type {SellerCheckout}
     * @memberof SellerCheckouts
     */
    pix?: SellerCheckout;
    /**
     * 
     * @type {SellerCheckout}
     * @memberof SellerCheckouts
     */
    credit_card?: SellerCheckout;
}

export function SellerCheckoutsFromJSON(json: any): SellerCheckouts {
    return SellerCheckoutsFromJSONTyped(json, false);
}

export function SellerCheckoutsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerCheckouts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billet': !exists(json, 'billet') ? undefined : SellerCheckoutFromJSON(json['billet']),
        'pix': !exists(json, 'pix') ? undefined : SellerCheckoutFromJSON(json['pix']),
        'credit_card': !exists(json, 'credit_card') ? undefined : SellerCheckoutFromJSON(json['credit_card']),
    };
}

export function SellerCheckoutsToJSON(value?: SellerCheckouts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billet': SellerCheckoutToJSON(value.billet),
        'pix': SellerCheckoutToJSON(value.pix),
        'credit_card': SellerCheckoutToJSON(value.credit_card),
    };
}


