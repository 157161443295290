/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateProtestLinkDto,
    CreateProtestLinkDtoFromJSON,
    CreateProtestLinkDtoToJSON,
    CreateSellerProtestLinkDto,
    CreateSellerProtestLinkDtoFromJSON,
    CreateSellerProtestLinkDtoToJSON,
    GetProtestLinkDto,
    GetProtestLinkDtoFromJSON,
    GetProtestLinkDtoToJSON,
    UpdateProtestLinkDto,
    UpdateProtestLinkDtoFromJSON,
    UpdateProtestLinkDtoToJSON,
} from '../models';

export interface CancelProtestLinkRequest {
    sellerId: string;
    protestLinkId: string;
}

export interface CreateProtestLinkRequest {
    createProtestLinkDto: CreateProtestLinkDto;
}

export interface CreateProtestLinkV2Request {
    sellerId: string;
    createSellerProtestLinkDto: CreateSellerProtestLinkDto;
}

export interface GetProtestLinkByTokenRequest {
    token: string;
}

export interface SentProtestLinkReceiptMailRequest {
    token: string;
}

export interface UpdateProtestLinkRequest {
    protestLinkId: string;
    updateProtestLinkDto: UpdateProtestLinkDto;
}

/**
 * 
 */
export class ProtestLinksApi extends runtime.BaseAPI {

    /**
     * Cancelar um link de protesto
     */
    async cancelProtestLinkRaw(requestParameters: CancelProtestLinkRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling cancelProtestLink.');
        }

        if (requestParameters.protestLinkId === null || requestParameters.protestLinkId === undefined) {
            throw new runtime.RequiredError('protestLinkId','Required parameter requestParameters.protestLinkId was null or undefined when calling cancelProtestLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/protest-links/sellers/{sellerId}/{protestLinkId}/cancel`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"protestLinkId"}}`, encodeURIComponent(String(requestParameters.protestLinkId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancelar um link de protesto
     */
    async cancelProtestLink(requestParameters: CancelProtestLinkRequest): Promise<void> {
        await this.cancelProtestLinkRaw(requestParameters);
    }

    /**
     * Em alternativa a esta rota, utilize a v2.
     * Gerar link de protesto para o pagador de um estabelecimento
     */
    async createProtestLinkRaw(requestParameters: CreateProtestLinkRequest): Promise<runtime.ApiResponse<GetProtestLinkDto>> {
        if (requestParameters.createProtestLinkDto === null || requestParameters.createProtestLinkDto === undefined) {
            throw new runtime.RequiredError('createProtestLinkDto','Required parameter requestParameters.createProtestLinkDto was null or undefined when calling createProtestLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protest-links`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProtestLinkDtoToJSON(requestParameters.createProtestLinkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtestLinkDtoFromJSON(jsonValue));
    }

    /**
     * Em alternativa a esta rota, utilize a v2.
     * Gerar link de protesto para o pagador de um estabelecimento
     */
    async createProtestLink(requestParameters: CreateProtestLinkRequest): Promise<GetProtestLinkDto> {
        const response = await this.createProtestLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar link de protesto para o pagador de um estabelecimento
     */
    async createProtestLinkV2Raw(requestParameters: CreateProtestLinkV2Request): Promise<runtime.ApiResponse<GetProtestLinkDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createProtestLinkV2.');
        }

        if (requestParameters.createSellerProtestLinkDto === null || requestParameters.createSellerProtestLinkDto === undefined) {
            throw new runtime.RequiredError('createSellerProtestLinkDto','Required parameter requestParameters.createSellerProtestLinkDto was null or undefined when calling createProtestLinkV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/protest-links/seller/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerProtestLinkDtoToJSON(requestParameters.createSellerProtestLinkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtestLinkDtoFromJSON(jsonValue));
    }

    /**
     * Gerar link de protesto para o pagador de um estabelecimento
     */
    async createProtestLinkV2(requestParameters: CreateProtestLinkV2Request): Promise<GetProtestLinkDto> {
        const response = await this.createProtestLinkV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar os dados do link de protesto informado
     */
    async getProtestLinkByTokenRaw(requestParameters: GetProtestLinkByTokenRequest): Promise<runtime.ApiResponse<GetProtestLinkDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getProtestLinkByToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/protest-links/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtestLinkDtoFromJSON(jsonValue));
    }

    /**
     * Retornar os dados do link de protesto informado
     */
    async getProtestLinkByToken(requestParameters: GetProtestLinkByTokenRequest): Promise<GetProtestLinkDto> {
        const response = await this.getProtestLinkByTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reenviar recibo para o email do pagador
     */
    async sentProtestLinkReceiptMailRaw(requestParameters: SentProtestLinkReceiptMailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling sentProtestLinkReceiptMail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/protest-links/{token}/receipt`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reenviar recibo para o email do pagador
     */
    async sentProtestLinkReceiptMail(requestParameters: SentProtestLinkReceiptMailRequest): Promise<void> {
        await this.sentProtestLinkReceiptMailRaw(requestParameters);
    }

    /**
     * Atualizar um link de protesto
     */
    async updateProtestLinkRaw(requestParameters: UpdateProtestLinkRequest): Promise<runtime.ApiResponse<GetProtestLinkDto>> {
        if (requestParameters.protestLinkId === null || requestParameters.protestLinkId === undefined) {
            throw new runtime.RequiredError('protestLinkId','Required parameter requestParameters.protestLinkId was null or undefined when calling updateProtestLink.');
        }

        if (requestParameters.updateProtestLinkDto === null || requestParameters.updateProtestLinkDto === undefined) {
            throw new runtime.RequiredError('updateProtestLinkDto','Required parameter requestParameters.updateProtestLinkDto was null or undefined when calling updateProtestLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/protest-links/{protestLinkId}/update`.replace(`{${"protestLinkId"}}`, encodeURIComponent(String(requestParameters.protestLinkId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProtestLinkDtoToJSON(requestParameters.updateProtestLinkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtestLinkDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar um link de protesto
     */
    async updateProtestLink(requestParameters: UpdateProtestLinkRequest): Promise<GetProtestLinkDto> {
        const response = await this.updateProtestLinkRaw(requestParameters);
        return await response.value();
    }

}
