import * as React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router-dom';

import { useAuth } from '../contexts/AuthProvider';

const ProtectedRoute = (routeProps: RouteProps) => {
	const Component: any = routeProps.component;
	const { signed } = useAuth();
	const rest = { ...routeProps };

	delete rest.component;

	return (
		<Route
			{...rest}
			render={(props) => {
				return signed ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
			}}
		/>
	);
};

export default ProtectedRoute;
