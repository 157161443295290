import React from 'react';

import { Input as DefaultInput, InputProps } from '@chakra-ui/react';
import { useTheme } from 'contexts/ThemeProvider';

type DefaultInputInputProps = InputProps & {
	id: string;
};

const Input: React.FC<DefaultInputInputProps> = React.forwardRef((props: DefaultInputInputProps, ref) => {
	const { theme } = useTheme();
	return (
	<DefaultInput color={theme?.text_color || `darkGrey`} fontSize={`md`} size={`md`} rounded={`md`} borderColor='defaultGrey' borderWidth={1} bgColor={`transparent`} {...props} />
)});

export default Input;
