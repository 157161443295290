import React from 'react';

import { Button, Icon } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';
import { ImBlocked } from 'react-icons/im';

import { FiEdit } from 'react-icons/fi';

export default ({
	data,
	setPagination,
	pagination,
	totalPages,
	handleOpenUserModal,
	showMerchantColumn,
	currentUser,
	handleOpenUserPermissionModal,
}) => {
	const columns = React.useMemo(
		() => {
			let cols: any = [];

			if (showMerchantColumn) {
				cols.push({
					Header: ({ column }) => <HeaderTitle id='merchant-title' column={column}>Merchant</HeaderTitle>,
					accessor: 'merchant',
					Cell: ({ cell: { row, column } }) => {
						return (
							<CellContent id={`${row.index}-${column.id}-cell`} textAlign={`center`} color={`darkGrey`}>
								{row?.original?.merchant?.description}
							</CellContent>
						);
					},
				});
			}
			return cols.concat([
				{
					Header: ({ column }) => <HeaderTitle id='name-title' column={column}>Nome</HeaderTitle>,
					accessor: 'name',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContent id={`${row.index}-${column.id}-cell`} textAlign={`center`} color={`darkGrey`}>
							{value}
						</CellContent>
					),
				},
				{
					Header: ({ column }) => <HeaderTitle id='email-title' column={column}>E-mail</HeaderTitle>,
					accessor: 'email',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContent id={`${row.index}-${column.id}-cell`} textAlign={`center`} color={`darkGrey`}>
							{value}
						</CellContent>
					),
				},
				{
					Header: ({ column }) => <HeaderTitle id='role-name-title' column={column}>Perfil</HeaderTitle>,
					accessor: 'role.name',
					Cell: ({ cell: { row, column } }) => (
						<CellContent
						id={`${row.index}-${column.id}-cell`}
							onClick={() => {
								handleOpenUserPermissionModal(row.original.role);
							}}
							cursor='pointer'
							textAlign={`center`}
							color={`darkGrey`}
						>
							{row.original?.role?.name}
							<Icon id={`${row.index}-${column.id}-icon`} ml='2' as={InfoOutlineIcon} />
						</CellContent>
					),
				},
				{
					Header: ({ column }) => <HeaderTitle id='status-title' column={column}>Desabilitado</HeaderTitle>,
					accessor: 'status',
					Cell: ({ cell: { value, row, column } }) => <CellContent id={`${row.index}-${column.id}-cell`}>{value !== 'active' ? <ImBlocked fontSize='20' /> : ''}</CellContent>,
				},
				{
					Header: ({ column }) => <HeaderTitle id='edit-id-title' column={column}>Editar</HeaderTitle>,
					accessor: 'id',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContent id={`${row.index}-${column.id}-cell`}>
							<Button
								id={`${row.index}-${column.id}-button`}
								isDisabled={currentUser.id === row.original.id}
								background={'transparent'}
								onClick={() => {
									handleOpenUserModal(row.original);
								}}
							>
								<Icon id={`${row.index}-${column.id}-icon`} as={FiEdit} boxSize={5} />
							</Button>
						</CellContent>
					),
				},
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <SimpleTable columns={columns} data={data} setPagination={setPagination} pagination={pagination} totalPages={totalPages} pl={2} />;
};
