/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountBankAccount
 */
export interface DiscountBankAccount {
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    owner_document: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    bank_code: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    agreement_code: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    agency_number: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    agency_digit?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    account_number: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    account_digit: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    type_account: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    pix_key?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    wallet_number: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    layout_number: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountBankAccount
     */
    movement_code: string;
    /**
     * 
     * @type {Date}
     * @memberof DiscountBankAccount
     */
    created_at: Date;
    /**
     * 
     * @type {number}
     * @memberof DiscountBankAccount
     */
    created_at_time: number;
    /**
     * 
     * @type {Date}
     * @memberof DiscountBankAccount
     */
    updated_at?: Date;
    /**
     * 
     * @type {number}
     * @memberof DiscountBankAccount
     */
    omie_id?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountBankAccount
     */
    omie_sales_id?: number;
}

export function DiscountBankAccountFromJSON(json: any): DiscountBankAccount {
    return DiscountBankAccountFromJSONTyped(json, false);
}

export function DiscountBankAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountBankAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner_document': json['owner_document'],
        'bank_code': json['bank_code'],
        'agreement_code': json['agreement_code'],
        'agency_number': json['agency_number'],
        'agency_digit': !exists(json, 'agency_digit') ? undefined : json['agency_digit'],
        'account_number': json['account_number'],
        'account_digit': json['account_digit'],
        'type_account': json['type_account'],
        'pix_key': !exists(json, 'pix_key') ? undefined : json['pix_key'],
        'wallet_number': json['wallet_number'],
        'layout_number': json['layout_number'],
        'movement_code': json['movement_code'],
        'created_at': (new Date(json['created_at'])),
        'created_at_time': json['created_at_time'],
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'omie_id': !exists(json, 'omie_id') ? undefined : json['omie_id'],
        'omie_sales_id': !exists(json, 'omie_sales_id') ? undefined : json['omie_sales_id'],
    };
}

export function DiscountBankAccountToJSON(value?: DiscountBankAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner_document': value.owner_document,
        'bank_code': value.bank_code,
        'agreement_code': value.agreement_code,
        'agency_number': value.agency_number,
        'agency_digit': value.agency_digit,
        'account_number': value.account_number,
        'account_digit': value.account_digit,
        'type_account': value.type_account,
        'pix_key': value.pix_key,
        'wallet_number': value.wallet_number,
        'layout_number': value.layout_number,
        'movement_code': value.movement_code,
        'created_at': (value.created_at.toISOString()),
        'created_at_time': value.created_at_time,
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
        'omie_id': value.omie_id,
        'omie_sales_id': value.omie_sales_id,
    };
}


