/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExportSellersListDto
 */
export interface CreateExportSellersListDto {
    /**
     * Dia da atualização
     * @type {boolean}
     * @memberof CreateExportSellersListDto
     */
    updated_day?: boolean;
    /**
     * Usuário logado
     * @type {string}
     * @memberof CreateExportSellersListDto
     */
    created_by_user_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSellersListDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSellersListDto
     */
    document?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSellersListDto
     */
    cns?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSellersListDto
     */
    code?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSellersListDto
     */
    status?: Array<CreateExportSellersListDtoStatusEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSellersListDto
     */
    extension?: CreateExportSellersListDtoExtensionEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateExportSellersListDtoStatusEnum {
    Activated = 'activated',
    Inactive = 'inactive',
    Pending = 'pending',
    ActivationSent = 'activation_sent'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSellersListDtoExtensionEnum {
    Xls = 'xls',
    Csv = 'csv',
    Json = 'json',
    Pdf = 'pdf'
}

export function CreateExportSellersListDtoFromJSON(json: any): CreateExportSellersListDto {
    return CreateExportSellersListDtoFromJSONTyped(json, false);
}

export function CreateExportSellersListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExportSellersListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updated_day': !exists(json, 'updated_day') ? undefined : json['updated_day'],
        'created_by_user_name': !exists(json, 'created_by_user_name') ? undefined : json['created_by_user_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'document': !exists(json, 'document') ? undefined : json['document'],
        'cns': !exists(json, 'cns') ? undefined : json['cns'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
    };
}

export function CreateExportSellersListDtoToJSON(value?: CreateExportSellersListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updated_day': value.updated_day,
        'created_by_user_name': value.created_by_user_name,
        'name': value.name,
        'document': value.document,
        'cns': value.cns,
        'code': value.code,
        'status': value.status,
        'extension': value.extension,
    };
}


