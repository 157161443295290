/* eslint-disable camelcase */
import React from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { BreadcrumbItem, BreadcrumbLink, Button, Flex, HStack, Spacer, Stack } from '@chakra-ui/react';

import Loader from '../../containers/Loader';

import {
	EditsellerRiskRequest,
	EditSellerRequest,
	GetMerchantDto,
	GetResponsibleDto,
	GetSalePlanDto,
	GetSellerDto,
	GetSellerRiskDto,
	ListResponsibleTypeEnum,
	MerchantsApi,
	ResponsibleApi,
	ResponsibleTypeEnum,
	SalesPlansApi,
	SellerRisksApi,
	SellersApi,
	ListSalePlanRequest,
	ListSalePlanModeEnum,
	CreateSellerRiskRequest,
} from '../../clients';
import { getApiAuthConfig } from '../../services/api.service';

import { useCurrentSeller } from 'contexts/SellerProvider';

import Paper from '../../containers/Paper';
import Timeline from 'components/Timeline';
import DataForm from './components/DataForm';
import Breadcrumb from 'components/Breadcrumb';
import { useAuth } from 'contexts/AuthProvider';
import SettingsForm from './components/SettingsForm';
import editSellerSchema from './components/Validation';
import RiskForm from './components/RiskForm';
import SalePlanForm from './components/SalePlanForm';

import { useHistory } from 'react-router-dom';
import Text from '../../components/Text';

const EditSeller: React.FC = () => {
	const { id } = useParams();
	const history = useHistory();
	const { addToast } = useToasts();
	const { currentSeller, updateCurrentSeller } = useCurrentSeller();
	const { isBackoffice } = useAuth();

	const [isLoading, setIsLoading] = React.useState(false);
	const [merchants, setMerchants] = React.useState<GetMerchantDto[]>([]);
	const [salePlans, setSalePlans] = React.useState<GetSalePlanDto[]>([]);
	const [seller, setSeller] = React.useState<GetSellerDto | undefined>(undefined);
	const [sellerRisk, setSellerRisk] = React.useState<GetSellerRiskDto | undefined>(undefined);
	const [stage, setStage] = React.useState<number>(0);
	const [responsibleSupports, setResponsibleSupports] = React.useState<GetResponsibleDto[]>([]);
	const [responsibleCommercials, setResponsibleCommercials] = React.useState<GetResponsibleDto[]>([]);
	const [isSalePlanConfirmed, setIsSalePlanConfirmed] = React.useState<boolean>(false);

	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const merchantsApi = new MerchantsApi(apiConfig);
	const salesPlansApi = new SalesPlansApi(apiConfig);
	const responsibleApi = new ResponsibleApi(apiConfig);
	const sellerRisksApi = new SellerRisksApi(apiConfig);

	const stages = [
		{ title: 'Dados', text: '1', isEnabled: true, config: { ml: -1 } },
		{ title: 'Configurações', text: '2', isEnabled: true, config: { ml: -6 } },
		{ title: 'Riscos', text: '3', isEnabled: true, config: { ml: -2 } },
		{ title: 'Plano de venda', text: '4', isEnabled: true, config: { ml: -8 } },
	];

	const editSellerInitialValues = {
		name: seller?.name,
		description: seller?.description,
		document: seller?.document,
		state_registration: seller?.state_registration,
		cns: seller?.cns,
		cnae: seller?.cnae,
		code: seller?.code,
		assignment: seller?.assignment,
		email: seller?.email,
		secondary_email: seller?.secondary_email,
		tertiary_email: seller?.tertiary_email,
		created_at: seller?.created_at || new Date().toISOString(),
		settlement_type: seller?.settlement_type,
		has_split_rules: seller?.has_split_rules ? 'true' : 'false',
		address: {
			zipcode: seller?.address.zipcode,
			street: seller?.address.street,
			number: seller?.address.number,
			complement: seller?.address.complement,
			district: seller?.address.district,
			state: seller?.address.state,
			city: seller?.address.city,
		},
		bank_account: {
			bank_code: seller?.bank_account.bank_code,
			agency_number: seller?.bank_account.agency_number,
			agency_digit: seller?.bank_account.agency_digit,
			account_number: seller?.bank_account.account_number,
			account_digit: seller?.bank_account.account_digit,
			type_account: seller?.bank_account.type_account,
			pix_key: seller?.bank_account.pix_key,
		},
		monthly_billing: seller?.monthly_billing,
		erp: seller?.erp,
		covenant: seller?.covenant,
		is_split: seller?.is_split,
		phone: seller?.phone,
		secondary_phone: seller?.secondary_phone,
		tertiary_phone: seller?.tertiary_phone,
		absorb_costs: seller?.absorb_costs,
		status: seller?.status,
		type: seller?.type,
		merchant_id: seller?.merchant.id,
		sale_plan_id: seller?.sale_plan.id,
		pix_pos: seller?.pix_pos ? 'true' : 'false',
		billet_provider: seller?.billet_provider,
		is_assignor: seller?.is_assignor ? 'true' : 'false',
		pix_provider: seller?.pix_provider,
		no_payment_methods: seller?.no_payment_methods,
		owner_name: seller?.owner_name,
		owner_document: seller?.owner_document,
		responsible: {
			support: seller?.responsibles?.find((responsible) => responsible.type === ResponsibleTypeEnum.Support)?.id,
			commercial: seller?.responsibles?.find((responsible) => responsible.type === ResponsibleTypeEnum.Commercial)?.id,
		},
		document_ofac: seller?.document_ofac ? 'true' : 'false',
		document_onu: seller?.document_onu ? 'true' : 'false',
		document_rf: seller?.document_rf ? 'true' : 'false',
		owner_document_ofac: seller?.owner_document_ofac ? 'true' : 'false',
		owner_document_onu: seller?.owner_document_onu ? 'true' : 'false',
		owner_document_pep: seller?.owner_document_pep ? 'true' : 'false',
		owner_document_ref: seller?.owner_document_ref ? 'true' : 'false',
		frontier: seller?.frontier ? 'true' : 'false',
		owner_birth: seller?.owner_birth ? new Date(seller.owner_birth) : undefined,
		three_ds_active: seller?.three_ds_active ? 'true' : 'false',
		min_three_ds: seller?.min_three_ds ? (seller.min_three_ds / 100).toFixed(2) : 0,
		three_ds_value: seller?.three_ds_value ? (seller.three_ds_value / 100).toFixed(2) : 0,
		risk_active: seller?.risk_active ? 'true' : 'false',
		min_risk: seller && seller.min_risk ? (seller.min_risk / 100).toFixed(2) : 0,
		risk_value: seller?.risk_value ? (seller.risk_value / 100).toFixed(2) : 0,
		risk_classification: sellerRisk?.classification,
		negative_media: sellerRisk?.negative_media ? 'true' : 'false',
		lawsuits: sellerRisk?.lawsuits ? 'true' : 'false',
		note: sellerRisk?.note,
		attachments: sellerRisk?.attachments.map(({ original_name, ...fields }) => ({ name: original_name, ...fields })) || [],
		billet_code: seller?.billet_code ? 'true' : 'false',
		card_payer_check: seller?.card_payer_check ? 'true' : 'false',
		card_payer_check_min_value: seller && seller.card_payer_check_min_value ? (seller?.card_payer_check_min_value / 100).toFixed(2) : 0,
		extract_name: seller?.extract_name,
		accreditation_status: seller?.accreditation_status,
		celcoin_value: seller?.celcoin_value ? seller?.celcoin_value / 100 : 0,
		celcoin_fee_type: seller?.celcoin_fee_type,
		pos_android_cancel: seller?.pos_android_cancel ? 'true' : 'false',
		pos_android_absorb_costs: seller?.pos_android_absorb_costs ? 'true' : 'false',
		payment_provider: seller?.payment_provider ? seller.payment_provider : 'adyen'
	};

	const getMerchants = async () => {
		try {
			const response = await merchantsApi.listMerchants();

			setMerchants(response);
		} catch (error) {
			addToast('Erro ao buscar Merchant', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	const getSalesPlan = async () => {
		try {
			const requestParams: ListSalePlanRequest = {
				mode: ListSalePlanModeEnum.Raw,
			};

			const response = await salesPlansApi.listSalePlan(requestParams);

			setSalePlans(response);
		} catch (error) {
			addToast('Erro ao buscar planos de venda', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	const getSeller = async () => {
		try {
			const response = await sellersApi.getSeller({ sellerId: id });

			setSeller(response);
		} catch (error) {
			addToast('Erro ao buscar planos de venda', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	const getResponsibleSupports = async () => {
		setIsLoading(true);

		try {
			const response = await responsibleApi.listResponsible({
				type: ListResponsibleTypeEnum.Support,
				isActive: true,
			});

			setResponsibleSupports(response);
		} catch (error) {
			addToast('Erro ao buscar responsáveis pelo suporte', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getResponsibleCommercials = async () => {
		setIsLoading(true);

		try {
			const response = await responsibleApi.listResponsible({
				type: ListResponsibleTypeEnum.Commercial,
				isActive: true,
			});

			setResponsibleCommercials(response);
		} catch (error) {
			addToast('Erro ao buscar responsáveis pelo comercial', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getSellerRisk = async () => {
		setIsLoading(true);

		try {
			const response = await sellerRisksApi.getSellerRiskBySellerId({
				sellerId: id,
			});

			setSellerRisk(response);
		} catch (error) {
			addToast('Erro ao buscar risco', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const fetchData = async () => {
		setIsLoading(true);

		await Promise.all([
			getSeller(),
			getSalesPlan(),
			getMerchants(),
			getResponsibleSupports(),
			getResponsibleCommercials(),
			getSellerRisk(),
		]);

		setIsLoading(false);
	};

	React.useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEditSeller = async (values) => {
		const {
			absorb_costs,
			no_payment_methods,
			pix_pos,
			has_split_rules,
			is_assignor,
			code,
			responsible,
			document_ofac,
			document_onu,
			document_rf,
			owner_document_ofac,
			owner_document_onu,
			owner_document_pep,
			owner_document_ref,
			frontier,
			owner_birth,
			three_ds_active,
			min_three_ds,
			three_ds_value,
			risk_active,
			min_risk,
			risk_value,
			billet_code,
			card_payer_check,
			card_payer_check_min_value,
			celcoin_value,
			pos_android_cancel,
			pos_android_absorb_costs,
			...rest
		} = values;
		const { support, commercial } = responsible;

		setIsLoading(true);

		try {
			const requestParams: EditSellerRequest = {
				editSellerDto: {
					absorb_costs,
					pix_pos: pix_pos === 'true',
					no_payment_methods,
					has_split_rules: has_split_rules === 'true',
					is_assignor: typeof is_assignor === 'string' ? is_assignor === 'true' : is_assignor,
					risk_active: typeof risk_active === 'string' ? risk_active === 'true' : risk_active,
					min_risk: Math.trunc(min_risk * 100),
					code: code.trim(),
					responsibles: [support, commercial],
					three_ds_active: three_ds_active === 'true',
					min_three_ds: Math.trunc(min_three_ds * 100),
					three_ds_value: Math.trunc(three_ds_value * 100),
					risk_value: Math.trunc(risk_value * 100),
					card_payer_check_min_value: Math.trunc(card_payer_check_min_value * 100),
					billet_code: billet_code === 'true',
					card_payer_check: card_payer_check === 'true',
					celcoin_value: Math.trunc(celcoin_value * 100),
					pos_android_cancel: pos_android_cancel === 'true',
					pos_android_absorb_costs: pos_android_absorb_costs === 'true',
					...rest,
				},
				sellerId: id,
			};

			if (document_ofac) {
				requestParams.editSellerDto.document_ofac = document_ofac === 'true';
			}

			if (document_onu) {
				requestParams.editSellerDto.document_onu = document_onu === 'true';
			}

			if (document_rf) {
				requestParams.editSellerDto.document_rf = document_rf === 'true';
			}

			if (owner_document_ofac) {
				requestParams.editSellerDto.owner_document_ofac = owner_document_ofac === 'true';
			}

			if (owner_document_onu) {
				requestParams.editSellerDto.owner_document_onu = owner_document_onu === 'true';
			}

			if (owner_document_pep) {
				requestParams.editSellerDto.owner_document_pep = owner_document_pep === 'true';
			}

			if (owner_document_ref) {
				requestParams.editSellerDto.owner_document_ref = owner_document_ref === 'true';
			}

			if (frontier) {
				requestParams.editSellerDto.frontier = frontier === 'true';
			}

			if (owner_birth) {
				requestParams.editSellerDto.owner_birth = owner_birth;
			}

			await sellersApi.editSeller(requestParams);

			await editSellerRisk(values);

			if (currentSeller && currentSeller.id === id) {
				updateCurrentSeller({
					...currentSeller,
					code: values.code.trim(),
					name: values.name,
					description: values.description,
					document: values.document,
					has_split_rules: values.has_split_rules === 'true',
					is_assignor: typeof values.is_assignor === 'string' ? values.is_assignor === 'true' : values.is_assignor,
					billet_code: billet_code === 'true',
					card_payer_check: card_payer_check === 'true',
					celcoin_value: Math.trunc(celcoin_value * 100),
				});
			}

			addToast('Estabelecimento editado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
			history.push(`/admin/estabelecimentos`);
		} catch (error) {
			addToast('Erro ao editar estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const editSellerRisk = async (values) => {
		const { risk_classification, negative_media, lawsuits, note, attachments } = values;

		try {
			if (sellerRisk?.id) {
				const requestParams: EditsellerRiskRequest = {
					sellerRiskId: sellerRisk?.id || '',
					updateSellerRiskDto: {
						classification: risk_classification,
						negative_media: negative_media === 'true' ? true : false,
						lawsuits: lawsuits === 'true' ? true : false,
						note,
						attachments,
					},
				};

				await sellerRisksApi.editsellerRisk(requestParams);
			} else {
				const requestParams: CreateSellerRiskRequest = {
					createSellerRiskDto: {
						seller_id: id,
						classification: risk_classification,
						negative_media,
						lawsuits,
						note,
						attachments,
					},
				};

				await sellerRisksApi.createSellerRisk(requestParams);
			}

			addToast('Risco de estabelecimento atualizado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao atualizar risco do estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	const renderStage = (stage: number, values, errors, setFieldValue) => {
		const stagesComponents = [
			<DataForm
				salesPlans={salePlans}
				merchants={merchants}
				errors={errors}
				values={values}
				setFieldValue={setFieldValue}
				isStatusDisabled={!isBackoffice}
				isCnsSearchEnabled={false}
				responsibleSupports={responsibleSupports}
				responsibleCommercials={responsibleCommercials}
			/>,
			<SettingsForm merchants={merchants} values={values} setFieldValue={setFieldValue} errors={errors} />,
			<RiskForm values={values} setFieldValue={setFieldValue} />,
			<SalePlanForm
				salePlans={salePlans}
				initialSalePlan={seller?.sale_plan_id}
				values={values}
				confirmSalePlan={setIsSalePlanConfirmed}
			/>,
		];

		return stagesComponents[stage];
	};

	return (
		<Flex direction='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<Text id='resume-text' fontSize='md'>
						Resumo
					</Text>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md' href='/admin/estabelecimentos'>
						Estabelecimentos
					</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<Text id='edit-text' fontSize='md' fontWeight='semibold'>
						Editar {seller ? seller.name : id}
					</Text>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper id='seller-timeline-paper-text' w={{ base: '100%', xl: '95%', '2xl': '80%' }}>
				<Stack mb={5} maxWidth='95%' pr={{ base: 5, md: 0 }}>
					<Timeline selected={stage} steps={stages} updateStep={(value: number) => setStage(value)} />
				</Stack>
			</Paper>

			<Paper id='seller-formik-paper-text' title={stages[stage].title} w={{ base: '100%', xl: '95%', '2xl': '80%' }}>
				<Formik
					enableReinitialize
					initialValues={editSellerInitialValues}
					validationSchema={editSellerSchema}
					initialTouched={{ sale_plan_id: true }}
					onSubmit={handleEditSeller}
				>
					{({ isValid, setFieldValue, errors, values }) => {
						return (
							<>
								{renderStage(stage, values, errors, setFieldValue)}
								<HStack justify={'space-between'} mt={10}>
									<Button bgColor='primary' color='white' hidden={stage === 0} onClick={() => setStage(stage - 1)}>
										Anterior
									</Button>
									<Spacer />
									<Button
										bgColor='primary'
										color='white'
										disabled={stage < stages.length - 1 ? !stages[stage + 1].isEnabled : true}
										hidden={stage === stages.length - 1}
										onClick={() => setStage(stage + 1)}
									>
										Próximo
									</Button>
									<Button
										bgColor='primary'
										color='white'
										hidden={!(stage === stages.length - 1)}
										disabled={!isValid || !isSalePlanConfirmed}
										isLoading={isLoading}
										onClick={() => handleEditSeller(values)}
									>
										Salvar
									</Button>
								</HStack>
							</>
						);
					}}
				</Formik>
			</Paper>
		</Flex>
	);
};

export default EditSeller;
