/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
} from './';

/**
 * 
 * @export
 * @interface SplitRules
 */
export interface SplitRules {
    /**
     * 
     * @type {string}
     * @memberof SplitRules
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SplitRules
     */
    amount: number;
    /**
     * 
     * @type {Seller}
     * @memberof SplitRules
     */
    main_seller: Seller;
    /**
     * 
     * @type {string}
     * @memberof SplitRules
     */
    main_seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof SplitRules
     */
    main_seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof SplitRules
     */
    main_seller_document: string;
    /**
     * 
     * @type {string}
     * @memberof SplitRules
     */
    main_seller_code: string;
    /**
     * 
     * @type {string}
     * @memberof SplitRules
     */
    main_sale_id: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof SplitRules
     */
    splits: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof SplitRules
     */
    is_splited?: boolean;
}

export function SplitRulesFromJSON(json: any): SplitRules {
    return SplitRulesFromJSONTyped(json, false);
}

export function SplitRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SplitRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': json['amount'],
        'main_seller': SellerFromJSON(json['main_seller']),
        'main_seller_id': json['main_seller_id'],
        'main_seller_name': json['main_seller_name'],
        'main_seller_document': json['main_seller_document'],
        'main_seller_code': json['main_seller_code'],
        'main_sale_id': json['main_sale_id'],
        'splits': json['splits'],
        'is_splited': !exists(json, 'is_splited') ? undefined : json['is_splited'],
    };
}

export function SplitRulesToJSON(value?: SplitRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'main_seller': SellerToJSON(value.main_seller),
        'main_seller_id': value.main_seller_id,
        'main_seller_name': value.main_seller_name,
        'main_seller_document': value.main_seller_document,
        'main_seller_code': value.main_seller_code,
        'main_sale_id': value.main_sale_id,
        'splits': value.splits,
        'is_splited': value.is_splited,
    };
}


