/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetMerchantDto,
    GetMerchantDtoFromJSON,
    GetMerchantDtoFromJSONTyped,
    GetMerchantDtoToJSON,
    GetPaymentTypeDto,
    GetPaymentTypeDtoFromJSON,
    GetPaymentTypeDtoFromJSONTyped,
    GetPaymentTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAcquirerSalePlanDto
 */
export interface GetAcquirerSalePlanDto {
    /**
     * 
     * @type {string}
     * @memberof GetAcquirerSalePlanDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAcquirerSalePlanDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAcquirerSalePlanDto
     */
    description: string;
    /**
     * 
     * @type {Array<GetPaymentTypeDto>}
     * @memberof GetAcquirerSalePlanDto
     */
    payment_types: Array<GetPaymentTypeDto>;
    /**
     * 
     * @type {string}
     * @memberof GetAcquirerSalePlanDto
     */
    type: GetAcquirerSalePlanDtoTypeEnum;
    /**
     * 
     * @type {GetMerchantDto}
     * @memberof GetAcquirerSalePlanDto
     */
    merchant: GetMerchantDto;
}

/**
* @export
* @enum {string}
*/
export enum GetAcquirerSalePlanDtoTypeEnum {
    Markup = 'markup',
    Spread = 'spread',
    Split = 'split'
}

export function GetAcquirerSalePlanDtoFromJSON(json: any): GetAcquirerSalePlanDto {
    return GetAcquirerSalePlanDtoFromJSONTyped(json, false);
}

export function GetAcquirerSalePlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAcquirerSalePlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'payment_types': ((json['payment_types'] as Array<any>).map(GetPaymentTypeDtoFromJSON)),
        'type': json['type'],
        'merchant': GetMerchantDtoFromJSON(json['merchant']),
    };
}

export function GetAcquirerSalePlanDtoToJSON(value?: GetAcquirerSalePlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'payment_types': ((value.payment_types as Array<any>).map(GetPaymentTypeDtoToJSON)),
        'type': value.type,
        'merchant': GetMerchantDtoToJSON(value.merchant),
    };
}


