import React from 'react';

import { Tabs, TabList, Tab, TabPanels, TabPanel, Box, useStyles, useTab, VStack } from '@chakra-ui/react';

import Billet from '../components/Billet';
import CreditCard from '../components/CreditCard';
import QrCode from '../components/QrCode';

import { GetPaymentLinkSellerDto, GetSimulationDto } from '../../../clients';

import { CheckoutSaleInformationType } from '../types/checkout.type';
import { BilletQrcodeType } from '../types/permit-billet-qrcode.type';
import Text from '../../../components/Text';

type TabsWrapperProps = {
	simulationValues: Array<GetSimulationDto>;
	seller: GetPaymentLinkSellerDto;
	saleInformation: CheckoutSaleInformationType | undefined;
	setLinkPayed(payed: boolean): void;
	hasPreCapture?: boolean;
	protestLinkData: BilletQrcodeType;
};

const CustomTab: React.FC = (props) => {
	const styles = useStyles();
	const tabProps = useTab(props);

	return (
		<Tab sx={styles.tab} {...tabProps} border='none'>
			<VStack as='span' mr='2'>
				{props.children}
			</VStack>
		</Tab>
	);
};

const TabsWrapper: React.FC<TabsWrapperProps> = ({
	simulationValues,
	seller,
	saleInformation,
	setLinkPayed,
	hasPreCapture,
	protestLinkData,
}) => (
	<Tabs variant='enclosed' align='center'>
		<TabList>
			{protestLinkData?.card_is_permitted && (
				<CustomTab>
					<Box>Cartão de Crédito</Box>
					<Text id='card-confirmation-text' fontSize='0.75em'>
						Confirmação instantânea
					</Text>
				</CustomTab>
			)}
			{protestLinkData?.qrcode_is_permitted && (
				<CustomTab>
					<Box>PIX QR Code</Box>
					<Text id='pix-confirmation-text' fontSize='0.75em'>
						Confirmação instantânea
					</Text>
				</CustomTab>
			)}
			{protestLinkData?.billet_is_permitted && (
				<CustomTab>
					<Box>Boleto Bancário</Box>
				</CustomTab>
			)}
		</TabList>
		<TabPanels>
			{protestLinkData?.card_is_permitted && (
				<TabPanel>
					<CreditCard
						hasPreCapture={hasPreCapture}
						seller={seller}
						simulationValues={simulationValues}
						saleInformation={saleInformation}
						setLinkPayed={setLinkPayed}
					/>
				</TabPanel>
			)}
			{protestLinkData?.qrcode_is_permitted && (
				<TabPanel>
					<QrCode seller={seller} simulationValues={simulationValues} saleInformation={saleInformation} />
				</TabPanel>
			)}
			{protestLinkData?.billet_is_permitted && (
				<TabPanel>
					<Billet seller={seller} simulationValues={simulationValues} saleInformation={saleInformation} setLinkPayed={setLinkPayed} />
				</TabPanel>
			)}
		</TabPanels>
	</Tabs>
);

export default TabsWrapper;
