/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncludeAcquirerDto
 */
export interface IncludeAcquirerDto {
    /**
     * 
     * @type {string}
     * @memberof IncludeAcquirerDto
     */
    acquirer_id: string;
}

export function IncludeAcquirerDtoFromJSON(json: any): IncludeAcquirerDto {
    return IncludeAcquirerDtoFromJSONTyped(json, false);
}

export function IncludeAcquirerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncludeAcquirerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acquirer_id': json['acquirer_id'],
    };
}

export function IncludeAcquirerDtoToJSON(value?: IncludeAcquirerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acquirer_id': value.acquirer_id,
    };
}


