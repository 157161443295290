/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSplitRulesDto
 */
export interface GetSplitRulesDto {
    /**
     * 
     * @type {string}
     * @memberof GetSplitRulesDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSplitRulesDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetSplitRulesDto
     */
    main_seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSplitRulesDto
     */
    main_seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSplitRulesDto
     */
    main_seller_document: string;
    /**
     * 
     * @type {string}
     * @memberof GetSplitRulesDto
     */
    main_seller_code: string;
    /**
     * 
     * @type {string}
     * @memberof GetSplitRulesDto
     */
    main_sale_id: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetSplitRulesDto
     */
    splits: Array<object>;
}

export function GetSplitRulesDtoFromJSON(json: any): GetSplitRulesDto {
    return GetSplitRulesDtoFromJSONTyped(json, false);
}

export function GetSplitRulesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSplitRulesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': json['amount'],
        'main_seller_id': json['main_seller_id'],
        'main_seller_name': json['main_seller_name'],
        'main_seller_document': json['main_seller_document'],
        'main_seller_code': json['main_seller_code'],
        'main_sale_id': json['main_sale_id'],
        'splits': json['splits'],
    };
}

export function GetSplitRulesDtoToJSON(value?: GetSplitRulesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'main_seller_id': value.main_seller_id,
        'main_seller_name': value.main_seller_name,
        'main_seller_document': value.main_seller_document,
        'main_seller_code': value.main_seller_code,
        'main_sale_id': value.main_sale_id,
        'splits': value.splits,
    };
}


