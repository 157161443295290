/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSellerRiskDto
 */
export interface UpdateSellerRiskDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerRiskDto
     */
    classification?: UpdateSellerRiskDtoClassificationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSellerRiskDto
     */
    negative_media: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSellerRiskDto
     */
    lawsuits?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateSellerRiskDto
     */
    attachments?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerRiskDto
     */
    note?: string;
}

/**
* @export
* @enum {string}
*/
export enum UpdateSellerRiskDtoClassificationEnum {
    Lower = 'lower',
    Medium = 'medium',
    High = 'high'
}

export function UpdateSellerRiskDtoFromJSON(json: any): UpdateSellerRiskDto {
    return UpdateSellerRiskDtoFromJSONTyped(json, false);
}

export function UpdateSellerRiskDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSellerRiskDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'negative_media': json['negative_media'],
        'lawsuits': !exists(json, 'lawsuits') ? undefined : json['lawsuits'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function UpdateSellerRiskDtoToJSON(value?: UpdateSellerRiskDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classification': value.classification,
        'negative_media': value.negative_media,
        'lawsuits': value.lawsuits,
        'attachments': value.attachments,
        'note': value.note,
    };
}


