/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePosPrefixDto
 */
export interface CreatePosPrefixDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePosPrefixDto
     */
    prefix: string;
}

export function CreatePosPrefixDtoFromJSON(json: any): CreatePosPrefixDto {
    return CreatePosPrefixDtoFromJSONTyped(json, false);
}

export function CreatePosPrefixDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePosPrefixDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prefix': json['prefix'],
    };
}

export function CreatePosPrefixDtoToJSON(value?: CreatePosPrefixDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prefix': value.prefix,
    };
}


