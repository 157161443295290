import { Breadcrumb as DefaultBreadcrumb, BreadcrumbProps as DefaultBreadcrumbProps } from '@chakra-ui/react';
import React from 'react';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { useTheme } from 'contexts/ThemeProvider';

type BreadcrumbProps = DefaultBreadcrumbProps & {
	children: JSX.Element[] | JSX.Element;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ children }: BreadcrumbProps) => {
	const { theme } = useTheme();
	return (
		<DefaultBreadcrumb separator={<ChevronRightIcon />} color={theme?.secondary_color || 'primary'} mb='4'>
			{children}
		</DefaultBreadcrumb>
	);
};

export default Breadcrumb;
