/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPaperRollOrderDto
 */
export interface GetPaperRollOrderDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    requesting_amount: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    sent_amount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    shipping_type?: GetPaperRollOrderDtoShippingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    tracking_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    status: GetPaperRollOrderDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    seller_name: string;
    /**
     * 
     * @type {Address}
     * @memberof GetPaperRollOrderDto
     */
    address: Address;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaperRollOrderDto
     */
    default_address: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    requesting_user_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    backoffice_user_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaperRollOrderDto
     */
    backoffice_note?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetPaperRollOrderDto
     */
    updated_at?: Date;
}

/**
* @export
* @enum {string}
*/
export enum GetPaperRollOrderDtoShippingTypeEnum {
    Pac = 'Pac',
    Sedex = 'Sedex'
}/**
* @export
* @enum {string}
*/
export enum GetPaperRollOrderDtoStatusEnum {
    Pending = 'pending',
    Sent = 'sent',
    NotSent = 'not_sent'
}

export function GetPaperRollOrderDtoFromJSON(json: any): GetPaperRollOrderDto {
    return GetPaperRollOrderDtoFromJSONTyped(json, false);
}

export function GetPaperRollOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaperRollOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'requesting_amount': json['requesting_amount'],
        'sent_amount': !exists(json, 'sent_amount') ? undefined : json['sent_amount'],
        'shipping_type': !exists(json, 'shipping_type') ? undefined : json['shipping_type'],
        'tracking_code': !exists(json, 'tracking_code') ? undefined : json['tracking_code'],
        'status': json['status'],
        'seller_name': json['seller_name'],
        'address': AddressFromJSON(json['address']),
        'default_address': json['default_address'],
        'requesting_user_name': json['requesting_user_name'],
        'backoffice_user_name': !exists(json, 'backoffice_user_name') ? undefined : json['backoffice_user_name'],
        'backoffice_note': !exists(json, 'backoffice_note') ? undefined : json['backoffice_note'],
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}

export function GetPaperRollOrderDtoToJSON(value?: GetPaperRollOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'requesting_amount': value.requesting_amount,
        'sent_amount': value.sent_amount,
        'shipping_type': value.shipping_type,
        'tracking_code': value.tracking_code,
        'status': value.status,
        'seller_name': value.seller_name,
        'address': AddressToJSON(value.address),
        'default_address': value.default_address,
        'requesting_user_name': value.requesting_user_name,
        'backoffice_user_name': value.backoffice_user_name,
        'backoffice_note': value.backoffice_note,
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
    };
}


