import { TableProps, useTheme } from '@chakra-ui/react';
import React from 'react';

import { CellContent, CellContentProps, HeaderTitle, HeaderTitleProps, SimpleTable } from '../../../containers/SimpleTable';
import { DateFormatEnum, formatToBrazilianTimezone, maskMoney } from '../../../services/masks.service';

interface IMonthlyAlertsTable extends TableProps {
	data: string[];
	setPagination: React.Dispatch<React.SetStateAction<{ currentPage: number; limit: number }>>;
	pagination: { currentPage: number; limit: number };
	totalPages: number;
}

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default (props: IMonthlyAlertsTable) => {
	const { sizes } = useTheme();
	const { data, ...rest } = props;

	const columns = React.useMemo(
		() => {
			let cols: any = [];

			return cols.concat([
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='id-title' column={column}>
							ID
						</HeaderTitleStyled>
					),
					accessor: 'id',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='seller-name-title' column={column}>
							Estabelecimento
						</HeaderTitleStyled>
					),
					accessor: 'seller_name',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='seller-id-title' column={column}>
							ID Estabelecimento
						</HeaderTitleStyled>
					),
					accessor: 'seller_id',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='monthly-billing-title' column={column}>
							Faturamento Mensal
						</HeaderTitleStyled>
					),
					accessor: 'monthly_billing',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContentStyled id={`${row.index}-${column.id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='accumulated-amount-title' column={column}>
							Total Acumulado
						</HeaderTitleStyled>
					),
					accessor: 'accumulated_amount',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContentStyled id={`${row.index}-${column.id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='created-at-title' column={column}>
							Data de criação
						</HeaderTitleStyled>
					),
					accessor: 'created_at',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContentStyled id={`${row.index}-${column.id}-cell`}>
							{formatToBrazilianTimezone(value, DateFormatEnum.DayMonthYear)}
						</CellContentStyled>
					),
				},
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			maxHeightTableWrapper={`calc(100vh - ${sizes.headerHeight} - 24.1875rem)`}
			{...rest}
			variant='unstyled'
		/>
	);
};
