/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDebitAddressDTO
 */
export interface CreateDebitAddressDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAddressDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAddressDTO
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAddressDTO
     */
    house_number_or_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAddressDTO
     */
    postal_code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAddressDTO
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAddressDTO
     */
    state: string;
}

export function CreateDebitAddressDTOFromJSON(json: any): CreateDebitAddressDTO {
    return CreateDebitAddressDTOFromJSONTyped(json, false);
}

export function CreateDebitAddressDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDebitAddressDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': json['city'],
        'country': json['country'],
        'house_number_or_name': json['house_number_or_name'],
        'postal_code': json['postal_code'],
        'street': json['street'],
        'state': json['state'],
    };
}

export function CreateDebitAddressDTOToJSON(value?: CreateDebitAddressDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'country': value.country,
        'house_number_or_name': value.house_number_or_name,
        'postal_code': value.postal_code,
        'street': value.street,
        'state': value.state,
    };
}


