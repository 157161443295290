import React from 'react';

import { HStack } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillClockCircle } from 'react-icons/ai';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';
import { formatWithoutTimezone } from '../../../services/date.service';
import { protocolStatusEnum, protocolHistoryTypeEnum } from '../../../services/enums.service';
import Text from '../../../components/Text';

const iconStatusLiteral = {
	paid: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	alert: <AiFillCloseCircle color='tomato' size={16} />,
	overdued: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({ data }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='type-title' column={column}>
						Tipo
					</HeaderTitle>
				),
				accessor: 'type',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{protocolHistoryTypeEnum[value]}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='before-title' column={column}>
						Antes
					</HeaderTitle>
				),
				accessor: 'before',
				Cell: ({ cell: { row, column,  } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{row.original.type === 'due_date' ? (
							formatWithoutTimezone(row.original.before, 'dd/MM/yyyy HH:mm:ss')
						) : (
							<HStack>
								<Text id={`${row.index}-${column.id}-icon-text`} color={`darkGrey`}>
									{iconStatusLiteral[row.original.before]}
								</Text>
								<Text id={`${row.index}-${column.id}-text`} color={`darkGrey`}>
									{protocolStatusEnum[row.original.before]}
								</Text>
							</HStack>
						)}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='after-title' column={column}>
						Depois
					</HeaderTitle>
				),
				accessor: 'after',
				Cell: ({ cell: { row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{row.original.type === 'due_date' ? (
							formatWithoutTimezone(row.original.after, 'dd/MM/yyyy HH:mm:ss')
						) : (
							<HStack>
								<Text id={`${row.index}-${column.id}-icon-text`}color={`darkGrey`}>
									{iconStatusLiteral[row.original.after]}
								</Text>
								<Text id={`${row.index}-${column.id}-text`} color={`darkGrey`}>
									{protocolStatusEnum[row.original.after]}
								</Text>
							</HStack>
						)}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='created-at-title' column={column}>
						Data de Alteração
					</HeaderTitle>
				),
				accessor: 'created_at',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='user-name-title' column={column}>
						Usuário
					</HeaderTitle>
				),
				accessor: 'user_name',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <SimpleTable columns={columns} data={data} isLimited={true} />;
};
