/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettlementOption
 */
export interface SettlementOption {
    /**
     * 
     * @type {string}
     * @memberof SettlementOption
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementOption
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementOption
     */
    code: SettlementOptionCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof SettlementOption
     */
    type: SettlementOptionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SettlementOption
     */
    days: number;
}

/**
* @export
* @enum {string}
*/
export enum SettlementOptionCodeEnum {
    D0 = 'D0',
    D1 = 'D1',
    D30 = 'D30'
}/**
* @export
* @enum {string}
*/
export enum SettlementOptionTypeEnum {
    Pix = 'pix',
    Debit = 'debit',
    Credit = 'credit',
    Billet = 'billet'
}

export function SettlementOptionFromJSON(json: any): SettlementOption {
    return SettlementOptionFromJSONTyped(json, false);
}

export function SettlementOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': json['description'],
        'code': json['code'],
        'type': json['type'],
        'days': json['days'],
    };
}

export function SettlementOptionToJSON(value?: SettlementOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'code': value.code,
        'type': value.type,
        'days': value.days,
    };
}


