/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExportMonthlyAlertsListDto
 */
export interface CreateExportMonthlyAlertsListDto {
    /**
     * Data de inicio. Formato: yyyy-mm-dd
     * @type {string}
     * @memberof CreateExportMonthlyAlertsListDto
     */
    start_date?: string;
    /**
     * Data de fim. Formato: yyyy-mm-dd
     * @type {string}
     * @memberof CreateExportMonthlyAlertsListDto
     */
    end_date?: string;
    /**
     * ID do estabelecimento
     * @type {string}
     * @memberof CreateExportMonthlyAlertsListDto
     */
    seller_id?: string;
}

export function CreateExportMonthlyAlertsListDtoFromJSON(json: any): CreateExportMonthlyAlertsListDto {
    return CreateExportMonthlyAlertsListDtoFromJSONTyped(json, false);
}

export function CreateExportMonthlyAlertsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExportMonthlyAlertsListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start_date': !exists(json, 'start_date') ? undefined : json['start_date'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
    };
}

export function CreateExportMonthlyAlertsListDtoToJSON(value?: CreateExportMonthlyAlertsListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.start_date,
        'end_date': value.end_date,
        'seller_id': value.seller_id,
    };
}


