/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSaleSellerAddressDto,
    GetSaleSellerAddressDtoFromJSON,
    GetSaleSellerAddressDtoFromJSONTyped,
    GetSaleSellerAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSaleSellerDto
 */
export interface GetSaleSellerDto {
    /**
     * 
     * @type {string}
     * @memberof GetSaleSellerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleSellerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleSellerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleSellerDto
     */
    phone: string;
    /**
     * 
     * @type {GetSaleSellerAddressDto}
     * @memberof GetSaleSellerDto
     */
    address: GetSaleSellerAddressDto;
}

export function GetSaleSellerDtoFromJSON(json: any): GetSaleSellerDto {
    return GetSaleSellerDtoFromJSONTyped(json, false);
}

export function GetSaleSellerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSaleSellerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'document': json['document'],
        'phone': json['phone'],
        'address': GetSaleSellerAddressDtoFromJSON(json['address']),
    };
}

export function GetSaleSellerDtoToJSON(value?: GetSaleSellerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'document': value.document,
        'phone': value.phone,
        'address': GetSaleSellerAddressDtoToJSON(value.address),
    };
}


