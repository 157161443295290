/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPosPrefixDto
 */
export interface GetPosPrefixDto {
    /**
     * 
     * @type {string}
     * @memberof GetPosPrefixDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPosPrefixDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetPosPrefixDto
     */
    prefix: string;
}

export function GetPosPrefixDtoFromJSON(json: any): GetPosPrefixDto {
    return GetPosPrefixDtoFromJSONTyped(json, false);
}

export function GetPosPrefixDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPosPrefixDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': json['created_at'],
        'prefix': json['prefix'],
    };
}

export function GetPosPrefixDtoToJSON(value?: GetPosPrefixDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.created_at,
        'prefix': value.prefix,
    };
}


