/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditSellerCredentialDto
 */
export interface EditSellerCredentialDto {
    /**
     * 
     * @type {string}
     * @memberof EditSellerCredentialDto
     */
    api_key?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSellerCredentialDto
     */
    client_id: string;
}

export function EditSellerCredentialDtoFromJSON(json: any): EditSellerCredentialDto {
    return EditSellerCredentialDtoFromJSONTyped(json, false);
}

export function EditSellerCredentialDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditSellerCredentialDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_key': !exists(json, 'api_key') ? undefined : json['api_key'],
        'client_id': json['client_id'],
    };
}

export function EditSellerCredentialDtoToJSON(value?: EditSellerCredentialDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_key': value.api_key,
        'client_id': value.client_id,
    };
}


