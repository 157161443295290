/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAcquirerDto,
    GetAcquirerDtoFromJSON,
    GetAcquirerDtoFromJSONTyped,
    GetAcquirerDtoToJSON,
    GetCostPlanDto,
    GetCostPlanDtoFromJSON,
    GetCostPlanDtoFromJSONTyped,
    GetCostPlanDtoToJSON,
    GetMerchantDto,
    GetMerchantDtoFromJSON,
    GetMerchantDtoFromJSONTyped,
    GetMerchantDtoToJSON,
    GetSalePlanPaymentTypeDto,
    GetSalePlanPaymentTypeDtoFromJSON,
    GetSalePlanPaymentTypeDtoFromJSONTyped,
    GetSalePlanPaymentTypeDtoToJSON,
    GetSettlementPlanDto,
    GetSettlementPlanDtoFromJSON,
    GetSettlementPlanDtoFromJSONTyped,
    GetSettlementPlanDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSalePlanDto
 */
export interface GetSalePlanDto {
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanDto
     */
    description: string;
    /**
     * 
     * @type {Array<GetSalePlanPaymentTypeDto>}
     * @memberof GetSalePlanDto
     */
    payment_types: Array<GetSalePlanPaymentTypeDto>;
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanDto
     */
    type: GetSalePlanDtoTypeEnum;
    /**
     * 
     * @type {GetMerchantDto}
     * @memberof GetSalePlanDto
     */
    merchant: GetMerchantDto;
    /**
     * 
     * @type {GetAcquirerDto}
     * @memberof GetSalePlanDto
     */
    acquirer: GetAcquirerDto;
    /**
     * 
     * @type {GetSettlementPlanDto}
     * @memberof GetSalePlanDto
     */
    settlement_plan: GetSettlementPlanDto;
    /**
     * 
     * @type {GetCostPlanDto}
     * @memberof GetSalePlanDto
     */
    cost_plan: GetCostPlanDto;
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanDto
     */
    payment_method: string;
    /**
     * 
     * @type {number}
     * @memberof GetSalePlanDto
     */
    risk_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSalePlanDto
     */
    inactive?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum GetSalePlanDtoTypeEnum {
    Markup = 'markup',
    Spread = 'spread',
    Split = 'split'
}

export function GetSalePlanDtoFromJSON(json: any): GetSalePlanDto {
    return GetSalePlanDtoFromJSONTyped(json, false);
}

export function GetSalePlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalePlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'payment_types': ((json['payment_types'] as Array<any>).map(GetSalePlanPaymentTypeDtoFromJSON)),
        'type': json['type'],
        'merchant': GetMerchantDtoFromJSON(json['merchant']),
        'acquirer': GetAcquirerDtoFromJSON(json['acquirer']),
        'settlement_plan': GetSettlementPlanDtoFromJSON(json['settlement_plan']),
        'cost_plan': GetCostPlanDtoFromJSON(json['cost_plan']),
        'payment_method': json['payment_method'],
        'risk_value': !exists(json, 'risk_value') ? undefined : json['risk_value'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
    };
}

export function GetSalePlanDtoToJSON(value?: GetSalePlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'payment_types': ((value.payment_types as Array<any>).map(GetSalePlanPaymentTypeDtoToJSON)),
        'type': value.type,
        'merchant': GetMerchantDtoToJSON(value.merchant),
        'acquirer': GetAcquirerDtoToJSON(value.acquirer),
        'settlement_plan': GetSettlementPlanDtoToJSON(value.settlement_plan),
        'cost_plan': GetCostPlanDtoToJSON(value.cost_plan),
        'payment_method': value.payment_method,
        'risk_value': value.risk_value,
        'inactive': value.inactive,
    };
}


