/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillPaymentSalesPermissions,
    BillPaymentSalesPermissionsFromJSON,
    BillPaymentSalesPermissionsFromJSONTyped,
    BillPaymentSalesPermissionsToJSON,
    SalesHistoryReportPermissions,
    SalesHistoryReportPermissionsFromJSON,
    SalesHistoryReportPermissionsFromJSONTyped,
    SalesHistoryReportPermissionsToJSON,
    SettlementBatchSalesReport,
    SettlementBatchSalesReportFromJSON,
    SettlementBatchSalesReportFromJSONTyped,
    SettlementBatchSalesReportToJSON,
    SettlementBatchTransactionsReport,
    SettlementBatchTransactionsReportFromJSON,
    SettlementBatchTransactionsReportFromJSONTyped,
    SettlementBatchTransactionsReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface MerchantReportsPermissions
 */
export interface MerchantReportsPermissions {
    /**
     * 
     * @type {SalesHistoryReportPermissions}
     * @memberof MerchantReportsPermissions
     */
    sales_history?: SalesHistoryReportPermissions;
    /**
     * 
     * @type {SettlementBatchTransactionsReport}
     * @memberof MerchantReportsPermissions
     */
    settlement_batch_transaction?: SettlementBatchTransactionsReport;
    /**
     * 
     * @type {SettlementBatchSalesReport}
     * @memberof MerchantReportsPermissions
     */
    settlement_batch_sale?: SettlementBatchSalesReport;
    /**
     * 
     * @type {BillPaymentSalesPermissions}
     * @memberof MerchantReportsPermissions
     */
    bill_payment_sales?: BillPaymentSalesPermissions;
}

export function MerchantReportsPermissionsFromJSON(json: any): MerchantReportsPermissions {
    return MerchantReportsPermissionsFromJSONTyped(json, false);
}

export function MerchantReportsPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantReportsPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sales_history': !exists(json, 'sales_history') ? undefined : SalesHistoryReportPermissionsFromJSON(json['sales_history']),
        'settlement_batch_transaction': !exists(json, 'settlement_batch_transaction') ? undefined : SettlementBatchTransactionsReportFromJSON(json['settlement_batch_transaction']),
        'settlement_batch_sale': !exists(json, 'settlement_batch_sale') ? undefined : SettlementBatchSalesReportFromJSON(json['settlement_batch_sale']),
        'bill_payment_sales': !exists(json, 'bill_payment_sales') ? undefined : BillPaymentSalesPermissionsFromJSON(json['bill_payment_sales']),
    };
}

export function MerchantReportsPermissionsToJSON(value?: MerchantReportsPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sales_history': SalesHistoryReportPermissionsToJSON(value.sales_history),
        'settlement_batch_transaction': SettlementBatchTransactionsReportToJSON(value.settlement_batch_transaction),
        'settlement_batch_sale': SettlementBatchSalesReportToJSON(value.settlement_batch_sale),
        'bill_payment_sales': BillPaymentSalesPermissionsToJSON(value.bill_payment_sales),
    };
}


