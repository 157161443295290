/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateExportAssignmentTransactionAlertsDto,
    CreateExportAssignmentTransactionAlertsDtoFromJSON,
    CreateExportAssignmentTransactionAlertsDtoToJSON,
    GetAssignmentTransactionAlertsDto,
    GetAssignmentTransactionAlertsDtoFromJSON,
    GetAssignmentTransactionAlertsDtoToJSON,
    GetFileExportRequestDto,
    GetFileExportRequestDtoFromJSON,
    GetFileExportRequestDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface CreateAlertExportRequestRequest {
    createExportAssignmentTransactionAlertsDto: CreateExportAssignmentTransactionAlertsDto;
}

export interface GetAssignmentTransactionAlertDtoRequest {
    alertId: string;
}

export interface ListAssignmentTransactionAlertsRequest {
    limit: number;
    currentPage: number;
    description?: string;
    startDate?: string;
    endDate?: string;
}

/**
 * 
 */
export class AssignmentTransactionAlertsApi extends runtime.BaseAPI {

    /**
     * Gerar uma requisição de exportação de alertas
     */
    async createAlertExportRequestRaw(requestParameters: CreateAlertExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportAssignmentTransactionAlertsDto === null || requestParameters.createExportAssignmentTransactionAlertsDto === undefined) {
            throw new runtime.RequiredError('createExportAssignmentTransactionAlertsDto','Required parameter requestParameters.createExportAssignmentTransactionAlertsDto was null or undefined when calling createAlertExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/assignment-transaction-alerts/history/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportAssignmentTransactionAlertsDtoToJSON(requestParameters.createExportAssignmentTransactionAlertsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação de alertas
     */
    async createAlertExportRequest(requestParameters: CreateAlertExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createAlertExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar os alertas do dia anterior
     */
    async generateAlertsRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/assignment-transaction-alerts/generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gerar os alertas do dia anterior
     */
    async generateAlerts(): Promise<object> {
        const response = await this.generateAlertsRaw();
        return await response.value();
    }

    /**
     * Obter alerta pelo ID
     */
    async getAssignmentTransactionAlertDtoRaw(requestParameters: GetAssignmentTransactionAlertDtoRequest): Promise<runtime.ApiResponse<GetAssignmentTransactionAlertsDto>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling getAssignmentTransactionAlertDto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/assignment-transaction-alerts/{alertId}`.replace(`{${"alertId"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssignmentTransactionAlertsDtoFromJSON(jsonValue));
    }

    /**
     * Obter alerta pelo ID
     */
    async getAssignmentTransactionAlertDto(requestParameters: GetAssignmentTransactionAlertDtoRequest): Promise<GetAssignmentTransactionAlertsDto> {
        const response = await this.getAssignmentTransactionAlertDtoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lista alertas de transações por atribuição
     */
    async listAssignmentTransactionAlertsRaw(requestParameters: ListAssignmentTransactionAlertsRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listAssignmentTransactionAlerts.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listAssignmentTransactionAlerts.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/assignment-transaction-alerts/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Lista alertas de transações por atribuição
     */
    async listAssignmentTransactionAlerts(requestParameters: ListAssignmentTransactionAlertsRequest): Promise<PaginatedDto> {
        const response = await this.listAssignmentTransactionAlertsRaw(requestParameters);
        return await response.value();
    }

}
