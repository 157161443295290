import * as React from 'react';

import { GetThemeDto } from '../clients';

interface ThemeContextData {
	theme: GetThemeDto | undefined,
	updateTheme: (theme: GetThemeDto | undefined) => void;
}

const ThemeContext = React.createContext<ThemeContextData>({} as ThemeContextData);

type ThemeProviderProps = {
	children: React.ReactElement;	
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
	const [theme, setTheme] = React.useState<GetThemeDto | undefined>(undefined);

	async function updateTheme(newTheme: GetThemeDto | undefined) {
		setTheme(newTheme);
	}

	return (
		<ThemeContext.Provider value={{ theme, updateTheme }}>
			{children}
		</ThemeContext.Provider>
	);
};

export function useTheme() {
	const context = React.useContext(ThemeContext);

	return context;
}
