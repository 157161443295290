import * as React from 'react';

import { GetSellerDto } from '../clients';
import {
	clearKey as clearSellersSession,
	persistKey as persistSellersSession,
	getKey as getSellersSessionData,
} from '../services/local-storage.service';

interface InMemorySellersProviderContextData {
	sellers: Array<Partial<GetSellerDto>>;
	updateSellers: (sellers: Array<Partial<GetSellerDto>>) => void;
	newSeller: (data: InMemorySellersNewDataProps) => void;
	editSellerOrder: (id: string, data: InMemorySellersEditDataProps) => void;
	clearSellers: () => void;
}

const InMemorySellersContext = React.createContext<InMemorySellersProviderContextData>({} as InMemorySellersProviderContextData);

type InMemorySellersProviderProps = {
	children: React.ReactElement;
	sellers?: Array<Partial<GetSellerDto>>;
};

type InMemorySellersNewDataProps = Omit<Partial<GetSellerDto>, 'sale_plan'> & {
	sale_plan?: {
		id: string;
	};
};

type InMemorySellersEditDataProps = Omit<Partial<GetSellerDto>, 'sale_plan'> & {
	sale_plan?: {
		id: string;
	};
};

export const InMemorySellersProvider: React.FC<InMemorySellersProviderProps> = ({ children, ...rest }) => {
	const { sellers: sellersProp } = rest;

	const [sellers, setSellers] = React.useState<Array<Partial<GetSellerDto>>>(rest?.sellers || []);

	React.useEffect(() => {
		if (sellersProp && sellersProp.length) {
			clearSellersSession('sellers');
			setSellers(sellersProp);
			persistSellersSession('sellers', [...sellersProp]);
		}
	}, [sellersProp]);

	React.useEffect(() => {
		const sellersData = getSellersSessionData('sellers');

		setSellers((sellersData as unknown) as Array<Partial<GetSellerDto>>);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function updateSellers(sellers: Array<Partial<GetSellerDto>>) {
		persistSellersSession('sellers', [...sellers]);
		setSellers(sellers);
	}

	async function clearSellers() {
		clearSellersSession('sellers');
		setSellers([]);
	}

	function newSeller(data: InMemorySellersNewDataProps) {
		let sellersData = getSellersSessionData('sellers');

		if (sellersData && Array.isArray(sellersData)) {
			sellersData.unshift(data);
		} else {
			sellersData = [data];
		}
		clearSellersSession('sellers');
		if (sellersData.length > 10) {
			sellersData.pop();
		}
		setSellers(sellersData);
		persistSellersSession('sellers', sellersData);
	}

	function editSellerOrder(id: string, data: InMemorySellersEditDataProps) {
		const sellersData = getSellersSessionData('sellers');

		if (sellersData && Array.isArray(sellersData)) {
			const index = sellersData.map((seller) => seller.id).indexOf(id);
			if (index !== -1) {
				sellersData.splice(index, 1);
				sellersData.unshift(data);
				if (sellersData.length > 10) {
					sellersData.pop();
				}
				clearSellersSession('sellers');
				setSellers(sellersData);
				persistSellersSession('sellers', sellersData);
			}
		}
	}

	return (
		<InMemorySellersContext.Provider
			value={{
				sellers,
				updateSellers,
				clearSellers,
				newSeller,
				editSellerOrder,
			}}
		>
			{children}
		</InMemorySellersContext.Provider>
	);
};

export function useInMemorySellers() {
	const context = React.useContext(InMemorySellersContext);

	return context;
}
