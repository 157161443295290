/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Logo,
    LogoFromJSON,
    LogoFromJSONTyped,
    LogoToJSON,
    Payer,
    PayerFromJSON,
    PayerFromJSONTyped,
    PayerToJSON,
    Sale,
    SaleFromJSON,
    SaleFromJSONTyped,
    SaleToJSON,
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
    Theme,
    ThemeFromJSON,
    ThemeFromJSONTyped,
    ThemeToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentLink
 */
export interface PaymentLink {
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentLink
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    phone?: string;
    /**
     * 
     * @type {Payer}
     * @memberof PaymentLink
     */
    payer: Payer;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    payer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    payer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    payer_document: string;
    /**
     * 
     * @type {User}
     * @memberof PaymentLink
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    status: PaymentLinkStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentLink
     */
    generated_by_payer: boolean;
    /**
     * 
     * @type {Sale}
     * @memberof PaymentLink
     */
    sale: Sale;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    sale_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    sale_form_payment?: PaymentLinkSaleFormPaymentEnum;
    /**
     * 
     * @type {Seller}
     * @memberof PaymentLink
     */
    seller: Seller;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    seller_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentLink
     */
    created_at_time?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    updated_at?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentLink
     */
    updated_at_time?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    expires_in: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentLink
     */
    expires_in_time?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentLink
     */
    billet_payment_is_permitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentLink
     */
    qrcode_payment_is_permitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentLink
     */
    card_payment_is_permitted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    qrcode_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentLink
     */
    payment_methods?: Array<PaymentLinkPaymentMethodsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentLink
     */
    pre_capture?: boolean;
    /**
     * 
     * @type {Logo}
     * @memberof PaymentLink
     */
    logo?: Logo;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    logo_id?: string;
    /**
     * 
     * @type {Theme}
     * @memberof PaymentLink
     */
    theme?: Theme;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    theme_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    metadata?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentLink
     */
    service_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentLink
     */
    is_edit?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum PaymentLinkStatusEnum {
    Active = 'active',
    PendingCapture = 'pending_capture',
    Expired = 'expired',
    Canceled = 'canceled',
    Paid = 'paid'
}/**
* @export
* @enum {string}
*/
export enum PaymentLinkSaleFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Pending = 'pending',
    PixPos = 'pix_pos'
}/**
* @export
* @enum {string}
*/
export enum PaymentLinkPaymentMethodsEnum {
    Card = 'card',
    Billet = 'billet',
    Pix = 'pix'
}

export function PaymentLinkFromJSON(json: any): PaymentLink {
    return PaymentLinkFromJSONTyped(json, false);
}

export function PaymentLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': json['amount'],
        'description': json['description'],
        'url': json['url'],
        'token': json['token'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'payer': PayerFromJSON(json['payer']),
        'payer_id': !exists(json, 'payer_id') ? undefined : json['payer_id'],
        'payer_name': !exists(json, 'payer_name') ? undefined : json['payer_name'],
        'payer_document': json['payer_document'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'status': json['status'],
        'generated_by_payer': json['generated_by_payer'],
        'sale': SaleFromJSON(json['sale']),
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'sale_form_payment': !exists(json, 'sale_form_payment') ? undefined : json['sale_form_payment'],
        'seller': SellerFromJSON(json['seller']),
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
        'created_at': json['created_at'],
        'created_at_time': !exists(json, 'created_at_time') ? undefined : json['created_at_time'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'updated_at_time': !exists(json, 'updated_at_time') ? undefined : json['updated_at_time'],
        'expires_in': json['expires_in'],
        'expires_in_time': !exists(json, 'expires_in_time') ? undefined : json['expires_in_time'],
        'billet_payment_is_permitted': !exists(json, 'billet_payment_is_permitted') ? undefined : json['billet_payment_is_permitted'],
        'qrcode_payment_is_permitted': !exists(json, 'qrcode_payment_is_permitted') ? undefined : json['qrcode_payment_is_permitted'],
        'card_payment_is_permitted': !exists(json, 'card_payment_is_permitted') ? undefined : json['card_payment_is_permitted'],
        'qrcode_url': !exists(json, 'qrcode_url') ? undefined : json['qrcode_url'],
        'payment_methods': !exists(json, 'payment_methods') ? undefined : json['payment_methods'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
        'logo': !exists(json, 'logo') ? undefined : LogoFromJSON(json['logo']),
        'logo_id': !exists(json, 'logo_id') ? undefined : json['logo_id'],
        'theme': !exists(json, 'theme') ? undefined : ThemeFromJSON(json['theme']),
        'theme_id': !exists(json, 'theme_id') ? undefined : json['theme_id'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'service_value': !exists(json, 'service_value') ? undefined : json['service_value'],
        'is_edit': !exists(json, 'is_edit') ? undefined : json['is_edit'],
    };
}

export function PaymentLinkToJSON(value?: PaymentLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'description': value.description,
        'url': value.url,
        'token': value.token,
        'email': value.email,
        'phone': value.phone,
        'payer': PayerToJSON(value.payer),
        'payer_id': value.payer_id,
        'payer_name': value.payer_name,
        'payer_document': value.payer_document,
        'user': UserToJSON(value.user),
        'user_id': value.user_id,
        'status': value.status,
        'generated_by_payer': value.generated_by_payer,
        'sale': SaleToJSON(value.sale),
        'sale_id': value.sale_id,
        'sale_form_payment': value.sale_form_payment,
        'seller': SellerToJSON(value.seller),
        'seller_id': value.seller_id,
        'created_at': value.created_at,
        'created_at_time': value.created_at_time,
        'updated_at': value.updated_at,
        'updated_at_time': value.updated_at_time,
        'expires_in': value.expires_in,
        'expires_in_time': value.expires_in_time,
        'billet_payment_is_permitted': value.billet_payment_is_permitted,
        'qrcode_payment_is_permitted': value.qrcode_payment_is_permitted,
        'card_payment_is_permitted': value.card_payment_is_permitted,
        'qrcode_url': value.qrcode_url,
        'payment_methods': value.payment_methods,
        'pre_capture': value.pre_capture,
        'logo': LogoToJSON(value.logo),
        'logo_id': value.logo_id,
        'theme': ThemeToJSON(value.theme),
        'theme_id': value.theme_id,
        'metadata': value.metadata,
        'note': value.note,
        'service_value': value.service_value,
        'is_edit': value.is_edit,
    };
}


