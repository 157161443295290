/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAcquirerDto,
    GetAcquirerDtoFromJSON,
    GetAcquirerDtoFromJSONTyped,
    GetAcquirerDtoToJSON,
    GetAddressDto,
    GetAddressDtoFromJSON,
    GetAddressDtoFromJSONTyped,
    GetAddressDtoToJSON,
    GetBankAccountDto,
    GetBankAccountDtoFromJSON,
    GetBankAccountDtoFromJSONTyped,
    GetBankAccountDtoToJSON,
    GetLogoDto,
    GetLogoDtoFromJSON,
    GetLogoDtoFromJSONTyped,
    GetLogoDtoToJSON,
    GetMerchantDto,
    GetMerchantDtoFromJSON,
    GetMerchantDtoFromJSONTyped,
    GetMerchantDtoToJSON,
    GetThemeDto,
    GetThemeDtoFromJSON,
    GetThemeDtoFromJSONTyped,
    GetThemeDtoToJSON,
    ProtocolCustomizations,
    ProtocolCustomizationsFromJSON,
    ProtocolCustomizationsFromJSONTyped,
    ProtocolCustomizationsToJSON,
    ReportCustomization,
    ReportCustomizationFromJSON,
    ReportCustomizationFromJSONTyped,
    ReportCustomizationToJSON,
    SellerEmails,
    SellerEmailsFromJSON,
    SellerEmailsFromJSONTyped,
    SellerEmailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUserLoginSellerDto
 */
export interface GetUserLoginSellerDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    state_registration: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    code: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserLoginSellerDto
     */
    absorb_costs?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    settlement_type: GetUserLoginSellerDtoSettlementTypeEnum;
    /**
     * 
     * @type {Array<GetAcquirerDto>}
     * @memberof GetUserLoginSellerDto
     */
    acquirers?: Array<GetAcquirerDto>;
    /**
     * 
     * @type {GetAddressDto}
     * @memberof GetUserLoginSellerDto
     */
    address: GetAddressDto;
    /**
     * 
     * @type {GetBankAccountDto}
     * @memberof GetUserLoginSellerDto
     */
    bank_account: GetBankAccountDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserLoginSellerDto
     */
    is_split: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    status: GetUserLoginSellerDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    type: GetUserLoginSellerDtoTypeEnum;
    /**
     * 
     * @type {GetMerchantDto}
     * @memberof GetUserLoginSellerDto
     */
    merchant: GetMerchantDto;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    sale_plan_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserLoginSellerDto
     */
    pix_pos: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserLoginSellerDto
     */
    billet_provider: GetUserLoginSellerDtoBilletProviderEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserLoginSellerDto
     */
    no_payment_methods?: Array<string>;
    /**
     * 
     * @type {GetLogoDto}
     * @memberof GetUserLoginSellerDto
     */
    logo?: GetLogoDto;
    /**
     * 
     * @type {GetThemeDto}
     * @memberof GetUserLoginSellerDto
     */
    theme?: GetThemeDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserLoginSellerDto
     */
    has_split_rules?: boolean;
    /**
     * 
     * @type {ProtocolCustomizations}
     * @memberof GetUserLoginSellerDto
     */
    protocol_customizations?: ProtocolCustomizations;
    /**
     * 
     * @type {SellerEmails}
     * @memberof GetUserLoginSellerDto
     */
    custom_emails?: SellerEmails;
    /**
     * 
     * @type {ReportCustomization}
     * @memberof GetUserLoginSellerDto
     */
    report_customization?: ReportCustomization;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserLoginSellerDto
     */
    billet_code?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum GetUserLoginSellerDtoSettlementTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}/**
* @export
* @enum {string}
*/
export enum GetUserLoginSellerDtoStatusEnum {
    Activated = 'activated',
    Inactive = 'inactive',
    Pending = 'pending',
    ActivationSent = 'activation_sent'
}/**
* @export
* @enum {string}
*/
export enum GetUserLoginSellerDtoTypeEnum {
    Transaction = 'transaction',
    Registry = 'registry',
    Group = 'group',
    RestrictedSeller = 'restricted_seller',
    Creditor = 'creditor'
}/**
* @export
* @enum {string}
*/
export enum GetUserLoginSellerDtoBilletProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau'
}

export function GetUserLoginSellerDtoFromJSON(json: any): GetUserLoginSellerDto {
    return GetUserLoginSellerDtoFromJSONTyped(json, false);
}

export function GetUserLoginSellerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserLoginSellerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'document': json['document'],
        'state_registration': json['state_registration'],
        'code': json['code'],
        'absorb_costs': !exists(json, 'absorb_costs') ? undefined : json['absorb_costs'],
        'settlement_type': json['settlement_type'],
        'acquirers': !exists(json, 'acquirers') ? undefined : ((json['acquirers'] as Array<any>).map(GetAcquirerDtoFromJSON)),
        'address': GetAddressDtoFromJSON(json['address']),
        'bank_account': GetBankAccountDtoFromJSON(json['bank_account']),
        'is_split': json['is_split'],
        'phone': json['phone'],
        'status': json['status'],
        'type': json['type'],
        'merchant': GetMerchantDtoFromJSON(json['merchant']),
        'sale_plan_id': json['sale_plan_id'],
        'pix_pos': json['pix_pos'],
        'billet_provider': json['billet_provider'],
        'no_payment_methods': !exists(json, 'no_payment_methods') ? undefined : json['no_payment_methods'],
        'logo': !exists(json, 'logo') ? undefined : GetLogoDtoFromJSON(json['logo']),
        'theme': !exists(json, 'theme') ? undefined : GetThemeDtoFromJSON(json['theme']),
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'protocol_customizations': !exists(json, 'protocol_customizations') ? undefined : ProtocolCustomizationsFromJSON(json['protocol_customizations']),
        'custom_emails': !exists(json, 'custom_emails') ? undefined : SellerEmailsFromJSON(json['custom_emails']),
        'report_customization': !exists(json, 'report_customization') ? undefined : ReportCustomizationFromJSON(json['report_customization']),
        'billet_code': !exists(json, 'billet_code') ? undefined : json['billet_code'],
    };
}

export function GetUserLoginSellerDtoToJSON(value?: GetUserLoginSellerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'document': value.document,
        'state_registration': value.state_registration,
        'code': value.code,
        'absorb_costs': value.absorb_costs,
        'settlement_type': value.settlement_type,
        'acquirers': value.acquirers === undefined ? undefined : ((value.acquirers as Array<any>).map(GetAcquirerDtoToJSON)),
        'address': GetAddressDtoToJSON(value.address),
        'bank_account': GetBankAccountDtoToJSON(value.bank_account),
        'is_split': value.is_split,
        'phone': value.phone,
        'status': value.status,
        'type': value.type,
        'merchant': GetMerchantDtoToJSON(value.merchant),
        'sale_plan_id': value.sale_plan_id,
        'pix_pos': value.pix_pos,
        'billet_provider': value.billet_provider,
        'no_payment_methods': value.no_payment_methods,
        'logo': GetLogoDtoToJSON(value.logo),
        'theme': GetThemeDtoToJSON(value.theme),
        'has_split_rules': value.has_split_rules,
        'protocol_customizations': ProtocolCustomizationsToJSON(value.protocol_customizations),
        'custom_emails': SellerEmailsToJSON(value.custom_emails),
        'report_customization': ReportCustomizationToJSON(value.report_customization),
        'billet_code': value.billet_code,
    };
}


