import React from 'react';

import { Button } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import { GetSellerDto, SellersApi } from 'clients';
import { getApiAuthConfig } from 'services/api.service';

type RenderBilletButtonProps = {
	saleId: string;
	seller: GetSellerDto;
	id: string
};

const RenderBilletButton: React.FC<RenderBilletButtonProps> = ({ saleId, seller, id }) => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);

	const handleAction = async () => {
		setIsLoading(true);

		try {
			const data = await sellersApi.getSellerSaleBillet({ sellerId: seller.id, saleId });

			window.open(data.url);
		} catch (err) {
			addToast('Erro ao carregar o boleto', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Button
			id={`${id}-button`}
			px='4'
			height='auto'
			bgColor='primary'
			color='white'
			fontWeight='normal'
			disabled={isLoading}
			isLoading={isLoading}
			onClick={handleAction}
		>
			Boleto
		</Button>
	);
};

export default RenderBilletButton;
