import { HStack, Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { GetDdaByBilletKeyEnum, GetDdaByBilletProviderEnum } from '../../../clients/apis/BilletsApi';
import Button from '../../../components/Button';
import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormLabel from '../../../components/Form/FormLabel';
import FormSelect from '../../../components/Form/FormSelect';
import { FormValues } from '../FindDda';

type SearchFormProps = {
	isLoading: boolean;
	values?: FormValues;
};

export const SearchForm = (props: SearchFormProps) => {
	const keyLabels = {
		[GetDdaByBilletKeyEnum.Barcode]: 'Código de Barras',
		[GetDdaByBilletKeyEnum.PayerDocument]: 'CPF/CNPJ do Pagador',
		[GetDdaByBilletKeyEnum.PayerName]: 'Nome do Pagador',
		[GetDdaByBilletKeyEnum.SaleAcquirerReference]: 'PSP',
	};

	const keyArray = Object.entries(GetDdaByBilletKeyEnum).map(([key, value]) => ({
		id: key,
		name: value,
		display: keyLabels[value as GetDdaByBilletKeyEnum],
	}));

	const providerArray = Object.entries(GetDdaByBilletProviderEnum).map(([key, value]) => ({ id: key, name: value }));

	return (
		<HStack spacing={[0, 0, 4]} mb={4} alignItems={['flex-start', 'flex-start', 'flex-end']} flexDirection={['column', 'column', 'row']}>
			<Stack direction='column' minHeight='90px' w='100%'>
				<FormControl id='key' isRequired>
					<FormLabel id='key-form-label' fontSize='md' fontWeight='bold'>
						Tipo de Pesquisa
					</FormLabel>
					<FormSelect id='key-select-form' name='key'>
						<option value='' />
						{keyArray.map((key) => (
							<option value={key.name} key={key.id}>
								{key.display}
							</option>
						))}
					</FormSelect>
				</FormControl>
				<Box minHeight='25px'>
					<FormErrorMessage id='key-form-error' name='key' />
				</Box>
			</Stack>

			<Stack direction='column' minHeight='90px' w='100%'>
				<FormControl id='value' isRequired>
					<FormLabel id='search-form-label' fontSize='md' fontWeight='bold'>
						Valor Pesquisado
					</FormLabel>
					<FormInput id='search-form-imput' name='value' />
				</FormControl>
				<Box minHeight='25px'>
					<FormErrorMessage id='search-form-error' name='value' />
				</Box>
			</Stack>

			{props.values?.key === 'barcode' && (
				<Stack direction='column' minHeight='90px' w='100%'>
					<FormControl id='provider'>
						<FormLabel id='provider-form-label' fontSize='md' fontWeight='bold'>
							Provedor
						</FormLabel>
						<FormSelect id='provider-form-select' name='provider'>
							<option value='' />
							{providerArray.map((provider) => (
								<option value={provider.name} key={provider.id}>
									{provider.name}
								</option>
							))}
						</FormSelect>
					</FormControl>
					<Box minHeight='25px'>
						<FormErrorMessage id='provider-form-error' name='provider' />
					</Box>
				</Stack>
			)}

			<Stack direction='column' minHeight='90px' justify='flex-end'>
				<Button id='send-button' isLoading={props.isLoading} bgColor='primary' type='submit'>
					Enviar
				</Button>
				<Box minHeight='25px' />
			</Stack>
		</HStack>
	);
};
