import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';
import { Flex, Stack, BreadcrumbItem, BreadcrumbLink, FormControl, Input, Icon, Fade, Box } from '@chakra-ui/react';
import { Center } from '@chakra-ui/layout';
import { FiEdit } from 'react-icons/fi';

import {
	CreateMerchantRequest,
	MerchantsApi,
	PermissionSubjectEnum,
	SalesHistoryReportPermissionsFieldsEnum,
	SalesHistoryTablePermissionsFieldsEnum,
	SettlementBatchTransactionsReportFieldsEnum,
	SettlementBatchSalesReportFieldsEnum,
	SettlementBatchSalesTableFieldsEnum,
} from 'clients';

import Loader from 'containers/Loader';
import Paper from 'containers/Paper';
import { getApiAuthConfig } from 'services/api.service';
import Button from 'components/Button';
import Breadcrumb from 'components/Breadcrumb';
import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormCpfCnpjInput from 'components/Form/FormCpfCnpjInput';
import MultiSelect from 'components/MultiSelect';

import { default_merchant_subjects, default_merchant_seller_subjects, merchant_types } from 'config/constants';
import yup from 'services/yup.service';
import {
	subjectTitle,
	salesHistoryReport,
	salesHistoryTable,
	settlementBatchTransactionReport,
	settlementBatchSaleReport,
	settlementBatchSaleTable,
} from 'services/enums.service';
import ThreeDsForm from 'pages/Sellers/components/ThreeDsForm';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormSelect from 'components/Form/FormSelect';
import Checkbox from '../../components/Checkbox';
import Text from '../../components/Text';
import FormLabel from '../../components/Form/FormLabel';

const CreateMerchant: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const merchantsApi = new MerchantsApi(apiConfig);

	const [isLoading, setIsLoading] = React.useState(false);
	const [showReports, setShowReports] = React.useState<boolean>(false);
	const [showTables, setShowTables] = React.useState<boolean>(false);

	const { addToast } = useToasts();

	const handleToggleReports = () => {
		setShowReports(!showReports);
	};

	const handleToggleTables = () => {
		setShowTables(!showTables);
	};

	const createMerchantInitialValues = {
		name: '',
		description: '',
		document: '',
		code: '',
		is_active: true,
		risk_active: false,
		min_risk: 0,
		merchant_api_key: null,
		merchant_account: null,
		merchant_subjects: default_merchant_subjects,
		seller_subjects: default_merchant_seller_subjects,
		sales_history_report: Object.values(SalesHistoryReportPermissionsFieldsEnum),
		sales_history_table: Object.values(SalesHistoryTablePermissionsFieldsEnum),
		settlement_batch_transaction_report: Object.values(SettlementBatchTransactionsReportFieldsEnum),
		settlement_batch_sale_report: Object.values(SettlementBatchSalesReportFieldsEnum),
		settlement_batch_sale_table: Object.values(SettlementBatchSalesTableFieldsEnum),
		risk_value: 0,
		three_ds_active: false,
		min_three_ds: 0,
		three_ds_value: 0,
		type: '',
	};

	const createMerchantSchema = yup.object().shape({
		name: yup.string().required('Campo obrigatório'),
		description: yup.string().required('Campo obrigatório'),
		document: yup.string().required('Campo obrigatório').isCpfOrCnpjOptional('Documento inválido'),
		code: yup.string().required('Campo obrigatório'),
		is_active: yup.boolean(),
		risk_active: yup.boolean(),
		min_risk: yup.number(),
		merchant_api_key: yup.string().nullable(),
		merchant_account: yup.string().nullable(),
		merchant_subjects: yup.array().nullable(),
		seller_subjects: yup.array().nullable(),
		sales_history_report: yup.array().nullable(),
		sales_history_table: yup.array().nullable(),
		settlement_batch_transaction_report: yup.array().nullable(),
		settlement_batch_sale_report: yup.array().nullable(),
		settlement_batch_sale_table: yup.array().nullable(),
		three_ds_active: yup.string().nullable(),
		min_three_ds: yup.number().nullable().typeError('O valor deve ser do tipo numérico'),
		three_ds_value: yup.number().nullable().typeError('O valor deve ser do tipo numérico'),
		type: yup.string().nullable(),
	});

	const createMerchant = async (values, { resetForm }) => {
		setIsLoading(true);

		const sales_history_report = Object.values(SalesHistoryReportPermissionsFieldsEnum).filter((e) => {
			return values.sales_history_report?.indexOf(e) === -1;
		});

		const sales_history_table = Object.values(SalesHistoryTablePermissionsFieldsEnum).filter((e) => {
			return values.sales_history_table?.indexOf(e) === -1;
		});

		const settlement_batch_transaction_report = Object.values(SettlementBatchTransactionsReportFieldsEnum).filter((e) => {
			return values.settlement_batch_transaction_report?.indexOf(e) === -1;
		});

		const settlement_batch_sale_report = Object.values(SettlementBatchSalesReportFieldsEnum).filter((e) => {
			return values.settlement_batch_transaction_report?.indexOf(e) === -1;
		});

		const settlement_batch_sale_table = Object.values(SettlementBatchSalesTableFieldsEnum).filter((e) => {
			return values.settlement_batch_transaction_table?.indexOf(e) === -1;
		});

		try {
			const requestParams: CreateMerchantRequest = {
				createMerchantDto: {
					name: values.name,
					description: values.description,
					document: values.document,
					code: values.code,
					is_active: values.is_active,
					risk_active: values.risk_active,
					min_risk: Math.trunc(values.min_risk * 100),
					merchant_api_key: values.merchant_api_key,
					merchant_account: values.merchant_account,
					merchant_subjects: values.merchant_subjects,
					seller_subjects: values.seller_subjects,
					reports_permissions: {
						sales_history: { denied_fields: sales_history_report },
						settlement_batch_transaction: { denied_fields: settlement_batch_transaction_report },
						settlement_batch_sale: { denied_fields: settlement_batch_sale_report },
					},
					tables_permissions: {
						sales_history: { denied_fields: sales_history_table },
						settlement_batch_sale: { denied_fields: settlement_batch_sale_table },
					},
					three_ds_active: values.three_ds_active === 'true' ? true : false,
					min_three_ds: Math.trunc(values.min_three_ds * 100),
					three_ds_value: Math.trunc(values.three_ds_value * 100),
					risk_value: Math.trunc(values.risk_value * 100),
					type: values.type?.length ? values.type : null,
				},
			};

			await merchantsApi.createMerchant(requestParams);

			addToast('Merchant criado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao criar Merchant', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			resetForm();
		}
	};

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink href='/admin/merchants'>Merchants</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontWeight='semibold'>Criar Novo Merchant</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper id='create-merchant-formik-paper-text' p={6}>
				<Flex flexDirection='column'>
					<>
						<Formik initialValues={createMerchantInitialValues} validationSchema={createMerchantSchema} onSubmit={createMerchant}>
							{({ handleSubmit, isValid, setFieldValue, errors, values }) => {
								return (
									<form onSubmit={handleSubmit} style={{ width: '100%' }}>
										<Stack spacing={4}>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='name' isRequired>
													<FormLabel id='create-merchant-name-label' fontSize='md' fontWeight='bold'>
														Nome
													</FormLabel>
													<FormInput id='create-merchant-name-form-input' name='name' />
													<FormErrorMessage id='create-merchant-name-form-error-text' name='name' />
												</FormControl>

												<FormControl id='document' isRequired>
													<FormLabel id='create-merchant-document-label' fontSize='md' fontWeight='bold'>
														CPF/CNPJ
													</FormLabel>
													<FormCpfCnpjInput
														id='create-merchant-document-form-cpf-cnpj-input'
														name='document'
														isDisabled={false}
														isSearchPayer={false}
													/>
													<FormErrorMessage id='create-merchant-document-form-error-text' name='document' />
												</FormControl>

												<FormControl id='description' isRequired>
													<FormLabel id='create-merchant-description-label' fontSize='md' fontWeight='bold'>
														Descrição
													</FormLabel>
													<FormInput id='create-merchant-description-form-input' as={Input} name='description' />
													<FormErrorMessage id='create-merchant-description-form-error-text' name='description' />
												</FormControl>
											</Stack>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='code' isRequired>
													<FormLabel id='code-label' fontSize='md' fontWeight='bold'>
														Código
													</FormLabel>
													<FormInput id='create-merchant-code-form-input' name='code' />
													<FormErrorMessage id='create-merchant-code-form-error-text' name='code' />
												</FormControl>
												<FormControl id='min_risk'>
													<FormLabel id='min-risk-label' fontSize='md' fontWeight='bold'>
														Mínimo Para Antifraude
													</FormLabel>
													<FormCurrencyInput id='create-merchant-min-risk-form-currency-text' borderColor='darkGrey' name='min_risk' />
													<FormErrorMessage id='create-merchant-min-risk-form-error-text' name='min_risk' />
												</FormControl>
												<FormControl id='risk_value'>
													<FormLabel id='create-merchant-risk-value-label' fontSize='md' fontWeight='bold'>
														Valor Antifraude
													</FormLabel>
													<FormCurrencyInput id='create-merchant-risk-value-form-currency-input' borderColor='darkGrey' name='risk_value' />
													<FormErrorMessage id='create-merchant-risk-value-form-error-text' name='risk_value' />
												</FormControl>
											</Stack>

											<ThreeDsForm errors={errors} />

											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<Box minW='33%'>
													<FormControl id='type'>
														<FormLabel id='type-label' fontSize='md' fontWeight='bold'>
															Tipo
														</FormLabel>
														<FormSelect id='create-merchant-type-form-select' name='type'>
															<option value='' />
															{merchant_types.map((type) => (
																<option value={type.value} key={type.key}>
																	{type.text}
																</option>
															))}
														</FormSelect>
														<FormErrorMessage id='create-merchant-type-form-error-text' name='type' />
													</FormControl>
												</Box>

												<Stack isInline spacing={4} align='end'>
													<Checkbox
														id='active-merchant-checkbox'
														name='is_active'
														isChecked={values.is_active}
														color={`darkGrey`}
														fontSize={`md`}
														fontWeight={`medium`}
														onChange={(e) => {
															setFieldValue('is_active', e.target.checked);
														}}
													>
														Ativo
													</Checkbox>
													<Checkbox
														id='risk-active-merchant-checkbox'
														name='risk_active'
														isChecked={values.risk_active}
														color={`darkGrey`}
														fontSize={`md`}
														fontWeight={`medium`}
														onChange={(e) => {
															setFieldValue('risk_active', e.target.checked);
														}}
													>
														Risco Ativo
													</Checkbox>
												</Stack>
											</Stack>

											<Stack isInline spacing={4}>
												<FormControl id='merchant_subjects' my={2}>
													<FormLabel id='permitions-merchant-label' fontSize={`md`} fontWeight='bold'>
														Permissões Merchant
													</FormLabel>
													<MultiSelect
														id='merchant_subjects'
														placeholder='Permissões Merchant'
														options={Object.values(PermissionSubjectEnum)
															?.slice(1)
															?.map((value) => ({
																label: subjectTitle[value],
																value: value,
															}))}
														value={values.merchant_subjects?.map((value) => ({
															label: subjectTitle[value],
															value: value,
														}))}
														onChange={(values) => {
															setFieldValue(
																'merchant_subjects',
																values.map((item) => item.value)
															);
														}}
													/>
													<FormErrorMessage id='create-merchant-subjects-form-error-text' name='merchant_subjects' />
												</FormControl>
											</Stack>
											<Stack isInline spacing={4}>
												<FormControl id='seller_subjects' my={2}>
													<FormLabel id='permitions-seller-label' fontSize={`md`} fontWeight='bold'>
														Permissões Estabelecimento
													</FormLabel>
													<MultiSelect
														id='seller_subjects'
														placeholder='Permissões Merchant'
														options={Object.values(PermissionSubjectEnum)
															?.slice(1)
															?.map((value) => ({
																label: subjectTitle[value],
																value: value,
															}))}
														value={values.seller_subjects?.map((value) => ({
															label: subjectTitle[value],
															value: value,
														}))}
														onChange={(values) => {
															setFieldValue(
																'seller_subjects',
																values.map((item) => item.value)
															);
														}}
													/>
													<FormErrorMessage id='create-merchant-seller-subjects-form-error-text' name='merchant_subjects' />
												</FormControl>
											</Stack>
											<Stack isInline spacing={4}>
												<FormControl id='reports_permissions'>
													<FormLabel id='reports-label' fontSize={`md`} fontWeight='bold' cursor='pointer' onClick={handleToggleReports}>
														Permissões de Relatórios &nbsp;
														<Icon as={FiEdit} boxSize={4} />
													</FormLabel>
													<Fade in={showReports} unmountOnExit>
														<Stack spacing='2'>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text id='sale-payments-text' color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico de Vendas / Pagamentos
																</Text>
															</Flex>
															<MultiSelect
																id='sales_history_report'
																placeholder='Permissões do Histórico de Vendas'
																options={Object.values(SalesHistoryReportPermissionsFieldsEnum)?.map((value) => ({
																	label: salesHistoryReport[value],
																	value: value,
																}))}
																value={values.sales_history_report?.map((value) => ({
																	label: salesHistoryReport[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'sales_history_report',
																		values.map((item) => item.value)
																	);
																}}
															/>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text id='transaction-text' color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico Liquidação - Transações
																</Text>
															</Flex>
															<MultiSelect
																id='settlement_batch_transaction_report'
																placeholder='Permissões do Histórico Liquidação - Transações'
																options={Object.values(SettlementBatchTransactionsReportFieldsEnum)?.map((value) => ({
																	label: settlementBatchTransactionReport[value],
																	value: value,
																}))}
																value={values.settlement_batch_transaction_report?.map((value) => ({
																	label: settlementBatchTransactionReport[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'settlement_batch_transaction_report',
																		values.map((item) => item.value)
																	);
																}}
															/>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text id='batch-sales-text' color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico Liquidação - Transações do Lote de Liquidação
																</Text>
															</Flex>
															<MultiSelect
																id='settlement_batch_sale_report'
																placeholder='Permissões do Histórico Liquidação - Transações do Lote de Liquidação'
																options={Object.values(SettlementBatchSalesReportFieldsEnum)?.map((value) => ({
																	label: settlementBatchSaleReport[value],
																	value: value,
																}))}
																value={values.settlement_batch_sale_report?.map((value) => ({
																	label: settlementBatchSaleReport[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'settlement_batch_sale_report',
																		values.map((item) => item.value)
																	);
																}}
															/>
														</Stack>
													</Fade>
												</FormControl>
											</Stack>

											<Stack isInline spacing={4}>
												<FormControl id='tables_permissions'>
													<FormLabel id='permitions-table-label' fontSize={`md`} fontWeight='bold' cursor='pointer' onClick={handleToggleTables}>
														Permissões de Tabelas &nbsp;
														<Icon as={FiEdit} boxSize={4} />
													</FormLabel>
													<Fade in={showTables} unmountOnExit>
														<Stack spacing='2'>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text id='sales-history-text' color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico de Vendas
																</Text>
															</Flex>
															<MultiSelect
																id='sales_history_table'
																placeholder='Permissões do Histórico de Vendas'
																options={Object.values(SalesHistoryTablePermissionsFieldsEnum)?.map((value) => ({
																	label: salesHistoryTable[value],
																	value: value,
																}))}
																value={values.sales_history_table?.map((value) => ({
																	label: salesHistoryTable[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'sales_history_table',
																		values.map((item) => item.value)
																	);
																}}
															/>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text id='settlement-batch-text' color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico Liquidação - Transações do Lote de Liquidação
																</Text>
															</Flex>
															<MultiSelect
																id='settlement_batch_sale_table'
																placeholder='Permissões do Histórico Liquidação - Transações do Lote de Liquidação'
																options={Object.values(SettlementBatchSalesTableFieldsEnum)?.map((value) => ({
																	label: settlementBatchSaleTable[value],
																	value: value,
																}))}
																value={values.settlement_batch_sale_table?.map((value) => ({
																	label: settlementBatchSaleTable[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'settlement_batch_sale_table',
																		values.map((item) => item.value)
																	);
																}}
															/>
														</Stack>
													</Fade>
												</FormControl>
											</Stack>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='merchant_api_key'>
													<FormLabel id='api-key-label' fontSize='md' fontWeight='bold'>
														API KEY
													</FormLabel>
													<FormInput id='create-merchant-api-key-form-input' name='merchant_api_key' />
													<FormErrorMessage id='create-merchant-api-key-form-error-text' name='merchant_api_key' />
												</FormControl>
												<FormControl id='merchant_account'>
													<FormLabel id='account-label' fontSize='md' fontWeight='bold'>
														ACCOUNT
													</FormLabel>
													<FormInput id='create-merchant-account-form-input' as={Input} name='merchant_account' />
													<FormErrorMessage id='create-merchant-account-form-error-text' name='merchant_account' />
												</FormControl>
											</Stack>
										</Stack>

										<Center pt='6'>
											<Button
												id='create-merchant-button'
												bgColor='primary'
												size='md'
												disabled={!isValid}
												type='submit'
												isLoading={isLoading}
											>
												Criar Merchant
											</Button>
										</Center>
									</form>
								);
							}}
						</Formik>
					</>
				</Flex>
			</Paper>
		</Flex>
	);
};

export default CreateMerchant;
