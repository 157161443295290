/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Acquirer,
    AcquirerFromJSON,
    AcquirerFromJSONTyped,
    AcquirerToJSON,
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSellerCredentialResponseDto
 */
export interface GetSellerCredentialResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerCredentialResponseDto
     */
    id?: string;
    /**
     * 
     * @type {Acquirer}
     * @memberof GetSellerCredentialResponseDto
     */
    acquirer: Acquirer;
    /**
     * 
     * @type {Seller}
     * @memberof GetSellerCredentialResponseDto
     */
    seller: Seller;
    /**
     * 
     * @type {string}
     * @memberof GetSellerCredentialResponseDto
     */
    api_key?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerCredentialResponseDto
     */
    client_id: string;
}

export function GetSellerCredentialResponseDtoFromJSON(json: any): GetSellerCredentialResponseDto {
    return GetSellerCredentialResponseDtoFromJSONTyped(json, false);
}

export function GetSellerCredentialResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerCredentialResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'acquirer': AcquirerFromJSON(json['acquirer']),
        'seller': SellerFromJSON(json['seller']),
        'api_key': !exists(json, 'api_key') ? undefined : json['api_key'],
        'client_id': json['client_id'],
    };
}

export function GetSellerCredentialResponseDtoToJSON(value?: GetSellerCredentialResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'acquirer': AcquirerToJSON(value.acquirer),
        'seller': SellerToJSON(value.seller),
        'api_key': value.api_key,
        'client_id': value.client_id,
    };
}


