import React from 'react';

import {
	Flex,
	HStack,
	Center,
	FormControl,
	InputGroup,
	InputRightElement,
	IconButton,
	BreadcrumbItem,
	BreadcrumbLink,
} from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';

import { SalesPlansApi } from '../../clients';

import Button from '../../components/Button';
import Paper from '../../containers/Paper';
import SampleTable from './components/SampleTable';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { useHistory } from 'react-router-dom';

import Input from '../../components/Input';

import { SearchIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import Breadcrumb from 'components/Breadcrumb';

const SalesPlan: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const salesPlansApi = new SalesPlansApi(apiConfig);
	const history = useHistory();

	const { isBackoffice } = useAuth();

	const [isLoading, setIsLoading] = React.useState(false);
	const [filters, setFilters] = React.useState({});

	const [salesPlans, setSalesPlans] = React.useState<any>();
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);

	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();

	const initialValues = {
		name: '',
	};

	async function fetchListSalesPlanRequest(params) {
		const { name } = params;

		setIsLoading(true);

		try {
			const response = await salesPlansApi.listAllSalePlan({
				limit: pagination.limit,
				currentPage: pagination.currentPage,
				name,
			});

			setSalesPlans(response.results);
			setTotalPages(response.total_pages);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchListSalesPlanRequest(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, pagination]);

	const handleFormSubmit = async (values) => {
		setFilters(values);
		fetchListSalesPlanRequest(values);
	};

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<HStack justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Planos de Venda
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>

				<Button
					id='navigate-to-create-sale-plan-button'
					title='Criar'
					isDisabled={!isBackoffice}
					bgColor='primary'
					onClick={() => {
						history.push(`/admin/planos-de-venda/criar`);
					}}
				>
					Criar
				</Button>
			</HStack>

			<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Paper id='sale-plan-paper-text' p={6}>
								<HStack spacing={4} mb={4}>
									<Center>
										<FormControl>
											<InputGroup>
												<Input
													id='sale-plan-name-input'
													name='name'
													placeholder='Nome do Plano de Venda'
													value={values.name}
													onChange={(event) => {
														handleChange(event);
													}}
													borderColor='darkGrey'
													onBlur={() => handleSubmit()}
												/>
												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Search database'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</FormControl>
									</Center>

									<Center>
										{values.name !== '' && (
											<Button
												id='clear-filter-button'
												onClick={() => {
													resetForm();
													handleSubmit();
												}}
												paddingInline='10px'
												_focus={{ outline: 'none' }}
												color='secondary'
												bgColor='transparent'
											>
												Limpar Filtros
											</Button>
										)}
									</Center>
								</HStack>

								<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
									{salesPlans && (
										<SampleTable data={salesPlans} setPagination={setPagination} pagination={pagination} totalPages={totalPages} />
									)}
								</Flex>
							</Paper>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default SalesPlan;
