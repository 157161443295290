/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSellerRiskDto
 */
export interface GetSellerRiskDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerRiskDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerRiskDto
     */
    classification: GetSellerRiskDtoClassificationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerRiskDto
     */
    negative_media: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerRiskDto
     */
    lawsuits: boolean;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof GetSellerRiskDto
     */
    attachments: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof GetSellerRiskDto
     */
    note: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerRiskDto
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerRiskDto
     */
    updated_at?: string;
}

/**
* @export
* @enum {string}
*/
export enum GetSellerRiskDtoClassificationEnum {
    Lower = 'lower',
    Medium = 'medium',
    High = 'high'
}

export function GetSellerRiskDtoFromJSON(json: any): GetSellerRiskDto {
    return GetSellerRiskDtoFromJSONTyped(json, false);
}

export function GetSellerRiskDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerRiskDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'classification': json['classification'],
        'negative_media': json['negative_media'],
        'lawsuits': json['lawsuits'],
        'attachments': ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'note': json['note'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function GetSellerRiskDtoToJSON(value?: GetSellerRiskDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'classification': value.classification,
        'negative_media': value.negative_media,
        'lawsuits': value.lawsuits,
        'attachments': ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'note': value.note,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}


