/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSplitRulesSimulationDto
 */
export interface GetSplitRulesSimulationDto {
    /**
     * 
     * @type {number}
     * @memberof GetSplitRulesSimulationDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetSplitRulesSimulationDto
     */
    seller: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSplitRulesSimulationDto
     */
    no_cost?: boolean;
}

export function GetSplitRulesSimulationDtoFromJSON(json: any): GetSplitRulesSimulationDto {
    return GetSplitRulesSimulationDtoFromJSONTyped(json, false);
}

export function GetSplitRulesSimulationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSplitRulesSimulationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'seller': json['seller'],
        'no_cost': !exists(json, 'no_cost') ? undefined : json['no_cost'],
    };
}

export function GetSplitRulesSimulationDtoToJSON(value?: GetSplitRulesSimulationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'seller': value.seller,
        'no_cost': value.no_cost,
    };
}


