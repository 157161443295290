/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetBilletDataDto,
    GetBilletDataDtoFromJSON,
    GetBilletDataDtoFromJSONTyped,
    GetBilletDataDtoToJSON,
    GetBilletPayerAddressDto,
    GetBilletPayerAddressDtoFromJSON,
    GetBilletPayerAddressDtoFromJSONTyped,
    GetBilletPayerAddressDtoToJSON,
    GetOneBilletSaleDto,
    GetOneBilletSaleDtoFromJSON,
    GetOneBilletSaleDtoFromJSONTyped,
    GetOneBilletSaleDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOneBilletDto
 */
export interface GetOneBilletDto {
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    id: string;
    /**
     * 
     * @type {GetOneBilletSaleDto}
     * @memberof GetOneBilletDto
     */
    sale: GetOneBilletSaleDto;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    expires_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    payer_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    payer_email: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneBilletDto
     */
    payer_document: string;
    /**
     * 
     * @type {GetBilletPayerAddressDto}
     * @memberof GetOneBilletDto
     */
    payer_address: GetBilletPayerAddressDto;
    /**
     * 
     * @type {GetBilletDataDto}
     * @memberof GetOneBilletDto
     */
    billet_data?: GetBilletDataDto;
}

export function GetOneBilletDtoFromJSON(json: any): GetOneBilletDto {
    return GetOneBilletDtoFromJSONTyped(json, false);
}

export function GetOneBilletDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOneBilletDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sale': GetOneBilletSaleDtoFromJSON(json['sale']),
        'url': json['url'],
        'expires_at': json['expires_at'],
        'barcode': json['barcode'],
        'status': json['status'],
        'description': json['description'],
        'email': json['email'],
        'payer_name': json['payer_name'],
        'payer_email': json['payer_email'],
        'payer_document': json['payer_document'],
        'payer_address': GetBilletPayerAddressDtoFromJSON(json['payer_address']),
        'billet_data': !exists(json, 'billet_data') ? undefined : GetBilletDataDtoFromJSON(json['billet_data']),
    };
}

export function GetOneBilletDtoToJSON(value?: GetOneBilletDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sale': GetOneBilletSaleDtoToJSON(value.sale),
        'url': value.url,
        'expires_at': value.expires_at,
        'barcode': value.barcode,
        'status': value.status,
        'description': value.description,
        'email': value.email,
        'payer_name': value.payer_name,
        'payer_email': value.payer_email,
        'payer_document': value.payer_document,
        'payer_address': GetBilletPayerAddressDtoToJSON(value.payer_address),
        'billet_data': GetBilletDataDtoToJSON(value.billet_data),
    };
}


