/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelPaymentDTO
 */
export interface CancelPaymentDTO {
    /**
     * Id na adquirente
     * @type {string}
     * @memberof CancelPaymentDTO
     */
    acquirer_reference?: string;
    /**
     * Descrição
     * @type {string}
     * @memberof CancelPaymentDTO
     */
    description?: string;
}

export function CancelPaymentDTOFromJSON(json: any): CancelPaymentDTO {
    return CancelPaymentDTOFromJSONTyped(json, false);
}

export function CancelPaymentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelPaymentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acquirer_reference': !exists(json, 'acquirer_reference') ? undefined : json['acquirer_reference'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CancelPaymentDTOToJSON(value?: CancelPaymentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acquirer_reference': value.acquirer_reference,
        'description': value.description,
    };
}


