/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
    CustomerDTO,
    CustomerDTOFromJSON,
    CustomerDTOFromJSONTyped,
    CustomerDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePixPaymentWithSplitInternalDto
 */
export interface CreatePixPaymentWithSplitInternalDto {
    /**
     * Valor em centavos
     * @type {number}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    amount_cents: number;
    /**
     * Descrição
     * @type {string}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    description: string;
    /**
     * Cliente
     * @type {CustomerDTO}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    customer: CustomerDTO;
    /**
     * Id da venda
     * @type {string}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    sale_id?: string;
    /**
     * Criado via link de protesto
     * @type {boolean}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    protest_link?: boolean;
    /**
     * Valor da taxa
     * @type {number}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    amount_fee?: number;
    /**
     * Há divisão de pagamento
     * @type {boolean}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    has_split_rules?: boolean;
    /**
     * Divisão de pagamento
     * @type {Array<CreateSplitRulesDto>}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    split_rules?: Array<CreateSplitRulesDto>;
    /**
     * Há confirmação de pagamento
     * @type {boolean}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    confirmation_required?: boolean;
    /**
     * Identificação do extrato
     * @type {string}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    extract_identification?: string;
    /**
     * Identificador externo
     * @type {string}
     * @memberof CreatePixPaymentWithSplitInternalDto
     */
    service_id?: string;
}

export function CreatePixPaymentWithSplitInternalDtoFromJSON(json: any): CreatePixPaymentWithSplitInternalDto {
    return CreatePixPaymentWithSplitInternalDtoFromJSONTyped(json, false);
}

export function CreatePixPaymentWithSplitInternalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePixPaymentWithSplitInternalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'description': json['description'],
        'customer': CustomerDTOFromJSON(json['customer']),
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'protest_link': !exists(json, 'protest_link') ? undefined : json['protest_link'],
        'amount_fee': !exists(json, 'amount_fee') ? undefined : json['amount_fee'],
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'extract_identification': !exists(json, 'extract_identification') ? undefined : json['extract_identification'],
        'service_id': !exists(json, 'service_id') ? undefined : json['service_id'],
    };
}

export function CreatePixPaymentWithSplitInternalDtoToJSON(value?: CreatePixPaymentWithSplitInternalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'description': value.description,
        'customer': CustomerDTOToJSON(value.customer),
        'sale_id': value.sale_id,
        'protest_link': value.protest_link,
        'amount_fee': value.amount_fee,
        'has_split_rules': value.has_split_rules,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
        'confirmation_required': value.confirmation_required,
        'extract_identification': value.extract_identification,
        'service_id': value.service_id,
    };
}


