import React from 'react';

import { BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react';
import { startOfMonth, format, endOfMonth, parse } from 'date-fns';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';

import { getApiAuthConfig } from 'services/api.service';

import { SettlementsBatchesApi, NoPaginatedListAllSettlementDetailedWithRequest } from 'clients';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { useAuth } from '../../contexts/AuthProvider';

import CalendarComponent from './components/CalendarComponent';
import Breadcrumb from 'components/Breadcrumb';

const Calendar: React.FC = () => {
	const { addToast } = useToasts();
	const { isMerchant, user } = useAuth();
	const [isLoading, setIsLoading] = React.useState(false);
	const [currentMonth, setCurrentMonth] = React.useState<Date>(startOfMonth(new Date()));
	const [batches, setBatches] = React.useState<any[]>([]);

	const { currentSeller } = useCurrentSeller();

	async function fetchSettlementBatches(startMonth) {
		setIsLoading(true);

		try {
			const apiConfig = getApiAuthConfig();
			let requestParams: NoPaginatedListAllSettlementDetailedWithRequest = {
				startDate: format(startMonth, 'yyyy-MM-dd'),
				endDate: format(endOfMonth(startMonth), 'yyyy-MM-dd'),
				sellerId: currentSeller?.id,
			};

			if(!currentSeller && isMerchant) {
				requestParams.merchantId = user?.merchant?.id;
			}

			const settlementBatchApi = new SettlementsBatchesApi(apiConfig);
			const response = await settlementBatchApi.noPaginatedListAllSettlementDetailedWith(requestParams);

			setBatches(response.results);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchSettlementBatches(currentMonth);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentMonth, currentSeller]);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Gestão Financeira</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink opacity='0.9' cursor='unset' fontSize='md' fontWeight='semibold'>
						Calendário
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<CalendarComponent
				setCurrentMonth={setCurrentMonth}
				currentMonth={currentMonth}
				sellerId={currentSeller?.id}
				events={batches.map((batch) => {
					return {
						status: batch.status,
						id: batch.id,
						title: (batch.total / 100).toString(),
						date: parse(batch.settlement_day, 'yyyy-MM-dd', new Date()),
					};
				})}
			/>
		</Flex>
	);
};

export default Calendar;
