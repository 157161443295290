import { Box, Flex, FormControl, FormLabel, Input, SimpleGrid, Text, useBreakpointValue, Checkbox } from '@chakra-ui/react';
import { Formik } from 'formik';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { CreatePaperRollOrderDto, GetSellerDto, PaperRollOrderApi } from '../../../clients';
import Button from '../../../components/Button';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormSelect from '../../../components/Form/FormSelect';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import { states } from '../../../config/constants';
import NewModal from '../../../containers/NewModal';
import { getApiAuthConfig } from '../../../services/api.service';
import getzipcodeService from '../../../services/getzipcode.service';

type HeaderModalProps = {
	isOpen: boolean;
	seller: GetSellerDto;
	onClose: () => void;
};

const HeaderModal: React.FC<HeaderModalProps> = ({ seller, isOpen, onClose }) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const [isLoading, setIsLoading] = React.useState(false);
	const { addToast } = useToasts();

	const emptyAddress = {
		zipcode: '',
		street: '',
		number: '',
		complement: '',
		district: '',
		city: '',
		state: '',
	};

	const requests = ['8', '12', '24'];

	const initialAddress = seller.address || emptyAddress;

	const initialValues = {
		requesting_amount: '',
		description: '',
		change_address: false,
		address: initialAddress,
	};

	const handleFormSubmit = async (values) => {
		setIsLoading(true);
		try {
			const apiConfig = getApiAuthConfig();
			const paperRollOrderApi = new PaperRollOrderApi(apiConfig);

			const parsedBody: CreatePaperRollOrderDto = {
				requesting_amount: values.requesting_amount,
				description: values.description,
				...(values.change_address && { address: values.address }),
			};

			await paperRollOrderApi.createNewPaperRollOrder({ id: seller.id, createPaperRollOrderDto: parsedBody });
			addToast('Pedido criado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Falha na criação do pedido', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			onClose();
			setIsLoading(false);
		}
	};

	const handleAddressChange = (setFieldValue, isChecked) => {
		setFieldValue('change_address', isChecked);
		setFieldValue('address', isChecked ? emptyAddress : initialAddress);
	};

	return (
		<NewModal isOpen={isOpen} onClose={onClose} size='4xl' showCloseButton>
			<Box borderRadius='md'>
				<Flex py={4} px={12} flexDirection='column'>
					<Text align='center' color='darkGrey' fontSize='md' fontWeight='bold'>
						Solicitação de bobina
					</Text>

					<Formik
						enableReinitialize
						initialValues={initialValues}
						onSubmit={async (values, { resetForm }) => {
							await handleFormSubmit(values);
							resetForm({ values: initialValues });
						}}
					>
						{({ handleSubmit, setFieldValue, values }) => (
							<form onSubmit={handleSubmit}>
								<SimpleGrid columns={isMobile ? 1 : 2} spacing='4' w='100%' mt='2'>
									<Flex>
										<FormControl id='requesting_amount' isRequired>
											<FormLabel fontSize='md' fontWeight='bold'>
												Quantidade
											</FormLabel>
											<FormSelect id='requesting-amount-form-select' name='requesting_amount' isDisabled={isLoading}>
												<option value='' />
												{requests.map((value, index) => (
													<option value={value} key={index}>
														{value}
													</option>
												))}
											</FormSelect>
											<FormErrorMessage id='requesting-amount-form-error' name='requesting_amount' />
										</FormControl>
									</Flex>
									<Flex>
										<FormControl id='description'>
											<FormLabel fontSize='md' fontWeight='bold'>
												Descrição
											</FormLabel>
											<FormInput id='description-form-input' as={Input} name='description' />
											<FormErrorMessage id='description-form-error' name='description' />
										</FormControl>
									</Flex>
								</SimpleGrid>
								<SimpleGrid columns={1} spacing='4' w='100%' my='15px'>
									<Flex>
										<FormControl id='change_address'>
											<FormInput id='change-address-input' as={Checkbox} name='change_address' onChange={(e) => handleAddressChange(setFieldValue, e.target.checked)}>
												Alterar endereço de envio
											</FormInput>
											<FormErrorMessage id='change-address-error' name='change_address' />
										</FormControl>
									</Flex>
								</SimpleGrid>
								<SimpleGrid columns={isMobile ? 1 : 2} spacing='4' w='100%' mt='2'>
									<Flex>
										<FormControl id='address.zipcode' isRequired={values.change_address} isDisabled={!values.change_address}>
											<FormLabel fontSize='md' fontWeight='bold'>
												CEP
											</FormLabel>
											<FormZipcodeInput
											id='zipcode-input'
												name='address.zipcode'
												performSearch={getzipcodeService}
												onSearchResults={(data) => {
													setFieldValue('address.street', data.logradouro);
													setFieldValue('address.district', data.bairro);
													setFieldValue('address.city', data.localidade);
													setFieldValue('address.state', data.uf);
													setFieldValue('address.complement', data.complemento);
												}}
											/>
											<FormErrorMessage id='zipcode-error' name='address.zipcode' />
										</FormControl>
									</Flex>
									<Flex>
										<FormControl id='address.street' isRequired={values.change_address} isDisabled={!values.change_address}>
											<FormLabel fontSize='md' fontWeight='bold'>
												Logradouro
											</FormLabel>
											<FormInput id='street-input' as={Input} name='address.street' />
											<FormErrorMessage id='street-error' name='address.street' />
										</FormControl>
									</Flex>
									<Flex>
										<FormControl id='address.number' isRequired={values.change_address} isDisabled={!values.change_address}>
											<FormLabel fontSize='md' fontWeight='bold'>
												Número
											</FormLabel>
											<FormInput id='number-input' as={Input} name='address.number' />
											<FormErrorMessage id='number-error' name='address.number' />
										</FormControl>
									</Flex>
									<Flex>
										<FormControl id='address.complement' isDisabled={!values.change_address}>
											<FormLabel fontSize='md' fontWeight='bold'>
												Complemento
											</FormLabel>
											<FormInput id='complement-input' as={Input} name='address.complement' />
											<FormErrorMessage id='complement-error' name='address.complement' />
										</FormControl>
									</Flex>
									<Flex>
										<FormControl id='address.district' isRequired={values.change_address} isDisabled={!values.change_address}>
											<FormLabel fontSize='md' fontWeight='bold'>
												Bairro
											</FormLabel>
											<FormInput id='district-input' as={Input} name='address.district' />
											<FormErrorMessage id='district-error' name='address.district' />
										</FormControl>
									</Flex>
									<Flex>
										<FormControl id='address.city' isRequired={values.change_address} isDisabled={!values.change_address}>
											<FormLabel fontSize='md' fontWeight='bold'>
												Cidade
											</FormLabel>
											<FormInput id='city-input' as={Input} name='address.city' />
											<FormErrorMessage id='city-error' name='address.city' />
										</FormControl>
									</Flex>
									<Flex>
										<FormControl id='address.state' isRequired={values.change_address} isDisabled={!values.change_address}>
											<FormLabel fontSize='md' fontWeight='bold'>
												UF
											</FormLabel>
											<FormSelect id='state-select' fontSize='md' name='address.state'>
												<option value='' />
												{states.map((state) => (
													<option value={state.value} key={state.value}>
														{state.text}
													</option>
												))}
											</FormSelect>
											<FormErrorMessage id='state-error' name='address.state' />
										</FormControl>
									</Flex>
								</SimpleGrid>
								<Flex>
									<Button id='send-button' mt='4' bgColor='primary' color='white' borderColor='black' isFullWidth isLoading={isLoading} type='submit'>
										Enviar
									</Button>
								</Flex>
							</form>
						)}
					</Formik>
				</Flex>
			</Box>
		</NewModal>
	);
};

export default HeaderModal;
