/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateResponsibleDto
 */
export interface UpdateResponsibleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateResponsibleDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResponsibleDto
     */
    type?: UpdateResponsibleDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResponsibleDto
     */
    is_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateResponsibleDto
     */
    phone?: string;
}

/**
* @export
* @enum {string}
*/
export enum UpdateResponsibleDtoTypeEnum {
    Commercial = 'commercial',
    Support = 'support'
}

export function UpdateResponsibleDtoFromJSON(json: any): UpdateResponsibleDto {
    return UpdateResponsibleDtoFromJSONTyped(json, false);
}

export function UpdateResponsibleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResponsibleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'is_active': !exists(json, 'is_active') ? undefined : json['is_active'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function UpdateResponsibleDtoToJSON(value?: UpdateResponsibleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'is_active': value.is_active,
        'phone': value.phone,
    };
}


