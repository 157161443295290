/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateProtestLinkPayerDto,
    CreateProtestLinkPayerDtoFromJSON,
    CreateProtestLinkPayerDtoFromJSONTyped,
    CreateProtestLinkPayerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateProtestLinkDto
 */
export interface CreateProtestLinkDto {
    /**
     * 
     * @type {number}
     * @memberof CreateProtestLinkDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProtestLinkDto
     */
    fee: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProtestLinkDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProtestLinkDto
     */
    expires_in?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProtestLinkDto
     */
    seller_id: string;
    /**
     * 
     * @type {CreateProtestLinkPayerDto}
     * @memberof CreateProtestLinkDto
     */
    payer: CreateProtestLinkPayerDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProtestLinkDto
     */
    payment_methods: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProtestLinkDto
     */
    pre_capture?: boolean;
}

export function CreateProtestLinkDtoFromJSON(json: any): CreateProtestLinkDto {
    return CreateProtestLinkDtoFromJSONTyped(json, false);
}

export function CreateProtestLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProtestLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'fee': json['fee'],
        'description': json['description'],
        'expires_in': !exists(json, 'expires_in') ? undefined : json['expires_in'],
        'seller_id': json['seller_id'],
        'payer': CreateProtestLinkPayerDtoFromJSON(json['payer']),
        'payment_methods': json['payment_methods'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
    };
}

export function CreateProtestLinkDtoToJSON(value?: CreateProtestLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'fee': value.fee,
        'description': value.description,
        'expires_in': value.expires_in,
        'seller_id': value.seller_id,
        'payer': CreateProtestLinkPayerDtoToJSON(value.payer),
        'payment_methods': value.payment_methods,
        'pre_capture': value.pre_capture,
    };
}


