/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetLogoDto,
    GetLogoDtoFromJSON,
    GetLogoDtoFromJSONTyped,
    GetLogoDtoToJSON,
    GetProtestLinkPayerDto,
    GetProtestLinkPayerDtoFromJSON,
    GetProtestLinkPayerDtoFromJSONTyped,
    GetProtestLinkPayerDtoToJSON,
    GetProtestLinkSaleDto,
    GetProtestLinkSaleDtoFromJSON,
    GetProtestLinkSaleDtoFromJSONTyped,
    GetProtestLinkSaleDtoToJSON,
    GetProtestLinkSellerDto,
    GetProtestLinkSellerDtoFromJSON,
    GetProtestLinkSellerDtoFromJSONTyped,
    GetProtestLinkSellerDtoToJSON,
    GetProtestLinkUserDto,
    GetProtestLinkUserDtoFromJSON,
    GetProtestLinkUserDtoFromJSONTyped,
    GetProtestLinkUserDtoToJSON,
    GetThemeDto,
    GetThemeDtoFromJSON,
    GetThemeDtoFromJSONTyped,
    GetThemeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProtestLinkDto
 */
export interface GetProtestLinkDto {
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetProtestLinkDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof GetProtestLinkDto
     */
    fee: number;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    token: string;
    /**
     * 
     * @type {GetProtestLinkPayerDto}
     * @memberof GetProtestLinkDto
     */
    payer: GetProtestLinkPayerDto;
    /**
     * 
     * @type {GetProtestLinkUserDto}
     * @memberof GetProtestLinkDto
     */
    user: GetProtestLinkUserDto;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    status: GetProtestLinkDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    formatted_status: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProtestLinkDto
     */
    generated_by_payer: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    formatted_generated_by_payer: string;
    /**
     * 
     * @type {GetProtestLinkSaleDto}
     * @memberof GetProtestLinkDto
     */
    sale: GetProtestLinkSaleDto;
    /**
     * 
     * @type {GetProtestLinkSellerDto}
     * @memberof GetProtestLinkDto
     */
    seller: GetProtestLinkSellerDto;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    expires_in: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProtestLinkDto
     */
    billet_payment_is_permitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProtestLinkDto
     */
    qrcode_payment_is_permitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProtestLinkDto
     */
    card_payment_is_permitted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProtestLinkDto
     */
    qrcode_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProtestLinkDto
     */
    payment_methods?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetProtestLinkDto
     */
    pre_capture: boolean;
    /**
     * 
     * @type {GetLogoDto}
     * @memberof GetProtestLinkDto
     */
    logo: GetLogoDto;
    /**
     * 
     * @type {GetThemeDto}
     * @memberof GetProtestLinkDto
     */
    theme: GetThemeDto;
}

/**
* @export
* @enum {string}
*/
export enum GetProtestLinkDtoStatusEnum {
    Active = 'active',
    PendingCapture = 'pending_capture',
    Expired = 'expired',
    Canceled = 'canceled',
    Paid = 'paid'
}

export function GetProtestLinkDtoFromJSON(json: any): GetProtestLinkDto {
    return GetProtestLinkDtoFromJSONTyped(json, false);
}

export function GetProtestLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProtestLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'fee': json['fee'],
        'description': json['description'],
        'url': json['url'],
        'email': json['email'],
        'token': json['token'],
        'payer': GetProtestLinkPayerDtoFromJSON(json['payer']),
        'user': GetProtestLinkUserDtoFromJSON(json['user']),
        'status': json['status'],
        'formatted_status': json['formatted_status'],
        'generated_by_payer': json['generated_by_payer'],
        'formatted_generated_by_payer': json['formatted_generated_by_payer'],
        'sale': GetProtestLinkSaleDtoFromJSON(json['sale']),
        'seller': GetProtestLinkSellerDtoFromJSON(json['seller']),
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'expires_in': json['expires_in'],
        'billet_payment_is_permitted': !exists(json, 'billet_payment_is_permitted') ? undefined : json['billet_payment_is_permitted'],
        'qrcode_payment_is_permitted': !exists(json, 'qrcode_payment_is_permitted') ? undefined : json['qrcode_payment_is_permitted'],
        'card_payment_is_permitted': !exists(json, 'card_payment_is_permitted') ? undefined : json['card_payment_is_permitted'],
        'qrcode_url': !exists(json, 'qrcode_url') ? undefined : json['qrcode_url'],
        'payment_methods': !exists(json, 'payment_methods') ? undefined : json['payment_methods'],
        'pre_capture': json['pre_capture'],
        'logo': GetLogoDtoFromJSON(json['logo']),
        'theme': GetThemeDtoFromJSON(json['theme']),
    };
}

export function GetProtestLinkDtoToJSON(value?: GetProtestLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'fee': value.fee,
        'description': value.description,
        'url': value.url,
        'email': value.email,
        'token': value.token,
        'payer': GetProtestLinkPayerDtoToJSON(value.payer),
        'user': GetProtestLinkUserDtoToJSON(value.user),
        'status': value.status,
        'formatted_status': value.formatted_status,
        'generated_by_payer': value.generated_by_payer,
        'formatted_generated_by_payer': value.formatted_generated_by_payer,
        'sale': GetProtestLinkSaleDtoToJSON(value.sale),
        'seller': GetProtestLinkSellerDtoToJSON(value.seller),
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'expires_in': value.expires_in,
        'billet_payment_is_permitted': value.billet_payment_is_permitted,
        'qrcode_payment_is_permitted': value.qrcode_payment_is_permitted,
        'card_payment_is_permitted': value.card_payment_is_permitted,
        'qrcode_url': value.qrcode_url,
        'payment_methods': value.payment_methods,
        'pre_capture': value.pre_capture,
        'logo': GetLogoDtoToJSON(value.logo),
        'theme': GetThemeDtoToJSON(value.theme),
    };
}


