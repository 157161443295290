import React from 'react';

import { BreadcrumbItem, BreadcrumbLink, Flex, Hide, HStack, Spacer, Stack } from '@chakra-ui/react';

import { useParams, useHistory, Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';
import BoxPayer from './components/BoxPayer';

import {
	GetSaleDto,
	GetSaleDtoStatusEnum,
	GetSellerSaleRequest,
	PaymentsApi,
	PaymentsControllerCancelPaymentRequest,
	SellersApi,
	SalesApi,
	GetSaleRequest,
	PermissionActionsEnum,
	PermissionSubjectEnum,
} from '../../clients';

import Button from '../../components/Button';

import ReceiptModal from 'components/Receipt/ReceiptModal';
import CancelModal from './components/Modal';
import AlertModal from './components/AlertModal';
import CaptureAmountModal, { CaptureAmountModalHandle } from './components/CaptureAmountModal';

import { useCurrentSeller } from '../../contexts/SellerProvider';
import { getApiAuthConfig, parseExceptionMessage } from '../../services/api.service';
import SaleData from './components/SaleData';
import Can from '../../contexts/Can';
import SplitsTable from './components/SplitsTable';
import Breadcrumb from 'components/Breadcrumb';
import { useAuth } from 'contexts/AuthProvider';
import BoxBillPayment from './components/BoxBillPayment';

const SaleDetails: React.FC = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = React.useState(true);
	const [sale, setSale] = React.useState<GetSaleDto>();
	const [openReceiptModal, setOpenReceiptModal] = React.useState(false);
	const [openReceiptBillPaymentModal, setOpenReceiptBillPaymentModal] = React.useState(false);
	const [openConfirmPaymentCancel, setOpenConfirmPaymentCancel] = React.useState(false);
	const [openAlertCancel, setOpenAlertCancel] = React.useState(false);
	const [textAlertCancel, setTextAlertCancel] = React.useState('');
	const [filters, setFilters] = React.useState();

	const firstUpdate = React.useRef(true);

	const { id } = useParams();
	const { addToast } = useToasts();

	const { currentSeller } = useCurrentSeller();

	const apiConfig = getApiAuthConfig();
	const salesApi = new SalesApi(apiConfig);
	const sellersApi = new SellersApi(apiConfig);
	const paymentsApi = new PaymentsApi(apiConfig);

	const captureAmountModalRef = React.useRef<CaptureAmountModalHandle>(null);

	const { isBackoffice } = useAuth();

	React.useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		if (sale?.seller.id !== currentSeller?.id) {
			history.goBack();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller]);

	async function fetchGetSaleRequest() {
		setIsLoading(true);

		if (currentSeller) {
			try {
				const requestParams: GetSellerSaleRequest = {
					sellerId: currentSeller?.id!,
					saleId: id,
				};

				const response = await sellersApi.getSellerSale(requestParams);

				setSale(response);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: GetSaleRequest = {
					saleId: id,
				};

				const response = await salesApi.getSale(requestParams);

				setSale(response);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	async function fetchPaymentsControllerCancelPayment() {
		setIsLoading(true);

		try {
			const requestParams: PaymentsControllerCancelPaymentRequest = {
				sellerId: currentSeller?.id!,
				saleId: id,
				cancelPaymentDTO: {},
			};
			await paymentsApi.paymentsControllerCancelPayment(requestParams);

			setSale({ ...(sale as GetSaleDto), status: GetSaleDtoStatusEnum.PendingCancel });
			setTextAlertCancel(
				'Sua solicitação de cancelamento foi processada e está em análise. Assim que a transação for cancelada alteraremos  o status no painel  e o seu cliente receberá um email com a notificação do cancelamento'
			);
		} catch (error) {
			const errorMessage = await parseExceptionMessage(error, 'Não foi possível alterar a senha');
			if (errorMessage === 'Sale cannot be canceled') {
				setTextAlertCancel('O cancelamento não pode ser realizado pois não existe agenda financeira disponível.');
			} else {
				addToast('Erro ao cancelar venda!', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		} finally {
			setIsLoading(false);
			setOpenAlertCancel(true);
		}
	}

	async function handleCaptureModalOpen() {
		captureAmountModalRef?.current?.open();
	}

	function showCancelButton(): boolean {
		if (sale?.form_payment !== 'billet' && ((sale?.has_split_rules && sale?.split_rules) || !sale?.has_split_rules) && !sale?.celcoin) {
			return true;
		}
		return false;
	}

	function showReceiptAndCancelButtons() {
		if (
			sale?.status === 'succeeded' ||
			sale?.status === 'waiting_confirmation' ||
			sale?.status === 'waiting_cashIn' ||
			sale?.status === 'pending_capture'
		) {
			return true;
		}
		return false;
	}

	function showReceipt() {
		if (sale?.status !== 'pending_capture') {
			return true;
		}
		return false;
	}

	function setLastFilters(): void {
		const { location } = history || {};
		const { state } = location || {};
		const { filters } = state || {};
		setFilters(filters);
	}

	const goBack = () => {
		history.push(history.location?.state?.route || '/admin/sales-history', { filters });
	};

	React.useEffect(() => {
		fetchGetSaleRequest();
		setLastFilters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller]);

	return (
		<Flex flexDirection='column' color='primary'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>Gestão Financeira</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink as={Link} to={{ pathname: '/admin/sales-history', state: { filters } }}>
						Histórico de Vendas
					</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage fontWeight='semibold'>
					<BreadcrumbLink>Detalhes da venda</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Stack w='100%'>
				<HStack>
					<Hide below='sm'>
						<Button
							id='go-back-button'
							onClick={() => goBack()}
							backgroundColor='transparent'
							color='black'
							borderWidth={1}
							borderColor='black'
							_hover={{
								backgroundColor: 'white',
							}}
						>
							Voltar
						</Button>
					</Hide>

					<Spacer />
					{sale?.status === 'succeeded' && sale?.celcoin && (
						<Button
							id='receipt-bill-payment-button'
							onClick={() => {
								setOpenReceiptBillPaymentModal(!openReceiptBillPaymentModal);
							}}
							backgroundColor='transparent'
							color='black'
							borderWidth={1}
							borderColor='black'
							_hover={{
								backgroundColor: 'white',
							}}
						>
							Recibo de Pagamento de Título
						</Button>
					)}
					{showReceiptAndCancelButtons() && (
						<>
							{showReceipt() && (
								<Button
									id='sale-receipt-button'
									onClick={() => {
										setOpenReceiptModal(!openReceiptModal);
									}}
									backgroundColor='transparent'
									color='black'
									borderWidth={1}
									borderColor='black'
									_hover={{
										backgroundColor: 'white',
									}}
								>
									Recibo de venda
								</Button>
							)}
							{showCancelButton() && (
								<Can do={PermissionActionsEnum.Read} on={PermissionSubjectEnum.CancelSale}>
									<Button
										id='cancel-sale-button'
										isDisabled={!currentSeller}
										onClick={() => setOpenConfirmPaymentCancel(true)}
										backgroundColor='primary'
									>
										Estornar a venda
									</Button>
								</Can>
							)}
						</>
					)}

					{sale?.status === 'pending_capture' && (
						<Button
							id='capture-sale-button'
							isDisabled={!currentSeller}
							backgroundColor='primary'
							onClick={handleCaptureModalOpen}
							w='150px'
						>
							Capturar a venda
						</Button>
					)}
				</HStack>
			</Stack>

			<Flex direction='column'>
				{sale && <SaleData sale={sale} showBackofficeInfos={isBackoffice} />}
				{sale?.payer && <BoxPayer {...sale} />}
				{sale?.split_rules && <SplitsTable {...sale.split_rules} />}
				{sale?.celcoin && <BoxBillPayment {...sale.celcoin} />}
			</Flex>

			<CancelModal
				openConfirmPaymentCancel={openConfirmPaymentCancel}
				handleCancelation={fetchPaymentsControllerCancelPayment}
				setOpenConfirmPaymentCancel={setOpenConfirmPaymentCancel}
				isLoading={isLoading}
			/>

			<AlertModal openAlertCancel={openAlertCancel} setOpenAlertCancel={setOpenAlertCancel} text={textAlertCancel} />
			<ReceiptModal sale={sale!} openReceiptModal={openReceiptModal} setOpenReceiptModal={setOpenReceiptModal} />
			<ReceiptModal
				sale={sale!}
				openReceiptModal={openReceiptBillPaymentModal}
				setOpenReceiptModal={setOpenReceiptBillPaymentModal}
				isBillPayment={true}
			/>

			<CaptureAmountModal
				ref={captureAmountModalRef}
				sale={sale}
				seller={currentSeller}
				afterSubmit={(updatedSale) => setSale(updatedSale)}
			/>
		</Flex>
	);
};

export default SaleDetails;
