/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SentPaperRollOrderDto
 */
export interface SentPaperRollOrderDto {
    /**
     * 
     * @type {boolean}
     * @memberof SentPaperRollOrderDto
     */
    sent: boolean;
    /**
     * 
     * @type {string}
     * @memberof SentPaperRollOrderDto
     */
    backoffice_note?: string;
    /**
     * 
     * @type {string}
     * @memberof SentPaperRollOrderDto
     */
    shipping_type?: SentPaperRollOrderDtoShippingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SentPaperRollOrderDto
     */
    tracking_code?: string;
    /**
     * 
     * @type {string}
     * @memberof SentPaperRollOrderDto
     */
    sent_amount?: string;
}

/**
* @export
* @enum {string}
*/
export enum SentPaperRollOrderDtoShippingTypeEnum {
    Pac = 'Pac',
    Sedex = 'Sedex'
}

export function SentPaperRollOrderDtoFromJSON(json: any): SentPaperRollOrderDto {
    return SentPaperRollOrderDtoFromJSONTyped(json, false);
}

export function SentPaperRollOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SentPaperRollOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sent': json['sent'],
        'backoffice_note': !exists(json, 'backoffice_note') ? undefined : json['backoffice_note'],
        'shipping_type': !exists(json, 'shipping_type') ? undefined : json['shipping_type'],
        'tracking_code': !exists(json, 'tracking_code') ? undefined : json['tracking_code'],
        'sent_amount': !exists(json, 'sent_amount') ? undefined : json['sent_amount'],
    };
}

export function SentPaperRollOrderDtoToJSON(value?: SentPaperRollOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sent': value.sent,
        'backoffice_note': value.backoffice_note,
        'shipping_type': value.shipping_type,
        'tracking_code': value.tracking_code,
        'sent_amount': value.sent_amount,
    };
}


