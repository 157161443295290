import React from 'react';

import { HStack } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillClockCircle } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToasts } from 'react-toast-notifications';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';
import { formatWithoutTimezone } from '../../../services/date.service';

import { maskMoney } from '../../../services/masks.service';
import { paymentMethods } from '../../../services/enums.service';

import Button from '../../../components/Button';
import CancelButton from './CancelButton';
import Text from '../../../components/Text';

const iconStatusLiteral = {
	paid: <AiFillCheckCircle color='#47FF62' size={16} />,
	active: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	expired: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({ data, setPagination, pagination, totalPages, onRowClick, afterCancel, seller }) => {
	const { addToast } = useToasts();

	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='cancel-link-title' column={column}>
						Cancelar Link
					</HeaderTitle>
				),
				accessor: 'id',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column,
					},
				}) => (
					<CellContent
						id={`${index}-${column.id}-cell`}
						onClick={async (e) => {
							e.stopPropagation();
						}}
					>
						{original.status === 'active' && <CancelButton id={`${index}-${column.id}-button`} seller={seller} afterCancel={afterCancel} />}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='formated-status-title' column={column}>
						Status
					</HeaderTitle>
				),
				accessor: 'formatted_status',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column,
					},
				}) => (
					<CellContent id={`${index}-${column.id}-cell`}>
						<HStack>
							<Text id={`${index}-${column.id}-icon-text`}>{iconStatusLiteral[original.status]}</Text>
							<Text id={`${index}-${column.id}-text`}>{value === 'Ativo' ? 'Pendente' : value}</Text>
						</HStack>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='formatted-generated-by-payer-title' column={column}>
						Gerado pelo Pagador
					</HeaderTitle>
				),
				accessor: 'formatted_generated_by_payer',
				Cell: ({ cell: { value, column, row } }) => <CellContent id={`${row.index}-${column.id}-cell`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='description-title' column={column}>
						N° do Pedido
					</HeaderTitle>
				),
				accessor: 'description',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} w='200px'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-methods-title' column={column}>
						Métodos de Pagamento
					</HeaderTitle>
				),
				accessor: 'payment_methods',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>{value?.map((val) => paymentMethods[val]).toString()}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payer-name-title' column={column}>
						Pagador
					</HeaderTitle>
				),
				accessor: 'payer.name',
				Cell: ({ cell: { value, row, column } }) => <CellContent id={`${row.index}-${column.id}-cell`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='created-at-title' column={column}>
						Data de Criação
					</HeaderTitle>
				),
				accessor: 'created_at',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='updated-at-title' column={column}>
						Data de Pagamento
					</HeaderTitle>
				),
				accessor: 'updated_at',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column,
					},
				}) => (
					<CellContent id={`${index}-${column.id}-cell`}>
						{original.status === 'paid' && value && formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='expires-in-title' column={column}>
						Data de Vencimento
					</HeaderTitle>
				),
				accessor: 'expires_in',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='form-payment-title' column={column}>
						Forma de Pagamento
					</HeaderTitle>
				),
				accessor: 'form_payment',
				Cell: ({ cell: { value, row, column } }) => <CellContent id={`${row.index}-${column.id}-cell`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='amount-title' column={column}>
						Total
					</HeaderTitle>
				),
				accessor: 'amount',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>{`R$ ${maskMoney(value)}`}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='amount-without-feed-title' column={column}>
						Total Divida
					</HeaderTitle>
				),
				accessor: 'amount_without_fees',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>{`R$ ${maskMoney(value)}`}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='fee-title' column={column}>
						Emolumento
					</HeaderTitle>
				),
				accessor: 'fee',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>{`R$ ${maskMoney(value)}`}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='url-title' column={column}>
						Referência
					</HeaderTitle>
				),
				accessor: 'url',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} onClick={(e) => e.stopPropagation()}>
						<CopyToClipboard
							id={`${row.index}-${column.id}-copy-clipboard`}
							text={value}
							onCopy={() => {
								addToast('Link copiado com sucesso', {
									appearance: 'success',
									autoDismiss: true,
								});
							}}
						>
							<Button id={`${row.index}-${column.id}-button`} size={`sm`} color='secondary' bgColor='transparent'>
								Copiar link
							</Button>
						</CopyToClipboard>
					</CellContent>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			onRowClick={onRowClick}
			pl={2}
			isLimited={true}
		/>
	);
};
