/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
    ShopperDTO,
    ShopperDTOFromJSON,
    ShopperDTOFromJSONTyped,
    ShopperDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBilletDto
 */
export interface CreateBilletDto {
    /**
     * Valor
     * @type {number}
     * @memberof CreateBilletDto
     */
    value: number;
    /**
     * CPF ou CNPJ
     * @type {string}
     * @memberof CreateBilletDto
     */
    social_security_number: string;
    /**
     * Descrição do boleto
     * @type {string}
     * @memberof CreateBilletDto
     */
    shopper_statement: string;
    /**
     * Descrição
     * @type {string}
     * @memberof CreateBilletDto
     */
    description: string;
    /**
     * Data de vencimento
     * @type {Date}
     * @memberof CreateBilletDto
     */
    delivery_date: Date;
    /**
     * Dados do cliente
     * @type {ShopperDTO}
     * @memberof CreateBilletDto
     */
    shopper: ShopperDTO;
    /**
     * Há divisão de pagamento
     * @type {boolean}
     * @memberof CreateBilletDto
     */
    has_split_rules?: boolean;
    /**
     * Divisão de pagamento
     * @type {Array<CreateSplitRulesDto>}
     * @memberof CreateBilletDto
     */
    split_rules?: Array<CreateSplitRulesDto>;
    /**
     * Há confirmação de pagamento
     * @type {boolean}
     * @memberof CreateBilletDto
     */
    confirmation_required?: boolean;
    /**
     * Há link de pagamento
     * @type {boolean}
     * @memberof CreateBilletDto
     */
    with_payment_link?: boolean;
    /**
     * Número do protocolo
     * @type {string}
     * @memberof CreateBilletDto
     */
    protocol?: string;
    /**
     * Instruções para o boleto(caso vazio será usado o campo description)
     * @type {string}
     * @memberof CreateBilletDto
     */
    instructions?: string;
    /**
     * Layout de boleto reduzido
     * @type {boolean}
     * @memberof CreateBilletDto
     */
    reduce_layout?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateBilletDto
     */
    metadata?: object;
}

export function CreateBilletDtoFromJSON(json: any): CreateBilletDto {
    return CreateBilletDtoFromJSONTyped(json, false);
}

export function CreateBilletDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBilletDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'social_security_number': json['social_security_number'],
        'shopper_statement': json['shopper_statement'],
        'description': json['description'],
        'delivery_date': (new Date(json['delivery_date'])),
        'shopper': ShopperDTOFromJSON(json['shopper']),
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'with_payment_link': !exists(json, 'with_payment_link') ? undefined : json['with_payment_link'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'reduce_layout': !exists(json, 'reduce_layout') ? undefined : json['reduce_layout'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function CreateBilletDtoToJSON(value?: CreateBilletDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'social_security_number': value.social_security_number,
        'shopper_statement': value.shopper_statement,
        'description': value.description,
        'delivery_date': (value.delivery_date.toISOString()),
        'shopper': ShopperDTOToJSON(value.shopper),
        'has_split_rules': value.has_split_rules,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
        'confirmation_required': value.confirmation_required,
        'with_payment_link': value.with_payment_link,
        'protocol': value.protocol,
        'instructions': value.instructions,
        'reduce_layout': value.reduce_layout,
        'metadata': value.metadata,
    };
}


