import React from 'react';
import { SimpleGrid, FormControl, FormLabel, Heading, Divider, Input } from '@chakra-ui/react';
import { GridItem } from '@chakra-ui/layout';

import { absorb_costs, settlement_type, pix_pos, billet_provider, pix_provider, payment_provider } from 'config/constants';
import { GetMerchantDto } from '../../../clients';
import ThreeDsForm from '../components/ThreeDsForm';
import FormSelect from 'components/Form/FormSelect';
import MultiSelect from '../../../components/MultiSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormInput from 'components/Form/FormInput';
import FormPercentInput from 'components/Form/FormPercentInput';

type Props = {
	values: any;
	setFieldValue: any;
	merchants: GetMerchantDto[];
	errors: any;
};

const SettingsForm: React.FC<Props> = ({ values, errors, merchants, setFieldValue }) => (
	<SimpleGrid columns={{ base: 1, md: 6, xl: 12 }} color='gray.700' gap={4}>
		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, xl: 6 }}>
			<FormControl id='merchant_id' isRequired>
				<FormLabel id='settings-merchant-label' fontSize='md' fontWeight='bold'>
					Merchant
				</FormLabel>
				<FormSelect id='settings-merchant-id-form-select' name='merchant_id'>
					<option value='' />
					{merchants?.map((merchant) => (
						<option value={merchant.id} key={merchant.id}>
							{merchant.description}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='settings-merchant-id-form-error-text' name='merchant_id' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1, md: 4, xl: 7 }} colSpan={{ base: 3, xl: 6 }}>
			<FormControl id='settlement_type' isRequired>
				<FormLabel id='settings-settlement-type-label' fontSize='md' fontWeight='bold'>
					Tipo de liquidação
				</FormLabel>
				<FormSelect id='settings-settlement-type-form-select' name='settlement_type'>
					<option value='' />
					{settlement_type.map((type) => (
						<option value={type.value} key={type.value}>
							{type.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='settings-settlement-type-form-error-text' name='settlement_type' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1, xl: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }}>
			<FormControl id='pix_provider' isRequired>
				<FormLabel id='settings-pix-provider-label' fontSize='md' fontWeight='bold'>
					Provedor de Pix
				</FormLabel>
				<FormSelect id='settings-pix-provider-form-select' name='pix_provider'>
					<option value='' />
					{pix_provider.map((pix) => (
						<option value={pix.value} key={pix.value}>
							{pix.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='settings-pix-provider-form-error-text' name='pix_provider' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }}>
			<FormControl id='billet_provider' isRequired>
				<FormLabel id='settings-billet-provider-label' fontSize='md' fontWeight='bold'>
					Provedor de Boleto
				</FormLabel>
				<FormSelect id='settings-billet-provider-form-select' name='billet_provider'>
					<option value='' />
					{billet_provider.map((billet) => (
						<option value={billet.value} key={billet.value}>
							{billet.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='settings-billet-provider-form-error-text' name='billet_provider' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }}>
			<FormControl id='payment_provider' isRequired>
				<FormLabel id='settings-payment-provider-label' fontSize='md' fontWeight='bold'>
					Provedor de Crédito
				</FormLabel>
				<FormSelect id='settings-payment-provider-form-select' name='payment_provider'>
					{payment_provider.map((payment) => (
						<option value={payment.value} key={payment.value}>
							{payment.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='settings-payment-provider-form-error-text' name='payment_provider' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1, '2xl': 7 }} colSpan={{ base: 3, md: 2, xl: 3 }} order={{ xl: 6 }}>
			<FormControl id='has_split_rules' isRequired>
				<FormLabel id='settings-has-split-rules-label' fontSize='md' fontWeight='bold'>
					Regras de divisão
				</FormLabel>
				<FormSelect id='settings-has-split-rules-form-select' name='has_split_rules'>
					<option value='false'>Não</option>
					<option value='true'>Sim</option>
				</FormSelect>
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 2, xl: 3 }}>
			<FormControl id='pix_pos' isRequired>
				<FormLabel id='settings-pix-pos-label' fontSize='md' fontWeight='bold'>
					Pix POS
				</FormLabel>
				<FormSelect id='settings-pix-pos-form-select' name='pix_pos'>
					<option value='' />
					{pix_pos.map((type) => (
						<option value={type.value} key={type.value}>
							{type.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='setting-pix-pos-form-error-text' name='pix_pos' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 2, xl: 3 }}>
			<FormControl id='is_assignor' isRequired>
				<FormLabel id='settings-is-assignor-label' fontSize='md' fontWeight='bold'>
					Cedente do Boleto
				</FormLabel>
				<FormSelect id='settings-is-assignor-form-select' name='is_assignor'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage id='settings-is-assignor-form-error-select' name='is_assignor' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1, xl: 1 }} colSpan={{ base: 3, md: 3, xl: 6 }}>
			<FormControl>
				<FormLabel id='settings-no-payment-methods-label' fontSize='md' fontWeight='bold'>
					Pagamentos não utilizados
				</FormLabel>
				<MultiSelect
					id='no_payment_methods'
					placeholder='Formas de pagamento'
					options={absorb_costs.map((costs) => ({
						value: costs.value,
						label: costs.text,
					}))}
					value={
						values.no_payment_methods &&
						absorb_costs
							.filter((cost) => {
								return values.no_payment_methods.includes(cost.value);
							})
							.map((cost) => {
								return { value: cost.value, label: cost.text };
							})
					}
					onChange={(value) => {
						setFieldValue(
							'no_payment_methods',
							value.map((obj) => obj.value)
						);
					}}
				/>
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1, md: 4 }} colSpan={{ base: 3, md: 3, xl: 6 }}>
			<FormControl>
				<FormLabel id='settings-absorb-costs-label' fontSize='md' fontWeight='bold'>
					Absorver Custos
				</FormLabel>
				<MultiSelect
					id='absorb_costs'
					placeholder='Absorver custos de...'
					options={absorb_costs.map((costs) => ({
						value: costs.value,
						label: costs.text,
					}))}
					value={
						values.absorb_costs &&
						absorb_costs
							.filter((cost) => {
								return values.absorb_costs.includes(cost.value);
							})
							.map((cost) => {
								return { value: cost.value, label: cost.text };
							})
					}
					onChange={(value) => {
						setFieldValue(
							'absorb_costs',
							value.map((obj) => obj.value)
						);
					}}
				/>
			</FormControl>
		</GridItem>

		<GridItem colSpan={{ base: 3, xl: 6 }} order={{ xl: 5 }}>
			<FormControl id='extract_name'>
				<FormLabel id='settings-extract-name-label' fontSize='md' fontWeight='bold'>
					Identificação Extrato (cartão)
				</FormLabel>
				<FormInput id='extract-name-form-input' as={Input} name='extract_name' maxLength={22} />
				<FormErrorMessage id='extract-name-form-error-text' name='extract_name' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }}>
			<FormControl id='card_payer_check'>
				<FormLabel id='settings-card-payer-check-label' fontSize='md' fontWeight='bold'>
					Validar Nome no Link
				</FormLabel>
				<FormSelect id='settings-card-payer-check-form-select' name='card_payer_check'>
					<option value='' />
					<option value='false'>Desativado</option>
					<option value='true'>Ativo</option>
				</FormSelect>
				<FormErrorMessage id='card-payer-check-form-error-text' name='card_payer_check' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }}>
			<FormControl id='card_payer_check_min_value'>
				<FormLabel id='card-payer-check-min-value-label' fontSize='md' fontWeight='bold'>
					Mínimo Para Validar Nome no Link
				</FormLabel>
				<FormCurrencyInput id='card-payer-check-min-value-form-currency-input' borderColor='darkGrey' name='card_payer_check_min_value' />
				<FormErrorMessage id='card-payer-check-min-value-form-error-text' name='card_payer_check_min_value' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 6, xl: 12 }} mt={5}>
			<Heading color='gray.700' as='h6' size='sm' py='1%'>
				Ativar 3Ds
			</Heading>
			<Divider />
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 6, xl: 12 }} order={{ base: 8 }}>
			<ThreeDsForm errors={errors} />
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 6, xl: 12 }} order={{ base: 9 }} mt={5}>
			<Heading id='settings-risk-active-heading' color='gray.700' as='h6' size='sm' py='1%'>
				Ativar Antifraude
			</Heading>
			<Divider />
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }} order={{ base: 10 }}>
			<FormControl id='risk_active'>
				<FormLabel id='settings-risk-active-label' fontSize='md' fontWeight='bold'>
					Antifraude
				</FormLabel>
				<FormSelect id='settings-risk-active-form-select' name='risk_active'>
					<option value='' />
					<option value='false'>Desativado</option>
					<option value='true'>Ativo</option>
				</FormSelect>
				<FormErrorMessage id='risk-active-form-error-text' name='risk_active' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }} order={{ base: 11 }}>
			<FormControl id='min_risk'>
				<FormLabel id='setting-min-risk-label' fontSize='md' fontWeight='bold'>
					Mínimo Para Antifraude
				</FormLabel>
				<FormCurrencyInput id='min-risk-form-currency-input' borderColor='darkGrey' name='min_risk' />
				<FormErrorMessage id='min-risk-form-error-text' name='min_risk' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }} order={{ base: 12 }}>
			<FormControl id='risk_value'>
				<FormLabel id='setting-risk-value-label' fontSize='md' fontWeight='bold'>
					Valor Antifraude
				</FormLabel>
				<FormCurrencyInput id='risk-value-form-currency-input' borderColor='darkGrey' name='risk_value' />
				<FormErrorMessage id='risk-value-form-currency-input' name='risk_value' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 6, xl: 12 }} order={{ base: 13 }} mt={5}>
			<Heading color='gray.700' as='h6' size='sm' py='1%'>
				Pagamento de Contas
			</Heading>
			<Divider />
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }} order={{ base: 14 }}>
			<FormControl id='celcoin_fee_type'>
				<FormLabel id='settings-celcoin-fee-type-label' fontSize='md' fontWeight='bold'>
					Tipo Taxa Pagamento de Contas
				</FormLabel>
				<FormSelect id='settings-celcoin-fee-type-form-select' name='celcoin_fee_type'>
					<option value='' />
					<option value='real'>Real</option>
					<option value='percentage'>Porcentagem</option>
				</FormSelect>
				<FormErrorMessage id='celcoin-fee-type-form-error-text' name='celcoin_fee_type' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }} order={{ base: 15 }}>
			<FormControl id='celcoin_value'>
				<FormLabel id='settings-celcoin-value-label' fontSize='md' fontWeight='bold'>
					Taxa Pagamento de Contas
				</FormLabel>
				{values.celcoin_fee_type === 'real' && (
					<FormCurrencyInput id='celcoin-value-form-currency-input' borderColor='darkGrey' name='celcoin_value' />
				)}
				{values.celcoin_fee_type === 'percentage' && (
					<FormPercentInput id='celcoin-value-form-percent-input' borderColor='darkGrey' name='celcoin_value' />
				)}
				<FormErrorMessage id='celcoin-value-form-error-text' name='celcoin_value' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 6, xl: 12 }} order={{ base: 16 }} mt={5}>
			<Heading color='gray.700' as='h6' size='sm' py='1%'>
				Terminal Android
			</Heading>
			<Divider />
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }} order={{ base: 17 }}>
			<FormControl id='pos_android_cancel'>
				<FormLabel id='settings-pos_android_cancel-label' fontSize='md' fontWeight='bold'>
					Cancelamento no terminal
				</FormLabel>
				<FormSelect id='settings-pos_android_cancel-form-select' name='pos_android_cancel'>
					<option value='' />
					<option value='false'>Desativado</option>
					<option value='true'>Ativo</option>
				</FormSelect>
				<FormErrorMessage id='pos_android_cancel-form-error-text' name='pos_android_cancel' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 3, xl: 3 }} order={{ base: 17 }}>
			<FormControl id='pos_android_absorb_costs'>
				<FormLabel id='settings-pos_android_absorb_costs_label' fontSize='md' fontWeight='bold'>
					Venda Standalone
				</FormLabel>
				<FormSelect id='settings-pos_android_absorb_costs_form_select' name='pos_android_absorb_costs'>
					<option value='' />
					<option value='false'>Desativado</option>
					<option value='true'>Ativo</option>
				</FormSelect>
				<FormErrorMessage id='pos_android_absorb_costs-form-error-text' name='pos_android_absorb_costs' />
			</FormControl>
		</GridItem>
	</SimpleGrid>
);

export default SettingsForm;
