import { Flex, Stack } from '@chakra-ui/react';
import Button from 'components/Button';
import NewModal from 'containers/NewModal';
import React from 'react';
import Text from '../../../components/Text';

type ActivationModalTypes = {
	isOpen: boolean;
	closeActivationModal: () => void;
	confirmActivationSeller: () => void;
};

const ActivationModal: React.FC<ActivationModalTypes> = (props) => {
	const { isOpen, closeActivationModal, confirmActivationSeller } = props;
	return (
		<NewModal showCloseButton={true} isOpen={isOpen} onClose={() => closeActivationModal()}>
			<Stack height={180} justifyContent='space-between'>
				<Text id='active-seller-text' fontWeight={`semibold`} color='primary' mt={4} align={'center'}>
					Ativar Estabelecimento?
				</Text>
				<Flex alignItems='center' padding={5} justifyContent='center'>
					<Button id='cancel-button' onClick={() => closeActivationModal()} mr={3} backgroundColor='red.400'>
						Cancelar
					</Button>
					<Button id='confirm-button' onClick={() => confirmActivationSeller()}>
						Confirmar
					</Button>
				</Flex>
			</Stack>
		</NewModal>
	);
};

export default ActivationModal;
