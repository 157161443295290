import React from 'react';


import { useToasts } from 'react-toast-notifications';
import Button from '../../../components/Button';

type DownloadPaymentBilletButtonProps = {
	billet_url: string;
};

const DownloadPaymentBillet: React.FC<DownloadPaymentBilletButtonProps> = ({ billet_url }) => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const { addToast } = useToasts();

	const handleAction = async () => {
		setIsLoading(true);

		try {

			window.open(billet_url);
		} catch (err) {
			addToast('Erro ao carregar o boleto', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Button
			id='download-payment-billet-button'
			px='6'
			py='2'
			height='auto'
			bgColor='primary'
			color='white'
			fontWeight='normal'
			disabled={isLoading}
			isLoading={isLoading}
			onClick={handleAction}
		>
			Boleto
		</Button>
	);
};

export default DownloadPaymentBillet;
