/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotaryOffice
 */
export interface NotaryOffice {
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    cns: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    assignment: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    secondary_phone: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    address_number: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryOffice
     */
    responsible: string;
    /**
     * 
     * @type {number}
     * @memberof NotaryOffice
     */
    invoicing: number;
}

export function NotaryOfficeFromJSON(json: any): NotaryOffice {
    return NotaryOfficeFromJSONTyped(json, false);
}

export function NotaryOfficeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotaryOffice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cns': json['cns'],
        'assignment': json['assignment'],
        'phone': json['phone'],
        'secondary_phone': json['secondary_phone'],
        'email': json['email'],
        'zipcode': json['zipcode'],
        'street': json['street'],
        'address_number': json['address_number'],
        'district': json['district'],
        'city': json['city'],
        'state': json['state'],
        'responsible': json['responsible'],
        'invoicing': json['invoicing'],
    };
}

export function NotaryOfficeToJSON(value?: NotaryOffice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cns': value.cns,
        'assignment': value.assignment,
        'phone': value.phone,
        'secondary_phone': value.secondary_phone,
        'email': value.email,
        'zipcode': value.zipcode,
        'street': value.street,
        'address_number': value.address_number,
        'district': value.district,
        'city': value.city,
        'state': value.state,
        'responsible': value.responsible,
        'invoicing': value.invoicing,
    };
}


