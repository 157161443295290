import React from 'react';
import { Modal, ModalOverlay, ModalContent, Flex, Heading, Button } from '@chakra-ui/react';
import { Events, IconStatus } from './CalendarComponent';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { maskMoney } from '../../../services/masks.service';
import Text from '../../../components/Text';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	paymentData: Events | undefined;
};

const PaymentDetailsModal: React.FC<Props> = (props) => {
	const { isOpen, onClose, paymentData } = props;
	const history = useHistory();

	const navigateToDetails = () => {
		if (paymentData && paymentData.id) {
			history.push(`/admin/payments-details/${paymentData.id}`, { date: format(paymentData.date, 'yyyy-MM-dd') });
		}
	};

	const translateStatus = (status: string) => {
		switch (status) {
			case 'paid':
				return 'Pago';
			case 'pending':
				return 'Pendente';
			case 'canceled':
				return 'Cancelado';
			case 'sent':
				return 'Enviado';
			case 'failed':
				return 'Falhado';
			default:
				return '';
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size='xs' trapFocus={false}>
			<ModalOverlay />

			<ModalContent rounded='md'>
				<Flex direction='column' p='3'>
					<Heading id='payment-data-date-heading' color='primary' fontSize='md' fontWeight='semibold' mb='2rem' textAlign='center'>
						Pagamento dia {paymentData && format(paymentData.date, 'dd/MM/yyyy')}
					</Heading>

					<Flex justifyContent='space-between' fontSize='md' color='darkGrey' fontWeight='semibold' mb='3'>
						<Text id='payment-value-text'>Valor do Pagamento</Text>
						<Text id='payment-data-mask-title-text'>R$ {paymentData && maskMoney(paymentData.title)}</Text>
					</Flex>

					<Flex justifyContent='space-between' fontSize='md' color='darkGrey' fontWeight='semibold' mb='3'>
						<Text id='status-text'>Status</Text>

						<Flex alignItems='center'>
							{paymentData && (
								<>
									{IconStatus[paymentData.status]}
									<Text id='payment-data-status-text' ml='2'>{translateStatus(paymentData.status)}</Text>
								</>
							)}
						</Flex>
					</Flex>

					<Flex direction='column' fontSize='md' color='darkGrey' fontWeight='semibold' mb='6'>
						<Text id='payment-id-text'>ID do Pagamento:</Text>
						<Text id='payment-data-id-text'>{paymentData?.id}</Text>
					</Flex>

					<Flex justifyContent='center'>
						<Button id='details-button' bgColor='transparent' borderWidth={1} borderColor='black' rounded='md' width='fit-content' onClick={navigateToDetails}>
							Ver Detalhes
						</Button>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default PaymentDetailsModal;
