/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
    ShopperDTO,
    ShopperDTOFromJSON,
    ShopperDTOFromJSONTyped,
    ShopperDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBilletInternalDto
 */
export interface CreateBilletInternalDto {
    /**
     * Valor
     * @type {number}
     * @memberof CreateBilletInternalDto
     */
    value: number;
    /**
     * CPF ou CNPJ
     * @type {string}
     * @memberof CreateBilletInternalDto
     */
    social_security_number: string;
    /**
     * Descrição do boleto
     * @type {string}
     * @memberof CreateBilletInternalDto
     */
    shopper_statement: string;
    /**
     * Descrição
     * @type {string}
     * @memberof CreateBilletInternalDto
     */
    description: string;
    /**
     * Data de vencimento
     * @type {Date}
     * @memberof CreateBilletInternalDto
     */
    delivery_date: Date;
    /**
     * Dados do cliente
     * @type {ShopperDTO}
     * @memberof CreateBilletInternalDto
     */
    shopper: ShopperDTO;
    /**
     * Id da venda
     * @type {string}
     * @memberof CreateBilletInternalDto
     */
    sale_id?: string;
    /**
     * Criado via link de protesto
     * @type {boolean}
     * @memberof CreateBilletInternalDto
     */
    protest_link?: boolean;
    /**
     * Valor da taxa
     * @type {number}
     * @memberof CreateBilletInternalDto
     */
    amount_fee?: number;
    /**
     * Há divisão de pagamento
     * @type {boolean}
     * @memberof CreateBilletInternalDto
     */
    has_split_rules?: boolean;
    /**
     * Divisão de pagamento
     * @type {Array<CreateSplitRulesDto>}
     * @memberof CreateBilletInternalDto
     */
    split_rules?: Array<CreateSplitRulesDto>;
    /**
     * Há confirmação de pagamento
     * @type {boolean}
     * @memberof CreateBilletInternalDto
     */
    confirmation_required?: boolean;
    /**
     * Há link de pagamento
     * @type {boolean}
     * @memberof CreateBilletInternalDto
     */
    with_payment_link?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateBilletInternalDto
     */
    metadata?: object;
}

export function CreateBilletInternalDtoFromJSON(json: any): CreateBilletInternalDto {
    return CreateBilletInternalDtoFromJSONTyped(json, false);
}

export function CreateBilletInternalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBilletInternalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'social_security_number': json['social_security_number'],
        'shopper_statement': json['shopper_statement'],
        'description': json['description'],
        'delivery_date': (new Date(json['delivery_date'])),
        'shopper': ShopperDTOFromJSON(json['shopper']),
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'protest_link': !exists(json, 'protest_link') ? undefined : json['protest_link'],
        'amount_fee': !exists(json, 'amount_fee') ? undefined : json['amount_fee'],
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'with_payment_link': !exists(json, 'with_payment_link') ? undefined : json['with_payment_link'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function CreateBilletInternalDtoToJSON(value?: CreateBilletInternalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'social_security_number': value.social_security_number,
        'shopper_statement': value.shopper_statement,
        'description': value.description,
        'delivery_date': (value.delivery_date.toISOString()),
        'shopper': ShopperDTOToJSON(value.shopper),
        'sale_id': value.sale_id,
        'protest_link': value.protest_link,
        'amount_fee': value.amount_fee,
        'has_split_rules': value.has_split_rules,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
        'confirmation_required': value.confirmation_required,
        'with_payment_link': value.with_payment_link,
        'metadata': value.metadata,
    };
}


