/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSimulationV2Dto
 */
export interface GetSimulationV2Dto {
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    installment_amount_cents_decimal: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    installment_amount_cents: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    original_amount_cents: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    installment: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    total_amount_cents: number;
    /**
     * 
     * @type {string}
     * @memberof GetSimulationV2Dto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    cet: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    monthly_cet: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    annual_cet: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    fee: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationV2Dto
     */
    nominal_fee: number;
}

export function GetSimulationV2DtoFromJSON(json: any): GetSimulationV2Dto {
    return GetSimulationV2DtoFromJSONTyped(json, false);
}

export function GetSimulationV2DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSimulationV2Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'installment_amount_cents_decimal': json['installment_amount_cents_decimal'],
        'installment_amount_cents': json['installment_amount_cents'],
        'original_amount_cents': json['original_amount_cents'],
        'installment': json['installment'],
        'total_amount_cents': json['total_amount_cents'],
        'type': json['type'],
        'cet': json['cet'],
        'monthly_cet': json['monthly_cet'],
        'annual_cet': json['annual_cet'],
        'fee': json['fee'],
        'nominal_fee': json['nominal_fee'],
    };
}

export function GetSimulationV2DtoToJSON(value?: GetSimulationV2Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'installment_amount_cents_decimal': value.installment_amount_cents_decimal,
        'installment_amount_cents': value.installment_amount_cents,
        'original_amount_cents': value.original_amount_cents,
        'installment': value.installment,
        'total_amount_cents': value.total_amount_cents,
        'type': value.type,
        'cet': value.cet,
        'monthly_cet': value.monthly_cet,
        'annual_cet': value.annual_cet,
        'fee': value.fee,
        'nominal_fee': value.nominal_fee,
    };
}


