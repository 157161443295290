/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAssignmentTransactionAlertsDto
 */
export interface GetAssignmentTransactionAlertsDto {
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    sale_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    sale_description: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    sale_acquirer_reference: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    sale_payment_day: string;
    /**
     * 
     * @type {number}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    sale_amount: number;
    /**
     * 
     * @type {number}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    sale_original_amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    seller_code: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    seller_document: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    seller_cns: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    seller_assignment: string;
    /**
     * 
     * @type {number}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    average_assignment_ticket: number;
    /**
     * 
     * @type {string}
     * @memberof GetAssignmentTransactionAlertsDto
     */
    created_at: string;
}

export function GetAssignmentTransactionAlertsDtoFromJSON(json: any): GetAssignmentTransactionAlertsDto {
    return GetAssignmentTransactionAlertsDtoFromJSONTyped(json, false);
}

export function GetAssignmentTransactionAlertsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAssignmentTransactionAlertsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sale_id': json['sale_id'],
        'sale_description': json['sale_description'],
        'sale_acquirer_reference': json['sale_acquirer_reference'],
        'sale_payment_day': json['sale_payment_day'],
        'sale_amount': json['sale_amount'],
        'sale_original_amount': json['sale_original_amount'],
        'seller_id': json['seller_id'],
        'seller_code': json['seller_code'],
        'seller_document': json['seller_document'],
        'seller_name': json['seller_name'],
        'seller_cns': json['seller_cns'],
        'seller_assignment': json['seller_assignment'],
        'average_assignment_ticket': json['average_assignment_ticket'],
        'created_at': json['created_at'],
    };
}

export function GetAssignmentTransactionAlertsDtoToJSON(value?: GetAssignmentTransactionAlertsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sale_id': value.sale_id,
        'sale_description': value.sale_description,
        'sale_acquirer_reference': value.sale_acquirer_reference,
        'sale_payment_day': value.sale_payment_day,
        'sale_amount': value.sale_amount,
        'sale_original_amount': value.sale_original_amount,
        'seller_id': value.seller_id,
        'seller_code': value.seller_code,
        'seller_document': value.seller_document,
        'seller_name': value.seller_name,
        'seller_cns': value.seller_cns,
        'seller_assignment': value.seller_assignment,
        'average_assignment_ticket': value.average_assignment_ticket,
        'created_at': value.created_at,
    };
}


