import React from 'react';

type Props = {
	error: {
		message: string;
	};
};

function App({ error }: Props): React.ReactElement {
	return (
		<div className='App'>
			<p>Ops, algo de errado aconteceu...</p>
		</div>
	);
}

export default App;
