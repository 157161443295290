/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Theme
 */
export interface Theme {
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    primary_color: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    secondary_color: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    default_color: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    text_color: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    menu_text_color: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    updated_at?: string;
}

export function ThemeFromJSON(json: any): Theme {
    return ThemeFromJSONTyped(json, false);
}

export function ThemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Theme {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'primary_color': json['primary_color'],
        'secondary_color': json['secondary_color'],
        'default_color': json['default_color'],
        'text_color': json['text_color'],
        'menu_text_color': json['menu_text_color'],
        'created_at': json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function ThemeToJSON(value?: Theme | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'primary_color': value.primary_color,
        'secondary_color': value.secondary_color,
        'default_color': value.default_color,
        'text_color': value.text_color,
        'menu_text_color': value.menu_text_color,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}


