import React from 'react';

import { BreadcrumbItem, BreadcrumbLink, Flex, HStack, Spinner, Stack } from '@chakra-ui/react';
import Breadcrumb from 'components/Breadcrumb';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';

import { GetNotificationDto, NotificationApi } from '../../clients';

import Paper from '../../containers/Paper';

import { getApiAuthConfig } from '../../services/api.service';

import SampleTable from './components/SampleTable';
import { UploadImageForm } from './components/UploadImageForm';

const Notifications: React.FC = () => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const notificationApi = new NotificationApi(apiConfig);

	const [isLoading, setIsLoading] = React.useState(false);
	const [getAllLoading, setGetAllLoading] = React.useState(false);

	const [notifications, setNotifications] = React.useState<GetNotificationDto[]>([]);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 10 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [file, setFile] = React.useState<File | null>(null);

	const initialValues = {
		action_link: '',
	};

	async function getAllNotifications() {
		setGetAllLoading(true);
		try {
			const response = await notificationApi.getAllNotifications({
				limit: pagination.limit,
				currentPage: pagination.currentPage,
			});
			setTotalPages(response.total_pages);
			setNotifications((response.results as unknown) as GetNotificationDto[]);
		} catch (error) {
			addToast('Erro ao buscar as notificações.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setGetAllLoading(false);
		}
	}

	async function updateActive(id, data) {
		setIsLoading(true);
		const updateActiveNotificationRequestDto = {
			is_active: data,
		};
		try {
			await notificationApi.updateActiveNotification({ id, updateActiveNotificationRequestDto });
			getAllNotifications();
			addToast('Notificação ativa alterada com sucesso.', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao alterar a notificaçao ativa.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const handleFormSubmit = async (values) => {
		setIsLoading(true);
		if (file) {
			const uploadNotificationRequestDto = {
				file: file,
				name: file.name,
				contentType: file.type,
				actionLink: values.action_link,
			};
			await notificationApi.uploadNotification(uploadNotificationRequestDto);
			getAllNotifications();
			addToast('Arquivo adicionado com sucesso.', {
				appearance: 'success',
				autoDismiss: true,
			});
			setFile(null);
		} else {
			addToast('Nenhum arquivo selecionado.', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
		setIsLoading(false);
	};

	React.useEffect(() => {
		getAllNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	return (
		<Flex flexDirection='column'>
			<HStack justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Notificações
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</HStack>

			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={async (values, { resetForm }) => {
					await handleFormSubmit(values);
					resetForm({ values: initialValues });
				}}
			>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Paper id='upload-image-paper-text' p={6}>
								<UploadImageForm key={file?.name} file={file} setFile={setFile} isLoading={isLoading} />
								<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
									{getAllLoading ? (
										<Stack w='100%' alignItems='center' mt={6}>
											<Spinner />
										</Stack>
									) : (
										<>
											{notifications && (
												<SampleTable
													data={notifications}
													setPagination={setPagination}
													pagination={pagination}
													totalPages={totalPages}
													updateActive={updateActive}
													isLoading={isLoading}
												/>
											)}
										</>
									)}
								</Flex>
							</Paper>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default Notifications;
