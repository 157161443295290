/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Responsible
 */
export interface Responsible {
    /**
     * 
     * @type {string}
     * @memberof Responsible
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Responsible
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Responsible
     */
    type: ResponsibleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Responsible
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Responsible
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Responsible
     */
    phone: string;
    /**
     * 
     * @type {boolean}
     * @memberof Responsible
     */
    is_active: boolean;
    /**
     * 
     * @type {string}
     * @memberof Responsible
     */
    created_at?: string;
}

/**
* @export
* @enum {string}
*/
export enum ResponsibleTypeEnum {
    Commercial = 'commercial',
    Support = 'support'
}

export function ResponsibleFromJSON(json: any): Responsible {
    return ResponsibleFromJSONTyped(json, false);
}

export function ResponsibleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Responsible {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'url': json['url'],
        'key': json['key'],
        'phone': json['phone'],
        'is_active': json['is_active'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
    };
}

export function ResponsibleToJSON(value?: Responsible | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'url': value.url,
        'key': value.key,
        'phone': value.phone,
        'is_active': value.is_active,
        'created_at': value.created_at,
    };
}


