import React from 'react';

import { Field } from 'formik';
import { SelectProps } from '@chakra-ui/react';

import Select from '../Select';
import { useTheme } from 'contexts/ThemeProvider';

type FormSelectProps = SelectProps & {
	id: string;
};

const FormSelect: React.FC<FormSelectProps> = (props: FormSelectProps) => {
	const { theme } = useTheme();
	return (
		<Field {...props}>
			{({ form, field }) => {
				return (
					<Select
						color={theme?.text_color || 'blackAlpha.600'}
						size={`md`}
						rounded={`md`}
						fontSize={`sm`}
						bg={`white`}
						{...props}
						borderColor='darkGrey'
						onBlur={form.handleBlur}
						value={field.value}
						onChange={(e) => {
							props.onChange && props.onChange(e);

							form.setFieldValue(field.name, e.target.value);
						}}
					/>
				);
			}}
		</Field>
	);
};

export default FormSelect;
