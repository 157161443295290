/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProtocolStatusUpdateHistory
 */
export interface ProtocolStatusUpdateHistory {
    /**
     * 
     * @type {string}
     * @memberof ProtocolStatusUpdateHistory
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolStatusUpdateHistory
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof ProtocolStatusUpdateHistory
     */
    created_at_unix_time: number;
    /**
     * 
     * @type {string}
     * @memberof ProtocolStatusUpdateHistory
     */
    before: ProtocolStatusUpdateHistoryBeforeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProtocolStatusUpdateHistory
     */
    after: ProtocolStatusUpdateHistoryAfterEnum;
}

/**
* @export
* @enum {string}
*/
export enum ProtocolStatusUpdateHistoryBeforeEnum {
    Pending = 'pending',
    Alert = 'alert',
    Paid = 'paid',
    Overdued = 'overdued',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum ProtocolStatusUpdateHistoryAfterEnum {
    Pending = 'pending',
    Alert = 'alert',
    Paid = 'paid',
    Overdued = 'overdued',
    Canceled = 'canceled'
}

export function ProtocolStatusUpdateHistoryFromJSON(json: any): ProtocolStatusUpdateHistory {
    return ProtocolStatusUpdateHistoryFromJSONTyped(json, false);
}

export function ProtocolStatusUpdateHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolStatusUpdateHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': json['user_name'],
        'created_at': json['created_at'],
        'created_at_unix_time': json['created_at_unix_time'],
        'before': json['before'],
        'after': json['after'],
    };
}

export function ProtocolStatusUpdateHistoryToJSON(value?: ProtocolStatusUpdateHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'created_at': value.created_at,
        'created_at_unix_time': value.created_at_unix_time,
        'before': value.before,
        'after': value.after,
    };
}


