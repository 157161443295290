import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import Modal from 'containers/Modal';
import Button from '../../../components/Button';

type CancelModalProps = {
	openConfirmPaymentCancel: boolean;
	handleCancelation(): void;
	setOpenConfirmPaymentCancel(open: boolean): void;
	isLoading: boolean;
};

const CancelModal: React.FC<CancelModalProps> = ({
	openConfirmPaymentCancel,
	handleCancelation,
	setOpenConfirmPaymentCancel,
	isLoading,
}) => (
	<Modal isOpen={openConfirmPaymentCancel}>
		<Flex p={8} flexDirection='column'>
			<Heading fontSize='lg' fontWeight='bold' color='primary' marginBottom={8}>
				Confirmar o estorno da venda?
			</Heading>

			<Flex flexDirection='row' alignItems='center' justifyContent='space-between' fontSize='md'>
				<Button
					id='cancel-confirmation-button'
					bgColor='transparent'
					color='black'
					borderWidth={1}
					borderColor='black'
					onClick={() => {
						setOpenConfirmPaymentCancel(!openConfirmPaymentCancel);
					}}
				>
					Cancelar
				</Button>

				<Button
					id='confirm-cancelation-button'
					onClick={async () => {
						await handleCancelation();
						setOpenConfirmPaymentCancel(!openConfirmPaymentCancel);
					}}
					bgColor='primary'
					ml='2'
					px='10'
				>
					Sim
				</Button>
			</Flex>
		</Flex>
	</Modal>
);

export default CancelModal;
