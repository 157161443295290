import { BreadcrumbItem, BreadcrumbLink, Flex, HStack } from '@chakra-ui/react';
import Breadcrumb from 'components/Breadcrumb';
import React from 'react';
import Title from '../../components/Title';
import Paper from '../../containers/Paper';
import { ExportButton } from './components/ExportButton';
import SampleTable from './components/SampleTable';
import SearchForm from './components/SearchForm';
import { PaperRollOrderProvider } from './context/PaperRollOrderContext';

const PaperRollOrder: React.FC = () => {
	return (
		<PaperRollOrderProvider>
			<Flex flexDirection='column'>
				<HStack justify='space-between'>
					<Breadcrumb>
						<BreadcrumbItem>
							<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
						</BreadcrumbItem>

						<BreadcrumbItem>
							<BreadcrumbLink fontSize='md'>Minha Conta</BreadcrumbLink>
						</BreadcrumbItem>

						<BreadcrumbItem isCurrentPage>
							<BreadcrumbLink fontSize='md' fontWeight='semibold'>
								Pedido de Bobinas
							</BreadcrumbLink>
						</BreadcrumbItem>
					</Breadcrumb>
				</HStack>
				<Flex flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
					<Title id='order-paper-roll-title'>PEDIDO DE BOBINAS</Title>
					<ExportButton />
				</Flex>
				<Paper id='search-form-paper' p={6}>
					<SearchForm />
				</Paper>
				<Paper id='sample-table-paper' p={6}>
					<Flex overflowX='auto' overflowY='hidden' flexDirection='column' mb='5px'>
						<SampleTable />
					</Flex>
				</Paper>
			</Flex>
		</PaperRollOrderProvider>
	);
};

export default PaperRollOrder;
