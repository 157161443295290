import React from 'react';

import { Center, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { useTheme } from 'contexts/ThemeProvider';

type InformationBoxProps = {
	header: string;
	body: string;
};


const InformationBox: React.FC<InformationBoxProps> = (props: InformationBoxProps) => {
	const { theme } = useTheme();
	
	return (
		<Center w='180px' h='80px'>
			<Stat>
				<StatLabel id='header-label' color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					{props.header}
				</StatLabel>
				<StatNumber id='body-label' color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					{props.body}
				</StatNumber>
			</Stat>
		</Center>
	)
}
export default InformationBox;
