/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentLinkPayerAddressDto,
    PaymentLinkPayerAddressDtoFromJSON,
    PaymentLinkPayerAddressDtoFromJSONTyped,
    PaymentLinkPayerAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPaymentLinkPayerDto
 */
export interface GetPaymentLinkPayerDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkPayerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkPayerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkPayerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkPayerDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkPayerDto
     */
    phone: string;
    /**
     * 
     * @type {PaymentLinkPayerAddressDto}
     * @memberof GetPaymentLinkPayerDto
     */
    address: PaymentLinkPayerAddressDto;
}

export function GetPaymentLinkPayerDtoFromJSON(json: any): GetPaymentLinkPayerDto {
    return GetPaymentLinkPayerDtoFromJSONTyped(json, false);
}

export function GetPaymentLinkPayerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentLinkPayerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'document': json['document'],
        'email': json['email'],
        'phone': json['phone'],
        'address': PaymentLinkPayerAddressDtoFromJSON(json['address']),
    };
}

export function GetPaymentLinkPayerDtoToJSON(value?: GetPaymentLinkPayerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'document': value.document,
        'email': value.email,
        'phone': value.phone,
        'address': PaymentLinkPayerAddressDtoToJSON(value.address),
    };
}


