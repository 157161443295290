import React from 'react';

import { SimpleGrid, FormControl, Input } from '@chakra-ui/react';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import { Stack } from '@chakra-ui/layout';
import { costOptionFeeType, costPlanTypeStatus, salePlanPaymentTypeEnum } from '../../../config/constants';
import FormSelect from '../../../components/Form/FormSelect';
import FormCurrencyInput from '../../../components/Form/FormCurrencyInput';
import { brandEnum } from '../../../services/enums.service';
import FormLabel from '../../../components/Form/FormLabel';

type Props = {
	errors: any;
	index: number;
	values: any;
};

const OptionsForm: React.FC<Props> = ({ errors, index, values }) => {
	return (
		<SimpleGrid color='black' borderWidth={1} borderColor='darkGrey' rounded='md' spacing='2.5rem' p='4'>
			<Stack direction={['column', 'column', 'row']} spacing={4}>
				<FormControl id={`options.${[index]}.description`} isRequired>
					<FormLabel id='description-label' fontSize='md' fontWeight='bold'>
						Descrição
					</FormLabel>

					<FormInput id='options-description-form-input' as={Input} name={`options.${[index]}.description`} />
					<FormErrorMessage id='options-description-form-error-text' name={`options.${[index]}.description`} />
				</FormControl>

				<FormControl id={`options.${[index]}.type`} isRequired>
					<FormLabel id='type-label' fontSize='md' fontWeight='bold'>
						Tipo
					</FormLabel>

					<FormSelect id='option-type-form-select' name={`options.${[index]}.type`}>
						<option value='' />
						{salePlanPaymentTypeEnum.map((type) => (
							<option value={type.value} key={type.value}>
								{type.text}
							</option>
						))}
					</FormSelect>
					<FormErrorMessage id='options-type-form-error-text' name={`options.${[index]}.type`} />
				</FormControl>

				<FormControl id={`options.${[index]}.type_status`}>
					<FormLabel id='status-label' fontSize='md' fontWeight='bold'>
						Status
					</FormLabel>

					<FormSelect id='options-type-status-form-select' name={`options.${[index]}.type_status`}>
						<option value='' />
						{costPlanTypeStatus.map((type) => (
							<option value={type.value} key={type.value}>
								{type.text}
							</option>
						))}
					</FormSelect>
					<FormErrorMessage id='options-type-status-form-error-text' name={`options.${[index]}.type_status`} />
				</FormControl>
			</Stack>

			{values.options[index].type !== 'pix' && values.options[index].type !== 'billet' && (
				<>
					<Stack direction={['column', 'column', 'row']} spacing={4}>
						<FormControl id={`options.${[index]}.brand`}>
							<FormLabel id='brand-label' fontSize='md' fontWeight='bold'>
								Bandeira
							</FormLabel>

							<FormSelect id='option-brand-form-select' name={`options.${[index]}.brand`}>
								<option value='' />
								{brandEnum.map((type) => (
									<option value={type.value} key={type.value}>
										{type.text}
									</option>
								))}
							</FormSelect>
							<FormErrorMessage id='options-brand-form-error-text' name={`options.${[index]}.brand`} />
						</FormControl>

						<FormControl id={`options.${[index]}.brand_fee_type`}>
							<FormLabel id='brand-fee-type-label' fontSize='md' fontWeight='bold'>
								Tipo de taxa da bandeira
							</FormLabel>

							<FormSelect id='option-brand-fee-type-form-select' name={`options.${[index]}.brand_fee_type`}>
								<option value='' />
								{costOptionFeeType.map((type) => (
									<option value={type.value} key={type.value}>
										{type.text}
									</option>
								))}
							</FormSelect>
							<FormErrorMessage id='options-brand-fee-type-form-error-text' name={`options.${[index]}.brand_fee_type`} />
						</FormControl>

						<FormControl id={`options.${[index]}.brand_fee`}>
							<FormLabel id='brand-fee-percent-label' fontSize='md' fontWeight='bold'>
								Taxa da bandeira
							</FormLabel>

							{values.options[index].brand_fee_type === 'percent' ? (
								<FormInput id='options-brand-free-percent-form-input' as={Input} name={`options.${[index]}.brand_fee`} />
							) : (
								<FormCurrencyInput id='options-brand-fee-form-currency-input' name={`options.${[index]}.brand_fee`} />
							)}
							<FormErrorMessage id='options-brand-fee-form-error-text' name={`options.${[index]}.brand_fee`} />
						</FormControl>

						<FormControl id={`options.${[index]}.installments`}>
							<FormLabel id='installments-label' fontSize='md' fontWeight='bold'>
								Parcelas
							</FormLabel>

							<FormInput id='options-installments-form-input' as={Input} name={`options.${[index]}.installments`} />
							<FormErrorMessage id='options-installments-form-error-text' name={`options.${[index]}.installments`} />
						</FormControl>
					</Stack>

					<Stack direction={['column', 'column', 'row']} spacing={4}>
						<FormControl id={`options.${[index]}.exchange_fee_type`} isRequired>
							<FormLabel id='exchange-fee-type-label' fontSize='md' fontWeight='bold'>
								Tipo de taxa de câmbio
							</FormLabel>

							<FormSelect id='option-exchange-fee-type-form-select' name={`options.${[index]}.exchange_fee_type`}>
								<option value='' />
								{costOptionFeeType.map((type) => (
									<option value={type.value} key={type.value}>
										{type.text}
									</option>
								))}
							</FormSelect>
							<FormErrorMessage id='options-exchange-fee-type-form-error-text' name={`options.${[index]}.exchange_fee_type`} />
						</FormControl>

						<FormControl id={`options.${[index]}.exchange_fee`}>
							<FormLabel id='exchange-fee-percent-form-label' fontSize='md' fontWeight='bold'>
								Taxa de câmbio
							</FormLabel>

							{values.options[index].exchange_fee_type === 'percent' ? (
								<FormInput id='options-exchange-fee-percent-form-input' as={Input} name={`options.${[index]}.exchange_fee`} />
							) : (
								<FormCurrencyInput id='options-exchange-fee-form-currency-input' name={`options.${[index]}.exchange_fee`} />
							)}
							<FormErrorMessage id='options-exchange-fee-form-error-text' name={`options.${[index]}.exchange_fee`} />
						</FormControl>

						<FormControl id={`options.${[index]}.anticipation_fee_type`}>
							<FormLabel id='anticipation-fee-type-label' fontSize='md' fontWeight='bold'>
								Tipo de taxa de antecipação
							</FormLabel>

							<FormSelect id='option-anticipation-fee-type-form-select' name={`options.${[index]}.anticipation_fee_type`}>
								<option value='' />
								{costOptionFeeType.map((type) => (
									<option value={type.value} key={type.value}>
										{type.text}
									</option>
								))}
							</FormSelect>
							<FormErrorMessage id='options-anticipation-fee-type-form-error-text' name={`options.${[index]}.anticipation_fee_type`} />
						</FormControl>

						<FormControl id={`options.${[index]}.anticipation_fee`}>
							<FormLabel id='anticipation-fee-percent-label' fontSize='md' fontWeight='bold'>
								Taxa de antecipação
							</FormLabel>

							{values.options[index].anticipation_fee_type === 'percent' ? (
								<FormInput id='options-anticipation-fee-percent-form-input' as={Input} name={`options.${[index]}.anticipation_fee`} />
							) : (
								<FormCurrencyInput id='options-anticipation-fee-form-currency-input' name={`options.${[index]}.anticipation_fee`} />
							)}
							<FormErrorMessage id='options-anticipation-fee-form-error-text' name={`options.${[index]}.anticipation_fee`} />
						</FormControl>
					</Stack>

					<Stack direction={['column', 'column', 'row']} spacing={4}>
						<FormControl id={`options.${[index]}.markup_fee_type`}>
							<FormLabel id='markup-fee-type-label' fontSize='md' fontWeight='bold'>
								Tipo de taxa de marcação
							</FormLabel>

							<FormSelect id='option-markup-fee-type-form-select' name={`options.${[index]}.markup_fee_type`}>
								<option value='' />
								{costOptionFeeType.map((type) => (
									<option value={type.value} key={type.value}>
										{type.text}
									</option>
								))}
							</FormSelect>
							<FormErrorMessage id='options-markup-fee-type-form-error-text' name={`options.${[index]}.markup_fee_type`} />
						</FormControl>

						<FormControl id={`options.${[index]}.markup_fee`}>
							<FormLabel id='markup-fee-label' fontSize='md' fontWeight='bold'>
								Taxa de marcação
							</FormLabel>

							{values.options[index].markup_fee_type === 'percent' ? (
								<FormInput id='options-markup' as={Input} name={`options.${[index]}.markup_fee`} />
							) : (
								<FormCurrencyInput id='options-markup-fee-form-currency-input' name={`options.${[index]}.markup_fee`} />
							)}
							<FormErrorMessage id='options-markup-fee-form-error-text' name={`options.${[index]}.markup_fee`} />
						</FormControl>
					</Stack>
				</>
			)}

			<Stack direction={['column', 'column', 'row']} spacing={4}>
				<FormControl id={`options.${[index]}.fixed_amount`} isRequired>
					<FormLabel id='fixed-amount-label' fontSize='md' fontWeight='bold'>
						Quantia fixa
					</FormLabel>

					<FormCurrencyInput id='options-fixed-amount-form-currency-input' name={`options.${[index]}.fixed_amount`} />
					<FormErrorMessage id='options-fixed-amount-form-error-text' name={`options.${[index]}.fixed_amount`} />
				</FormControl>

				{values.options[index].type !== 'credit' && values.options[index].type !== 'debit' && (
					<FormControl id={`options.${[index]}.bank_fixed_amount`} isRequired>
						<FormLabel id='bank-fixed-amount-label' fontSize='md' fontWeight='bold'>
							Taxa do banco
						</FormLabel>

						<FormCurrencyInput
							id='options-bank-fixed-amount-form-currency-input'
							as={Input}
							name={`options.${[index]}.bank_fixed_amount`}
						/>
						<FormErrorMessage id='options-bank-fixed-amount-form-error-text' name={`options.${[index]}.bank_fixed_amount`} />
					</FormControl>
				)}

				<FormControl id={`options.${[index]}.is_cap`}>
					<FormLabel id='is-cap-label' fontSize='md' fontWeight='bold'>
						Troca
					</FormLabel>

					<FormSelect id='option-is-cap-form-select' name={`options.${[index]}.is_cap`}>
						<option value='' />
						<option value={'true'} key={'true'}>
							Sim
						</option>
					</FormSelect>
					<FormErrorMessage id='options-is-cap-form-eror-text' name={`options.${[index]}.is_cap`} />
				</FormControl>

				<FormControl id={`options.${[index]}.exchange_cap`}>
					<FormLabel id='exchange-cap-label' fontSize='md' fontWeight='bold'>
						Limite de troca
					</FormLabel>

					<FormCurrencyInput id='options-exchange-cap-form-currency-input' name={`options.${[index]}.exchange_cap`} />
					<FormErrorMessage id='options-exchange-cap-form-error-text' name={`options.${[index]}.exchange_cap`} />
				</FormControl>
			</Stack>
		</SimpleGrid>
	);
};

export default OptionsForm;
