/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreatePaymentLinkDto,
    CreatePaymentLinkDtoFromJSON,
    CreatePaymentLinkDtoToJSON,
    CreatePaymentLinkResponseDto,
    CreatePaymentLinkResponseDtoFromJSON,
    CreatePaymentLinkResponseDtoToJSON,
    CreatePaymentLinkSellerDto,
    CreatePaymentLinkSellerDtoFromJSON,
    CreatePaymentLinkSellerDtoToJSON,
    GetPaymentLinkDto,
    GetPaymentLinkDtoFromJSON,
    GetPaymentLinkDtoToJSON,
    GetPaymentLinkSaleDataResponseDto,
    GetPaymentLinkSaleDataResponseDtoFromJSON,
    GetPaymentLinkSaleDataResponseDtoToJSON,
    UpdatePaymentLinkDto,
    UpdatePaymentLinkDtoFromJSON,
    UpdatePaymentLinkDtoToJSON,
} from '../models';

export interface CancelPaymentLinkRequest {
    sellerId: string;
    paymentLinkId: string;
}

export interface CreatePaymentLinkRequest {
    createPaymentLinkDto: CreatePaymentLinkDto;
}

export interface CreatePaymentLinkV2Request {
    createPaymentLinkDto: Array<CreatePaymentLinkDto>;
}

export interface CreateSellerPaymentLinksRequest {
    sellerId: string;
    createPaymentLinkSellerDto: Array<CreatePaymentLinkSellerDto>;
}

export interface GetPaymentLinkBilletUrlRequest {
    token: string;
}

export interface GetPaymentLinkByTokenRequest {
    token: string;
}

export interface SentPaymentLinkReceiptMailRequest {
    token: string;
}

export interface UpdatePaymentLinkRequest {
    paymentLinkId: string;
    updatePaymentLinkDto: UpdatePaymentLinkDto;
}

/**
 * 
 */
export class PaymentLinksApi extends runtime.BaseAPI {

    /**
     * Cancelar um link de pagamento
     */
    async cancelPaymentLinkRaw(requestParameters: CancelPaymentLinkRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling cancelPaymentLink.');
        }

        if (requestParameters.paymentLinkId === null || requestParameters.paymentLinkId === undefined) {
            throw new runtime.RequiredError('paymentLinkId','Required parameter requestParameters.paymentLinkId was null or undefined when calling cancelPaymentLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/payment-links/sellers/{sellerId}/{paymentLinkId}/cancel`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"paymentLinkId"}}`, encodeURIComponent(String(requestParameters.paymentLinkId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancelar um link de pagamento
     */
    async cancelPaymentLink(requestParameters: CancelPaymentLinkRequest): Promise<void> {
        await this.cancelPaymentLinkRaw(requestParameters);
    }

    /**
     * Gerar link de pagamento para o pagador de um estabelecimento
     */
    async createPaymentLinkRaw(requestParameters: CreatePaymentLinkRequest): Promise<runtime.ApiResponse<GetPaymentLinkDto>> {
        if (requestParameters.createPaymentLinkDto === null || requestParameters.createPaymentLinkDto === undefined) {
            throw new runtime.RequiredError('createPaymentLinkDto','Required parameter requestParameters.createPaymentLinkDto was null or undefined when calling createPaymentLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payment-links`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentLinkDtoToJSON(requestParameters.createPaymentLinkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentLinkDtoFromJSON(jsonValue));
    }

    /**
     * Gerar link de pagamento para o pagador de um estabelecimento
     */
    async createPaymentLink(requestParameters: CreatePaymentLinkRequest): Promise<GetPaymentLinkDto> {
        const response = await this.createPaymentLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Em alternativa a esta rota, utilize a [Payment Links -> createSellerPaymentLinks](#/Payment%20Links/createSellerPaymentLinks)
     * Gerar um lote ou um link de pagamento para o pagador de um estabelecimento
     */
    async createPaymentLinkV2Raw(requestParameters: CreatePaymentLinkV2Request): Promise<runtime.ApiResponse<Array<CreatePaymentLinkResponseDto>>> {
        if (requestParameters.createPaymentLinkDto === null || requestParameters.createPaymentLinkDto === undefined) {
            throw new runtime.RequiredError('createPaymentLinkDto','Required parameter requestParameters.createPaymentLinkDto was null or undefined when calling createPaymentLinkV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/payment-links`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createPaymentLinkDto.map(CreatePaymentLinkDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreatePaymentLinkResponseDtoFromJSON));
    }

    /**
     * Em alternativa a esta rota, utilize a [Payment Links -> createSellerPaymentLinks](#/Payment%20Links/createSellerPaymentLinks)
     * Gerar um lote ou um link de pagamento para o pagador de um estabelecimento
     */
    async createPaymentLinkV2(requestParameters: CreatePaymentLinkV2Request): Promise<Array<CreatePaymentLinkResponseDto>> {
        const response = await this.createPaymentLinkV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar um lote ou um link de pagamento para o pagador de um estabelecimento
     */
    async createSellerPaymentLinksRaw(requestParameters: CreateSellerPaymentLinksRequest): Promise<runtime.ApiResponse<Array<CreatePaymentLinkResponseDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createSellerPaymentLinks.');
        }

        if (requestParameters.createPaymentLinkSellerDto === null || requestParameters.createPaymentLinkSellerDto === undefined) {
            throw new runtime.RequiredError('createPaymentLinkSellerDto','Required parameter requestParameters.createPaymentLinkSellerDto was null or undefined when calling createSellerPaymentLinks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/payment-links/sellers/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createPaymentLinkSellerDto.map(CreatePaymentLinkSellerDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreatePaymentLinkResponseDtoFromJSON));
    }

    /**
     * Gerar um lote ou um link de pagamento para o pagador de um estabelecimento
     */
    async createSellerPaymentLinks(requestParameters: CreateSellerPaymentLinksRequest): Promise<Array<CreatePaymentLinkResponseDto>> {
        const response = await this.createSellerPaymentLinksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar a url do boleto pelo token do pagamento informado
     */
    async getPaymentLinkBilletUrlRaw(requestParameters: GetPaymentLinkBilletUrlRequest): Promise<runtime.ApiResponse<GetPaymentLinkSaleDataResponseDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getPaymentLinkBilletUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/payment-links/{token}/billet`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentLinkSaleDataResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retornar a url do boleto pelo token do pagamento informado
     */
    async getPaymentLinkBilletUrl(requestParameters: GetPaymentLinkBilletUrlRequest): Promise<GetPaymentLinkSaleDataResponseDto> {
        const response = await this.getPaymentLinkBilletUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar os dados do link de pagamento informado
     */
    async getPaymentLinkByTokenRaw(requestParameters: GetPaymentLinkByTokenRequest): Promise<runtime.ApiResponse<GetPaymentLinkDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getPaymentLinkByToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/payment-links/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentLinkDtoFromJSON(jsonValue));
    }

    /**
     * Retornar os dados do link de pagamento informado
     */
    async getPaymentLinkByToken(requestParameters: GetPaymentLinkByTokenRequest): Promise<GetPaymentLinkDto> {
        const response = await this.getPaymentLinkByTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reenviar recibo para o email do pagador
     */
    async sentPaymentLinkReceiptMailRaw(requestParameters: SentPaymentLinkReceiptMailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling sentPaymentLinkReceiptMail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/payment-links/{token}/receipt`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reenviar recibo para o email do pagador
     */
    async sentPaymentLinkReceiptMail(requestParameters: SentPaymentLinkReceiptMailRequest): Promise<void> {
        await this.sentPaymentLinkReceiptMailRaw(requestParameters);
    }

    /**
     * Atualizar um link de pagamento
     */
    async updatePaymentLinkRaw(requestParameters: UpdatePaymentLinkRequest): Promise<runtime.ApiResponse<GetPaymentLinkDto>> {
        if (requestParameters.paymentLinkId === null || requestParameters.paymentLinkId === undefined) {
            throw new runtime.RequiredError('paymentLinkId','Required parameter requestParameters.paymentLinkId was null or undefined when calling updatePaymentLink.');
        }

        if (requestParameters.updatePaymentLinkDto === null || requestParameters.updatePaymentLinkDto === undefined) {
            throw new runtime.RequiredError('updatePaymentLinkDto','Required parameter requestParameters.updatePaymentLinkDto was null or undefined when calling updatePaymentLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payment-links/{paymentLinkId}/update`.replace(`{${"paymentLinkId"}}`, encodeURIComponent(String(requestParameters.paymentLinkId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePaymentLinkDtoToJSON(requestParameters.updatePaymentLinkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentLinkDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar um link de pagamento
     */
    async updatePaymentLink(requestParameters: UpdatePaymentLinkRequest): Promise<GetPaymentLinkDto> {
        const response = await this.updatePaymentLinkRaw(requestParameters);
        return await response.value();
    }

}
