/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateBilletDto,
    CreateBilletDtoFromJSON,
    CreateBilletDtoFromJSONTyped,
    CreateBilletDtoToJSON,
    CreatePaymentLinkPayerDto,
    CreatePaymentLinkPayerDtoFromJSON,
    CreatePaymentLinkPayerDtoFromJSONTyped,
    CreatePaymentLinkPayerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePaymentLinkSellerDto
 */
export interface CreatePaymentLinkSellerDto {
    /**
     * Valor
     * @type {number}
     * @memberof CreatePaymentLinkSellerDto
     */
    amount: number;
    /**
     * Descrição
     * @type {string}
     * @memberof CreatePaymentLinkSellerDto
     */
    description: string;
    /**
     * Data de vencimento
     * @type {Date}
     * @memberof CreatePaymentLinkSellerDto
     */
    expires_in?: Date;
    /**
     * Pagador
     * @type {CreatePaymentLinkPayerDto}
     * @memberof CreatePaymentLinkSellerDto
     */
    payer: CreatePaymentLinkPayerDto;
    /**
     * Métodos de pagamento
     * @type {Array<string>}
     * @memberof CreatePaymentLinkSellerDto
     */
    payment_methods: Array<CreatePaymentLinkSellerDtoPaymentMethodsEnum>;
    /**
     * Pré captura
     * @type {boolean}
     * @memberof CreatePaymentLinkSellerDto
     */
    pre_capture?: boolean;
    /**
     * Observação
     * @type {string}
     * @memberof CreatePaymentLinkSellerDto
     */
    note?: string;
    /**
     * Boleto
     * @type {CreateBilletDto}
     * @memberof CreatePaymentLinkSellerDto
     */
    billet?: CreateBilletDto;
    /**
     * Permitir a edição dos campos de geração de boleto no link de pagamento
     * @type {boolean}
     * @memberof CreatePaymentLinkSellerDto
     */
    is_edit?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreatePaymentLinkSellerDto
     */
    metadata?: object;
}

/**
* @export
* @enum {string}
*/
export enum CreatePaymentLinkSellerDtoPaymentMethodsEnum {
    Card = 'card',
    Billet = 'billet',
    Pix = 'pix'
}

export function CreatePaymentLinkSellerDtoFromJSON(json: any): CreatePaymentLinkSellerDto {
    return CreatePaymentLinkSellerDtoFromJSONTyped(json, false);
}

export function CreatePaymentLinkSellerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentLinkSellerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'description': json['description'],
        'expires_in': !exists(json, 'expires_in') ? undefined : (new Date(json['expires_in'])),
        'payer': CreatePaymentLinkPayerDtoFromJSON(json['payer']),
        'payment_methods': json['payment_methods'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'billet': !exists(json, 'billet') ? undefined : CreateBilletDtoFromJSON(json['billet']),
        'is_edit': !exists(json, 'is_edit') ? undefined : json['is_edit'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function CreatePaymentLinkSellerDtoToJSON(value?: CreatePaymentLinkSellerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'description': value.description,
        'expires_in': value.expires_in === undefined ? undefined : (value.expires_in.toISOString()),
        'payer': CreatePaymentLinkPayerDtoToJSON(value.payer),
        'payment_methods': value.payment_methods,
        'pre_capture': value.pre_capture,
        'note': value.note,
        'billet': CreateBilletDtoToJSON(value.billet),
        'is_edit': value.is_edit,
        'metadata': value.metadata,
    };
}


