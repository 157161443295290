/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateAddressDto,
    CreateAddressDtoFromJSON,
    CreateAddressDtoFromJSONTyped,
    CreateAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePayerDto
 */
export interface CreatePayerDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePayerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePayerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePayerDto
     */
    email?: string;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof CreatePayerDto
     */
    address?: CreateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreatePayerDto
     */
    phone?: string;
}

export function CreatePayerDtoFromJSON(json: any): CreatePayerDto {
    return CreatePayerDtoFromJSONTyped(json, false);
}

export function CreatePayerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePayerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'document': json['document'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address': !exists(json, 'address') ? undefined : CreateAddressDtoFromJSON(json['address']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function CreatePayerDtoToJSON(value?: CreatePayerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'document': value.document,
        'email': value.email,
        'address': CreateAddressDtoToJSON(value.address),
        'phone': value.phone,
    };
}


