import React from 'react';

import { Text } from '@chakra-ui/react';
import { ErrorMessage, ErrorMessageProps } from 'formik';

type FormErrorMessageProps = ErrorMessageProps & {
	id: string;
};

const FormErrorMessage = (props: FormErrorMessageProps) => {
	return (
		<ErrorMessage {...props} render={msg => <Text id={`text-${props.id}`} fontSize={`sm`} color={`red.500`}>{msg}</Text>}/>
	);
};

export default FormErrorMessage;