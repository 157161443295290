/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetMerchantReportsPermissionsDto,
    GetMerchantReportsPermissionsDtoFromJSON,
    GetMerchantReportsPermissionsDtoFromJSONTyped,
    GetMerchantReportsPermissionsDtoToJSON,
    GetMerchantTablesPermissionsDto,
    GetMerchantTablesPermissionsDtoFromJSON,
    GetMerchantTablesPermissionsDtoFromJSONTyped,
    GetMerchantTablesPermissionsDtoToJSON,
    Logo,
    LogoFromJSON,
    LogoFromJSONTyped,
    LogoToJSON,
    Theme,
    ThemeFromJSON,
    ThemeFromJSONTyped,
    ThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMerchantDto
 */
export interface GetMerchantDto {
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMerchantDto
     */
    is_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    payment_by_link_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    payment_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    accounting_account_spread_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMerchantDto
     */
    financial_emails?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetMerchantDto
     */
    pre_capture?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMerchantDto
     */
    risk_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMerchantDto
     */
    min_risk?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    merchant_api_key?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    merchant_account?: string;
    /**
     * 
     * @type {Logo}
     * @memberof GetMerchantDto
     */
    logo?: Logo;
    /**
     * 
     * @type {Theme}
     * @memberof GetMerchantDto
     */
    theme?: Theme;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMerchantDto
     */
    merchant_subjects?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMerchantDto
     */
    seller_subjects?: Array<string>;
    /**
     * 
     * @type {GetMerchantReportsPermissionsDto}
     * @memberof GetMerchantDto
     */
    reports_permissions?: GetMerchantReportsPermissionsDto;
    /**
     * 
     * @type {GetMerchantTablesPermissionsDto}
     * @memberof GetMerchantDto
     */
    tables_permissions?: GetMerchantTablesPermissionsDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetMerchantDto
     */
    three_ds_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMerchantDto
     */
    min_three_ds?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMerchantDto
     */
    three_ds_value?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMerchantDto
     */
    risk_value?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantDto
     */
    type?: GetMerchantDtoTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum GetMerchantDtoTypeEnum {
    Base = 'base',
    Central = 'central',
    Others = 'others'
}

export function GetMerchantDtoFromJSON(json: any): GetMerchantDto {
    return GetMerchantDtoFromJSONTyped(json, false);
}

export function GetMerchantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMerchantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'document': json['document'],
        'name': json['name'],
        'code': json['code'],
        'description': json['description'],
        'is_active': !exists(json, 'is_active') ? undefined : json['is_active'],
        'payment_by_link_url': !exists(json, 'payment_by_link_url') ? undefined : json['payment_by_link_url'],
        'payment_url': !exists(json, 'payment_url') ? undefined : json['payment_url'],
        'accounting_account_spread_id': !exists(json, 'accounting_account_spread_id') ? undefined : json['accounting_account_spread_id'],
        'financial_emails': !exists(json, 'financial_emails') ? undefined : json['financial_emails'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
        'risk_active': !exists(json, 'risk_active') ? undefined : json['risk_active'],
        'min_risk': !exists(json, 'min_risk') ? undefined : json['min_risk'],
        'merchant_api_key': !exists(json, 'merchant_api_key') ? undefined : json['merchant_api_key'],
        'merchant_account': !exists(json, 'merchant_account') ? undefined : json['merchant_account'],
        'logo': !exists(json, 'logo') ? undefined : LogoFromJSON(json['logo']),
        'theme': !exists(json, 'theme') ? undefined : ThemeFromJSON(json['theme']),
        'merchant_subjects': !exists(json, 'merchant_subjects') ? undefined : json['merchant_subjects'],
        'seller_subjects': !exists(json, 'seller_subjects') ? undefined : json['seller_subjects'],
        'reports_permissions': !exists(json, 'reports_permissions') ? undefined : GetMerchantReportsPermissionsDtoFromJSON(json['reports_permissions']),
        'tables_permissions': !exists(json, 'tables_permissions') ? undefined : GetMerchantTablesPermissionsDtoFromJSON(json['tables_permissions']),
        'three_ds_active': !exists(json, 'three_ds_active') ? undefined : json['three_ds_active'],
        'min_three_ds': !exists(json, 'min_three_ds') ? undefined : json['min_three_ds'],
        'three_ds_value': !exists(json, 'three_ds_value') ? undefined : json['three_ds_value'],
        'risk_value': !exists(json, 'risk_value') ? undefined : json['risk_value'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function GetMerchantDtoToJSON(value?: GetMerchantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'document': value.document,
        'name': value.name,
        'code': value.code,
        'description': value.description,
        'is_active': value.is_active,
        'payment_by_link_url': value.payment_by_link_url,
        'payment_url': value.payment_url,
        'accounting_account_spread_id': value.accounting_account_spread_id,
        'financial_emails': value.financial_emails,
        'pre_capture': value.pre_capture,
        'risk_active': value.risk_active,
        'min_risk': value.min_risk,
        'merchant_api_key': value.merchant_api_key,
        'merchant_account': value.merchant_account,
        'logo': LogoToJSON(value.logo),
        'theme': ThemeToJSON(value.theme),
        'merchant_subjects': value.merchant_subjects,
        'seller_subjects': value.seller_subjects,
        'reports_permissions': GetMerchantReportsPermissionsDtoToJSON(value.reports_permissions),
        'tables_permissions': GetMerchantTablesPermissionsDtoToJSON(value.tables_permissions),
        'three_ds_active': value.three_ds_active,
        'min_three_ds': value.min_three_ds,
        'three_ds_value': value.three_ds_value,
        'risk_value': value.risk_value,
        'type': value.type,
    };
}


