/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateCostPlanDto,
    CreateCostPlanDtoFromJSON,
    CreateCostPlanDtoToJSON,
    GetCostPlanDto,
    GetCostPlanDtoFromJSON,
    GetCostPlanDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface CreateCostPlanRequest {
    createCostPlanDto: CreateCostPlanDto;
}

export interface GetCostPlanRequest {
    costPlanId: string;
}

export interface ListCostsPlansRequest {
    limit: number;
    currentPage: number;
    sort?: ListCostsPlansSortEnum;
    name?: string;
}

/**
 * 
 */
export class CostsPlansApi extends runtime.BaseAPI {

    /**
     * Criar plano de custo
     */
    async createCostPlanRaw(requestParameters: CreateCostPlanRequest): Promise<runtime.ApiResponse<GetCostPlanDto>> {
        if (requestParameters.createCostPlanDto === null || requestParameters.createCostPlanDto === undefined) {
            throw new runtime.RequiredError('createCostPlanDto','Required parameter requestParameters.createCostPlanDto was null or undefined when calling createCostPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs-plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCostPlanDtoToJSON(requestParameters.createCostPlanDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostPlanDtoFromJSON(jsonValue));
    }

    /**
     * Criar plano de custo
     */
    async createCostPlan(requestParameters: CreateCostPlanRequest): Promise<GetCostPlanDto> {
        const response = await this.createCostPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obtém dados do plano de custo através do id.
     */
    async getCostPlanRaw(requestParameters: GetCostPlanRequest): Promise<runtime.ApiResponse<GetCostPlanDto>> {
        if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
            throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling getCostPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs-plans/{costPlanId}`.replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostPlanDtoFromJSON(jsonValue));
    }

    /**
     * Obtém dados do plano de custo através do id.
     */
    async getCostPlan(requestParameters: GetCostPlanRequest): Promise<GetCostPlanDto> {
        const response = await this.getCostPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar todos os planos de custos cadastrados
     */
    async listAllCostsPlansRaw(): Promise<runtime.ApiResponse<Array<GetCostPlanDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs-plans/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCostPlanDtoFromJSON));
    }

    /**
     * Listar todos os planos de custos cadastrados
     */
    async listAllCostsPlans(): Promise<Array<GetCostPlanDto>> {
        const response = await this.listAllCostsPlansRaw();
        return await response.value();
    }

    /**
     * Listar os planos de custos cadastrados
     */
    async listCostsPlansRaw(requestParameters: ListCostsPlansRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listCostsPlans.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listCostsPlans.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs-plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar os planos de custos cadastrados
     */
    async listCostsPlans(requestParameters: ListCostsPlansRequest): Promise<PaginatedDto> {
        const response = await this.listCostsPlansRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListCostsPlansSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
