/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatePaymentLinkFromProtocolDto,
    CreatePaymentLinkFromProtocolDtoFromJSON,
    CreatePaymentLinkFromProtocolDtoFromJSONTyped,
    CreatePaymentLinkFromProtocolDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateSellerProtocolDto
 */
export interface CreateSellerProtocolDto {
    /**
     * 
     * @type {number}
     * @memberof CreateSellerProtocolDto
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerProtocolDto
     */
    presenter_name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSellerProtocolDto
     */
    value_cents?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerProtocolDto
     */
    has_email_notification: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerProtocolDto
     */
    payer_email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerProtocolDto
     */
    email_content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerProtocolDto
     */
    has_payment_link: boolean;
    /**
     * 
     * @type {CreatePaymentLinkFromProtocolDto}
     * @memberof CreateSellerProtocolDto
     */
    payment_link?: CreatePaymentLinkFromProtocolDto;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerProtocolDto
     */
    due_date: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerProtocolDto
     */
    creation_date: string;
}

export function CreateSellerProtocolDtoFromJSON(json: any): CreateSellerProtocolDto {
    return CreateSellerProtocolDtoFromJSONTyped(json, false);
}

export function CreateSellerProtocolDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSellerProtocolDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'presenter_name': json['presenter_name'],
        'value_cents': !exists(json, 'value_cents') ? undefined : json['value_cents'],
        'has_email_notification': json['has_email_notification'],
        'payer_email': !exists(json, 'payer_email') ? undefined : json['payer_email'],
        'email_content': !exists(json, 'email_content') ? undefined : json['email_content'],
        'has_payment_link': json['has_payment_link'],
        'payment_link': !exists(json, 'payment_link') ? undefined : CreatePaymentLinkFromProtocolDtoFromJSON(json['payment_link']),
        'due_date': json['due_date'],
        'creation_date': json['creation_date'],
    };
}

export function CreateSellerProtocolDtoToJSON(value?: CreateSellerProtocolDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'presenter_name': value.presenter_name,
        'value_cents': value.value_cents,
        'has_email_notification': value.has_email_notification,
        'payer_email': value.payer_email,
        'email_content': value.email_content,
        'has_payment_link': value.has_payment_link,
        'payment_link': CreatePaymentLinkFromProtocolDtoToJSON(value.payment_link),
        'due_date': value.due_date,
        'creation_date': value.creation_date,
    };
}


