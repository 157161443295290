/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRoleDto,
    GetRoleDtoFromJSON,
    GetRoleDtoFromJSONTyped,
    GetRoleDtoToJSON,
    GetUserMerchantDto,
    GetUserMerchantDtoFromJSON,
    GetUserMerchantDtoFromJSONTyped,
    GetUserMerchantDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMerchantUserDto
 */
export interface GetMerchantUserDto {
    /**
     * 
     * @type {string}
     * @memberof GetMerchantUserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantUserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantUserDto
     */
    type: GetMerchantUserDtoTypeEnum;
    /**
     * 
     * @type {GetUserMerchantDto}
     * @memberof GetMerchantUserDto
     */
    merchant: GetUserMerchantDto;
    /**
     * 
     * @type {GetRoleDto}
     * @memberof GetMerchantUserDto
     */
    role: GetRoleDto;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantUserDto
     */
    status: GetMerchantUserDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantUserDto
     */
    created_at: string;
}

/**
* @export
* @enum {string}
*/
export enum GetMerchantUserDtoTypeEnum {
    Merchant = 'merchant',
    Seller = 'seller',
    Backoffice = 'backoffice'
}/**
* @export
* @enum {string}
*/
export enum GetMerchantUserDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending'
}

export function GetMerchantUserDtoFromJSON(json: any): GetMerchantUserDto {
    return GetMerchantUserDtoFromJSONTyped(json, false);
}

export function GetMerchantUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMerchantUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'email': json['email'],
        'type': json['type'],
        'merchant': GetUserMerchantDtoFromJSON(json['merchant']),
        'role': GetRoleDtoFromJSON(json['role']),
        'status': json['status'],
        'created_at': json['created_at'],
    };
}

export function GetMerchantUserDtoToJSON(value?: GetMerchantUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'type': value.type,
        'merchant': GetUserMerchantDtoToJSON(value.merchant),
        'role': GetRoleDtoToJSON(value.role),
        'status': value.status,
        'created_at': value.created_at,
    };
}


