/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SettlementOption,
    SettlementOptionFromJSON,
    SettlementOptionFromJSONTyped,
    SettlementOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettlementPlan
 */
export interface SettlementPlan {
    /**
     * 
     * @type {string}
     * @memberof SettlementPlan
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementPlan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementPlan
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementPlan
     */
    code: SettlementPlanCodeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SettlementPlan
     */
    anticipation_receivables: boolean;
    /**
     * 
     * @type {Array<SettlementOption>}
     * @memberof SettlementPlan
     */
    options?: Array<SettlementOption>;
}

/**
* @export
* @enum {string}
*/
export enum SettlementPlanCodeEnum {
    D0 = 'D0',
    D1 = 'D1',
    D30 = 'D30'
}

export function SettlementPlanFromJSON(json: any): SettlementPlan {
    return SettlementPlanFromJSONTyped(json, false);
}

export function SettlementPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': json['description'],
        'code': json['code'],
        'anticipation_receivables': json['anticipation_receivables'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(SettlementOptionFromJSON)),
    };
}

export function SettlementPlanToJSON(value?: SettlementPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'code': value.code,
        'anticipation_receivables': value.anticipation_receivables,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(SettlementOptionToJSON)),
    };
}


