/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentsSplitRulesDto,
    PaymentsSplitRulesDtoFromJSON,
    PaymentsSplitRulesDtoFromJSONTyped,
    PaymentsSplitRulesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePixPaymentWithSplitResponseDTO
 */
export interface CreatePixPaymentWithSplitResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatePixPaymentWithSplitResponseDTO
     */
    resultCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePixPaymentWithSplitResponseDTO
     */
    qrCodeData: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePixPaymentWithSplitResponseDTO
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePixPaymentWithSplitResponseDTO
     */
    hasSplitRules?: boolean;
    /**
     * 
     * @type {PaymentsSplitRulesDto}
     * @memberof CreatePixPaymentWithSplitResponseDTO
     */
    splitRules?: PaymentsSplitRulesDto;
}

export function CreatePixPaymentWithSplitResponseDTOFromJSON(json: any): CreatePixPaymentWithSplitResponseDTO {
    return CreatePixPaymentWithSplitResponseDTOFromJSONTyped(json, false);
}

export function CreatePixPaymentWithSplitResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePixPaymentWithSplitResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': json['resultCode'],
        'qrCodeData': json['qrCodeData'],
        'url': json['url'],
        'hasSplitRules': !exists(json, 'hasSplitRules') ? undefined : json['hasSplitRules'],
        'splitRules': !exists(json, 'splitRules') ? undefined : PaymentsSplitRulesDtoFromJSON(json['splitRules']),
    };
}

export function CreatePixPaymentWithSplitResponseDTOToJSON(value?: CreatePixPaymentWithSplitResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'qrCodeData': value.qrCodeData,
        'url': value.url,
        'hasSplitRules': value.hasSplitRules,
        'splitRules': PaymentsSplitRulesDtoToJSON(value.splitRules),
    };
}


