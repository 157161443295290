/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBilletPayerAddressDto
 */
export interface GetBilletPayerAddressDto {
    /**
     * 
     * @type {string}
     * @memberof GetBilletPayerAddressDto
     */
    complement: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletPayerAddressDto
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletPayerAddressDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletPayerAddressDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletPayerAddressDto
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletPayerAddressDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletPayerAddressDto
     */
    city: string;
}

export function GetBilletPayerAddressDtoFromJSON(json: any): GetBilletPayerAddressDto {
    return GetBilletPayerAddressDtoFromJSONTyped(json, false);
}

export function GetBilletPayerAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBilletPayerAddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'complement': json['complement'],
        'district': json['district'],
        'street': json['street'],
        'number': json['number'],
        'zipcode': json['zipcode'],
        'state': json['state'],
        'city': json['city'],
    };
}

export function GetBilletPayerAddressDtoToJSON(value?: GetBilletPayerAddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'complement': value.complement,
        'district': value.district,
        'street': value.street,
        'number': value.number,
        'zipcode': value.zipcode,
        'state': value.state,
        'city': value.city,
    };
}


