import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';

import { UserLoginResponseDto, Permission, PermissionActionsEnum, PermissionSubjectEnum } from '../clients';

export type AppAbility = Ability<[PermissionActionsEnum, PermissionSubjectEnum]>;

export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(loggedUserInfo: UserLoginResponseDto) {
	const { can, rules } = new AbilityBuilder(AppAbility);

	if (!loggedUserInfo || null === loggedUserInfo) {
		return [];
	}

	let permissions: Permission[] = loggedUserInfo?.role?.permissions;

	can(PermissionActionsEnum.Read, PermissionSubjectEnum.PageNotFound);

	permissions?.forEach((permission: Permission) => {
		can(permission.actions, permission.subject);
	});

	return rules;
}

export function buildAbilityFor(loggedUserInfo: UserLoginResponseDto): AppAbility {
	return new AppAbility(defineRulesFor(loggedUserInfo), {
		detectSubjectType: (object) => {
			return object;
		},
	});
}
