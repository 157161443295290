/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface CancelSessionRequest {
    sellerId: string;
    sessionId: string;
}

export interface ListSellerSessionsRequest {
    limit: number;
    currentPage: number;
    status: ListSellerSessionsStatusEnum;
    sellerId: string;
    sort?: ListSellerSessionsSortEnum;
}

/**
 * 
 */
export class SessionsApi extends runtime.BaseAPI {

    /**
     * Cancelar uma sessão ativa
     */
    async cancelSessionRaw(requestParameters: CancelSessionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling cancelSession.');
        }

        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling cancelSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sessions/{sellerId}/{sessionId}/cancel`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancelar uma sessão ativa
     */
    async cancelSession(requestParameters: CancelSessionRequest): Promise<void> {
        await this.cancelSessionRaw(requestParameters);
    }

    /**
     * Listar as Sessões de um seller
     */
    async listSellerSessionsRaw(requestParameters: ListSellerSessionsRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerSessions.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerSessions.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling listSellerSessions.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerSessions.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sessions/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar as Sessões de um seller
     */
    async listSellerSessions(requestParameters: ListSellerSessionsRequest): Promise<PaginatedDto> {
        const response = await this.listSellerSessionsRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListSellerSessionsStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSessionsSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
