/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CancelSalePos,
    CancelSalePosFromJSON,
    CancelSalePosToJSON,
    PaymentPosRequest,
    PaymentPosRequestFromJSON,
    PaymentPosRequestToJSON,
    PaymentPosRequestDTOV2,
    PaymentPosRequestDTOV2FromJSON,
    PaymentPosRequestDTOV2ToJSON,
} from '../models';

export interface PosControllerCancelPaymentRequest {
    sellerId: string;
    cancelSalePos: CancelSalePos;
}

export interface PosControllerPaymentsRequestRequest {
    sellerId: string;
    paymentPosRequest: PaymentPosRequest;
}

export interface PosControllerV2PaymentsRequestWithSplitRequest {
    sellerId: string;
    paymentPosRequestDTOV2: PaymentPosRequestDTOV2;
}

/**
 * 
 */
export class PosPaymentsApi extends runtime.BaseAPI {

    /**
     * Cancelar uma venda via Pos
     */
    async posControllerCancelPaymentRaw(requestParameters: PosControllerCancelPaymentRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling posControllerCancelPayment.');
        }

        if (requestParameters.cancelSalePos === null || requestParameters.cancelSalePos === undefined) {
            throw new runtime.RequiredError('cancelSalePos','Required parameter requestParameters.cancelSalePos was null or undefined when calling posControllerCancelPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos/cancel_sale/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelSalePosToJSON(requestParameters.cancelSalePos),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Cancelar uma venda via Pos
     */
    async posControllerCancelPayment(requestParameters: PosControllerCancelPaymentRequest): Promise<object> {
        const response = await this.posControllerCancelPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo pagamento via Pos
     */
    async posControllerPaymentsRequestRaw(requestParameters: PosControllerPaymentsRequestRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling posControllerPaymentsRequest.');
        }

        if (requestParameters.paymentPosRequest === null || requestParameters.paymentPosRequest === undefined) {
            throw new runtime.RequiredError('paymentPosRequest','Required parameter requestParameters.paymentPosRequest was null or undefined when calling posControllerPaymentsRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos/payments/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentPosRequestToJSON(requestParameters.paymentPosRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Criar novo pagamento via Pos
     */
    async posControllerPaymentsRequest(requestParameters: PosControllerPaymentsRequestRequest): Promise<object> {
        const response = await this.posControllerPaymentsRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo pagamento via Pos
     */
    async posControllerV2PaymentsRequestWithSplitRaw(requestParameters: PosControllerV2PaymentsRequestWithSplitRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling posControllerV2PaymentsRequestWithSplit.');
        }

        if (requestParameters.paymentPosRequestDTOV2 === null || requestParameters.paymentPosRequestDTOV2 === undefined) {
            throw new runtime.RequiredError('paymentPosRequestDTOV2','Required parameter requestParameters.paymentPosRequestDTOV2 was null or undefined when calling posControllerV2PaymentsRequestWithSplit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/pos/payments/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentPosRequestDTOV2ToJSON(requestParameters.paymentPosRequestDTOV2),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Criar novo pagamento via Pos
     */
    async posControllerV2PaymentsRequestWithSplit(requestParameters: PosControllerV2PaymentsRequestWithSplitRequest): Promise<object> {
        const response = await this.posControllerV2PaymentsRequestWithSplitRaw(requestParameters);
        return await response.value();
    }

}
