/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillPaymentSalesPermissions
 */
export interface BillPaymentSalesPermissions {
    /**
     * 
     * @type {string}
     * @memberof BillPaymentSalesPermissions
     */
    report: BillPaymentSalesPermissionsReportEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillPaymentSalesPermissions
     */
    fields: Array<BillPaymentSalesPermissionsFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillPaymentSalesPermissions
     */
    denied_fields: Array<BillPaymentSalesPermissionsDeniedFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillPaymentSalesPermissions
     */
    allowed_fields: Array<BillPaymentSalesPermissionsAllowedFieldsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum BillPaymentSalesPermissionsReportEnum {
    SalesHistory = 'sales_history',
    SettlementBatchTransaction = 'settlement_batch_transaction',
    SettlementBatchSale = 'settlement_batch_sale',
    BillPaymentSales = 'bill_payment_sales'
}/**
* @export
* @enum {string}
*/
export enum BillPaymentSalesPermissionsFieldsEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    UpdatedAt = 'updated_at',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PayerDocument = 'payer_document',
    PayerEmail = 'payer_email',
    PayerPhone = 'payer_phone',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    Digitable = 'digitable',
    DueDate = 'due_date',
    Assignor = 'assignor',
    RecipientName = 'recipient_name',
    RecipientDocument = 'recipient_document'
}/**
* @export
* @enum {string}
*/
export enum BillPaymentSalesPermissionsDeniedFieldsEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    UpdatedAt = 'updated_at',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PayerDocument = 'payer_document',
    PayerEmail = 'payer_email',
    PayerPhone = 'payer_phone',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    Digitable = 'digitable',
    DueDate = 'due_date',
    Assignor = 'assignor',
    RecipientName = 'recipient_name',
    RecipientDocument = 'recipient_document'
}/**
* @export
* @enum {string}
*/
export enum BillPaymentSalesPermissionsAllowedFieldsEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    UpdatedAt = 'updated_at',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PayerDocument = 'payer_document',
    PayerEmail = 'payer_email',
    PayerPhone = 'payer_phone',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    Digitable = 'digitable',
    DueDate = 'due_date',
    Assignor = 'assignor',
    RecipientName = 'recipient_name',
    RecipientDocument = 'recipient_document'
}

export function BillPaymentSalesPermissionsFromJSON(json: any): BillPaymentSalesPermissions {
    return BillPaymentSalesPermissionsFromJSONTyped(json, false);
}

export function BillPaymentSalesPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillPaymentSalesPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'report': json['report'],
        'fields': json['fields'],
        'denied_fields': json['denied_fields'],
        'allowed_fields': json['allowed_fields'],
    };
}

export function BillPaymentSalesPermissionsToJSON(value?: BillPaymentSalesPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report': value.report,
        'fields': value.fields,
        'denied_fields': value.denied_fields,
        'allowed_fields': value.allowed_fields,
    };
}


