/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateExportPosListDto,
    CreateExportPosListDtoFromJSON,
    CreateExportPosListDtoToJSON,
    GetFileExportRequestDto,
    GetFileExportRequestDtoFromJSON,
    GetFileExportRequestDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    UpdateSellerPosDto,
    UpdateSellerPosDtoFromJSON,
    UpdateSellerPosDtoToJSON,
} from '../models';

export interface CreatePosListExportRequestRequest {
    createExportPosListDto: CreateExportPosListDto;
}

export interface ListPosRequest {
    limit: number;
    currentPage: number;
    sort?: ListPosSortEnum;
    status?: Array<ListPosStatusEnum>;
    search?: string;
}

export interface UpdateBackofficePosRequest {
    posId: string;
    updateSellerPosDto: UpdateSellerPosDto;
}

/**
 * 
 */
export class PosApi extends runtime.BaseAPI {

    /**
     * Gerar uma requisição de exportação da lista de máquinas
     */
    async createPosListExportRequestRaw(requestParameters: CreatePosListExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportPosListDto === null || requestParameters.createExportPosListDto === undefined) {
            throw new runtime.RequiredError('createExportPosListDto','Required parameter requestParameters.createExportPosListDto was null or undefined when calling createPosListExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos/list/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportPosListDtoToJSON(requestParameters.createExportPosListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação da lista de máquinas
     */
    async createPosListExportRequest(requestParameters: CreatePosListExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createPosListExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar as máquinas de todos os estabelecimentos
     */
    async listPosRaw(requestParameters: ListPosRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listPos.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listPos.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar as máquinas de todos os estabelecimentos
     */
    async listPos(requestParameters: ListPosRequest): Promise<PaginatedDto> {
        const response = await this.listPosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar pos via backoffice
     */
    async updateBackofficePosRaw(requestParameters: UpdateBackofficePosRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.posId === null || requestParameters.posId === undefined) {
            throw new runtime.RequiredError('posId','Required parameter requestParameters.posId was null or undefined when calling updateBackofficePos.');
        }

        if (requestParameters.updateSellerPosDto === null || requestParameters.updateSellerPosDto === undefined) {
            throw new runtime.RequiredError('updateSellerPosDto','Required parameter requestParameters.updateSellerPosDto was null or undefined when calling updateBackofficePos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos/{posId}`.replace(`{${"posId"}}`, encodeURIComponent(String(requestParameters.posId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSellerPosDtoToJSON(requestParameters.updateSellerPosDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualizar pos via backoffice
     */
    async updateBackofficePos(requestParameters: UpdateBackofficePosRequest): Promise<void> {
        await this.updateBackofficePosRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListPosSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}
/**
    * @export
    * @enum {string}
    */
export enum ListPosStatusEnum {
    Active = 'active',
    Broken = 'broken',
    Canceled = 'canceled',
    Inventory = 'inventory',
    StockForRepair = 'stock_for_repair',
    Repair = 'repair',
    Lost = 'lost'
}
