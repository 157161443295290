/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSplitRulesSessionDto,
    GetSplitRulesSessionDtoFromJSON,
    GetSplitRulesSessionDtoFromJSONTyped,
    GetSplitRulesSessionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSessionDto
 */
export interface GetSessionDto {
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSessionDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    form_payment: string;
    /**
     * 
     * @type {number}
     * @memberof GetSessionDto
     */
    recurrence_day: number;
    /**
     * 
     * @type {number}
     * @memberof GetSessionDto
     */
    installments: number;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    card_token: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSessionDto
     */
    confirmation_required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    service_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    protocol: string;
    /**
     * 
     * @type {Array<GetSplitRulesSessionDto>}
     * @memberof GetSessionDto
     */
    split_rules?: Array<GetSplitRulesSessionDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSessionDto
     */
    main_sales_created: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    status: GetSessionDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    payer_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    payer_email: string;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    payer_document: string;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    payer_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSessionDto
     */
    created_at?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSessionDto
     */
    updated_at?: Date;
}

/**
* @export
* @enum {string}
*/
export enum GetSessionDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

export function GetSessionDtoFromJSON(json: any): GetSessionDto {
    return GetSessionDtoFromJSONTyped(json, false);
}

export function GetSessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSessionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': json['amount'],
        'form_payment': json['form_payment'],
        'recurrence_day': json['recurrence_day'],
        'installments': json['installments'],
        'card_token': json['card_token'],
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'service_id': json['service_id'],
        'protocol': json['protocol'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(GetSplitRulesSessionDtoFromJSON)),
        'main_sales_created': json['main_sales_created'],
        'status': json['status'],
        'description': json['description'],
        'payer_name': json['payer_name'],
        'payer_email': json['payer_email'],
        'payer_document': json['payer_document'],
        'payer_phone': !exists(json, 'payer_phone') ? undefined : json['payer_phone'],
        'seller_id': json['seller_id'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}

export function GetSessionDtoToJSON(value?: GetSessionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'form_payment': value.form_payment,
        'recurrence_day': value.recurrence_day,
        'installments': value.installments,
        'card_token': value.card_token,
        'confirmation_required': value.confirmation_required,
        'service_id': value.service_id,
        'protocol': value.protocol,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(GetSplitRulesSessionDtoToJSON)),
        'main_sales_created': value.main_sales_created,
        'status': value.status,
        'description': value.description,
        'payer_name': value.payer_name,
        'payer_email': value.payer_email,
        'payer_document': value.payer_document,
        'payer_phone': value.payer_phone,
        'seller_id': value.seller_id,
        'created_at': value.created_at,
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
    };
}


