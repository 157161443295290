import { format, formatISO } from "date-fns";

export const formatWithTimezone = (date: Date, expectedFormat: string): string => {
	const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);

	return format(dtDateOnly, expectedFormat);
}

export const formatWithoutTimezone = (date: string | Date, expectedFormat: string): string => {
	let dt = date;

	if (! (date instanceof Date)) {
		dt = new Date(date); 
	}		
	return format(dt as Date, expectedFormat);
}

export const dateToString = (date: Date, expectedFormat: string = 'yyyy-MM-dd'): string => {
	const dateStr = formatISO(date);

	return formatWithoutTimezone(dateStr, expectedFormat);
}

export const monthNumberToName = (monthNumber: number): string => {
	const monthName = {
		1: 'Janeiro',
		2: 'Fevereiro',
		3: 'Março',
		4: 'Abril',
		5: 'Maio',
		6: 'Junho',
		7: 'Julho',
		8: 'Agosto',
		9: 'Setembro',
		10: 'Outubro',
		11: 'Novembro',
		12: 'Dezembro'
	}

	return monthName[monthNumber];
}
