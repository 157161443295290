import * as React from 'react';

import { 
	Spinner, 
	Box, 
	BoxProps, 
	Modal, 
	ModalOverlay, 
	ModalContent, 
	ModalBody
} from '@chakra-ui/react';

type LoaderProps = BoxProps & {
	isOpen: boolean;	
	onClose?: () => void;
};

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
	const { isOpen } = props;

	if (!isOpen) {
		return <></>;
	}
	return (
		<Modal isOpen={isOpen} onClose={() => {}} closeOnOverlayClick={true} closeOnEsc={false} isCentered={true}>
			<ModalOverlay />
			<ModalContent width={`auto`}>
				<ModalBody px={4}>
					<Box bgColor={props.bgColor || `#fff`} p={1}>
						<Spinner thickness='4px' speed='0.3s' emptyColor='gray.200' color='blue.500' size='md' mt={1} />
					</Box>	
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default Loader;
