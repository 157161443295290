/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSimulationDto
 */
export interface GetSimulationDto {
    /**
     * 
     * @type {number}
     * @memberof GetSimulationDto
     */
    installment_amount_cents_decimal: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationDto
     */
    installment_amount_cents: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationDto
     */
    original_amount_cents: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationDto
     */
    installment: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationDto
     */
    total_amount_cents: number;
    /**
     * 
     * @type {string}
     * @memberof GetSimulationDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationDto
     */
    cet: number;
    /**
     * 
     * @type {string}
     * @memberof GetSimulationDto
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationDto
     */
    operation_fee_cents: number;
    /**
     * 
     * @type {number}
     * @memberof GetSimulationDto
     */
    risk_value: number;
}

export function GetSimulationDtoFromJSON(json: any): GetSimulationDto {
    return GetSimulationDtoFromJSONTyped(json, false);
}

export function GetSimulationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSimulationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'installment_amount_cents_decimal': json['installment_amount_cents_decimal'],
        'installment_amount_cents': json['installment_amount_cents'],
        'original_amount_cents': json['original_amount_cents'],
        'installment': json['installment'],
        'total_amount_cents': json['total_amount_cents'],
        'type': json['type'],
        'cet': json['cet'],
        'code': json['code'],
        'operation_fee_cents': json['operation_fee_cents'],
        'risk_value': json['risk_value'],
    };
}

export function GetSimulationDtoToJSON(value?: GetSimulationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'installment_amount_cents_decimal': value.installment_amount_cents_decimal,
        'installment_amount_cents': value.installment_amount_cents,
        'original_amount_cents': value.original_amount_cents,
        'installment': value.installment,
        'total_amount_cents': value.total_amount_cents,
        'type': value.type,
        'cet': value.cet,
        'code': value.code,
        'operation_fee_cents': value.operation_fee_cents,
        'risk_value': value.risk_value,
    };
}


