/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentsSplitsDto
 */
export interface PaymentsSplitsDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentsSplitsDto
     */
    saleId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsSplitsDto
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsSplitsDto
     */
    sellerName: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentsSplitsDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentsSplitsDto
     */
    description: string;
}

export function PaymentsSplitsDtoFromJSON(json: any): PaymentsSplitsDto {
    return PaymentsSplitsDtoFromJSONTyped(json, false);
}

export function PaymentsSplitsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentsSplitsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'saleId': !exists(json, 'saleId') ? undefined : json['saleId'],
        'sellerId': json['sellerId'],
        'sellerName': json['sellerName'],
        'amount': json['amount'],
        'description': json['description'],
    };
}

export function PaymentsSplitsDtoToJSON(value?: PaymentsSplitsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saleId': value.saleId,
        'sellerId': value.sellerId,
        'sellerName': value.sellerName,
        'amount': value.amount,
        'description': value.description,
    };
}


