import React from 'react';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';

import { AiFillCloseCircle, AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai';
import { maskDate, maskMoney } from 'services/masks.service';

import { saleStatus } from 'services/enums.service';
import { HStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import Text from '../../../components/Text';

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	waiting_confirmation: <AiFillClockCircle color='#FFA547' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
};

const PixTable = ({ data, setPagination, pagination, totalPages, onRowClick, setIsModalOpen, setQrcode, ...rest }) => {
	const sales = data?.results?.map((sale) => {
		const date = new Date(sale?.created_at)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

		return {
			id: sale.id,
			description: sale.description,
			total: sale.original_amount / 100,
			payer: sale.payer_name,
			payment_type: sale.payment_type_description,
			status: sale.status,
			created_at:
				date &&
				`${maskDate(date[0] ? date[0].split('-').reverse().join('-') : '-/-/-')} às ${
					date[1] ? date[1]?.replace('Z', '').substr(0, 5) : '-:-'
				}`,
			qrCodeData: sale?.qrcode_data,
		};
	});

	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='status-title' column={column}>
						Status
					</HeaderTitle>
				),
				accessor: 'status',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						<HStack>
							<Text id={`${row.index}-${column.id}-icon-text`}>{iconStatusLiteral[value]}</Text>
							<Text id={`${row.index}-${column.id}-text`}>{saleStatus[value] ?? 'N/A'}</Text>
						</HStack>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='description-title' column={column}>
						Nº do Pedido
					</HeaderTitle>
				),
				accessor: 'description',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payer-title' column={column}>
						Pagador
					</HeaderTitle>
				),
				accessor: 'payer',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-type-title' column={column}>
						Forma de pagamento
					</HeaderTitle>
				),
				accessor: 'payment_type',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='total-title' column={column}>
						Total
					</HeaderTitle>
				),
				accessor: 'total',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						R$ {maskMoney(value)}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='created-at-title' column={column}>
						Data de Criação
					</HeaderTitle>
				),
				accessor: 'created_at',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='qr-code-data-tilte' column={column}>
						QRCode
					</HeaderTitle>
				),
				accessor: 'qrCodeData',
				Cell: ({
					cell: {
						row: { values, original, index },
						value,
						column,
					},
				}) => {
					return (
						<CellContent id={`${index}-${column.id}-cell`} onClick={(e) => e.stopPropagation()}>
							{values.status === 'pending' && values.qrCodeData && (
								<Button
									id={`${index}-${column.id}-button`}
									px='4'
									py='1'
									height='auto'
									bgColor='teal.300'
									color='white'
									fontWeight='normal'
									onClick={() => {
										setIsModalOpen(true);
										setQrcode(values.qrCodeData);
									}}
								>
									QRCode
								</Button>
							)}
						</CellContent>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			onRowClick={onRowClick}
			data={sales}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			maxHeightTableWrapper={250}
		/>
	);
};

export default PixTable;
