/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatePaymentLinkFromProtocolDto,
    CreatePaymentLinkFromProtocolDtoFromJSON,
    CreatePaymentLinkFromProtocolDtoFromJSONTyped,
    CreatePaymentLinkFromProtocolDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateProtocolDto
 */
export interface CreateProtocolDto {
    /**
     * Número do protocolo
     * @type {number}
     * @memberof CreateProtocolDto
     */
    number: number;
    /**
     * Nome do apresentante
     * @type {string}
     * @memberof CreateProtocolDto
     */
    presenter_name: string;
    /**
     * Valor em centavos
     * @type {number}
     * @memberof CreateProtocolDto
     */
    value_cents?: number;
    /**
     * Há notificação por email
     * @type {boolean}
     * @memberof CreateProtocolDto
     */
    has_email_notification: boolean;
    /**
     * Email do pagador
     * @type {string}
     * @memberof CreateProtocolDto
     */
    payer_email?: string;
    /**
     * Conteúdo do email
     * @type {string}
     * @memberof CreateProtocolDto
     */
    email_content?: string;
    /**
     * Há link de pagamento
     * @type {boolean}
     * @memberof CreateProtocolDto
     */
    has_payment_link: boolean;
    /**
     * Link de pagamento
     * @type {CreatePaymentLinkFromProtocolDto}
     * @memberof CreateProtocolDto
     */
    payment_link?: CreatePaymentLinkFromProtocolDto;
    /**
     * Data de vencimento
     * @type {string}
     * @memberof CreateProtocolDto
     */
    due_date: string;
    /**
     * Data de criação
     * @type {string}
     * @memberof CreateProtocolDto
     */
    creation_date: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProtocolDto
     */
    seller_id: string;
}

export function CreateProtocolDtoFromJSON(json: any): CreateProtocolDto {
    return CreateProtocolDtoFromJSONTyped(json, false);
}

export function CreateProtocolDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProtocolDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'presenter_name': json['presenter_name'],
        'value_cents': !exists(json, 'value_cents') ? undefined : json['value_cents'],
        'has_email_notification': json['has_email_notification'],
        'payer_email': !exists(json, 'payer_email') ? undefined : json['payer_email'],
        'email_content': !exists(json, 'email_content') ? undefined : json['email_content'],
        'has_payment_link': json['has_payment_link'],
        'payment_link': !exists(json, 'payment_link') ? undefined : CreatePaymentLinkFromProtocolDtoFromJSON(json['payment_link']),
        'due_date': json['due_date'],
        'creation_date': json['creation_date'],
        'seller_id': json['seller_id'],
    };
}

export function CreateProtocolDtoToJSON(value?: CreateProtocolDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'presenter_name': value.presenter_name,
        'value_cents': value.value_cents,
        'has_email_notification': value.has_email_notification,
        'payer_email': value.payer_email,
        'email_content': value.email_content,
        'has_payment_link': value.has_payment_link,
        'payment_link': CreatePaymentLinkFromProtocolDtoToJSON(value.payment_link),
        'due_date': value.due_date,
        'creation_date': value.creation_date,
        'seller_id': value.seller_id,
    };
}


