/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Celcoin
 */
export interface Celcoin {
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    transaction_id: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    sale_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    assignor: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    payer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    digitable: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    external_terminal?: string;
    /**
     * 
     * @type {number}
     * @memberof Celcoin
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof Celcoin
     */
    original_value: number;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    document_payer?: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    due_date: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    recipient_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Celcoin
     */
    recipient_document?: string;
    /**
     * 
     * @type {number}
     * @memberof Celcoin
     */
    type?: number;
}

export function CelcoinFromJSON(json: any): Celcoin {
    return CelcoinFromJSONTyped(json, false);
}

export function CelcoinFromJSONTyped(json: any, ignoreDiscriminator: boolean): Celcoin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'transaction_id': json['transaction_id'],
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'assignor': json['assignor'],
        'payer_id': !exists(json, 'payer_id') ? undefined : json['payer_id'],
        'digitable': json['digitable'],
        'created_at': json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'external_terminal': !exists(json, 'external_terminal') ? undefined : json['external_terminal'],
        'value': json['value'],
        'original_value': json['original_value'],
        'document_payer': !exists(json, 'document_payer') ? undefined : json['document_payer'],
        'due_date': json['due_date'],
        'recipient_name': !exists(json, 'recipient_name') ? undefined : json['recipient_name'],
        'recipient_document': !exists(json, 'recipient_document') ? undefined : json['recipient_document'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function CelcoinToJSON(value?: Celcoin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'transaction_id': value.transaction_id,
        'sale_id': value.sale_id,
        'assignor': value.assignor,
        'payer_id': value.payer_id,
        'digitable': value.digitable,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'external_terminal': value.external_terminal,
        'value': value.value,
        'original_value': value.original_value,
        'document_payer': value.document_payer,
        'due_date': value.due_date,
        'recipient_name': value.recipient_name,
        'recipient_document': value.recipient_document,
        'type': value.type,
    };
}


