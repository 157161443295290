/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstallmentPlanDTO
 */
export interface InstallmentPlanDTO {
    /**
     * 
     * @type {number}
     * @memberof InstallmentPlanDTO
     */
    number_installments: number;
}

export function InstallmentPlanDTOFromJSON(json: any): InstallmentPlanDTO {
    return InstallmentPlanDTOFromJSONTyped(json, false);
}

export function InstallmentPlanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallmentPlanDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number_installments': json['number_installments'],
    };
}

export function InstallmentPlanDTOToJSON(value?: InstallmentPlanDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number_installments': value.number_installments,
    };
}


