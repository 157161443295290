/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncludePaymentTypeDto,
    IncludePaymentTypeDtoFromJSON,
    IncludePaymentTypeDtoFromJSONTyped,
    IncludePaymentTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface IncludePaymentTypesDto
 */
export interface IncludePaymentTypesDto {
    /**
     * 
     * @type {Array<IncludePaymentTypeDto>}
     * @memberof IncludePaymentTypesDto
     */
    items: Array<IncludePaymentTypeDto>;
}

export function IncludePaymentTypesDtoFromJSON(json: any): IncludePaymentTypesDto {
    return IncludePaymentTypesDtoFromJSONTyped(json, false);
}

export function IncludePaymentTypesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncludePaymentTypesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(IncludePaymentTypeDtoFromJSON)),
    };
}

export function IncludePaymentTypesDtoToJSON(value?: IncludePaymentTypesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(IncludePaymentTypeDtoToJSON)),
    };
}


