/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetResponsibleDto,
    GetResponsibleDtoFromJSON,
    GetResponsibleDtoToJSON,
    UpdateResponsibleDto,
    UpdateResponsibleDtoFromJSON,
    UpdateResponsibleDtoToJSON,
} from '../models';

export interface CreateResponsibleRequest {
    file: Blob;
    name: string;
    type: CreateResponsibleTypeEnum;
    isActive: boolean;
    phone: string;
}

export interface EditResponsibleRequest {
    responsibleId: string;
    updateResponsibleDto: UpdateResponsibleDto;
}

export interface GetResponsibleRequest {
    responsibleId: string;
}

export interface ListResponsibleRequest {
    type?: ListResponsibleTypeEnum;
    isActive?: boolean;
}

export interface UploadResponsibleImageRequest {
    responsibleId: string;
    file: Blob;
}

/**
 * 
 */
export class ResponsibleApi extends runtime.BaseAPI {

    /**
     * Criar um responsável
     */
    async createResponsibleRaw(requestParameters: CreateResponsibleRequest): Promise<runtime.ApiResponse<GetResponsibleDto>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling createResponsible.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling createResponsible.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling createResponsible.');
        }

        if (requestParameters.isActive === null || requestParameters.isActive === undefined) {
            throw new runtime.RequiredError('isActive','Required parameter requestParameters.isActive was null or undefined when calling createResponsible.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling createResponsible.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        if (requestParameters.isActive !== undefined) {
            formParams.append('is_active', requestParameters.isActive as any);
        }

        if (requestParameters.phone !== undefined) {
            formParams.append('phone', requestParameters.phone as any);
        }

        const response = await this.request({
            path: `/v1/responsible`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponsibleDtoFromJSON(jsonValue));
    }

    /**
     * Criar um responsável
     */
    async createResponsible(requestParameters: CreateResponsibleRequest): Promise<GetResponsibleDto> {
        const response = await this.createResponsibleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Editar responsável
     */
    async editResponsibleRaw(requestParameters: EditResponsibleRequest): Promise<runtime.ApiResponse<GetResponsibleDto>> {
        if (requestParameters.responsibleId === null || requestParameters.responsibleId === undefined) {
            throw new runtime.RequiredError('responsibleId','Required parameter requestParameters.responsibleId was null or undefined when calling editResponsible.');
        }

        if (requestParameters.updateResponsibleDto === null || requestParameters.updateResponsibleDto === undefined) {
            throw new runtime.RequiredError('updateResponsibleDto','Required parameter requestParameters.updateResponsibleDto was null or undefined when calling editResponsible.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/responsible/{responsibleId}`.replace(`{${"responsibleId"}}`, encodeURIComponent(String(requestParameters.responsibleId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateResponsibleDtoToJSON(requestParameters.updateResponsibleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponsibleDtoFromJSON(jsonValue));
    }

    /**
     * Editar responsável
     */
    async editResponsible(requestParameters: EditResponsibleRequest): Promise<GetResponsibleDto> {
        const response = await this.editResponsibleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter responsável pelo ID
     */
    async getResponsibleRaw(requestParameters: GetResponsibleRequest): Promise<runtime.ApiResponse<GetResponsibleDto>> {
        if (requestParameters.responsibleId === null || requestParameters.responsibleId === undefined) {
            throw new runtime.RequiredError('responsibleId','Required parameter requestParameters.responsibleId was null or undefined when calling getResponsible.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/responsible/{responsibleId}`.replace(`{${"responsibleId"}}`, encodeURIComponent(String(requestParameters.responsibleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponsibleDtoFromJSON(jsonValue));
    }

    /**
     * Obter responsável pelo ID
     */
    async getResponsible(requestParameters: GetResponsibleRequest): Promise<GetResponsibleDto> {
        const response = await this.getResponsibleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar responsáveis
     */
    async listResponsibleRaw(requestParameters: ListResponsibleRequest): Promise<runtime.ApiResponse<Array<GetResponsibleDto>>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['is_active'] = requestParameters.isActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/responsible/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetResponsibleDtoFromJSON));
    }

    /**
     * Listar responsáveis
     */
    async listResponsible(requestParameters: ListResponsibleRequest): Promise<Array<GetResponsibleDto>> {
        const response = await this.listResponsibleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Editar imagem do responsável
     */
    async uploadResponsibleImageRaw(requestParameters: UploadResponsibleImageRequest): Promise<runtime.ApiResponse<GetResponsibleDto>> {
        if (requestParameters.responsibleId === null || requestParameters.responsibleId === undefined) {
            throw new runtime.RequiredError('responsibleId','Required parameter requestParameters.responsibleId was null or undefined when calling uploadResponsibleImage.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadResponsibleImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/v1/responsible/{responsibleId}/upload`.replace(`{${"responsibleId"}}`, encodeURIComponent(String(requestParameters.responsibleId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponsibleDtoFromJSON(jsonValue));
    }

    /**
     * Editar imagem do responsável
     */
    async uploadResponsibleImage(requestParameters: UploadResponsibleImageRequest): Promise<GetResponsibleDto> {
        const response = await this.uploadResponsibleImageRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CreateResponsibleTypeEnum {
    Commercial = 'commercial',
    Support = 'support'
}
/**
    * @export
    * @enum {string}
    */
export enum ListResponsibleTypeEnum {
    Commercial = 'commercial',
    Support = 'support'
}
