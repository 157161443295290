import * as React from 'react';

import { Input, InputProps } from '@chakra-ui/react';

import IntlCurrencyInput from 'react-intl-currency-input';

const currencyConfig = {
	locale: 'pt-BR',
	formats: {
		number: {
			BRL: {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
		},
	},
};

type CurrencyInputProps = InputProps & {
	onAmountChange?: (e: any, value: any, maskedValue: any) => void;
	id: string;
};

const CurrencyInput: React.FC<CurrencyInputProps> = (props: CurrencyInputProps) => {
	return (
		<IntlCurrencyInput
			{...props}
			onChange={(e, value, maskedValue) => props.onAmountChange && props.onAmountChange(e, value, maskedValue)}
			component={Input}
			currency='BRL'
			config={currencyConfig}
		/>
	);
};

export default CurrencyInput;
