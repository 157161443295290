import React from 'react';

import { Flex, Box } from '@chakra-ui/react';

import Modal from 'containers/Modal';

import Button from '../../../components/Button';
import Text from '../../../components/Text';

type DeleteCredentialModalProps = {
	openDeleteCredentialModal: boolean;
	isLoading: boolean;
	cancelDelete: () => void;
	deleteCredential: () => void;
};

const DeleteCredentialModal: React.FC<DeleteCredentialModalProps> = ({
	openDeleteCredentialModal,
	isLoading,
	cancelDelete,
	deleteCredential,
}) => (
	<Modal isOpen={openDeleteCredentialModal}>
		<Box borderRadius='md'>
			<Flex py={4} px={12} flexDirection='column'>
				<Text id='delete-credential-text' align='center' color='darkGrey' fontSize='md' fontWeight='bold'>
					Excluir Credencial?
				</Text>

				<Button
					id='confirm-delete-credential-button'
					my='4'
					bgColor='primary'
					color='white'
					borderColor='black'
					isFullWidth
					onClick={async () => deleteCredential()}
					isLoading={isLoading}
					className='button__confirm-delete-credential'
				>
					Confirmar
				</Button>

				<Button id='cancel-delete-button' bgColor='red.400' color='white' borderColor='black' isFullWidth onClick={cancelDelete}>
					Cancelar
				</Button>
			</Flex>
		</Box>
	</Modal>
);

export default DeleteCredentialModal;
