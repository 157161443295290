/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BarCodeDTO
 */
export interface BarCodeDTO {
    /**
     * 
     * @type {number}
     * @memberof BarCodeDTO
     */
    type: number;
    /**
     * 
     * @type {string}
     * @memberof BarCodeDTO
     */
    digitable?: string;
    /**
     * 
     * @type {string}
     * @memberof BarCodeDTO
     */
    barCode?: string;
}

export function BarCodeDTOFromJSON(json: any): BarCodeDTO {
    return BarCodeDTOFromJSONTyped(json, false);
}

export function BarCodeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BarCodeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'digitable': !exists(json, 'digitable') ? undefined : json['digitable'],
        'barCode': !exists(json, 'barCode') ? undefined : json['barCode'],
    };
}

export function BarCodeDTOToJSON(value?: BarCodeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'digitable': value.digitable,
        'barCode': value.barCode,
    };
}


