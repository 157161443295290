/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountBankAccount,
    DiscountBankAccountFromJSON,
    DiscountBankAccountFromJSONTyped,
    DiscountBankAccountToJSON,
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettlementBatch
 */
export interface SettlementBatch {
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    id?: string;
    /**
     * 
     * @type {Seller}
     * @memberof SettlementBatch
     */
    seller?: Seller;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    seller_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    seller_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    seller_document?: string;
    /**
     * 
     * @type {Merchant}
     * @memberof SettlementBatch
     */
    merchant?: Merchant;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    merchant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    status: SettlementBatchStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof SettlementBatch
     */
    settlement_day: Date;
    /**
     * 
     * @type {Date}
     * @memberof SettlementBatch
     */
    settlement_date: Date;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    type: SettlementBatchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    discount_bank_account_id: string;
    /**
     * 
     * @type {DiscountBankAccount}
     * @memberof SettlementBatch
     */
    discount_bank_account?: DiscountBankAccount;
    /**
     * 
     * @type {number}
     * @memberof SettlementBatch
     */
    total_spread?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementBatch
     */
    total: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    cnab_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    cnab_filename?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettlementBatch
     */
    is_retry: boolean;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementBatch
     */
    updated_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettlementBatch
     */
    registered_omie?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum SettlementBatchStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}/**
* @export
* @enum {string}
*/
export enum SettlementBatchTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}

export function SettlementBatchFromJSON(json: any): SettlementBatch {
    return SettlementBatchFromJSONTyped(json, false);
}

export function SettlementBatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementBatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'seller': !exists(json, 'seller') ? undefined : SellerFromJSON(json['seller']),
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
        'seller_name': !exists(json, 'seller_name') ? undefined : json['seller_name'],
        'seller_document': !exists(json, 'seller_document') ? undefined : json['seller_document'],
        'merchant': !exists(json, 'merchant') ? undefined : MerchantFromJSON(json['merchant']),
        'merchant_id': !exists(json, 'merchant_id') ? undefined : json['merchant_id'],
        'status': json['status'],
        'settlement_day': (new Date(json['settlement_day'])),
        'settlement_date': (new Date(json['settlement_date'])),
        'type': json['type'],
        'discount_bank_account_id': json['discount_bank_account_id'],
        'discount_bank_account': !exists(json, 'discount_bank_account') ? undefined : DiscountBankAccountFromJSON(json['discount_bank_account']),
        'total_spread': !exists(json, 'total_spread') ? undefined : json['total_spread'],
        'total': json['total'],
        'cnab_id': !exists(json, 'cnab_id') ? undefined : json['cnab_id'],
        'cnab_filename': !exists(json, 'cnab_filename') ? undefined : json['cnab_filename'],
        'is_retry': json['is_retry'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'registered_omie': !exists(json, 'registered_omie') ? undefined : json['registered_omie'],
    };
}

export function SettlementBatchToJSON(value?: SettlementBatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'seller': SellerToJSON(value.seller),
        'seller_id': value.seller_id,
        'seller_name': value.seller_name,
        'seller_document': value.seller_document,
        'merchant': MerchantToJSON(value.merchant),
        'merchant_id': value.merchant_id,
        'status': value.status,
        'settlement_day': (value.settlement_day.toISOString()),
        'settlement_date': (value.settlement_date.toISOString()),
        'type': value.type,
        'discount_bank_account_id': value.discount_bank_account_id,
        'discount_bank_account': DiscountBankAccountToJSON(value.discount_bank_account),
        'total_spread': value.total_spread,
        'total': value.total,
        'cnab_id': value.cnab_id,
        'cnab_filename': value.cnab_filename,
        'is_retry': value.is_retry,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'registered_omie': value.registered_omie,
    };
}


