import React from 'react';

import { Flex, Text, Box } from '@chakra-ui/react';

import Modal from 'containers/Modal';

import Button from '../../../components/Button';

type PosModalProps = {
	openConfirmPaymentModal: boolean;
	handlePayment(): void;
	setOpenConfirmPaymentModal(open: boolean): void;
	resetForm(): void;
	isLoading: boolean;
};

const PosModal: React.FC<PosModalProps> = ({
	openConfirmPaymentModal,
	handlePayment,
	setOpenConfirmPaymentModal,
	resetForm,
	isLoading,
}) => (
	<Modal isOpen={openConfirmPaymentModal}>
		<Box borderRadius='md'>
			<Flex py={4} px={12} flexDirection='column'>
				<Text id='billet-comfirmation-text' align='center' color='darkGrey' fontSize='md' fontWeight='bold'>
					Confirmação de emissão do boleto
				</Text>

				<Button
					id='confirm-payment-button'
					my='4'
					bgColor='primary'
					color='white'
					borderColor='black'
					isFullWidth
					onClick={async () => {
						await handlePayment();
						setOpenConfirmPaymentModal(!openConfirmPaymentModal);
						resetForm();
					}}
					isLoading={isLoading}
					className='button__confirm-generate-billet'
				>
					Confirmar
				</Button>

				<Button
					id='cancel-payment-button'
					bgColor='red.400'
					color='white'
					borderColor='black'
					isFullWidth
					onClick={() => {
						setOpenConfirmPaymentModal(!openConfirmPaymentModal);
					}}
				>
					Cancelar
				</Button>
			</Flex>
		</Box>
	</Modal>
);

export default PosModal;
