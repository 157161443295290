import React from 'react';
import NewModal from '../../../containers/NewModal';
import { Flex, Box, Heading } from '@chakra-ui/react';
import Button from '../../../components/Button';
import FormControl from '../../../components/Form/FormControl';
import FormLabel from '../../../components/Form/FormLabel';
import { Formik } from 'formik';
import yup from '../../../services/yup.service';
import { getApiAuthConfig } from '../../../services/api.service';
import { PosPrefixApi, CreatePosPrefixDto, GetPosPrefixDto } from '../../../clients';
import { useToasts } from 'react-toast-notifications';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';

type PosPrefixProps = {
	open: boolean;
	handleOpenPosPrefixModal: () => void;
	isLoading: boolean;
	setIsLoading: (loading: boolean) => void;
	prefixes: GetPosPrefixDto[];
};

const PosPrefixModal: React.FC<PosPrefixProps> = ({ open, handleOpenPosPrefixModal, isLoading, setIsLoading, prefixes }) => {
	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const posPrefixApi = new PosPrefixApi(apiConfig);

	const initialValues = {
		prefix: '',
	};

	const validationPosPrefixSchema = yup.object().shape({
		prefix: yup.string().required('Campo Obrigatório'),
	});

	const handleFormSubmit = async (values) => {
		setIsLoading(true);

		const { prefix } = values;

		const parsedPrefix = prefix.split('-').length > 1 ? prefix : `${prefix}-`;

		try {
			const createPosPrefixRequest: CreatePosPrefixDto = {
				prefix: parsedPrefix,
			};

			await posPrefixApi.createPosPrefix({ createPosPrefixDto: createPosPrefixRequest });

			prefixes.push({id: prefix, created_at: new Date().toISOString(), prefix: parsedPrefix});
		} catch (error) {
			addToast('Erro ao criar prefixo.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			handleOpenPosPrefixModal();
			addToast('Prefixo criado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		}

		setIsLoading(false);
	};

	return (
		<NewModal
			isOpen={open}
			size='lg'
			onClose={() => {
				handleOpenPosPrefixModal();
			}}
		>
			<Box borderRadius='md' padding={6}>
				<Flex px={4} py={4} flexDirection='column' gridGap={10}>
					<Flex justify='center'>
						<Heading fontSize='lg' mb={4} color='primary'>
							Criar Prefixo
						</Heading>
					</Flex>

					{prefixes && (
						<Flex justify='center'>
							<Heading fontSize='md' color='primary'>
							Prefixos Disponíveis
						</Heading>
						</Flex>
					)}

					<Flex flexDirection='row' flexWrap='wrap' gridGap={5} justify='center'>
						{prefixes &&
							prefixes.map((posPrefix, index) => (
								<Flex
									borderRadius='5px'
									borderWidth='3px'
									fontSize='12px'
									justify='center'
									align='center'
									bgColor='grey.700'
									fontWeight='bold'
									w='100px'
									h='50px'
									color='primary'
									key={index}
								>
									{posPrefix.prefix}
								</Flex>
							))}
					</Flex>

					<Formik initialValues={initialValues} onSubmit={handleFormSubmit} validationSchema={validationPosPrefixSchema}>
						{({ handleSubmit }) => {
							return (
								<form onSubmit={handleSubmit}>
									<FormControl isRequired>
										<FormLabel id='create-pos-prefix-form-label'>Prefixo</FormLabel>
										<FormInput id='create-pos-prefix-form-input' name='prefix' size={`md`} />
										<FormErrorMessage id='create-pos-prefix-form-error-message' name='prefix' />
									</FormControl>

									<Flex justifyContent='center' mt='4'>
										<Button id='create-pos-prefix-submit-button' size='lg' type='submit' bgColor='primary' isLoading={isLoading}>
											Criar Prefixo
										</Button>
									</Flex>
								</form>
							);
						}}
					</Formik>
				</Flex>
			</Box>
		</NewModal>
	);
};

export default PosPrefixModal;
