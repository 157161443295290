import React from 'react';
import { FormControl, SimpleGrid, FormLabel, GridItem, Input } from '@chakra-ui/react';

import { subYears } from 'date-fns';

import FormCpfInput from 'components/Form/FormCpfInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormInput from 'components/Form/FormInput';

import FormDatePickerInput from '../../../components/Form/FormDatePickerInput';
import FormSelect from '../../../components/Form/FormSelect';

type Props = {
	errors: any;
};

const OwnerForm: React.FC<Props> = (props) => (
	<SimpleGrid columns={{ base: 1, md: 6, xl: 12 }} color='gray.700' gap={4}>
		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 4, xl: 6 }}>
			<FormControl id='owner_name' isRequired>
				<FormLabel id='owner-name-label' fontSize='md' fontWeight='bold'>
					Nome
				</FormLabel>
				<FormInput id='owner-name-form-input' as={Input} name='owner_name' />
				<FormErrorMessage id='owner-name-form-error-text' name='owner_name' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 3 }}>
			<FormControl id='owner_document'>
				<FormLabel id='owner-document-label' fontSize='md' fontWeight='bold'>
					CPF
				</FormLabel>
				<FormCpfInput id='owner-document-form-cpf-input' borderColor={`darkGrey`} name='owner_document' />
				<FormErrorMessage id='owner-document-form-error-text' name='owner_document' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 3 }}>
			<FormControl>
				<FormLabel id='owner-birth-date-label' fontSize='md' fontWeight='bold'>
					Data de Nascimento
				</FormLabel>
				<FormDatePickerInput
					id='owner-birth-date-picker-input'
					name='owner_birth'
					placeholder='Informe a data'
					maxDate={subYears(new Date(), 18)}
					minDate={subYears(new Date(), 100)}
					borderColor='darkGrey'
				/>
				<FormErrorMessage id='owner-birth-date-form-error-text' name='owner_birth' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }}>
			<FormControl id='owner_document_ofac'>
				<FormLabel id='owner-document-ofac-label' fontSize='md' fontWeight='bold'>
					CPF OFAC
				</FormLabel>
				<FormSelect id='owner-document-ofac-form-select' name='owner_document_ofac'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage id='owner-document-ofac-form-error-text' name='owner_document_ofac' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }}>
			<FormControl id='owner_document_onu'>
				<FormLabel id='owner-document-onu-label' fontSize='md' fontWeight='bold'>
					CPF ONU
				</FormLabel>
				<FormSelect id='owner-document-onu-form-select' name='owner_document_onu'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage id='owner-document-onu-form-error-text' name='owner_document_onu' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }}>
			<FormControl id='owner_document_pep'>
				<FormLabel id='owner-document-pep-label' fontSize='md' fontWeight='bold'>
					CPF PEP
				</FormLabel>
				<FormSelect id='owner-document-pep-form-select' name='owner_document_pep'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage id='owner-document-pep-form-error-text' name='owner_document_pep' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }}>
			<FormControl id='owner_document_ref'>
				<FormLabel id='owner-document-ref-label' fontSize='md' fontWeight='bold'>
					CPF REF
				</FormLabel>
				<FormSelect id='owner-document-ref-form-select' name='owner_document_ref'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage id='owner-document-ref-form-error-text' name='owner_document_ref' />
			</FormControl>
		</GridItem>
	</SimpleGrid>
);

export default OwnerForm;
