import React, { ReactElement } from 'react';
import { GetOrdersStatusEnum } from '../../../clients';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';

interface StatusInfo {
	icon: ReactElement;
	text: string;
}

export const statusText: Record<GetOrdersStatusEnum, StatusInfo> = {
	[GetOrdersStatusEnum.Sent]: {
		icon: <AiFillCheckCircle color='LimeGreen' size={16} />,
		text: 'Enviado',
	},
	[GetOrdersStatusEnum.Pending]: {
		icon: <AiFillClockCircle color='orange' size={16} />,
		text: 'Pendente',
	},
	[GetOrdersStatusEnum.NotSent]: {
		icon: <AiFillCloseCircle color='tomato' size={16} />,
		text: 'Não Enviado',
	},
};
