/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateUserDto,
    CreateUserDtoFromJSON,
    CreateUserDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    UpdateUserDto,
    UpdateUserDtoFromJSON,
    UpdateUserDtoToJSON,
} from '../models';

export interface CreateBackofficeUserRequest {
    createUserDto: CreateUserDto;
}

export interface ListUsersRequest {
    limit: number;
    currentPage: number;
    type: ListUsersTypeEnum;
    sort?: ListUsersSortEnum;
}

export interface UpdateBackofficeUserRequest {
    userId: string;
    updateUserDto: UpdateUserDto;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Criar usuário de backoffice
     */
    async createBackofficeUserRaw(requestParameters: CreateBackofficeUserRequest): Promise<runtime.ApiResponse<GetUserDto>> {
        if (requestParameters.createUserDto === null || requestParameters.createUserDto === undefined) {
            throw new runtime.RequiredError('createUserDto','Required parameter requestParameters.createUserDto was null or undefined when calling createBackofficeUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserDtoToJSON(requestParameters.createUserDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserDtoFromJSON(jsonValue));
    }

    /**
     * Criar usuário de backoffice
     */
    async createBackofficeUser(requestParameters: CreateBackofficeUserRequest): Promise<GetUserDto> {
        const response = await this.createBackofficeUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os usuários de todos os estabelecimentos
     */
    async listUsersRaw(requestParameters: ListUsersRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listUsers.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listUsers.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling listUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar os usuários de todos os estabelecimentos
     */
    async listUsers(requestParameters: ListUsersRequest): Promise<PaginatedDto> {
        const response = await this.listUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar um usuário de backoffice
     */
    async updateBackofficeUserRaw(requestParameters: UpdateBackofficeUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateBackofficeUser.');
        }

        if (requestParameters.updateUserDto === null || requestParameters.updateUserDto === undefined) {
            throw new runtime.RequiredError('updateUserDto','Required parameter requestParameters.updateUserDto was null or undefined when calling updateBackofficeUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserDtoToJSON(requestParameters.updateUserDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualizar um usuário de backoffice
     */
    async updateBackofficeUser(requestParameters: UpdateBackofficeUserRequest): Promise<void> {
        await this.updateBackofficeUserRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListUsersTypeEnum {
    Merchant = 'merchant',
    Seller = 'seller',
    Backoffice = 'backoffice'
}
/**
    * @export
    * @enum {string}
    */
export enum ListUsersSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
