import React from 'react';
import { useHistory } from 'react-router-dom';
import { CellContent, CellContentProps, HeaderTitle, HeaderTitleProps, SimpleTable } from '../../../containers/SimpleTable';
import { formatToBrazilianTimezone, maskCpfOrCnpj, maskMoney } from '../../../services/masks.service';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold' w='100%'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages }) => {
	const history = useHistory();
	const columns = React.useMemo(() => {
		let cols: any = [];

		return cols.concat([
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sale-id-header-title-styled' column={column}>
						ID da Venda
					</HeaderTitleStyled>
				),
				accessor: 'sale_id',
				id: 'sale_id',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContentStyled id={`${index}-${id}-cell`} textAlign={`center`}>
						{value}
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='barcode-header-title-styled' column={column}>
						Código de Barras
					</HeaderTitleStyled>
				),
				accessor: 'barcode',
				id: 'barcode',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContentStyled id={`${index}-${id}-cell`} textAlign={`center`}>
						{value}
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='seller-name-header-title-styled' column={column}>
						Cartório
					</HeaderTitleStyled>
				),
				accessor: 'seller_name',
				id: 'seller_name',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContentStyled id={`${index}-${id}-cell`} textAlign={`center`}>
						{value}
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sale-description-header-title-styled' column={column}>
						Descrição
					</HeaderTitleStyled>
				),
				accessor: 'sale_description',
				id: 'sale_description',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContentStyled id={`${index}-${id}-cell`} textAlign={`center`}>
						{value}
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sale-original-amount-header-title-styled' column={column}>
						Valor
					</HeaderTitleStyled>
				),
				accessor: 'sale_original_amount',
				id: 'sale_original_amount',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{`R$${maskMoney(value / 100)}`}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='payer-name-header-title-styled' column={column}>
						Nome Pagador
					</HeaderTitleStyled>
				),
				accessor: 'payer_name',
				id: 'payer_name',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='payer-document-header-title-styled' column={column}>
						Documento Pagador
					</HeaderTitleStyled>
				),
				accessor: 'payer_document',
				id: 'payer_document',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{maskCpfOrCnpj(value)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='expiress-at-header-title-styled' column={column}>
						Data Expiração
					</HeaderTitleStyled>
				),
				accessor: 'expires_at',
				id: 'expires_at',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{formatToBrazilianTimezone(value)}</CellContentStyled>,
			},
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};
