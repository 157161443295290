/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateBilletDto,
    CreateBilletDtoFromJSON,
    CreateBilletDtoToJSON,
    CreateBilletHybridDto,
    CreateBilletHybridDtoFromJSON,
    CreateBilletHybridDtoToJSON,
    CreateBilletInternalDto,
    CreateBilletInternalDtoFromJSON,
    CreateBilletInternalDtoToJSON,
    GetBilletDto,
    GetBilletDtoFromJSON,
    GetBilletDtoToJSON,
    GetBilletUrlDto,
    GetBilletUrlDtoFromJSON,
    GetBilletUrlDtoToJSON,
    GetBilletV3Dto,
    GetBilletV3DtoFromJSON,
    GetBilletV3DtoToJSON,
    GetOneBilletDto,
    GetOneBilletDtoFromJSON,
    GetOneBilletDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface CreateBilletHybridRequest {
    sellerId: string;
    createBilletHybridDto: Array<CreateBilletHybridDto>;
}

export interface CreateBilletHybridV3Request {
    sellerId: string;
    createBilletHybridDto: Array<CreateBilletHybridDto>;
}

export interface CreateBilletPaymentRequest {
    sellerId: string;
    createBilletDto: CreateBilletDto;
}

export interface CreateBilletPaymentInternalRequest {
    sellerId: string;
    createBilletInternalDto: Array<CreateBilletInternalDto>;
}

export interface CreateBilletPaymentV2Request {
    sellerId: string;
    createBilletDto: Array<CreateBilletDto>;
}

export interface CreateBilletPaymentV3Request {
    sellerId: string;
    createBilletDto: Array<CreateBilletDto>;
}

export interface GetBilletUrlRequest {
    billetId: string;
}

export interface GetDdaByBilletRequest {
    limit: number;
    currentPage: number;
    key: GetDdaByBilletKeyEnum;
    value: string;
    provider?: GetDdaByBilletProviderEnum;
}

export interface ListOneBilletRequest {
    id: string;
}

export interface PayBilletRequest {
    billetId: string;
}

export interface WriteOffBilletRequest {
    sellerId: string;
    billetId: string;
}

export interface WriteOffBilletsRequest {
    sellerId: string;
    requestBody: Array<string>;
}

/**
 * 
 */
export class BilletsApi extends runtime.BaseAPI {

    /**
     * Criar novo lote de boletos hibridos
     */
    async createBilletHybridRaw(requestParameters: CreateBilletHybridRequest): Promise<runtime.ApiResponse<Array<GetBilletDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createBilletHybrid.');
        }

        if (requestParameters.createBilletHybridDto === null || requestParameters.createBilletHybridDto === undefined) {
            throw new runtime.RequiredError('createBilletHybridDto','Required parameter requestParameters.createBilletHybridDto was null or undefined when calling createBilletHybrid.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/billets/{sellerId}/hybrid`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createBilletHybridDto.map(CreateBilletHybridDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBilletDtoFromJSON));
    }

    /**
     * Criar novo lote de boletos hibridos
     */
    async createBilletHybrid(requestParameters: CreateBilletHybridRequest): Promise<Array<GetBilletDto>> {
        const response = await this.createBilletHybridRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo lote de boletos hibridos
     */
    async createBilletHybridV3Raw(requestParameters: CreateBilletHybridV3Request): Promise<runtime.ApiResponse<Array<GetBilletV3Dto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createBilletHybridV3.');
        }

        if (requestParameters.createBilletHybridDto === null || requestParameters.createBilletHybridDto === undefined) {
            throw new runtime.RequiredError('createBilletHybridDto','Required parameter requestParameters.createBilletHybridDto was null or undefined when calling createBilletHybridV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/billets/{sellerId}/hybrid`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createBilletHybridDto.map(CreateBilletHybridDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBilletV3DtoFromJSON));
    }

    /**
     * Criar novo lote de boletos hibridos
     */
    async createBilletHybridV3(requestParameters: CreateBilletHybridV3Request): Promise<Array<GetBilletV3Dto>> {
        const response = await this.createBilletHybridV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo pagamento de boleto
     */
    async createBilletPaymentRaw(requestParameters: CreateBilletPaymentRequest): Promise<runtime.ApiResponse<GetBilletDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createBilletPayment.');
        }

        if (requestParameters.createBilletDto === null || requestParameters.createBilletDto === undefined) {
            throw new runtime.RequiredError('createBilletDto','Required parameter requestParameters.createBilletDto was null or undefined when calling createBilletPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBilletDtoToJSON(requestParameters.createBilletDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBilletDtoFromJSON(jsonValue));
    }

    /**
     * Criar novo pagamento de boleto
     */
    async createBilletPayment(requestParameters: CreateBilletPaymentRequest): Promise<GetBilletDto> {
        const response = await this.createBilletPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo lote de pagamento de boleto (PEX)
     */
    async createBilletPaymentInternalRaw(requestParameters: CreateBilletPaymentInternalRequest): Promise<runtime.ApiResponse<Array<GetBilletDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createBilletPaymentInternal.');
        }

        if (requestParameters.createBilletInternalDto === null || requestParameters.createBilletInternalDto === undefined) {
            throw new runtime.RequiredError('createBilletInternalDto','Required parameter requestParameters.createBilletInternalDto was null or undefined when calling createBilletPaymentInternal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/billets/{sellerId}/internal`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createBilletInternalDto.map(CreateBilletInternalDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBilletDtoFromJSON));
    }

    /**
     * Criar novo lote de pagamento de boleto (PEX)
     */
    async createBilletPaymentInternal(requestParameters: CreateBilletPaymentInternalRequest): Promise<Array<GetBilletDto>> {
        const response = await this.createBilletPaymentInternalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo lote de pagamento de boleto
     */
    async createBilletPaymentV2Raw(requestParameters: CreateBilletPaymentV2Request): Promise<runtime.ApiResponse<Array<GetBilletDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createBilletPaymentV2.');
        }

        if (requestParameters.createBilletDto === null || requestParameters.createBilletDto === undefined) {
            throw new runtime.RequiredError('createBilletDto','Required parameter requestParameters.createBilletDto was null or undefined when calling createBilletPaymentV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/billets/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createBilletDto.map(CreateBilletDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBilletDtoFromJSON));
    }

    /**
     * Criar novo lote de pagamento de boleto
     */
    async createBilletPaymentV2(requestParameters: CreateBilletPaymentV2Request): Promise<Array<GetBilletDto>> {
        const response = await this.createBilletPaymentV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo lote de pagamento de boleto
     */
    async createBilletPaymentV3Raw(requestParameters: CreateBilletPaymentV3Request): Promise<runtime.ApiResponse<Array<GetBilletV3Dto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createBilletPaymentV3.');
        }

        if (requestParameters.createBilletDto === null || requestParameters.createBilletDto === undefined) {
            throw new runtime.RequiredError('createBilletDto','Required parameter requestParameters.createBilletDto was null or undefined when calling createBilletPaymentV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/billets/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createBilletDto.map(CreateBilletDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBilletV3DtoFromJSON));
    }

    /**
     * Criar novo lote de pagamento de boleto
     */
    async createBilletPaymentV3(requestParameters: CreateBilletPaymentV3Request): Promise<Array<GetBilletV3Dto>> {
        const response = await this.createBilletPaymentV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * Retorna a URL do boleto
     */
    async getBilletUrlRaw(requestParameters: GetBilletUrlRequest): Promise<runtime.ApiResponse<GetBilletUrlDto>> {
        if (requestParameters.billetId === null || requestParameters.billetId === undefined) {
            throw new runtime.RequiredError('billetId','Required parameter requestParameters.billetId was null or undefined when calling getBilletUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/{billetId}/url`.replace(`{${"billetId"}}`, encodeURIComponent(String(requestParameters.billetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBilletUrlDtoFromJSON(jsonValue));
    }

    /**
     * Retorna a URL do boleto
     */
    async getBilletUrl(requestParameters: GetBilletUrlRequest): Promise<GetBilletUrlDto> {
        const response = await this.getBilletUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * Encontrar DDA pelo Boleto.
     */
    async getDdaByBilletRaw(requestParameters: GetDdaByBilletRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getDdaByBillet.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getDdaByBillet.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getDdaByBillet.');
        }

        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value','Required parameter requestParameters.value was null or undefined when calling getDdaByBillet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        if (requestParameters.value !== undefined) {
            queryParameters['value'] = requestParameters.value;
        }

        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/billets/get-dda`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Encontrar DDA pelo Boleto.
     */
    async getDdaByBillet(requestParameters: GetDdaByBilletRequest): Promise<PaginatedDto> {
        const response = await this.getDdaByBilletRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lista um boleto com status
     */
    async listOneBilletRaw(requestParameters: ListOneBilletRequest): Promise<runtime.ApiResponse<GetOneBilletDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listOneBillet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOneBilletDtoFromJSON(jsonValue));
    }

    /**
     * Lista um boleto com status
     */
    async listOneBillet(requestParameters: ListOneBilletRequest): Promise<GetOneBilletDto> {
        const response = await this.listOneBilletRaw(requestParameters);
        return await response.value();
    }

    /**
     * Pagar boleto (em ambiente de desenvolvimento)
     */
    async payBilletRaw(requestParameters: PayBilletRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billetId === null || requestParameters.billetId === undefined) {
            throw new runtime.RequiredError('billetId','Required parameter requestParameters.billetId was null or undefined when calling payBillet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/{billetId}/pay`.replace(`{${"billetId"}}`, encodeURIComponent(String(requestParameters.billetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Pagar boleto (em ambiente de desenvolvimento)
     */
    async payBillet(requestParameters: PayBilletRequest): Promise<void> {
        await this.payBilletRaw(requestParameters);
    }

    /**
     * Dar baixa do boleto
     */
    async writeOffBilletRaw(requestParameters: WriteOffBilletRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling writeOffBillet.');
        }

        if (requestParameters.billetId === null || requestParameters.billetId === undefined) {
            throw new runtime.RequiredError('billetId','Required parameter requestParameters.billetId was null or undefined when calling writeOffBillet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/billets/{sellerId}/{billetId}/void`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"billetId"}}`, encodeURIComponent(String(requestParameters.billetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Dar baixa do boleto
     */
    async writeOffBillet(requestParameters: WriteOffBilletRequest): Promise<void> {
        await this.writeOffBilletRaw(requestParameters);
    }

    /**
     * Dar baixa em boletos
     */
    async writeOffBilletsRaw(requestParameters: WriteOffBilletsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling writeOffBillets.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling writeOffBillets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/billets/{sellerId}/void`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Dar baixa em boletos
     */
    async writeOffBillets(requestParameters: WriteOffBilletsRequest): Promise<void> {
        await this.writeOffBilletsRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetDdaByBilletKeyEnum {
    Barcode = 'barcode',
    PayerDocument = 'payer_document',
    PayerName = 'payer_name',
    SaleAcquirerReference = 'sale_acquirer_reference'
}
/**
    * @export
    * @enum {string}
    */
export enum GetDdaByBilletProviderEnum {
    Bradesco = 'bradesco',
    Itau = 'itau'
}
