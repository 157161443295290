/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMerchantPermissionDto
 */
export interface CreateMerchantPermissionDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMerchantPermissionDto
     */
    denied_fields: Array<string>;
}

export function CreateMerchantPermissionDtoFromJSON(json: any): CreateMerchantPermissionDto {
    return CreateMerchantPermissionDtoFromJSONTyped(json, false);
}

export function CreateMerchantPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMerchantPermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denied_fields': json['denied_fields'],
    };
}

export function CreateMerchantPermissionDtoToJSON(value?: CreateMerchantPermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denied_fields': value.denied_fields,
    };
}


