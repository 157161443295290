/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponsibleGoalDto
 */
export interface ResponsibleGoalDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsibleGoalDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsibleGoalDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsibleGoalDto
     */
    type: ResponsibleGoalDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ResponsibleGoalDto
     */
    goal: number;
}

/**
* @export
* @enum {string}
*/
export enum ResponsibleGoalDtoTypeEnum {
    Commercial = 'commercial',
    Support = 'support'
}

export function ResponsibleGoalDtoFromJSON(json: any): ResponsibleGoalDto {
    return ResponsibleGoalDtoFromJSONTyped(json, false);
}

export function ResponsibleGoalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponsibleGoalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'goal': json['goal'],
    };
}

export function ResponsibleGoalDtoToJSON(value?: ResponsibleGoalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'goal': value.goal,
    };
}


