import React from 'react';

import { Flex, Heading, Box, Spacer } from '@chakra-ui/react';

import Modal from '../../../containers/Modal';
import Button from '../../../components/Button';

type BilletModalProps = {
	confirmPayment: boolean;
	url: string;
	setConfirmPayment(open: boolean): void;
	resetForm(): void;
	isLoading: boolean;
	setLinkPayed(payed: boolean): void;
};

const BilletModal: React.FC<BilletModalProps> = ({ confirmPayment, url, setConfirmPayment, resetForm, isLoading, setLinkPayed }) => (
	<Modal isOpen={confirmPayment}>
		<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
			<Flex p={8} flexDirection='column'>
				<Heading fontSize='lg' paddingBottom={8}>
					Boleto gerado com sucesso!
				</Heading>

				<Flex flexDirection='row' alignItems='center' fontSize='md' px={3} pb={2}>
					<Spacer />

					<Button
						id='download-button'
						size='lg'
						color='white'
						isFullWidth
						onClick={async () => {
							window.open(url, '_blank');
							setConfirmPayment(!confirmPayment);
							resetForm();
							setLinkPayed(true);
						}}
						isLoading={isLoading}
					>
						Download
					</Button>
				</Flex>
			</Flex>
		</Box>
	</Modal>
);

export default BilletModal;
