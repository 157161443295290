import React from 'react';

import AdyenCheckout from '@adyen/adyen-web';

import '@adyen/adyen-web/dist/adyen.css';
import { useToasts } from 'react-toast-notifications';

import { PaymentsApi, PaymentDetailsRequest } from 'clients';

import { Spinner } from '@chakra-ui/react';

import { getApiDefaultConfig } from '../../../services/api.service';

const threeDSConfiguration = {
	size: '02',
};

type PaymentThreeDSProps = {
	action: any;
	setPaymentSecure(secure: boolean): void;
	setLinkPayed(secure: boolean): void;
	sellerID: string;
	saleID: string;
};

const PaymentThreeDS: React.FC<PaymentThreeDSProps> = ({ action, setPaymentSecure, setLinkPayed, sellerID, saleID }) => {
	const { addToast } = useToasts();

	const [challenger, setChallenger] = React.useState(action);
	const [isLoading, setIsLoading] = React.useState(true);

	async function handleOnAdditionalDetails(state, component) {
		try {
			if (!isLoading) setIsLoading(true);

			const apiConfig = getApiDefaultConfig();
			const paymentsApi = new PaymentsApi(apiConfig);

			const createPaymentRequest: PaymentDetailsRequest = {
				saleId: saleID,
				sellerId: sellerID,
				paymentDetailsDTO: { data: state.data, PaRes: '', MD: '', PaReq: '' },
			};

			const response = await paymentsApi.paymentDetails(createPaymentRequest);

			if (response.resultCode === 'Authorised') {
				setPaymentSecure(false);
				setIsLoading(!isLoading);
				addToast('Pagamento realizado com sucesso!', {
					appearance: 'success',
					autoDismiss: true,
				});
				setLinkPayed(true);
				return;
			}

			if (state.data.details['threeds2.fingerprint']) {
				setIsLoading(!isLoading);
				setChallenger(response);
				return;
			}

			if (response.resultCode === 'Refused') {
				setPaymentSecure(false);
				addToast('Falha no pagamento! Verifique os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		} catch (err) {
			setPaymentSecure(false);
			addToast('Falha no pagamento! Verifique os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	}

	const configuration = {
		locale: 'pt_BR',
		environment: process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_ENVIRONMENT,
		clientKey: process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_CLIENT_KEY,
		onAdditionalDetails: handleOnAdditionalDetails,
	};

	React.useEffect(() => {
		const checkout = new AdyenCheckout(configuration);
		checkout.createFromAction(challenger, threeDSConfiguration).mount('#three-checkout');
	}, [challenger, configuration]);

	return (
		<>
			<div id='three-checkout' style={{ display: isLoading ? 'none' : 'block' }} />
			<Spinner
				thickness='5px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='lg'
				p={2}
				mb={2}
				display={isLoading ? 'block' : 'none'}
			/>
		</>
	);
};

export default PaymentThreeDS;
