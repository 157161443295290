/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateWebHookLinkDto,
    CreateWebHookLinkDtoFromJSON,
    CreateWebHookLinkDtoToJSON,
    CreateWebHookLinkResponseDto,
    CreateWebHookLinkResponseDtoFromJSON,
    CreateWebHookLinkResponseDtoToJSON,
    ListWebHookLinkResponseDto,
    ListWebHookLinkResponseDtoFromJSON,
    ListWebHookLinkResponseDtoToJSON,
} from '../models';

export interface CreateWebHookSellerLinkRequest {
    sellerId: string;
    createWebHookLinkDto: CreateWebHookLinkDto;
}

export interface DeleteWebHookUrlRequest {
    urlId: string;
    sellerId: string;
}

export interface ListUrlsBySellerRequest {
    sellerId: string;
}

/**
 * 
 */
export class WebHooksApi extends runtime.BaseAPI {

    /**
     * Criar um link de webhook para um seller
     */
    async createWebHookSellerLinkRaw(requestParameters: CreateWebHookSellerLinkRequest): Promise<runtime.ApiResponse<CreateWebHookLinkResponseDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createWebHookSellerLink.');
        }

        if (requestParameters.createWebHookLinkDto === null || requestParameters.createWebHookLinkDto === undefined) {
            throw new runtime.RequiredError('createWebHookLinkDto','Required parameter requestParameters.createWebHookLinkDto was null or undefined when calling createWebHookSellerLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/webhooks`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWebHookLinkDtoToJSON(requestParameters.createWebHookLinkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateWebHookLinkResponseDtoFromJSON(jsonValue));
    }

    /**
     * Criar um link de webhook para um seller
     */
    async createWebHookSellerLink(requestParameters: CreateWebHookSellerLinkRequest): Promise<CreateWebHookLinkResponseDto> {
        const response = await this.createWebHookSellerLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletar url específica
     */
    async deleteWebHookUrlRaw(requestParameters: DeleteWebHookUrlRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.urlId === null || requestParameters.urlId === undefined) {
            throw new runtime.RequiredError('urlId','Required parameter requestParameters.urlId was null or undefined when calling deleteWebHookUrl.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling deleteWebHookUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/webhooks/{urlId}`.replace(`{${"urlId"}}`, encodeURIComponent(String(requestParameters.urlId))).replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletar url específica
     */
    async deleteWebHookUrl(requestParameters: DeleteWebHookUrlRequest): Promise<void> {
        await this.deleteWebHookUrlRaw(requestParameters);
    }

    /**
     * Listar urls por seller
     */
    async listUrlsBySellerRaw(requestParameters: ListUrlsBySellerRequest): Promise<runtime.ApiResponse<Array<ListWebHookLinkResponseDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listUrlsBySeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/webhooks`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListWebHookLinkResponseDtoFromJSON));
    }

    /**
     * Listar urls por seller
     */
    async listUrlsBySeller(requestParameters: ListUrlsBySellerRequest): Promise<Array<ListWebHookLinkResponseDto>> {
        const response = await this.listUrlsBySellerRaw(requestParameters);
        return await response.value();
    }

}
