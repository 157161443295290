/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    role_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    status?: UpdateUserDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    email?: string;
}

/**
* @export
* @enum {string}
*/
export enum UpdateUserDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending'
}

export function UpdateUserDtoFromJSON(json: any): UpdateUserDto {
    return UpdateUserDtoFromJSONTyped(json, false);
}

export function UpdateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'role_id': !exists(json, 'role_id') ? undefined : json['role_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function UpdateUserDtoToJSON(value?: UpdateUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'role_id': value.role_id,
        'status': value.status,
        'email': value.email,
    };
}


