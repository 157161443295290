/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListByTypeDto
 */
export interface ListByTypeDto {
    /**
     * 
     * @type {string}
     * @memberof ListByTypeDto
     */
    type: ListByTypeDtoTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ListByTypeDtoTypeEnum {
    Merchant = 'merchant',
    Seller = 'seller',
    Backoffice = 'backoffice'
}

export function ListByTypeDtoFromJSON(json: any): ListByTypeDto {
    return ListByTypeDtoFromJSONTyped(json, false);
}

export function ListByTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListByTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
    };
}

export function ListByTypeDtoToJSON(value?: ListByTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}


