import React from 'react';
import { Flex, HStack } from '@chakra-ui/react';

import FileUploadInput from '../../../containers/FileUploadInput';

import Button from '../../../components/Button';
import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormLabel from '../../../components/Form/FormLabel';
import Text from '../../../components/Text';

type UploadImageFormProps = {
	file: File | null;
	setFile: React.Dispatch<React.SetStateAction<File | null>>;
	isLoading: boolean;
};

export const UploadImageForm = (props: UploadImageFormProps) => {
	return (
		<HStack spacing={4} mb={4} alignItems='flex-end'>
			<Flex alignItems='center' border='1px solid lightgray' borderRadius='md' justifyContent='center' p={2}>
				<FileUploadInput
					id='file-upload-input'
					containerProps={{ m: 0 }}
					inputProps={{
						onChange: (e) => {
							if (e.target.files?.length) {
								props.setFile(e.target.files[0]);
							}
						},
						accept: 'image/png, image/jpg, image/jpeg',
					}}
				/>
				<Text id='file-name-text' mx={2} whiteSpace='nowrap'>
					{props.file ? props.file.name : 'Selecione uma imagem.'}
				</Text>
			</Flex>

			<Flex>
				<FormControl id='action_link'>
					<FormLabel id='action-link-label' fontSize='md' fontWeight='bold'>
						Link de Ação
					</FormLabel>
					<FormInput id='action-link-form-input' name='action_link' />
					<FormErrorMessage id='action-link-form-error-text' name='action_link' />
				</FormControl>
			</Flex>
			<Flex>
				<Button id='submit-upload-image-button' isDisabled={!props.file} isLoading={props.isLoading} type='submit'>
					Enviar
				</Button>
			</Flex>
		</HStack>
	);
};
