/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetThemeDto
 */
export interface GetThemeDto {
    /**
     * 
     * @type {string}
     * @memberof GetThemeDto
     */
    primary_color: string;
    /**
     * 
     * @type {string}
     * @memberof GetThemeDto
     */
    secondary_color: string;
    /**
     * 
     * @type {string}
     * @memberof GetThemeDto
     */
    default_color: string;
    /**
     * 
     * @type {string}
     * @memberof GetThemeDto
     */
    text_color: string;
    /**
     * 
     * @type {string}
     * @memberof GetThemeDto
     */
    menu_text_color: string;
}

export function GetThemeDtoFromJSON(json: any): GetThemeDto {
    return GetThemeDtoFromJSONTyped(json, false);
}

export function GetThemeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetThemeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primary_color': json['primary_color'],
        'secondary_color': json['secondary_color'],
        'default_color': json['default_color'],
        'text_color': json['text_color'],
        'menu_text_color': json['menu_text_color'],
    };
}

export function GetThemeDtoToJSON(value?: GetThemeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary_color': value.primary_color,
        'secondary_color': value.secondary_color,
        'default_color': value.default_color,
        'text_color': value.text_color,
        'menu_text_color': value.menu_text_color,
    };
}


