/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CancelPaymentDTO,
    CancelPaymentDTOFromJSON,
    CancelPaymentDTOToJSON,
    CancelPaymentResponseDTO,
    CancelPaymentResponseDTOFromJSON,
    CancelPaymentResponseDTOToJSON,
    CapturePaymentDto,
    CapturePaymentDtoFromJSON,
    CapturePaymentDtoToJSON,
    CreateDebitPaymentResponseDTO,
    CreateDebitPaymentResponseDTOFromJSON,
    CreateDebitPaymentResponseDTOToJSON,
    CreatePaymentDto,
    CreatePaymentDtoFromJSON,
    CreatePaymentDtoToJSON,
    CreatePaymentResponseDTO,
    CreatePaymentResponseDTOFromJSON,
    CreatePaymentResponseDTOToJSON,
    CreatePaymentV3Dto,
    CreatePaymentV3DtoFromJSON,
    CreatePaymentV3DtoToJSON,
    CreatePaymentWithSplitDto,
    CreatePaymentWithSplitDtoFromJSON,
    CreatePaymentWithSplitDtoToJSON,
    CreatePixPaymentDto,
    CreatePixPaymentDtoFromJSON,
    CreatePixPaymentDtoToJSON,
    CreatePixPaymentResponseDTO,
    CreatePixPaymentResponseDTOFromJSON,
    CreatePixPaymentResponseDTOToJSON,
    CreatePixPaymentWithSplitDto,
    CreatePixPaymentWithSplitDtoFromJSON,
    CreatePixPaymentWithSplitDtoToJSON,
    CreatePixPaymentWithSplitInternalDto,
    CreatePixPaymentWithSplitInternalDtoFromJSON,
    CreatePixPaymentWithSplitInternalDtoToJSON,
    CreatePixPaymentWithSplitResponseDTO,
    CreatePixPaymentWithSplitResponseDTOFromJSON,
    CreatePixPaymentWithSplitResponseDTOToJSON,
    CreatePosPixPaymentDto,
    CreatePosPixPaymentDtoFromJSON,
    CreatePosPixPaymentDtoToJSON,
    GetSaleDto,
    GetSaleDtoFromJSON,
    GetSaleDtoToJSON,
    PaymentDetailsDTO,
    PaymentDetailsDTOFromJSON,
    PaymentDetailsDTOToJSON,
} from '../models';

export interface CapturePaymentRequest {
    sellerId: string;
    saleId: string;
    capturePaymentDto: CapturePaymentDto;
}

export interface CreatePaymentRequest {
    sellerId: string;
    createPaymentDto: CreatePaymentDto;
}

export interface CreatePaymentV3Request {
    sellerId: string;
    createPaymentV3Dto: CreatePaymentV3Dto;
}

export interface CreatePaymentWithSplitRequest {
    sellerId: string;
    createPaymentWithSplitDto: CreatePaymentWithSplitDto;
}

export interface CreatePaymentWithSplitInternalRequest {
    sellerId: string;
    createPaymentWithSplitDto: CreatePaymentWithSplitDto;
}

export interface CreatePixPaymentRequest {
    sellerId: string;
    createPixPaymentDto: CreatePixPaymentDto;
}

export interface CreatePixPaymentInternalWithSplitsRequest {
    sellerId: string;
    createPixPaymentWithSplitInternalDto: CreatePixPaymentWithSplitInternalDto;
}

export interface CreatePixPaymentWithSplitsRequest {
    sellerId: string;
    createPixPaymentWithSplitDto: CreatePixPaymentWithSplitDto;
}

export interface CreatePosPixPaymentRequest {
    clientId: string;
    createPosPixPaymentDto: CreatePosPixPaymentDto;
}

export interface CreatePosPixPaymentByTerminalIdRequest {
    terminalId: string;
    createPosPixPaymentDto: CreatePosPixPaymentDto;
}

export interface PaymentDetailsRequest {
    sellerId: string;
    saleId: string;
    paymentDetailsDTO: PaymentDetailsDTO;
}

export interface PaymentsControllerCancelPaymentRequest {
    sellerId: string;
    saleId: string;
    cancelPaymentDTO: CancelPaymentDTO;
}

export interface PaymentsControllerV3CancelPaymentRequest {
    terminalId: string;
    saleId: string;
    cancelPaymentDTO: CancelPaymentDTO;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Capturar pagamento
     */
    async capturePaymentRaw(requestParameters: CapturePaymentRequest): Promise<runtime.ApiResponse<GetSaleDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling capturePayment.');
        }

        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling capturePayment.');
        }

        if (requestParameters.capturePaymentDto === null || requestParameters.capturePaymentDto === undefined) {
            throw new runtime.RequiredError('capturePaymentDto','Required parameter requestParameters.capturePaymentDto was null or undefined when calling capturePayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payments/sellers/{seller_id}/sales/{sale_id}/capture`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"sale_id"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CapturePaymentDtoToJSON(requestParameters.capturePaymentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleDtoFromJSON(jsonValue));
    }

    /**
     * Capturar pagamento
     */
    async capturePayment(requestParameters: CapturePaymentRequest): Promise<GetSaleDto> {
        const response = await this.capturePaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo pagamento
     */
    async createPaymentRaw(requestParameters: CreatePaymentRequest): Promise<runtime.ApiResponse<CreatePaymentResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createPayment.');
        }

        if (requestParameters.createPaymentDto === null || requestParameters.createPaymentDto === undefined) {
            throw new runtime.RequiredError('createPaymentDto','Required parameter requestParameters.createPaymentDto was null or undefined when calling createPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payments/sellers/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentDtoToJSON(requestParameters.createPaymentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar novo pagamento
     */
    async createPayment(requestParameters: CreatePaymentRequest): Promise<CreatePaymentResponseDTO> {
        const response = await this.createPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo pagamento
     */
    async createPaymentV3Raw(requestParameters: CreatePaymentV3Request): Promise<runtime.ApiResponse<CreatePaymentResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createPaymentV3.');
        }

        if (requestParameters.createPaymentV3Dto === null || requestParameters.createPaymentV3Dto === undefined) {
            throw new runtime.RequiredError('createPaymentV3Dto','Required parameter requestParameters.createPaymentV3Dto was null or undefined when calling createPaymentV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/payments/sellers/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentV3DtoToJSON(requestParameters.createPaymentV3Dto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar novo pagamento
     */
    async createPaymentV3(requestParameters: CreatePaymentV3Request): Promise<CreatePaymentResponseDTO> {
        const response = await this.createPaymentV3Raw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo pagamento online
     */
    async createPaymentWithSplitRaw(requestParameters: CreatePaymentWithSplitRequest): Promise<runtime.ApiResponse<CreatePaymentResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createPaymentWithSplit.');
        }

        if (requestParameters.createPaymentWithSplitDto === null || requestParameters.createPaymentWithSplitDto === undefined) {
            throw new runtime.RequiredError('createPaymentWithSplitDto','Required parameter requestParameters.createPaymentWithSplitDto was null or undefined when calling createPaymentWithSplit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/payments/sellers/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentWithSplitDtoToJSON(requestParameters.createPaymentWithSplitDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar novo pagamento online
     */
    async createPaymentWithSplit(requestParameters: CreatePaymentWithSplitRequest): Promise<CreatePaymentResponseDTO> {
        const response = await this.createPaymentWithSplitRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar novo pagamento online
     */
    async createPaymentWithSplitInternalRaw(requestParameters: CreatePaymentWithSplitInternalRequest): Promise<runtime.ApiResponse<CreatePaymentResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createPaymentWithSplitInternal.');
        }

        if (requestParameters.createPaymentWithSplitDto === null || requestParameters.createPaymentWithSplitDto === undefined) {
            throw new runtime.RequiredError('createPaymentWithSplitDto','Required parameter requestParameters.createPaymentWithSplitDto was null or undefined when calling createPaymentWithSplitInternal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/payments/sellers/{seller_id}/internal`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentWithSplitDtoToJSON(requestParameters.createPaymentWithSplitDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar novo pagamento online
     */
    async createPaymentWithSplitInternal(requestParameters: CreatePaymentWithSplitInternalRequest): Promise<CreatePaymentResponseDTO> {
        const response = await this.createPaymentWithSplitInternalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar um pagamento usando Pix
     */
    async createPixPaymentRaw(requestParameters: CreatePixPaymentRequest): Promise<runtime.ApiResponse<CreatePixPaymentResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createPixPayment.');
        }

        if (requestParameters.createPixPaymentDto === null || requestParameters.createPixPaymentDto === undefined) {
            throw new runtime.RequiredError('createPixPaymentDto','Required parameter requestParameters.createPixPaymentDto was null or undefined when calling createPixPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payments/sellers/{seller_id}/pix`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePixPaymentDtoToJSON(requestParameters.createPixPaymentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePixPaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar um pagamento usando Pix
     */
    async createPixPayment(requestParameters: CreatePixPaymentRequest): Promise<CreatePixPaymentResponseDTO> {
        const response = await this.createPixPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar um pagamento usando Pix
     */
    async createPixPaymentInternalWithSplitsRaw(requestParameters: CreatePixPaymentInternalWithSplitsRequest): Promise<runtime.ApiResponse<CreatePixPaymentWithSplitResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createPixPaymentInternalWithSplits.');
        }

        if (requestParameters.createPixPaymentWithSplitInternalDto === null || requestParameters.createPixPaymentWithSplitInternalDto === undefined) {
            throw new runtime.RequiredError('createPixPaymentWithSplitInternalDto','Required parameter requestParameters.createPixPaymentWithSplitInternalDto was null or undefined when calling createPixPaymentInternalWithSplits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/payments/sellers/{seller_id}/pix/internal`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePixPaymentWithSplitInternalDtoToJSON(requestParameters.createPixPaymentWithSplitInternalDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePixPaymentWithSplitResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar um pagamento usando Pix
     */
    async createPixPaymentInternalWithSplits(requestParameters: CreatePixPaymentInternalWithSplitsRequest): Promise<CreatePixPaymentWithSplitResponseDTO> {
        const response = await this.createPixPaymentInternalWithSplitsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar um pagamento usando Pix
     */
    async createPixPaymentWithSplitsRaw(requestParameters: CreatePixPaymentWithSplitsRequest): Promise<runtime.ApiResponse<CreatePixPaymentWithSplitResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createPixPaymentWithSplits.');
        }

        if (requestParameters.createPixPaymentWithSplitDto === null || requestParameters.createPixPaymentWithSplitDto === undefined) {
            throw new runtime.RequiredError('createPixPaymentWithSplitDto','Required parameter requestParameters.createPixPaymentWithSplitDto was null or undefined when calling createPixPaymentWithSplits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/payments/sellers/{seller_id}/pix`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePixPaymentWithSplitDtoToJSON(requestParameters.createPixPaymentWithSplitDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePixPaymentWithSplitResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar um pagamento usando Pix
     */
    async createPixPaymentWithSplits(requestParameters: CreatePixPaymentWithSplitsRequest): Promise<CreatePixPaymentWithSplitResponseDTO> {
        const response = await this.createPixPaymentWithSplitsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar um pagamento para Pos usando Pix
     */
    async createPosPixPaymentRaw(requestParameters: CreatePosPixPaymentRequest): Promise<runtime.ApiResponse<CreatePixPaymentResponseDTO>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling createPosPixPayment.');
        }

        if (requestParameters.createPosPixPaymentDto === null || requestParameters.createPosPixPaymentDto === undefined) {
            throw new runtime.RequiredError('createPosPixPaymentDto','Required parameter requestParameters.createPosPixPaymentDto was null or undefined when calling createPosPixPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/payments/sellers/{client_id}/pix`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePosPixPaymentDtoToJSON(requestParameters.createPosPixPaymentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePixPaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar um pagamento para Pos usando Pix
     */
    async createPosPixPayment(requestParameters: CreatePosPixPaymentRequest): Promise<CreatePixPaymentResponseDTO> {
        const response = await this.createPosPixPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar um pagamento Pix para Pos usando terminal Id
     */
    async createPosPixPaymentByTerminalIdRaw(requestParameters: CreatePosPixPaymentByTerminalIdRequest): Promise<runtime.ApiResponse<CreatePixPaymentResponseDTO>> {
        if (requestParameters.terminalId === null || requestParameters.terminalId === undefined) {
            throw new runtime.RequiredError('terminalId','Required parameter requestParameters.terminalId was null or undefined when calling createPosPixPaymentByTerminalId.');
        }

        if (requestParameters.createPosPixPaymentDto === null || requestParameters.createPosPixPaymentDto === undefined) {
            throw new runtime.RequiredError('createPosPixPaymentDto','Required parameter requestParameters.createPosPixPaymentDto was null or undefined when calling createPosPixPaymentByTerminalId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/payments/sellers/terminal-id/{terminal_id}/pix`.replace(`{${"terminal_id"}}`, encodeURIComponent(String(requestParameters.terminalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePosPixPaymentDtoToJSON(requestParameters.createPosPixPaymentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePixPaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Criar um pagamento Pix para Pos usando terminal Id
     */
    async createPosPixPaymentByTerminalId(requestParameters: CreatePosPixPaymentByTerminalIdRequest): Promise<CreatePixPaymentResponseDTO> {
        const response = await this.createPosPixPaymentByTerminalIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Verificar detalhes de pagamento
     */
    async paymentDetailsRaw(requestParameters: PaymentDetailsRequest): Promise<runtime.ApiResponse<CreateDebitPaymentResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling paymentDetails.');
        }

        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling paymentDetails.');
        }

        if (requestParameters.paymentDetailsDTO === null || requestParameters.paymentDetailsDTO === undefined) {
            throw new runtime.RequiredError('paymentDetailsDTO','Required parameter requestParameters.paymentDetailsDTO was null or undefined when calling paymentDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/payments/sellers/{seller_id}/details/sale/{sale_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"sale_id"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentDetailsDTOToJSON(requestParameters.paymentDetailsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDebitPaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Verificar detalhes de pagamento
     */
    async paymentDetails(requestParameters: PaymentDetailsRequest): Promise<CreateDebitPaymentResponseDTO> {
        const response = await this.paymentDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cancelar um pagamento
     */
    async paymentsControllerCancelPaymentRaw(requestParameters: PaymentsControllerCancelPaymentRequest): Promise<runtime.ApiResponse<CancelPaymentResponseDTO>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling paymentsControllerCancelPayment.');
        }

        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling paymentsControllerCancelPayment.');
        }

        if (requestParameters.cancelPaymentDTO === null || requestParameters.cancelPaymentDTO === undefined) {
            throw new runtime.RequiredError('cancelPaymentDTO','Required parameter requestParameters.cancelPaymentDTO was null or undefined when calling paymentsControllerCancelPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/payments/sellers/{seller_id}/sales/{sale_id}/void`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"sale_id"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelPaymentDTOToJSON(requestParameters.cancelPaymentDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CancelPaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Cancelar um pagamento
     */
    async paymentsControllerCancelPayment(requestParameters: PaymentsControllerCancelPaymentRequest): Promise<CancelPaymentResponseDTO> {
        const response = await this.paymentsControllerCancelPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cancelar um pagamento via pos
     */
    async paymentsControllerV3CancelPaymentRaw(requestParameters: PaymentsControllerV3CancelPaymentRequest): Promise<runtime.ApiResponse<CancelPaymentResponseDTO>> {
        if (requestParameters.terminalId === null || requestParameters.terminalId === undefined) {
            throw new runtime.RequiredError('terminalId','Required parameter requestParameters.terminalId was null or undefined when calling paymentsControllerV3CancelPayment.');
        }

        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling paymentsControllerV3CancelPayment.');
        }

        if (requestParameters.cancelPaymentDTO === null || requestParameters.cancelPaymentDTO === undefined) {
            throw new runtime.RequiredError('cancelPaymentDTO','Required parameter requestParameters.cancelPaymentDTO was null or undefined when calling paymentsControllerV3CancelPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/payments/terminal-id/{terminal_id}/sales/{sale_id}/pos/void`.replace(`{${"terminal_id"}}`, encodeURIComponent(String(requestParameters.terminalId))).replace(`{${"sale_id"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelPaymentDTOToJSON(requestParameters.cancelPaymentDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CancelPaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     * Cancelar um pagamento via pos
     */
    async paymentsControllerV3CancelPayment(requestParameters: PaymentsControllerV3CancelPaymentRequest): Promise<CancelPaymentResponseDTO> {
        const response = await this.paymentsControllerV3CancelPaymentRaw(requestParameters);
        return await response.value();
    }

}
