import React from 'react';
import { Center, FormControl, SimpleGrid, Stack } from '@chakra-ui/react';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';

import { GetAcquirerDto, GetSellerCredentialResponseDto } from 'clients';

import Button from 'components/Button';
import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';
import Text from '../../../components/Text';
import FormLabel from '../../../components/Form/FormLabel';

type CredentialsForm = {
	acquirers: GetAcquirerDto[];
	isLoading?: boolean;
	isValid: boolean;
	editingCredential?: GetSellerCredentialResponseDto;
};

const CredentialsForm: React.FC<CredentialsForm> = ({ acquirers, isLoading, isValid, editingCredential }) => {
	return (
		<SimpleGrid color='gray.700' spacing='2.5rem' marginLeft='1%'>
			<Stack direction={'column'} spacing={2}>
				<FormControl id='api_key'>
					<FormLabel id='credential-api-key-label' fontSize='md' fontWeight='bold'>
						Chave da Api
					</FormLabel>
					<FormInput id='credential-api-key-form-input' name='api_key' />
					<FormErrorMessage id='credential-api-key-form-error-text' name='api_key' />
				</FormControl>

				<FormControl id='client_id' isRequired>
					<FormLabel id='credentials-client-id-label' fontSize='md' fontWeight='bold'>
						Id do Cliente (carteira)
					</FormLabel>
					<FormInput id='credentials-client-id-form-input' name='client_id' />
					<FormErrorMessage id='credentials-client-id-form-error-text' name='client_id' />
				</FormControl>

				<FormControl id='acquirer_code' isRequired>
					<FormLabel id='credential-acquirer-name-label' fontSize='md' fontWeight='bold'>
						Adquirente
					</FormLabel>
					{!!editingCredential ? (
						<Text id='editing-credential-acquirer-name-text'>{editingCredential.acquirer.name}</Text>
					) : (
						<FormSelect id='credential-acquirer-code-form-select' name='acquirer_code'>
							<option value='' />
							{acquirers.map((acquirer) => (
								<option value={acquirer.code} key={acquirer.id}>
									{acquirer.name}
								</option>
							))}
						</FormSelect>
					)}
					<FormErrorMessage id='credential-aquirer-code-form-error-text' name='acquirer_code' />
				</FormControl>
				<Center pt='6' justifyContent='space-around'>
					<Button id='save-credential-button' bgColor='primary' size='md' disabled={!isValid} type='submit' isLoading={isLoading}>
						Salvar Credencial
					</Button>
				</Center>
			</Stack>
		</SimpleGrid>
	);
};

export default CredentialsForm;
