/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserMerchantDto
 */
export interface GetUserMerchantDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserMerchantDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserMerchantDto
     */
    description: string;
}

export function GetUserMerchantDtoFromJSON(json: any): GetUserMerchantDto {
    return GetUserMerchantDtoFromJSONTyped(json, false);
}

export function GetUserMerchantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserMerchantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
    };
}

export function GetUserMerchantDtoToJSON(value?: GetUserMerchantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
    };
}


