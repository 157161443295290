import React from 'react';

import { Box, Stack, Text } from '@chakra-ui/react';
import { AiFillWarning } from 'react-icons/ai';

const BackofficeAlertContainer: React.FC = () => {
	return (
		<Box border='1px solid #EE7D00' p={2} mt={2} bgColor={`white`}>
			<Stack isInline spacing={2} color={`gray.700`} justifyContent={`center`} alignItems={`center`}>
				<AiFillWarning color={`orange`} />
				<Text id='attention-text' align='center' fontWeight={`bold`}>
					Atenção:
				</Text>
				<Text id='warning-select-seller-text' className='warning__attention-warning'>
					Você deve selecionar um estabelecimento para continuar
				</Text>
			</Stack>
		</Box>
	);
};

export default BackofficeAlertContainer;
