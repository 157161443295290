/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSellerProtocolsQueryDto
 */
export interface GetSellerProtocolsQueryDto {
    /**
     * 
     * @type {number}
     * @memberof GetSellerProtocolsQueryDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof GetSellerProtocolsQueryDto
     */
    current_page: number;
    /**
     * 
     * @type {number}
     * @memberof GetSellerProtocolsQueryDto
     */
    number?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSellerProtocolsQueryDto
     */
    status?: Array<GetSellerProtocolsQueryDtoStatusEnum>;
    /**
     * 
     * @type {string}
     * @memberof GetSellerProtocolsQueryDto
     */
    status_date_start?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerProtocolsQueryDto
     */
    status_date_end?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerProtocolsQueryDto
     */
    creation_date_start?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerProtocolsQueryDto
     */
    creation_date_end?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerProtocolsQueryDto
     */
    alert_date_start?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerProtocolsQueryDto
     */
    alert_date_end?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerProtocolsQueryDto
     */
    due_date_start?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerProtocolsQueryDto
     */
    due_date_end?: string;
}

/**
* @export
* @enum {string}
*/
export enum GetSellerProtocolsQueryDtoStatusEnum {
    Pending = 'pending',
    Alert = 'alert',
    Paid = 'paid',
    Overdued = 'overdued',
    Canceled = 'canceled'
}

export function GetSellerProtocolsQueryDtoFromJSON(json: any): GetSellerProtocolsQueryDto {
    return GetSellerProtocolsQueryDtoFromJSONTyped(json, false);
}

export function GetSellerProtocolsQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerProtocolsQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'current_page': json['current_page'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'status_date_start': !exists(json, 'status_date_start') ? undefined : json['status_date_start'],
        'status_date_end': !exists(json, 'status_date_end') ? undefined : json['status_date_end'],
        'creation_date_start': !exists(json, 'creation_date_start') ? undefined : json['creation_date_start'],
        'creation_date_end': !exists(json, 'creation_date_end') ? undefined : json['creation_date_end'],
        'alert_date_start': !exists(json, 'alert_date_start') ? undefined : json['alert_date_start'],
        'alert_date_end': !exists(json, 'alert_date_end') ? undefined : json['alert_date_end'],
        'due_date_start': !exists(json, 'due_date_start') ? undefined : json['due_date_start'],
        'due_date_end': !exists(json, 'due_date_end') ? undefined : json['due_date_end'],
    };
}

export function GetSellerProtocolsQueryDtoToJSON(value?: GetSellerProtocolsQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'current_page': value.current_page,
        'number': value.number,
        'status': value.status,
        'status_date_start': value.status_date_start,
        'status_date_end': value.status_date_end,
        'creation_date_start': value.creation_date_start,
        'creation_date_end': value.creation_date_end,
        'alert_date_start': value.alert_date_start,
        'alert_date_end': value.alert_date_end,
        'due_date_start': value.due_date_start,
        'due_date_end': value.due_date_end,
    };
}


