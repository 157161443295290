import React from 'react';

import { Button } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import { CancelPaymentLinkRequest, GetSellerDto, PaymentLinksApi } from 'clients';
import { getApiAuthConfig } from 'services/api.service';

type CancelButtonProps = {
	id: string;
	seller?: GetSellerDto;
	afterCancel?: () => void;
	paymentLinkId: string;
};

const CancelButton: React.FC<CancelButtonProps> = ({ id, seller, afterCancel, paymentLinkId }) => {
	const [isCancelling, setIsCancelling] = React.useState<boolean>(false);

	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const paymentLinkApi = new PaymentLinksApi(apiConfig);

	const handleCancel = async (id: string) => {
		setIsCancelling(true);

		try {
			const requestParams: CancelPaymentLinkRequest = {
				sellerId: seller?.id!,
				paymentLinkId: id,
			};

			await paymentLinkApi.cancelPaymentLink(requestParams);

			addToast('Link cancelado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});

			afterCancel && afterCancel();
		} catch (err) {
			setIsCancelling(false);

			addToast('Não foi possível cancelar o link de pagamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	return (
		<Button
		  	id={id}
			bgColor={`white`}
			border='1px solid'
			borderColor={`blue.500`}
			color={`blue.500`}
			fontWeight={`medium`}
			px='4'
			height='auto'
			disabled={isCancelling}
			isLoading={isCancelling}
			onClick={() => handleCancel(paymentLinkId)}
		>
			Cancelar
		</Button>
	);
};

export default CancelButton;
