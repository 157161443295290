import React from 'react';

import { Flex, FormControl, FormLabel, Stack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, useBreakpointValue } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-multi-date-picker';
import { parse } from 'date-fns';

import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import Button from 'components/Button';

import { EditSellerPartialRequest, SellersApi } from '../../clients';

import { useCurrentSeller } from '../../contexts/SellerProvider';

import yup from 'services/yup.service';
import { getApiAuthConfig } from '../../services/api.service';
import { formatWithoutTimezone } from '../../services/date.service';
import Text from '../../components/Text';

const ProtocolsCustom: React.FC = () => {
	const { currentSeller, updateCurrentSeller } = useCurrentSeller();
	const { addToast } = useToasts();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [dates, setDates] = React.useState<any>(null);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);

	const protocolCustomInitialValues = {
		alert_before_days: currentSeller?.protocol_customizations?.alert_before_days ?? '',
		default_email_content: currentSeller?.protocol_customizations?.default_email_content ?? '',
		default_business_days_to_due: currentSeller?.protocol_customizations?.default_business_days_to_due ?? '',
	};

	const schema = yup.object().shape({
		alert_before_days: yup.number(),
		default_email_content: yup.string(),
		default_business_days_to_due: yup.number(),
	});

	function setInitialDates() {
		if (currentSeller?.protocol_customizations?.holidays_to_consider) {
			const dates = currentSeller.protocol_customizations.holidays_to_consider.map((date) => {
				return parse(date, 'dd-MM', new Date());
			});

			setDates(dates);
		}
	}

	function formatDates(dates) {
		if (!dates) {
			return null;
		}

		return dates.map((date) => {
			return formatWithoutTimezone(date, 'dd-MM');
		});
	}

	const submitForm = async (values) => {
		if (currentSeller) {
			const requestParams: EditSellerPartialRequest = {
				editSellerPartialDto: {
					protocol_customizations: {
						holidays_to_consider: formatDates(dates),
						alert_before_days: values.alert_before_days,
						default_email_content: values.default_email_content,
						default_business_days_to_due: values.default_business_days_to_due,
					},
				},
				sellerId: currentSeller.id,
			};

			try {
				setIsSubmitting(true);

				const seller = await sellersApi.editSellerPartial(requestParams);

				updateCurrentSeller(seller);

				addToast('Personalização salva com sucesso', {
					appearance: 'success',
					autoDismiss: true,
				});

				setIsSubmitting(false);
			} catch (e) {
				addToast('Não foi possível salvar a personalização.', {
					appearance: 'error',
					autoDismiss: true,
				});

				setIsSubmitting(false);
			}
		}
	};

	React.useEffect(() => {
		setInitialDates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex flexDirection={`column`} w='100%'>
			<Flex pb='8'>
				<Breadcrumb separator={<ChevronRightIcon />} color={`darkGrey`}>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Gestão de Protocolos
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' fontSize='md' fontWeight={`semibold`}>
							Personalização de Protocolos
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<Formik
				enableReinitialize
				initialValues={protocolCustomInitialValues}
				validationSchema={schema}
				onSubmit={async (values, { resetForm }) => {
					await submitForm(values);

					resetForm();
				}}
			>
				{({ handleSubmit, setFieldValue, values, isValid }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Flex mb='4'>
								<Text id='cutom-protocols-text' color={`darkGrey`} fontSize={isMobile ? 'lg' : '2xl'} fontWeight={`bold`}>
									PERSONALIZAÇÃO DE PROTOCOLOS
								</Text>
							</Flex>

							<Flex flexDirection={`column`}>
								<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
									<Stack spacing={3} color={`primary`}>
										<Stack direction='column' spacing={4}>
											<Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
												<FormControl>
													<FormLabel id='set-dates-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Feriados Municipais
													</FormLabel>
													<DatePicker
														value={dates}
														onChange={(date) => setDates(date)}
														containerStyle={{ width: '100%' }}
														style={{
															fontSize: '0.925rem',
															width: '100%',
															height: '2.5rem',
															border: '1px solid',
															borderColor: '#4f4f4f',
															borderRadius: '0.5rem',
															paddingLeft: '1rem',
															paddingRight: '1rem',
														}}
														format='DD-MM'
														placeholder='Selecione as datas'
														disableYearPicker
														multiple
													/>
													<FormErrorMessage id='protocols-custom-dates-form-error-text' name='dates' />
												</FormControl>
												<FormControl>
													<FormLabel id='protocol-custom-alert-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Alerta de Vencimento
													</FormLabel>
													<FormInput
														id='protocol-custom-alert-form-input'
														type='number'
														placeholder='Quantidade de dias antes do vencimento'
														name='alert_before_days'
														isDisabled={isSubmitting}
													/>
													<FormErrorMessage id='protocol-custom-alert-before-days-form-error-text' name='alert_before_days' />
												</FormControl>
												<FormControl>
													<FormLabel id='protocol-business-days-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Dias Para o Vencimento
													</FormLabel>
													<FormInput
														id='protocol-default-business-days-to-due-form-input'
														type='number'
														placeholder='Dias úteis para do vencimento'
														name='default_business_days_to_due'
														isDisabled={isSubmitting}
													/>
													<FormErrorMessage
														id='protocol-custom-default-business-day-to-due-form-error-text'
														name='default_business_days_to_due'
													/>
												</FormControl>
											</Stack>
											<Stack>
												<FormControl>
													<FormLabel id='default-email-content-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Corpo do Email
													</FormLabel>
													<ReactQuill
														theme='snow'
														value={values.default_email_content}
														onChange={(value) => {
															setFieldValue('default_email_content', value);
														}}
													/>
													<FormErrorMessage id='protocol-custom-default-email-content-form-error-text' name='default_email_content' />
												</FormControl>
											</Stack>
										</Stack>
									</Stack>
									<FormControl display='flex' justifyContent={isMobile ? 'center' : 'flex-end'} mt='4'>
										<Button
											id='save-customization-button'
											size='lg'
											type='submit'
											disabled={!isValid || isSubmitting}
											bgColor={'primary'}
											isLoading={isSubmitting}
										>
											Salvar Personalização
										</Button>
									</FormControl>
								</Flex>
							</Flex>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default ProtocolsCustom;
