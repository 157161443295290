/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillingAddressDTO,
    BillingAddressDTOFromJSON,
    BillingAddressDTOFromJSONTyped,
    BillingAddressDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShopperDTO
 */
export interface ShopperDTO {
    /**
     * 
     * @type {string}
     * @memberof ShopperDTO
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ShopperDTO
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ShopperDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopperDTO
     */
    phone?: string;
    /**
     * 
     * @type {BillingAddressDTO}
     * @memberof ShopperDTO
     */
    billing_address: BillingAddressDTO;
}

export function ShopperDTOFromJSON(json: any): ShopperDTO {
    return ShopperDTOFromJSONTyped(json, false);
}

export function ShopperDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopperDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'billing_address': BillingAddressDTOFromJSON(json['billing_address']),
    };
}

export function ShopperDTOToJSON(value?: ShopperDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'phone': value.phone,
        'billing_address': BillingAddressDTOToJSON(value.billing_address),
    };
}


