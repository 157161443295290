import * as React from 'react';

import { useAuth } from './AuthProvider';

interface RefreshTokenContextData {}

const RefreshTokenContext = React.createContext<RefreshTokenContextData>({} as RefreshTokenContextData);

type RefreshTokenProviderProps = {
	children: React.ReactElement;	
};

export const RefreshTokenProvider: React.FC<RefreshTokenProviderProps> = ({ children }) => {
	const [ loop, setLoop ] = React.useState<number | undefined>(undefined);
	const [ isLocked, setIsLocked ] = React.useState<boolean>(false);

	const { signed, refreshToken, isTokenExpired } = useAuth();

	React.useEffect(() => {
		if (loop) {
			clearInterval(loop);
		}

		if (! signed) {
			return;
		}
		const loopFunction = window.setInterval(() => {
			if (isTokenExpired() && signed && ! isLocked) {			
				setIsLocked(true);

				refreshToken().then(() => {
					setIsLocked(false);
					console.log('Refresh token updated!');
				});
			}			
		}, 5000);

		setLoop(loopFunction);

		return () => clearInterval(loop);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signed]);

	return (
		<RefreshTokenContext.Provider value={{}}>
			{children}
		</RefreshTokenContext.Provider>
	);
};
