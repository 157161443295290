/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExportSettlementListDto
 */
export interface CreateExportSettlementListDto {
    /**
     * Formato: yyyy-mm-dd
     * @type {string}
     * @memberof CreateExportSettlementListDto
     */
    start_date?: string;
    /**
     * Formato: yyyy-mm-dd
     * @type {string}
     * @memberof CreateExportSettlementListDto
     */
    end_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSettlementListDto
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSettlementListDto
     */
    status?: Array<CreateExportSettlementListDtoStatusEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSettlementListDto
     */
    type?: Array<CreateExportSettlementListDtoTypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSettlementListDto
     */
    extension?: CreateExportSettlementListDtoExtensionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSettlementListDto
     */
    seller_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateExportSettlementListDtoStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSettlementListDtoTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSettlementListDtoExtensionEnum {
    Xls = 'xls',
    Csv = 'csv',
    Json = 'json',
    Pdf = 'pdf'
}

export function CreateExportSettlementListDtoFromJSON(json: any): CreateExportSettlementListDto {
    return CreateExportSettlementListDtoFromJSONTyped(json, false);
}

export function CreateExportSettlementListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExportSettlementListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start_date': !exists(json, 'start_date') ? undefined : json['start_date'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
    };
}

export function CreateExportSettlementListDtoToJSON(value?: CreateExportSettlementListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.start_date,
        'end_date': value.end_date,
        'id': value.id,
        'status': value.status,
        'type': value.type,
        'extension': value.extension,
        'seller_id': value.seller_id,
    };
}


