/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
    CustomerDTO,
    CustomerDTOFromJSON,
    CustomerDTOFromJSONTyped,
    CustomerDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePixPaymentWithSplitDto
 */
export interface CreatePixPaymentWithSplitDto {
    /**
     * Valor em centavos
     * @type {number}
     * @memberof CreatePixPaymentWithSplitDto
     */
    amount_cents: number;
    /**
     * Descrição
     * @type {string}
     * @memberof CreatePixPaymentWithSplitDto
     */
    description: string;
    /**
     * Cliente
     * @type {CustomerDTO}
     * @memberof CreatePixPaymentWithSplitDto
     */
    customer: CustomerDTO;
    /**
     * Há divisão de pagamento
     * @type {boolean}
     * @memberof CreatePixPaymentWithSplitDto
     */
    has_split_rules?: boolean;
    /**
     * Divisão de pagamento
     * @type {Array<CreateSplitRulesDto>}
     * @memberof CreatePixPaymentWithSplitDto
     */
    split_rules?: Array<CreateSplitRulesDto>;
    /**
     * Há confirmação de pagamento
     * @type {boolean}
     * @memberof CreatePixPaymentWithSplitDto
     */
    confirmation_required?: boolean;
    /**
     * Identificação do extrato
     * @type {string}
     * @memberof CreatePixPaymentWithSplitDto
     */
    extract_identification?: string;
    /**
     * Identificador externo
     * @type {string}
     * @memberof CreatePixPaymentWithSplitDto
     */
    service_id?: string;
    /**
     * Número do protocolo
     * @type {string}
     * @memberof CreatePixPaymentWithSplitDto
     */
    protocol?: string;
    /**
     * Data de vencimento do pix
     * @type {string}
     * @memberof CreatePixPaymentWithSplitDto
     */
    delivery_date?: string;
}

export function CreatePixPaymentWithSplitDtoFromJSON(json: any): CreatePixPaymentWithSplitDto {
    return CreatePixPaymentWithSplitDtoFromJSONTyped(json, false);
}

export function CreatePixPaymentWithSplitDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePixPaymentWithSplitDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'description': json['description'],
        'customer': CustomerDTOFromJSON(json['customer']),
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'extract_identification': !exists(json, 'extract_identification') ? undefined : json['extract_identification'],
        'service_id': !exists(json, 'service_id') ? undefined : json['service_id'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'delivery_date': !exists(json, 'delivery_date') ? undefined : json['delivery_date'],
    };
}

export function CreatePixPaymentWithSplitDtoToJSON(value?: CreatePixPaymentWithSplitDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'description': value.description,
        'customer': CustomerDTOToJSON(value.customer),
        'has_split_rules': value.has_split_rules,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
        'confirmation_required': value.confirmation_required,
        'extract_identification': value.extract_identification,
        'service_id': value.service_id,
        'protocol': value.protocol,
        'delivery_date': value.delivery_date,
    };
}


