import React from 'react';

import { Progress, Text } from '@chakra-ui/react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from 'containers/SimpleTable';
import { maskMoney } from 'services/masks.service';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='type-name-title' column={column}>
						Tipo de Venda
					</HeaderTitleStyled>
				),
				accessor: 'type_name',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='financial-volume-title' column={column}>
						Volume Financeiro
					</HeaderTitleStyled>
				),
				accessor: 'financial_volume',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='gross-volume-title' column={column}>
						Valor Bruto
					</HeaderTitleStyled>
				),
				accessor: 'gross_volume',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='number-sales-title' column={column}>
						Núm. de Vendas
					</HeaderTitleStyled>
				),
				accessor: 'number_sales',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='average-title' column={column}>
						Média
					</HeaderTitleStyled>
				),
				accessor: 'average',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='percentage-title' column={column}>
						Porcentagem
					</HeaderTitleStyled>
				),
				accessor: 'percentage',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<div>
						<Progress id={`${index}-${id}-progress-value`} value={value} rounded='md' />{' '}
						{value > 0 && (
							<Text id={`${index}-${id}-progress-text`} textAlign='center'>
								{value}%
							</Text>
						)}
					</div>
				),
			},
		],
		[]
	);

	return <SimpleTable columns={columns} variant='unstyled' data={data} fillParent />;
};
