import React from 'react';

import { SimpleGrid, FormControl, Input, useBreakpointValue } from '@chakra-ui/react';

import FormCpfCnpjInput from 'components/Form/FormCpfCnpjInput';
import FormPhoneInput from 'components/Form/FormPhoneInput';
import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import { requiredFields } from './types/requiredFields.type';
import { useTheme } from 'contexts/ThemeProvider';
import FormLabel from '../../components/Form/FormLabel';

type PayerFormProps = {
	errors: any;
	onSearchResults(obj: any): void;
	setSelectedPayer(param: any): void;
	requiredFields: requiredFields;
	columnsNumber: number;
};

const PayerForm: React.FC<PayerFormProps> = (props: PayerFormProps) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const { theme } = useTheme();

	return (
		<SimpleGrid columns={isMobile ? 1 : props.columnsNumber} spacing='4' w='100%' mt='2'>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.document' isRequired={props.requiredFields.document}>
				<FormLabel id='payer-document-label'  color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					CPF / CNPJ
				</FormLabel>
				<FormCpfCnpjInput
					id='payer-form-document-form-input'
					setSelectedPayer={props.setSelectedPayer}
					isSearchPayer={true}
					onSearchResults={props.onSearchResults}
					name='payer.document'
				/>
				<FormErrorMessage id='payer-document-form-error-text' name='payer.document' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.name' isRequired={props.requiredFields.name}>
				<FormLabel id='payer-name-label' color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`} isTruncated>
					Nome Completo
				</FormLabel>
				<FormInput id='payer-name-form-input' name='payer.name' as={Input} />
				<FormErrorMessage id='payer-name-form-error-text' name='payer.name' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.email' isRequired={props.requiredFields.email}>
				<FormLabel id='payer-email-label' color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					E-mail
				</FormLabel>
				<FormInput id='payer-email-form-input' name='payer.email' as={Input} />
				<FormErrorMessage id='payer-email-form-error-text' name='payer.email' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.phone' isRequired={props.requiredFields.phone}>
				<FormLabel id='payer-phone-label' color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					Telefone
				</FormLabel>
				<FormPhoneInput id='payer-phone-form-phone-input' name='payer.phone' />
				<FormErrorMessage id='payer-phone-form-error-text' name='payer.phone' />
			</FormControl>
		</SimpleGrid>
	);
};

export default PayerForm;
