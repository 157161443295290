import { Flex, Image, Stack, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';

import { Divider } from '@chakra-ui/layout';
import { cardBrandEnum, saleStatus } from 'services/enums.service';

import AmexIcon from '../../../assets/images/cards/amex.svg';
import AuraIcon from '../../../assets/images/cards/aura.svg';
import DinersIcon from '../../../assets/images/cards/diners.svg';
import DiscoverIcon from '../../../assets/images/cards/discover.svg';
import EloIcon from '../../../assets/images/cards/elo.svg';
import HipercardIcon from '../../../assets/images/cards/hipercard.svg';
import JcbIcon from '../../../assets/images/cards/jcb.svg';
import McIcon from '../../../assets/images/cards/mc.svg';
import VisaIcon from '../../../assets/images/cards/visa.svg';
import { GetSaleDto } from '../../../clients';
import Paper from '../../../containers/Paper';
import { maskMoney } from '../../../services/masks.service';
import Steps from './Steps';
import Text from 'components/Text';

type Props = {
	sale: GetSaleDto;
	showBackofficeInfos: Boolean;
};

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	waiting_confirmation: <AiFillClockCircle color='#FFA547' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_capture: <AiFillClockCircle color='#FFA547' size={16} />,
	in_progress_capture: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
	expired: <AiFillCloseCircle color='tomato' size={16} />,
};

const iconCard = {
	visa: VisaIcon,
	elo: EloIcon,
	mc: McIcon,
	amex: AmexIcon,
	hipercard: HipercardIcon,
	aura: AuraIcon,
	diners: DinersIcon,
	discover: DiscoverIcon,
	jcb: JcbIcon,
	mastercard: McIcon,
};

const SaleData: React.FC<Props> = (props) => {
	const { sale, showBackofficeInfos } = props;
	const isMobile = useBreakpointValue({ base: true, lg: false });

	return (
		<Paper id='sale-data-paper-text' title='Dados da venda' p={isMobile ? 6 : 10}>
			<Flex direction='column'>
				<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-around' mb='3'>
					<Flex direction='column' width={isMobile ? '100%' : '50%'} mb={isMobile ? '3' : '0'}>
						<Text id='identifier-text' fontWeight='bold'>
							Identificador
						</Text>
						<Text id='data-sale-id-text'>{sale.id}</Text>
					</Flex>

					<Flex textAlign='start' direction='column' width={isMobile ? '100%' : '50%'}>
						<Text id='description-text' fontWeight='bold'>
							Descrição
						</Text>
						<Text id='data-sale-description-text'>{sale.protocol ? sale.protocol : 'N/A'}</Text>
					</Flex>
				</Flex>
				<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-around' mb='3'>
					<Flex direction='column' width={isMobile ? '100%' : '50%'} mb={isMobile ? '3' : '0'}>
						<Text id='description-text' fontWeight='bold'>
							Nº do pedido
						</Text>
						<Text id='data-description-text'>{sale.description}</Text>
					</Flex>

					<Flex direction='column' width={isMobile ? '100%' : '50%'}>
						<Text id='split-description-text' fontWeight='bold'>
							Descrição da Divisão
						</Text>
						<Text id='data-split-description-text'>{sale.split_description ? sale.split_description : 'N/A'}</Text>
					</Flex>
				</Flex>
				<Flex direction={isMobile ? 'column' : 'row'} justifyContent='start' mb='7'>
				{showBackofficeInfos && (
						<Flex direction='column' width={isMobile ? '100%' : '50%'}>
							<Text id='backoffice-acquirer-reference-text' fontWeight='bold'>
								PSP
							</Text>
							<Text id='data-backoffice-acquirer-reference-text'>{sale.acquirer_reference}</Text>
						</Flex>
				)}
				{sale?.refused_reason && (
					<Flex direction='column' width={isMobile ? '100%' : '50%'}>
						<Text id='refused-reason-description-text' fontWeight='bold'>
							Motivo da Falha
						</Text>
						<Text id='data-refused-reason-text'>{sale.refused_reason}</Text>
					</Flex>
				)}
				</Flex>
			</Flex>
			<Flex
				direction={isMobile ? 'column' : 'row'}
				justifyContent='space-between'
				alignItems={isMobile ? 'flex-start' : 'center'}
				borderWidth={1}
				borderColor='#A8A8A8'
				rounded='md'
				px='5'
				py={isMobile ? '2' : '7'}
				mb='7'
			>
				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text id='sale-celcoin-text' fontWeight='bold'>
						{sale.celcoin ? 'Valor Pago' : 'Valor da Venda'}
					</Text>
					<Text id='sale-original-amount-text' fontWeight='bold'>
						{sale.original_amount ? `R$ ${maskMoney(sale.original_amount / 100)}` : 'N/A'}
					</Text>
				</Stack>

				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text id='sale-additional-value-text' fontWeight='bold'>
						{sale.celcoin ? 'Valor de Acréscimo' : 'Taxa'}
					</Text>
					<Text id='sale-deduction-amount-text' fontWeight='bold'>
						{sale?.original_amount - sale?.amount ? `R$ ${maskMoney((sale?.original_amount - sale?.amount) / 100)}` : 'N/A'}
					</Text>
				</Stack>

				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text id='sale-net-value-text' fontWeight='bold'>
						{sale.celcoin ? 'Valor do Título' : 'Valor Líquido'}
					</Text>
					<Text id='sale-amount-text' fontWeight='bold'>
						{sale.amount ? `R$ ${maskMoney(sale.amount / 100)}` : 'N/A'}
					</Text>
				</Stack>

				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text id='sale-payment-type-text' fontWeight='bold'>
						Tipo de Venda
					</Text>
					<Text id='sale-payment-type-description-text' fontWeight='bold'>
						{sale.payment_type_description ? sale.payment_type_description : 'N/A'}
					</Text>
				</Stack>

				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text id='sale-status-label' fontWeight='bold'>
						Status
					</Text>
					<Flex id='sale-status-value' fontWeight='bold' alignItems='center'>
						<Text id='sale-status-icon' mr='3'>
							{iconStatusLiteral[sale.status]}
						</Text>
						<Text id='sale-status-text'>{saleStatus[sale.status] ?? 'N/A'}</Text>
					</Flex>
				</Stack>
			</Flex>

			{sale.card_number && (
				<>
					<Text id='data-card-text' fontSize='xl' fontWeight='bold' mb={3}>
						Dados do cartão
					</Text>

					<Divider mb='7' />

					<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-between' mb='7'>
						<Flex direction='column'>
							<Text id='card-holder-name-text' fontWeight='bold'>
								Nome no cartão
							</Text>
							<Text id='-data-sale-card-holder-name-text'>{sale.card_holder_name}</Text>
						</Flex>

						<Flex direction='column'>
							<Text id='card-number-text' fontWeight='bold'>
								Número do cartão
							</Text>
							<Text id='data-sale-card-number-text'>{sale?.card_number}</Text>
						</Flex>

						<Flex direction='column'>
							<Text id='sale-card-brand-text' fontWeight='bold'>
								Bandeira
							</Text>
							<Flex direction='row'>
								{sale.card_brand && <Image mr={2} src={iconCard[sale?.card_brand?.toLowerCase()]} />}
								<Text id='data-sale-card-brand-text'>{sale?.card_brand ? cardBrandEnum[sale?.card_brand?.toLowerCase()] : 'N/A'}</Text>
							</Flex>
						</Flex>
					</Flex>
				</>
			)}

			<Text id='history-text' fontSize='xl' fontWeight='bold' mb={3}>
				Histórico
			</Text>

			<Flex width='100%' justifyContent='center'>
				<Flex width={isMobile ? '100%' : '80%'} justifyContent='center'>
					<Steps {...sale} />
				</Flex>
			</Flex>
		</Paper>
	);
};

export default SaleData;
