/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentPosRequest
 */
export interface PaymentPosRequest {
    /**
     * Id da máquina de pagamento
     * @type {string}
     * @memberof PaymentPosRequest
     */
    terminal_id: string;
    /**
     * Valor
     * @type {number}
     * @memberof PaymentPosRequest
     */
    amount: number;
    /**
     * É débito
     * @type {boolean}
     * @memberof PaymentPosRequest
     */
    is_debit: boolean;
    /**
     * Parcelas
     * @type {number}
     * @memberof PaymentPosRequest
     */
    instalments: number;
    /**
     * Identificador de até 10 caracteres alfanumérico
     * @type {string}
     * @memberof PaymentPosRequest
     */
    service_id: string;
    /**
     * É pix
     * @type {boolean}
     * @memberof PaymentPosRequest
     */
    is_pix: boolean;
    /**
     * Descrição
     * @type {string}
     * @memberof PaymentPosRequest
     */
    description?: string;
    /**
     * Documento
     * @type {string}
     * @memberof PaymentPosRequest
     */
    document?: string;
    /**
     * Celcoin ID
     * @type {string}
     * @memberof PaymentPosRequest
     */
    celcoinId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPosRequest
     */
    pre_capture?: boolean;
}

export function PaymentPosRequestFromJSON(json: any): PaymentPosRequest {
    return PaymentPosRequestFromJSONTyped(json, false);
}

export function PaymentPosRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPosRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'terminal_id': json['terminal_id'],
        'amount': json['amount'],
        'is_debit': json['is_debit'],
        'instalments': json['instalments'],
        'service_id': json['service_id'],
        'is_pix': json['is_pix'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'document': !exists(json, 'document') ? undefined : json['document'],
        'celcoinId': !exists(json, 'celcoinId') ? undefined : json['celcoinId'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
    };
}

export function PaymentPosRequestToJSON(value?: PaymentPosRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'terminal_id': value.terminal_id,
        'amount': value.amount,
        'is_debit': value.is_debit,
        'instalments': value.instalments,
        'service_id': value.service_id,
        'is_pix': value.is_pix,
        'description': value.description,
        'document': value.document,
        'celcoinId': value.celcoinId,
        'pre_capture': value.pre_capture,
    };
}


