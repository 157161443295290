/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSettlementBatchDTO
 */
export interface UpdateSettlementBatchDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateSettlementBatchDTO
     */
    status: UpdateSettlementBatchDTOStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateSettlementBatchDTOStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}

export function UpdateSettlementBatchDTOFromJSON(json: any): UpdateSettlementBatchDTO {
    return UpdateSettlementBatchDTOFromJSONTyped(json, false);
}

export function UpdateSettlementBatchDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSettlementBatchDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function UpdateSettlementBatchDTOToJSON(value?: UpdateSettlementBatchDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}


