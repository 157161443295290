/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentMethodTransactionDto
 */
export interface PaymentMethodTransactionDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodTransactionDto
     */
    type: PaymentMethodTransactionDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodTransactionDto
     */
    payer_name: string;
    /**
     * 
     * @type {object}
     * @memberof PaymentMethodTransactionDto
     */
    metadata: object;
}

/**
* @export
* @enum {string}
*/
export enum PaymentMethodTransactionDtoTypeEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    PixPos = 'pix_pos'
}

export function PaymentMethodTransactionDtoFromJSON(json: any): PaymentMethodTransactionDto {
    return PaymentMethodTransactionDtoFromJSONTyped(json, false);
}

export function PaymentMethodTransactionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodTransactionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'payer_name': json['payer_name'],
        'metadata': json['metadata'],
    };
}

export function PaymentMethodTransactionDtoToJSON(value?: PaymentMethodTransactionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'payer_name': value.payer_name,
        'metadata': value.metadata,
    };
}


