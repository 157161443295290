/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Acquirer,
    AcquirerFromJSON,
    AcquirerFromJSONTyped,
    AcquirerToJSON,
    CostPlan,
    CostPlanFromJSON,
    CostPlanFromJSONTyped,
    CostPlanToJSON,
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
    PaymentType,
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
    SettlementPlan,
    SettlementPlanFromJSON,
    SettlementPlanFromJSONTyped,
    SettlementPlanToJSON,
} from './';

/**
 * 
 * @export
 * @interface SalePlan
 */
export interface SalePlan {
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    description: string;
    /**
     * 
     * @type {Array<PaymentType>}
     * @memberof SalePlan
     */
    payment_types?: Array<PaymentType>;
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    type: SalePlanTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    payment_method: SalePlanPaymentMethodEnum;
    /**
     * 
     * @type {Merchant}
     * @memberof SalePlan
     */
    merchant: Merchant;
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    merchant_id?: string;
    /**
     * 
     * @type {Acquirer}
     * @memberof SalePlan
     */
    acquirer: Acquirer;
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    acquirer_id?: string;
    /**
     * 
     * @type {SettlementPlan}
     * @memberof SalePlan
     */
    settlement_plan: SettlementPlan;
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    settlement_plan_id?: string;
    /**
     * 
     * @type {CostPlan}
     * @memberof SalePlan
     */
    cost_plan: CostPlan;
    /**
     * 
     * @type {string}
     * @memberof SalePlan
     */
    cost_plan_id?: string;
    /**
     * 
     * @type {number}
     * @memberof SalePlan
     */
    risk_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SalePlan
     */
    inactive?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum SalePlanTypeEnum {
    Markup = 'markup',
    Spread = 'spread',
    Split = 'split'
}/**
* @export
* @enum {string}
*/
export enum SalePlanPaymentMethodEnum {
    Pos = 'pos',
    Online = 'online'
}

export function SalePlanFromJSON(json: any): SalePlan {
    return SalePlanFromJSONTyped(json, false);
}

export function SalePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalePlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': json['description'],
        'payment_types': !exists(json, 'payment_types') ? undefined : ((json['payment_types'] as Array<any>).map(PaymentTypeFromJSON)),
        'type': json['type'],
        'payment_method': json['payment_method'],
        'merchant': MerchantFromJSON(json['merchant']),
        'merchant_id': !exists(json, 'merchant_id') ? undefined : json['merchant_id'],
        'acquirer': AcquirerFromJSON(json['acquirer']),
        'acquirer_id': !exists(json, 'acquirer_id') ? undefined : json['acquirer_id'],
        'settlement_plan': SettlementPlanFromJSON(json['settlement_plan']),
        'settlement_plan_id': !exists(json, 'settlement_plan_id') ? undefined : json['settlement_plan_id'],
        'cost_plan': CostPlanFromJSON(json['cost_plan']),
        'cost_plan_id': !exists(json, 'cost_plan_id') ? undefined : json['cost_plan_id'],
        'risk_value': !exists(json, 'risk_value') ? undefined : json['risk_value'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
    };
}

export function SalePlanToJSON(value?: SalePlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'payment_types': value.payment_types === undefined ? undefined : ((value.payment_types as Array<any>).map(PaymentTypeToJSON)),
        'type': value.type,
        'payment_method': value.payment_method,
        'merchant': MerchantToJSON(value.merchant),
        'merchant_id': value.merchant_id,
        'acquirer': AcquirerToJSON(value.acquirer),
        'acquirer_id': value.acquirer_id,
        'settlement_plan': SettlementPlanToJSON(value.settlement_plan),
        'settlement_plan_id': value.settlement_plan_id,
        'cost_plan': CostPlanToJSON(value.cost_plan),
        'cost_plan_id': value.cost_plan_id,
        'risk_value': value.risk_value,
        'inactive': value.inactive,
    };
}


