import React from 'react';

import { Flex, Tag, ModalHeader } from '@chakra-ui/react';

import NewModal from 'containers/NewModal';

import { userType, subjectTitle } from 'services/enums.service';

type UserModalProps = {
	openUserPermissionModal: boolean;
	handleOpenUserPermissionModal(role?: any): void;
	userRole?: any;
};

const UserPermissionModal: React.FC<UserModalProps> = ({ openUserPermissionModal, handleOpenUserPermissionModal, userRole }) => {
	return (
		<NewModal
			isOpen={openUserPermissionModal}
			size='lg'
			onClose={() => {
				handleOpenUserPermissionModal();
			}}
			showCloseButton
		>
			<ModalHeader fontSize='lg'>
				Permissões {userType[userRole?.type]} - {userRole?.name}
			</ModalHeader>
			<Flex p='4' direction='row' wrap='wrap'>
				{userRole?.permissions?.map((permission) => (
					<Tag size='md' mr='2' mb='2'>
						{subjectTitle[permission.subject]}
					</Tag>
				))}
			</Flex>
		</NewModal>
	);
};

export default UserPermissionModal;
