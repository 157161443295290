import * as React from 'react';

import useModal from 'react-hooks-use-modal';

import { Container } from '@chakra-ui/react';

type LoaderProps = {
	isOpen: boolean;
	children: React.ReactElement | React.ReactElement[];
	onClose?: () => void;
	maxW?: string;
};

const Modal: React.FC<LoaderProps> = (props: LoaderProps) => {
	const { isOpen, children, ...rest } = props;

	const [Modal, open, close] = useModal('root', {
		preventScroll: true,
	});

	React.useEffect(() => {
		if (isOpen) {
			open();
		} else {
			close();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, open, close]);

	if (!isOpen) {
		return <></>;
	}
	return (
		<Modal>
			<Container bgColor={`#fff`} p={4} centerContent borderWidth='1px' borderRadius='md' {...rest}>
				{children}
			</Container>
		</Modal>
	);
};

export default Modal;
