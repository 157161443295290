import React from 'react';

import {
	Flex,
	Center,
	FormControl,
	BreadcrumbItem,
	BreadcrumbLink,
	Tooltip,
	chakra,
	Wrap,
	WrapItem,
	useBreakpointValue,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { format, lastDayOfMonth } from 'date-fns';
import { useToasts } from 'react-toast-notifications';

import Loader from '../../containers/Loader';
import Paper from '../../containers/Paper';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import SampleTable from './components/SampleTable';
import RangeMonthPicker, { IPeriod } from '../../containers/RangeMonthPicker';
import MultiSelect from '../../components/MultiSelect';
import GoalModal from './components/GoalModal';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';

import { GetResponsibleDto, GoalApi, ListGoalsRequest, ResponsibleApi } from '../../clients';

const Goals: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const goalApi = new GoalApi(apiConfig);
	const responsiblesApi = new ResponsibleApi(apiConfig);

	const { isBackoffice } = useAuth();

	const isMobile = useBreakpointValue({ base: true, lg: false });
	const [isLoading, setIsLoading] = React.useState(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [createReference, setCreateReference] = React.useState(false);
	const [filters, setFilters] = React.useState({});
	const [responsibles, setResponsibles] = React.useState<GetResponsibleDto[]>([]);
	const [responsiblesReference, setResponsiblesReference] = React.useState<GetResponsibleDto[]>([]);
	const [goalIdReference, setGoalIdReference] = React.useState('');

	const [goals, setGoals] = React.useState<any>(null);

	const [period, setPeriod] = React.useState<IPeriod>({
		startDate: new Date(new Date().setHours(0, 0, 0, 0)),
		endDate: new Date(new Date().setHours(23, 59, 59, 999)),
		key: 'selection',
	});

	const { addToast } = useToasts();

	const initialValues = {
		responsibles: [],
	};

	async function fetchListResponsiblesRequest() {
		setIsLoading(true);

		try {
			const response = await responsiblesApi.listResponsible({ isActive: true });

			setResponsibles(response);
		} catch (error) {
			addToast('Erro ao buscar responsáveis', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	async function fetchListGoalsRequest(params) {
		const { referenceDate, responsibles, ...parsedParams } = params;

		setIsLoading(true);

		const fromYear = period.startDate.getFullYear();
		const fromMonth = period.startDate.getMonth();
		const toYear = period.endDate.getFullYear();
		const toMonth = period.endDate.getMonth();
		const monthFirstDay = new Date(fromYear, fromMonth, 1).getDate();
		const monthLastDay = lastDayOfMonth(new Date().setFullYear(toYear, toMonth - 1)).getDate();

		try {
			const requestParams: ListGoalsRequest = {
				startDate: format(new Date().setFullYear(fromYear, fromMonth, monthFirstDay), 'yyyy-MM-dd'),
				endDate: format(new Date().setFullYear(toYear, toMonth, monthLastDay), 'yyyy-MM-dd'),
				responsibles: responsibles?.map((responsible) => responsible.value),
				...parsedParams,
			};

			const response = await goalApi.listGoals(requestParams);

			const parsedGoalList = response.map((goal) => {
				const { responsibles, ...goalInformations } = goal;
				const parsedGoal = {
					...goalInformations,
					subRows: responsibles,
				};

				return parsedGoal;
			});

			setGoals(parsedGoalList);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchListGoalsRequest(filters);
		fetchListResponsiblesRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormSubmit = async (values) => {
		setFilters(values);
		fetchListGoalsRequest(values);
	};

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Flex direction={['column', 'column', 'row']} justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Metas
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>

				<Button
					id='open-create-goal-modal-button'
					title='Criar Meta'
					isDisabled={!isBackoffice}
					bgColor='primary'
					onClick={() => {
						setResponsiblesReference(responsibles);
						setIsModalOpen(true);
						setCreateReference(true);
					}}
					className='button__create-goal'
				>
					Criar Meta
				</Button>
			</Flex>

			<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit, submitForm, setFieldValue, values, resetForm }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Paper id='goals-range-month-picker-paper-text' p={6}>
								<Wrap mb={4}>
									<WrapItem>
										<Center>
											<RangeMonthPicker
												period={period}
												setPeriod={setPeriod}
												setFieldValue={setFieldValue}
												handleSubmit={handleSubmit}
												backgroundColor='primary'
												isLoading={isLoading}
												size='md'
											/>

											<Tooltip label={'Data de Referência'} placement='top' hasArrow>
												<chakra.span>
													<IoInformationCircleOutline size='1.3rem' color='primary' />
												</chakra.span>
											</Tooltip>
										</Center>
									</WrapItem>

									<WrapItem width={isMobile ? '200px' : '12rem'}>
										<Center minW='100%'>
											<FormControl>
												<MultiSelect
													id='responsibles'
													placeholder='Responsáveis'
													options={responsibles.map((responsible) => ({ value: responsible.id, label: responsible.name }))}
													value={values.responsibles}
													onChange={(value) => {
														setFieldValue('responsibles', value);
														handleSubmit();
													}}
												/>
											</FormControl>
										</Center>
									</WrapItem>

									<WrapItem>
										<Center>
											{values.responsibles.length > 0 && (
												<Button
													id='clear-filter-button'
													onClick={() => {
														resetForm();
														handleSubmit();
													}}
													paddingInline='10px'
													_focus={{ outline: 'none' }}
													color='secondary'
													bgColor='transparent'
												>
													Limpar Filtros
												</Button>
											)}
										</Center>
									</WrapItem>
								</Wrap>

								<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
									{goals && (
										<SampleTable
											data={goals}
											setCreateReference={setCreateReference}
											setIsModalOpen={setIsModalOpen}
											setResponsiblesReference={setResponsiblesReference}
											setGoalIdReference={setGoalIdReference}
										/>
									)}
								</Flex>
							</Paper>

							<GoalModal
								isLoading={isLoading}
								setIsLoading={setIsLoading}
								isModalOpen={isModalOpen}
								setIsModalOpen={setIsModalOpen}
								createReference={createReference}
								responsibles={responsiblesReference}
								goalIdReference={goalIdReference}
								setGoalIdReference={setGoalIdReference}
								handleSubmit={handleSubmit}
							/>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default Goals;
