/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ListSettlementSellerDto,
    ListSettlementSellerDtoFromJSON,
    ListSettlementSellerDtoFromJSONTyped,
    ListSettlementSellerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListSettlementResponseDTO
 */
export interface ListSettlementResponseDTO {
    /**
     * Formato: yyyy-mm-dd
     * @type {string}
     * @memberof ListSettlementResponseDTO
     */
    readonly settlement_day: string;
    /**
     * 
     * @type {number}
     * @memberof ListSettlementResponseDTO
     */
    readonly total: number;
    /**
     * 
     * @type {string}
     * @memberof ListSettlementResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ListSettlementResponseDTO
     */
    seller_document: string;
    /**
     * 
     * @type {string}
     * @memberof ListSettlementResponseDTO
     */
    status: ListSettlementResponseDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ListSettlementResponseDTO
     */
    type: ListSettlementResponseDTOTypeEnum;
    /**
     * 
     * @type {ListSettlementSellerDto}
     * @memberof ListSettlementResponseDTO
     */
    seller: ListSettlementSellerDto;
    /**
     * 
     * @type {string}
     * @memberof ListSettlementResponseDTO
     */
    cnab_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListSettlementResponseDTO
     */
    is_retry?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ListSettlementResponseDTOStatusEnum {
    Pending = 'pending',
    Paid = 'paid',
    Sent = 'sent',
    PendingCancel = 'pending_cancel',
    Canceled = 'canceled',
    Failed = 'failed',
    Resent = 'resent'
}/**
* @export
* @enum {string}
*/
export enum ListSettlementResponseDTOTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}

export function ListSettlementResponseDTOFromJSON(json: any): ListSettlementResponseDTO {
    return ListSettlementResponseDTOFromJSONTyped(json, false);
}

export function ListSettlementResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSettlementResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settlement_day': json['settlement_day'],
        'total': json['total'],
        'id': json['id'],
        'seller_document': json['seller_document'],
        'status': json['status'],
        'type': json['type'],
        'seller': ListSettlementSellerDtoFromJSON(json['seller']),
        'cnab_id': !exists(json, 'cnab_id') ? undefined : json['cnab_id'],
        'is_retry': !exists(json, 'is_retry') ? undefined : json['is_retry'],
    };
}

export function ListSettlementResponseDTOToJSON(value?: ListSettlementResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'seller_document': value.seller_document,
        'status': value.status,
        'type': value.type,
        'seller': ListSettlementSellerDtoToJSON(value.seller),
        'cnab_id': value.cnab_id,
        'is_retry': value.is_retry,
    };
}


