import React from 'react';
import { FormControl, SimpleGrid, GridItem, Input } from '@chakra-ui/react';

import FormZipcodeInput from 'components/Form/FormZipcodeInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormSelect from 'components/Form/FormSelect';
import getzipcode from 'services/getzipcode.service';
import FormInput from 'components/Form/FormInput';
import { states } from 'config/constants';
import FormLabel from '../../../components/Form/FormLabel';

type Props = {
	onSearchResults(obj: any): void;
	errors: any;
};

const AddressForm: React.FC<Props> = (props) => (
	<SimpleGrid columns={{ base: 1, md: 6, xl: 12 }} color='gray.700' gap={4}>
		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }}>
			<FormControl id='address.zipcode' isRequired>
				<FormLabel id='address-zipcode-label' fontSize='md' fontWeight='bold'>
					CEP
				</FormLabel>
				<FormZipcodeInput
					id='address-zipcode-form-zipcoe-input'
					name='address.zipcode'
					performSearch={getzipcode}
					onSearchResults={props.onSearchResults}
				/>
				<FormErrorMessage id='address-zipcode-form-error-text' name='address.zipcode' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 3, xl: 4 }}>
			<FormControl id='address.street' isRequired>
				<FormLabel id='address-stree-label' fontSize='md' fontWeight='bold'>
					Logradouro
				</FormLabel>
				<FormInput id='address-street-form-input' as={Input} name='address.street' />
				<FormErrorMessage id='address-street-form-error-text' name='address.street' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 1, xl: 2 }}>
			<FormControl id='address.number' isRequired>
				<FormLabel id='address-number-label' fontSize='md' fontWeight='bold'>
					Número
				</FormLabel>
				<FormInput id='address-number-form-input' as={Input} name='address.number' />
				<FormErrorMessage id='address-number-form-error-text' name='address.number' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 3, xl: 4 }}>
			<FormControl id='address.complement'>
				<FormLabel id='address-complement-label' fontSize='md' fontWeight='bold'>
					Complemento
				</FormLabel>
				<FormInput id='address-complement-form-input' as={Input} name='address.complement' />
				<FormErrorMessage id='address-complement-form-error-text' name='address.complement' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1, xl: 1 }} colSpan={{ base: 4, md: 3, xl: 5 }}>
			<FormControl id='address.district' isRequired>
				<FormLabel id='address-district-label' fontSize={`md`} fontWeight='bold'>
					Bairro
				</FormLabel>
				<FormInput id='address-district-form-input' as={Input} name='address.district' />
				<FormErrorMessage id='address-district-form-error-text' name='address.district' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 3, xl: 5 }}>
			<FormControl id='address.city' isRequired>
				<FormLabel id='address-city-label' fontSize='md' fontWeight='bold'>
					Cidade
				</FormLabel>
				<FormInput id='address-city-form-input' as={Input} name='address.city' />
				<FormErrorMessage id='address-city-form-error-text' name='address.city' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 3, xl: 2 }}>
			<FormControl id='address.state' isRequired>
				<FormLabel id='address-state-label' fontSize='md' fontWeight='bold'>
					UF
				</FormLabel>
				<FormSelect id='address-state-form-select' name='address.state'>
					<option value='' />
					{states.map((state) => (
						<option value={state.value} key={state.value}>
							{state.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='address-state-form-error-text' name='address.state' />
			</FormControl>
		</GridItem>
	</SimpleGrid>
);

export default AddressForm;
