/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelSalePos
 */
export interface CancelSalePos {
    /**
     * Id da máquina de pagamento
     * @type {string}
     * @memberof CancelSalePos
     */
    terminal_id: string;
    /**
     * Motivo do cancelamento
     * @type {string}
     * @memberof CancelSalePos
     */
    abort_reason: string;
    /**
     * 
     * @type {string}
     * @memberof CancelSalePos
     */
    service_id: string;
    /**
     * 
     * @type {string}
     * @memberof CancelSalePos
     */
    cancel_sale_id: string;
    /**
     * 
     * @type {string}
     * @memberof CancelSalePos
     */
    cancel_service_id: string;
}

export function CancelSalePosFromJSON(json: any): CancelSalePos {
    return CancelSalePosFromJSONTyped(json, false);
}

export function CancelSalePosFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelSalePos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'terminal_id': json['terminal_id'],
        'abort_reason': json['abort_reason'],
        'service_id': json['service_id'],
        'cancel_sale_id': json['cancel_sale_id'],
        'cancel_service_id': json['cancel_service_id'],
    };
}

export function CancelSalePosToJSON(value?: CancelSalePos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'terminal_id': value.terminal_id,
        'abort_reason': value.abort_reason,
        'service_id': value.service_id,
        'cancel_sale_id': value.cancel_sale_id,
        'cancel_service_id': value.cancel_service_id,
    };
}


