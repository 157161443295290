/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetResponsibleDto
 */
export interface GetResponsibleDto {
    /**
     * 
     * @type {string}
     * @memberof GetResponsibleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetResponsibleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetResponsibleDto
     */
    type: GetResponsibleDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetResponsibleDto
     */
    is_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetResponsibleDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof GetResponsibleDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetResponsibleDto
     */
    key: string;
}

/**
* @export
* @enum {string}
*/
export enum GetResponsibleDtoTypeEnum {
    Commercial = 'commercial',
    Support = 'support'
}

export function GetResponsibleDtoFromJSON(json: any): GetResponsibleDto {
    return GetResponsibleDtoFromJSONTyped(json, false);
}

export function GetResponsibleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResponsibleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'is_active': !exists(json, 'is_active') ? undefined : json['is_active'],
        'phone': json['phone'],
        'url': json['url'],
        'key': json['key'],
    };
}

export function GetResponsibleDtoToJSON(value?: GetResponsibleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'is_active': value.is_active,
        'phone': value.phone,
        'url': value.url,
        'key': value.key,
    };
}


