/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatePayerDto,
    CreatePayerDtoFromJSON,
    CreatePayerDtoFromJSONTyped,
    CreatePayerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateSaleAutoGeneratePaymentLinkDto
 */
export interface CreateSaleAutoGeneratePaymentLinkDto {
    /**
     * Valor
     * @type {number}
     * @memberof CreateSaleAutoGeneratePaymentLinkDto
     */
    amount: number;
    /**
     * Descricão
     * @type {string}
     * @memberof CreateSaleAutoGeneratePaymentLinkDto
     */
    description?: string;
    /**
     * Protocolo
     * @type {string}
     * @memberof CreateSaleAutoGeneratePaymentLinkDto
     */
    protocol: string;
    /**
     * Pagador
     * @type {CreatePayerDto}
     * @memberof CreateSaleAutoGeneratePaymentLinkDto
     */
    payer: CreatePayerDto;
}

export function CreateSaleAutoGeneratePaymentLinkDtoFromJSON(json: any): CreateSaleAutoGeneratePaymentLinkDto {
    return CreateSaleAutoGeneratePaymentLinkDtoFromJSONTyped(json, false);
}

export function CreateSaleAutoGeneratePaymentLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSaleAutoGeneratePaymentLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'protocol': json['protocol'],
        'payer': CreatePayerDtoFromJSON(json['payer']),
    };
}

export function CreateSaleAutoGeneratePaymentLinkDtoToJSON(value?: CreateSaleAutoGeneratePaymentLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'description': value.description,
        'protocol': value.protocol,
        'payer': CreatePayerDtoToJSON(value.payer),
    };
}


