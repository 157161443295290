/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSalePlanPaymentTypeDto
 */
export interface GetSalePlanPaymentTypeDto {
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanPaymentTypeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanPaymentTypeDto
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof GetSalePlanPaymentTypeDto
     */
    fee: number;
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanPaymentTypeDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof GetSalePlanPaymentTypeDto
     */
    installments: number;
    /**
     * 
     * @type {number}
     * @memberof GetSalePlanPaymentTypeDto
     */
    teto: number;
    /**
     * 
     * @type {number}
     * @memberof GetSalePlanPaymentTypeDto
     */
    piso: number;
    /**
     * 
     * @type {string}
     * @memberof GetSalePlanPaymentTypeDto
     */
    operation_mode: GetSalePlanPaymentTypeDtoOperationModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum GetSalePlanPaymentTypeDtoOperationModeEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    BilletCode = 'billet_code',
    PixPos = 'pix_pos'
}

export function GetSalePlanPaymentTypeDtoFromJSON(json: any): GetSalePlanPaymentTypeDto {
    return GetSalePlanPaymentTypeDtoFromJSONTyped(json, false);
}

export function GetSalePlanPaymentTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalePlanPaymentTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'fee': json['fee'],
        'description': json['description'],
        'installments': json['installments'],
        'teto': json['teto'],
        'piso': json['piso'],
        'operation_mode': json['operation_mode'],
    };
}

export function GetSalePlanPaymentTypeDtoToJSON(value?: GetSalePlanPaymentTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'fee': value.fee,
        'description': value.description,
        'installments': value.installments,
        'teto': value.teto,
        'piso': value.piso,
        'operation_mode': value.operation_mode,
    };
}


