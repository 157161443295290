/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    original_name: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    extension: AttachmentExtensionEnum;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    updated_at?: string;
}

/**
* @export
* @enum {string}
*/
export enum AttachmentExtensionEnum {
    Png = 'png',
    Jpeg = 'jpeg',
    Jpg = 'jpg',
    Pdf = 'pdf',
    Doc = 'doc',
    Docx = 'docx',
    Xls = 'xls',
    Xlsx = 'xlsx',
    Rar = 'rar',
    Zip = 'zip',
    Csv = 'csv'
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': json['key'],
        'original_name': json['original_name'],
        'filename': json['filename'],
        'extension': json['extension'],
        'size': json['size'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'created_at': json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'original_name': value.original_name,
        'filename': value.filename,
        'extension': value.extension,
        'size': value.size,
        'url': value.url,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}


