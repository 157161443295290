import React from 'react';
import { SimpleGrid, FormControl, Input, GridItem } from '@chakra-ui/react';

import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import { type_account } from 'config/constants';
import FormLabel from '../../../components/Form/FormLabel';

type Props = {
	errors: any;
};

const BankForm: React.FC<Props> = (props) => (
	<SimpleGrid columns={{ base: 1, md: 6, xl: 12 }} color='gray.700' gap={4}>
		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 4, '2xl': 3 }} order={{ base: 1 }}>
			<FormControl id='bank_account.bank_code' isRequired>
				<FormLabel id='bank-account-code-label' fontSize='md' fontWeight='bold'>
					Código do Banco
				</FormLabel>
				<FormInput id='bank-account-code-form-input' as={Input} name='bank_account.bank_code' />
				<FormErrorMessage id='bank-account-code-form-error-text' name='bank_account.bank_code' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1, xl: 1 }} colSpan={{ base: 4, md: 2, xl: 4 }} order={{ base: 2, xl: 3 }}>
			<FormControl id='bank_account.agency_number' isRequired>
				<FormLabel id='bank-account-agency-label' fontSize='md' fontWeight='bold'>
					Número da agência
				</FormLabel>
				<FormInput id='bank-account-agency-number-form-input' as={Input} name='bank_account.agency_number' />
				<FormErrorMessage id='back-account-agency-number-form-error-text' name='bank_account.agency_number' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }} order={{ base: 3, xl: 4 }}>
			<FormControl id='bank_account.agency_digit'>
				<FormLabel id='bank-account-agency-digit-label' fontSize='md' fontWeight='bold'>
					Digito
				</FormLabel>
				<FormInput id='bank-account-agency-digit-form-input' as={Input} name='bank_account.agency_digit' />
				<FormErrorMessage id='bank-account-agency-digit-form-error-text' name='bank_account.agency_digit' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 4 }} order={{ base: 4, xl: 5 }}>
			<FormControl id='bank_account.account_number' isRequired>
				<FormLabel id='bank-account-number-label' fontSize='md' fontWeight='bold'>
					Número da conta
				</FormLabel>
				<FormInput id='bank-account-number-form-input' as={Input} name='bank_account.account_number' />
				<FormErrorMessage id='bank-account-number-form-error-text' name='bank_account.account_number' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }} order={{ base: 5, xl: 6 }}>
			<FormControl id='bank_account.account_digit'>
				<FormLabel id='bank-account-digit-label' fontSize='md' fontWeight='bold'>
					Digito
				</FormLabel>
				<FormInput id='bank-account-digit-form-input' as={Input} name='bank_account.account_digit' />
				<FormErrorMessage id='bank-account-digit-form-error-text' name='bank_account.account_digit' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 4 }} order={{ base: 6, xl: 1 }}>
			<FormControl id='bank_account.type_account' isRequired>
				<FormLabel id='bank-account-type-label' fontSize='md' fontWeight='bold'>
					Tipo de conta
				</FormLabel>
				<FormSelect id='bank-account-type-account-form-select' name='bank_account.type_account'>
					<option value='' />
					{type_account.map((type) => (
						<option value={type.value} key={type.value}>
							{type.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='bank-account-type-form-error-text' name='bank_account.type_account' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 4, xl: 6 }} order={{ base: 7 }}>
			<FormControl id='bank_account.pix_key'>
				<FormLabel id='bank-account-pix-key-label' fontSize='md' fontWeight='bold'>
					Chave Pix
				</FormLabel>
				<FormInput id='bank-account-pix-key-form-input' as={Input} name='bank_account.pix_key' />
				<FormErrorMessage id='bank-account-pix-key-form-eror-text' name='bank_account.pix_key' />
			</FormControl>
		</GridItem>
	</SimpleGrid>
);

export default BankForm;
