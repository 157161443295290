import React from 'react';

import { SimpleGrid, FormControl, FormLabel, Input, GridItem, Heading, Divider } from '@chakra-ui/react';

import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import { status, seller_assignment, accreditationStatus } from 'config/constants';
import { GetMerchantDto, PermissionActionsEnum, PermissionSubjectEnum } from '../../../clients';
import FormCpfCnpjInput from '../../../components/Form/FormCpfCnpjInput';
import FormCurrencyInput from '../../../components/Form/FormCurrencyInput';
import FormCnsInput from '../../../components/Form/FormCnsInput';
import FormCnaeInput from '../../../components/Form/FormCnaeInput';
import FormPhoneInput from '../../../components/Form/FormPhoneInput';
import Can from 'contexts/Can';
import CompanyComplianceForm from './CompanyComplianceForm';

type Props = {
	errors: any;
	values: any;
	setFieldValue: any;
	setSelectedPayer?: React.Dispatch<React.SetStateAction<any[]>>;
	merchants: GetMerchantDto[];
	salesPlans: any;
	isStatusDisabled: boolean;
	isCnsSearchEnabled: boolean;
};

const SellerForm: React.FC<Props> = ({ setSelectedPayer, isStatusDisabled, setFieldValue, isCnsSearchEnabled }) => {
	const completeDataByCns = ({
		assignment,
		phone,
		secondary_phone,
		email,
		zipcode,
		street,
		address_number,
		district,
		city,
		state,
		responsible,
		monthlyBilling,
	}) => {
		setFieldValue('assignment', assignment);
		setFieldValue('phone', phone);
		setFieldValue('secondary_phone', secondary_phone);
		setFieldValue('email', email);
		setFieldValue('monthly_billing', monthlyBilling);
		setFieldValue('address.zipcode', zipcode);
		setFieldValue('address.street', street);
		setFieldValue('address.number', address_number);
		setFieldValue('address.district', district);
		setFieldValue('address.city', city);
		setFieldValue('address.state', state);
		setFieldValue('owner_name', responsible);
	};

	return (
		<SimpleGrid columns={{ base: 1, md: 6, xl: 12 }} color='gray.700' gap={4}>
			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, xl: 5 }}>
				<FormControl id='name' isRequired>
					<FormLabel id='seller-name-label' fontSize='md' fontWeight='bold'>
						Nome
					</FormLabel>
					<FormInput id='seller-name-form-input' name='name' />
					<FormErrorMessage id='seller-name-form-error-text' name='name' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1, md: 5 }} colSpan={{ base: 4, md: 2, xl: 3 }}>
				<FormControl id='document' isRequired>
					<FormLabel id='seller-document-label' fontSize='md' fontWeight='bold'>
						CPF/CNPJ
					</FormLabel>
					<FormCpfCnpjInput
						id='seller-document-form-cpf-cnpj-input'
						name='document'
						isDisabled={false}
						isSearchPayer={false}
						setSelectedPayer={setSelectedPayer}
					/>
					<FormErrorMessage id='seller-document-form-error-text' name='document' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1, md: 1 }} colSpan={{ base: 4, xl: 4 }}>
				<FormControl id='description' isRequired>
					<FormLabel id='seller-description-label' fontSize='md' fontWeight='bold'>
						Descrição
					</FormLabel>
					<FormInput id='seller-description-form-input' as={Input} name='description' />
					<FormErrorMessage id='seller-description-form-error-text' name='description' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1, md: 5, xl: 1 }} colSpan={{ base: 4, md: 2, xl: 4 }}>
				<FormControl id='state_registration'>
					<FormLabel id='seller-registration-label' fontSize='md' fontWeight='bold'>
						Registro estadual
					</FormLabel>
					<FormInput id='state-registration-form-input' as={Input} name='state_registration' />
					<FormErrorMessage id='state-registration-form-error-text' name='state_registration' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }}>
				<FormControl id='cns' isRequired>
					<FormLabel id='seller-cns-label' fontSize='md' fontWeight='bold'>
						CNS
					</FormLabel>
					<FormCnsInput
						id='select-form-cns-form-input'
						name='cns'
						search={
							isCnsSearchEnabled
								? (data) => {
										completeDataByCns(data);
								  }
								: undefined
						}
					/>
					<FormErrorMessage id='select-form-cns-form-error-text' name='cns' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }}>
				<FormControl id='cnae' isRequired>
					<FormLabel id='seller-cnae-label' fontSize='md' fontWeight='bold'>
						CNAE
					</FormLabel>
					<FormCnaeInput id='seller-form-cnae-form-input' name='cnae' />
					<FormErrorMessage id='seller-form-cnae-error-text' name='cnae' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 4 }}>
				<FormControl id='assignment' isRequired>
					<FormLabel id='seller-assignment-label' fontSize='md' fontWeight='bold'>
						Atribuição
					</FormLabel>
					<FormSelect id='seller-form-assignment-form-select' name='assignment'>
						<option value='' />
						{seller_assignment.map((assignment) => (
							<option value={assignment.key} key={assignment.key}>
								{assignment.text}
							</option>
						))}
					</FormSelect>
					<FormErrorMessage id='seller-form-assignment-form-error-text' name='assignment' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 4, xl: 4 }}>
				<FormControl id='code' isRequired>
					<FormLabel id='seller-code-label' fontSize='md' fontWeight='bold'>
						Chave do Estabelecimento
					</FormLabel>
					<FormInput id='code-form-input' as={Input} name='code' />
					<FormErrorMessage id='code-form-error-text' name='code' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 3 }}>
				<FormControl id='monthly_billing' isRequired>
					<FormLabel id='seller-montlhy-label' fontSize='md' fontWeight='bold'>
						Faturamento Mensal
					</FormLabel>
					<FormCurrencyInput id='monthly-billing-form-currency-input' borderColor='darkGrey' name='monthly_billing' />
					<FormErrorMessage id='monthly-billing-form-error-text' name='monthly_billing' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 4, xl: 3 }}>
				<FormControl id='covenant'>
					<FormLabel id='seller-covenant-label' fontSize='md' fontWeight='bold'>
						Convênio
					</FormLabel>
					<FormInput id='covenant-form-input' as={Input} name='covenant' />
					<FormErrorMessage id='covenant-form-error-text' name='covenant' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 2 }}>
				<Can do={PermissionActionsEnum.Read} on={PermissionSubjectEnum.ChangeSeller}>
					<FormControl id='status' isDisabled={isStatusDisabled} isRequired>
						<FormLabel id='seller-status-label' fontSize='md' fontWeight='bold'>
							Status
						</FormLabel>
						<FormSelect id='seller-status-form-select' name='status'>
							<option value='' />
							{status.map((type) => (
								<option disabled={type.disabled} value={type.value} key={type.value}>
									{type.text}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='seller-status-form-error-text' name='status' />
					</FormControl>
				</Can>
			</GridItem>

			<GridItem colStart={{ base: 1, xl: 1 }} colSpan={{ base: 4, md: 3, xl: 4 }}>
				<FormControl id='erp' isRequired>
					<FormLabel id='seller-erp-label' fontSize='md' fontWeight='bold'>
						Sistema de gestão (ERP)
					</FormLabel>
					<FormInput id='erp' as={Input} name='erp' />
					<FormErrorMessage id='erp-form-error-text' name='erp' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 3, xl: 4 }}>
				<FormControl id='accreditation_status'>
					<FormLabel id='seller-accreditation-status-label' fontSize='md' fontWeight='bold'>
						Status do credenciamento
					</FormLabel>
					<FormSelect id='accreditation_status' name='accreditation_status'>
						<option value='' />
						{accreditationStatus.map((type) => (
							<option value={type.value} key={type.value}>
								{type.text}
							</option>
						))}
					</FormSelect>
					<FormErrorMessage id='seller-accreditation-status-form-error-text' name='accreditation_status' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 4 }} order={{ md: 2, xl: 1 }}>
				<FormControl id='frontier'>
					<FormLabel id='seller-frontier-label' fontSize='md' fontWeight='bold'>
						Fronteiriça
					</FormLabel>
					<FormSelect id='seller-frontier-form-select' name='frontier'>
						<option value='' />
						<option value='true'>Sim</option>
						<option value='false'>Não</option>
					</FormSelect>
					<FormErrorMessage id='seller-frontier-form-error-text' name='frontier' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 6, xl: 12 }} order={{ md: 1, xl: 2 }}>
				<CompanyComplianceForm />
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 3, md: 6, xl: 12 }} mt={5} order={{ base: 10 }}>
				<Heading color='gray.700' as='h6' size='sm' py='1%'>
					Contato
				</Heading>
				<Divider />
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 2, xl: 4 }} order={{ base: 11, md: 12, xl: 11 }}>
				<FormControl id='phone'>
					<FormLabel id='seller-phone-label' fontSize='md' fontWeight='bold'>
						Telefone
					</FormLabel>
					<FormPhoneInput id='phone-form-input' name='phone' />
					<FormErrorMessage id='seller-phone-form-error-text' name='phone' />
				</FormControl>
			</GridItem>

			<GridItem colSpan={{ base: 4, md: 2, xl: 4 }} order={{ base: 12, md: 14, xl: 12 }}>
				<FormControl id='secondary_phone'>
					<FormLabel id='settings-secondary-phone-label' fontSize='md' fontWeight='bold'>
						Telefone Secundário
					</FormLabel>
					<FormPhoneInput id='secondary-phone-form-input' name='secondary_phone' />
					<FormErrorMessage id='seller-secondary-phone-form-error-text' name='secondary_phone' />
				</FormControl>
			</GridItem>

			<GridItem colSpan={{ base: 4, md: 2, xl: 4 }} order={{ base: 13, md: 16, xl: 13 }}>
				<FormControl id='tertiary_phone'>
					<FormLabel id='settings-tertiary-phone-label' fontSize='md' fontWeight='bold'>
						Telefone Terciário
					</FormLabel>
					<FormPhoneInput id='tertiary-phone-form-input' name='tertiary_phone' />
					<FormErrorMessage id='seller-tertiary-phone-form-error-text' name='tertiary_phone' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 4, xl: 4 }} order={{ base: 14, md: 13, xl: 14 }}>
				<FormControl id='email'>
					<FormLabel id='seller-email-label' fontSize='md' fontWeight='bold'>
						E-mail
					</FormLabel>
					<FormInput id='seller-form-email-form-input' name='email' />
					<FormErrorMessage id='seller-email-form-error-text' name='email' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 4, xl: 4 }} order={{ base: 15, md: 14, xl: 15 }}>
				<FormControl id='secondary_email'>
					<FormLabel id='seller-secondary-email-label' fontSize='md' fontWeight='bold'>
						E-mail Secundário
					</FormLabel>
					<FormInput id='seller-secondary-email-form-input' name='secondary_email' />
					<FormErrorMessage id='seller-secondary-email-form-error-text' name='secondary_email' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{ base: 1 }} colSpan={{ base: 4, md: 4, xl: 4 }} order={{ base: 16, md: 16, xl: 16 }}>
				<FormControl id='tertiary_email'>
					<FormLabel id='seller-tertiary-email-label' fontSize='md' fontWeight='bold'>
						E-mail Terciário
					</FormLabel>
					<FormInput id='seller-tertiary-email-form-input' name='tertiary_email' />
					<FormErrorMessage id='seller-tertiary-email-form-error-text' name='tertiary_email' />
				</FormControl>
			</GridItem>
		</SimpleGrid>
	);
};

export default SellerForm;
