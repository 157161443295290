import * as React from 'react';

import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';

const CommonRoute = (routeProps: RouteProps) => {
  const Component: any = routeProps.component;
  const rest = {...routeProps};

	delete rest.component;
	
	return (
		<Route {...rest} render={(props) => <Component {...props} />} />			
  );
};

export default CommonRoute;
