import React from 'react';

import { Stack, Flex, Wrap, FormControl, Switch, BreadcrumbItem, BreadcrumbLink, useBreakpointValue } from '@chakra-ui/react';
import { Formik } from 'formik';

import * as yup from 'yup';
import * as math from 'mathjs';

import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import InformationBox from './components/InformationBox';
import LastSalesTable from './components/LastSalesTable';
import Simulate from './components/Simulate';
import Modal from './components/Modal';
import Loader from 'containers/Loader';

import { maskMoney, maskPorcent } from '../../services/masks.service';

import FormInput from 'components/Form/FormInput';
import Paper from 'containers/Paper';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import Button from 'components/Button';

import CreateSearchPayerWrapper from 'containers/CreateSearchPayerWrapper';

import {
	CreateSellerPayerRequest,
	GetSimulationDto,
	SimulationApi,
	ListSimulationRequest,
	ListSellerPosRequest,
	GetSellerPosDto,
	SellersApi,
	PosPaymentsApi,
	PaginatedDto,
	GetUserDtoTypeEnum,
	PosControllerV2PaymentsRequestWithSplitRequest,
	CreateSplitRulesDto,
	InternalListSellerSalesRequest,
	InternalListSellerSalesTypeEnum,
} from '../../clients';

import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { getApiAuthConfig } from '../../services/api.service';

import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';
import FormDebouncedInput from 'components/Form/FormDebouncedInput';
import SplitRulesWrapper, { SplitSellerValue } from 'containers/SplitRulesWrapper';
import { round } from 'services/math.service';
import { validSplitRulesAmount } from 'services/validations.service';
import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';
import Text from 'components/Text';
import { useTheme } from 'contexts/ThemeProvider';
import FormLabel from 'components/Form/FormLabel';
import Checkbox from 'components/Checkbox';

const PosSale: React.FC = () => {
	const { user } = useAuth();
	const { currentSeller } = useCurrentSeller();
	const { addToast } = useToasts();
	const { theme } = useTheme();

	const history = useHistory();

	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [amount, setAmount] = React.useState('');
	const [showAmount, setShowAmount] = React.useState('');

	const [description, setDescription] = React.useState('');
	const [preCapture, setPreCapture] = React.useState(false);

	const [isLoading, setIsLoading] = React.useState(false);

	const [installmentsSimulation, setInstallmentsSimulation] = React.useState<Array<GetSimulationDto>>([]);
	const [selectedPayer, setSelectedPayer] = React.useState<any>();
	const [splits, setSplits] = React.useState<SplitSellerValue[]>([]);
	const [listPOS, setListPOS] = React.useState<Array<GetSellerPosDto>>([]);
	const [simulator, setSimulator] = React.useState({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });
	const [lastSales, setLastSales] = React.useState<PaginatedDto>({} as PaginatedDto);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [lastSalesTotalPages, setLastSalesTotalPages] = React.useState(1);
	const [updateTable, setUpdateTable] = React.useState(true);

	const [selectedPOS, setSelectedPOS] = React.useState('');
	const [radioValue, setRadioValue] = React.useState('');

	const [addPayer, setAddPayer] = React.useState(false);
	const [hasSplitRules, setHasSplitRules] = React.useState(false);
	const [confirmPayment, setConfirmPayment] = React.useState(false);

	const apiConfig = getApiAuthConfig();
	const simulationApi = new SimulationApi(apiConfig);
	const sellerApi = new SellersApi(apiConfig);
	const posPaymentsApi = new PosPaymentsApi(apiConfig);

	const handleSimulateOnChange = (data) => {
		const sim = JSON.parse(data);
		setSimulator(sim);
		setRadioValue(data);
	};

	const handleGetPOS = async () => {
		setIsLoading(true);

		const listSellerPosRequest: ListSellerPosRequest = {
			sellerId: currentSeller?.id!,
		};

		const response = await sellerApi.listSellerPos(listSellerPosRequest);

		setListPOS(response);
		setIsLoading(false);
	};

	const handleSavePayerFormSubmit = async (values) => {
		try {
			setIsLoading(true);

			const { payer } = values;

			const addressIsNull = Object.values(payer.address).every((att) => !att);

			if (addressIsNull) {
				delete payer.address;
			}
			const createPayerDto = payer;
			const createSellerPayerRequest: CreateSellerPayerRequest = {
				sellerId: currentSeller?.id!,
				createPayerDto,
			};

			const response = await sellerApi.createSellerPayer(createSellerPayerRequest);

			setSelectedPayer(response);
		} catch (e) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			setConfirmPayment(!confirmPayment);
		}
	};

	const handlePayment = async () => {
		try {
			if (!simulator.totalAmount) {
				addToast('Valor inválido', {
					appearance: 'error',
					autoDismiss: true,
				});
				return;
			}
			setIsLoading(true);

			const posControllerPaymentsRequest: PosControllerV2PaymentsRequestWithSplitRequest = {
				sellerId: currentSeller?.id!,
				paymentPosRequestDTOV2: {
					terminal_id: selectedPOS,
					pre_capture: preCapture || false,
					amount: round(simulator.totalAmount * 100),
					is_debit: simulator.type === 'debit',
					instalments: Number(simulator.installment.split('x')[0]),
					service_id: Math.random().toString(36).substr(2, 8),
					is_pix: simulator.type === 'pix_pos',
					description,
					has_split_rules: false,
					split_rules: [],
				},
			};

			if (hasSplitRules && splits.length) {
				const parsedSplits: CreateSplitRulesDto[] = splits.map((split) => ({
					seller_id: split.seller.id,
					amount: split.amount,
				}));
				posControllerPaymentsRequest.paymentPosRequestDTOV2.has_split_rules = true;
				posControllerPaymentsRequest.paymentPosRequestDTOV2.split_rules = parsedSplits as CreateSplitRulesDto[];
			}

			if (selectedPayer) {
				posControllerPaymentsRequest.paymentPosRequestDTOV2.document = selectedPayer.document;
			}
			const response = await posPaymentsApi.posControllerV2PaymentsRequestWithSplit(posControllerPaymentsRequest);

			if (response) {
				setAddPayer(false);
				setAmount('');
				setShowAmount('');
				setDescription('');
				setPreCapture(false);
				setSimulator({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });
				setInstallmentsSimulation([]);
				setUpdateTable(!updateTable);
				setRadioValue('');
				setSplits([]);
				setHasSplitRules(false);
			}
			addToast('Venda enviada para a máquina com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (e) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmit = async (values, isValid) => {
		if (!isValid) return;
		if (hasSplitRules && splits.length) {
			const roundedAmount = round(simulator.totalAmount * 100);
			const isSplitsValid = validSplitRulesAmount(roundedAmount, splits);
			if (!isSplitsValid) {
				addToast('A soma das divisões não podem ser maiores que o valor da venda', {
					appearance: 'error',
					autoDismiss: true,
				});
				return;
			}
		}
		setDescription(values.description);
		setPreCapture(values.pre_capture);

		await handleGetPOS();
		if (addPayer) {
			await handleSavePayerFormSubmit(values);
		}
		setConfirmPayment(!confirmPayment);
	};

	const salePosInitialValues = {
		description: '',
		createPayer: false,
		pre_capture: false,
		payer: {
			name: '',
			document: '',
			email: '',
			phone: '',
			address: { zipcode: '', street: '', district: '', city: '', state: '', complement: '', number: '' },
		},
		search: {
			name: '',
			document: '',
		},
	};

	const schema = yup.object().shape({
		description: yup.string(),
		createPayer: yup.boolean(),
		payer: yup.object().shape({
			name: yup.string().when('createPayer', {
				is: true,
				then: yup.string().required('Campo obrigatório'),
			}),
			document: yup
				.string()
				.when('createPayer', {
					is: true,
					then: yup.string().required('Campo obrigatório'),
				})
				.isCpfOrCnpjOptional('Documento inválido'),
		}),
	});

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, { route: history.location.pathname });
	}

	React.useEffect(() => {
		setRadioValue('');
		setSimulator({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });
		setInstallmentsSimulation([]);
		setAmount('');
		setShowAmount('');
		setSplits([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller]);

	React.useEffect(() => {
		(async function () {
			if (currentSeller) {
				setIsLoading(true);

				const listSellerPayersRequest: InternalListSellerSalesRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					sellerId: currentSeller?.id!,
					type: [InternalListSellerSalesTypeEnum.Pos],
					calcTotalAmount: false
				};

				if (user.type !== GetUserDtoTypeEnum.Backoffice) {
					listSellerPayersRequest.user = user.id;
				}
				const response = await sellerApi.internalListSellerSales(listSellerPayersRequest);

				if (response) {
					setLastSales(response);
					setLastSalesTotalPages(response.total_pages);
				}
				setIsLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateTable, currentSeller, pagination]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}
	return (
		<>
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Venda
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink opacity='0.9' cursor='unset' href='#' fontSize='md' fontWeight={`semibold`}>
							Venda na Máquina
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Formik enableReinitialize initialValues={salePosInitialValues} validationSchema={schema} onSubmit={handleSubmit}>
				{({ handleSubmit, setFieldValue, resetForm, values, errors, isValid }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }} className='form__sale-data'>
							<Loader isOpen={isLoading} />

							<Flex mb='4'>
								<Title id='pos-sale-title-text' fontSize={isMobile ? 'lg' : '2xl'}>VENDA NA MÁQUINA</Title>
							</Flex>

							<Flex flexDirection={`column`} pb='4'>
								<Flex flexDir={`column`} p={isMobile ? 4 : 10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
									<Stack spacing={3} color={theme?.text_color || `darkGrey`}>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb='4'>
											<FormControl isRequired>
												<FormLabel id='' fontSize={`md`} fontWeight={`medium`}>
													Valor dos Serviços
												</FormLabel>
												<FormDebouncedInput
													id='pos-sale-amount-form-debounced-input'
													isDisabled={isLoading}
													name='amount'
													value={showAmount}
													placeholder='"Ex.: 123,52+64,32+..."'
													onChange={(event) => {
														try {
															const filteredValue = event.target.value.replace(/,/gi, '.');

															setShowAmount(filteredValue);
															const totalValue = Number(math.evaluate(filteredValue));

															setAmount(totalValue.toString());
														} catch (err) {}
													}}
													afterDebounce={async () => {
														const amountCents = Number(amount) * 100;

														if (isNaN(amountCents)) {
															setRadioValue('');
															setSimulator({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });
															setInstallmentsSimulation([]);

															return;
														}
														setIsLoading(true);

														const SimulationRequest: ListSimulationRequest = {
															sellerId: currentSeller?.id!,
															amountCents: Number(amountCents),
															isPos: true,
														};

														setSimulator({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });

														const response = await simulationApi.listSimulation(SimulationRequest);

														if (currentSeller?.pix_pos) {
															setInstallmentsSimulation(
																response
																	.filter(
																		(installment) =>
																			installment.type !== 'billet' && installment.type !== 'pix' && installment.type !== 'billet_code'
																	)
																	.sort((a, b) => b.type.localeCompare(a.type))
															);
														} else {
															setInstallmentsSimulation(
																response
																	.filter(
																		(installment) =>
																			installment.type !== 'billet' &&
																			installment.type !== 'pix' &&
																			installment.type !== 'pix_pos' &&
																			installment.type !== 'billet_code'
																	)
																	.sort((a, b) => b.type.localeCompare(a.type))
															);
														}
														setIsLoading(false);
														setRadioValue(
															JSON.stringify({
																installment: '',
																installmentAmount: 0,
																totalAmount: 0,
																type: '',
															})
														);
													}}
												/>
											</FormControl>
											<FormControl m={1} color={theme?.text_color || `darkGrey`}>
												<FormLabel id='description-form-label' fontSize={`md`} fontWeight={`medium`}>
													Nº do Pedido
												</FormLabel>
												<FormInput id='pos-sale-description-form-input' borderColor={`darkGrey`} name='description' />
												<FormErrorMessage id='pos-sale-description-form-error-text' name='description' />
											</FormControl>
											{currentSeller && currentSeller.merchant && currentSeller.merchant.pre_capture && (
												<FormControl>
													<Stack h={`100%`} spacing={4}>
														<Text id='pre-capture-text' color={`gray.700`} fontSize={`md`}>
															Pré-captura
														</Text>
														<Checkbox
															id='active-pre-capture-checkbox'
															isChecked={values.pre_capture}
															onChange={(e) => {
																setFieldValue('pre_capture', e.target.checked);
															}}
														>
															Sim
														</Checkbox>
													</Stack>
												</FormControl>
											)}
										</Stack>
									</Stack>
									{installmentsSimulation.length >= 1 && amount !== '' && (
										<>
											<Simulate
												handleSimulateOnChange={handleSimulateOnChange}
												radioValue={radioValue}
												simulation={installmentsSimulation}
											/>
											<Wrap justify='flex-start' my='4'>
												<InformationBox header='Valor Original' body={`R$ ${maskMoney(amount)}`} />
												<InformationBox header='Parcelas' body={`${simulator.installment} R$ ${maskMoney(simulator.installmentAmount)}`} />
												<InformationBox header='Valor a cobrar' body={`R$ ${maskMoney(simulator.totalAmount)}`} />
												<InformationBox header='Custo efetivo total' body={`${maskPorcent(simulator.cet)}`} />
											</Wrap>
										</>
									)}
								</Flex>

								{currentSeller?.has_split_rules && (
									<Paper id='split-sale-paper-text'>
										<FormControl display='flex' alignItems='center'>
											<FormLabel id='split-sale-label' htmlFor='email-alerts' mb='0'>
												Dividir valor da venda?
											</FormLabel>
											<Switch
												id='payer-data'
												isChecked={hasSplitRules}
												onChange={() => {
													setHasSplitRules(!hasSplitRules);
													setFieldValue('hasSplitRules', !hasSplitRules);
												}}
												name='hasSplitRules'
											/>
										</FormControl>

										{hasSplitRules && <SplitRulesWrapper splits={splits} setSplits={setSplits} />}
									</Paper>
								)}

								<Paper id='email-alerts-paper-text'>
									<FormControl display='flex' alignItems='center'>
										<FormLabel id='payer-payment-form-label' htmlFor='email-alerts' mb='0' fontWeight='bold'>
											<Text id='payer-payment-methods-text' fontSize={`md`} fontWeight={`medium`}>
												Incluir dados do pagador?
											</Text>
										</FormLabel>
										<Switch
											id='payer-data'
											isChecked={addPayer}
											onChange={() => {
												setAddPayer(!addPayer);
												setFieldValue('createPayer', !addPayer);
											}}
											name='createPayer'
											className='radio__payer-data'
										/>
									</FormControl>

									{addPayer && (
										<CreateSearchPayerWrapper
											selectedPayer={selectedPayer}
											setSelectedPayer={setSelectedPayer}
											setFieldValue={setFieldValue}
											errors={errors}
											requiredFields={{
												document: true,
												name: true,
												email: false,
												phone: false,
												address: {
													zipcode: false,
													street: false,
													district: false,
													city: false,
													state: false,
													number: false,
												},
											}}
										/>
									)}
									<Flex display='flex' flexDirection='column' alignItems={isMobile ? 'center' : 'flex-end'} mt='4'>
										<Button
											id='generate-sale-pos-button'
											size='lg'
											type='submit'
											disabled={!isValid || !simulator.totalAmount}
											bgColor={'primary'}
											className='button__generate-sale'
										>
											Gerar Venda
										</Button>
									</Flex>
								</Paper>

								{lastSales && lastSales?.results && (
									<>
										<Flex mt='8'>
											<Title id='last-payer-sellers-title-text'>Últimas vendas feitas por você</Title>
										</Flex>
										<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
											<Flex overflowX='auto'>
												<LastSalesTable
													onRowClick={(row) => navigateToDetails(row.original.id)}
													data={lastSales}
													setPagination={setPagination}
													pagination={pagination}
													totalPages={lastSalesTotalPages}
													variant={`simple`}
												/>
											</Flex>
										</Flex>
									</>
								)}
							</Flex>
							<Modal
								confirmPayment={confirmPayment}
								handlePayment={handlePayment}
								listPOS={listPOS}
								setConfirmPayment={setConfirmPayment}
								setSelectedPOS={setSelectedPOS}
								resetForm={resetForm}
								isLoading={isLoading}
								setSelectedPayer={setSelectedPayer}
							/>
						</form>
					);
				}}
			</Formik>
		</>
	);
};

export default PosSale;
