import React from 'react';

import { SimpleGrid, FormControl, Input } from '@chakra-ui/react';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import { payment_method, salePlanType } from '../../../config/constants';

import { Stack } from '@chakra-ui/layout';
import { GetAcquirerDto, GetCostPlanDto, GetMerchantDto, GetSettlementPlanDto } from '../../../clients';
import FormSelect from '../../../components/Form/FormSelect';
import FormLabel from '../../../components/Form/FormLabel';

type Props = {
	errors: any;
	merchants: GetMerchantDto[];
	acquirers: GetAcquirerDto[];
	settlementPlans: GetSettlementPlanDto[];
	costPlans: GetCostPlanDto[];
};

const SalePlanForm: React.FC<Props> = (props) => (
	<SimpleGrid color='gray.700' spacing='2.5rem'>
		<Stack direction={['column', 'column', 'row']} spacing={4}>
			<FormControl id='name' isRequired>
				<FormLabel id='name-label' fontSize='md' fontWeight='bold'>
					Nome
				</FormLabel>
				<FormInput id='sale-plan-name-form-input' as={Input} name='name' />
				<FormErrorMessage id='sale-plan-name-form-error-text' name='name' />
			</FormControl>

			<FormControl id='description' isRequired>
				<FormLabel id='description-label' fontSize='md' fontWeight='bold'>
					Descrição
				</FormLabel>
				<FormInput id='sale-plan-description-form-input' as={Input} name='description' />
				<FormErrorMessage id='sale-plan-description-form-error-text' name='description' />
			</FormControl>

			<FormControl id='type' isRequired>
				<FormLabel id='type-label' fontSize='md' fontWeight='bold'>
					Tipo
				</FormLabel>
				<FormSelect id='sale-plan-type-form-select' name='type'>
					<option value='' />
					{salePlanType.map((type) => (
						<option value={type.value} key={type.value}>
							{type.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='sale-plan-type-form-error-text' name='type' />
			</FormControl>

			<FormControl id='payment_method' isRequired>
				<FormLabel id='sale-plan-payment-method-label' fontSize='md' fontWeight='bold'>
					Método de Pagamento
				</FormLabel>
				<FormSelect id='sale-plan-payment-method-form-select' name='payment_method'>
					<option value='' />
					{payment_method.map((method) => (
						<option value={method.value} key={method.value}>
							{method.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='sale-plan-payment-method-form-error-message' name='payment_method' />
			</FormControl>
		</Stack>

		<Stack direction={['column', 'column', 'row']} spacing={4}>
			<FormControl id='merchant_id' isRequired>
				<FormLabel id='merchant-label' fontSize='md' fontWeight='bold'>
					Merchant
				</FormLabel>
				<FormSelect id='sale-plan-merchant-id-form-select' name='merchant_id'>
					<option value='' />
					{props?.merchants?.map((merchant) => (
						<option value={merchant.id} key={merchant.id}>
							{merchant.description}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='sale-plan-merchant-id-form-error-text' name='merchant_id' />
			</FormControl>

			<FormControl id='acquirer_id' isRequired>
				<FormLabel id='acquirer-label' fontSize='md' fontWeight='bold'>
					Adquirente
				</FormLabel>
				<FormSelect id='sale-plan-acquirer-id-form-select' name='acquirer_id'>
					<option value='' />
					{props?.acquirers?.map((acquirer) => (
						<option value={acquirer.id} key={acquirer.id}>
							{acquirer.name}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='sale-plan-acquirer-id-form-error-text' name='acquirer_id' />
			</FormControl>

			<FormControl id='settlement_plan_id' isRequired>
				<FormLabel id='plan-label' fontSize='md' fontWeight='bold'>
					Plano de Liquidação
				</FormLabel>
				<FormSelect id='sale-plan-settlement-plan-id-form-select' name='settlement_plan_id'>
					<option value='' />
					{props?.settlementPlans?.map((settlementPlan) => (
						<option value={settlementPlan.id} key={settlementPlan.id}>
							{settlementPlan.name}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='sale-plan-settlement-plan-id-form-error-text' name='settlement_plan_id' />
			</FormControl>

			<FormControl id='cost_plan_id' isRequired>
				<FormLabel id='cost-plan-label' fontSize='md' fontWeight='bold'>
					Plano de Custo
				</FormLabel>
				<FormSelect id='sale-plan-cost-plan-id-form-select' name='cost_plan_id'>
					<option value='' />
					{props?.costPlans?.map((costPlan) => (
						<option value={costPlan.id} key={costPlan.id}>
							{costPlan.name}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage id='sale-plan-cost-plan-id-form-error-text' name='cost_plan_id' />
			</FormControl>
		</Stack>
	</SimpleGrid>
);

export default SalePlanForm;
