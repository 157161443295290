/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCostPlanOptionsDto
 */
export interface CreateCostPlanOptionsDto {
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlanOptionsDto
     */
    fixed_amount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlanOptionsDto
     */
    bank_fixed_amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanOptionsDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanOptionsDto
     */
    type: CreateCostPlanOptionsDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanOptionsDto
     */
    type_status?: CreateCostPlanOptionsDtoTypeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanOptionsDto
     */
    brand?: CreateCostPlanOptionsDtoBrandEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlanOptionsDto
     */
    brand_fee?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanOptionsDto
     */
    brand_fee_type?: CreateCostPlanOptionsDtoBrandFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlanOptionsDto
     */
    installments?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanOptionsDto
     */
    exchange_fee_type: CreateCostPlanOptionsDtoExchangeFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlanOptionsDto
     */
    exchange_fee: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanOptionsDto
     */
    anticipation_fee_type?: CreateCostPlanOptionsDtoAnticipationFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlanOptionsDto
     */
    anticipation_fee?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanOptionsDto
     */
    markup_fee_type?: CreateCostPlanOptionsDtoMarkupFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlanOptionsDto
     */
    markup_fee?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCostPlanOptionsDto
     */
    is_cap?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlanOptionsDto
     */
    exchange_cap?: number;
}

/**
* @export
* @enum {string}
*/
export enum CreateCostPlanOptionsDtoTypeEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    PixPos = 'pix_pos'
}/**
* @export
* @enum {string}
*/
export enum CreateCostPlanOptionsDtoTypeStatusEnum {
    Pending = 'pending',
    Paid = 'paid'
}/**
* @export
* @enum {string}
*/
export enum CreateCostPlanOptionsDtoBrandEnum {
    Visa = 'visa',
    Elo = 'elo',
    Mc = 'mc',
    Amex = 'amex',
    Hipercard = 'hipercard',
    Default = 'default'
}/**
* @export
* @enum {string}
*/
export enum CreateCostPlanOptionsDtoBrandFeeTypeEnum {
    Fixed = 'fixed',
    Percent = 'percent'
}/**
* @export
* @enum {string}
*/
export enum CreateCostPlanOptionsDtoExchangeFeeTypeEnum {
    Fixed = 'fixed',
    Percent = 'percent'
}/**
* @export
* @enum {string}
*/
export enum CreateCostPlanOptionsDtoAnticipationFeeTypeEnum {
    Fixed = 'fixed',
    Percent = 'percent'
}/**
* @export
* @enum {string}
*/
export enum CreateCostPlanOptionsDtoMarkupFeeTypeEnum {
    Fixed = 'fixed',
    Percent = 'percent'
}

export function CreateCostPlanOptionsDtoFromJSON(json: any): CreateCostPlanOptionsDto {
    return CreateCostPlanOptionsDtoFromJSONTyped(json, false);
}

export function CreateCostPlanOptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCostPlanOptionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fixed_amount': json['fixed_amount'],
        'bank_fixed_amount': json['bank_fixed_amount'],
        'description': json['description'],
        'type': json['type'],
        'type_status': !exists(json, 'type_status') ? undefined : json['type_status'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'brand_fee': !exists(json, 'brand_fee') ? undefined : json['brand_fee'],
        'brand_fee_type': !exists(json, 'brand_fee_type') ? undefined : json['brand_fee_type'],
        'installments': !exists(json, 'installments') ? undefined : json['installments'],
        'exchange_fee_type': json['exchange_fee_type'],
        'exchange_fee': json['exchange_fee'],
        'anticipation_fee_type': !exists(json, 'anticipation_fee_type') ? undefined : json['anticipation_fee_type'],
        'anticipation_fee': !exists(json, 'anticipation_fee') ? undefined : json['anticipation_fee'],
        'markup_fee_type': !exists(json, 'markup_fee_type') ? undefined : json['markup_fee_type'],
        'markup_fee': !exists(json, 'markup_fee') ? undefined : json['markup_fee'],
        'is_cap': !exists(json, 'is_cap') ? undefined : json['is_cap'],
        'exchange_cap': !exists(json, 'exchange_cap') ? undefined : json['exchange_cap'],
    };
}

export function CreateCostPlanOptionsDtoToJSON(value?: CreateCostPlanOptionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fixed_amount': value.fixed_amount,
        'bank_fixed_amount': value.bank_fixed_amount,
        'description': value.description,
        'type': value.type,
        'type_status': value.type_status,
        'brand': value.brand,
        'brand_fee': value.brand_fee,
        'brand_fee_type': value.brand_fee_type,
        'installments': value.installments,
        'exchange_fee_type': value.exchange_fee_type,
        'exchange_fee': value.exchange_fee,
        'anticipation_fee_type': value.anticipation_fee_type,
        'anticipation_fee': value.anticipation_fee,
        'markup_fee_type': value.markup_fee_type,
        'markup_fee': value.markup_fee,
        'is_cap': value.is_cap,
        'exchange_cap': value.exchange_cap,
    };
}


