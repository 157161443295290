/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAttachmentDto
 */
export interface CreateAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    extension: CreateAttachmentDtoExtensionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    data: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAttachmentDto
     */
    size: number;
}

/**
* @export
* @enum {string}
*/
export enum CreateAttachmentDtoExtensionEnum {
    Png = 'png',
    Jpeg = 'jpeg',
    Jpg = 'jpg',
    Pdf = 'pdf',
    Doc = 'doc',
    Docx = 'docx',
    Xls = 'xls',
    Xlsx = 'xlsx',
    Rar = 'rar',
    Zip = 'zip',
    Csv = 'csv'
}

export function CreateAttachmentDtoFromJSON(json: any): CreateAttachmentDto {
    return CreateAttachmentDtoFromJSONTyped(json, false);
}

export function CreateAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'extension': json['extension'],
        'data': json['data'],
        'size': json['size'],
    };
}

export function CreateAttachmentDtoToJSON(value?: CreateAttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'extension': value.extension,
        'data': value.data,
        'size': value.size,
    };
}


