import {
	Stack,
	StackProps,
	Center,
	chakra,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	IconButton,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberDecrementStepper,
	TableProps,
	Box,
	Flex,
	Tooltip,
	NumberInputField,
	Select,
} from '@chakra-ui/react';

import React from 'react';

import { useTable, useSortBy, useExpanded } from 'react-table';
import { TriangleDownIcon, TriangleUpIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ArrowRightIcon } from '@chakra-ui/icons';
import Text from 'components/Text';

export type HeaderTitleProps = StackProps & {
	column: any;
	id: string;
};

export const HeaderTitle: React.FC<HeaderTitleProps> = (props: HeaderTitleProps) => {
	const { column, ...parsedProps } = props;

	return (
		<Stack isInline justifyContent={parsedProps.justifyContent || `center`} {...parsedProps}>
			<Center as={`span`}>
				<Text id={`${props.id}-text`}>{props.children}</Text>
			</Center>
			{column && (
				<chakra.span id={`label-${props.id}`}>
					{column.isSorted &&
						(column.isSortedDesc ? <TriangleDownIcon id='down-icon' aria-label='sorted descending' /> : <TriangleUpIcon id='up-icon' aria-label='sorted ascending' />)}
				</chakra.span>
			)}
		</Stack>
	);
};

export type CellContentProps = StackProps & {
	id: string;
};

export const CellContent: React.FC<CellContentProps> = (props: CellContentProps) => {
	return (
		<Stack isInline justifyContent={props.justifyContent || `center`} {...props}>
			<Text id={`${props.id}-span`} as={`span`}>{props.children}</Text>
		</Stack>
	);
};

type SimpleTableProps = TableProps & {
	columns: any;
	data: any;
	onRowClick?: (row: any) => void;
	setPagination?: React.Dispatch<React.SetStateAction<{ currentPage: number; limit: number }>>;
	pagination?: { currentPage: number; limit: number };
	totalPages?: number;
	isLimited?: boolean;
	maxHeightTableWrapper?: number | string;
	fillParent?: boolean;
	renderRowSubComponent?: (row: any) => React.ReactElement;
};

export const SimpleTable: React.FC<SimpleTableProps> = (props: SimpleTableProps) => {
	const {
		columns,
		setPagination,
		pagination,
		totalPages,
		data,
		maxHeightTableWrapper,
		fillParent,
		renderRowSubComponent,
		...parsedProps
	} = props;

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
		{ columns, data, expandSubRows: false },
		useSortBy,
		useExpanded
	);

	const headersCount = headerGroups.map((group) => group.headers).shift().length;

	const propsFlex = props.isLimited
		? {
				maxH: '600px',
				paddingBottom: '10px',
		  }
		: {};

	return (
		<Flex flexDirection={'column'} minW='100%' height={fillParent ? '100%' : 'auto'}>
			<Flex
				{...propsFlex}
				overflowY='auto'
				height={fillParent ? '100%' : 'auto'}
				maxH={maxHeightTableWrapper ? maxHeightTableWrapper : 'inherit'}
			>
				<Table
					color={`primary`}
					{...props}
					variant={parsedProps.variant || `simple`}
					{...getTableProps()}
					height={fillParent ? '100%' : 'auto'}
					minW='100%'
				>
					<Thead position='sticky' top={0} background='white'>
						{headerGroups.map((headerGroup) => (
							<Tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<Th textTransform={`none`} py={3} {...column.getHeaderProps(column.getSortByToggleProps())} isNumeric={column.isNumeric}>
										<Box w={`100%`} flexDir={`row`} justifyContent={`center`} fontWeight='bold' color='tableTitle'>
											{column.render('Header')}
										</Box>
									</Th>
								))}
							</Tr>
						))}
					</Thead>
					<Tbody {...getTableBodyProps()}>
						{rows.length === 0 ? (
							<Tr key='empty-list-key'>
								<Td textAlign={`center`} py={6} fontSize={`sm`} colSpan={headersCount}>
									<Text id='no-registry-text'>Sem registros</Text>
								</Td>
							</Tr>
						) : (
							<>
								{rows.map((row, index) => {
									prepareRow(row);

									return (
										<>
											<Tr
												key={`${row.original.id}-key`}
												id={`${row.original.id}-tr`}
												cursor={props.onRowClick ? 'pointer' : 'text'}
												onClick={() => props.onRowClick && props.onRowClick(row)}
												{...row.getRowProps()}
												{...(props.onRowClick && { _hover: { bg: 'defaultGrey' } })}
											>
												{row.cells.map((cell) => {													
													return (
														<Td id={`${index}-${cell?.column?.id}`} py={2} fontSize={`sm`} {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
															{cell.render('Cell')}
														</Td>
													);
												})}
											</Tr>

											{row.isExpanded && (
												<Tr>
													<Td colSpan={visibleColumns.length}>{renderRowSubComponent && renderRowSubComponent({ row })}</Td>
												</Tr>
											)}
										</>
									);
								})}
							</>
						)}
					</Tbody>
				</Table>
			</Flex>

			{!!(setPagination && pagination && totalPages && totalPages > 0 && data.length > 0) && (
				<Flex justifyContent='space-between' m={[0, 0, 4]} mt={[2, 2, 0]} alignItems='center' fontSize={['md', 'md', 'inherit']}>
					<Flex>
						<Tooltip label='Primeira página'>
							<IconButton
								aria-label='Primeira página'
								onClick={() => setPagination({ currentPage: 1, limit: pagination.limit })}
								isDisabled={pagination.currentPage === 1}
								icon={<ArrowLeftIcon h={3} w={3} />}
								mr={[0, 0, 4]}
							/>
						</Tooltip>

						<Tooltip label='Página anterior'>
							<IconButton
								aria-label='Página anterior'
								onClick={() =>
									setPagination({
										currentPage: pagination.currentPage - 1,
										limit: pagination.limit,
									})
								}
								isDisabled={pagination.currentPage === 1}
								icon={<ChevronLeftIcon h={6} w={6} />}
							/>
						</Tooltip>
					</Flex>

					<Flex direction={['column', 'column', 'row']} alignItems='center'>
						<Text id='page-text' flexShrink={0} mr={[0, 0, 8]} mb={[2, 2, 0]}>
							Pagina{' '}
							<Text id='current-page-text' fontWeight='bold' as='span'>
								{pagination.currentPage}
							</Text>{' '}
							de{' '}
							<Text id='total-page-text' fontWeight='bold' as='span'>
								{totalPages}
							</Text>
						</Text>
						<Text  id='navigate-page-text' flexShrink={0}>Ir para pagina:</Text>{' '}
						<NumberInput
							ml={[0, 0, 2]}
							mr={[0, 0, 8]}
							mb={[2, 2, 0]}
							w={28}
							min={1}
							max={totalPages}
							onChange={(value) => {
								let page = 1;
								if (Number(value) < 1) {
									page = 1;
								} else if (Number(value) > totalPages) {
									page = totalPages;
								} else {
									page = Number(value);
								}
								setPagination({ currentPage: page, limit: pagination.limit });
							}}
							defaultValue={pagination.currentPage}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
						<Select
							w={32}
							value={pagination.limit}
							onChange={(e) => {
								setPagination({ currentPage: 1, limit: Number(e.target.value) });
							}}
						>
							{[10, 20, 30, 40, 50, 100].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Ver {pageSize}
								</option>
							))}
						</Select>
					</Flex>

					<Flex>
						<Tooltip label='Proxima página'>
							<IconButton
								aria-label='Proxima página'
								onClick={() =>
									setPagination({
										currentPage: pagination.currentPage + 1,
										limit: pagination.limit,
									})
								}
								isDisabled={pagination.currentPage === totalPages}
								icon={<ChevronRightIcon h={6} w={6} />}
							/>
						</Tooltip>

						<Tooltip label='Ultima página'>
							<IconButton
								aria-label='Ultima página'
								onClick={() => setPagination({ currentPage: totalPages, limit: pagination.limit })}
								isDisabled={pagination.currentPage === totalPages}
								icon={<ArrowRightIcon h={3} w={3} />}
								ml={4}
							/>
						</Tooltip>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};
