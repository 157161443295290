import React from 'react';

import { SearchIcon, RepeatIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import { format } from 'date-fns';
import { Stack, Center, FormControl, useBreakpointValue, InputGroup, InputRightElement, IconButton, Tooltip } from '@chakra-ui/react';

import IPeriod from '../../../interfaces/period.interface';

import { GetPaymentLinkSaleDtoFormPaymentEnum, SaleStatusEnum } from '../../../clients';

import RangeDatePicker from '../../../containers/RangeDatePicker';
import MultiSelect from '../../../components/MultiSelect';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

type BillSaleFiltersProps = {
	onChangeFilters: (filters) => void;
};

const BillSaleFilters: React.FC<BillSaleFiltersProps> = (props: BillSaleFiltersProps) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const pagination = { currentPage: 1, limit: 10 };

	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const initialValues = {
		status: [],
		form_payment: [],
		startDate: format(new Date(), 'yyyy-MM-dd'),
		endDate: format(new Date(), 'yyyy-MM-dd'),
	};

	const handleFormSubmit = async (values) => {
		let parsedFilters = {
			currentPage: pagination.currentPage,
			limit: pagination.limit,
			startDate: format(period[0].startDate, 'yyyy-MM-dd'),
			endDate: format(period[0].endDate, 'yyyy-MM-dd'),
			status: values.status?.map((v) => v.value),
			formPayment: values.form_payment?.map((v) => v.value),
			payerName: values.payer_name,
			description: values.description,
		};

		if (parsedFilters.status && !parsedFilters.status.length) {
			delete parsedFilters.status;
		}

		if (parsedFilters.formPayment && !parsedFilters.formPayment.length) {
			delete parsedFilters.formPayment;
		}

		props.onChangeFilters(parsedFilters);
	};

	return (
		<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
			{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
				return (
					<form onSubmit={handleSubmit}>
						<Stack direction={isMobile ? 'column' : 'row'} spacing={0} mb={4} wrap='wrap'>
							<Center position='relative' mr={{ lg: 9 }} mb={4}>
								<RangeDatePicker
									bgColor={`primary`}
									p='6'
									period={period}
									setPeriod={setPeriod}
									setFieldValue={setFieldValue}
									handleSubmit={handleSubmit}
								/>
							</Center>
							<Center pt={0} minWidth='250px' mr={{ lg: 4 }} mb={4}>
								<FormControl>
									<MultiSelect
										id='status'
										placeholder='Status'
										options={[
											{ value: SaleStatusEnum.Pending, label: 'Pendente' },
											{ value: SaleStatusEnum.Succeeded, label: 'Aprovado' },
											{ value: SaleStatusEnum.PendingCancel, label: 'Cancelamento Pendente' },
											{ value: SaleStatusEnum.Canceled, label: 'Cancelado' },
											{ value: SaleStatusEnum.Failed, label: 'Falho' },
											{ value: SaleStatusEnum.Expired, label: 'Expirado' },
											{ value: SaleStatusEnum.WaitingCashIn, label: 'Aguardando Pagamento' },
										]}
										value={values.status}
										onChange={(value) => {
											setFieldValue('status', value);
											handleSubmit();
										}}
									/>
								</FormControl>
							</Center>
							<Center pt={0} minWidth='250px' mr={{ lg: 4 }} mb={4}>
								<FormControl>
									<InputGroup>
										<Input
											borderColor='darkGrey'
											id='description'
											placeholder='N° do Pedido'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={() => handleSubmit()}
											py='5'
										/>

										<InputRightElement
											children={
												<IconButton
													_hover={{ backgroundColor: 'transparent' }}
													_focus={{ outline: 'none' }}
													aria-label='Pesquisar por número do pedido'
													icon={<SearchIcon />}
													color='primary'
													backgroundColor='transparent'
													onClick={() => handleSubmit()}
												/>
											}
										/>
									</InputGroup>
								</FormControl>
							</Center>
							<Center pt={0} minWidth='250px' mr={{ lg: 4 }} mb={4}>
								<FormControl>
									<MultiSelect
										id='form-payment'
										placeholder='Forma de Pagamento'
										options={[
											{ value: GetPaymentLinkSaleDtoFormPaymentEnum.Credit, label: 'Crédito' },
											{ value: GetPaymentLinkSaleDtoFormPaymentEnum.Debit, label: 'Débito' },
										]}
										value={values.form_payment}
										onChange={(value) => {
											setFieldValue('form_payment', value);
											handleSubmit();
										}}
									/>
								</FormControl>
							</Center>
							<Center pt={0} minWidth='250px' mr={{ lg: 4 }} mb={4}>
								<FormControl>
									<InputGroup>
										<Input
											borderColor='darkGrey'
											id='payer_name'
											placeholder='Pagador'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={() => handleSubmit()}
											py='5'
										/>

										<InputRightElement
											children={
												<IconButton
													_hover={{ backgroundColor: 'transparent' }}
													_focus={{ outline: 'none' }}
													aria-label='Pesquisar por nome de pagador'
													icon={<SearchIcon />}
													color='primary'
													backgroundColor='transparent'
													onClick={() => handleSubmit()}
												/>
											}
										/>
									</InputGroup>
								</FormControl>
							</Center>
							<Tooltip label={'Refazer a busca'} placement='top' hasArrow>
								<IconButton
									_hover={{ backgroundColor: 'transparent' }}
									_focus={{ outline: 'none' }}
									aria-label='Refazer a busca'
									icon={<RepeatIcon />}
									color='primary'
									backgroundColor='transparent'
									size='lg'
									onClick={() => handleSubmit()}
								/>
							</Tooltip>
							{(values.form_payment.length > 0 || values.status.length > 0) && (
								<Button
									id='clear-filters-button'
									onClick={() => {
										resetForm();
										handleSubmit();
									}}
									_focus={{ outline: 'none' }}
									color='secondary'
									bgColor='transparent'
								>
									Limpar Filtros
								</Button>
							)}
						</Stack>
					</form>
				);
			}}
		</Formik>
	);
};

export default BillSaleFilters;
