/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateDebitAddressDTO,
    CreateDebitAddressDTOFromJSON,
    CreateDebitAddressDTOFromJSONTyped,
    CreateDebitAddressDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerDTO
 */
export interface CustomerDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    last_name: string;
    /**
     * Número do CPF/CNPJ do comprador
     * @type {string}
     * @memberof CustomerDTO
     */
    document?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    ip?: string;
    /**
     * 
     * @type {CreateDebitAddressDTO}
     * @memberof CustomerDTO
     */
    billing_address?: CreateDebitAddressDTO;
}

export function CustomerDTOFromJSON(json: any): CustomerDTO {
    return CustomerDTOFromJSONTyped(json, false);
}

export function CustomerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'document': !exists(json, 'document') ? undefined : json['document'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'billing_address': !exists(json, 'billing_address') ? undefined : CreateDebitAddressDTOFromJSON(json['billing_address']),
    };
}

export function CustomerDTOToJSON(value?: CustomerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'document': value.document,
        'email': value.email,
        'ip': value.ip,
        'billing_address': CreateDebitAddressDTOToJSON(value.billing_address),
    };
}


