/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatePaymentLinkFromProtocolDto,
    CreatePaymentLinkFromProtocolDtoFromJSON,
    CreatePaymentLinkFromProtocolDtoFromJSONTyped,
    CreatePaymentLinkFromProtocolDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateProtocolDto
 */
export interface UpdateProtocolDto {
    /**
     * Valor em centavos
     * @type {number}
     * @memberof UpdateProtocolDto
     */
    value_cents?: number;
    /**
     * Data de atualização do status
     * @type {string}
     * @memberof UpdateProtocolDto
     */
    status_date?: string;
    /**
     * Data de criação
     * @type {string}
     * @memberof UpdateProtocolDto
     */
    creation_date?: string;
    /**
     * Data de vencimento
     * @type {string}
     * @memberof UpdateProtocolDto
     */
    due_date?: string;
    /**
     * Método de pagamento
     * @type {string}
     * @memberof UpdateProtocolDto
     */
    payment_method?: UpdateProtocolDtoPaymentMethodEnum;
    /**
     * Há notificação por email
     * @type {boolean}
     * @memberof UpdateProtocolDto
     */
    has_email_notification?: boolean;
    /**
     * Email do pagador
     * @type {string}
     * @memberof UpdateProtocolDto
     */
    payer_email?: string;
    /**
     * Conteúdo do email
     * @type {string}
     * @memberof UpdateProtocolDto
     */
    email_content?: string;
    /**
     * Há link de pagamento
     * @type {boolean}
     * @memberof UpdateProtocolDto
     */
    has_payment_link?: boolean;
    /**
     * Link de pagamento
     * @type {CreatePaymentLinkFromProtocolDto}
     * @memberof UpdateProtocolDto
     */
    payment_link?: CreatePaymentLinkFromProtocolDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateProtocolDto
     */
    status?: UpdateProtocolDtoStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateProtocolDtoPaymentMethodEnum {
    Credit = 'credit',
    CreditInInstallments = 'credit_in_installments',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Transference = 'transference',
    Cash = 'cash',
    BankCheck = 'bank_check'
}/**
* @export
* @enum {string}
*/
export enum UpdateProtocolDtoStatusEnum {
    Pending = 'pending',
    Alert = 'alert',
    Paid = 'paid',
    Overdued = 'overdued',
    Canceled = 'canceled'
}

export function UpdateProtocolDtoFromJSON(json: any): UpdateProtocolDto {
    return UpdateProtocolDtoFromJSONTyped(json, false);
}

export function UpdateProtocolDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProtocolDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value_cents': !exists(json, 'value_cents') ? undefined : json['value_cents'],
        'status_date': !exists(json, 'status_date') ? undefined : json['status_date'],
        'creation_date': !exists(json, 'creation_date') ? undefined : json['creation_date'],
        'due_date': !exists(json, 'due_date') ? undefined : json['due_date'],
        'payment_method': !exists(json, 'payment_method') ? undefined : json['payment_method'],
        'has_email_notification': !exists(json, 'has_email_notification') ? undefined : json['has_email_notification'],
        'payer_email': !exists(json, 'payer_email') ? undefined : json['payer_email'],
        'email_content': !exists(json, 'email_content') ? undefined : json['email_content'],
        'has_payment_link': !exists(json, 'has_payment_link') ? undefined : json['has_payment_link'],
        'payment_link': !exists(json, 'payment_link') ? undefined : CreatePaymentLinkFromProtocolDtoFromJSON(json['payment_link']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function UpdateProtocolDtoToJSON(value?: UpdateProtocolDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value_cents': value.value_cents,
        'status_date': value.status_date,
        'creation_date': value.creation_date,
        'due_date': value.due_date,
        'payment_method': value.payment_method,
        'has_email_notification': value.has_email_notification,
        'payer_email': value.payer_email,
        'email_content': value.email_content,
        'has_payment_link': value.has_payment_link,
        'payment_link': CreatePaymentLinkFromProtocolDtoToJSON(value.payment_link),
        'status': value.status,
    };
}


