/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateGoalDto,
    CreateGoalDtoFromJSON,
    CreateGoalDtoToJSON,
    GetGoalDto,
    GetGoalDtoFromJSON,
    GetGoalDtoToJSON,
    UpdateGoalDto,
    UpdateGoalDtoFromJSON,
    UpdateGoalDtoToJSON,
} from '../models';

export interface CreateGoalRequest {
    createGoalDto: CreateGoalDto;
}

export interface EditGoalRequest {
    goalId: string;
    updateGoalDto: UpdateGoalDto;
}

export interface GetGoalRequest {
    goalId: string;
}

export interface ListGoalsRequest {
    startDate?: string;
    endDate?: string;
    responsibles?: Array<string>;
    id?: string;
}

/**
 * 
 */
export class GoalApi extends runtime.BaseAPI {

    /**
     * Criar meta
     */
    async createGoalRaw(requestParameters: CreateGoalRequest): Promise<runtime.ApiResponse<GetGoalDto>> {
        if (requestParameters.createGoalDto === null || requestParameters.createGoalDto === undefined) {
            throw new runtime.RequiredError('createGoalDto','Required parameter requestParameters.createGoalDto was null or undefined when calling createGoal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/goal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGoalDtoToJSON(requestParameters.createGoalDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGoalDtoFromJSON(jsonValue));
    }

    /**
     * Criar meta
     */
    async createGoal(requestParameters: CreateGoalRequest): Promise<GetGoalDto> {
        const response = await this.createGoalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Editar meta
     */
    async editGoalRaw(requestParameters: EditGoalRequest): Promise<runtime.ApiResponse<GetGoalDto>> {
        if (requestParameters.goalId === null || requestParameters.goalId === undefined) {
            throw new runtime.RequiredError('goalId','Required parameter requestParameters.goalId was null or undefined when calling editGoal.');
        }

        if (requestParameters.updateGoalDto === null || requestParameters.updateGoalDto === undefined) {
            throw new runtime.RequiredError('updateGoalDto','Required parameter requestParameters.updateGoalDto was null or undefined when calling editGoal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/goal/{goalId}`.replace(`{${"goalId"}}`, encodeURIComponent(String(requestParameters.goalId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGoalDtoToJSON(requestParameters.updateGoalDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGoalDtoFromJSON(jsonValue));
    }

    /**
     * Editar meta
     */
    async editGoal(requestParameters: EditGoalRequest): Promise<GetGoalDto> {
        const response = await this.editGoalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter meta pelo ID
     */
    async getGoalRaw(requestParameters: GetGoalRequest): Promise<runtime.ApiResponse<GetGoalDto>> {
        if (requestParameters.goalId === null || requestParameters.goalId === undefined) {
            throw new runtime.RequiredError('goalId','Required parameter requestParameters.goalId was null or undefined when calling getGoal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/goal/{goalId}`.replace(`{${"goalId"}}`, encodeURIComponent(String(requestParameters.goalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGoalDtoFromJSON(jsonValue));
    }

    /**
     * Obter meta pelo ID
     */
    async getGoal(requestParameters: GetGoalRequest): Promise<GetGoalDto> {
        const response = await this.getGoalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar metas
     */
    async listGoalsRaw(requestParameters: ListGoalsRequest): Promise<runtime.ApiResponse<Array<GetGoalDto>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.responsibles) {
            queryParameters['responsibles'] = requestParameters.responsibles;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/goal/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetGoalDtoFromJSON));
    }

    /**
     * Listar metas
     */
    async listGoals(requestParameters: ListGoalsRequest): Promise<Array<GetGoalDto>> {
        const response = await this.listGoalsRaw(requestParameters);
        return await response.value();
    }

}
