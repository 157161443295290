import React from 'react';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';

import { AiFillCloseCircle, AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai';
import { maskDate, maskMoney } from 'services/masks.service';

import { saleStatus } from 'services/enums.service';
import { HStack, Text } from '@chakra-ui/layout';

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({ data, setPagination, pagination, totalPages, onRowClick, ...rest }) => {
	const sales = data?.results?.map((response) => {
		const sale = JSON.parse(JSON.stringify({ response }));

		const date = new Date(sale.response?.created_at)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

		return {
			id: sale.response.id,
			description: sale.response.description,
			type: sale.response.type,
			total: sale.response.original_amount / 100,
			payer: sale.response.payer_name,
			formatted_pre_capture: sale.response.formatted_pre_capture,
			payment_type: sale.response.payment_type_description,
			status: sale.response.status,
			created_at:
				date &&
				`${maskDate(date[0] ? date[0].split('-').reverse().join('-') : '-/-/-')} às ${
					date[1] ? date[1]?.replace('Z', '').substr(0, 5) : '-:-'
				}`,
		};
	});

	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='status-title' column={column}>
						Status
					</HeaderTitle>
				),
				accessor: 'status',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						<HStack>
							<Text id={`${row.index}-${column.id}-icon-text`}>{iconStatusLiteral[value]}</Text>
							<Text id={`${row.index}-${column.id}-text`}>{saleStatus[value] ?? 'N/A'}</Text>
						</HStack>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='description-title' column={column}>
						Nº do Pedido
					</HeaderTitle>
				),
				accessor: 'description',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payer-title' column={column}>
						Pagador
					</HeaderTitle>
				),
				accessor: 'payer',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='formatted-pre-capture-title' column={column}>
						Tem Pré-Captura
					</HeaderTitle>
				),
				accessor: 'formatted_pre_capture',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`} className='cell-content__pre-capture'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-type-title' column={column}>
						Forma de pagamento
					</HeaderTitle>
				),
				accessor: 'payment_type',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='total-title' column={column}>
						Total
					</HeaderTitle>
				),
				accessor: 'total',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						R$ {maskMoney(value)}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='created-at-title' column={column}>
						Data de Criação
					</HeaderTitle>
				),
				accessor: 'created_at',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
		],
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			onRowClick={onRowClick}
			data={sales}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			variant={rest.variant || `striped`}
			pl={2}
			maxHeightTableWrapper={250}
		/>
	);
};
