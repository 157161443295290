/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRoleDto,
    GetRoleDtoFromJSON,
    GetRoleDtoFromJSONTyped,
    GetRoleDtoToJSON,
    GetUserSellerDto,
    GetUserSellerDtoFromJSON,
    GetUserSellerDtoFromJSONTyped,
    GetUserSellerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSellerUserDto
 */
export interface GetSellerUserDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerUserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerUserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerUserDto
     */
    type: GetSellerUserDtoTypeEnum;
    /**
     * 
     * @type {GetRoleDto}
     * @memberof GetSellerUserDto
     */
    role: GetRoleDto;
    /**
     * 
     * @type {string}
     * @memberof GetSellerUserDto
     */
    status: GetSellerUserDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSellerUserDto
     */
    created_at: string;
    /**
     * 
     * @type {GetUserSellerDto}
     * @memberof GetSellerUserDto
     */
    seller: GetUserSellerDto;
}

/**
* @export
* @enum {string}
*/
export enum GetSellerUserDtoTypeEnum {
    Merchant = 'merchant',
    Seller = 'seller',
    Backoffice = 'backoffice'
}/**
* @export
* @enum {string}
*/
export enum GetSellerUserDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending'
}

export function GetSellerUserDtoFromJSON(json: any): GetSellerUserDto {
    return GetSellerUserDtoFromJSONTyped(json, false);
}

export function GetSellerUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'email': json['email'],
        'type': json['type'],
        'role': GetRoleDtoFromJSON(json['role']),
        'status': json['status'],
        'created_at': json['created_at'],
        'seller': GetUserSellerDtoFromJSON(json['seller']),
    };
}

export function GetSellerUserDtoToJSON(value?: GetSellerUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'type': value.type,
        'role': GetRoleDtoToJSON(value.role),
        'status': value.status,
        'created_at': value.created_at,
        'seller': GetUserSellerDtoToJSON(value.seller),
    };
}


