import React from 'react';

import { Flex, Stack, FormControl } from '@chakra-ui/react';
import { Formik } from 'formik';

import * as yup from 'yup';

import FormInput from '../../components/Form/FormInput';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import FormLabel from '../../components/Form/FormLabel';
import Button from '../../components/Button';

import { Configuration, AuthApi } from '../../clients';

type ForgotPasswordModalProps = {
	afterSubmit: () => void;
};

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = (props: ForgotPasswordModalProps) => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const apiConfig = new Configuration({
		basePath: process.env.REACT_APP_BASE_URL,
	});

	const authApi = new AuthApi(apiConfig);

	const initialValues = {
		email: '',
	};

	const schema = yup.object().shape({
		email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
	});

	const handleFormSubmit = async (values) => {
		setIsLoading(true);

		try {
			await authApi.forgotPasswordRequest({
				forgotPasswordRequestDto: {
					email: values.email,
				},
			});
		} finally {
			setIsLoading(false);

			props.afterSubmit();
		}
	};

	return (
		<Flex width={`100%`} height={`100%`} flexDirection={`row`} alignItems={`center`} justifyContent={`center`}>
			<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Stack isInline spacing={5} p={8} bg={`white`} rounded={`md`}>
								<FormControl>
									<FormLabel id='email-label' htmlFor='email'>Informe o seu e-mail</FormLabel>
									<FormInput id='email-form-input' autoFocus name='email' />
									<FormErrorMessage id='forgot-password-modal-email-form-error-text' name='email' />
								</FormControl>
								<Flex flexDir='row' alignItems={`flex-end`}>
									<Button id='submit-forgot-password-button' type='submit' isLoading={isLoading} px={6}>
										Enviar
									</Button>
								</Flex>
							</Stack>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default ForgotPasswordModal;
