/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetPosSellerDto,
    GetPosSellerDtoFromJSON,
    GetPosSellerDtoFromJSONTyped,
    GetPosSellerDtoToJSON,
    GetSellerPosAcquirerDto,
    GetSellerPosAcquirerDtoFromJSON,
    GetSellerPosAcquirerDtoFromJSONTyped,
    GetSellerPosAcquirerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSellerPosDto
 */
export interface GetSellerPosDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerPosDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerPosDto
     */
    associated_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerPosDto
     */
    terminal_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerPosDto
     */
    status: GetSellerPosDtoStatusEnum;
    /**
     * 
     * @type {GetSellerPosAcquirerDto}
     * @memberof GetSellerPosDto
     */
    acquirer: GetSellerPosAcquirerDto;
    /**
     * 
     * @type {GetPosSellerDto}
     * @memberof GetSellerPosDto
     */
    seller: GetPosSellerDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerPosDto
     */
    is_android: boolean;
}

/**
* @export
* @enum {string}
*/
export enum GetSellerPosDtoStatusEnum {
    Active = 'active',
    Broken = 'broken',
    Canceled = 'canceled',
    Inventory = 'inventory',
    StockForRepair = 'stock_for_repair',
    Repair = 'repair',
    Lost = 'lost'
}

export function GetSellerPosDtoFromJSON(json: any): GetSellerPosDto {
    return GetSellerPosDtoFromJSONTyped(json, false);
}

export function GetSellerPosDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerPosDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'associated_at': json['associated_at'],
        'terminal_id': json['terminal_id'],
        'status': json['status'],
        'acquirer': GetSellerPosAcquirerDtoFromJSON(json['acquirer']),
        'seller': GetPosSellerDtoFromJSON(json['seller']),
        'is_android': json['is_android'],
    };
}

export function GetSellerPosDtoToJSON(value?: GetSellerPosDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'associated_at': value.associated_at,
        'terminal_id': value.terminal_id,
        'status': value.status,
        'acquirer': GetSellerPosAcquirerDtoToJSON(value.acquirer),
        'seller': GetPosSellerDtoToJSON(value.seller),
        'is_android': value.is_android,
    };
}


