/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentLinkPayerAddressDto
 */
export interface PaymentLinkPayerAddressDto {
    /**
     * Cidade
     * @type {string}
     * @memberof PaymentLinkPayerAddressDto
     */
    city: string;
    /**
     * Número
     * @type {string}
     * @memberof PaymentLinkPayerAddressDto
     */
    number: string;
    /**
     * CEP
     * @type {string}
     * @memberof PaymentLinkPayerAddressDto
     */
    zipcode: string;
    /**
     * Estado
     * @type {string}
     * @memberof PaymentLinkPayerAddressDto
     */
    state: string;
    /**
     * Rua
     * @type {string}
     * @memberof PaymentLinkPayerAddressDto
     */
    street: string;
    /**
     * Complemento
     * @type {string}
     * @memberof PaymentLinkPayerAddressDto
     */
    complement?: string;
    /**
     * Bairro
     * @type {string}
     * @memberof PaymentLinkPayerAddressDto
     */
    district: string;
}

export function PaymentLinkPayerAddressDtoFromJSON(json: any): PaymentLinkPayerAddressDto {
    return PaymentLinkPayerAddressDtoFromJSONTyped(json, false);
}

export function PaymentLinkPayerAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentLinkPayerAddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': json['city'],
        'number': json['number'],
        'zipcode': json['zipcode'],
        'state': json['state'],
        'street': json['street'],
        'complement': !exists(json, 'complement') ? undefined : json['complement'],
        'district': json['district'],
    };
}

export function PaymentLinkPayerAddressDtoToJSON(value?: PaymentLinkPayerAddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'number': value.number,
        'zipcode': value.zipcode,
        'state': value.state,
        'street': value.street,
        'complement': value.complement,
        'district': value.district,
    };
}


