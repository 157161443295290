import React from 'react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from 'containers/SimpleTable';
import { maskMoney } from 'services/masks.service';
import Text from 'components/Text';

type HeaderTitleStyledProps = HeaderTitleProps & {
	id: string;
};

const HeaderTitleStyled: React.FC<HeaderTitleStyledProps> = (props: HeaderTitleStyledProps) => {
	return (
		<HeaderTitle {...props} justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			<Text id={`text-${props.id}`}>{props.children}</Text>
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='financial-success-sale-header-title-styled' column={column}>
						Aprovadas
					</HeaderTitleStyled>
				),
				accessor: 'financial_success_sale',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='financial-pending-sale-header-title-styled' column={column}>
						Pendentes
					</HeaderTitleStyled>
				),
				accessor: 'financial_pending_sale',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='financial-canceled-sale-header-title-styled' column={column}>
						Canceladas
					</HeaderTitleStyled>
				),
				accessor: 'financial_canceled_sale',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
		],
		[]
	);

	return <SimpleTable columns={columns} data={[data]} variant='unstyled' fillParent />;
};
