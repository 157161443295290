/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SalesHistoryTablePermissions,
    SalesHistoryTablePermissionsFromJSON,
    SalesHistoryTablePermissionsFromJSONTyped,
    SalesHistoryTablePermissionsToJSON,
    SettlementBatchSalesTable,
    SettlementBatchSalesTableFromJSON,
    SettlementBatchSalesTableFromJSONTyped,
    SettlementBatchSalesTableToJSON,
} from './';

/**
 * 
 * @export
 * @interface MerchantTablesPermissions
 */
export interface MerchantTablesPermissions {
    /**
     * 
     * @type {SalesHistoryTablePermissions}
     * @memberof MerchantTablesPermissions
     */
    sales_history?: SalesHistoryTablePermissions;
    /**
     * 
     * @type {SettlementBatchSalesTable}
     * @memberof MerchantTablesPermissions
     */
    settlement_batch_sale?: SettlementBatchSalesTable;
}

export function MerchantTablesPermissionsFromJSON(json: any): MerchantTablesPermissions {
    return MerchantTablesPermissionsFromJSONTyped(json, false);
}

export function MerchantTablesPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantTablesPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sales_history': !exists(json, 'sales_history') ? undefined : SalesHistoryTablePermissionsFromJSON(json['sales_history']),
        'settlement_batch_sale': !exists(json, 'settlement_batch_sale') ? undefined : SettlementBatchSalesTableFromJSON(json['settlement_batch_sale']),
    };
}

export function MerchantTablesPermissionsToJSON(value?: MerchantTablesPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sales_history': SalesHistoryTablePermissionsToJSON(value.sales_history),
        'settlement_batch_sale': SettlementBatchSalesTableToJSON(value.settlement_batch_sale),
    };
}


