/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSellerCredentialDto
 */
export interface CreateSellerCredentialDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSellerCredentialDto
     */
    acquirer_code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerCredentialDto
     */
    api_key?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerCredentialDto
     */
    client_id: string;
}

export function CreateSellerCredentialDtoFromJSON(json: any): CreateSellerCredentialDto {
    return CreateSellerCredentialDtoFromJSONTyped(json, false);
}

export function CreateSellerCredentialDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSellerCredentialDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acquirer_code': json['acquirer_code'],
        'api_key': !exists(json, 'api_key') ? undefined : json['api_key'],
        'client_id': json['client_id'],
    };
}

export function CreateSellerCredentialDtoToJSON(value?: CreateSellerCredentialDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acquirer_code': value.acquirer_code,
        'api_key': value.api_key,
        'client_id': value.client_id,
    };
}


