import React from 'react';

import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { Box, FormControl, Heading } from '@chakra-ui/react';
import ResponsiblesGoalTable, { responsibleGoalType } from './ResponsiblesGoalTable';
import NewModal from 'containers/NewModal';
import FormCurrencyInput from '../../../components/Form/FormCurrencyInput';
import FormMonthPickerInput from '../../../components/Form/FormMonthPickerInput';
import Button from '../../../components/Button';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';

import { getApiAuthConfig } from '../../../services/api.service';
import yup from '../../../services/yup.service';
import { round } from '../../../services/math.service';

import { CreateGoalDto, GetResponsibleDto, GoalApi, UpdateGoalDto } from '../../../clients';
import FormLabel from '../../../components/Form/FormLabel';

type GoalModalProps = {
	isLoading: boolean;
	setIsLoading: (loading: boolean) => void;
	isModalOpen: boolean;
	setIsModalOpen: (open: boolean) => void;
	createReference: boolean;
	responsibles: Array<GetResponsibleDto>;
	goalIdReference: string;
	setGoalIdReference: (id: string) => void;
	handleSubmit: () => void;
};

const GoalModal: React.FC<GoalModalProps> = ({
	isLoading,
	setIsLoading,
	isModalOpen,
	setIsModalOpen,
	createReference,
	responsibles,
	goalIdReference,
	setGoalIdReference,
	handleSubmit,
}) => {
	const { addToast } = useToasts();

	const [selectedDate, setSelectedDate] = React.useState(new Date());
	const [responsiblesGoal, setResponsiblesGoal] = React.useState({});

	const apiConfig = getApiAuthConfig();
	const goalApi = new GoalApi(apiConfig);

	const initialValues = {
		reference_date: '',
		business_days: '',
		overall_goal: '',
		responsibles: [],
	};

	const handleFormSubmit = async (values) => {
		setIsLoading(true);

		const parsedResponsibleList = Object.entries(responsiblesGoal as responsibleGoalType).map(([key, value]) => {
			const { name, goal, type } = value;
			const parsedResponsible = {
				id: key,
				name: name,
				type: type,
				goal: round(Number(goal.split('R$')[1].trim().replace('.', '').replace(',', '.')) * 100),
			};
			return parsedResponsible;
		});

		if (createReference) {
			try {
				const createGoalRequest: CreateGoalDto = {
					reference_date: selectedDate.toISOString(),
					business_days: values.business_days,
					overall_goal: round(values.overall_goal * 100),
					responsibles: parsedResponsibleList,
				};

				await goalApi.createGoal({ createGoalDto: createGoalRequest });

				setSelectedDate(new Date());
				setResponsiblesGoal({});
			} catch (error) {
				addToast('Erro ao criar meta.', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}

		if (!createReference) {
			try {
				const updateGoalRequest: UpdateGoalDto = {
					reference_date: selectedDate.toISOString(),
					business_days: values.business_days,
					overall_goal: round(values.overall_goal * 100),
					responsibles: parsedResponsibleList,
				};

				await goalApi.editGoal({
					goalId: goalIdReference,
					updateGoalDto: updateGoalRequest,
				});

				setSelectedDate(new Date());
				setResponsiblesGoal({});
				setGoalIdReference('');
			} catch (error) {
				addToast('Erro ao editar meta.', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}

		setIsLoading(false);
	};

	const validationGoalSchema = yup.object().shape({
		reference_date: yup.date(),
		business_days: yup.number().required('Campo Obrigatório').integer('Deve ser um número inteiro').typeError('Apenas números são válidos'),
		overall_goal: yup.number().required('Campo Obrigatório'),
	});

	return (
		<NewModal
			isOpen={isModalOpen}
			onClose={() => {
				setIsModalOpen(false);
				handleSubmit();
			}}
			showCloseButton
		>
			<Box padding={6}>
				<Heading fontSize='lg' mb={4} color='primary'>
					{createReference ? 'Criar Meta' : 'Editar Meta'}
				</Heading>

				<Formik initialValues={initialValues} onSubmit={handleFormSubmit} validationSchema={validationGoalSchema}>
					{({ handleSubmit, isValid }) => {
						return (
							<form onSubmit={handleSubmit}>
								<FormControl isRequired>
									<FormLabel id='reference-date-label'>Data de Referência</FormLabel>
									<FormMonthPickerInput id='reference-date-month-picker-input' selectedDate={selectedDate} setSelectedDate={setSelectedDate} name='reference_date' />
								</FormControl>

								<FormControl mt={2} isRequired>
									<FormLabel id='business-days-label'>Dias Úteis</FormLabel>
									<FormInput id='business-days-form-input' name='business_days' />
									<FormErrorMessage id='goal-business-days-form-error-text' name='business_days' />
								</FormControl>

								<FormControl mt={2} isRequired>
									<FormLabel id='overall-goal-label'>Meta Geral</FormLabel>
									<FormCurrencyInput id='goal-overall-form-currency-input' name='overall_goal' />
									<FormErrorMessage id='goal-overall-goal-form-error-text' name='overall_goal' />
								</FormControl>

								<FormControl mt={2} isRequired>
									<ResponsiblesGoalTable
										responsibles={responsibles}
										createReference={createReference}
										responsiblesGoal={responsiblesGoal}
										setResponsiblesGoal={setResponsiblesGoal}
									/>
								</FormControl>

								<FormControl display='flex' justifyContent='center' mt='4'>
									<Button
										id='submit-goal-button'
										size='lg'
										type='submit'
										disabled={!isValid}
										bgColor={'primary'}
										isLoading={isLoading}
										className='button__submit-goal'
									>
										{createReference ? 'Criar Meta' : 'Editar Meta'}
									</Button>
								</FormControl>
							</form>
						);
					}}
				</Formik>
			</Box>
		</NewModal>
	);
};

export default GoalModal;
