import React from 'react';

import { Checkbox as DefaultCheckbox, CheckboxProps } from '@chakra-ui/react';


type CustomCheckboxProps = CheckboxProps & {
	id: string;
};


const Checkbox: React.FC<CustomCheckboxProps> = React.forwardRef((props: CustomCheckboxProps, ref) => (
	<DefaultCheckbox {...props} rounded={`md`} />
));

export default Checkbox;