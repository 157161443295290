/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateCostPlanOptionsDto,
    CreateCostPlanOptionsDtoFromJSON,
    CreateCostPlanOptionsDtoFromJSONTyped,
    CreateCostPlanOptionsDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateCostPlanDto
 */
export interface CreateCostPlanDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlanDto
     */
    description: string;
    /**
     * 
     * @type {Array<CreateCostPlanOptionsDto>}
     * @memberof CreateCostPlanDto
     */
    options?: Array<CreateCostPlanOptionsDto>;
}

export function CreateCostPlanDtoFromJSON(json: any): CreateCostPlanDto {
    return CreateCostPlanDtoFromJSONTyped(json, false);
}

export function CreateCostPlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCostPlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(CreateCostPlanOptionsDtoFromJSON)),
    };
}

export function CreateCostPlanDtoToJSON(value?: CreateCostPlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(CreateCostPlanOptionsDtoToJSON)),
    };
}


