/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateMerchantUserDto
 */
export interface UpdateMerchantUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantUserDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantUserDto
     */
    role_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantUserDto
     */
    status?: UpdateMerchantUserDtoStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateMerchantUserDtoStatusEnum {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending'
}

export function UpdateMerchantUserDtoFromJSON(json: any): UpdateMerchantUserDto {
    return UpdateMerchantUserDtoFromJSONTyped(json, false);
}

export function UpdateMerchantUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMerchantUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'role_id': !exists(json, 'role_id') ? undefined : json['role_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function UpdateMerchantUserDtoToJSON(value?: UpdateMerchantUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'role_id': value.role_id,
        'status': value.status,
    };
}


