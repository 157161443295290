/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizeTransactionDTO,
    AuthorizeTransactionDTOFromJSON,
    AuthorizeTransactionDTOToJSON,
    PaymentPosRequest,
    PaymentPosRequestFromJSON,
    PaymentPosRequestToJSON,
} from '../models';

export interface AuthorizeRequest {
    authorizeTransactionDTO: AuthorizeTransactionDTO;
}

export interface CancelRequest {
    transactionId: string;
}

export interface CelcoinPosRequest {
    sellerId: string;
    paymentPosRequest: PaymentPosRequest;
}

export interface GetPaymentRequest {
    transactionId: string;
}

/**
 * 
 */
export class CelcoinApi extends runtime.BaseAPI {

    /**
     * Autoriza um pagamento na celcoin
     */
    async authorizeRaw(requestParameters: AuthorizeRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.authorizeTransactionDTO === null || requestParameters.authorizeTransactionDTO === undefined) {
            throw new runtime.RequiredError('authorizeTransactionDTO','Required parameter requestParameters.authorizeTransactionDTO was null or undefined when calling authorize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/celcoin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizeTransactionDTOToJSON(requestParameters.authorizeTransactionDTO),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Autoriza um pagamento na celcoin
     */
    async authorize(requestParameters: AuthorizeRequest): Promise<object> {
        const response = await this.authorizeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cancela um pagamento.
     */
    async cancelRaw(requestParameters: CancelRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling cancel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/celcoin/payment/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Cancela um pagamento.
     */
    async cancel(requestParameters: CancelRequest): Promise<object> {
        const response = await this.cancelRaw(requestParameters);
        return await response.value();
    }

    /**
     * Processa todos aguardando pagamento
     */
    async celcoinCashinRaw(): Promise<runtime.ApiResponse<Array<object>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/celcoin/process/cash_in`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Processa todos aguardando pagamento
     */
    async celcoinCashin(): Promise<Array<object>> {
        const response = await this.celcoinCashinRaw();
        return await response.value();
    }

    /**
     * Criar novo pagamento celcoin via Pos
     */
    async celcoinPosRaw(requestParameters: CelcoinPosRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling celcoinPos.');
        }

        if (requestParameters.paymentPosRequest === null || requestParameters.paymentPosRequest === undefined) {
            throw new runtime.RequiredError('paymentPosRequest','Required parameter requestParameters.paymentPosRequest was null or undefined when calling celcoinPos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/celcoin/pos/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentPosRequestToJSON(requestParameters.paymentPosRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Criar novo pagamento celcoin via Pos
     */
    async celcoinPos(requestParameters: CelcoinPosRequest): Promise<object> {
        const response = await this.celcoinPosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Busca um status de pagamento na celcoin
     */
    async getPaymentRaw(requestParameters: GetPaymentRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling getPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/celcoin/payment/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Busca um status de pagamento na celcoin
     */
    async getPayment(requestParameters: GetPaymentRequest): Promise<object> {
        const response = await this.getPaymentRaw(requestParameters);
        return await response.value();
    }

}
