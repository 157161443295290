import React from 'react';

import { HStack, Text } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillClockCircle } from 'react-icons/ai';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';
import { formatWithoutTimezone } from '../../../services/date.service';

import { maskMoney } from '../../../services/masks.service';
import { saleStatus } from '../../../services/enums.service';

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_capture: <AiFillClockCircle color='#FFA547' size={16} />,
	in_progress_capture: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
	waiting_confirmation: <AiFillClockCircle color='#FFA547' size={16} />,
	waiting_cashIn: <AiFillClockCircle color='#FFA547' size={16} />,
	expired: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({ data, setPagination, pagination, totalPages, onRowClick, afterCancel, seller }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='status-header-title' column={column}>
						Status
					</HeaderTitle>
				),
				accessor: 'status',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} align='left' className='cell-content__pendent'>
						<HStack>
							<Text id='status-icon-status-literal-value-text'>{iconStatusLiteral[value]}</Text>
							<Text i='status-value-text'>{saleStatus[value]}</Text>
						</HStack>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='description-header-title' column={column}>
						N° do Pedido
					</HeaderTitle>
				),
				accessor: 'description',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} w='200px' color={`darkGrey`} className='cell-content__order-number'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payer-name-header-title' column={column}>
						Pagador
					</HeaderTitle>
				),
				accessor: 'payer_name',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__payer'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='created-at-header-title' column={column}>
						Data de Criação
					</HeaderTitle>
				),
				accessor: 'created_at',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__creation-date'>
						{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-day-header-title' column={column}>
						Data de Pagamento
					</HeaderTitle>
				),
				accessor: 'payment_day',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__payer'>
						{value && formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='updated-at-header-title' column={column}>
						Data de Pagamento do Titulo
					</HeaderTitle>
				),
				accessor: 'updated_at',
				Cell: ({
					cell: {
						value,
						row: { index, original },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__payment-date'>
						{original.status === 'succeeded' && value && formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-type-description-header-title' column={column}>
						Forma de Pagamento
					</HeaderTitle>
				),
				accessor: 'payment_type_description',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__payment-form'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='amount-header-title' column={column}>
						Valor do Título
					</HeaderTitle>
				),
				accessor: 'amount',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__total'>{`R$ ${maskMoney(
						value / 100
					)}`}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='original-amount-header-title' column={column}>
						Valor Total Pago
					</HeaderTitle>
				),
				accessor: 'original_amount',
				Cell: ({
					cell: {
						value,
						row: { index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__total'>{`R$ ${maskMoney(
						value / 100
					)}`}</CellContent>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			data={data?.results ?? []}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			onRowClick={onRowClick}
			pl={2}
			isLimited
			maxHeightTableWrapper={450}
		/>
	);
};
