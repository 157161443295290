/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAddressDto,
    GetAddressDtoFromJSON,
    GetAddressDtoFromJSONTyped,
    GetAddressDtoToJSON,
    GetPayerSellerDto,
    GetPayerSellerDtoFromJSON,
    GetPayerSellerDtoFromJSONTyped,
    GetPayerSellerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPayerDto
 */
export interface GetPayerDto {
    /**
     * 
     * @type {string}
     * @memberof GetPayerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayerDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayerDto
     */
    created_at: string;
    /**
     * 
     * @type {GetAddressDto}
     * @memberof GetPayerDto
     */
    address: GetAddressDto;
    /**
     * 
     * @type {string}
     * @memberof GetPayerDto
     */
    phone: string;
    /**
     * 
     * @type {GetPayerSellerDto}
     * @memberof GetPayerDto
     */
    seller: GetPayerSellerDto;
}

export function GetPayerDtoFromJSON(json: any): GetPayerDto {
    return GetPayerDtoFromJSONTyped(json, false);
}

export function GetPayerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPayerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'document': json['document'],
        'email': json['email'],
        'created_at': json['created_at'],
        'address': GetAddressDtoFromJSON(json['address']),
        'phone': json['phone'],
        'seller': GetPayerSellerDtoFromJSON(json['seller']),
    };
}

export function GetPayerDtoToJSON(value?: GetPayerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'document': value.document,
        'email': value.email,
        'created_at': value.created_at,
        'address': GetAddressDtoToJSON(value.address),
        'phone': value.phone,
        'seller': GetPayerSellerDtoToJSON(value.seller),
    };
}


