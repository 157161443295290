/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SplitsDto,
    SplitsDtoFromJSON,
    SplitsDtoFromJSONTyped,
    SplitsDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SplitRulesDto
 */
export interface SplitRulesDto {
    /**
     * 
     * @type {number}
     * @memberof SplitRulesDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof SplitRulesDto
     */
    main_seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof SplitRulesDto
     */
    main_seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof SplitRulesDto
     */
    main_seller_document: string;
    /**
     * 
     * @type {string}
     * @memberof SplitRulesDto
     */
    main_seller_code: string;
    /**
     * 
     * @type {string}
     * @memberof SplitRulesDto
     */
    main_sale_id: string;
    /**
     * 
     * @type {Array<SplitsDto>}
     * @memberof SplitRulesDto
     */
    splits: Array<SplitsDto>;
}

export function SplitRulesDtoFromJSON(json: any): SplitRulesDto {
    return SplitRulesDtoFromJSONTyped(json, false);
}

export function SplitRulesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SplitRulesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'main_seller_id': json['main_seller_id'],
        'main_seller_name': json['main_seller_name'],
        'main_seller_document': json['main_seller_document'],
        'main_seller_code': json['main_seller_code'],
        'main_sale_id': json['main_sale_id'],
        'splits': ((json['splits'] as Array<any>).map(SplitsDtoFromJSON)),
    };
}

export function SplitRulesDtoToJSON(value?: SplitRulesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'main_seller_id': value.main_seller_id,
        'main_seller_name': value.main_seller_name,
        'main_seller_document': value.main_seller_document,
        'main_seller_code': value.main_seller_code,
        'main_sale_id': value.main_sale_id,
        'splits': ((value.splits as Array<any>).map(SplitsDtoToJSON)),
    };
}


