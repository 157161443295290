/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMerchantUserDto
 */
export interface CreateMerchantUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantUserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantUserDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantUserDto
     */
    role_id: string;
}

export function CreateMerchantUserDtoFromJSON(json: any): CreateMerchantUserDto {
    return CreateMerchantUserDtoFromJSONTyped(json, false);
}

export function CreateMerchantUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMerchantUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'password': json['password'],
        'email': json['email'],
        'role_id': json['role_id'],
    };
}

export function CreateMerchantUserDtoToJSON(value?: CreateMerchantUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'password': value.password,
        'email': value.email,
        'role_id': value.role_id,
    };
}


