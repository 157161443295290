/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePosPasswordDto
 */
export interface UpdatePosPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePosPasswordDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePosPasswordDto
     */
    old_password: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePosPasswordDto
     */
    seller_id: string;
}

export function UpdatePosPasswordDtoFromJSON(json: any): UpdatePosPasswordDto {
    return UpdatePosPasswordDtoFromJSONTyped(json, false);
}

export function UpdatePosPasswordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePosPasswordDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': json['password'],
        'old_password': json['old_password'],
        'seller_id': json['seller_id'],
    };
}

export function UpdatePosPasswordDtoToJSON(value?: UpdatePosPasswordDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'old_password': value.old_password,
        'seller_id': value.seller_id,
    };
}


