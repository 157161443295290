const daysInWeek = [
	{ day: 0, label: 'Domingo' },
	{ day: 1, label: 'Segunda' },
	{ day: 2, label: 'Terça' },
	{ day: 3, label: 'Quarta' },
	{ day: 4, label: 'Quinta' },
	{ day: 5, label: 'Sexta' },
	{ day: 6, label: 'Sábado' },
];

export default daysInWeek;