import React from 'react';

import { Field } from 'formik';
import { InputProps } from '@chakra-ui/react';

import { NumberInputProps } from '../NumberInput';

import Input from '../Input';

type FormInputProps = InputProps &
	NumberInputProps & {
		as?: React.FC;
		disabled?: boolean;
		id: string
	};

const FormInput: React.FC<FormInputProps> = React.forwardRef((props: FormInputProps, ref) => {
	let Component = Input;

	if (props.as) {
		Component = props.as;
	}
	const { as, ...fieldProps } = props;

	return (
		<Field {...fieldProps}>
			{({ form, field }) => {
				return <Component {...field} onBlur={form.handleBlur} borderColor='darkGrey' {...fieldProps} />;
			}}
		</Field>
	);
});

export default FormInput;
