import { Flex, Heading, Box, Spacer } from '@chakra-ui/react';
import React from 'react';
import { useToasts } from 'react-toast-notifications';

import { CreatePaymentDto, GetPaymentLinkSellerDto, PaymentsApi, CreatePaymentRequest } from 'clients';

import Modal from 'containers/Modal';

import Button from 'components/Button';

import { getApiDefaultConfig } from '../../../services/api.service';
import { roundMoneyToSend } from '../../../services/masks.service';
import Text from '../../../components/Text';

type CreditCardModalProps = {
	confirmPayment: boolean;
	isLoading: boolean;
	hasPreCapture?: boolean;
	setConfirmPayment(open: boolean): void;
	setIsLoading: (loading: boolean) => void;
	saleInformation: any;
	creditCheckout: any;
	simulatedAmount: any;
	seller: GetPaymentLinkSellerDto;
	setLinkPayed(payed: boolean): void;
};
const CreditCardModal: React.FC<CreditCardModalProps> = ({
	confirmPayment,
	setConfirmPayment,
	isLoading,
	hasPreCapture,
	creditCheckout,
	saleInformation,
	setIsLoading,
	simulatedAmount,
	seller,
	setLinkPayed,
}) => {
	const { addToast } = useToasts();

	const handlePayment = async () => {
		if (!simulatedAmount.simulated) return;
		setIsLoading(true);

		const apiConfig = getApiDefaultConfig();

		try {
			const createPaymentDto: CreatePaymentDto = {
				amount_cents: roundMoneyToSend(simulatedAmount.simulated / 100),
				description: saleInformation.description,
				card_attributes: {
					holder_name: creditCheckout.holder_name,
					number: creditCheckout.number,
					expiration_month: creditCheckout.expiration_month,
					expiration_year: creditCheckout.expiration_year,
					security_code: creditCheckout.security_code,
				},
				installment_plan: {
					number_installments: simulatedAmount.installments,
				},
				customer: {
					first_name: saleInformation.first_name,
					last_name: saleInformation.last_name,
					document: saleInformation.document,
				},
				sale_id: saleInformation.id,
				protest_link: true,
				amount_fee: saleInformation.fee,
			};

			if (!createPaymentDto.customer.document) {
				delete createPaymentDto.customer.document;
			}

			if (typeof hasPreCapture !== 'undefined') {
				createPaymentDto.pre_capture = hasPreCapture;
			}
			const createPaymentRequest: CreatePaymentRequest = {
				sellerId: seller.id,
				createPaymentDto,
			};

			const paymentsApi = new PaymentsApi(apiConfig);
			const response = await paymentsApi.createPayment(createPaymentRequest);

			if (response.resultCode === 'Authorised') {
				addToast('Pagamento realizado com sucesso!', {
					appearance: 'success',
					autoDismiss: true,
				});
				setConfirmPayment(!confirmPayment);
				setLinkPayed(true);
				return;
			}
		} catch (err) {
			addToast('Falha no pagamento! Verifique os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal isOpen={confirmPayment}>
			<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
				<Flex p={8} flexDirection='column'>
					<Heading fontSize='lg' paddingBottom={8}>
						Confirmação de pagamento
					</Heading>

					<Box mt={2} mb={5}>
						<Text id='transation-parcela-text' align='center'>
							Esta transação estará identificada na sua fatura como <strong>ParcelaExpress.</strong>
						</Text>
					</Box>

					<Flex flexDirection='row' alignItems='center' fontSize='md' px={3} pb={2}>
						<Spacer />

						<Button
							id='confirm-payment-button'
							size='lg'
							color='white'
							backgroundColor='primary'
							isFullWidth
							onClick={async () => {
								await handlePayment();
							}}
							isLoading={isLoading}
						>
							Confirmar Pagamento!
						</Button>
					</Flex>
				</Flex>
			</Box>
		</Modal>
	);
};

export default CreditCardModal;
