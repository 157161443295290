/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CardDTO
 */
export interface CardDTO {
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    holder_name: string;
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    expiration_month: string;
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    expiration_year: string;
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    security_code: string;
}

export function CardDTOFromJSON(json: any): CardDTO {
    return CardDTOFromJSONTyped(json, false);
}

export function CardDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'holder_name': json['holder_name'],
        'number': json['number'],
        'expiration_month': json['expiration_month'],
        'expiration_year': json['expiration_year'],
        'security_code': json['security_code'],
    };
}

export function CardDTOToJSON(value?: CardDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'holder_name': value.holder_name,
        'number': value.number,
        'expiration_month': value.expiration_month,
        'expiration_year': value.expiration_year,
        'security_code': value.security_code,
    };
}


