import React from 'react';

import { HStack, Box } from '@chakra-ui/react';
import { maskCpfOrCnpj, maskPhoneNumber } from '../../../services/masks.service';

import Paper from 'containers/Paper';
import Text from 'components/Text';

export default (data) => {
	return (
		<>
			<Paper id='payer-data-paper-text' title='Dados Cadastrados do Pagador'>
				<HStack spacing='10' alignItems='flex-start' justifyContent='space-between'>
					<Box>
						<Text id='payer-name-text' fontWeight='bold'>
							Nome Completo
						</Text>
						<Text id='data-payer-name-text'>{data.payer_name || '-'}</Text>
					</Box>
					<Box>
						<Text id='payer-document-text' fontWeight='bold'>
							CPF/CNPJ
						</Text>
						<Text id='data-payer-document-text'>{data.payer_document ? maskCpfOrCnpj(data.payer_document) : 'N/A'}</Text>
					</Box>
					<Box>
						<Text id='payer-phone-text' fontWeight='bold'>
							Telefone
						</Text>
						<Text id='data-payer-phone-text'>{data.payer_phone ? maskPhoneNumber(data.payer_phone) : 'N/A'}</Text>
					</Box>
					<Box>
						<Text id='email-payer-text' fontWeight='bold'>Email</Text>
						<Text id='data-payer-email-text'>{data.payer_email || '-'}</Text>
					</Box>
				</HStack>
			</Paper>
			<Paper id='address-paper-text' title='Endereço'>
				<HStack spacing='10' alignItems='flex-start' justifyContent='space-between'>
					<Box>
						<Text id='payer-zipcode-text' fontWeight='bold'>CEP</Text>
						<Text id='data-payer-zipcode-text' >{data?.payer_address?.zipcode || '-'}</Text>
					</Box>
					<Box>
						<Text id='payer-street-text' fontWeight='bold'>Rua</Text>
						<Text id='data-payer-street-text'>{data?.payer_address?.street || '-'}</Text>
					</Box>
					<Box>
						<Text id='payer-number-text' fontWeight='bold'>Número</Text>
						<Text id='data-payer-number-text'>{data?.payer_address?.number || '-'}</Text>
					</Box>
					<Box>
						<Text id='payer-complement-text' fontWeight='bold'>Complemento</Text>
						<Text id='data-payer-complement-text'>{data?.payer_address?.complement || 'N/A'}</Text>
					</Box>
					<Box>
						<Text id='payer-district-text' fontWeight='bold'>Bairro</Text>
						<Text id='data-payer-district-text'>{data?.payer_address?.district || '-'}</Text>
					</Box>
					<Box>
						<Text id='payer-address-city-text' fontWeight='bold'>Cidade</Text>
						<Text id='data-payer-address-city-text'>{data?.payer_address?.city || '-'}</Text>
					</Box>
					<Box>
						<Text id='payer-state-text' fontWeight='bold'>Estado</Text>
						<Text id='data-payer-state-text'>{data?.payer_address?.state || '-'}</Text>
					</Box>
				</HStack>
			</Paper>
		</>
	);
};
