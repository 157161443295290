import React from 'react';

import { Flex, Box, Image, Stack, Alert, AlertIcon, Center } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

import * as variables from '../../config/variables';

import {
	ProtestLinksApi,
	GetProtestLinkDto,
	GetSimulationDto,
	SimulationApi,
	ListSimulationRequest,
	UpdateProtestLinkRequest,
} from '../../clients';

import { getApiDefaultConfig, parseExceptionMessage } from '../../services/api.service';
import { maskMoney, unmaskCpfOrCnpj } from '../../services/masks.service';
import { CheckoutSaleInformationType } from './types/checkout.type';
import { useTheme } from '../../contexts/ThemeProvider';

import TabsWrapper from './containers/TabsWrapper';
import Button from '../../components/Button';
import Loader from '../../containers/Loader';
import Text from '../../components/Text';

const ProtestLinkCheckout: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [protestLinkData, setProtestLinkData] = React.useState<GetProtestLinkDto | undefined>(undefined);
	const [simulationValues, setSimulationValues] = React.useState<Array<GetSimulationDto>>([]);
	const [saleInformation, setSaleInformation] = React.useState<CheckoutSaleInformationType | undefined>(undefined);
	const [linkPayed, setLinkPayed] = React.useState(false);
	const [linkExpiredPaid, setLinkExpiredPaid] = React.useState(false);

	const { token } = useParams();
	const { updateTheme } = useTheme();

	const apiConfig = getApiDefaultConfig();
	const paymentLinkApi = new ProtestLinksApi(apiConfig);
	const simulationApi = new SimulationApi(apiConfig);

	const fetchProtestLinkData = async (filters = {}) => {
		setProtestLinkData(undefined);
		setSimulationValues([]);

		try {
			setIsLoading(true);

			const response = await paymentLinkApi.getProtestLinkByToken({ token });

			setProtestLinkData(response);
			updateTheme(response.theme);

			const listSimulationRequest: ListSimulationRequest = {
				sellerId: response.seller.id,
				amountCents: response.amount,
				fee: response.fee,
			};

			const simulationResponse = await simulationApi.listSimulation(listSimulationRequest);

			setSimulationValues(simulationResponse);

			setSaleInformation({
				id: response.sale.id,
				description: response.description,
				first_name: response.payer.name.split(' ')[0],
				last_name: response.payer.name.split(' ').slice(1).join(' '),
				document: response.payer.document ? unmaskCpfOrCnpj(response.payer.document) : null,
				email: response.email,
				fee: response.fee,
			});
		} catch (e) {
			const errorMessage = await parseExceptionMessage(e, 'Link inválido');

			if (errorMessage === 'Paid link') {
				setLinkExpiredPaid(true);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const submitReceiptEmail = async () => {
		try {
			setIsLoading(true);
			await paymentLinkApi.sentProtestLinkReceiptMail({ token });

			setLinkExpiredPaid(false);
			setLinkPayed(true);
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchProtestLinkData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (!protestLinkData) return;

		(async function () {
			const requestParams: UpdateProtestLinkRequest = {
				protestLinkId: protestLinkData.id,
				updateProtestLinkDto: { expiresIn: true },
			};

			await paymentLinkApi.updateProtestLink(requestParams);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [linkPayed]);

	if ((!isLoading && !protestLinkData && !linkExpiredPaid) || linkPayed) {
		return (
			<Flex w={`100%`} h={`100%`} justifyContent={`center`}>
				<Stack w={`40%`} spacing={3} mt={20}>
					<Flex flexDir={`column`} alignItems={`center`}>
						<Image src={protestLinkData?.logo.url || variables.logoUrl} w={130} />
					</Flex>
					<Flex flexDir={`column`}>
						{!linkPayed ? (
							<Alert mt={10} status='error'>
								<AlertIcon />
								<Text id='invalid-link'>Link inválido.</Text>
							</Alert>
						) : (
							<Alert mt={10} status='success'>
								<AlertIcon />
								<Text id='operation-success-text'>Operação efetuada com sucesso!</Text>
							</Alert>
						)}
					</Flex>
				</Stack>
			</Flex>
		);
	}

	if (linkExpiredPaid && !isLoading) {
		return (
			<Flex w={`100%`} h={`100%`} justifyContent={`center`}>
				<Stack w={`40%`} spacing={3} mt={20}>
					<Flex flexDir={`column`} alignItems={`center`}>
						<Image src={protestLinkData?.logo.url} w={130} />
					</Flex>
					<Flex flexDir={`column`}>
						<Alert mt={10} status='success'>
							<AlertIcon />
							<Text id='payment-success-text'>Pagamento já efetuado com sucesso!</Text>
						</Alert>
						<Flex justifyContent={`center`} mt={3}>
							<Button id='resend-receipt-button' onClick={() => submitReceiptEmail()}>
								Reenviar recibo
							</Button>
						</Flex>
					</Flex>
				</Stack>
			</Flex>
		);
	}

	if (isLoading) {
		return <Loader isOpen={true} />;
	}

	return (
		<Flex flexDirection={`column`} w={`100%`} h={`100%`} overflowY={['scroll', 'hidden']}>
			{!isLoading && protestLinkData && simulationValues && saleInformation && (
				<Flex flexDirection={['column', 'row']} w={`100%`} h={`100%`}>
					<Flex
						flexDir='column'
						width={['100%', '40%']}
						height={`100%`}
						backgroundColor={`primary`}
						color={`#fff`}
						align={['center', 'unset']}
					>
						<Flex flexDir={`row`} alignItems={'center'} justifyContent={'center'} minH={120}>
							<Image
								src={protestLinkData?.logo.url}
								fallbackSrc={``}
								w={150}
								objectFit={`contain`}
								display={[
									'none', // 0-30em
									'block', // 30em-48em
									'block', // 48em-62em
									'block', // 62em+
								]}
							/>
							<Center>
								<Image
									src={protestLinkData?.logo.url}
									fallbackSrc={``}
									alt='ParcelaExpress Logo'
									objectFit={`contain`}
									w='40%'
									display={[
										'block', // 0-30em
										'none', // 30em-48em
										'none', // 48em-62em
										'none', // 62em+
									]}
								/>
							</Center>
						</Flex>
						<Stack px={5}>
							<Flex flexDir={`column`}>
								<Stack spacing={5}>
									<Stack flexDir={`column`} spacing={3} alignItems={'center'}>
										<Text id='payment-send-text' as={`span`} fontSize={`md`} align={['center', 'unset']}>
											Cobrança enviada por
										</Text>
										<Text id='protest-link-data-seller-description-text' as={`span`} fontWeight={`bold`} textDecor={`underline`}>
											{protestLinkData?.seller.description}
										</Text>
										<Text id='amoun-sale-text' as={`span`} fontWeight={`bold`}>
											Valor da dívida:
										</Text>
										<Text id='data-protest-link-amount-text' fontSize={`xl`} fontWeight={`bold`} align={['center', 'unset']}>{`R$ ${maskMoney(
											(protestLinkData!.amount - protestLinkData!.fee) / 100
										)}`}</Text>
										<Text id='emolumento-text' as={`span`} fontWeight={`bold`}>
											Valor do emolumento:
										</Text>
										<Text id='data-protest-link-data-fee-text' fontSize={`xl`} fontWeight={`bold`} align={['center', 'unset']}>{`R$ ${maskMoney(
											protestLinkData!.fee / 100
										)}`}</Text>
									</Stack>
								</Stack>
							</Flex>
						</Stack>
						<Flex mt='auto' px={5} mb={5}>
							<Stack alignSelf='flex-end'>
								<Text id='emolumentos-tax-parcela-text' display='block' fontSize='sm' lineHeight='normal' align={['center', 'unset']}>
									Sobre o valor dos emolumentos informado será acrescentada a taxa da administradora do cartão.
								</Text>
							</Stack>
						</Flex>
					</Flex>
					<Flex w={['100%', '60%']} h={`100%`} justifyContent={`center`} mt={10}>
						<Box w={`80%`}>
							<TabsWrapper
								seller={protestLinkData!.seller}
								simulationValues={simulationValues}
								saleInformation={saleInformation}
								setLinkPayed={setLinkPayed}
								hasPreCapture={protestLinkData.pre_capture}
								protestLinkData={{
									billet_is_permitted: protestLinkData?.billet_payment_is_permitted || false,
									qrcode_is_permitted: protestLinkData?.qrcode_payment_is_permitted || false,
									card_is_permitted: protestLinkData?.card_payment_is_permitted || false,
								}}
							/>
						</Box>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default ProtestLinkCheckout;
