/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSalePlanPaymentTypeDto
 */
export interface CreateSalePlanPaymentTypeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanPaymentTypeDto
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSalePlanPaymentTypeDto
     */
    fee: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanPaymentTypeDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSalePlanPaymentTypeDto
     */
    installments: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSalePlanPaymentTypeDto
     */
    teto?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSalePlanPaymentTypeDto
     */
    piso?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanPaymentTypeDto
     */
    operation_mode: CreateSalePlanPaymentTypeDtoOperationModeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateSalePlanPaymentTypeDto
     */
    min_rules?: number;
}

/**
* @export
* @enum {string}
*/
export enum CreateSalePlanPaymentTypeDtoOperationModeEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    BilletCode = 'billet_code',
    PixPos = 'pix_pos'
}

export function CreateSalePlanPaymentTypeDtoFromJSON(json: any): CreateSalePlanPaymentTypeDto {
    return CreateSalePlanPaymentTypeDtoFromJSONTyped(json, false);
}

export function CreateSalePlanPaymentTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSalePlanPaymentTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'fee': json['fee'],
        'description': json['description'],
        'installments': json['installments'],
        'teto': !exists(json, 'teto') ? undefined : json['teto'],
        'piso': !exists(json, 'piso') ? undefined : json['piso'],
        'operation_mode': json['operation_mode'],
        'min_rules': !exists(json, 'min_rules') ? undefined : json['min_rules'],
    };
}

export function CreateSalePlanPaymentTypeDtoToJSON(value?: CreateSalePlanPaymentTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'fee': value.fee,
        'description': value.description,
        'installments': value.installments,
        'teto': value.teto,
        'piso': value.piso,
        'operation_mode': value.operation_mode,
        'min_rules': value.min_rules,
    };
}


