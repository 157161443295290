/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
} from './';

/**
 * 
 * @export
 * @interface Payer
 */
export interface Payer {
    /**
     * 
     * @type {string}
     * @memberof Payer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Payer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Payer
     */
    document?: string;
    /**
     * 
     * @type {string}
     * @memberof Payer
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Payer
     */
    created_at?: string;
    /**
     * 
     * @type {Address}
     * @memberof Payer
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Payer
     */
    phone?: string;
    /**
     * 
     * @type {Seller}
     * @memberof Payer
     */
    seller: Seller;
    /**
     * 
     * @type {string}
     * @memberof Payer
     */
    birth_date?: string;
}

export function PayerFromJSON(json: any): Payer {
    return PayerFromJSONTyped(json, false);
}

export function PayerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'document': !exists(json, 'document') ? undefined : json['document'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'seller': SellerFromJSON(json['seller']),
        'birth_date': !exists(json, 'birth_date') ? undefined : json['birth_date'],
    };
}

export function PayerToJSON(value?: Payer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'document': value.document,
        'email': value.email,
        'created_at': value.created_at,
        'address': AddressToJSON(value.address),
        'phone': value.phone,
        'seller': SellerToJSON(value.seller),
        'birth_date': value.birth_date,
    };
}


