/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateRoleDto,
    CreateRoleDtoFromJSON,
    CreateRoleDtoToJSON,
    GetRoleDto,
    GetRoleDtoFromJSON,
    GetRoleDtoToJSON,
    ListByTypeDto,
    ListByTypeDtoFromJSON,
    ListByTypeDtoToJSON,
    UpdateRoleDto,
    UpdateRoleDtoFromJSON,
    UpdateRoleDtoToJSON,
} from '../models';

export interface CreateRoleRequest {
    createRoleDto: CreateRoleDto;
}

export interface GetRoleRequest {
    roleId: string;
}

export interface ListRolesByTypeRequest {
    listByTypeDto: ListByTypeDto;
}

export interface UpdateRoleRequest {
    roleId: string;
    updateRoleDto: UpdateRoleDto;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     * Criar um perfil de usuário
     */
    async createRoleRaw(requestParameters: CreateRoleRequest): Promise<runtime.ApiResponse<GetRoleDto>> {
        if (requestParameters.createRoleDto === null || requestParameters.createRoleDto === undefined) {
            throw new runtime.RequiredError('createRoleDto','Required parameter requestParameters.createRoleDto was null or undefined when calling createRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRoleDtoToJSON(requestParameters.createRoleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRoleDtoFromJSON(jsonValue));
    }

    /**
     * Criar um perfil de usuário
     */
    async createRole(requestParameters: CreateRoleRequest): Promise<GetRoleDto> {
        const response = await this.createRoleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Buscar perfil de usuário
     */
    async getRoleRaw(requestParameters: GetRoleRequest): Promise<runtime.ApiResponse<GetRoleDto>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/roles/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRoleDtoFromJSON(jsonValue));
    }

    /**
     * Buscar perfil de usuário
     */
    async getRole(requestParameters: GetRoleRequest): Promise<GetRoleDto> {
        const response = await this.getRoleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lista perfis de usuário
     */
    async listRolesRaw(): Promise<runtime.ApiResponse<Array<GetRoleDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/roles/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRoleDtoFromJSON));
    }

    /**
     * Lista perfis de usuário
     */
    async listRoles(): Promise<Array<GetRoleDto>> {
        const response = await this.listRolesRaw();
        return await response.value();
    }

    /**
     * Lista perfis de usuário
     */
    async listRolesByTypeRaw(requestParameters: ListRolesByTypeRequest): Promise<runtime.ApiResponse<Array<GetRoleDto>>> {
        if (requestParameters.listByTypeDto === null || requestParameters.listByTypeDto === undefined) {
            throw new runtime.RequiredError('listByTypeDto','Required parameter requestParameters.listByTypeDto was null or undefined when calling listRolesByType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/roles/listByType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListByTypeDtoToJSON(requestParameters.listByTypeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRoleDtoFromJSON));
    }

    /**
     * Lista perfis de usuário
     */
    async listRolesByType(requestParameters: ListRolesByTypeRequest): Promise<Array<GetRoleDto>> {
        const response = await this.listRolesByTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar um perfil de usuário
     */
    async updateRoleRaw(requestParameters: UpdateRoleRequest): Promise<runtime.ApiResponse<GetRoleDto>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling updateRole.');
        }

        if (requestParameters.updateRoleDto === null || requestParameters.updateRoleDto === undefined) {
            throw new runtime.RequiredError('updateRoleDto','Required parameter requestParameters.updateRoleDto was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/roles/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRoleDtoToJSON(requestParameters.updateRoleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRoleDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar um perfil de usuário
     */
    async updateRole(requestParameters: UpdateRoleRequest): Promise<GetRoleDto> {
        const response = await this.updateRoleRaw(requestParameters);
        return await response.value();
    }

}
