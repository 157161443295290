/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProtocolCustomizations
 */
export interface ProtocolCustomizations {
    /**
     * 
     * @type {string}
     * @memberof ProtocolCustomizations
     */
    default_email_content?: string;
    /**
     * 
     * @type {number}
     * @memberof ProtocolCustomizations
     */
    alert_before_days?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProtocolCustomizations
     */
    holidays_to_consider?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProtocolCustomizations
     */
    default_business_days_to_due?: number;
}

export function ProtocolCustomizationsFromJSON(json: any): ProtocolCustomizations {
    return ProtocolCustomizationsFromJSONTyped(json, false);
}

export function ProtocolCustomizationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolCustomizations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_email_content': !exists(json, 'default_email_content') ? undefined : json['default_email_content'],
        'alert_before_days': !exists(json, 'alert_before_days') ? undefined : json['alert_before_days'],
        'holidays_to_consider': !exists(json, 'holidays_to_consider') ? undefined : json['holidays_to_consider'],
        'default_business_days_to_due': !exists(json, 'default_business_days_to_due') ? undefined : json['default_business_days_to_due'],
    };
}

export function ProtocolCustomizationsToJSON(value?: ProtocolCustomizations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_email_content': value.default_email_content,
        'alert_before_days': value.alert_before_days,
        'holidays_to_consider': value.holidays_to_consider,
        'default_business_days_to_due': value.default_business_days_to_due,
    };
}


