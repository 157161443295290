import React from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';
import { Flex, Stack, BreadcrumbItem, BreadcrumbLink, FormControl, Image, Tag, useBreakpointValue, Box } from '@chakra-ui/react';
import { Center } from '@chakra-ui/layout';

import {
	ResponsibleApi,
	EditResponsibleRequest,
	GetResponsibleRequest,
	SellersApi,
	GetSellerDto,
	UploadResponsibleImageRequest,
} from 'clients';

import Loader from 'containers/Loader';
import Paper from 'containers/Paper';
import { getApiAuthConfig } from 'services/api.service';
import Button from 'components/Button';
import Breadcrumb from 'components/Breadcrumb';
import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import yup from 'services/yup.service';
import FormSelect from 'components/Form/FormSelect';
import { responsible_type } from 'config/constants';
import Text from '../../components/Text';
import FormLabel from '../../components/Form/FormLabel';
import FormPhoneInput from '../../components/Form/FormPhoneInput';
import { UploadImageForm } from './components/UploadImageForm';

const EditResponsible: React.FC = () => {
	const { id } = useParams();
	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const responsibleApi = new ResponsibleApi(apiConfig);
	const sellersApi = new SellersApi(apiConfig);
	const [isLoading, setIsLoading] = React.useState(false);
	const [sellers, setSellers] = React.useState<GetSellerDto[]>([]);
	const [file, setFile] = React.useState<File | undefined>(undefined);
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [initialValues, setInitalValues] = React.useState<any>({
		name: '',
		type: '',
		phone: '',
		is_active: true,
	});

	const updateResponsibleSchema = yup.object().shape({
		name: yup.string().required('Campo obrigatório'),
		type: yup.string().required('Campo obrigatório'),
		phone: yup.string().required('Campo obrigatório'),
		is_active: yup.boolean(),
	});

	const uploadResponsibleImage = async () => {
		const payload: UploadResponsibleImageRequest = {
			responsibleId: id,
			file: file!!,
		};

		try {
			setIsLoading(true);

			await responsibleApi.uploadResponsibleImage(payload);

			addToast('Arquivo atualizado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
			getResponsible();
		} catch (error) {
			addToast('Erro ao atualizar Arquivo', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleEditResponsible = async (values) => {
		const { is_active } = values;

		const payload: EditResponsibleRequest = {
			responsibleId: id,
			updateResponsibleDto: {
				name: values.name,
				type: values.type,
				phone: values.phone,
				is_active: typeof is_active === 'string' ? is_active === 'true' : is_active,
			},
		};

		try {
			setIsLoading(true);

			await responsibleApi.editResponsible(payload);

			addToast('Responsável atualizado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao atualizar Responsável', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getResponsible = async () => {
		setIsLoading(true);

		try {
			const getByIdParams: GetResponsibleRequest = { responsibleId: id };

			const responsible = await responsibleApi.getResponsible(getByIdParams);

			setInitalValues({
				name: responsible.name,
				type: responsible.type,
				phone: responsible.phone,
				is_active: responsible.is_active,
				url: responsible.url,
			});

			setIsLoading(false);
		} catch (error) {
			addToast('Erro ao buscar Responsável', {
				appearance: 'error',
				autoDismiss: true,
			});

			setIsLoading(false);
		}
	};

	const getSellers = async () => {
		setIsLoading(true);

		try {
			const getByIdParams: GetResponsibleRequest = { responsibleId: id };

			const sellers = await sellersApi.getSellersByResponsibleId(getByIdParams);

			setSellers(sellers);

			setIsLoading(false);
		} catch (error) {
			addToast('Erro ao buscar estabelecimentos', {
				appearance: 'error',
				autoDismiss: true,
			});

			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		getResponsible();
		getSellers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink href='/admin/responsaveis'>Responsáveis</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontWeight='semibold'>Editar Responsável</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper id='edit-responsible-paper-text' p={6}>
				<Flex flexDirection='column'>
					<Flex flexDirection={isMobile ? 'column' : 'row'} align='center' justifyContent='space-between'>
						<Flex flexDirection={isMobile ? 'column' : 'row'} align='center' justifyContent='flex-start'>
							<UploadImageForm key={file?.name} file={file} setFile={setFile} isLoading={isLoading} />
							<Button
								id='edit-responsible-save-button'
								marginX='10'
								bgColor='primary'
								size='md'
								type='submit'
								isLoading={isLoading}
								onClick={uploadResponsibleImage}
							>
								Save
							</Button>
						</Flex>
						{initialValues.url && (
							<Box
								id='edit-responsible-box-button'
								w='60px'
								h='60px'
								borderRadius='50%'
								overflow='hidden'
								border='1px solid black'
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								<Image id='edit-responsible-image' src={initialValues.url} w='68px' h='68px' objectFit='cover' alt='Logo' />
							</Box>
						)}
					</Flex>
					<>
						<Formik
							enableReinitialize
							initialValues={initialValues}
							validationSchema={updateResponsibleSchema}
							onSubmit={handleEditResponsible}
						>
							{({ handleSubmit, isValid, setFieldValue, values }) => {
								return (
									<form onSubmit={handleSubmit} style={{ width: '100%' }}>
										<Stack spacing={4}>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='name' isRequired>
													<FormLabel id='edit-responsible-name-label' fontSize='md' fontWeight='bold'>
														Nome
													</FormLabel>
													<FormInput id='edit-responsible-name-input' name='name' />
													<FormErrorMessage id='edit-responsible-name-error-text' name='name' />
												</FormControl>

												<FormControl id='type' isRequired>
													<FormLabel id='edit-responsible-type-label' fontSize='md' fontWeight='bold'>
														Tipo
													</FormLabel>
													<FormSelect id='edit-responsible-type-form-select' name='type' isDisabled={!!sellers.length}>
														{responsible_type.map((type) => (
															<option value={type.key} key={type.key}>
																{type.text}
															</option>
														))}
													</FormSelect>
													<FormErrorMessage id='edit-responsible-type-form-error-text' name='type' />
												</FormControl>
											</Stack>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='phone' isRequired>
													<FormLabel id='edit-responsible-phone-label' fontSize='md' fontWeight='bold'>
														Telefone
													</FormLabel>

													<FormPhoneInput id='edit-responsible-phone-input' borderColor='darkGrey' name='phone' />
												</FormControl>
												<FormControl id='is_active' isRequired>
													<FormLabel id='edit-responsible-is-active-label' fontSize='md' fontWeight='bold'>
														Ativo
													</FormLabel>
													<FormSelect id='edit-responsible-is-active-form-select' name='is_active'>
														<option value={'true'} key={1}>
															Sim
														</option>
														<option value={'false'} key={0}>
															Não
														</option>
													</FormSelect>
													<FormErrorMessage id='edit-responsible-is-active-form-error-text' name='is_active' />
												</FormControl>
											</Stack>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormLabel id='edit-responsible-is-active-label' fontSize='md' fontWeight='bold'>
													Estabelecimentos
												</FormLabel>
											</Stack>
											<Stack direction={['column', 'column', 'row']} spacing={0} wrap='wrap'>
												{sellers.map((seller) => {
													return (
														<Tag variant='outline' fontSize='sm' mb={2} mr={{ base: 0, md: 2 }} justifyContent='center'>
															{seller.name}
														</Tag>
													);
												})}
												{!sellers.length && (
													<Text id='no-linked-establishments-text' color='gray.500'>
														Nenhum estabelecimento vinculado
													</Text>
												)}
											</Stack>
										</Stack>

										<Center pt='6'>
											<Button
												id='edit-responsible-button'
												bgColor='primary'
												size='md'
												disabled={!isValid}
												type='submit'
												isLoading={isLoading}
											>
												Editar Responsável
											</Button>
										</Center>
									</form>
								);
							}}
						</Formik>
					</>
				</Flex>
			</Paper>
		</Flex>
	);
};

export default EditResponsible;
