import React from 'react';
import './styles.css';
import { Box, BreadcrumbItem, BreadcrumbLink, Button, Divider, Flex, Heading, Image, SimpleGrid } from '@chakra-ui/react';
import { logoUrl } from '../../config/variables';
import { AiOutlineDownload } from 'react-icons/ai';
import Paper from '../../containers/Paper';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import CropModal from './components/CropModal';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import Loader from 'containers/Loader';
import { SellersApi, UpdateSellerThemeRequest, UploadSellerLogoRequest } from '../../clients';
import { getApiAuthConfig } from '../../services/api.service';
import b64toBlob from 'b64-to-blob';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '../../contexts/AuthProvider';
import { useTheme } from '../../contexts/ThemeProvider';
import BackofficeAlertContainer from '../../containers/BackofficeAlertContainer';
import Breadcrumb from 'components/Breadcrumb';
import Text from 'components/Text';

const allowedMimes = ['image/jpg', 'image/jpeg', 'image/png'];

const Customization: React.FC = () => {
	const { user, updateUser, isBackoffice } = useAuth();
	const { currentSeller } = useCurrentSeller();
	const { theme } = useTheme();
	const { addToast } = useToasts();

	const [logoImages, setLogoImages] = React.useState<ImageListType>([{ dataURL: logoUrl }]);
	const [actualLogoUrl, setActualLogoUrl] = React.useState(user.logo.url || logoUrl);
	const [croppedImage, setCroppedImage] = React.useState<string | undefined>(undefined);
	const [isModalOpen, setModalOpen] = React.useState(false);
	const [isLoading, setLoading] = React.useState(false);
	const [primaryColor, setPrimaryColor] = React.useState('');
	const [secondaryColor, setSecondaryColor] = React.useState('');
	const [defaultColor, setDefaultColor] = React.useState('');
	const [textColor, setTextColor] = React.useState('');
	const [menuTextColor, setMenuTextColor] = React.useState('');

	React.useEffect(() => {
		if (isBackoffice && currentSeller) {
			if (currentSeller.logo) {
				setActualLogoUrl(currentSeller.logo.url);
			}

			if (currentSeller.theme) {
				setPrimaryColor(currentSeller.theme.primary_color);
				setSecondaryColor(currentSeller.theme.secondary_color);
				setDefaultColor(currentSeller.theme.default_color);
				setTextColor(currentSeller.theme.text_color);
				setMenuTextColor(currentSeller.theme.menu_text_color);
			} else {
				if (theme) {
					setPrimaryColor(theme.primary_color);
					setSecondaryColor(theme.secondary_color);
					setDefaultColor(theme.default_color);
					setTextColor(theme.text_color);
					setMenuTextColor(theme.menu_text_color);
				}
			}
		} else {
			if (theme) {
				setPrimaryColor(theme.primary_color);
				setSecondaryColor(theme.secondary_color);
				setDefaultColor(theme.default_color);
				setTextColor(theme.text_color);
				setMenuTextColor(theme.menu_text_color);
			}
		}
	}, [theme, currentSeller, isBackoffice]);

	const onImageUploaded = (image: ImageListType) => {
		setLogoImages(image);
		setModalOpen(true);
	};

	const handleSave = async () => {
		try {
			setLoading(true);

			const apiConfig = getApiAuthConfig();
			const sellersApi = new SellersApi(apiConfig);

			let newLogoUrl = '';

			if (croppedImage) {
				let imageMimeType = 'image/jpeg';

				if (logoImages[0].file?.type && allowedMimes.includes(logoImages[0].file.type)) {
					imageMimeType = logoImages[0].file.type;
				}

				const base64Data = croppedImage.split(',')[1];
				const blob = b64toBlob(base64Data, imageMimeType);

				const payload1: UploadSellerLogoRequest = {
					sellerId: currentSeller?.id!,
					file: blob,
				};

				const updateLogoResponse = await sellersApi.uploadSellerLogo(payload1);
				newLogoUrl = updateLogoResponse.url;
			}

			const payload2: UpdateSellerThemeRequest = {
				sellerId: currentSeller?.id!,
				updateSellerThemeDto: {
					default_color: defaultColor,
					menu_text_color: menuTextColor,
					primary_color: primaryColor,
					secondary_color: secondaryColor,
					text_color: textColor,
				},
			};
			await sellersApi.updateSellerTheme(payload2);

			if (!isBackoffice) {
				changeTheme(newLogoUrl);
			}

			addToast('Tema atualizado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (err) {
			console.log('Customization handleSave error', err);
			addToast('Erro ao atualizar tema', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setLoading(false);
		}
	};

	const changeTheme = (logoUrl: string) => {
		updateUser({
			...user,
			...(logoUrl
				? {
						logo: {
							url: logoUrl,
						},
				  }
				: {}),
			theme: {
				default_color: defaultColor,
				menu_text_color: menuTextColor,
				primary_color: primaryColor,
				secondary_color: secondaryColor,
				text_color: textColor,
			},
		});
	};

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex direction='column'>
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Minha Conta</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontSize='md' fontWeight='semibold'>
						Customização
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper id='customization-paper-text' p={6}>
				<Flex justifyContent='space-between' alignItems='center' mb='4'>
					<Heading id='choice-logo-heading' color={theme?.text_color} fontSize='lg' fontWeight='bold'>
						Escolher Logo
					</Heading>

					<Button id='save-button' rounded='md' bgColor='primary' _hover={{ opacity: 0.7, cursor: 'pointer' }} onClick={handleSave}>
						<Text id='save-text' fontWeight='normal' fontSize='lg' color='white'>
							Salvar
						</Text>
					</Button>
				</Flex>

				<Flex mb='6' justifyContent='center'>
					<ImageUploading value={logoImages} onChange={(value) => onImageUploaded(value)}>
						{({ onImageUpload, dragProps }) => (
							<>
								<Image
									id='cropped-or-logo-image'
									src={croppedImage ? croppedImage : actualLogoUrl}
									maxW='23.75rem'
									maxH='5.625rem'
									borderWidth={1}
									borderColor='gray.400'
									rounded='sm'
									mr='6'
								/>

								<Flex direction='column' justifyContent='center'>
									<Button
										id='upload-image-button'
										mb='2'
										onClick={onImageUpload}
										{...dragProps}
										rounded='sm'
										borderWidth={0.2}
										borderColor='gray.400'
										bgColor='white'
									>
										<AiOutlineDownload color='blue.700' size={16} />
										<Text id='upload-image-text' color='gray.700' ml='2'>
											Importar imagem
										</Text>
									</Button>

									<Text id='standard-size-text' fontSize='sm' textAlign='center'>
										Tamanho padrão 80x90
									</Text>
									<Text id='max-width-text' fontSize='sm' textAlign='center'>
										Largura máxima: 380px
									</Text>
									<Text id='max-heigth-text' fontSize='sm' textAlign='center'>
										Altura máxima: 90px
									</Text>
								</Flex>
							</>
						)}
					</ImageUploading>
				</Flex>

				<Divider mb='6' />

				<Heading id='choice-sistem-color-heading' color={theme?.text_color} fontSize='lg' fontWeight='bold' mb='4'>
					Escolher cores do sistema
				</Heading>

				<SimpleGrid columns={4} spacing={4}>
					<Flex direction='column'>
						<Heading id='primary-collor-heading' color={theme?.text_color} fontSize='md' fontWeight='semibold' mb='3'>
							Cor primária
						</Heading>

						<Flex direction='column'>
							<Flex mb='3'>
								<HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
							</Flex>

							<Flex>
								<HexColorInput className='color-picker-input' color={primaryColor} onChange={setPrimaryColor} prefixed />
								<Box
									width='2.5rem'
									height='inherit'
									bgColor={primaryColor}
									borderRightRadius='0.3125rem'
									borderWidth={1}
									borderLeftWidth={0}
									borderColor='darkGrey'
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction='column'>
						<Heading id='secondary-color-heading' color={theme?.text_color} fontSize='md' fontWeight='semibold' mb='3'>
							Cor secundária
						</Heading>

						<Flex direction='column'>
							<Flex mb='3'>
								<HexColorPicker color={secondaryColor} onChange={setSecondaryColor} />
							</Flex>

							<Flex>
								<HexColorInput
									id='color-hex-input'
									className='color-picker-input'
									color={secondaryColor}
									onChange={setSecondaryColor}
									prefixed
								/>
								<Box width='2.5rem' height='inherit' bgColor={secondaryColor} borderRightRadius='0.3125rem' />
							</Flex>
						</Flex>
					</Flex>

					<Flex direction='column'>
						<Heading id='background-color-heading' color={theme?.text_color} fontSize='md' fontWeight='semibold' mb='3'>
							Cor do fundo
						</Heading>

						<Flex direction='column'>
							<Flex mb='3'>
								<HexColorPicker color={defaultColor} onChange={setDefaultColor} />
							</Flex>

							<Flex>
								<HexColorInput
									id='text-collor-hex-color-input'
									className='color-picker-input'
									color={defaultColor}
									onChange={setDefaultColor}
									prefixed
								/>
								<Box width='2.5rem' height='inherit' bgColor={defaultColor} borderRightRadius='0.3125rem' />
							</Flex>
						</Flex>
					</Flex>

					<Flex direction='column'>
						<Heading id='text-color-heading' color={theme?.text_color} fontSize='md' fontWeight='semibold' mb='3'>
							Cor do texto
						</Heading>

						<Flex direction='column'>
							<Flex mb='3'>
								<HexColorPicker color={textColor} onChange={setTextColor} />
							</Flex>

							<Flex>
								<HexColorInput
									id='menu-text-color-hex-input'
									className='color-picker-input'
									color={textColor}
									onChange={setTextColor}
									prefixed
								/>
								<Box width='2.5rem' height='inherit' bgColor={textColor} borderRightRadius='0.3125rem' />
							</Flex>
						</Flex>
					</Flex>

					<Flex direction='column'>
						<Heading id='menu-text-color-heading' color={theme?.text_color} fontSize='md' fontWeight='semibold' mb='3'>
							Cor do texto do menu
						</Heading>

						<Flex direction='column'>
							<Flex mb='3'>
								<HexColorPicker color={menuTextColor} onChange={setMenuTextColor} />
							</Flex>

							<Flex>
								<HexColorInput className='color-picker-input' color={menuTextColor} onChange={setMenuTextColor} prefixed />
								<Box
									width='2.5rem'
									height='inherit'
									bgColor={menuTextColor}
									borderRightRadius='0.3125rem'
									borderWidth={1}
									borderLeftWidth={0}
									borderColor='darkGrey'
								/>
							</Flex>
						</Flex>
					</Flex>
				</SimpleGrid>
			</Paper>

			<CropModal
				isOpen={isModalOpen}
				imageToCrop={logoImages[0]}
				onClose={() => setModalOpen(false)}
				setCroppedImage={(croppedImg) => setCroppedImage(croppedImg)}
			/>

			<Loader isOpen={isLoading} />
		</Flex>
	);
};

export default Customization;
