/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSaleSummaryTypeDto,
    GetSaleSummaryTypeDtoFromJSON,
    GetSaleSummaryTypeDtoFromJSONTyped,
    GetSaleSummaryTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSaleSummaryDto
 */
export interface GetSaleSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    total_financial_volume: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    total_gross_volume: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    total_sales_number: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    average_ticket: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    total_sellers: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    financial_success_sale: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    financial_canceled_sale: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    financial_pending_sale: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    online_financial_volume: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    online_gross_volume: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    physical_financial_volume: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryDto
     */
    physical_gross_volume: number;
    /**
     * 
     * @type {Array<GetSaleSummaryTypeDto>}
     * @memberof GetSaleSummaryDto
     */
    types: Array<GetSaleSummaryTypeDto>;
}

export function GetSaleSummaryDtoFromJSON(json: any): GetSaleSummaryDto {
    return GetSaleSummaryDtoFromJSONTyped(json, false);
}

export function GetSaleSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSaleSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total_financial_volume': json['total_financial_volume'],
        'total_gross_volume': json['total_gross_volume'],
        'total_sales_number': json['total_sales_number'],
        'average_ticket': json['average_ticket'],
        'total_sellers': json['total_sellers'],
        'financial_success_sale': json['financial_success_sale'],
        'financial_canceled_sale': json['financial_canceled_sale'],
        'financial_pending_sale': json['financial_pending_sale'],
        'online_financial_volume': json['online_financial_volume'],
        'online_gross_volume': json['online_gross_volume'],
        'physical_financial_volume': json['physical_financial_volume'],
        'physical_gross_volume': json['physical_gross_volume'],
        'types': ((json['types'] as Array<any>).map(GetSaleSummaryTypeDtoFromJSON)),
    };
}

export function GetSaleSummaryDtoToJSON(value?: GetSaleSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_financial_volume': value.total_financial_volume,
        'total_gross_volume': value.total_gross_volume,
        'total_sales_number': value.total_sales_number,
        'average_ticket': value.average_ticket,
        'total_sellers': value.total_sellers,
        'financial_success_sale': value.financial_success_sale,
        'financial_canceled_sale': value.financial_canceled_sale,
        'financial_pending_sale': value.financial_pending_sale,
        'online_financial_volume': value.online_financial_volume,
        'online_gross_volume': value.online_gross_volume,
        'physical_financial_volume': value.physical_financial_volume,
        'physical_gross_volume': value.physical_gross_volume,
        'types': ((value.types as Array<any>).map(GetSaleSummaryTypeDtoToJSON)),
    };
}


