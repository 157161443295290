/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSettlementOptionDto
 */
export interface GetSettlementOptionDto {
    /**
     * 
     * @type {string}
     * @memberof GetSettlementOptionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSettlementOptionDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetSettlementOptionDto
     */
    code: GetSettlementOptionDtoCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSettlementOptionDto
     */
    type: GetSettlementOptionDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetSettlementOptionDto
     */
    days: number;
}

/**
* @export
* @enum {string}
*/
export enum GetSettlementOptionDtoCodeEnum {
    D0 = 'D0',
    D1 = 'D1',
    D30 = 'D30'
}/**
* @export
* @enum {string}
*/
export enum GetSettlementOptionDtoTypeEnum {
    Pix = 'pix',
    Debit = 'debit',
    Credit = 'credit',
    Billet = 'billet'
}

export function GetSettlementOptionDtoFromJSON(json: any): GetSettlementOptionDto {
    return GetSettlementOptionDtoFromJSONTyped(json, false);
}

export function GetSettlementOptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSettlementOptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'code': json['code'],
        'type': json['type'],
        'days': json['days'],
    };
}

export function GetSettlementOptionDtoToJSON(value?: GetSettlementOptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'code': value.code,
        'type': value.type,
        'days': value.days,
    };
}


