export const pagination = (items, currentPage: number, limit: number, totalItemsPaginated?: number) => {
	currentPage = currentPage || 1;
	limit = limit || 10;

	const offset = (currentPage - 1) * limit;
	const paginatedItems = items.slice(offset).slice(0, limit);
	const totalItems = totalItemsPaginated || items.length;
	const totalPages = Math.ceil(totalItems / limit);
	const start = (currentPage - 1) * limit;

	let end = totalItems;

	if (limit < totalItems) {
		end = limit * currentPage;

		if (end > totalItems) {
			end = totalItems;
		}
	}
	const hasMore = end - start === limit;

	return {
		current_page: currentPage,
		total_pages: totalPages,
		limit,
		start,
		end,
		total_items: totalItems,
		has_more: hasMore,
		results: paginatedItems,
	};
};
