/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelPaymentResponseDTO
 */
export interface CancelPaymentResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CancelPaymentResponseDTO
     */
    pspReference: string;
    /**
     * 
     * @type {string}
     * @memberof CancelPaymentResponseDTO
     */
    response: string;
}

export function CancelPaymentResponseDTOFromJSON(json: any): CancelPaymentResponseDTO {
    return CancelPaymentResponseDTOFromJSONTyped(json, false);
}

export function CancelPaymentResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelPaymentResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pspReference': json['pspReference'],
        'response': json['response'],
    };
}

export function CancelPaymentResponseDTOToJSON(value?: CancelPaymentResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pspReference': value.pspReference,
        'response': value.response,
    };
}


