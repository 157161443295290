import { useBreakpointValue } from '@chakra-ui/react';
import FileDownload from 'js-file-download';
import * as React from 'react';
import { AddToast, useToasts } from 'react-toast-notifications';
import { GetExportedOrdersStatusEnum, GetOrdersStatusEnum, GetPaperRollOrderDto, PaperRollOrderApi } from '../../../clients';
import { IPeriod } from '../../../containers/RangeDatePicker';
import { useCurrentSeller } from '../../../contexts/SellerProvider';
import { getApiAuthConfig } from '../../../services/api.service';

interface PaperRollOrderContextData {
	orders: GetPaperRollOrderDto[];
	totalPages: number;
	getExportedOrders(): Promise<void>;
	exportedLoading: boolean;
	period: IPeriod[];
	setPeriod: React.Dispatch<React.SetStateAction<IPeriod[]>>;
	getOrders: () => Promise<void>;
	setStatus: React.Dispatch<React.SetStateAction<GetOrdersStatusEnum | undefined>>;
	isLoading: boolean;
	paperRollOrderApi: PaperRollOrderApi;
	addToast: AddToast;
	isMobile: boolean | undefined;
	pagination: Pagination;
	setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
}

const PaperRollOrderContext = React.createContext<PaperRollOrderContextData>({} as PaperRollOrderContextData);

type PaperRollOrderProviderProps = {
	children: React.ReactElement;
};

type Pagination = {
	currentPage: number;
	limit: number;
};

export const PaperRollOrderProvider: React.FC<PaperRollOrderProviderProps> = ({ children }) => {
	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();

	const apiConfig = getApiAuthConfig();
	const paperRollOrderApi = new PaperRollOrderApi(apiConfig);

	const [isLoading, setIsLoading] = React.useState(true);
	const [exportedLoading, setExportedLoading] = React.useState(false);
	const [orders, setOrders] = React.useState<GetPaperRollOrderDto[]>([]);
	const [pagination, setPagination] = React.useState<Pagination>({ currentPage: 1, limit: 10 });
	const [totalPages, setTotalPages] = React.useState(1);
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const [status, setStatus] = React.useState<GetOrdersStatusEnum | undefined>(undefined);
	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	async function getOrders() {
		setIsLoading(true);
		try {
			const response = await paperRollOrderApi.getOrders({
				limit: pagination.limit,
				currentPage: pagination.currentPage,
				startDate: period[0].startDate.toISOString(),
				endDate: period[0].endDate.toISOString(),
				sellerId: currentSeller?.id,
				status: status,
			});
			setTotalPages(response.total_pages);
			setOrders((response.results as unknown) as GetPaperRollOrderDto[]);
		} catch (error) {
			addToast('Erro ao buscar os pedidos.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	async function getExportedOrders() {
		setExportedLoading(true);
		try {
			const response = await paperRollOrderApi.getExportedOrders({
				startDate: period[0].startDate.toISOString(),
				endDate: period[0].endDate.toISOString(),
				sellerId: currentSeller?.id,
				status: (status as unknown) as GetExportedOrdersStatusEnum,
			});
			FileDownload(response, 'Histórico de pedidos.xlsx');
			addToast('Pedidos exportados com sucesso.', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao exportar os pedidos.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportedLoading(false);
		}
	}

	React.useEffect(() => {
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, currentSeller, status]);

	return (
		<PaperRollOrderContext.Provider
			value={{
				period,
				setPeriod,
				setStatus,
				isLoading,
				getOrders,
				orders,
				pagination,
				setPagination,
				totalPages,
				getExportedOrders,
				exportedLoading,
				paperRollOrderApi,
				addToast,
				isMobile,
			}}
		>
			{children}
		</PaperRollOrderContext.Provider>
	);
};

export function usePaperRollOrder() {
	const context = React.useContext(PaperRollOrderContext);
	return context;
}
