import React from 'react';

import QRCode from 'react-qr-code';
import { useToasts } from 'react-toast-notifications';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Skeleton, Button } from '@chakra-ui/react';
import { Box, Flex, HStack } from '@chakra-ui/layout';

import {
	CreatePixPaymentDto,
	CreatePixPaymentResponseDTO,
	PaymentsApi,
	GetPaymentLinkSellerDto,
	CreatePixPaymentRequest,
	GetSimulationDto,
} from '../../../clients';

import { maskMoney } from '../../../services/masks.service';
import { getApiDefaultConfig } from '../../../services/api.service';
import { CheckoutSaleInformationType } from '../types/checkout.type';

import Checkbox from '../../../components/Checkbox';
import Text from '../../../components/Text';

type QrCodeProps = {
	seller: GetPaymentLinkSellerDto;
	saleInformation: CheckoutSaleInformationType | undefined;
	simulationValues: Array<GetSimulationDto>;
};

const QrCode: React.FC<QrCodeProps> = ({ seller, saleInformation, simulationValues }) => {
	const [qrCode, setQrCode] = React.useState<CreatePixPaymentResponseDTO | null>(null);
	const [value, setValue] = React.useState({
		simulated: 0,
		original: 0,
	});
	const [checked, setChecked] = React.useState(false);

	const { addToast } = useToasts();

	React.useEffect(() => {
		(async function () {
			if (qrCode || !checked) return;
			if (!value.simulated) return;

			const apiConfig = getApiDefaultConfig();

			const createPixPaymentDto: CreatePixPaymentDto = {
				amount_cents: value.simulated,
				description: saleInformation!.description,
				customer: {
					first_name: saleInformation!.first_name,
					last_name: saleInformation!.last_name,
					document: saleInformation!.document,
				},
				sale_id: saleInformation!.id,
				protest_link: true,
				amount_fee: saleInformation!.fee,
			};

			if (!createPixPaymentDto.customer.document) {
				delete createPixPaymentDto.customer.document;
			}

			const paymentRequest: CreatePixPaymentRequest = {
				sellerId: seller.id,
				createPixPaymentDto,
			};

			const paymentsApi = new PaymentsApi(apiConfig);
			const response = await paymentsApi.createPixPayment(paymentRequest);

			setQrCode(response);
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);

	React.useEffect(() => {
		(async function () {
			const amount = simulationValues.find((payment) => payment.type === 'pix');

			if (!amount) return;

			setValue({
				simulated: amount.total_amount_cents,
				original: amount.original_amount_cents,
			});
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDir='column'>
			<Text id='original-amount-of-debt-allocated-to-the-notary-text' align='center' mt={3}>
				Valor original da dívida destinado ao cartório: R${' '}
				<span style={{ fontWeight: 'bold' }}>{maskMoney((value.original ? value.original - saleInformation?.fee! : 0) / 100)}</span>
			</Text>
			<Text id='increase-on-the-notary-debt-text' align='center'>
				* Acréscimo sobre a dívida do cartório: R${' '}
				<span style={{ fontWeight: 'bold' }}>{maskMoney((value.simulated - value.original) / 100)}</span>
			</Text>
			{saleInformation && (
				<Text id='fee-amount-text' align='center'>
					Valor do Emolumento: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney(saleInformation.fee / 100)}</span>
				</Text>
			)}
			<Text id='increase-on-the-fee-debt-text' align='center'>
				Acréscimo sobre a dívida de emolumento: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney(0)}</span>
			</Text>
			<Text id='value-fee-text' align='center'>
				Valor a ser pago com acréscimo: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney(value.simulated / 100)}</span>
			</Text>

			{!checked && (
				<Box border='1px solid #EE7D00' p={2} mt={2}>
					<Text id='payment-qrcode-accept-notory-text' align='center' fontSize='0.75rem'>
						O pagamento realizado por QR Code será confirmado de forma instantânea no cartório.
					</Text>

					<Text id='payment-qrcode-parcela-text' align='center' fontSize='0.75rem'>
						O pagamento por QR Code para Parcela Express será processo por <strong>Adyen.</strong>
					</Text>
					<Text id='finished-payment-text' align='center' fontSize='0.75rem'>
						Após a finalização do pagamento, o comprovante dessa transação será enviado para o e-mail cadastrado.
					</Text>

					<Checkbox
						id='accept-terms-checkbox'
						size='sm'
						colorScheme='orange'
						isChecked={checked}
						border='#EE7D00'
						onClick={() => {
							setChecked(!checked);
						}}
						mt={3}
					>
						Estou ciente
					</Checkbox>
				</Box>
			)}

			{checked &&
				(qrCode ? (
					<>
						<Flex flexDir={['column', 'row']} flexWrap='nowrap' align='center'>
							<Flex flex={1} />

							<Flex>
								<QRCode size={200} value={qrCode.qrCodeData} />
							</Flex>

							<Flex flex={1} textAlign='center'>
								<Text id='payment-pix-adyen-text' ml={2} align='center' color='tomato'>
									O pagamento por Pix será identificado como Adyen do Brasil Ltda.
								</Text>
							</Flex>
						</Flex>

						<HStack align='center' justify='center' mt={1} mb={1}>
							<CopyToClipboard
								text={qrCode.qrCodeData}
								onCopy={() => {
									addToast('Link copiado com sucesso', {
										appearance: 'success',
										autoDismiss: true,
									});
								}}
							>
								<Button backgroundColor='primary' color='white' fontWeight='bold'>
									Copiar código do pagamento
								</Button>
							</CopyToClipboard>
						</HStack>
						<Text id='close-screen-payment-text' mt={3} fontSize='0.75rem'>
							Após a finalização do pagamento você poderá fechar esta tela.
							<br />
							Seu comprovante de pagamento será enviado por email.
						</Text>
					</>
				) : (
					<Box pt={0.5} mt={2} mb={3}>
						<Flex flexDir={['column', 'row']} flexWrap='nowrap' align='center'>
							<Flex flex={1} />

							<Flex>
								<Skeleton height={200} variant='rect' width={200} />
							</Flex>

							<Flex flex={1} flexDir='column' align='center'>
								<Skeleton ml={2} height={25} variant='text' width='70%' />
								<Skeleton mt={2} height={25} variant='text' width='50%' />
							</Flex>
						</Flex>
						<Skeleton height={25} variant='text' width='30%' mt={1} />
					</Box>
				))}

			<Text id='terms-text' mt={3} fontSize='0.75rem'>
				Nos termos da Resolução BCB no 19/2020, Art. 3º, Parágrafo único, "as transações realizadas por meio de canais de atendimento
				presencial ou pessoal da instituição admitem a cobrança de tarifa".
			</Text>
		</Flex>
	);
};

export default QrCode;
