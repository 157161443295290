/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentsSplitsDto,
    PaymentsSplitsDtoFromJSON,
    PaymentsSplitsDtoFromJSONTyped,
    PaymentsSplitsDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentsSplitRulesDto
 */
export interface PaymentsSplitRulesDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentsSplitRulesDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentsSplitRulesDto
     */
    mainSellerName: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsSplitRulesDto
     */
    mainSellerDocument: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsSplitRulesDto
     */
    mainSellerCode: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsSplitRulesDto
     */
    mainSaleId: string;
    /**
     * 
     * @type {Array<PaymentsSplitsDto>}
     * @memberof PaymentsSplitRulesDto
     */
    splits?: Array<PaymentsSplitsDto>;
}

export function PaymentsSplitRulesDtoFromJSON(json: any): PaymentsSplitRulesDto {
    return PaymentsSplitRulesDtoFromJSONTyped(json, false);
}

export function PaymentsSplitRulesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentsSplitRulesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'mainSellerName': json['mainSellerName'],
        'mainSellerDocument': json['mainSellerDocument'],
        'mainSellerCode': json['mainSellerCode'],
        'mainSaleId': json['mainSaleId'],
        'splits': !exists(json, 'splits') ? undefined : ((json['splits'] as Array<any>).map(PaymentsSplitsDtoFromJSON)),
    };
}

export function PaymentsSplitRulesDtoToJSON(value?: PaymentsSplitRulesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'mainSellerName': value.mainSellerName,
        'mainSellerDocument': value.mainSellerDocument,
        'mainSellerCode': value.mainSellerCode,
        'mainSaleId': value.mainSaleId,
        'splits': value.splits === undefined ? undefined : ((value.splits as Array<any>).map(PaymentsSplitsDtoToJSON)),
    };
}


