/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProtocolDueDateUpdateHistory
 */
export interface ProtocolDueDateUpdateHistory {
    /**
     * 
     * @type {string}
     * @memberof ProtocolDueDateUpdateHistory
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof ProtocolDueDateUpdateHistory
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof ProtocolDueDateUpdateHistory
     */
    created_at_unix_time: number;
    /**
     * 
     * @type {object}
     * @memberof ProtocolDueDateUpdateHistory
     */
    before: object;
    /**
     * 
     * @type {object}
     * @memberof ProtocolDueDateUpdateHistory
     */
    after: object;
}

export function ProtocolDueDateUpdateHistoryFromJSON(json: any): ProtocolDueDateUpdateHistory {
    return ProtocolDueDateUpdateHistoryFromJSONTyped(json, false);
}

export function ProtocolDueDateUpdateHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolDueDateUpdateHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': json['user_name'],
        'created_at': json['created_at'],
        'created_at_unix_time': json['created_at_unix_time'],
        'before': json['before'],
        'after': json['after'],
    };
}

export function ProtocolDueDateUpdateHistoryToJSON(value?: ProtocolDueDateUpdateHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'created_at': value.created_at,
        'created_at_unix_time': value.created_at_unix_time,
        'before': value.before,
        'after': value.after,
    };
}


