/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetBilletDto,
    GetBilletDtoFromJSON,
    GetBilletDtoFromJSONTyped,
    GetBilletDtoToJSON,
    GetSalePayerDto,
    GetSalePayerDtoFromJSON,
    GetSalePayerDtoFromJSONTyped,
    GetSalePayerDtoToJSON,
    GetSalePlanPaymentTypeDto,
    GetSalePlanPaymentTypeDtoFromJSON,
    GetSalePlanPaymentTypeDtoFromJSONTyped,
    GetSalePlanPaymentTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSellerSaleAutoGeneratePaymentLinkDto
 */
export interface GetSellerSaleAutoGeneratePaymentLinkDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    original_amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    type: GetSellerSaleAutoGeneratePaymentLinkDtoTypeEnum;
    /**
     * 
     * @type {GetSalePlanPaymentTypeDto}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    payment_type: GetSalePlanPaymentTypeDto;
    /**
     * 
     * @type {GetSalePayerDto}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    payer: GetSalePayerDto;
    /**
     * 
     * @type {GetBilletDto}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    billet: GetBilletDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    billet_payment_is_permitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    qrcode_payment_is_permitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerSaleAutoGeneratePaymentLinkDto
     */
    card_payment_is_permitted?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum GetSellerSaleAutoGeneratePaymentLinkDtoTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}

export function GetSellerSaleAutoGeneratePaymentLinkDtoFromJSON(json: any): GetSellerSaleAutoGeneratePaymentLinkDto {
    return GetSellerSaleAutoGeneratePaymentLinkDtoFromJSONTyped(json, false);
}

export function GetSellerSaleAutoGeneratePaymentLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerSaleAutoGeneratePaymentLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'created_at': json['created_at'],
        'original_amount': json['original_amount'],
        'type': json['type'],
        'payment_type': GetSalePlanPaymentTypeDtoFromJSON(json['payment_type']),
        'payer': GetSalePayerDtoFromJSON(json['payer']),
        'billet': GetBilletDtoFromJSON(json['billet']),
        'billet_payment_is_permitted': !exists(json, 'billet_payment_is_permitted') ? undefined : json['billet_payment_is_permitted'],
        'qrcode_payment_is_permitted': !exists(json, 'qrcode_payment_is_permitted') ? undefined : json['qrcode_payment_is_permitted'],
        'card_payment_is_permitted': !exists(json, 'card_payment_is_permitted') ? undefined : json['card_payment_is_permitted'],
    };
}

export function GetSellerSaleAutoGeneratePaymentLinkDtoToJSON(value?: GetSellerSaleAutoGeneratePaymentLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'created_at': value.created_at,
        'original_amount': value.original_amount,
        'type': value.type,
        'payment_type': GetSalePlanPaymentTypeDtoToJSON(value.payment_type),
        'payer': GetSalePayerDtoToJSON(value.payer),
        'billet': GetBilletDtoToJSON(value.billet),
        'billet_payment_is_permitted': value.billet_payment_is_permitted,
        'qrcode_payment_is_permitted': value.qrcode_payment_is_permitted,
        'card_payment_is_permitted': value.card_payment_is_permitted,
    };
}


