/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StatusEnum {
    Succeeded = 'succeeded',
    PendingCapture = 'pending_capture',
    Canceled = 'canceled',
    Refunded = 'refunded',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn'
}

export function StatusEnumFromJSON(json: any): StatusEnum {
    return StatusEnumFromJSONTyped(json, false);
}

export function StatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusEnum {
    return json as StatusEnum;
}

export function StatusEnumToJSON(value?: StatusEnum | null): any {
    return value as any;
}

