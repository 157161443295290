/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CardDTO,
    CardDTOFromJSON,
    CardDTOFromJSONTyped,
    CardDTOToJSON,
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
    CustomerDTO,
    CustomerDTOFromJSON,
    CustomerDTOFromJSONTyped,
    CustomerDTOToJSON,
    InstallmentPlanDTO,
    InstallmentPlanDTOFromJSON,
    InstallmentPlanDTOFromJSONTyped,
    InstallmentPlanDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePaymentWithSplitDto
 */
export interface CreatePaymentWithSplitDto {
    /**
     * Valor em centavos
     * @type {number}
     * @memberof CreatePaymentWithSplitDto
     */
    amount_cents: number;
    /**
     * Descrição
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    description: string;
    /**
     * 
     * @type {CardDTO}
     * @memberof CreatePaymentWithSplitDto
     */
    card_attributes: CardDTO;
    /**
     * Parcelamento
     * @type {InstallmentPlanDTO}
     * @memberof CreatePaymentWithSplitDto
     */
    installment_plan: InstallmentPlanDTO;
    /**
     * Cliente
     * @type {CustomerDTO}
     * @memberof CreatePaymentWithSplitDto
     */
    customer: CustomerDTO;
    /**
     * Id da venda
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    sale_id?: string;
    /**
     * Criado via link de protesto
     * @type {boolean}
     * @memberof CreatePaymentWithSplitDto
     */
    protest_link?: boolean;
    /**
     * Valor da taxa
     * @type {number}
     * @memberof CreatePaymentWithSplitDto
     */
    amount_fee?: number;
    /**
     * Pré captura
     * @type {boolean}
     * @memberof CreatePaymentWithSplitDto
     */
    pre_capture?: boolean;
    /**
     * Forma de pagamento
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    form_payment?: CreatePaymentWithSplitDtoFormPaymentEnum;
    /**
     * Url de redirecionamento de sucesso (exclusivo 3DS)
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    success_return_url?: string;
    /**
     * Url de redirecionamento de erro (exclusivo 3DS)
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    error_return_url?: string;
    /**
     * Há Divisão de pagamento
     * @type {boolean}
     * @memberof CreatePaymentWithSplitDto
     */
    has_split_rules?: boolean;
    /**
     * Divisão de pagamento
     * @type {Array<CreateSplitRulesDto>}
     * @memberof CreatePaymentWithSplitDto
     */
    split_rules?: Array<CreateSplitRulesDto>;
    /**
     * Há confirmação de pagamento
     * @type {boolean}
     * @memberof CreatePaymentWithSplitDto
     */
    confirmation_required?: boolean;
    /**
     * Identificação do extrato. Recomenda-se usar até 22 caracteres, pois o banco pode excluir o excedente
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    extract_identification?: string;
    /**
     * Ativar 3DS
     * @type {boolean}
     * @memberof CreatePaymentWithSplitDto
     */
    active_3ds?: boolean;
    /**
     * Campo de risco customizável
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    risk_custom_field?: string;
    /**
     * Identificador externo
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    service_id?: string;
    /**
     * Número do protocolo
     * @type {string}
     * @memberof CreatePaymentWithSplitDto
     */
    protocol?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreatePaymentWithSplitDtoFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Pending = 'pending',
    PixPos = 'pix_pos'
}

export function CreatePaymentWithSplitDtoFromJSON(json: any): CreatePaymentWithSplitDto {
    return CreatePaymentWithSplitDtoFromJSONTyped(json, false);
}

export function CreatePaymentWithSplitDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentWithSplitDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'description': json['description'],
        'card_attributes': CardDTOFromJSON(json['card_attributes']),
        'installment_plan': InstallmentPlanDTOFromJSON(json['installment_plan']),
        'customer': CustomerDTOFromJSON(json['customer']),
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'protest_link': !exists(json, 'protest_link') ? undefined : json['protest_link'],
        'amount_fee': !exists(json, 'amount_fee') ? undefined : json['amount_fee'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
        'form_payment': !exists(json, 'form_payment') ? undefined : json['form_payment'],
        'success_return_url': !exists(json, 'success_return_url') ? undefined : json['success_return_url'],
        'error_return_url': !exists(json, 'error_return_url') ? undefined : json['error_return_url'],
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'split_rules': !exists(json, 'split_rules') ? undefined : ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'extract_identification': !exists(json, 'extract_identification') ? undefined : json['extract_identification'],
        'active_3ds': !exists(json, 'active_3ds') ? undefined : json['active_3ds'],
        'risk_custom_field': !exists(json, 'risk_custom_field') ? undefined : json['risk_custom_field'],
        'service_id': !exists(json, 'service_id') ? undefined : json['service_id'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
    };
}

export function CreatePaymentWithSplitDtoToJSON(value?: CreatePaymentWithSplitDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'description': value.description,
        'card_attributes': CardDTOToJSON(value.card_attributes),
        'installment_plan': InstallmentPlanDTOToJSON(value.installment_plan),
        'customer': CustomerDTOToJSON(value.customer),
        'sale_id': value.sale_id,
        'protest_link': value.protest_link,
        'amount_fee': value.amount_fee,
        'pre_capture': value.pre_capture,
        'form_payment': value.form_payment,
        'success_return_url': value.success_return_url,
        'error_return_url': value.error_return_url,
        'has_split_rules': value.has_split_rules,
        'split_rules': value.split_rules === undefined ? undefined : ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
        'confirmation_required': value.confirmation_required,
        'extract_identification': value.extract_identification,
        'active_3ds': value.active_3ds,
        'risk_custom_field': value.risk_custom_field,
        'service_id': value.service_id,
        'protocol': value.protocol,
    };
}


