import React from 'react';

import { VStack, Divider, HStack, Flex, Box } from '@chakra-ui/layout';
import { maskCpfOrCnpj, maskMoney, maskDate } from 'services/masks.service';
import { GetSaleDto } from '../../clients';
import Text from '../Text';

type ReceiptProps = {
	sale: GetSaleDto;
};

const ReceiptTemplate: React.FC<ReceiptProps> = ({ sale }) => {
	const {
		amount,
		payment_day,
		description,
		payment_type_code,
		seller,
		original_amount,
		absorb_costs,
		installments,
		payer_name,
		payer_document,
		payer_phone,
		payment_type_description,
	} = sale;
	const { address } = seller;

	const costAbsorbed = absorb_costs?.find((cost) => cost === payment_type_code);

	const formatDate = (param) => {
		const date = new Date(param)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

		return param
			? `${maskDate(date[0] ? date[0].split('-').reverse().join('-') : '-/-/-')} às ${date[1] ? date[1]?.substr(0, 5) : '-:-'}`
			: '-';
	};

	return (
		<VStack spacing={4}>
			<Flex flexDir={`column`} w={'100%'}>
				<Text id='emiter-text' textAlign='center' fontSize='md' color='black' fontWeight='bold' mb={3}>
					Emitente
				</Text>
				<VStack align='flex-start'>
					<HStack>
						<Text id='seller-name-text' fontSize='sm' fontWeight='bold' color='black'>
							{seller?.name || ''}
						</Text>
					</HStack>
					<HStack>
						<Text id='cpf-cnpj-text' fontSize='sm' fontWeight='bold' color='black'>
							CPF / CNPJ:
						</Text>
						<Text id='data-seller-document-text' fontSize='sm' fontWeight='bold' color='black'>
							{maskCpfOrCnpj(seller?.document) || ''}
						</Text>
					</HStack>
					<HStack>
						<Text id='phone-text' fontSize='sm' fontWeight='bold' color='black'>
							Telefone:
						</Text>
						<Text id='data-seller-phone-text' fontSize='sm' fontWeight='bold' color='black'>
							{seller?.phone || ''}
						</Text>
					</HStack>
					<HStack>
						<Text id='address-text' fontSize='sm' fontWeight='bold' color='black'>
							Endereço:
						</Text>
						<Text id='data-address-street-number-city-state-text' fontSize='sm' fontWeight='bold' color='black'>
							{`${address?.street}, ${address?.number} - ${address?.city}, ${address?.state}` || ''}
						</Text>
					</HStack>
				</VStack>
			</Flex>

			<Flex flexDir={`column`} w={'100%'}>
				<Box display='flex' flexDir='row' justifyContent='space-between' alignContent='center' mb={3}>
					<Text id='financial-intermediary-text' fontSize='md' color='black' fontWeight='bold'>
						Intermediadora Financeira
					</Text>
					<Text id='notary-user-text' fontSize='md' color='black' fontWeight='bold'>
						Usuário do Cartório
					</Text>
				</Box>

				<HStack justify='space-between' pb={2}>
					<VStack align='flex-start'>
						<HStack>
							<Text id='name-text' fontSize='sm' fontWeight='bold' color='black'>
								Nome:
							</Text>
							<Text id='parcela-express-service-text' fontSize='sm' fontWeight='bold' color='black'>
								Parcela Express Serviços Digitais SA
							</Text>
						</HStack>
						<HStack>
							<Text id='cpf-cnpj-text' fontSize='sm' fontWeight='bold' color='black'>
								CPF / CNPJ:
							</Text>
							<Text id='cnpj-parcela-text' fontSize='sm' fontWeight='bold' color='black'>
								32.602.300/0001-37
							</Text>
						</HStack>
					</VStack>

					<VStack align='flex-start'>
						{payer_name && (
							<HStack>
								<Text id='payer-name-text' fontSize='sm' fontWeight='bold' color='black'>
									Nome:
								</Text>
								<Text id='data-payer-name-text' fontSize='sm' fontWeight='bold' color='black'>
									{payer_name || ''}
								</Text>
							</HStack>
						)}
						{payer_document && (
							<HStack>
								<Text id='payer-document-text' fontSize='sm' fontWeight='bold' color='black'>
									CPF:
								</Text>
								<Text id='data-mask-payer-document-text' fontSize='sm' fontWeight='bold' color='black'>
									{maskCpfOrCnpj(payer_document) || ''}
								</Text>
							</HStack>
						)}
						{payer_phone && (
							<HStack>
								<Text id='payer-phone-text' fontSize='sm' fontWeight='bold' color='black'>
									Telefone:
								</Text>
								<Text id='data-payer-phone-text' fontSize='sm' fontWeight='bold' color='black'>
									{payer_phone || ''}
								</Text>
							</HStack>
						)}
					</VStack>
				</HStack>
			</Flex>

			<Flex flexDir={`column`} w={'100%'} pb={2}>
				<Text id='payer-data-text' textAlign='center' fontSize='md' color='black' fontWeight='bold' mb={3}>
					Dados de Pagamento
				</Text>
				<VStack flexDirection={'column'} alignItems='flex-start' color='black'>
					<HStack justifyContent={'space-between'} w={'100%'}>
						<Text id='sale-split-number-text' fontSize='sm' fontWeight='bold'>
							N° de Parcelas
						</Text>
						<Text id='data-sale-split-number-mask-text' fontSize='sm' fontWeight='bold'>
							{installments}x de R$ {maskMoney(original_amount / installments / 100)}
						</Text>
					</HStack>
					<HStack justifyContent={'space-between'} w={'100%'}>
						<Text id='payment-type-text' fontSize='sm' fontWeight='bold'>
							Forma de Pagamento
						</Text>
						<Text id='data-payment-type-description-text' fontSize='sm' fontWeight='bold'>
							{payment_type_description ? payment_type_description : ''}
						</Text>
					</HStack>
					<HStack justifyContent={'space-between'} w={'100%'}>
						<Text id='payment-description-text' fontSize='sm' fontWeight='bold'>
							N° do Pedido
						</Text>
						<Text id='data-payment-description-text' fontSize='sm' fontWeight='bold'>
							{description}
						</Text>
					</HStack>
					<HStack justifyContent={'space-between'} w={'100%'}>
						<Text id='payment-day-text' fontSize='sm' fontWeight='bold'>
							Data de Pagamento
						</Text>
						<Text id='data-payment-day-formated-text' fontSize='sm' fontWeight='bold'>
							{formatDate(payment_day)}
						</Text>
					</HStack>
					<HStack justifyContent={'space-between'} w={'100%'}>
						<Text id='total-price-text' fontSize='sm' fontWeight='bold'>
							Preço Total
						</Text>
						<Text id='data-mask-original-amount-text' fontSize='sm' fontWeight='bold' color='black'>
							R$ {maskMoney(original_amount / 100)}
						</Text>
					</HStack>

					<>
						<HStack justify='space-between' w='100%'>
							<Text id='amount-seller-text' fontSize='sm' fontWeight='bold' color='black'>
								Total destinado ao cartório:
							</Text>
							<Text id='data-mask-money-amount-text' fontSize='sm' fontWeight='bold' color='black'>
								R$ {maskMoney(amount / 100)}
							</Text>
						</HStack>
						<HStack justify='space-between' w='100%'>
							<Text id='original-amount-text' fontSize='sm' fontWeight='bold' color='black'>
								Total a pagar com acréscimo:
							</Text>
							<Text id='data-mask-money-original-amount-text' fontSize='sm' fontWeight='bold' color='black'>
								R$ {maskMoney(original_amount / 100)}
							</Text>
						</HStack>
						<HStack justify='space-between' w='100%'>
							<Text id='operation-cost-text' fontSize='sm' fontWeight='bold' color='black'>
								Custo financeiro da operação:
							</Text>
							<Text id='cost-absorbed-mask-amount-text' fontSize='sm' fontWeight='bold' color='black'>
								R$ {!costAbsorbed ? maskMoney((original_amount - amount) / 100) : '00,00'}
							</Text>
						</HStack>
					</>
				</VStack>
			</Flex>

			<VStack align='flex-start'>
				<Text id='parcela-sale-rules-text' fontSize='sm' fontWeight='bold' color='black' pb={2}>
					Pagamento processado pelo Parcela Express. Em caso de dúvida entre em contato pelo telefone (31) 3264-0337 ou consulte nossos
					termos de uso e política de privacidade em www.parcelaexpress.com.br
				</Text>
				<Text id='parcela-sale-rules-assign-text' alignSelf='flex-start' fontWeight='bold' fontSize='sm' color='black' pb={2}>
					Assinatura do Cliente: ________________________________________
				</Text>
			</VStack>
			<Divider border={'1px'} fontWeight='bold' backgroundColor={'black'} orientation='horizontal' variant={'dashed'} />
		</VStack>
	);
};

export default ReceiptTemplate;
