export const saleStatus = {
	pending: 'Pendente',
	pending_cancel: 'Cancelamento Pendente',
	pending_capture: 'Captura Pendente',
	in_progress_capture: 'Captura em andamento',
	succeeded: 'Aprovado',
	canceled: 'Cancelado',
	canceled_done: 'Cancelado',
	abort: 'Abortado',
	paid: 'Pago',
	failed: 'Falho',
	waiting_confirmation: 'Confirmação Pendente',
	expired: 'Expirado',
	waiting_cashIn: 'Aguardando Pagamento',
};

export const shippingReturnStatus = {
	pending: 'Pendente',
	succeeded: 'Processado',
	failed: 'Falha no processamento',
};

export const saleColorStatus = {
	pending: 'pending',
	succeeded: 'succeeded',
	canceled: 'error',
	canceled_done: 'error',
	abort: 'error',
	failed: 'error',
};

export const paymentTypeEnum = {
	credit: 'Crédito',
	credit1x: 'Crédito 1x',
	credit2x: 'Crédito 2x',
	credit3x: 'Crédito 3x',
	credit4x: 'Crédito 4x',
	credit5x: 'Crédito 5x',
	credit6x: 'Crédito 6x',
	credit7x: 'Crédito 7x',
	credit8x: 'Crédito 8x',
	credit9x: 'Crédito 9x',
	credit10x: 'Crédito 10x',
	credit11x: 'Crédito 11x',
	credit12x: 'Crédito 12x',
	credit13x: 'Crédito 13x',
	credit14x: 'Crédito 14x',
	credit15x: 'Crédito 15x',
	credit16x: 'Crédito 16x',
	credit17x: 'Crédito 17x',
	credit18x: 'Crédito 18x',
	billet: 'Boleto',
	pix: 'Pix',
	debit: 'Débito',
	PIX: 'Pix',
};

export const paymentMethodEnum = {
	boleto: 'Boleto',
	pos: 'Pos',
	payment_link: 'Link de Pagamento',
	protest_link: 'Link de Protesto',
	online: 'Online',
	pix: 'Pix',
	auto_generate_payment_link: 'Plataforma de Cobrança',
	billet_code: 'Boleto Híbrido',
	bill_payment: 'Pagamento de Contas',
};

export const settlementStatus = {
	pending: 'Pendente',
	paid: 'Pago',
	sent: 'Enviado',
	pending_cancel: 'Cancelamento Pendente',
	canceled: 'Cancelado',
	failed: 'Falhado',
	resent: 'Reenviado',
};

export const userStatus = {
	active: 'Ativo',
	inactive: 'Inativo',
	pending: 'Pendente',
};

export const sellerStatus = {
	activated: 'Ativo',
	inactive: 'Inativo',
	pending: 'Pendente',
	activation_sent: 'Pendente de Ativação',
};

export const listPosStatus = {
	active: 'Ativo',
	broken: 'Quebrado',
	canceled: 'Cancelado',
	inventory: 'Inventário',
	stock_for_repair: 'Estoque Para Conserto',
	repair: 'Conserto',
	lost: 'Extraviada'
};

export const salePlanType = {
	markup: 'Markup',
	spread: 'Spread',
	split: 'Split',
};

export const paymentMethods = {
	card: 'Cartão',
	pix: 'Pix',
	billet: 'Boleto',
};

export const brandEnum = [
	{
		key: 'visa',
		value: 'visa',
		text: 'Visa',
	},
	{
		key: 'elo',
		value: 'elo',
		text: 'Elo',
	},
	{
		key: 'mc',
		value: 'mc',
		text: 'Mc',
	},
	{
		key: 'amex',
		value: 'amex',
		text: 'Amex',
	},
	{
		key: 'hipercard',
		value: 'hipercard',
		text: 'Hipercard',
	},
	{
		key: 'default',
		value: 'default',
		text: 'Padrão',
	},
];

export const feeTypeEnum = [
	{
		key: 'fixed',
		value: 'fixed',
		text: 'Fixo',
	},
	{
		key: 'percent',
		value: 'percent',
		text: 'Porcentagem',
	},
];

export const costOptionFeeTypeEnum = {
	fixed: 'Fixo',
	percent: 'Percentual',
};

export const cardBrandEnum = {
	visa: 'Visa',
	elo: 'Elo',
	mc: 'Master Card',
	amex: 'Amex',
	hipercard: 'Hipercard',
	aura: 'Aura',
	diners: 'Diners',
	discover: 'Discover',
	jcb: 'JCB',
	default: 'Padrão',
	mastercard: 'Master Card',
};

export const costPlanTypeStatusEnum = {
	paid: 'Pago',
	pending: 'Pendente',
};

export const protocolStatusEnum = {
	paid: 'Pago',
	pending: 'Pendente',
	canceled: 'Cancelado',
	alert: 'Alerta',
	overdued: 'Vencido sem pagamento',
};

export const protocolPaymentMethodEnum = {
	credit: 'Crédito',
	credit_in_installments: 'Crédito Parcelado',
	debit: 'Débito',
	billet: 'Boleto',
	pix: 'Pix',
	transference: 'Transferência',
	cash: 'Dinheiro',
	bank_check: 'Cheque Bancário',
};

export const protocolPaymentTypeEnum = {
	boleto: 'Boleto',
	pos: 'POS',
	payment_link: 'Link de Pagamento',
	protest_link: 'Link de Protesto',
	online: 'Online',
	auto_generate_payment_link: 'Link de Pagamento Auto Gerável',
	auto_generate_protest_link: 'Link de Protesto Auto Gerável',
	others: 'Outros',
};

export const protocolHistoryTypeEnum = {
	status: 'Status',
	due_date: 'Data de Vencimento',
};

export const userType = {
	seller: 'Estabelecimento',
	merchant: 'Merchant',
	backoffice: 'Backoffice',
};

export const subjectTitle = {
	PageNotFound: 'Pagina não encontrada',
	BackofficePos: 'Backoffice POS',
	BackofficeUsers: 'Usuários Backoffice',
	Billet: 'Boleto',
	Pix: 'Pix',
	Calendar: 'Calendário',
	CancelSale: 'Estornar a venda',
	ChangeSeller: 'Selecionar Estabelecimento',
	CostPlanDetails: 'Detalhes do Plano de Custo',
	CostPlans: 'Planos de Custo',
	CreateCostPlans: 'Criar Planos de Custo',
	CreateSalesPlan: 'Criar Plano de Venda',
	CreateSeller: 'Criar Estabelecimento',
	Customization: 'Customização',
	Dashboard: 'Resumo',
	Fees: 'Pagamento de Tributos',
	Financial: 'Gestão Financeira',
	Link: 'Link de Pagamento',
	MyAccount: 'Minha Conta',
	MyData: 'Meus Dados',
	MyPlan: 'Meu Plano',
	POS: 'Venda POS',
	PaymentDetails: 'Detalhes de Pagamentos',
	PaymentsHistory: 'Histórico de Liquidação',
	Protocols: 'Protocolos',
	ProtocolsCustom: 'Customizar Protocolos',
	ProtocolsHistory: 'Histórico de Protocolos',
	ProtocolsManagement: 'Gestão de Protocolos',
	Reports: 'Relatórios',
	SaleDetails: 'Detalhes da Venda',
	SalesHistory: 'Histórico de Vendas',
	SalesHistoryPayment: 'Histórico de Pagamentos',
	SalesPlan: 'Planos de Venda',
	SalesPlanDetails: 'Detalhes do Planos de Venda',
	Sell: 'Gerar Venda',
	SellerCredentials: 'Credenciais Estabelecimento',
	Sellers: 'Estabelecimentos',
	Services: 'Cadastro de Serviços',
	Support: 'Suporte',
	Users: 'Usuários',
	Roles: 'Permissões',
	UploadRetFile: 'Arquivos Retorno',
	Merchants: 'Merchants',
	MerchantUsers: 'Usuários Merchant',
	EmailsCustom: 'Customização de emails',
	PaymentCheckoutTest: 'Teste Checkout',
	ReportCustomization: 'Personalização de Relatórios',
	Responsibles: 'Responsáveis',
	Goals: 'Metas',
	BillPaymentHistory: 'Histórico de Pagamento de Contas',
	BillPayment: 'Pagamento de Contas',
	CheckoutsCustomization: 'Personalização de Checkouts',
	MonthlyTransactionsAlerts: 'Alertas de transações mensais',
	AssignmentTransactionAlerts: 'Alertas Atribuições',
	Alerts: 'Alertas',
	Notifications: 'Notificações',
	PaperRollOrder: 'Pedido de Bobinas',
	FindDda: 'Localizar DDA',
	PosPassword: 'Senha Pos Android'
};

export const actionTitle = {
	manage: 'Gerenciar',
	create: 'Criar',
	read: 'Ler',
	update: 'Atualizar',
	delete: 'Deletar',
};

export const salesHistoryReport = {
	order_number: 'ID Parcela',
	created_at: 'Data/hora da venda',
	created_at_date: 'Data da venda',
	status_description: 'Status',
	description: 'Nº do pedido',
	form_payment_description: 'Forma de Pagamento',
	type_description: 'Meio de Pagamento',
	original_amount: 'Valor Bruto',
	fee_amount: 'Custo Financeiro',
	amount: 'Valor Líquido',
	operation_cost: 'Custo da Operação',
	fix_cost: 'Custo Fixo',
	payer_name: 'Pagador',
	payment_day: 'Data/hora do pagamento',
	payment_day_date: 'Data do pagamento',
	confirmation_day: 'Data da Confirmação do Serviço',
	settlement_day: 'Data de liquidação',
	generate_payer: 'Gerado pelo pagador',
	acquirer_reference: 'Nº do documento',
	id: 'ID',
	card_brand: 'Bandeira do cartão',
	created_by_user_name: 'Usuário',
	terminal_id: 'Máquina',
	seller_document: 'Documento',
	seller_id: 'ID do cartório',
	seller_code: 'Chave',
	seller_name: 'Estabelecimento',
	seller_description: 'Descrição do Estabelecimento',
	split_description: 'Descrição da Divisão',
};

export const salesHistoryTable = {
	seller_name: 'Estabelecimento',
	created_at: 'Data da Venda',
	status: 'Status',
	formatted_description: 'Nº do pedido',
	formatted_protocol: 'Descrição',
	form_payment: 'Forma de Pagamento',
	payment_method: 'Meio de Pagamento',
	original_amount: 'Valor Bruto',
	financial: 'Custo financeiro',
	amount: 'Valor Líquido',
	payer: 'Pagador',
	payment_day: 'Data do Pagamento',
	confirmation_day: 'Data da Confirmação do Serviço',
	settlement_day: 'Data de Liquidação',
	has_split_rules: 'Valor Dividido',
	generate_payer: 'Gerado pelo Pagador',
	id: 'ID',
	payer_email: 'Email do Pagador',
	payer_phone: 'Telefone do Pagador',
	expiration_day: 'Data de Expiração',
};

export const settlementBatchTransactionReport = {
	order_number: 'ID Parcela',
	id: 'ID do Lote',
	settlement_status: 'Status do Lote',
	description: 'Nº do Pedido',
	form_payment: 'Forma de Pagamento',
	type: 'Meio de Pagamento',
	original_amount: 'Valor Bruto',
	fee: 'Custo Financeiro',
	amount: 'Valor Líquido',
	payer: 'Pagador',
	created_at: 'Data/Hora de Criação',
	created_at_date: 'Data de Criação',
	payment_day: 'Data/Hora de Pagamento',
	payment_day_date: 'Data de Pagamento',
	confirmation_day: 'Data da Confirmação do Serviço',
	settlement_day: 'Data de Liquidação',
	generate_payer: 'Gerado Pelo Pagador',
	acquirer_reference: 'Nº do documento',
	seller_name: 'Razão Social',
	card_brand: 'Bandeira do cartão',
};

export const settlementBatchSaleReport = {
	order_number: 'ID Parcela',
	id: 'ID do Lote',
	settlement_status: 'Status do Lote',
	description: 'Nº do Pedido',
	form_payment: 'Forma de Pagamento',
	type: 'Meio de Pagamento',
	original_amount: 'Valor Bruto',
	fee: 'Custo Financeiro',
	amount: 'Valor Líquido',
	payer: 'Pagador',
	created_at: 'Data/Hora de Criação',
	created_at_date: 'Data de Criação',
	payment_day: 'Data/Hora de Pagamento',
	payment_day_date: 'Data de Pagamento',
	confirmation_day: 'Data da Confirmação do Serviço',
	settlement_day: 'Data de Liquidação',
	generate_payer: 'Gerado Pelo Pagador',
	acquirer_reference: 'Nº do documento',
	sale_id: 'ID da Venda',
};

export const settlementBatchSaleTable = {
	settlement_day: 'Data de Liquidação',
	status: 'Status do lote',
	formatted_description: 'Nº do Pedido',
	form_payment: 'Forma de Pagamento',
	payment_method: 'Meio de Pagamento',
	original_amount: 'Valor Bruto',
	financial: 'Custo Financeiro',
	amount: 'Valor Líquido',
	payer: 'Pagador',
	created_at: 'Data de Criação',
	payment_day: 'Data de Pagamento',
	generate_payer: 'Gerado Pelo Pagador',
	id: 'ID do Lote',
};

export const responsibleType = {
	support: 'Suporte',
	commercial: 'Comercial',
};

export const monthName = {
	January: 'Jan',
	February: 'Fev',
	March: 'Mar',
	April: 'Abr',
	May: 'Mai',
	June: 'Jun',
	Juiy: 'Jul',
	August: 'Ago',
	September: 'Set',
	October: 'Out',
	November: 'Nov',
	December: 'Dez',
};

export const sellerAssignments = {
	note: 'Notas',
	civil: 'Civil',
	protest: 'Protesto',
	properties: 'Imóveis',
	legal_entity: 'Pessoa jurídica',
};
