import React from 'react';

import { Flex, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';

import { ResponsibleApi } from '../../clients';

import Button from '../../components/Button';
import Paper from '../../containers/Paper';
import SampleTable from './components/SampleTable';
import Breadcrumb from 'components/Breadcrumb';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useHistory } from 'react-router-dom';

const Responsibles: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const responsiblesApi = new ResponsibleApi(apiConfig);
	const history = useHistory();

	const { isBackoffice } = useAuth();

	const [isLoading, setIsLoading] = React.useState(false);

	const [responsibles, setResponsibles] = React.useState<any>();

	const { addToast } = useToasts();

	async function fetchListResponsiblesRequest() {
		setIsLoading(true);

		try {
			const response = await responsiblesApi.listResponsible({});

			setResponsibles(response);
		} catch (error) {
			addToast('Erro ao buscar responsáveis', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchListResponsiblesRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Flex direction={['column', 'column', 'row']} justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Responsáveis
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>

				<Button
					id='register-responsible-button'
					title='Cadastrar responsáveis'
					isDisabled={!isBackoffice}
					bgColor='primary'
					onClick={() => {
						history.push(`/admin/responsaveis/criar`);
					}}
					className='button__register-seller'
				>
					Cadastrar Responsáveis
				</Button>
			</Flex>
			<Paper id='responsible-paper-text' p={6}>
				<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
					{responsibles && <SampleTable data={responsibles} />}
				</Flex>
			</Paper>
		</Flex>
	);
};

export default Responsibles;
