/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSellerPaymentProviderDto
 */
export interface GetSellerPaymentProviderDto {
    /**
     * 
     * @type {string}
     * @memberof GetSellerPaymentProviderDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSellerPaymentProviderDto
     */
    payment_provider: GetSellerPaymentProviderDtoPaymentProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum GetSellerPaymentProviderDtoPaymentProviderEnum {
    Adyen = 'adyen',
    Rede = 'rede'
}

export function GetSellerPaymentProviderDtoFromJSON(json: any): GetSellerPaymentProviderDto {
    return GetSellerPaymentProviderDtoFromJSONTyped(json, false);
}

export function GetSellerPaymentProviderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerPaymentProviderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'payment_provider': json['payment_provider'],
    };
}

export function GetSellerPaymentProviderDtoToJSON(value?: GetSellerPaymentProviderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'payment_provider': value.payment_provider,
    };
}


