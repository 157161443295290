/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSaleDto,
    GetSaleDtoFromJSON,
    GetSaleDtoFromJSONTyped,
    GetSaleDtoToJSON,
    ListSettlementResponseDTO,
    ListSettlementResponseDTOFromJSON,
    ListSettlementResponseDTOFromJSONTyped,
    ListSettlementResponseDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSettlementResponseDto
 */
export interface GetSettlementResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetSettlementResponseDto
     */
    current_page: number;
    /**
     * 
     * @type {number}
     * @memberof GetSettlementResponseDto
     */
    total_pages: number;
    /**
     * 
     * @type {number}
     * @memberof GetSettlementResponseDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof GetSettlementResponseDto
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof GetSettlementResponseDto
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof GetSettlementResponseDto
     */
    total_items: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSettlementResponseDto
     */
    has_more: boolean;
    /**
     * 
     * @type {Array<GetSaleDto>}
     * @memberof GetSettlementResponseDto
     */
    sales: Array<GetSaleDto>;
    /**
     * 
     * @type {number}
     * @memberof GetSettlementResponseDto
     */
    total_original_amount: number;
    /**
     * 
     * @type {number}
     * @memberof GetSettlementResponseDto
     */
    total_amount: number;
    /**
     * 
     * @type {ListSettlementResponseDTO}
     * @memberof GetSettlementResponseDto
     */
    settlement_batch: ListSettlementResponseDTO;
}

export function GetSettlementResponseDtoFromJSON(json: any): GetSettlementResponseDto {
    return GetSettlementResponseDtoFromJSONTyped(json, false);
}

export function GetSettlementResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSettlementResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_page': json['current_page'],
        'total_pages': json['total_pages'],
        'limit': json['limit'],
        'start': json['start'],
        'end': json['end'],
        'total_items': json['total_items'],
        'has_more': json['has_more'],
        'sales': ((json['sales'] as Array<any>).map(GetSaleDtoFromJSON)),
        'total_original_amount': json['total_original_amount'],
        'total_amount': json['total_amount'],
        'settlement_batch': ListSettlementResponseDTOFromJSON(json['settlement_batch']),
    };
}

export function GetSettlementResponseDtoToJSON(value?: GetSettlementResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_page': value.current_page,
        'total_pages': value.total_pages,
        'limit': value.limit,
        'start': value.start,
        'end': value.end,
        'total_items': value.total_items,
        'has_more': value.has_more,
        'sales': ((value.sales as Array<any>).map(GetSaleDtoToJSON)),
        'total_original_amount': value.total_original_amount,
        'total_amount': value.total_amount,
        'settlement_batch': ListSettlementResponseDTOToJSON(value.settlement_batch),
    };
}


