/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSellerAcceptedPaymentMethodsDto
 */
export interface GetSellerAcceptedPaymentMethodsDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerAcceptedPaymentMethodsDto
     */
    billet_payment_is_permitted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerAcceptedPaymentMethodsDto
     */
    qrcode_payment_is_permitted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSellerAcceptedPaymentMethodsDto
     */
    card_payment_is_permitted: boolean;
}

export function GetSellerAcceptedPaymentMethodsDtoFromJSON(json: any): GetSellerAcceptedPaymentMethodsDto {
    return GetSellerAcceptedPaymentMethodsDtoFromJSONTyped(json, false);
}

export function GetSellerAcceptedPaymentMethodsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerAcceptedPaymentMethodsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billet_payment_is_permitted': json['billet_payment_is_permitted'],
        'qrcode_payment_is_permitted': json['qrcode_payment_is_permitted'],
        'card_payment_is_permitted': json['card_payment_is_permitted'],
    };
}

export function GetSellerAcceptedPaymentMethodsDtoToJSON(value?: GetSellerAcceptedPaymentMethodsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billet_payment_is_permitted': value.billet_payment_is_permitted,
        'qrcode_payment_is_permitted': value.qrcode_payment_is_permitted,
        'card_payment_is_permitted': value.card_payment_is_permitted,
    };
}


