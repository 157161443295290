/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSellerThemeDto
 */
export interface UpdateSellerThemeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerThemeDto
     */
    primary_color?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerThemeDto
     */
    secondary_color?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerThemeDto
     */
    default_color?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerThemeDto
     */
    text_color?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSellerThemeDto
     */
    menu_text_color?: string;
}

export function UpdateSellerThemeDtoFromJSON(json: any): UpdateSellerThemeDto {
    return UpdateSellerThemeDtoFromJSONTyped(json, false);
}

export function UpdateSellerThemeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSellerThemeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primary_color': !exists(json, 'primary_color') ? undefined : json['primary_color'],
        'secondary_color': !exists(json, 'secondary_color') ? undefined : json['secondary_color'],
        'default_color': !exists(json, 'default_color') ? undefined : json['default_color'],
        'text_color': !exists(json, 'text_color') ? undefined : json['text_color'],
        'menu_text_color': !exists(json, 'menu_text_color') ? undefined : json['menu_text_color'],
    };
}

export function UpdateSellerThemeDtoToJSON(value?: UpdateSellerThemeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary_color': value.primary_color,
        'secondary_color': value.secondary_color,
        'default_color': value.default_color,
        'text_color': value.text_color,
        'menu_text_color': value.menu_text_color,
    };
}


