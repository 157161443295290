import React from 'react';

import { HStack } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillClockCircle } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToasts } from 'react-toast-notifications';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';
import { formatWithoutTimezone } from '../../../services/date.service';

import { maskMoney, maskPhoneNumber } from '../../../services/masks.service';
import { paymentMethods } from '../../../services/enums.service';

import Button from '../../../components/Button';
import CancelButton from './CancelButton';
import Text from '../../../components/Text';

const iconStatusLiteral = {
	paid: <AiFillCheckCircle color='#47FF62' size={16} />,
	active: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	expired: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({ data, setPagination, pagination, totalPages, onRowClick, afterCancel, seller }) => {
	const { addToast } = useToasts();

	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='cancel-link-title' column={column}>
						Cancelar Link
					</HeaderTitle>
				),
				accessor: 'id',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => (
					<CellContent
						id={`${index}-${id}-cell`}
						onClick={async (e) => {
							e.stopPropagation();
						}}
						className='cell-content__cancell'
					>
						{original.status === 'active' && (
							<CancelButton paymentLinkId={value} id={`${index}-${id}-cell-cancel-button`} seller={seller} afterCancel={afterCancel} />
						)}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='status-title' column={column}>
						Status
					</HeaderTitle>
				),
				accessor: 'formatted_status',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} align='left' className='cell-content__pendent'>
						<HStack>
							<Text id={`${index}-${id}-status-icon-text`}>{iconStatusLiteral[original.status]}</Text>
							<Text id={`${index}-${id}-status-is-active-text`}>{value === 'Ativo' ? 'Pendente' : value}</Text>
						</HStack>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='formatted-generated-by-payer-title' column={column}>
						Gerado pelo Pagador
					</HeaderTitle>
				),
				accessor: 'formatted_generated_by_payer',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__generated-by-payer'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='description-title' column={column}>
						N° do Pedido
					</HeaderTitle>
				),
				accessor: 'description',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} w='200px' color={`darkGrey`} className='cell-content__order-number'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='formatted-pre-capture' column={column}>
						Tem Pré-Captura
					</HeaderTitle>
				),
				accessor: 'formatted_pre_capture',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} w='200px' color={`darkGrey`} className='cell-content__order-number'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-methods-title' column={column}>
						Métodos de Pagamento
					</HeaderTitle>
				),
				accessor: 'payment_methods',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`}color={`darkGrey`} className='cell-content__payment-methods'>
						{value?.map((val) => paymentMethods[val]).toString()}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payer-name-title' column={column}>
						Pagador
					</HeaderTitle>
				),
				accessor: 'payer_name',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__payer'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='created-at-title' column={column}>
						Data de Criação
					</HeaderTitle>
				),
				accessor: 'created_at',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__creation-date'>
						{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='phone-title' column={column}>
						Telefone
					</HeaderTitle>
				),
				accessor: 'phone',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} minW={100} color={`darkGrey`} className='cell-content__phone'>
						{value ? maskPhoneNumber(value) : ''}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='updated-at-title' column={column}>
						Data de Pagamento
					</HeaderTitle>
				),
				accessor: 'updated_at',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__payment-date'>
						{original.status === 'paid' && value && formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='expires-in-title' column={column}>
						Data de Vencimento
					</HeaderTitle>
				),
				accessor: 'expires_in',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__due-date'>
						{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='form-payment-title' column={column}>
						Forma de Pagamento
					</HeaderTitle>
				),
				accessor: 'form_payment',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__payment-form'>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='note-title' column={column}>
						Obervação
					</HeaderTitle>
				),
				accessor: 'note',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__note'>
						{value?.length > 20 && `${value?.substring(0, 20)}...`}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='amount-title' column={column}>
						Total
					</HeaderTitle>
				),
				accessor: 'amount',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} color={`darkGrey`} className='cell-content__total'>{`R$ ${maskMoney(value)}`}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='url-title' column={column}>
						Referência
					</HeaderTitle>
				),
				accessor: 'url',
				Cell: ({
					cell: {
						value,
						row: { original, index },
					},
					column: { id },
				}) => (
					<CellContent id={`${index}-${id}-cell`} onClick={(e) => e.stopPropagation()} className='cell-content__reference'>
						<CopyToClipboard
							text={value}
							onCopy={() => {
								addToast('Link copiado com sucesso', {
									appearance: 'success',
									autoDismiss: true,
								});
							}}
						>
							<Button
								id={`${index}-${id}-copy-link-button`}
								bgColor={`white`}
								border='1px solid'
								borderColor={`primary`}
								color={`primary`}
								fontWeight={`medium`}
								px='4'
								height='auto'
							>
								Copiar link
							</Button>
						</CopyToClipboard>
					</CellContent>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			onRowClick={onRowClick}
			pl={2}
			isLimited={true}
			maxHeightTableWrapper={450}
		/>
	);
};
