import { BreadcrumbItem, BreadcrumbLink, Flex, Stack, Switch, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import { getApiAuthConfig } from 'services/api.service';
import { maskDate, maskMoney, unmaskCpfOrCnpj } from 'services/masks.service';
import { validSplitRulesAmount } from 'services/validations.service';
import * as yup from 'yup';

import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';
import Loader from 'containers/Loader';
import Paper from 'containers/Paper';
import SplitRulesWrapper from 'containers/SplitRulesWrapper';

import Breadcrumb from 'components/Breadcrumb';
import FormControl from 'components/Form/FormControl';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormLabel from 'components/Form/FormLabel';
import ReceiptModal from 'components/Receipt/ReceiptModal';
import Title from 'components/Title';

import {
	BilletsApi,
	CreateBilletDto,
	CreateBilletHybridRequest,
	CreateBilletPaymentV2Request,
	CreateSellerPayerRequest,
	CreateSplitRulesDto,
	GetMerchantSellerDto,
	GetSaleDto,
	GetSalePlanDto,
	GetSalePlanRequest,
	GetSellerSaleRequest,
	ListSellerBilletsRequest,
	SalesPlansApi,
	SellersApi,
} from '../../clients';
import Button from '../../components/Button';
import FormDatePickerInput from '../../components/Form/FormDatePickerInput';

import FormErrorMessage from '../../components/Form/FormErrorMessage';
import FormInput from '../../components/Form/FormInput';
import CreateSearchPayerWrapper from '../../containers/CreateSearchPayerWrapper';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';

import { round } from '../../services/math.service';
import BilletsTable from './components/BilletsTable';
import Modal from './components/Modal';
import NoBilletContainer from './components/NoBilletContainer';

export type SplitSellerValue = {
	amount: number;
	seller: GetMerchantSellerDto;
};

const BilletSale: React.FC = () => {
	const { user } = useAuth();
	const { currentSeller } = useCurrentSeller();
	const { addToast } = useToasts();

	const history = useHistory();

	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [billetsListLoading, setBilletsListLoading] = React.useState<boolean>(false);

	const [selectedPayer, setSelectedPayer] = React.useState<any[]>([]);
	const [createBillet, setCreateBillet] = React.useState<CreateBilletDto>({} as CreateBilletDto);

	const [openConfirmPaymentModal, setOpenConfirmPaymentModal] = React.useState(false);
	const [openReceiptModal, setOpenReceiptModal] = React.useState(false);
	const [receiptSaleInfo, setReceiptSaleInfo] = React.useState<GetSaleDto | undefined>(undefined);

	const [billetSales, setBilletSales] = React.useState<any[]>([]);

	const [updateTable, setUpdateTable] = React.useState(true);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [lastBilletTotalPages, setLastBilletTotalPages] = React.useState(1);

	const [hasSplitRules, setHasSplitRules] = React.useState(false);
	const [withPaymentLink, setWithPaymentLink] = React.useState(false);
	const [splits, setSplits] = React.useState<SplitSellerValue[]>([]);

	const [salePlan, setSalePlan] = React.useState<GetSalePlanDto>({} as GetSalePlanDto);

	const handleCreatePayerFormSubmit = async (values) => {
		try {
			setIsLoading(true);

			const apiConfig = getApiAuthConfig();

			const createPayerDto = {
				name: values.payer.name,
				document: values.payer.document,
				email: values.payer.email,
				phone: values.payer.phone,
				address: {
					zipcode: values.payer.address.zipcode,
					street: values.payer.address.street,
					number: values.payer.address.number,
					complement: values.payer.address.complement,
					district: values.payer.address.district,
					state: values.payer.address.state,
					city: values.payer.address.city,
				},
			};

			const createSellerPayerRequest: CreateSellerPayerRequest = {
				sellerId: currentSeller?.id!,
				createPayerDto,
			};

			const sellerApi = new SellersApi(apiConfig);
			await sellerApi.createSellerPayer(createSellerPayerRequest);
		} catch (e) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			setOpenConfirmPaymentModal(!openConfirmPaymentModal);
		}
	};

	const handlePayment = async () => {
		try {
			if (createBillet.value < 1) {
				addToast('Valor inválido', {
					appearance: 'error',
					autoDismiss: true,
				});
				return;
			}
			setIsLoading(true);

			const billetApiConfig = getApiAuthConfig();

			const billetsApi = new BilletsApi(billetApiConfig);
			let response;

			if (currentSeller?.billet_code && !withPaymentLink) {
				const createBilletPaymentRequest: CreateBilletHybridRequest = {
					sellerId: currentSeller?.id!,
					createBilletHybridDto: [createBillet],
				};

				if (hasSplitRules && splits.length) {
					const parsedSplits: CreateSplitRulesDto[] = splits.map((split) => ({ seller_id: split.seller.id, amount: split.amount }));
					createBilletPaymentRequest.createBilletHybridDto[0].has_split_rules = true;
					createBilletPaymentRequest.createBilletHybridDto[0].split_rules = parsedSplits;
				}

				response = await billetsApi.createBilletHybrid(createBilletPaymentRequest);
			} else {
				const createBilletPaymentRequest: CreateBilletPaymentV2Request = {
					sellerId: currentSeller?.id!,
					createBilletDto: [createBillet],
				};

				if (hasSplitRules && splits.length) {
					const parsedSplits: CreateSplitRulesDto[] = splits.map((split) => ({ seller_id: split.seller.id, amount: split.amount }));
					createBilletPaymentRequest.createBilletDto[0].has_split_rules = true;
					createBilletPaymentRequest.createBilletDto[0].split_rules = parsedSplits;
				}

				response = await billetsApi.createBilletPaymentV2(createBilletPaymentRequest);
			}

			if (response) {
				setUpdateTable(!updateTable);
				setSelectedPayer([]);
				setSplits([]);
			}
			addToast('Boleto emitido com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (err) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSplits([]);
			setHasSplitRules(false);
			setIsLoading(false);
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, { route: history.location.pathname });
	}

	const handleSubmit = async (values, isValid) => {
		if (!isValid) return;
		if (hasSplitRules && splits.length) {
			const roundedAmount = round(values.amount * 100);
			const isSplitsValid = validSplitRulesAmount(roundedAmount, splits);
			if (!isSplitsValid) {
				addToast('A soma das divisões não podem ser maiores que o valor da venda', {
					appearance: 'error',
					autoDismiss: true,
				});
				return;
			}
		}
		await handleCreatePayerFormSubmit(values);

		setCreateBillet({
			value: round(Number(values.amount) * 100),
			social_security_number: unmaskCpfOrCnpj(values.payer.document),
			shopper_statement: values.description,
			description: values.description,
			delivery_date: values.deliveryDate,
			shopper: {
				first_name: values.payer.name.split(' ')[0],
				last_name: values.payer.name.split(' ').slice(1).join(' '),
				email: values.payer.email,
				phone: values.payer.phone,
				billing_address: {
					city: values.payer.address.city,
					house_number_or_name: values.payer.address.number,
					postal_code: values.payer.address.zipcode.replace('-', '').replace('.', ''),
					state_or_province: values.payer.address.state,
					street: values.payer.address.street,
					complement: values.payer.address.complement !== '' ? values.payer.address.complement : 'N/A',
					district: values.payer.address.district,
				},
			},
			with_payment_link: withPaymentLink,
		});

		setOpenConfirmPaymentModal(!openConfirmPaymentModal);
	};

	const triggerReceiptModal = async (saleId: string) => {
		setIsLoading(true);

		try {
			const apiConfig = getApiAuthConfig();
			const sellersApi = new SellersApi(apiConfig);

			const requestParams: GetSellerSaleRequest = {
				sellerId: currentSeller?.id!,
				saleId,
			};

			const response = await sellersApi.getSellerSale(requestParams);
			setReceiptSaleInfo(response);
			setOpenReceiptModal(true);
		} catch (error) {
			addToast('Erro ao buscar os dados para exibir recibo', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const billetInitialValues = {
		amount: '',
		description: '',
		deliveryDate: '',
		payer: {
			name: '',
			document: '',
			email: '',
			phone: '',
			address: { zipcode: '', street: '', district: '', city: '', state: '', complement: '', number: '' },
		},
		search: {
			payer: '',
			document: '',
		},
	};

	const billetFee = salePlan.payment_types?.find((type) => type.code === 'billet');

	const schema = yup.object().shape({
		amount: yup
			.number()
			.min(billetFee?.fee || 2.4, `Valor mínimo R$${maskMoney(billetFee?.fee || 2.4)}`)
			.required('Campo obrigatório'),
		description: yup.string().required('Campo obrigatório'),
		deliveryDate: yup.string().required('Campo obrigatório'),
		payer: yup.object().shape({
			name: yup.string().isCompleteName('Obrigatório nome completo').required('Campo obrigatório'),
			document: yup.string().isCpfOrCnpjOptional('Documento inválido').required('Campo obrigatório'),
			email: yup.string().required('Campo obrigatório'),
			phone: yup.string().required('Campo obrigatório'),
			address: yup.object().shape({
				zipcode: yup.string().required('Campo obrigatório'),
				street: yup.string().required('Campo obrigatório'),
				district: yup.string().required('Campo obrigatório'),
				city: yup.string().required('Campo obrigatório'),
				state: yup.string().required('Campo obrigatório'),
				complement: yup.string(),
				number: yup.string().required('Campo obrigatório'),
			}),
		}),
	});

	React.useEffect(() => {
		(async function () {
			if (!currentSeller) {
				return;
			}

			setBilletsListLoading(true);

			const apiConfig = getApiAuthConfig();

			const listSellerBilletsRequest: ListSellerBilletsRequest = {
				currentPage: pagination.currentPage,
				limit: pagination.limit,
				sellerId: currentSeller?.id!,
			};

			const salePlanRequest: GetSalePlanRequest = {
				salePlanId: currentSeller?.sale_plan_id!,
			};

			const sellerApi = new SellersApi(apiConfig);
			const salesPlanApi = new SalesPlansApi(apiConfig);

			const responseBilletsList = await sellerApi.listSellerBillets(listSellerBilletsRequest);
			const responseSalePlan = await salesPlanApi.getSalePlan(salePlanRequest);

			if (responseBilletsList && responseSalePlan) {
				const filteredResults = responseBilletsList.results.map((response) => {
					const filterResponse = JSON.parse(JSON.stringify({ response }));

					const date = new Date(filterResponse.response?.created_at)
						?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
						?.split(' ');

					const expireDate = new Date(filterResponse.response?.expires_at)
						?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
						?.split(' ');

					return {
						id: filterResponse.response.id,
						sale_id: filterResponse.response.sale_id,
						status: filterResponse.response.status,
						description: filterResponse.response.description,
						created_at:
							date && `${maskDate(date[0].split('-').reverse().join('-'))} às ${date[1] ? date[1]?.replace('Z', '').substr(0, 5) : '-:-'}`,
						expires_at: expireDate && `${maskDate(expireDate[0])}`,
						original_amount: filterResponse.response.original_amount,
						reference: filterResponse.response?.billet?.url,
						payer: filterResponse.response.payer,
						seller: filterResponse.response.seller,
						formatted_type: filterResponse.response.formatted_type,
					};
				});

				setBilletSales(filteredResults);
				setLastBilletTotalPages(responseBilletsList.total_pages);

				setSalePlan(responseSalePlan);

				setBilletsListLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateTable, currentSeller, user.token_id, pagination]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	if (currentSeller?.no_payment_methods?.includes('billet')) {
		return <NoBilletContainer />;
	}

	return (
		<>
			<Flex pb='8'>
				<Loader isOpen={isLoading} />

				<Breadcrumb separator={<ChevronRightIcon />}>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Venda
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink opacity='0.9' cursor='unset' href='#' fontSize='md' fontWeight='semibold'>
							Venda por Boleto
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Formik enableReinitialize initialValues={billetInitialValues} validationSchema={schema} onSubmit={handleSubmit}>
				{({ handleSubmit, setFieldValue, resetForm, errors, isValid, setValues }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Loader isOpen={billetsListLoading} />

							<Flex mb='4'>
								<Title id='billet-sale-text' fontSize={isMobile ? 'lg' : '2xl'}>
									VENDA POR BOLETO
								</Title>
							</Flex>

							{!currentSeller?.absorb_costs?.includes('billet') && (
								<Flex mt='4'>
									<Text id='billet-fee-text' color='blue.500' fontSize='sm' fontWeight='medium' isTruncated>
										O custo do boleto de R$ {maskMoney(billetFee?.fee || 2.4)} deverá ser adicionado ao valor do serviço caso a cartório não
										queira ser descontado.
									</Text>
								</Flex>
							)}

							<Flex flexDirection='column'>
								<Flex flexDir='column' p={10} bgColor='white' rounded='md' boxShadow='sm' mt='2' w='100%' className='form__main-infos'>
									<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb='4'>
										<FormControl isRequired>
											<FormLabel id='billet-amount-form-label' fontSize='md' fontWeight='medium'>
												Valor Total do Serviço
											</FormLabel>
											<FormCurrencyInput
												id='billet-amount-form-currency-input'
												name='amount'
												borderColor='darkGrey'
												fontSize='md'
												fontWeight='medium'
											/>
											<FormErrorMessage id='billet-amount-form-error-text' name='amount' />
										</FormControl>
										<FormControl m={1} isRequired>
											<FormLabel id='description-form-label' fontSize='md' fontWeight='medium'>
												Descrição
											</FormLabel>
											<FormInput id='billet-sale-description-form-input' name='description' fontSize='md' fontWeight='medium' />
											<FormErrorMessage id='billet-description-form-error-text' name='description' />
										</FormControl>
										{/*
										<FormControl m={1}>
											<FormLabel>Tipo de Serviço</FormLabel>
											<Select />
										</FormControl>
										*/}
										<FormControl m={1} isRequired>
											<FormLabel id='due-date-form-label' fontSize='md' fontWeight='medium'>
												Data de Vencimento
											</FormLabel>
											<FormDatePickerInput
												id='billet-sale-date-picker-input'
												name='deliveryDate'
												placeholder='Informe o período'
												minDate={new Date()}
												borderColor='darkGrey'
												fontSize='md'
												fontWeight='medium'
											/>
											<FormErrorMessage id='billet-delivery-date-form-error-text' name='deliveryDate' />
										</FormControl>
									</Stack>
								</Flex>

								<Paper id='sale-payment-link-paper-text'>
									<FormControl display='flex' alignItems='center'>
										<FormLabel id='payment-link-sale-form-label' htmlFor='email-alerts' mb='0' color='primary' fontWeight='bold'>
											<Text id='payment-link-sale-text' fontSize='md' fontWeight='medium'>
												Venda com link de Pagamento?
											</Text>
										</FormLabel>
										<Switch
											id='payer-data'
											isChecked={withPaymentLink}
											onChange={() => {
												setWithPaymentLink(!withPaymentLink);
												setFieldValue('withPaymentLink', !withPaymentLink);
											}}
											name='withPaymentLink'
										/>
									</FormControl>
									{currentSeller?.has_split_rules && (
										<FormControl display='flex' alignItems='center'>
											<FormLabel id='split-sale-label' htmlFor='email-alerts' mb='0' color='primary' fontWeight='bold'>
												<Text id='split-sale-text' fontSize='md' fontWeight='medium'>
													Dividir valor da venda?
												</Text>
											</FormLabel>
											<Switch
												id='payer-data'
												isChecked={hasSplitRules}
												onChange={() => {
													setHasSplitRules(!hasSplitRules);
													setFieldValue('hasSplitRules', !hasSplitRules);
												}}
												name='hasSplitRules'
											/>
										</FormControl>
									)}
									{hasSplitRules && <SplitRulesWrapper splits={splits} setSplits={setSplits} />}
								</Paper>

								<Flex mt='8'>
									<Title id='payer-data-title-text'>Dados do pagador</Title>
								</Flex>
								<Flex flexDir='column' p={10} bgColor='white' rounded='md' boxShadow='sm' mt='4' w='100%' className='form__payer-data'>
									<CreateSearchPayerWrapper
										selectedPayer={selectedPayer}
										setSelectedPayer={setSelectedPayer}
										setFieldValue={setFieldValue}
										errors={errors}
										requiredFields={{
											document: true,
											name: true,
											email: true,
											phone: true,
											address: {
												zipcode: true,
												street: true,
												district: true,
												city: true,
												state: true,
												number: true,
											},
										}}
									/>
									<FormControl display='flex' flexDirection='column' alignItems={isMobile ? 'center' : 'flex-end'} mt='4'>
										<Button
											id='emit-billet-button'
											size='lg'
											type='submit'
											disabled={!isValid}
											bgColor='primary'
											className='button__generate-billet'
										>
											Emitir Boleto
										</Button>
									</FormControl>
								</Flex>
								<Flex mt='8'>
									<Title id='billet-title-text'>Boletos Gerados</Title>
								</Flex>

								<Flex flexDir='column' p={10} bgColor='white' rounded='md' boxShadow='sm' mt='2' w='100%'>
									<BilletsTable
										onRowClick={(row) => navigateToDetails(row.original.sale_id)}
										data={billetSales}
										setPagination={setPagination}
										pagination={pagination}
										totalPages={lastBilletTotalPages}
										seller={currentSeller}
										openReceiptModal={triggerReceiptModal}
										setValues={setValues}
										setIsLoading={setIsLoading}
									/>
								</Flex>
							</Flex>
							<Modal
								openConfirmPaymentModal={openConfirmPaymentModal}
								handlePayment={handlePayment}
								setOpenConfirmPaymentModal={setOpenConfirmPaymentModal}
								resetForm={resetForm}
								isLoading={isLoading}
							/>
							<ReceiptModal sale={receiptSaleInfo!} openReceiptModal={openReceiptModal} setOpenReceiptModal={setOpenReceiptModal} />
						</form>
					);
				}}
			</Formik>
		</>
	);
};

export default BilletSale;
