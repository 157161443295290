import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { ChevronRightIcon } from '@chakra-ui/icons';
import {
	AcquirersApi,
	CreateAddressDto,
	CreateBankAccountDto,
	CreateSellerCredentialDto,
	CreateSellerCredentialRequest,
	EditSellerCredentialDto,
	EditSellerCredentialRequest,
	EditSellerDto,
	EditSellerDtoBilletProviderEnum,
	EditSellerDtoSettlementTypeEnum,
	EditSellerDtoStatusEnum,
	EditSellerDtoTypeEnum,
	GetAcquirerDto,
	GetSellerCredentialResponseDto,
	GetSellerDto,
	GetSellerDtoStatusEnum,
	SellersApi,
} from 'clients';
import { Formik } from 'formik';
import { getApiAuthConfig } from 'services/api.service';

import Loader from 'containers/Loader';
import NewModal from 'containers/NewModal';
import Paper from 'containers/Paper';

import Button from 'components/Button';

import ActivationModal from './components/ActivationModal';
import CredentialsForm from './components/CredentialsForm';
import CredentialsTable from './components/CredentialsTable';
import DeleteCredentialModal from './components/DeleteCredentialModal';
import Text from '../../components/Text';

const SellerCredentials: React.FC = () => {
	const { id } = useParams();
	const { addToast } = useToasts();
	const [seller, setSeller] = React.useState<GetSellerDto | undefined>(undefined);
	const [credentials, setCredentials] = React.useState<GetSellerCredentialResponseDto[] | undefined>(undefined);
	const [acquirers, setAcquirers] = React.useState<GetAcquirerDto[] | undefined>(undefined);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [openDeleteCredentialModal, setOpenDeleteCredentialModal] = React.useState<boolean>(false);
	const [deletingCredentialId, setDeletingCredentialId] = React.useState<string | undefined>(undefined);
	const [editingCredential, setEditingCredential] = React.useState<GetSellerCredentialResponseDto | undefined>(undefined);
	const [activationModalIsOpen, setActivationModalIsOpen] = React.useState<boolean>(false);
	const [activingSeller, setActivingSeller] = React.useState<GetSellerDto | undefined>(undefined);

	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const acquirersApi = new AcquirersApi(apiConfig);

	const getSeller = async () => {
		try {
			const response = await sellersApi.getSeller({ sellerId: id });

			setSeller(response);
		} catch (error) {
			addToast('Erro ao buscar informações do estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	const getCredential = async () => {
		try {
			const response = await sellersApi.listSellerCredential({ sellerId: id });

			setCredentials(response);
		} catch (error) {
			addToast('Erro ao buscar credenciais do estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	const getAcquirers = async () => {
		try {
			const response = await acquirersApi.listAcquirers();

			setAcquirers(response);
		} catch (error) {
			addToast('Erro ao buscar as adquirentes do estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	const fetchData = async () => {
		setIsLoading(true);

		await Promise.all([getSeller(), getCredential(), getAcquirers()]);

		setIsLoading(false);
	};

	React.useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValues = {
		api_key: '',
		client_id: '',
		acquirer_code: '',
	};

	if (editingCredential) {
		initialValues.api_key = editingCredential.api_key || '';
		initialValues.client_id = editingCredential.client_id || '';
	}

	const closeActivationModal = () => {
		setActivingSeller(undefined);
		setActivationModalIsOpen(!activationModalIsOpen);
	};

	const openActivationModal = async (id: string) => {
		setIsLoading(true);
		try {
			const editingSeller = await sellersApi.getSeller({ sellerId: id });

			setActivingSeller(editingSeller);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			setActivationModalIsOpen(!activationModalIsOpen);
		}
	};

	const confirmActivationSeller = async () => {
		setIsLoading(true);
		if (!activingSeller || !activingSeller.merchant.id) {
			return;
		}
		try {
			const editSellerDto: EditSellerDto = {
				merchant_id: activingSeller.merchant.id,
				sale_plan_id: activingSeller.sale_plan.id,
				status: EditSellerDtoStatusEnum.Activated,
				name: activingSeller.name,
				description: activingSeller.description,
				document: activingSeller.document,
				state_registration: activingSeller.state_registration,
				code: activingSeller.code,
				settlement_type: (activingSeller.settlement_type as unknown) as EditSellerDtoSettlementTypeEnum,
				address: (activingSeller.address as unknown) as CreateAddressDto,
				bank_account: (activingSeller.bank_account as unknown) as CreateBankAccountDto,
				monthly_billing: activingSeller.monthly_billing,
				covenant: activingSeller.covenant,
				is_split: activingSeller.is_split,
				phone: activingSeller.phone,
				absorb_costs: activingSeller.absorb_costs,
				type: (activingSeller.type as unknown) as EditSellerDtoTypeEnum,
				pix_pos: activingSeller.pix_pos,
				billet_provider: (activingSeller.billet_provider as unknown) as EditSellerDtoBilletProviderEnum,
				no_payment_methods: activingSeller.no_payment_methods,
				has_split_rules: activingSeller.has_split_rules,
				protocol_customizations: activingSeller.protocol_customizations,
			};

			await sellersApi.editSeller({ editSellerDto, sellerId: activingSeller.id });
		} catch (error) {
			addToast('Erro ao ativar estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setActivingSeller(undefined);
			setActivationModalIsOpen(!activationModalIsOpen);
			setIsLoading(false);
		}
	};

	const handleFormSubmit = async (values) => {
		if (!seller) {
			return;
		}

		if (editingCredential && editingCredential.id) {
			const updatePayload: EditSellerCredentialDto = {
				client_id: values.client_id,
				api_key: values.api_key,
			};
			const updateCredential: EditSellerCredentialRequest = {
				credentialId: editingCredential.id,
				editSellerCredentialDto: updatePayload,
				sellerId: seller.id,
			};
			try {
				setIsLoading(!isLoading);
				await sellersApi.editSellerCredential(updateCredential);

				await getCredential();
			} catch (error) {
				addToast('Erro ao salvar credenciais', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setEditingCredential(undefined);
				setIsOpen(!isOpen);
				if (seller.status === GetSellerDtoStatusEnum.ActivationSent) {
					openActivationModal(seller.id);
				}
				setIsLoading(false);
			}
		} else {
			const payload: CreateSellerCredentialDto = {
				acquirer_code: values.acquirer_code,
				client_id: values.client_id,
				api_key: values.api_key,
			};
			const createCredential: CreateSellerCredentialRequest = {
				sellerId: seller.id,
				createSellerCredentialDto: payload,
			};
			try {
				setIsLoading(!isLoading);
				await sellersApi.createSellerCredential(createCredential);

				await getCredential();
			} catch (error) {
				addToast('Erro ao salvar credenciais', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsOpen(!isOpen);
				setIsLoading(false);
			}
		}
	};

	const confirmDelete = (credentialId: string) => {
		setDeletingCredentialId(credentialId);
		setOpenDeleteCredentialModal(!openDeleteCredentialModal);
	};

	const deleteCredential = async () => {
		if (!deletingCredentialId || !seller) {
			return;
		}

		try {
			setIsLoading(true);
			await sellersApi.deleteSellerCredential({ sellerId: seller.id, credentialId: deletingCredentialId });

			await getCredential();
		} catch (error) {
			addToast('Erro ao excluir credencial', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setOpenDeleteCredentialModal(false);
			setDeletingCredentialId(undefined);
			setIsLoading(false);
		}
	};

	const cancelDelete = () => {
		setDeletingCredentialId(undefined);
		setOpenDeleteCredentialModal(false);
	};

	const closeFormModal = () => {
		setEditingCredential(undefined);
		setIsOpen(false);
	};

	const editCredential = async (credentialId: string) => {
		if (!seller) {
			return;
		}
		const sellerCredential = await sellersApi.getSellerCredential({ sellerId: seller.id, credentialId });
		setEditingCredential(sellerCredential);
		setIsOpen(true);
	};

	return (
		<Flex direction='column'>
			<Loader isOpen={isLoading} />

			<Flex direction={['column', 'column', 'row']} justify='space-between'>
				<Breadcrumb separator={<ChevronRightIcon />} color='darkGrey' mb='4'>
					<BreadcrumbItem>
						<Text id='resume-text' fontSize='md'>Resumo</Text>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md' href='/admin/estabelecimentos'>
							Estabelecimentos
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<Text id='credentials-text' fontSize='md' fontWeight='semibold'>
							Credenciais {seller ? seller.name : id}
						</Text>
					</BreadcrumbItem>
				</Breadcrumb>

				<Button
					id='register-credential-button'
					title='Cadastrar Credencial'
					isDisabled={false}
					bgColor='primary'
					onClick={() => setIsOpen(!isOpen)}
					className='button__register-seller'
				>
					Cadastrar Credencial
				</Button>
			</Flex>
			<Paper id='seller-credentials-table-paper-text' p={6}>
				<Flex flexDirection='column'>
					{credentials && credentials.length > 0 && (
						<CredentialsTable editCredential={editCredential} data={credentials} confirmDelete={confirmDelete} />
					)}
				</Flex>
			</Paper>

			{acquirers && (
				<NewModal onClose={() => closeFormModal()} isOpen={isOpen}>
					<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
						{({ handleSubmit, isValid }) => {
							return (
								<form onSubmit={handleSubmit}>
									<CredentialsForm editingCredential={editingCredential} acquirers={acquirers} isLoading={isLoading} isValid={isValid} />
								</form>
							);
						}}
					</Formik>
				</NewModal>
			)}

			<DeleteCredentialModal
				isLoading={isLoading}
				openDeleteCredentialModal={openDeleteCredentialModal}
				cancelDelete={cancelDelete}
				deleteCredential={deleteCredential}
			/>
			<ActivationModal
				confirmActivationSeller={confirmActivationSeller}
				isOpen={activationModalIsOpen}
				closeActivationModal={closeActivationModal}
			/>
		</Flex>
	);
};

export default SellerCredentials;
