import React from 'react';

import Steps, { Step } from 'rc-steps';

import 'rc-steps/assets/index.css';
import { GoCheck, GoX } from 'react-icons/go';
import { Icon } from '@chakra-ui/react';
import { GetSaleDto, GetSaleDtoStatusEnum } from '../../../clients';
import { maskDate } from '../../../services/masks.service';

const formatDate = (param) => {
	const date = new Date(param)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

	return `${maskDate(date[0] ? date[0].split('-').reverse().join('-') : '-/-/-')} às ${date[1] ? date[1]?.substr(0, 5) : '-:-'}`;
};

const formatDateWithoutHour = (param) => {
	const date = param.split(' ');

	return `${maskDate(date[0] ? date[0].split('-').reverse().join('-') : '-/-/-')}`;
};

const billetIsNotVisible = (form_payment, status, payment_day) => {
	let showPaymentDay = false;
	const billetIsNotVisible = form_payment === 'billet' && status !== 'pending';

	if ((billetIsNotVisible || form_payment !== 'billet') && payment_day && payment_day.length > 0) {
		showPaymentDay = true;
	}

	return showPaymentDay;
};

const saleHasConfirmationRequired = (sale: GetSaleDto) => {
	return sale.status === GetSaleDtoStatusEnum.WaitingConfirmation || !!sale.confirmation_day;
};

const saleIsCelcoing = (sale: GetSaleDto) => {
	return !!sale.celcoin;
}

const currentStep = (data: GetSaleDto) => {
	const { status } = data;
	const hasConfirmationRequired = saleHasConfirmationRequired(data);
	const isCelcoin = saleIsCelcoing(data);

	switch (status) {
		case 'pending' || 'pending_capture' || 'in_progress_capture' || 'failed':
			return data.payment_day ? 1 : 0;
		case 'waiting_confirmation':
			return 1;
		case 'waiting_cashIn':
			return 1;
		case 'succeeded':
			return !hasConfirmationRequired && !isCelcoin ? 2 : 3;
		case 'canceled' || 'pending_cancel' || 'abort':
			return !hasConfirmationRequired && !isCelcoin ? 3 : 4;
		default:
			return 0;
	}
};

export default (data: GetSaleDto) => {
	const hasConfirmationRequired = saleHasConfirmationRequired(data);
	const isCelcoin = saleIsCelcoing(data);

	return (
		<Steps
			icons={{ finish: <Icon as={GoCheck} />, error: <Icon as={GoX} /> }}
			direction='horizontal'
			current={currentStep(data)}
			status='finish'
			labelPlacement='vertical'
		>
			<Step title='Criação' description={data?.created_at && formatDate(data?.created_at)} />
			<Step
				title='Efetivação'
				description={billetIsNotVisible(data?.form_payment, data?.status, data?.payment_day) && formatDate(data?.payment_day)}
			/>
			{(isCelcoin) && (
				<Step
					title='Aguardando Pagamento'
					description={billetIsNotVisible(data?.form_payment, data?.status, data?.payment_day) && formatDate(data?.payment_day)}
				/>
			)}
			{hasConfirmationRequired && (
				<Step title='Confirmação' description={data?.confirmation_day ? formatDate(data?.confirmation_day) : ''} />
			)}
			{isCelcoin && (
				<Step title='Pagamento Realizado' description={(data?.updated_at && data.status === 'succeeded') ? formatDate(data?.updated_at) : ''} />
			)}
			{!isCelcoin && <Step title='Liquidação' description={data?.settlement_day && formatDateWithoutHour(data?.settlement_day)} status={data?.settlement_status === 'pending' ? 'wait': 'finish'} />}
			{(data?.status === 'canceled' || data?.status === 'pending_cancel' || data?.status === 'abort') && (
				<Step
					title='Estorno'
					description={data?.settlement_day_cancellation && formatDateWithoutHour(data?.settlement_day_cancellation)}
				/>
			)}
		</Steps>
	);
};
