/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesHistoryTablePermissions
 */
export interface SalesHistoryTablePermissions {
    /**
     * 
     * @type {number}
     * @memberof SalesHistoryTablePermissions
     */
    table: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesHistoryTablePermissions
     */
    fields: Array<SalesHistoryTablePermissionsFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesHistoryTablePermissions
     */
    denied_fields: Array<SalesHistoryTablePermissionsDeniedFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesHistoryTablePermissions
     */
    allowed_fields: Array<SalesHistoryTablePermissionsAllowedFieldsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum SalesHistoryTablePermissionsFieldsEnum {
    Id = 'id',
    Status = 'status',
    PaymentMethod = 'payment_method',
    FormattedDescription = 'formatted_description',
    FormattedProtocol = 'formatted_protocol',
    GeneratePayer = 'generate_payer',
    Payer = 'payer',
    CreatedAt = 'created_at',
    PaymentDay = 'payment_day',
    Financial = 'financial',
    ExpirationDay = 'expiration_day',
    SettlementDay = 'settlement_day',
    FormPayment = 'form_payment',
    OriginalAmount = 'original_amount',
    Amount = 'amount',
    SellerName = 'seller_name',
    HasSplitRules = 'has_split_rules',
    PayerEmail = 'payer_email',
    PayerPhone = 'payer_phone',
    ConfirmationDay = 'confirmation_day'
}/**
* @export
* @enum {string}
*/
export enum SalesHistoryTablePermissionsDeniedFieldsEnum {
    Id = 'id',
    Status = 'status',
    PaymentMethod = 'payment_method',
    FormattedDescription = 'formatted_description',
    FormattedProtocol = 'formatted_protocol',
    GeneratePayer = 'generate_payer',
    Payer = 'payer',
    CreatedAt = 'created_at',
    PaymentDay = 'payment_day',
    Financial = 'financial',
    ExpirationDay = 'expiration_day',
    SettlementDay = 'settlement_day',
    FormPayment = 'form_payment',
    OriginalAmount = 'original_amount',
    Amount = 'amount',
    SellerName = 'seller_name',
    HasSplitRules = 'has_split_rules',
    PayerEmail = 'payer_email',
    PayerPhone = 'payer_phone',
    ConfirmationDay = 'confirmation_day'
}/**
* @export
* @enum {string}
*/
export enum SalesHistoryTablePermissionsAllowedFieldsEnum {
    Id = 'id',
    Status = 'status',
    PaymentMethod = 'payment_method',
    FormattedDescription = 'formatted_description',
    FormattedProtocol = 'formatted_protocol',
    GeneratePayer = 'generate_payer',
    Payer = 'payer',
    CreatedAt = 'created_at',
    PaymentDay = 'payment_day',
    Financial = 'financial',
    ExpirationDay = 'expiration_day',
    SettlementDay = 'settlement_day',
    FormPayment = 'form_payment',
    OriginalAmount = 'original_amount',
    Amount = 'amount',
    SellerName = 'seller_name',
    HasSplitRules = 'has_split_rules',
    PayerEmail = 'payer_email',
    PayerPhone = 'payer_phone',
    ConfirmationDay = 'confirmation_day'
}

export function SalesHistoryTablePermissionsFromJSON(json: any): SalesHistoryTablePermissions {
    return SalesHistoryTablePermissionsFromJSONTyped(json, false);
}

export function SalesHistoryTablePermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesHistoryTablePermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'table': json['table'],
        'fields': json['fields'],
        'denied_fields': json['denied_fields'],
        'allowed_fields': json['allowed_fields'],
    };
}

export function SalesHistoryTablePermissionsToJSON(value?: SalesHistoryTablePermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'table': value.table,
        'fields': value.fields,
        'denied_fields': value.denied_fields,
        'allowed_fields': value.allowed_fields,
    };
}


