import { Flex, Heading, Box, FormControl, Stack } from '@chakra-ui/react';
import React from 'react';
import { useToasts } from 'react-toast-notifications';

import { Formik } from 'formik';
import { round } from 'services/math.service';
import { validSplitRulesAmount } from 'services/validations.service';

import {
	GetSaleDto,
	PaymentsApi,
	GetSellerDto,
	CapturePaymentRequest,
	CreateSplitRulesDto,
	ListSimulationRequest,
	SimulationApi,
} from '../../../clients';
import Button from '../../../components/Button';
import FormCurrencyInput from '../../../components/Form/FormCurrencyInput';
import FormDebouncedCurrencyInput from '../../../components/Form/FormDebouncedCurrencyInput';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormLabel from '../../../components/Form/FormLabel';
import NewModal from '../../../containers/NewModal';
import { useCurrentSeller } from '../../../contexts/SellerProvider';
import { getApiAuthConfig } from '../../../services/api.service';
import { maskMoney } from '../../../services/masks.service';
import yup from '../../../services/yup.service';
import SplitsCaptureList from './SplitsCaptureList';

type CaptureAmountModalProps = {
	sale?: GetSaleDto;
	seller?: GetSellerDto;
	afterSubmit: (updatedSale: GetSaleDto) => void;
};

export type CaptureAmountModalHandle = {
	open: () => void;
	close: () => void;
};

export type SplitSellerCapture = {
	amount: number;
	seller: {
		seller_id: string;
		seller_name: string;
		seller_code: string;
	};
};

const CaptureAmountModal: React.RefForwardingComponent<CaptureAmountModalHandle, CaptureAmountModalProps> = (
	{ sale, seller, afterSubmit },
	forwardedRef
) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const { currentSeller } = useCurrentSeller();

	React.useImperativeHandle(forwardedRef, () => ({
		open() {
			setIsOpen(true);
		},
		close() {
			setIsOpen(false);
		},
	}));

	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const paymentsApi = new PaymentsApi(apiConfig);
	const simulationApi = new SimulationApi(apiConfig);

	const initialValues = {
		amount: (sale?.amount && sale?.amount / 100) || '',
		splits: sale?.split_rules?.splits?.map((split: any) => ({
			seller: {
				seller_name: split.seller_name,
				seller_id: split.seller_id,
				seller_code: split.seller_code,
			},
			amount: split.amount / 100,
		})),
		original_amount: (sale?.original_amount && sale?.original_amount / 100) || '',
	};

	const schema = yup.object().shape({
		original_amount: yup
			.number()
			.min(0.01, 'O valor mínimo deve ser R$ 0,01')
			.max(sale?.original_amount! / 100, `O valor total com a taxa não pode ser superior a R$ ${maskMoney(sale?.original_amount! / 100)}`)
			.required('Campo obrigatório'),
		splits: yup.array().of(
			yup.object().shape({
				amount: yup.number().required('Campo obrigatório'),
			})
		),
	});

	async function handleCapture(values) {
		const amount = Number(values.original_amount);
		setIsLoading(true);

		try {
			const data: CapturePaymentRequest = {
				sellerId: seller?.id!,
				saleId: sale?.id!,
				capturePaymentDto: {
					amount: amount * 100,
				},
			};

			if (values.splits) {
				const parsedSplits: Array<CreateSplitRulesDto> = values.splits.map((split) => ({
					amount: round(Number(split.amount) * 100),
					seller_id: split?.seller?.seller_id,
				}));
				const is_valid = validSplitRulesAmount(Number(values.amount * 100), parsedSplits);

				if (!is_valid) {
					addToast('A soma das divisões não pode ser maior que o valor total da venda!', {
						appearance: 'error',
						autoDismiss: true,
					});
					setIsLoading(false);
					return;
				}
				data.capturePaymentDto.split_rules = parsedSplits;
			}
			const updatedSale = await paymentsApi.capturePayment(data);

			addToast('Solicitação de captura realizada', {
				appearance: 'success',
				autoDismiss: true,
			});

			afterSubmit(updatedSale);

			setIsOpen(false);
		} catch (e) {
			addToast('Erro ao capturar venda!', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<NewModal isOpen={isOpen} size='lg' onClose={() => {}}>
			<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
				<Flex p={8} flexDirection='column'>
					<Heading fontSize='md' paddingBottom={8}>
						Informe o valor a ser capturado
					</Heading>

					<Formik initialValues={initialValues} validationSchema={schema} onSubmit={async (values) => handleCapture(values)}>
						{({ handleSubmit, isValid, setFieldValue, values }) => {
							return (
								<form onSubmit={handleSubmit}>
									<Stack flexDirection='column' isInline alignItems='flex-end' justifyContent='center' fontSize='md' spacing={2}>
										<Stack flexDirection='row'>
											<FormControl isRequired margin='0.5rem'>
												<FormLabel id='value-label' fontSize='md' fontWeight='medium'>
													Valor
												</FormLabel>
												<FormDebouncedCurrencyInput
													id='capture-seller-amount-form-debounced-currency-input'
													isDisabled={isLoading}
													name='amount'
													placeholder='R$'
													afterDebounce={async () => {
														const amountCents = Number(values.amount) * 100;

														if (isNaN(amountCents)) {
															return;
														}
														setIsLoading(true);

														const SimulationRequest: ListSimulationRequest = {
															sellerId: currentSeller?.id!,
															amountCents: Number(amountCents),
														};

														const response = await simulationApi.listSimulation(SimulationRequest);
														const simulationCredit = response.find(
															(paymentType) => paymentType.type === 'credit' && paymentType.installment === sale?.installments
														);
														setFieldValue(
															'original_amount',
															simulationCredit?.total_amount_cents ? simulationCredit?.total_amount_cents / 100 : 0
														);
														setIsLoading(false);
													}}
												/>
												<FormErrorMessage id='capture-original-amount-form-error-text' name='original_amount' />
											</FormControl>
											<FormControl>
												<FormLabel id='capture-original-amount-label' fontSize='md' fontWeight='medium'>
													Valor Total Com Taxa
												</FormLabel>
												<FormCurrencyInput
													id='capture-original-amount-form-currency-input'
													isDisabled
													name='original_amount'
													borderColor='darkGrey'
													fontSize='md'
													fontWeight='medium'
												/>
											</FormControl>
										</Stack>
									</Stack>
									<Stack isInline spacing={2} marginLeft='0.5rem'>
										<Button
											id='submit-button'
											type='submit'
											size='md'
											bgColor='primary'
											disabled={isLoading || !isValid}
											isLoading={isLoading}
										>
											OK
										</Button>
										<Button id='cancel-button' size='md' disabled={isLoading} onClick={handleClose}>
											Cancelar
										</Button>
									</Stack>
									{sale?.split_rules?.splits && sale?.split_rules?.splits.length && (
										<SplitsCaptureList setFieldValue={setFieldValue} splits={sale?.split_rules?.splits} />
									)}
								</form>
							);
						}}
					</Formik>
				</Flex>
			</Box>
		</NewModal>
	);
};

export default React.forwardRef(CaptureAmountModal);
