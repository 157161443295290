/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Permission,
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    type: CreateRoleDtoTypeEnum;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof CreateRoleDto
     */
    permissions: Array<Permission>;
}

/**
* @export
* @enum {string}
*/
export enum CreateRoleDtoTypeEnum {
    Merchant = 'merchant',
    Seller = 'seller',
    Backoffice = 'backoffice'
}

export function CreateRoleDtoFromJSON(json: any): CreateRoleDto {
    return CreateRoleDtoFromJSONTyped(json, false);
}

export function CreateRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'type': json['type'],
        'permissions': ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
    };
}

export function CreateRoleDtoToJSON(value?: CreateRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'permissions': ((value.permissions as Array<any>).map(PermissionToJSON)),
    };
}


