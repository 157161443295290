/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDebitPaymentResponseDTO
 */
export interface CreateDebitPaymentResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateDebitPaymentResponseDTO
     */
    paymentData: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitPaymentResponseDTO
     */
    paymentMethodType: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitPaymentResponseDTO
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitPaymentResponseDTO
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitPaymentResponseDTO
     */
    resultCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitPaymentResponseDTO
     */
    sale_id: string;
}

export function CreateDebitPaymentResponseDTOFromJSON(json: any): CreateDebitPaymentResponseDTO {
    return CreateDebitPaymentResponseDTOFromJSONTyped(json, false);
}

export function CreateDebitPaymentResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDebitPaymentResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentData': json['paymentData'],
        'paymentMethodType': json['paymentMethodType'],
        'token': json['token'],
        'type': json['type'],
        'resultCode': json['resultCode'],
        'sale_id': json['sale_id'],
    };
}

export function CreateDebitPaymentResponseDTOToJSON(value?: CreateDebitPaymentResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentData': value.paymentData,
        'paymentMethodType': value.paymentMethodType,
        'token': value.token,
        'type': value.type,
        'resultCode': value.resultCode,
        'sale_id': value.sale_id,
    };
}


