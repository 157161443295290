/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateActiveNotificationRequestDto
 */
export interface UpdateActiveNotificationRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateActiveNotificationRequestDto
     */
    is_active: boolean;
}

export function UpdateActiveNotificationRequestDtoFromJSON(json: any): UpdateActiveNotificationRequestDto {
    return UpdateActiveNotificationRequestDtoFromJSONTyped(json, false);
}

export function UpdateActiveNotificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateActiveNotificationRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_active': json['is_active'],
    };
}

export function UpdateActiveNotificationRequestDtoToJSON(value?: UpdateActiveNotificationRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_active': value.is_active,
    };
}


