import React from 'react';

import Modal from 'containers/Modal';
import { PrintComponent } from 'components/Receipt/PrintReceipt';
import { GetSaleDto } from '../../clients';

type ReceiptModalProps = {
	sale: GetSaleDto;
	openReceiptModal: boolean;
	setOpenReceiptModal: (openReceiptModal: boolean) => void;
	isBillPayment?: boolean;
};

const ReceiptModal: React.FC<ReceiptModalProps> = ({ sale, openReceiptModal, setOpenReceiptModal, isBillPayment }) => {
	return (
		<Modal isOpen={openReceiptModal} maxW='70%'>
			<PrintComponent sale={sale} setOpenReceiptModal={setOpenReceiptModal} isBillPayment={isBillPayment} />
		</Modal>
	);
};

export default ReceiptModal;
