/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPaymentLinkMerchantDto
 */
export interface GetPaymentLinkMerchantDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkMerchantDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkMerchantDto
     */
    three_ds_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentLinkMerchantDto
     */
    min_three_ds?: number;
}

export function GetPaymentLinkMerchantDtoFromJSON(json: any): GetPaymentLinkMerchantDto {
    return GetPaymentLinkMerchantDtoFromJSONTyped(json, false);
}

export function GetPaymentLinkMerchantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentLinkMerchantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'three_ds_active': !exists(json, 'three_ds_active') ? undefined : json['three_ds_active'],
        'min_three_ds': !exists(json, 'min_three_ds') ? undefined : json['min_three_ds'],
    };
}

export function GetPaymentLinkMerchantDtoToJSON(value?: GetPaymentLinkMerchantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'three_ds_active': value.three_ds_active,
        'min_three_ds': value.min_three_ds,
    };
}


