/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncludePaymentTypeDto
 */
export interface IncludePaymentTypeDto {
    /**
     * 
     * @type {string}
     * @memberof IncludePaymentTypeDto
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof IncludePaymentTypeDto
     */
    fee: number;
    /**
     * 
     * @type {string}
     * @memberof IncludePaymentTypeDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof IncludePaymentTypeDto
     */
    installments: number;
    /**
     * 
     * @type {string}
     * @memberof IncludePaymentTypeDto
     */
    operation_mode: IncludePaymentTypeDtoOperationModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncludePaymentTypeDtoOperationModeEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    BilletCode = 'billet_code',
    PixPos = 'pix_pos'
}

export function IncludePaymentTypeDtoFromJSON(json: any): IncludePaymentTypeDto {
    return IncludePaymentTypeDtoFromJSONTyped(json, false);
}

export function IncludePaymentTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncludePaymentTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'fee': json['fee'],
        'description': json['description'],
        'installments': json['installments'],
        'operation_mode': json['operation_mode'],
    };
}

export function IncludePaymentTypeDtoToJSON(value?: IncludePaymentTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'fee': value.fee,
        'description': value.description,
        'installments': value.installments,
        'operation_mode': value.operation_mode,
    };
}


