/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ComparePosPasswordDto,
    ComparePosPasswordDtoFromJSON,
    ComparePosPasswordDtoToJSON,
    CreatePosPasswordDto,
    CreatePosPasswordDtoFromJSON,
    CreatePosPasswordDtoToJSON,
    ResponsePosPasswordDto,
    ResponsePosPasswordDtoFromJSON,
    ResponsePosPasswordDtoToJSON,
    UpdatePosPasswordDto,
    UpdatePosPasswordDtoFromJSON,
    UpdatePosPasswordDtoToJSON,
} from '../models';

export interface ComparePosPasswordRequest {
    comparePosPasswordDto: ComparePosPasswordDto;
}

export interface CreatePosPasswordRequest {
    createPosPasswordDto: CreatePosPasswordDto;
}

export interface DeletePosPasswordRequest {
    sellerId: string;
    id: string;
}

export interface GetPosPasswordRequest {
    sellerId: string;
}

export interface UpdatePosPasswordRequest {
    id: string;
    updatePosPasswordDto: UpdatePosPasswordDto;
}

/**
 * 
 */
export class PosPasswordApi extends runtime.BaseAPI {

    /**
     * Valida uma senha para acesso as funções administrativas do terminal android
     */
    async comparePosPasswordRaw(requestParameters: ComparePosPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.comparePosPasswordDto === null || requestParameters.comparePosPasswordDto === undefined) {
            throw new runtime.RequiredError('comparePosPasswordDto','Required parameter requestParameters.comparePosPasswordDto was null or undefined when calling comparePosPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/pos-password/compare`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ComparePosPasswordDtoToJSON(requestParameters.comparePosPasswordDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Valida uma senha para acesso as funções administrativas do terminal android
     */
    async comparePosPassword(requestParameters: ComparePosPasswordRequest): Promise<void> {
        await this.comparePosPasswordRaw(requestParameters);
    }

    /**
     * Gerar uma senha para acesso as funções administrativas do terminal android
     */
    async createPosPasswordRaw(requestParameters: CreatePosPasswordRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.createPosPasswordDto === null || requestParameters.createPosPasswordDto === undefined) {
            throw new runtime.RequiredError('createPosPasswordDto','Required parameter requestParameters.createPosPasswordDto was null or undefined when calling createPosPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePosPasswordDtoToJSON(requestParameters.createPosPasswordDto),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gerar uma senha para acesso as funções administrativas do terminal android
     */
    async createPosPassword(requestParameters: CreatePosPasswordRequest): Promise<object> {
        const response = await this.createPosPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deleta uma senha para acesso as funções administrativas do terminal android
     */
    async deletePosPasswordRaw(requestParameters: DeletePosPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling deletePosPassword.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePosPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos-password/delete/seller/{seller_id}/pos-password/{id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deleta uma senha para acesso as funções administrativas do terminal android
     */
    async deletePosPassword(requestParameters: DeletePosPasswordRequest): Promise<void> {
        await this.deletePosPasswordRaw(requestParameters);
    }

    /**
     * Pega a senha para acesso as funções administrativas do terminal android
     */
    async getPosPasswordRaw(requestParameters: GetPosPasswordRequest): Promise<runtime.ApiResponse<ResponsePosPasswordDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getPosPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos-password/seller/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponsePosPasswordDtoFromJSON(jsonValue));
    }

    /**
     * Pega a senha para acesso as funções administrativas do terminal android
     */
    async getPosPassword(requestParameters: GetPosPasswordRequest): Promise<ResponsePosPasswordDto> {
        const response = await this.getPosPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * Troca uma senha para acesso as funções administrativas do terminal android
     */
    async updatePosPasswordRaw(requestParameters: UpdatePosPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePosPassword.');
        }

        if (requestParameters.updatePosPasswordDto === null || requestParameters.updatePosPasswordDto === undefined) {
            throw new runtime.RequiredError('updatePosPasswordDto','Required parameter requestParameters.updatePosPasswordDto was null or undefined when calling updatePosPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos-password/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePosPasswordDtoToJSON(requestParameters.updatePosPasswordDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Troca uma senha para acesso as funções administrativas do terminal android
     */
    async updatePosPassword(requestParameters: UpdatePosPasswordRequest): Promise<void> {
        await this.updatePosPasswordRaw(requestParameters);
    }

}
