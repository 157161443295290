import React from 'react';

import { FormControl as DefaultFormControl, FormControlProps } from '@chakra-ui/react';
import { useTheme } from 'contexts/ThemeProvider';

const FormControl: React.FC<FormControlProps> = (props: FormControlProps) => {
	const { theme } = useTheme();
	return (
		<DefaultFormControl {...props}
			color={theme?.text_color || `darkGrey`}>
			{props.children}
		</DefaultFormControl>
	)
};

export default FormControl;
