import { Flex, Stack, BreadcrumbItem, BreadcrumbLink, HStack } from '@chakra-ui/react';
import React from 'react';
import { useToasts } from 'react-toast-notifications';

import { Spacer } from '@chakra-ui/layout';
import { Formik } from 'formik';

import Loader from 'containers/Loader';

import {
	CreateBankAccountDtoTypeAccountEnum,
	CreateSellerDtoSettlementTypeEnum,
	CreateSellerDtoStatusEnum,
	CreateSellerDtoTypeEnum,
	CreateSellerRequest,
	GetMerchantDto,
	GetSalePlanDto,
	GetResponsibleDto,
	MerchantsApi,
	SalesPlansApi,
	SellersApi,
	ResponsibleApi,
	SellerRisksApi,
	ListResponsibleTypeEnum,
	CreateSellerRiskRequest,
	ListSalePlanRequest,
	ListSalePlanModeEnum,
} from '../../clients';
import Button from '../../components/Button';
import Paper from '../../containers/Paper';

import { getApiAuthConfig, parseExceptionMessage } from '../../services/api.service';

import createSellerSchema from './components/Validation';
import Breadcrumb from 'components/Breadcrumb';

import Timeline from 'components/Timeline';
import DataForm from './components/DataForm';
import SettingsForm from './components/SettingsForm';
import RiskForm from './components/RiskForm';
import SalePlanForm from './components/SalePlanForm';

const CreateSeller: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const merchantsApi = new MerchantsApi(apiConfig);
	const salesPlansApi = new SalesPlansApi(apiConfig);
	const responsibleApi = new ResponsibleApi(apiConfig);
	const sellerRisksApi = new SellerRisksApi(apiConfig);

	const [isLoading, setIsLoading] = React.useState(false);
	const [merchants, setMerchants] = React.useState<GetMerchantDto[]>([]);
	const [salePlans, setSalePlans] = React.useState<GetSalePlanDto[]>([]);
	const [responsibleSupports, setResponsibleSupports] = React.useState<GetResponsibleDto[]>([]);
	const [responsibleCommercials, setResponsibleCommercials] = React.useState<GetResponsibleDto[]>([]);

	const [isSalePlanConfirmed, setIsSalePlanConfirmed] = React.useState<boolean>(false);

	const { addToast } = useToasts();

	const [stage, setStage] = React.useState<number>(0);

	const stages = [
		{ title: 'Dados', text: '1', isEnabled: true, config: { ml: -1 } },
		{ title: 'Configurações', text: '2', isEnabled: true, config: { ml: -6 } },
		{ title: 'Riscos', text: '3', isEnabled: true, config: { ml: -2 } },
		{ title: 'Plano de venda', text: '4', isEnabled: true, config: { ml: -8 } },
	];

	const createSellerInitialValues = {
		name: '',
		description: '',
		document: '',
		state_registration: '',
		cns: '',
		cnae: '',
		code: '',
		assignment: '',
		email: '',
		created_at: new Date().toISOString(),
		settlement_type: CreateSellerDtoSettlementTypeEnum.Pix,
		has_split_rules: false,
		address: {
			zipcode: '',
			street: '',
			number: '',
			complement: '',
			district: '',
			state: '',
			city: '',
		},
		bank_account: {
			bank_code: '',
			agency_number: '',
			agency_digit: '',
			account_number: '',
			account_digit: '',
			type_account: CreateBankAccountDtoTypeAccountEnum.Current,
			pix_key: '',
		},
		monthly_billing: 0,
		erp: '',
		covenant: '',
		is_split: true,
		phone: '',
		secondary_phone: '',
		tertiary_phone: '',
		absorb_costs: [],
		status: CreateSellerDtoStatusEnum.Activated,
		type: CreateSellerDtoTypeEnum.Transaction,
		merchant_id: '',
		sale_plan_id: '',
		pix_pos: false,
		acquirer: 'adyen',
		billet_provider: 'bradesco',
		pix_provider: 'adyen',
		no_payment_methods: [],
		is_assignor: true,
		responsible: {
			support: '',
			commercial: '',
		},
		owner_name: '',
		owner_document: '',
		owner_birth: '',
		document_ofac: undefined,
		document_onu: undefined,
		document_rf: undefined,
		owner_document_ofac: undefined,
		owner_document_onu: undefined,
		owner_document_pep: undefined,
		owner_document_ref: undefined,
		frontier: undefined,
		risk_active: false,
		min_risk: 0,
		risk_value: 0,
		three_ds_active: false,
		min_three_ds: 0,
		three_ds_value: 0,
		attachments: [],
		risk_classification: '',
		negative_media: false,
		lawsuits: false,
		billet_code: 'true',
		card_payer_check: 'true',
		card_payer_check_min_value: 0,
		extract_name: '',
		celcoin_value: 0,
		celcoin_fee_type: '',
		pos_android_cancel: 'false',
		pos_android_absorb_costs: 'false',
		payment_provider: 'adyen'
	};

	const createSeller = async (values, { resetForm }) => {
		const {
			absorb_costs,
			no_payment_methods,
			pix_pos,
			has_split_rules,
			email,
			is_assignor,
			code,
			responsible,
			document_ofac,
			document_onu,
			document_rf,
			owner_document_ofac,
			owner_document_onu,
			owner_document_pep,
			owner_document_ref,
			frontier,
			owner_birth,
			three_ds_active,
			min_three_ds,
			three_ds_value,
			risk_active,
			min_risk,
			risk_value,
			billet_code,
			card_payer_check,
			celcoin_value,
			pos_android_cancel,
			pos_android_absorb_costs,
			...res
		} = values;
		const { support, commercial } = responsible;

		setIsLoading(true);

		try {
			const requestParams: CreateSellerRequest = {
				createSellerDto: {
					absorb_costs,
					pix_pos: pix_pos === 'true' ? true : false,
					no_payment_methods,
					has_split_rules: has_split_rules === 'true',
					is_assignor: typeof is_assignor === 'string' ? is_assignor === 'true' : is_assignor,
					risk_active: typeof risk_active === 'string' ? risk_active === 'true' : risk_active,
					min_risk: Math.trunc(min_risk * 100),
					code: code.trim(),
					responsibles: [support, commercial],
					three_ds_active: three_ds_active === 'true' ? true : false,
					min_three_ds: Math.trunc(min_three_ds * 100),
					three_ds_value: Math.trunc(three_ds_value * 100),
					risk_value: Math.trunc(risk_value * 100),
					billet_code: billet_code === 'true',
					card_payer_check: card_payer_check === 'true',
					celcoin_value: Math.trunc(celcoin_value * 100),
					pos_android_cancel: pos_android_cancel === 'true',
					pos_android_absorb_costs: pos_android_absorb_costs === 'true',
					...res,
				},
			};

			if (document_ofac) {
				requestParams.createSellerDto.document_ofac = document_ofac === 'true';
			}

			if (document_onu) {
				requestParams.createSellerDto.document_onu = document_onu === 'true';
			}

			if (document_rf) {
				requestParams.createSellerDto.document_rf = document_rf === 'true';
			}

			if (owner_document_ofac) {
				requestParams.createSellerDto.owner_document_ofac = owner_document_ofac === 'true';
			}

			if (owner_document_onu) {
				requestParams.createSellerDto.owner_document_onu = owner_document_onu === 'true';
			}

			if (owner_document_pep) {
				requestParams.createSellerDto.owner_document_pep = owner_document_pep === 'true';
			}

			if (owner_document_ref) {
				requestParams.createSellerDto.owner_document_ref = owner_document_ref === 'true';
			}

			if (frontier) {
				requestParams.createSellerDto.frontier = frontier === 'true';
			}

			if (owner_birth) {
				requestParams.createSellerDto.owner_birth = owner_birth;
			}

			if (email) {
				requestParams.createSellerDto.email = email;
			}

			const response = await sellersApi.createSeller(requestParams);

			await createSellerRisk(values, response.id);

			addToast('Estabelecimento criado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
			resetForm();
			setStage(0);
		} catch (error) {
			let errorMessage = await parseExceptionMessage(error, 'Erro ao criar estabelecimento');

			if (errorMessage === 'User with informed e-mail exists') {
				errorMessage = 'O e-mail informado já encontra-se cadastrado!';
			} else if (errorMessage === 'Seller is duplicated') {
				errorMessage = 'A chave do estabelecimento informada já encontra-se cadastrada!';
			} else {
				errorMessage = 'Erro ao criar estabelecimento';
			}

			addToast(errorMessage, {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const createSellerRisk = async (values, sellerId: string) => {
		const { risk_classification, negative_media, lawsuits, note, attachments } = values;

		try {
			const requestParams: CreateSellerRiskRequest = {
				createSellerRiskDto: {
					seller_id: sellerId,
					classification: risk_classification,
					negative_media,
					lawsuits,
					note,
					attachments,
				},
			};

			await sellerRisksApi.createSellerRisk(requestParams);

			addToast('Risco de estabelecimento criado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao criar risco do estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	};

	const getMerchants = async () => {
		setIsLoading(true);

		try {
			const response = await merchantsApi.listMerchants();

			await setMerchants(response);
		} catch (error) {
			addToast('Erro ao buscar Merchant', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getSalesPlan = async () => {
		setIsLoading(true);

		try {
			const requestParams: ListSalePlanRequest = {
				mode: ListSalePlanModeEnum.Raw
			};

			const response = await salesPlansApi.listSalePlan(requestParams);

			setSalePlans(response);
		} catch (error) {
			addToast('Erro ao buscar planos de venda', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getResponsibleSupports = async () => {
		setIsLoading(true);

		try {
			const response = await responsibleApi.listResponsible({
				type: ListResponsibleTypeEnum.Support,
				isActive: true,
			});

			setResponsibleSupports(response);
		} catch (error) {
			addToast('Erro ao buscar responsáveis pelo suporte', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getResponsibleCommercials = async () => {
		setIsLoading(true);

		try {
			const response = await responsibleApi.listResponsible({
				type: ListResponsibleTypeEnum.Commercial,
				isActive: true,
			});

			setResponsibleCommercials(response);
		} catch (error) {
			addToast('Erro ao buscar responsáveis pelo comercial', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		getMerchants();
		getSalesPlan();
		getResponsibleSupports();
		getResponsibleCommercials();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderStage = (stage: number, values, errors, setFieldValue) => {
		const stagesComponents = [
			<DataForm
				salesPlans={salePlans}
				merchants={merchants}
				errors={errors}
				values={values}
				setFieldValue={setFieldValue}
				isStatusDisabled={false}
				isCnsSearchEnabled={true}
				responsibleSupports={responsibleSupports}
				responsibleCommercials={responsibleCommercials}
			/>,
			<SettingsForm merchants={merchants} values={values} setFieldValue={setFieldValue} errors={errors} />,
			<RiskForm values={values} setFieldValue={setFieldValue} />,
			<SalePlanForm salePlans={salePlans} values={values} confirmSalePlan={setIsSalePlanConfirmed} />,
		];

		return stagesComponents[stage];
	};

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink href='/admin/estabelecimentos'>Estabelecimentos</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontWeight='semibold'>Criar novo Estabelecimento</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper id='seller-timeline-paper-text' w={{ base: '100%', xl: '95%', '2xl': '80%' }}>
				<Stack mb={5} maxWidth={'95%'} pr={{ base: 5, md: 0 }}>
					<Timeline selected={stage} steps={stages} updateStep={(value: number) => setStage(value)} />
				</Stack>
			</Paper>

			<Paper id='seller-formik-paper-text' title={stages[stage].title} w={{ base: '100%', xl: '95%', '2xl': '80%' }}>
				<Formik
					initialValues={createSellerInitialValues}
					validationSchema={createSellerSchema}
					initialTouched={{ sale_plan_id: true }}
					onSubmit={createSeller}
				>
					{({ isValid, setFieldValue, errors, values, resetForm }) => {
						return (
							<>
								{renderStage(stage, values, errors, setFieldValue)}
								<HStack justify={'space-between'} mt={10}>
									<Button id='previous-button' bgColor='primary' hidden={stage === 0} onClick={() => setStage(stage - 1)}>
										Anterior
									</Button>
									<Spacer />
									<Button
										id='next-button'
										bgColor='primary'
										disabled={stage < stages.length - 1 ? !stages[stage + 1].isEnabled : true}
										hidden={stage === stages.length - 1}
										onClick={() => setStage(stage + 1)}
									>
										Próximo
									</Button>
									<Button
										id='finish-button'
										bgColor='primary'
										hidden={!(stage === stages.length - 1)}
										disabled={!isValid || !isSalePlanConfirmed}
										isLoading={isLoading}
										onClick={() => createSeller(values, { resetForm })}
									>
										Finalizar
									</Button>
								</HStack>
							</>
						);
					}}
				</Formik>
			</Paper>
		</Flex>
	);
};

export default CreateSeller;
