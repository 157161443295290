/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForgotPasswordRequestDto,
    ForgotPasswordRequestDtoFromJSON,
    ForgotPasswordRequestDtoToJSON,
    GetForgotPasswordRequestDto,
    GetForgotPasswordRequestDtoFromJSON,
    GetForgotPasswordRequestDtoToJSON,
    ResetPasswordRequestDto,
    ResetPasswordRequestDtoFromJSON,
    ResetPasswordRequestDtoToJSON,
    UserLoginDto,
    UserLoginDtoFromJSON,
    UserLoginDtoToJSON,
    UserLoginResponseDto,
    UserLoginResponseDtoFromJSON,
    UserLoginResponseDtoToJSON,
    UserRefreshTokenDto,
    UserRefreshTokenDtoFromJSON,
    UserRefreshTokenDtoToJSON,
    UserRefreshTokenResponseDto,
    UserRefreshTokenResponseDtoFromJSON,
    UserRefreshTokenResponseDtoToJSON,
} from '../models';

export interface ForgotPasswordRequestRequest {
    forgotPasswordRequestDto: ForgotPasswordRequestDto;
}

export interface GetForgotPasswordRequestDataRequest {
    token: string;
}

export interface LoginRequest {
    userLoginDto: UserLoginDto;
}

export interface LoginV2Request {
    userLoginDto: UserLoginDto;
}

export interface RefreshTokenRequest {
    userRefreshTokenDto: UserRefreshTokenDto;
}

export interface RefreshTokenV2Request {
    userRefreshTokenDto: UserRefreshTokenDto;
}

export interface ResetPasswordRequestRequest {
    resetPasswordRequestDto: ResetPasswordRequestDto;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Solicitar alteração de senha
     */
    async forgotPasswordRequestRaw(requestParameters: ForgotPasswordRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.forgotPasswordRequestDto === null || requestParameters.forgotPasswordRequestDto === undefined) {
            throw new runtime.RequiredError('forgotPasswordRequestDto','Required parameter requestParameters.forgotPasswordRequestDto was null or undefined when calling forgotPasswordRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestDtoToJSON(requestParameters.forgotPasswordRequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Solicitar alteração de senha
     */
    async forgotPasswordRequest(requestParameters: ForgotPasswordRequestRequest): Promise<void> {
        await this.forgotPasswordRequestRaw(requestParameters);
    }

    /**
     * Validar token enviado no esqueci minha senha
     */
    async getForgotPasswordRequestDataRaw(requestParameters: GetForgotPasswordRequestDataRequest): Promise<runtime.ApiResponse<GetForgotPasswordRequestDto>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getForgotPasswordRequestData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/auth/forgot-password/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetForgotPasswordRequestDtoFromJSON(jsonValue));
    }

    /**
     * Validar token enviado no esqueci minha senha
     */
    async getForgotPasswordRequestData(requestParameters: GetForgotPasswordRequestDataRequest): Promise<GetForgotPasswordRequestDto> {
        const response = await this.getForgotPasswordRequestDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Realizar autenticação na aplicação
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<UserLoginResponseDto>> {
        if (requestParameters.userLoginDto === null || requestParameters.userLoginDto === undefined) {
            throw new runtime.RequiredError('userLoginDto','Required parameter requestParameters.userLoginDto was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginDtoToJSON(requestParameters.userLoginDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLoginResponseDtoFromJSON(jsonValue));
    }

    /**
     * Realizar autenticação na aplicação
     */
    async login(requestParameters: LoginRequest): Promise<UserLoginResponseDto> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Realizar autenticação na aplicação
     */
    async loginV2Raw(requestParameters: LoginV2Request): Promise<runtime.ApiResponse<UserLoginResponseDto>> {
        if (requestParameters.userLoginDto === null || requestParameters.userLoginDto === undefined) {
            throw new runtime.RequiredError('userLoginDto','Required parameter requestParameters.userLoginDto was null or undefined when calling loginV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginDtoToJSON(requestParameters.userLoginDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLoginResponseDtoFromJSON(jsonValue));
    }

    /**
     * Realizar autenticação na aplicação
     */
    async loginV2(requestParameters: LoginV2Request): Promise<UserLoginResponseDto> {
        const response = await this.loginV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar o token da aplicação
     */
    async refreshTokenRaw(requestParameters: RefreshTokenRequest): Promise<runtime.ApiResponse<UserRefreshTokenResponseDto>> {
        if (requestParameters.userRefreshTokenDto === null || requestParameters.userRefreshTokenDto === undefined) {
            throw new runtime.RequiredError('userRefreshTokenDto','Required parameter requestParameters.userRefreshTokenDto was null or undefined when calling refreshToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRefreshTokenDtoToJSON(requestParameters.userRefreshTokenDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRefreshTokenResponseDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar o token da aplicação
     */
    async refreshToken(requestParameters: RefreshTokenRequest): Promise<UserRefreshTokenResponseDto> {
        const response = await this.refreshTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar o token da aplicação
     */
    async refreshTokenV2Raw(requestParameters: RefreshTokenV2Request): Promise<runtime.ApiResponse<UserRefreshTokenResponseDto>> {
        if (requestParameters.userRefreshTokenDto === null || requestParameters.userRefreshTokenDto === undefined) {
            throw new runtime.RequiredError('userRefreshTokenDto','Required parameter requestParameters.userRefreshTokenDto was null or undefined when calling refreshTokenV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRefreshTokenDtoToJSON(requestParameters.userRefreshTokenDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRefreshTokenResponseDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar o token da aplicação
     */
    async refreshTokenV2(requestParameters: RefreshTokenV2Request): Promise<UserRefreshTokenResponseDto> {
        const response = await this.refreshTokenV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Realizar alteração de senha
     */
    async resetPasswordRequestRaw(requestParameters: ResetPasswordRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resetPasswordRequestDto === null || requestParameters.resetPasswordRequestDto === undefined) {
            throw new runtime.RequiredError('resetPasswordRequestDto','Required parameter requestParameters.resetPasswordRequestDto was null or undefined when calling resetPasswordRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestDtoToJSON(requestParameters.resetPasswordRequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Realizar alteração de senha
     */
    async resetPasswordRequest(requestParameters: ResetPasswordRequestRequest): Promise<void> {
        await this.resetPasswordRequestRaw(requestParameters);
    }

}
