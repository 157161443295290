import React from 'react';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';

import { Button, Icon } from '@chakra-ui/react';

import { FiEdit } from 'react-icons/fi';

import { formatWithoutTimezone } from 'services/date.service';
import { listPosStatus } from 'services/enums.service';

export default ({ data, setPagination, pagination, totalPages, handleOpenPosModal }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='associated-at-header-title' column={column}>
						Data de Vinculação
					</HeaderTitle>
				),
				accessor: 'associated_at',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContent id={`${index}-${id}-cell`}>{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='seller-header-title' column={column}>
						Estabelecimento
					</HeaderTitle>
				),
				accessor: 'seller',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} textAlign={`center`}>
						{original.seller.name}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='data-terminal-id-header-title' column={column}>
						Código da máquina
					</HeaderTitle>
				),
				accessor: 'terminal_id',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContent id={`${index}-${id}-cell`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='data-is-android-header-title' column={column}>
						Terminal android
					</HeaderTitle>
				),
				accessor: 'is_android',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContent id={`${index}-${id}-cell`}>{value ? 'Sim' : 'Não'}</CellContent>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='status-header-tilte' column={column}>
						Status
					</HeaderTitle>
				),
				accessor: 'status',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`} textAlign={`center`}>
						{listPosStatus[value]}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='edit-head-header-title' column={column}>
						Editar
					</HeaderTitle>
				),
				accessor: 'id',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => (
					<CellContent id={`${index}-${id}-cell`}>
						<Button
							background={'transparent'}
							onClick={() => {
								handleOpenPosModal(original);
							}}
						>
							<Icon as={FiEdit} boxSize={5} />
						</Button>
					</CellContent>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <SimpleTable columns={columns} data={data} setPagination={setPagination} pagination={pagination} totalPages={totalPages} pl={2} />;
};
