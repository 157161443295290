/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Payer,
    PayerFromJSON,
    PayerFromJSONTyped,
    PayerToJSON,
    Sale,
    SaleFromJSON,
    SaleFromJSONTyped,
    SaleToJSON,
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
} from './';

/**
 * 
 * @export
 * @interface Billet
 */
export interface Billet {
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    expires_at: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    provider: BilletProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    url_png?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    email?: string;
    /**
     * 
     * @type {Sale}
     * @memberof Billet
     */
    sale?: Sale;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    sale_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    sale_type?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    sale_acquirer_reference?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    sale_status?: string;
    /**
     * 
     * @type {Payer}
     * @memberof Billet
     */
    payer?: Payer;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    payer_id?: string;
    /**
     * 
     * @type {Seller}
     * @memberof Billet
     */
    seller?: Seller;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    seller_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    seller_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    seller_document?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    sale_description?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    seller_type?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    sale_created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof Billet
     */
    created_at_time?: number;
    /**
     * 
     * @type {number}
     * @memberof Billet
     */
    created_at_unix_time?: number;
    /**
     * 
     * @type {number}
     * @memberof Billet
     */
    sale_original_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    payer_document?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    payer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    payer_email?: string;
    /**
     * 
     * @type {Address}
     * @memberof Billet
     */
    payer_address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    metadata?: string;
    /**
     * 
     * @type {number}
     * @memberof Billet
     */
    sale_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    qrcode_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    payment_link_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Billet
     */
    instructions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Billet
     */
    reduce_layout?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum BilletProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau'
}

export function BilletFromJSON(json: any): Billet {
    return BilletFromJSONTyped(json, false);
}

export function BilletFromJSONTyped(json: any, ignoreDiscriminator: boolean): Billet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'barcode': json['barcode'],
        'expires_at': json['expires_at'],
        'status': json['status'],
        'provider': json['provider'],
        'url': json['url'],
        'url_png': !exists(json, 'url_png') ? undefined : json['url_png'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'sale': !exists(json, 'sale') ? undefined : SaleFromJSON(json['sale']),
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'sale_type': !exists(json, 'sale_type') ? undefined : json['sale_type'],
        'sale_acquirer_reference': !exists(json, 'sale_acquirer_reference') ? undefined : json['sale_acquirer_reference'],
        'sale_status': !exists(json, 'sale_status') ? undefined : json['sale_status'],
        'payer': !exists(json, 'payer') ? undefined : PayerFromJSON(json['payer']),
        'payer_id': !exists(json, 'payer_id') ? undefined : json['payer_id'],
        'seller': !exists(json, 'seller') ? undefined : SellerFromJSON(json['seller']),
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
        'seller_name': !exists(json, 'seller_name') ? undefined : json['seller_name'],
        'seller_document': !exists(json, 'seller_document') ? undefined : json['seller_document'],
        'sale_description': !exists(json, 'sale_description') ? undefined : json['sale_description'],
        'seller_type': !exists(json, 'seller_type') ? undefined : json['seller_type'],
        'sale_created_at': !exists(json, 'sale_created_at') ? undefined : json['sale_created_at'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'created_at_time': !exists(json, 'created_at_time') ? undefined : json['created_at_time'],
        'created_at_unix_time': !exists(json, 'created_at_unix_time') ? undefined : json['created_at_unix_time'],
        'sale_original_amount': !exists(json, 'sale_original_amount') ? undefined : json['sale_original_amount'],
        'payer_document': !exists(json, 'payer_document') ? undefined : json['payer_document'],
        'payer_name': !exists(json, 'payer_name') ? undefined : json['payer_name'],
        'payer_email': !exists(json, 'payer_email') ? undefined : json['payer_email'],
        'payer_address': !exists(json, 'payer_address') ? undefined : AddressFromJSON(json['payer_address']),
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'sale_amount': !exists(json, 'sale_amount') ? undefined : json['sale_amount'],
        'qrcode_url': !exists(json, 'qrcode_url') ? undefined : json['qrcode_url'],
        'payment_link_url': !exists(json, 'payment_link_url') ? undefined : json['payment_link_url'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'reduce_layout': !exists(json, 'reduce_layout') ? undefined : json['reduce_layout'],
    };
}

export function BilletToJSON(value?: Billet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'barcode': value.barcode,
        'expires_at': value.expires_at,
        'status': value.status,
        'provider': value.provider,
        'url': value.url,
        'url_png': value.url_png,
        'email': value.email,
        'sale': SaleToJSON(value.sale),
        'sale_id': value.sale_id,
        'sale_type': value.sale_type,
        'sale_acquirer_reference': value.sale_acquirer_reference,
        'sale_status': value.sale_status,
        'payer': PayerToJSON(value.payer),
        'payer_id': value.payer_id,
        'seller': SellerToJSON(value.seller),
        'seller_id': value.seller_id,
        'seller_name': value.seller_name,
        'seller_document': value.seller_document,
        'sale_description': value.sale_description,
        'seller_type': value.seller_type,
        'sale_created_at': value.sale_created_at,
        'created_at': value.created_at,
        'created_at_time': value.created_at_time,
        'created_at_unix_time': value.created_at_unix_time,
        'sale_original_amount': value.sale_original_amount,
        'payer_document': value.payer_document,
        'payer_name': value.payer_name,
        'payer_email': value.payer_email,
        'payer_address': AddressToJSON(value.payer_address),
        'metadata': value.metadata,
        'sale_amount': value.sale_amount,
        'qrcode_url': value.qrcode_url,
        'payment_link_url': value.payment_link_url,
        'instructions': value.instructions,
        'reduce_layout': value.reduce_layout,
    };
}


