import React from 'react';

import Receipt from 'components/Receipt/Receipt';
import Button from 'components/Button';
import ReceiptTemplate from './ReceiptTemplate';
import { useReactToPrint } from 'react-to-print';
import { Divider, VStack, Box, HStack } from '@chakra-ui/layout';
import { GetSaleDto } from '../../clients';
import ReceiptBillPayment from './ReceiptBillPayment';
import Text from '../Text';

type Props = {
	sale: GetSaleDto;
};

export class ComponentToPrint extends React.PureComponent<Props> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: Props) {
		super(props);
	}

	public render() {
		return <Receipt sale={this.props.sale} />;
	}
}

export class ComponentToPrintReceipt extends React.PureComponent<Props> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: Props) {
		super(props);
	}

	public render() {
		return <ReceiptTemplate sale={this.props.sale} />;
	}
}

export class ComponentToPrintReceiptBillPayment extends React.PureComponent<Props> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: Props) {
		super(props);
	}

	public render() {
		return <ReceiptBillPayment sale={this.props.sale} />;
	}
}

type PrintComponentProps = {
	sale: GetSaleDto;
	setOpenReceiptModal: (openReceiptModal: boolean) => void;
	isBillPayment?: boolean;
};

export const PrintComponent: React.FC<PrintComponentProps> = ({ sale, setOpenReceiptModal, isBillPayment }) => {
	const componentRef = React.useRef(null);
	let componentReceiptRef = React.useRef(null);

	const onBeforeGetContentResolve = React.useRef<(() => void) | null>(null);

	const [loading, setLoading] = React.useState(false);
	const [loadingReceipt, setLoadingReceipt] = React.useState(false);

	const handleAfterPrint = React.useCallback(() => {
		console.log('`onAfterPrint` called'); // tslint:disable-line no-console
	}, []);

	const handleBeforePrint = React.useCallback(() => {
		console.log('`onBeforePrint` called'); // tslint:disable-line no-console
	}, []);

	const handleOnBeforeGetContent = React.useCallback(() => {
		console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console
		setLoading(true);

		return new Promise<void>((resolve) => {
			onBeforeGetContentResolve.current = resolve;

			setTimeout(() => {
				setLoading(false);
				resolve();
			}, 2000);
		});
	}, [setLoading]);

	const handleOnBeforeGetContentReceipt = React.useCallback(() => {
		console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console
		setLoadingReceipt(true);

		return new Promise<void>((resolve) => {
			onBeforeGetContentResolve.current = resolve;

			setTimeout(() => {
				setLoadingReceipt(false);
				resolve();
			}, 2000);
		});
	}, [setLoadingReceipt]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentRef.current]);

	const reactToPrintReceiptContent = React.useCallback(() => {
		return componentReceiptRef.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentReceiptRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Recibo',
		onBeforeGetContent: handleOnBeforeGetContent,
		onBeforePrint: handleBeforePrint,
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	const handlePrintReceipt = useReactToPrint({
		content: reactToPrintReceiptContent,
		documentTitle: 'Recibo',
		onBeforeGetContent: handleOnBeforeGetContentReceipt,
		onBeforePrint: handleBeforePrint,
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	return (
		<VStack>
			<Text id='bill-payment-text' fontSize='2xl' fontWeight='bold' color='primary'>
				{!isBillPayment ? 'Segunda via de recibo' : 'Recibo de Quitação do Título'}
			</Text>

			<Divider orientation={`horizontal`} />
			<Box maxH='70vh' overflowY='scroll'>
				{isBillPayment ? (
					<ComponentToPrintReceiptBillPayment ref={componentRef} sale={sale} />
				) : (
					<ComponentToPrint ref={componentRef} sale={sale} />
				)}
			</Box>

			<Divider orientation={`horizontal`} />

			<HStack justify='flex-end' w='100%'>
				<Button id='print-button' bgColor='primary' onClick={handlePrint} isLoading={loading}>
					Imprimir
				</Button>

				{!sale?.celcoin && (
					<Button id='print-receipt-button' bgColor='black' onClick={handlePrintReceipt} isLoading={loadingReceipt}>
						Imprimir Cupom
					</Button>
				)}

				<Button
					id='close-button'
					bgColor='error'
					onClick={() => {
						setOpenReceiptModal(false);
					}}
					marginRight='1%'
				>
					Fechar
				</Button>
			</HStack>
			<div style={{ display: 'none' }}>
				<ComponentToPrintReceipt sale={sale} ref={componentReceiptRef} />
			</div>
		</VStack>
	);
};
