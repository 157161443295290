import React from 'react';

import { SimpleGrid, FormControl } from '@chakra-ui/react';

import FormSelect from 'components/Form/FormSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import { Stack } from '@chakra-ui/layout';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormLabel from '../../../components/Form/FormLabel';

type Props = {
	errors: any;
};

const ThreeDsForm: React.FC<Props> = (props) => (
	<SimpleGrid color='gray.700' spacing='2.5rem' marginLeft='1%'>
		<Stack direction={['column', 'column', 'row']} spacing={4}>
		<FormControl id='three_ds_active'>
				<FormLabel id='three-ds-active-label' fontSize='md' fontWeight='bold'>
					3Ds Ativo
				</FormLabel>
				<FormSelect id='three-ds-active-form-select' name='three_ds_active'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage id='three-ds-active-form-error-text' name='three_ds_active' />
			</FormControl>

			<FormControl id='min_three_ds'>
				<FormLabel id='three-ds-min-label' fontSize='md' fontWeight='bold'>
					Valor mínimo 3Ds
				</FormLabel>
				<FormCurrencyInput id='three-ds-min-form-currency-input' borderColor='darkGrey' name='min_three_ds' />
				<FormErrorMessage id='three-ds-min-three-ds-form-error-text' name='min_three_ds' />
			</FormControl>

			<FormControl id='three_ds_value'>
				<FormLabel id='three-ds-value-label' fontSize='md' fontWeight='bold'>
					Valor 3Ds
				</FormLabel>
				<FormCurrencyInput id='thress-ds-value-form-error-currency-text' borderColor='darkGrey' name='three_ds_value' />
				<FormErrorMessage id='three-ds-value-form-error-text' name='three_ds_value' />
			</FormControl>
		</Stack>
	</SimpleGrid>
);

export default ThreeDsForm;
