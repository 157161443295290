import React from 'react';

import { Heading, Text, Box, Flex, Spacer } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import NewModal from './NewModal';
import Button from 'components/Button';

type ExportFileSuccessModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onOkClick?: () => void;
	showCloseButton?: boolean;
};

const ExportFileSuccessModal: React.FC<ExportFileSuccessModalProps> = ({ isOpen, showCloseButton, onClose, onOkClick }) => {
	const history = useHistory();

	const navigateToFilesList = () => {
		history.push(`/admin/relatorios`);
	};

	return (
		<NewModal {...(showCloseButton && { showCloseButton: true })} isOpen={isOpen} onClose={onClose}>
			<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
				<Flex p={8} flexDirection='column'>
					<Heading fontSize='lg' paddingBottom={8}>
						Exportação de Arquivo
					</Heading>

					<Box mt={2} mb={5}>
						<Text id='process-file-text' align='center'>
							Seu arquivo está sendo processado e em breve estará disponível para download. Você pode acompanhar o progresso na área de
							relatórios exportados.
						</Text>
					</Box>

					<Flex flexDirection='row' alignItems='center' fontSize='md' px={3} pb={2}>
						<Spacer />

						<Button
							id='navigate-to-reports-button'
							size='lg'
							color='white'
							isFullWidth
							mt={10}
							bgColor='primary'
							onClick={() => {
								onOkClick && onOkClick();
								navigateToFilesList();
							}}
						>
							Ir para os relatórios exportados
						</Button>
					</Flex>
				</Flex>
			</Box>
		</NewModal>
	);
};

export default ExportFileSuccessModal;
