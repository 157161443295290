/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FailedTransfersDto
 */
export interface FailedTransfersDto {
    /**
     * 
     * @type {string}
     * @memberof FailedTransfersDto
     */
    seller_name: string;
    /**
     * 
     * @type {string}
     * @memberof FailedTransfersDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof FailedTransfersDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof FailedTransfersDto
     */
    cnab_id: string;
}

export function FailedTransfersDtoFromJSON(json: any): FailedTransfersDto {
    return FailedTransfersDtoFromJSONTyped(json, false);
}

export function FailedTransfersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailedTransfersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seller_name': json['seller_name'],
        'code': json['code'],
        'value': json['value'],
        'cnab_id': json['cnab_id'],
    };
}

export function FailedTransfersDtoToJSON(value?: FailedTransfersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seller_name': value.seller_name,
        'code': value.code,
        'value': value.value,
        'cnab_id': value.cnab_id,
    };
}


