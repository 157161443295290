/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateExportSalesListDto,
    CreateExportSalesListDtoFromJSON,
    CreateExportSalesListDtoToJSON,
    GetFileExportRequestDto,
    GetFileExportRequestDtoFromJSON,
    GetFileExportRequestDtoToJSON,
    GetSaleDto,
    GetSaleDtoFromJSON,
    GetSaleDtoToJSON,
    GetSaleSummaryChartDto,
    GetSaleSummaryChartDtoFromJSON,
    GetSaleSummaryChartDtoToJSON,
    GetSaleSummaryDto,
    GetSaleSummaryDtoFromJSON,
    GetSaleSummaryDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface CreateSalesBillPaymentListExportRequestRequest {
    createExportSalesListDto: CreateExportSalesListDto;
}

export interface CreateSalesConsolidateExportRequestRequest {
    createExportSalesListDto: CreateExportSalesListDto;
}

export interface CreateSalesHistoryExportRequestRequest {
    createExportSalesListDto: CreateExportSalesListDto;
}

export interface CreateSalesListExportRequestRequest {
    createExportSalesListDto: CreateExportSalesListDto;
}

export interface GetAllSalesSummaryRequest {
    startDate?: string;
    endDate?: string;
    status?: GetAllSalesSummaryStatusEnum;
}

export interface GetSaleRequest {
    saleId: string;
}

export interface ListSalesRequest {
    limit: number;
    currentPage: number;
    description?: string;
    search?: string;
    amount?: number;
    originalAmount?: number;
    type?: Array<ListSalesTypeEnum>;
    name?: string;
    payerName?: string;
    user?: string;
    formPayment?: Array<string>;
    startDate?: string;
    endDate?: string;
    lastNumberSales?: number;
    paymentDay?: boolean;
    payerEmail?: string;
    payerPhone?: string;
    settlementBatchDay?: string;
    updatedDay?: boolean;
    createdByUserName?: string;
    sort?: ListSalesSortEnum;
    id?: string;
    status?: Array<ListSalesStatusEnum>;
}

export interface PayPixRequest {
    saleId: string;
}

export interface PayPixPosRequest {
    saleId: string;
}

/**
 * 
 */
export class SalesApi extends runtime.BaseAPI {

    /**
     * Gerar uma requisição de exportação da lista de Pagamento de Contas
     */
    async createSalesBillPaymentListExportRequestRaw(requestParameters: CreateSalesBillPaymentListExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportSalesListDto === null || requestParameters.createExportSalesListDto === undefined) {
            throw new runtime.RequiredError('createExportSalesListDto','Required parameter requestParameters.createExportSalesListDto was null or undefined when calling createSalesBillPaymentListExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales/list/export/billpayment/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSalesListDtoToJSON(requestParameters.createExportSalesListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação da lista de Pagamento de Contas
     */
    async createSalesBillPaymentListExportRequest(requestParameters: CreateSalesBillPaymentListExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createSalesBillPaymentListExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação do consolidado de custo
     */
    async createSalesConsolidateExportRequestRaw(requestParameters: CreateSalesConsolidateExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportSalesListDto === null || requestParameters.createExportSalesListDto === undefined) {
            throw new runtime.RequiredError('createExportSalesListDto','Required parameter requestParameters.createExportSalesListDto was null or undefined when calling createSalesConsolidateExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales/consolidate/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSalesListDtoToJSON(requestParameters.createExportSalesListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação do consolidado de custo
     */
    async createSalesConsolidateExportRequest(requestParameters: CreateSalesConsolidateExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createSalesConsolidateExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação do histórico de vendas
     */
    async createSalesHistoryExportRequestRaw(requestParameters: CreateSalesHistoryExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportSalesListDto === null || requestParameters.createExportSalesListDto === undefined) {
            throw new runtime.RequiredError('createExportSalesListDto','Required parameter requestParameters.createExportSalesListDto was null or undefined when calling createSalesHistoryExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales/history/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSalesListDtoToJSON(requestParameters.createExportSalesListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação do histórico de vendas
     */
    async createSalesHistoryExportRequest(requestParameters: CreateSalesHistoryExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createSalesHistoryExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação da lista de vendas
     */
    async createSalesListExportRequestRaw(requestParameters: CreateSalesListExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportSalesListDto === null || requestParameters.createExportSalesListDto === undefined) {
            throw new runtime.RequiredError('createExportSalesListDto','Required parameter requestParameters.createExportSalesListDto was null or undefined when calling createSalesListExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales/list/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSalesListDtoToJSON(requestParameters.createExportSalesListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação da lista de vendas
     */
    async createSalesListExportRequest(requestParameters: CreateSalesListExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createSalesListExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar resumo de venda de todos Estabelecimentos
     */
    async getAllSalesSummaryRaw(requestParameters: GetAllSalesSummaryRequest): Promise<runtime.ApiResponse<GetSaleSummaryDto>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Listar resumo de venda de todos Estabelecimentos
     */
    async getAllSalesSummary(requestParameters: GetAllSalesSummaryRequest): Promise<GetSaleSummaryDto> {
        const response = await this.getAllSalesSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar resumo dos ultimos 6 meses de todos Estabelecimentos
     */
    async getAllSalesSummaryChartRaw(): Promise<runtime.ApiResponse<GetSaleSummaryChartDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales/summary-chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleSummaryChartDtoFromJSON(jsonValue));
    }

    /**
     * Listar resumo dos ultimos 6 meses de todos Estabelecimentos
     */
    async getAllSalesSummaryChart(): Promise<GetSaleSummaryChartDto> {
        const response = await this.getAllSalesSummaryChartRaw();
        return await response.value();
    }

    /**
     * Retornar dados de uma venda especifica
     */
    async getSaleRaw(requestParameters: GetSaleRequest): Promise<runtime.ApiResponse<GetSaleDto>> {
        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling getSale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales/{saleId}`.replace(`{${"saleId"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleDtoFromJSON(jsonValue));
    }

    /**
     * Retornar dados de uma venda especifica
     */
    async getSale(requestParameters: GetSaleRequest): Promise<GetSaleDto> {
        const response = await this.getSaleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar as vendas de todos os estabelecimentos
     */
    async listSalesRaw(requestParameters: ListSalesRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSales.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSales.');
        }

        const queryParameters: any = {};

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.originalAmount !== undefined) {
            queryParameters['original_amount'] = requestParameters.originalAmount;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.payerName !== undefined) {
            queryParameters['payer_name'] = requestParameters.payerName;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.formPayment) {
            queryParameters['form_payment'] = requestParameters.formPayment;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.lastNumberSales !== undefined) {
            queryParameters['last_number_sales'] = requestParameters.lastNumberSales;
        }

        if (requestParameters.paymentDay !== undefined) {
            queryParameters['payment_day'] = requestParameters.paymentDay;
        }

        if (requestParameters.payerEmail !== undefined) {
            queryParameters['payer_email'] = requestParameters.payerEmail;
        }

        if (requestParameters.payerPhone !== undefined) {
            queryParameters['payer_phone'] = requestParameters.payerPhone;
        }

        if (requestParameters.settlementBatchDay !== undefined) {
            queryParameters['settlement_batch_day'] = requestParameters.settlementBatchDay;
        }

        if (requestParameters.updatedDay !== undefined) {
            queryParameters['updated_day'] = requestParameters.updatedDay;
        }

        if (requestParameters.createdByUserName !== undefined) {
            queryParameters['created_by_user_name'] = requestParameters.createdByUserName;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar as vendas de todos os estabelecimentos
     */
    async listSales(requestParameters: ListSalesRequest): Promise<PaginatedDto> {
        const response = await this.listSalesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Pagar PIX (em ambiente de desenvolvimento)
     */
    async payPixRaw(requestParameters: PayPixRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling payPix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales/{saleId}/pix/pay`.replace(`{${"saleId"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Pagar PIX (em ambiente de desenvolvimento)
     */
    async payPix(requestParameters: PayPixRequest): Promise<void> {
        await this.payPixRaw(requestParameters);
    }

    /**
     * Pagar PIX (em ambiente de desenvolvimento) no pos
     */
    async payPixPosRaw(requestParameters: PayPixPosRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling payPixPos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sales/{saleId}/pix/pay/pos`.replace(`{${"saleId"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Pagar PIX (em ambiente de desenvolvimento) no pos
     */
    async payPixPos(requestParameters: PayPixPosRequest): Promise<void> {
        await this.payPixPosRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAllSalesSummaryStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSalesTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSalesSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSalesStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}
