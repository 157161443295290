/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginatedDto
 */
export interface PaginatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginatedDto
     */
    results: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDto
     */
    current_page: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDto
     */
    total_pages: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDto
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDto
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDto
     */
    total_items: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedDto
     */
    has_more: boolean;
    /**
     * 
     * @type {object}
     * @memberof PaginatedDto
     */
    additional_data?: object;
}

export function PaginatedDtoFromJSON(json: any): PaginatedDto {
    return PaginatedDtoFromJSONTyped(json, false);
}

export function PaginatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': json['results'],
        'current_page': json['current_page'],
        'total_pages': json['total_pages'],
        'limit': json['limit'],
        'start': json['start'],
        'end': json['end'],
        'total_items': json['total_items'],
        'has_more': json['has_more'],
        'additional_data': !exists(json, 'additional_data') ? undefined : json['additional_data'],
    };
}

export function PaginatedDtoToJSON(value?: PaginatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results,
        'current_page': value.current_page,
        'total_pages': value.total_pages,
        'limit': value.limit,
        'start': value.start,
        'end': value.end,
        'total_items': value.total_items,
        'has_more': value.has_more,
        'additional_data': value.additional_data,
    };
}


