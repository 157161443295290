import React from 'react';

import Select, { SelectProps, StylesConfig } from 'react-select';
interface option {
	label: string;
	value: string;
}

type MultiSelectProps = SelectProps & {
	options: option[];
	placeholder: string;
	onChange: (value: any) => void;
	id: string;
	value: option[];
};

const customStyle: StylesConfig<MultiSelectProps, false> = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
        paddingRight: '0.8rem',
        paddingLeft: '0.8rem',
        fontSize: '1rem',
        fontFamily: 'Open Sans, Sans Serif',
        border: 'none'
    }),
    container: (provided) => ({
        ...provided,
        borderWidth: 1,
        borderColor: '#7D7D7D',
        borderRadius: '0.5rem'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#7D7D7D',
        svg: {
            height: '1.375rem',
            width: '1.375rem',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '12px',
    })
};

const MultiSelect: React.FC<MultiSelectProps> = (props: MultiSelectProps) => {
	const { options, placeholder, value, onChange, id } = props;

	return (
		<Select
			id={id}
			value={value}
			onChange={onChange}
			isMulti
			options={options}
			placeholder={placeholder || 'Selecione...'}
			styles={customStyle}
		/>
	);
};

export default MultiSelect;
