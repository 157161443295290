import React from 'react';
import { Image, Spinner, Flex } from '@chakra-ui/react';

export const CustomImage = ({ src }) => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [imageError, setImageError] = React.useState(false);

	React.useEffect(() => {
		const img = new window.Image();
		img.src = src;

		img.onload = () => {
			setIsLoading(false);
		};

		img.onerror = () => {
			setIsLoading(false);
			setImageError(true);
		};
	}, [src]);

	return (
		<>
			{isLoading ? (
				<Flex m={3}>
					<Spinner />
				</Flex>
			) : imageError ? (
				<Flex m={3}>Imagem indisponível</Flex>
			) : (
				<Image src={src} alt='Image' />
			)}
		</>
	);
};
