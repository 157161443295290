import { FormControl, FormLabel, Stack, VStack } from '@chakra-ui/react';
import FormSelect from 'components/Form/FormSelect';
import React from 'react';

import FormCpfCnpjInput from '../../../components/Form/FormCpfCnpjInput';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import getzipcode from '../../../services/getzipcode.service';
import { states } from '../../../config/constants';
import FormPhoneInput from 'components/Form/FormPhoneInput';
import FormDatePickerInput from 'components/Form/FormDatePickerInput';

type PayerFormProps = {
	setFieldValue: any;
	values: any;
	formExtended: boolean | undefined;
};

const PayerForm: React.FC<PayerFormProps> = ({ setFieldValue, values, formExtended }) => {
	return (
		<VStack my={4} w='100%' fontSize='2sm' border='1px' borderColor='gray.400' rounded='md' p='5' className='data__billet'>
			<Stack direction={['column', 'row']} w='100%' spacing={4}>
				<FormControl>
					<FormLabel id='payer-name-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Nome Completo
					</FormLabel>
					<FormInput
						id='payer-name-form-input'
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						autoFocus
						name='name'
						borderColor='gray.500'
						rounded='md'
						p='5'
					/>
					<FormErrorMessage id='payer-name-form-error-text' name='name' />
				</FormControl>

				<FormControl>
					<FormLabel id='payer-document-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						CPF/CNPJ
					</FormLabel>
					<FormCpfCnpjInput
						id='payer-cpf-form-input'
						isSearchPayer={true}
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						name='cpf'
						borderColor='gray.500'
						rounded='md'
						p='5'
						setSelectedPayer={() => {}}
						className='form-data__document'
					/>
					<FormErrorMessage id='payer-cpf-form-zipcode-input' name='cpf' />
				</FormControl>
			</Stack>
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%' spacing={4}>
					<FormControl>
						<FormLabel id='payer-birth-date-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Data de Nascimento
						</FormLabel>
						<FormDatePickerInput
							id='payer-birth-date-picker-input'
							variant='flushed'
							placeholder='Digite aqui'
							size='lg'
							name='birth_date'
							borderColor='gray.500'
							rounded='md'
							p='5'
						/>
						<FormErrorMessage id='payer-birth-date-form-error-text' name='birth_date' />
					</FormControl>
					<FormControl>
						<FormLabel id='payer-email-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Email
						</FormLabel>
						<FormInput
							id='payer-email-form-input'
							variant='flushed'
							placeholder='Digite aqui'
							size='lg'
							name='email'
							borderColor='gray.500'
							rounded='md'
							p='5'
						/>
						<FormErrorMessage id='payer-email-form-text-input' name='email' />
					</FormControl>
				</Stack>
			)}
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%' spacing={4}>
					<FormControl>
						<FormLabel id='payer-phone-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Telefone
						</FormLabel>
						<FormPhoneInput
							id='payer-phone-form-input'
							variant='flushed'
							placeholder='Digite aqui'
							size='lg'
							name='phone'
							borderColor='gray.500'
							rounded='md'
							p='5'
							className='form-data__phone'
						/>
						<FormErrorMessage id='payer-phone-form-error-text' name='phone' />
					</FormControl>
					<FormControl>
						<FormLabel id='payer-cep-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							CEP
						</FormLabel>
						<FormZipcodeInput
							id='payer-cep-form-zipcode-input'
							variant='flushed'
							placeholder='Digite aqui'
							size='lg'
							name='cep'
							borderColor='gray.500'
							rounded='md'
							p='5'
							performSearch={getzipcode}
							onSearchResults={(data) => {
								setFieldValue('street', data.logradouro);
								setFieldValue('district', data.bairro);
								setFieldValue('city', data.localidade);
								setFieldValue('state', data.uf);
								setFieldValue('complement', data.complemento);
							}}
							className='form-data__zipcode'
						/>
						<FormErrorMessage id='payer-cep-form-error-text' name='cep' />
					</FormControl>
				</Stack>
			)}
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%'>
					<FormControl>
						<FormLabel id='payer-city-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Cidade
						</FormLabel>
						<FormInput
							id='payer-city-form-input'
							variant='flushed'
							placeholder='Digite aqui'
							size='lg'
							name='city'
							borderColor='gray.500'
							rounded='md'
							p='5'
						/>
						<FormErrorMessage id='payer-city-form-error-text' name='city' />
					</FormControl>
					<FormControl rounded='md'>
						<FormLabel id='payer-state-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Estado
						</FormLabel>
						<FormSelect
							id='payer-state-form-select'
							textColor={!!values.state ? `black` : `gray.500`}
							variant='outline'
							size='lg'
							fontSize='md'
							name='state'
							border='1px'
							borderColor='gray.500'
						>
							<option value=''>Selecione</option>
							{states.map((state) => (
								<option value={state.value} key={state.value}>
									{state.text}
								</option>
							))}
						</FormSelect>
						<FormErrorMessage id='payer-state-form-error-text' name='state' />
					</FormControl>
				</Stack>
			)}
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%'>
					<FormControl>
						<FormLabel id='payer-street-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Rua
						</FormLabel>
						<FormInput
							id='payer-street-form-input'
							variant='flushed'
							placeholder='Rua'
							size='lg'
							name='street'
							borderColor='gray.500'
							rounded='md'
							p='5'
						/>
						<FormErrorMessage id='payer-street-form-error-text' name='street' />
					</FormControl>
					<FormControl>
						<FormLabel id='payer-district-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Bairro
						</FormLabel>
						<FormInput
							id='payer-district-form-input'
							variant='flushed'
							placeholder='Bairro'
							size='lg'
							name='district'
							borderColor='gray.500'
							rounded='md'
							p='5'
						/>
						<FormErrorMessage id='payer-district-form-error-text' name='district' />
					</FormControl>
				</Stack>
			)}
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%'>
					<FormControl>
						<FormLabel id='payer-number-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Número
						</FormLabel>
						<FormInput
							id='payer-number-form-input'
							variant='flushed'
							placeholder='Número'
							size='lg'
							name='number'
							borderColor='gray.500'
							rounded='md'
							p='5'
							className='form-data__house-number'
						/>
						<FormErrorMessage id='payer-number-form-error-text' name='number' />
					</FormControl>
					<FormControl>
						<FormLabel id='payer-complement-label' color={`black`} fontWeight={`semibold`} fontSize={`md`}>
							Complemento
						</FormLabel>
						<FormInput
							id='payer-complement-form-input'
							variant='flushed'
							placeholder='Complemento'
							size='lg'
							name='complement'
							borderColor='gray.500'
							rounded='md'
							p='5'
						/>
						<FormErrorMessage id='payer-complement-form-error-text' name='complement' />
					</FormControl>
				</Stack>
			)}
		</VStack>
	);
};

export default PayerForm;
