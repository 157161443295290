import * as React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';

import { useAuth } from '../contexts/AuthProvider';

const PublicRoute = (routeProps: RouteProps) => {
  const Component: any = routeProps.component;
  const rest = {...routeProps};

	delete rest.component;
	
	const { signed } = useAuth();

  return (
    <Route {...rest} render={(props) => {
			if (! signed) {
				return <Component {...props} />
			}
			return <Redirect to={{ pathname: '/admin/dashboard', state: { from: props.location }}} />
		}} />
  );
};

export default PublicRoute;
