/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentLinkPayerAddressDto,
    PaymentLinkPayerAddressDtoFromJSON,
    PaymentLinkPayerAddressDtoFromJSONTyped,
    PaymentLinkPayerAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePaymentLinkPayerDto
 */
export interface CreatePaymentLinkPayerDto {
    /**
     * Nome
     * @type {string}
     * @memberof CreatePaymentLinkPayerDto
     */
    name: string;
    /**
     * Email
     * @type {string}
     * @memberof CreatePaymentLinkPayerDto
     */
    email?: string;
    /**
     * Telefone
     * @type {string}
     * @memberof CreatePaymentLinkPayerDto
     */
    phone?: string;
    /**
     * Endereço
     * @type {PaymentLinkPayerAddressDto}
     * @memberof CreatePaymentLinkPayerDto
     */
    address?: PaymentLinkPayerAddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentLinkPayerDto
     */
    document?: string;
}

export function CreatePaymentLinkPayerDtoFromJSON(json: any): CreatePaymentLinkPayerDto {
    return CreatePaymentLinkPayerDtoFromJSONTyped(json, false);
}

export function CreatePaymentLinkPayerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentLinkPayerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : PaymentLinkPayerAddressDtoFromJSON(json['address']),
        'document': !exists(json, 'document') ? undefined : json['document'],
    };
}

export function CreatePaymentLinkPayerDtoToJSON(value?: CreatePaymentLinkPayerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'address': PaymentLinkPayerAddressDtoToJSON(value.address),
        'document': value.document,
    };
}


