/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSellerDto,
    GetSellerDtoFromJSON,
    GetSellerDtoFromJSONTyped,
    GetSellerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetTransactionAlertResponseDto
 */
export interface GetTransactionAlertResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetTransactionAlertResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionAlertResponseDto
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionAlertResponseDto
     */
    seller_name: string;
    /**
     * 
     * @type {GetSellerDto}
     * @memberof GetTransactionAlertResponseDto
     */
    seller: GetSellerDto;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionAlertResponseDto
     */
    monthly_billing: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionAlertResponseDto
     */
    accumulated_amount: number;
}

export function GetTransactionAlertResponseDtoFromJSON(json: any): GetTransactionAlertResponseDto {
    return GetTransactionAlertResponseDtoFromJSONTyped(json, false);
}

export function GetTransactionAlertResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTransactionAlertResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'seller_id': json['seller_id'],
        'seller_name': json['seller_name'],
        'seller': GetSellerDtoFromJSON(json['seller']),
        'monthly_billing': json['monthly_billing'],
        'accumulated_amount': json['accumulated_amount'],
    };
}

export function GetTransactionAlertResponseDtoToJSON(value?: GetTransactionAlertResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'seller_id': value.seller_id,
        'seller_name': value.seller_name,
        'seller': GetSellerDtoToJSON(value.seller),
        'monthly_billing': value.monthly_billing,
        'accumulated_amount': value.accumulated_amount,
    };
}


