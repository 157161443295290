import React from 'react';

import { Flex, Stack, CheckboxGroup } from '@chakra-ui/react';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { addDays, format, differenceInDays, startOfDay, endOfDay } from 'date-fns';

import Loader from 'containers/Loader';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import Button from 'components/Button';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormCpfCnpjInput from 'components/Form/FormCpfCnpjInput';
import FormPhoneInput from 'components/Form/FormPhoneInput';
import FormDatePickerInput from 'components/Form/FormDatePickerInput';
import FormTextarea from 'components/Form/FormTextarea';

import yup from 'services/yup.service';
import PaymentLinksFilters from './components/PaymentLinksFilters';
import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';
import PaymentLinksTable from './components/PaymentLinksTable';

import { SellersApi, ListSellerPaymentLinksRequest, GetPaymentLinkDto, PaymentLinksApi } from '../../clients';
import { useHistory } from 'react-router-dom';

import { useCurrentSeller } from '../../contexts/SellerProvider';

import { getApiAuthConfig, parseExceptionMessage } from '../../services/api.service';
import { paymentTypeEnum } from 'services/enums.service';
import { round } from '../../services/math.service';

import { BreadcrumbItem, BreadcrumbLink, useBreakpointValue } from '@chakra-ui/react';
import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';
import FormControl from 'components/Form/FormControl';
import FormLabel from 'components/Form/FormLabel';
import Text from 'components/Text';
import Checkbox from '../../components/Checkbox';

const PaymentLinkSale: React.FC = () => {
	const { currentSeller } = useCurrentSeller();
	const { addToast } = useToasts();
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const history = useHistory();

	const [isLoading, setIsLoading] = React.useState(true);
	const [paymentLinks, setPaymentLinks] = React.useState<Array<GetPaymentLinkDto>>([]);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [lastPaymentLinksTotalPages, setLastPaymentLinksTotalPages] = React.useState(1);
	const [selectedFilters, setSelectedFilters] = React.useState({});

	const apiConfig = getApiAuthConfig();
	const sellerApi = new SellersApi(apiConfig);
	const paymentLinkApi = new PaymentLinksApi(apiConfig);

	const getPaymentMethods = () => {
		let methods = ['card', 'billet', 'pix'];

		if (currentSeller && currentSeller?.no_payment_methods && Array.isArray(currentSeller?.no_payment_methods)) {
			if (currentSeller.no_payment_methods.includes('billet')) {
				methods = methods.filter((method) => method !== 'billet');
			}

			if (currentSeller.no_payment_methods.includes('pix')) {
				methods = methods.filter((method) => method !== 'pix');
			}
		}
		return methods;
	};

	const paymentLinkInitialValues = {
		amount: '',
		description: '',
		expiresIn: addDays(new Date(), 7),
		pre_capture: false,
		payer: {
			name: '',
			document: '',
			email: '',
		},
		search: {
			payer: '',
			document: '',
		},
		paymentMethods: getPaymentMethods(),
		note: '',
	};

	const schema = yup.object().shape({
		amount: yup.string().required('Campo obrigatório'),
		description: yup.string().trim().required('Campo obrigatório'),
		expiresIn: yup.string(),
		payer: yup.object().shape({
			name: yup.string().isCompleteName('Obrigatório nome completo').required('Campo obrigatório'),
			document: yup.string().isCpfOrCnpjOptional('Documento inválido'),
			email: yup.string().email(),
		}),
		paymentMethods: yup.array().required('1 Método de pagamento é obrigatório'),
		phone: yup.string(),
		note: yup.string(),
	});

	const fetchPaymentLinks = async (filters: any = {}) => {
		const { currentPage, limit, ...parsedFilters } = filters;

		const listSellerPaymentLinksRequest: ListSellerPaymentLinksRequest = {
			currentPage: pagination.currentPage,
			limit: pagination.limit,
			startDate: filters['startDate'] || format(new Date().setHours(0, 0, 0, 0), 'yyyy-MM-dd'),
			endDate: filters['endDate'] || format(new Date().setHours(23, 59, 59, 999), 'yyyy-MM-dd'),
			sellerId: currentSeller?.id!,
			...parsedFilters,
		};

		try {
			setIsLoading(true);

			const response = await sellerApi.listSellerPaymentLinks(listSellerPaymentLinksRequest);

			const filteredResults: Array<GetPaymentLinkDto> = response.results.map((response) => {
				const filterResponse = JSON.parse(JSON.stringify({ response }));
				const parsedResponse = {
					...filterResponse.response,
					amount: filterResponse?.response?.amount / 100,
					form_payment: paymentTypeEnum[filterResponse?.response?.sale?.form_payment],
					updated_at: filterResponse?.response?.sale?.updated_at,
				};

				return parsedResponse;
			});

			setPaymentLinks(filteredResults);
			setLastPaymentLinksTotalPages(response.total_pages);
		} catch (e) {
			addToast('Não foi possível listar os links de pagamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const submitForm = async (values, actions) => {
		const payload = {
			createPaymentLinkDto: {
				seller_id: currentSeller?.id!,
				amount: round(Number(values.amount) * 100),
				description: values.description.trim(),
				expires_in: values.expiresIn,
				payer: {
					document: values?.payer?.document,
					email: values?.payer?.email || undefined,
					name: values.payer.name.trim(),
					phone: values.phone,
				},
				payment_methods: values.paymentMethods,
				note: values?.note,
			},
		};

		const isToday = differenceInDays(startOfDay(new Date(values.expiresIn)), startOfDay(new Date())) === 0;

		if (isToday) {
			payload.createPaymentLinkDto.expires_in = endOfDay(new Date(values.expiresIn));
		}

		if (!payload.createPaymentLinkDto.payer.document) {
			delete payload.createPaymentLinkDto.payer.document;
		}

		if (currentSeller && currentSeller.merchant && currentSeller.merchant.pre_capture) {
			payload.createPaymentLinkDto['pre_capture'] = values.pre_capture;
		}

		try {
			setIsSubmitting(true);

			await paymentLinkApi.createPaymentLink(payload);

			addToast('Link enviado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});

			setIsSubmitting(false);

			await fetchPaymentLinks(selectedFilters);

			actions.resetForm();
		} catch (e) {
			const errorMessage = await parseExceptionMessage(e, '');
			addToast('Não foi possível salvar o link de pagamento. Erro: ' + errorMessage, {
				appearance: 'error',
				autoDismiss: true,
			});

			setIsSubmitting(false);
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, { route: history.location.pathname });
	}

	React.useEffect(() => {
		if (currentSeller) {
			fetchPaymentLinks(selectedFilters);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, pagination]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex flexDirection={`column`} w='100%'>
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Venda
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink opacity='0.9' cursor='unset' href='#' fontSize='md' fontWeight={`semibold`}>
							Venda por Link
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<Formik enableReinitialize initialValues={paymentLinkInitialValues} validationSchema={schema} onSubmit={submitForm}>
				{({ handleSubmit, setFieldValue, resetForm, values, errors, isValid }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Loader isOpen={isLoading} />

							<Flex mb='4'>
								<Title id='link-sale-title-text' fontSize={isMobile ? 'lg' : '2xl'}>VENDA POR LINK</Title>
							</Flex>

							<Flex flexDirection={`column`}>
								<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
									<Stack spacing={3} color={`primary`}>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
											<FormControl isRequired>
												<FormLabel id='payment-link-amount-form-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													Valor Total do Serviço
												</FormLabel>
												<FormCurrencyInput
													id='payment-link-amount-form-currency-input'
													name='amount'
													isDisabled={isSubmitting}
													borderColor={`darkGrey`}
												/>
												<FormErrorMessage id='payment-link-amount-form-error-text' name='amount' />
											</FormControl>
											<FormControl isRequired>
												<FormLabel id='description-form-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													N° do Pedido
												</FormLabel>
												<FormInput id='payment-link-description-form-input' name='description' isDisabled={isSubmitting} />
												<FormErrorMessage id='payment-link-description-form-error-text' name='description' />
											</FormControl>
											<FormControl>
												<FormLabel id='expires-in-form-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													Data de Vencimento
												</FormLabel>
												<FormDatePickerInput
													id='payment-link-expires-in-date-picker-input'
													name='expiresIn'
													placeholder={`Informe o período`}
													minDate={addDays(new Date(), 0)}
													borderColor={`darkGrey`}
												/>
												<FormErrorMessage id='payment-link-expires-in-form-error-text' name='expiresIn' />
											</FormControl>
										</Stack>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb='4'>
											<FormControl isRequired>
												<FormLabel id='payer-name-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													Nome Completo
												</FormLabel>
												<FormInput id='payment-link-payer-name-form-input' name='payer[name]' isDisabled={isSubmitting} />
												<FormErrorMessage id='payment-link-payer-name-form-error-text' name='payer[name]' />
											</FormControl>
											<FormControl>
												<FormLabel id='payer-email' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													E-mail
												</FormLabel>
												<FormInput id='payment-link-payer-email-form-input' name='payer[email]' isDisabled={isSubmitting} />
												<FormErrorMessage id='payment-link-payer-email-form-error-text' name='payer[email]' />
											</FormControl>
											<FormControl>
												<FormLabel id='cpf-cnpj-form-label' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													CPF/CNPJ
												</FormLabel>
												<FormCpfCnpjInput
													id='payment-link-document-form-cpf-cnpj-input'
													name='payer[document]'
													isDisabled={isSubmitting}
													isSearchPayer={false}
													setSelectedPayer={() => {}}
												/>
												<FormErrorMessage id='payment-link-payer-document-form-error-text' name='payer[document]' />
											</FormControl>
											<FormControl>
												<FormLabel id='phone-form-label' fontSize={`md`} fontWeight={`medium`}>
													Telefone
												</FormLabel>
												<FormPhoneInput id='payment-link-phone-form-phone-input' name='phone' isDisabled={isSubmitting} borderColor={`darkGrey`} />
												<FormErrorMessage id='payment-link-payer-phone-form-error-text' name='phone' />
											</FormControl>
										</Stack>
										<Stack isInline spacing={4} width={['100%', '100%', '50%']}>
											<FormControl>
												<FormLabel id='note-form-label'>Observação</FormLabel>
												<FormTextarea id='note-form-text-area' name='note' isDisabled={isSubmitting} resize='none' maxLength={250} value={values.note} />
												<FormErrorMessage id='payment-link-note-form-error-text' name='note' />
											</FormControl>
										</Stack>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
											<FormControl>
												<Stack>
													<Text id='payment-methods-text' fontSize={`md`} fontWeight={`medium`}>
														Formas de pagamento
													</Text>
													<CheckboxGroup
														colorScheme='green'
														value={values.paymentMethods}
														onChange={(value) => setFieldValue('paymentMethods', value)}
													>
														<Stack isInline spacing={4}>
															<Checkbox
																id='card-payment-method-checkbox'
																value='card'
																fontSize={`md`}
																fontWeight={`medium`}
																className='checkbox__credit'
															>
																Cartão
															</Checkbox>
															{!currentSeller?.no_payment_methods?.includes('billet') && (
																<Checkbox
																	id='billet-payment-method-checkbox'
																	value='billet'
																	fontSize={`md`}
																	fontWeight={`medium`}
																	className='checkbox__billet'
																>
																	Boleto
																</Checkbox>
															)}
															{!currentSeller?.no_payment_methods?.includes('pix') && (
																<Checkbox
																	id='pix-payment-method-checkbox'
																	value='pix'
																	fontSize={`md`}
																	fontWeight={`medium`}
																	className='checkbox__pix'
																>
																	Pix
																</Checkbox>
															)}
														</Stack>
													</CheckboxGroup>
												</Stack>
											</FormControl>

											{currentSeller && currentSeller.merchant && currentSeller.merchant.pre_capture && (
												<FormControl>
													<Stack>
														<Text id='pre-capture-text' fontSize={`md`} fontWeight={`medium`}>
															Pré-captura
														</Text>

														<Checkbox
															id='active-pre-capture-checkbox'
															isChecked={values.pre_capture}
															onChange={(e) => {
																setFieldValue('pre_capture', e.target.checked);
															}}
														>
															Sim
														</Checkbox>
													</Stack>
												</FormControl>
											)}
										</Stack>
									</Stack>
									<FormControl display='flex' justifyContent={isMobile ? 'center' : 'flex-end'} mt='4'>
										<Button
											id='generate-link-button'
											size='lg'
											type='submit'
											disabled={!isValid || isSubmitting}
											bgColor={'primary'}
											isLoading={isSubmitting}
											className='button__generate-link'
										>
											Gerar Link
										</Button>
									</FormControl>
								</Flex>
							</Flex>
						</form>
					);
				}}
			</Formik>

			<Flex mt='8'>
				<Title id='generated-links-title-text'>Links Gerados</Title>
			</Flex>

			<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
				<PaymentLinksFilters
					onChangeFilters={async (filters) => {
						setSelectedFilters(filters);

						await fetchPaymentLinks(filters);
					}}
				/>

				<Flex overflowX='auto'>
					<PaymentLinksTable
						data={paymentLinks}
						setPagination={setPagination}
						pagination={pagination}
						totalPages={lastPaymentLinksTotalPages}
						onRowClick={(row) => navigateToDetails(row.original.sale.id)}
						seller={currentSeller}
						afterCancel={fetchPaymentLinks}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default PaymentLinkSale;
