/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBilletDataDto
 */
export interface GetBilletDataDto {
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    banco: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    data_emissao: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    data_vencimento: string;
    /**
     * 
     * @type {number}
     * @memberof GetBilletDataDto
     */
    valor: number;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    nosso_numero: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    instrucoes: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    agencia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    conta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    carteira: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    pagador: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    cedente: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    cedente_cnpj: string;
    /**
     * 
     * @type {string}
     * @memberof GetBilletDataDto
     */
    codigo_cedente: string;
}

export function GetBilletDataDtoFromJSON(json: any): GetBilletDataDto {
    return GetBilletDataDtoFromJSONTyped(json, false);
}

export function GetBilletDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBilletDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'banco': json['banco'],
        'data_emissao': json['data_emissao'],
        'data_vencimento': json['data_vencimento'],
        'valor': json['valor'],
        'nosso_numero': json['nosso_numero'],
        'instrucoes': json['instrucoes'],
        'agencia': !exists(json, 'agencia') ? undefined : json['agencia'],
        'conta': !exists(json, 'conta') ? undefined : json['conta'],
        'carteira': json['carteira'],
        'pagador': json['pagador'],
        'cedente': json['cedente'],
        'cedente_cnpj': json['cedente_cnpj'],
        'codigo_cedente': json['codigo_cedente'],
    };
}

export function GetBilletDataDtoToJSON(value?: GetBilletDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'banco': value.banco,
        'data_emissao': value.data_emissao,
        'data_vencimento': value.data_vencimento,
        'valor': value.valor,
        'nosso_numero': value.nosso_numero,
        'instrucoes': value.instrucoes,
        'agencia': value.agencia,
        'conta': value.conta,
        'carteira': value.carteira,
        'pagador': value.pagador,
        'cedente': value.cedente,
        'cedente_cnpj': value.cedente_cnpj,
        'codigo_cedente': value.codigo_cedente,
    };
}


