import React from 'react';

import NewModal from 'containers/NewModal';

import SplitForm from './SplitForm';
import { EditingSplitType, SplitSellerValue } from '..';

type SplitRulesModalType = {
	isOpen: boolean;
	onClose: () => void;
	splits: SplitSellerValue[];
	setSplits: React.Dispatch<React.SetStateAction<SplitSellerValue[]>>;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	editingSplit?: EditingSplitType;
	setEditingSplit: React.Dispatch<React.SetStateAction<EditingSplitType | undefined>>;
}

const SplitRulesModal: React.FC<SplitRulesModalType> = ({ isOpen, onClose, splits, setSplits, setIsOpen, editingSplit, setEditingSplit }) => {
	return (
		<NewModal showCloseButton isOpen={isOpen} onClose={onClose}>
			<SplitForm
				setEditingSplit={setEditingSplit}
				editingSplit={editingSplit}
				splits={splits}
				setSplits={setSplits}
				setIsOpen={setIsOpen}
			/>
		</NewModal>
	);
};

export default SplitRulesModal;