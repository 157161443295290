import React from 'react';
import { sellerAssignments } from 'services/enums.service';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';
import { maskFullDate, maskMoney } from '../../../services/masks.service';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages, onRowClick }) => {
	const columns = React.useMemo(() => {
		let cols: any = [];

		return cols.concat([
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='id-header-title-styled' column={column}>
						ID
					</HeaderTitleStyled>
				),
				accessor: 'id',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sale-id-header-title-styled' column={column}>
						ID da Venda
					</HeaderTitleStyled>
				),
				accessor: 'sale_id',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sale-description-header-title-styled' column={column}>
						Descrição da venda
					</HeaderTitleStyled>
				),
				accessor: 'sale_description',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='referency' column={column}>
						Referência
					</HeaderTitleStyled>
				),
				accessor: 'sale_acquirer_reference',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='original-amount-title' column={column}>
						Valor da Venda
					</HeaderTitleStyled>
				),
				accessor: 'sale_original_amount',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='seller-name' column={column}>
						Estabelecimento
					</HeaderTitleStyled>
				),
				accessor: 'seller_name',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='assignment' column={column}>
						Atribuição
					</HeaderTitleStyled>
				),
				accessor: 'seller_assignment',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{sellerAssignments[value || '']}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='created-at' column={column}>
						Data de criação
					</HeaderTitleStyled>
				),
				accessor: 'created_at',
				Cell: ({
					cell: {
						value,
						row: { original, index },
						column: { id },
					},
				}) => <CellContentStyled id={`${index}-${id}-cell`}>{maskFullDate(value)}</CellContentStyled>,
			},
		]);
	}, []);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			onRowClick={onRowClick}
		/>
	);
};
