import { HStack, TableProps, useTheme } from '@chakra-ui/react';
import React from 'react';
import {
	AiFillCloseCircle,
	AiFillCheckCircle,
	AiFillClockCircle,
	AiFillMinusCircle,
	AiFillDollarCircle,
	AiOutlineIssuesClose,
} from 'react-icons/ai';

import { settlementStatus } from 'services/enums.service';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';
import { maskMoney } from '../../../services/masks.service';
import Text from '../../../components/Text';

const iconStatusLiteral = {
	paid: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillMinusCircle color='tomato' size={16} />,
	sent: <AiFillDollarCircle color='#47FF62' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
	resent: <AiOutlineIssuesClose color='#63c75b' size={16} />,
};

interface IPaymentTable extends TableProps {
	data: string[];
	showSellerColumn: boolean;
	onRowClick?: (row: any) => void;
	setPagination: React.Dispatch<React.SetStateAction<{ currentPage: number; limit: number }>>;
	pagination: { currentPage: number; limit: number };
	totalPages: number;
}

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default (props: IPaymentTable) => {
	const { sizes } = useTheme();
	const { data, showSellerColumn, ...rest } = props;

	const columns = React.useMemo(
		() => {
			let cols: any = [];

			if (showSellerColumn) {
				cols.push({
					Header: ({ column }) => (
						<HeaderTitleStyled id='seller-title-text' column={column}>
							Estabelecimento
						</HeaderTitleStyled>
					),
					accessor: 'seller',
					Cell: ({ cell: { row, column } }) => (
						<CellContentStyled id={`${row.index}-${column.id}-cell`}>{row.original.seller.name}</CellContentStyled>
					),
				});
			}
			return cols.concat([
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='settlement-day-title-text' column={column}>
							Data de Pagamento
						</HeaderTitleStyled>
					),
					accessor: 'settlement_day',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContentStyled id={`${row.index}-${column.id}-cell`}>{value.split('-').reverse().join('/')}</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='status-title' column={column}>
							Status
						</HeaderTitleStyled>
					),
					accessor: 'status',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContentStyled id={`${row.index}-${column.id}-cell`}>
							<HStack>
								<Text id={`${row.index}-${column.id}-icon-text`}>{iconStatusLiteral[value]}</Text>
								<Text id={`${row.index}-${column.id}-text`}>{settlementStatus[value] ?? 'N/A'}</Text>
							</HStack>
						</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='settlement-method-title' column={column}>
							Método de Liquidação
						</HeaderTitleStyled>
					),
					accessor: 'type',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='total-title' column={column}>
							Valor
						</HeaderTitleStyled>
					),
					accessor: 'total',
					Cell: ({ cell: { value, row, column } }) => (
						<CellContentStyled id={`${row.index}-${column.id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='id-title' column={column}>
							ID
						</HeaderTitleStyled>
					),
					accessor: 'id',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				},
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			maxHeightTableWrapper={`calc(100vh - ${sizes.headerHeight} - 24.1875rem)`}
			{...rest}
			variant='unstyled'
		/>
	);
};
