/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExportPosListDto
 */
export interface CreateExportPosListDto {
    /**
     * Fonte da requisição
     * @type {string}
     * @memberof CreateExportPosListDto
     */
    source?: CreateExportPosListDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateExportPosListDto
     */
    extension: CreateExportPosListDtoExtensionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportPosListDto
     */
    status?: Array<CreateExportPosListDtoStatusEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportPosListDto
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportPosListDto
     */
    seller_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportPosListDto
     */
    end_date?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateExportPosListDtoSourceEnum {
    SalesHistory = 'sales-history',
    SalesPaymentHistory = 'sales-payment-history',
    SettlementSalesHistory = 'settlement-sales-history',
    PosBackoffice = 'pos-backoffice'
}/**
* @export
* @enum {string}
*/
export enum CreateExportPosListDtoExtensionEnum {
    Xls = 'xls',
    Csv = 'csv',
    Json = 'json',
    Pdf = 'pdf'
}/**
* @export
* @enum {string}
*/
export enum CreateExportPosListDtoStatusEnum {
    Active = 'active',
    Broken = 'broken',
    Canceled = 'canceled',
    Inventory = 'inventory',
    StockForRepair = 'stock_for_repair',
    Repair = 'repair',
    Lost = 'lost'
}

export function CreateExportPosListDtoFromJSON(json: any): CreateExportPosListDto {
    return CreateExportPosListDtoFromJSONTyped(json, false);
}

export function CreateExportPosListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExportPosListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': !exists(json, 'source') ? undefined : json['source'],
        'extension': json['extension'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
    };
}

export function CreateExportPosListDtoToJSON(value?: CreateExportPosListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'extension': value.extension,
        'status': value.status,
        'search': value.search,
        'seller_id': value.seller_id,
        'end_date': value.end_date,
    };
}


