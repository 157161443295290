/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateFileExportRequestDto
 */
export interface CreateFileExportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFileExportRequestDto
     */
    type: CreateFileExportRequestDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFileExportRequestDto
     */
    extension: CreateFileExportRequestDtoExtensionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFileExportRequestDto
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFileExportRequestDto
     */
    end_date: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFileExportRequestDto
     */
    querystring: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFileExportRequestDto
     */
    seller_id: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateFileExportRequestDtoTypeEnum {
    Sales = 'sales',
    SalesConcil = 'sales_concil',
    Payments = 'payments',
    Settlement = 'settlement',
    SettlementTransactions = 'settlement_transactions',
    SettlementsBatchTransactions = 'settlements_batch_transactions',
    Protocols = 'protocols',
    SalesHistory = 'sales_history',
    MonthlyAlerts = 'monthly_alerts',
    Sellers = 'sellers',
    SalesBillPayment = 'sales_bill_payment',
    AssignmentTransactionAlerts = 'assignment_transaction_alerts',
    ConsolidateCostSales = 'consolidate_cost_sales',
    PosList = 'pos_list'
}/**
* @export
* @enum {string}
*/
export enum CreateFileExportRequestDtoExtensionEnum {
    Xls = 'xls',
    Csv = 'csv',
    Json = 'json',
    Pdf = 'pdf'
}

export function CreateFileExportRequestDtoFromJSON(json: any): CreateFileExportRequestDto {
    return CreateFileExportRequestDtoFromJSONTyped(json, false);
}

export function CreateFileExportRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFileExportRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'extension': json['extension'],
        'start_date': json['start_date'],
        'end_date': json['end_date'],
        'querystring': json['querystring'],
        'seller_id': json['seller_id'],
    };
}

export function CreateFileExportRequestDtoToJSON(value?: CreateFileExportRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'extension': value.extension,
        'start_date': value.start_date,
        'end_date': value.end_date,
        'querystring': value.querystring,
        'seller_id': value.seller_id,
    };
}


