import React from 'react';

import {
	Flex,
	Center,
	FormControl,
	IconButton,
	InputGroup,
	InputRightElement,
	Stack,
	BreadcrumbItem,
	BreadcrumbLink,
	useBreakpointValue,
	Tooltip,
	chakra,
} from '@chakra-ui/react';

import { BiExport } from 'react-icons/bi';

import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import { RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { Formik } from 'formik';

import Loader from '../../containers/Loader';
import Button from '../../components/Button';
import Input from '../../components/Input';
import MultiSelect from '../../components/MultiSelect';
import RangeDatePicker from '../../containers/RangeDatePicker';
import ExportFileSuccessModal from '../../containers/ExportFileSuccessModal';
import SampleTable from './components/SampleTable';
import FormPhoneInput from 'components/Form/FormPhoneInput';

import IPeriod from '../../interfaces/period.interface';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { maskMoney } from '../../services/masks.service';

import {
	SellersApi,
	PaginatedDto,
	ListSalesRequest,
	SalesApi,
	CreateExportSalesListDtoExtensionEnum,
	InternalListSellerSalesRequest,
	OmieApi,
	CreateExportSalesListDtoSourceEnum,
	ListSellerPosRequest,
	GetSellerPosDto,
} from '../../clients';
import { IoInformationCircleOutline } from 'react-icons/io5';
import Breadcrumb from 'components/Breadcrumb';
import Text from 'components/Text';
import { useTheme } from 'contexts/ThemeProvider';
import OmieConfirmModal from '../../components/OmieConfirmModal';
import CurrencyInput from '../../components/CurrencyInput';

const SalesHistoryPayment: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [exportIsLoading, setExportIsLoading] = React.useState<boolean>(false);
	const [exportPdfIsLoading, setExportPdfIsLoading] = React.useState<boolean>(false);
	const [consolidateReportLoading, setConsolidateReportLoading] = React.useState<boolean>(false);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const { theme } = useTheme();
	const [salesOmieLoading, setSalesOmieLoading] = React.useState<boolean>(false);
	const [confirmExportOmieModalOpen, setConfirmExportOmieModalOpen] = React.useState<boolean>(false);

	const [sales, setSales] = React.useState<PaginatedDto>();
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [filters, setFilters] = React.useState({
		status: [{ value: 'succeeded', label: 'Aprovado' }],
	});
	const [listPOS, setListPOS] = React.useState<GetSellerPosDto[]>([]);

	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const { addToast } = useToasts();
	const { isBackoffice, isMerchant } = useAuth();
	const { currentSeller } = useCurrentSeller();

	const initialValues = {
		id: '',
		search: '',
		payer_name: '',
		status: [{ value: 'succeeded', label: 'Aprovado' }],
		formPayment: [],
		type: [],
		created_by_user_name: '',
		startDate: format(period[0].startDate, 'yyyy-MM-dd'),
		endDate: format(period[0].endDate, 'yyyy-MM-dd'),
		pos: [],
	};

	const history = useHistory();
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const salesApi = new SalesApi(apiConfig);
	const omieApi = new OmieApi(apiConfig);

	function formatParsedParams(parsedParams) {
		if (parsedParams.search && parsedParams.search.length > 0) {
			const status = [{ value: '', label: '' }];

			parsedParams = {
				startDate: '',
				endDate: '',
				status: status?.map((v) => v.value),
				formPayment: [],
				type: [],
				pos: [],
				search: parsedParams.search,
			};
		}

		return parsedParams;
	}

	async function fetchListSellerSalesRequest(params, currentPage?: number) {
		let {
			status,
			formPayment,
			type,
			payer_name,
			payer_email,
			payer_phone,
			created_by_user_name,
			original_amount,
			pos,
			...parsedParams
		} = params;

		parsedParams = formatParsedParams(parsedParams);
		setIsLoading(true);

		if (currentSeller) {
			try {
				let requestParams: InternalListSellerSalesRequest = {
					currentPage: currentPage || pagination.currentPage,
					limit: pagination.limit,
					startDate: format(period[0].startDate, 'yyyy-MM-dd'),
					endDate: format(period[0].endDate, 'yyyy-MM-dd'),
					sellerId: currentSeller?.id!,
					status: status?.map((v) => v.value),
					formPayment: formPayment?.map((v) => v.value),
					type: type?.map((v) => v.value),
					payerName: payer_name,
					paymentDay: true,
					payerEmail: payer_email,
					payerPhone: payer_phone,
					createdByUserName: created_by_user_name,
					originalAmount: original_amount > 0 ? parseInt((original_amount * 100).toFixed(0), 10) : undefined,
					pos: pos?.map((v) => v.value),
					calcTotalAmount: true,		
					...parsedParams,
				};

				const response = await sellersApi.internalListSellerSales(requestParams);

				setSales(response);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: ListSalesRequest = {
					currentPage: currentPage || pagination.currentPage,
					limit: pagination.limit,
					startDate: format(period[0].startDate, 'yyyy-MM-dd'),
					endDate: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					formPayment: formPayment?.map((v) => v.value),
					type: type?.map((v) => v.value),
					payerName: payer_name,
					paymentDay: true,
					payerEmail: payer_email,
					payerPhone: payer_phone,
					createdByUserName: created_by_user_name,
					originalAmount: original_amount > 0 ? parseInt((original_amount * 100).toFixed(0), 10) : undefined,
					...parsedParams,
				};

				const response = await salesApi.listSales(requestParams);
				setSales(response);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleFormSubmit = async (values) => {
		setFilters(values);

		await fetchListSellerSalesRequest(values, 1);
	};

	const handleExportReportFile = async (params) => {
		setExportIsLoading(true);

		try {
			let { status, formPayment, type, payer_name, original_amount, pos, ...parsedParams } = params;

			if (parsedParams.search && parsedParams.search.length > 0) {
				const status = [{ value: '', label: '' }];

				parsedParams = {
					start_date: '',
					end_date: '',
					status: status?.map((v) => v.value),
					formPayment: [],
					type: [],
					pos: [],
					search: parsedParams.search,
				};
			}

			await salesApi.createSalesListExportRequest({
				createExportSalesListDto: {
					start_date: format(period[0].startDate, 'yyyy-MM-dd'),
					end_date: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					form_payment: formPayment?.map((v) => v.value),
					type: type?.map((v) => v.value),
					extension: CreateExportSalesListDtoExtensionEnum.Xls,
					seller_id: currentSeller?.id,
					payer_name,
					payment_day: true,
					original_amount: original_amount > 0 ? parseInt((original_amount * 100).toFixed(0), 10) : undefined,
					pos: pos?.map((v) => v.value),
					...parsedParams,
				},
			});
			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportIsLoading(false);
		}
	};

	const handleExportHistoryReport = async (params) => {
		setExportPdfIsLoading(true);

		try {
			let { status, formPayment, type, payer_name, original_amount, pos, ...parsedParams } = params;

			if (parsedParams.search && parsedParams.search.length > 0) {
				const status = [{ value: '', label: '' }];

				parsedParams = {
					start_date: '',
					end_date: '',
					status: status?.map((v) => v.value),
					formPayment: [],
					type: [],
					pos: [],
					search: parsedParams.search,
				};
			}

			await salesApi.createSalesHistoryExportRequest({
				createExportSalesListDto: {
					start_date: format(period[0].startDate, 'yyyy-MM-dd'),
					end_date: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					form_payment: formPayment?.map((v) => v.value),
					type: type?.map((v) => v.value),
					extension: CreateExportSalesListDtoExtensionEnum.Pdf,
					seller_id: currentSeller?.id,
					payer_name,
					payment_day: true,
					original_amount: original_amount > 0 ? parseInt((original_amount * 100).toFixed(0), 10) : undefined,
					pos: pos?.map((v) => v.value),
					...parsedParams,
				},
			});
			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportPdfIsLoading(false);
		}
	};

	const handleExportConsolidateReport = async (params) => {
		setConsolidateReportLoading(true);

		try {
			let { status, original_amount, ...parsedParams } = params;

			await salesApi.createSalesConsolidateExportRequest({
				createExportSalesListDto: {
					start_date: format(period[0].startDate, 'yyyy-MM-dd'),
					end_date: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					extension: CreateExportSalesListDtoExtensionEnum.Pdf,
					seller_id: currentSeller?.id,
					payment_day: true,
					original_amount: original_amount > 0 ? parseInt((original_amount * 100).toFixed(0), 10) : undefined,
					source: CreateExportSalesListDtoSourceEnum.SalesPaymentHistory,
					...parsedParams,
				},
			});
			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setConsolidateReportLoading(false);
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, { route: history.location.pathname });
	}

	const handleGetPOS = async () => {
		setIsLoading(true);

		const listSellerPosRequest: ListSellerPosRequest = {
			sellerId: currentSeller?.id!,
		};

		const response = await sellersApi.listSellerPos(listSellerPosRequest);

		setListPOS(response);
		setIsLoading(false);
	};

	React.useEffect(() => {
		if (currentSeller) {
			handleGetPOS();
		}

		fetchListSellerSalesRequest(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, currentSeller]);

	const handleSalesOmie = async () => {
		setSalesOmieLoading(true);

		try {
			await omieApi.omieControllerIncludeAccountsReceivable();

			addToast('Processo iniciado!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSalesOmieLoading(false);
			setConfirmExportOmieModalOpen(false);
		}
	};

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Gestão Financeira</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink opacity='0.9' cursor='unset' fontSize='md' fontWeight='semibold'>
						Histórico de Pagamento
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<ExportFileSuccessModal
				showCloseButton
				isOpen={openExportSuccessModal}
				onOkClick={() => {
					setOpenExportSuccessModal(false);
				}}
				onClose={() => {
					setOpenExportSuccessModal(false);
				}}
			/>

			<Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Flex direction={['column', 'column', 'column', 'column', 'row']} justify='space-between' mb={1}>
								<FormControl width='auto' mb={3}>
									<InputGroup>
										<Input
											id='sale-payment-history-search-input'
											borderColor='darkGrey'
											name='search'
											placeholder='Busca...'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={() => handleSubmit()}
											py='5'
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													handleSubmit();
												}
											}}
										/>

										<InputRightElement
											children={
												<IconButton
													_hover={{ backgroundColor: 'transparent' }}
													_focus={{ outline: 'none' }}
													aria-label='Search database'
													icon={<SearchIcon />}
													color='primary'
													backgroundColor='transparent'
													onClick={() => handleSubmit()}
												/>
											}
										/>
									</InputGroup>
								</FormControl>
								<Stack direction={isMobile ? 'column' : 'row'} justify='flex-end' mb={4}>
									{isBackoffice && (
										<Button
											id='export-omie-button'
											bgColor='primary'
											isDisabled={salesOmieLoading}
											isLoading={salesOmieLoading}
											onClick={() => setConfirmExportOmieModalOpen(true)}
											leftIcon={<BiExport size={20} />}
										>
											Exportar vendas para OMIE
										</Button>
									)}
									<Button
										{...(sales?.results.length === 0 && { disabled: true })}
										bgColor='black'
										id='sales-history-paymet-consolidated-button'
										isDisabled={consolidateReportLoading}
										isLoading={consolidateReportLoading}
										onClick={() => handleExportConsolidateReport(values)}
										leftIcon={<BiExport size={20} />}
										order={[1, 0]}
									>
										Exportar Custo Financeiro para PDF
									</Button>
									<Button
										id='export-pdf-button'
										{...(sales?.results.length === 0 && { disabled: true })}
										bgColor='black'
										isDisabled={exportPdfIsLoading}
										isLoading={exportPdfIsLoading}
										onClick={() => handleExportHistoryReport(values)}
										leftIcon={<BiExport size={20} />}
										order={[1, 0]}
									>
										Exportar para PDF
									</Button>
									<Button
										id='export-exel-button'
										{...(sales?.results.length === 0 && { disabled: true })}
										bgColor='primary'
										isDisabled={exportIsLoading}
										isLoading={exportIsLoading}
										onClick={() => handleExportReportFile(values)}
										leftIcon={<BiExport size={20} />}
										order={[0, 1]}
									>
										Exportar para Excel
									</Button>
								</Stack>
							</Flex>

							<Flex direction='column' p='5' rounded='md' bgColor='white'>
								<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-between' mb='5'>
									<Flex>
										<Center position='relative'>
											<RangeDatePicker
												period={period}
												setPeriod={setPeriod}
												setFieldValue={setFieldValue}
												handleSubmit={handleSubmit}
												backgroundColor='primary'
												borderWidth={1}
												borderColor='white'
												py='5'
											/>
										</Center>

										<Center position='relative'>
											<Tooltip label={'Data do Pagamento'} placement='top' hasArrow>
												<chakra.span>
													<IoInformationCircleOutline size='1.3rem' color='primary' />
												</chakra.span>
											</Tooltip>
										</Center>
									</Flex>

									<Flex width='auto' px='2' direction={['column', 'column', 'column', 'column', 'row']} gridGap='5'>
										{currentSeller && (
											<Center minW='13.2rem'>
												<FormControl fontSize='12px'>
													<MultiSelect
														id='pos-sales-history-payment'
														placeholder='Máquina'
														options={listPOS.map((pos) => {
															return { value: pos.terminal_id, label: pos.terminal_id };
														})}
														value={values.pos}
														onChange={(value) => {
															setFieldValue('pos', value);
															handleSubmit();
														}}
													/>
												</FormControl>
											</Center>
										)}

										<InputGroup>
											<Input
												id='sale-payment-history-payer-name-input'
												borderColor='darkGrey'
												name='payer_name'
												placeholder='Pagador...'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={() => handleSubmit()}
												py='5'
											/>

											<InputRightElement
												children={
													<IconButton
														_hover={{ backgroundColor: 'transparent' }}
														_focus={{ outline: 'none' }}
														aria-label='Search database'
														icon={<SearchIcon />}
														color='primary'
														backgroundColor='transparent'
														onClick={() => handleSubmit()}
													/>
												}
											/>
										</InputGroup>

										<InputGroup>
											<Input
												id='sale-payment-history-created-by-user-name-input'
												borderColor='darkGrey'
												name='created_by_user_name'
												placeholder='Usuário'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={() => handleSubmit()}
												py='5'
											/>

											<InputRightElement
												children={
													<IconButton
														_hover={{ backgroundColor: 'transparent' }}
														_focus={{ outline: 'none' }}
														aria-label='Pesquisar por usuário'
														icon={<SearchIcon />}
														color='primary'
														backgroundColor='transparent'
														onClick={() => handleSubmit()}
													/>
												}
											/>
										</InputGroup>
									</Flex>

									{sales && sales.additional_data && (
										<Flex mt={isMobile ? '4' : '0'}>
											<Text id='total-original-amount-text' fontSize='md' fontWeight='semibold' mr='3'>
												Total Bruto: R$ {maskMoney(sales.additional_data['total_original_amount'] / 100 || 0)}
											</Text>
											<Text id='total-amount-text' fontSize='md' fontWeight='semibold'>
												Total Liquido: R$ {maskMoney(sales.additional_data['total_amount'] / 100 || 0)}
											</Text>
										</Flex>
									)}
								</Flex>

								<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-between'>
									<Stack direction={isMobile ? 'column' : 'row'} spacing='0' wrap='wrap'>
										<Center minW='13.2rem' mb={4} mr={{ sm: 0, lg: 4 }}>
											<FormControl>
												<MultiSelect
													id='status'
													placeholder='Status'
													options={[
														{ value: 'pending', label: 'Pendente' },
														{ value: 'succeeded', label: 'Aprovado' },
														{ value: 'pending_cancel', label: 'Cancelamento Pendente' },
														{ value: 'canceled', label: 'Cancelado' },
														{ value: 'failed', label: 'Falho' },
														{ value: 'expired', label: 'Expirado' },
														{ value: 'waiting_confirmation', label: 'Aguardando Confirmação' },
														{ value: 'pending_capture', label: 'Captura Pendente' },
														{ value: 'in_progress_capture', label: 'Captura em andamento' },
													]}
													value={values.status}
													onChange={(value) => {
														setFieldValue('status', value);
														handleSubmit();
													}}
												/>
											</FormControl>
										</Center>

										<Center minW='13.2rem' mb={4} mr={{ sm: 0, lg: 4 }}>
											<FormControl>
												<MultiSelect
													id='form-payment'
													placeholder='Forma de Pagamento'
													options={[
														{ value: 'credit', label: 'Crédito' },
														{ value: 'debit', label: 'Débito' },
														{ value: 'billet', label: 'Boleto' },
														{ value: 'pix', label: 'Pix' },
														{ value: 'pix_pos', label: 'Pix Pos' },
													]}
													value={values.formPayment}
													onChange={(value) => {
														setFieldValue('formPayment', value);
														handleSubmit();
													}}
												/>
											</FormControl>
										</Center>

										<Center minW='13.2rem' mb={4} mr={{ sm: 0, lg: 4 }}>
											<FormControl>
												<MultiSelect
													id='type'
													placeholder='Meio de Pagamento'
													options={[
														{ value: 'boleto', label: 'Boleto' },
														{ value: 'pos', label: 'Pos' },
														{ value: 'payment_link', label: 'Link de Pagamento' },
														{ value: 'protest_link', label: 'Link de Protesto' },
														{ value: 'online', label: 'Online' },
														{ value: 'pix', label: 'Pix' },
														{ value: 'auto_generate_payment_link', label: 'Plataforma de Cobrança' },
														{ value: 'billet_code', label: 'Boleto Hibrido' },
													]}
													value={values.type}
													onChange={(value) => {
														setFieldValue('type', value);
														handleSubmit();
													}}
												/>
											</FormControl>
										</Center>

										<Center minW='13.2rem' mb={4} mr={{ sm: 0, lg: 4 }}>
											<InputGroup>
												<Input
													id='sale-payment-history-payer-email-input'
													borderColor='darkGrey'
													name='payer_email'
													placeholder='Email'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={() => handleSubmit()}
													py='5'
												/>

												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Search database'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</Center>

										<Center minW='13.2rem' mb={4} mr={{ sm: 0, lg: 4 }}>
											<InputGroup>
												<FormPhoneInput
													id='sales-history-payer-phone-form-input'
													borderColor='darkGrey'
													name='payer_phone'
													placeholder='Telefone'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={() => handleSubmit()}
													py='5'
												/>

												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Pesquisar por telefone'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</Center>
										<Center minW='13.2rem' mb={4}>
											<InputGroup>
												<CurrencyInput
													id='sale-history-payment-original-amount-currency-input'
													name='original_amount'
													py='5'
													onAmountChange={(event, value) => {
														setFieldValue('original_amount', value);
													}}
													onBlur={() => handleSubmit()}
												/>

												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Pesquisar por valor bruto'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</Center>
									</Stack>

									<Center>
										<Tooltip label={'Refazer a busca'} placement='top' hasArrow>
											<IconButton
												_hover={{ backgroundColor: 'transparent' }}
												_focus={{ outline: 'none' }}
												aria-label='Refazer a busca'
												icon={<RepeatIcon />}
												color='primary'
												backgroundColor='transparent'
												size='lg'
												onClick={() => handleSubmit()}
											/>
										</Tooltip>
										{(values.formPayment.length > 0 || values.status.length > 0) && (
											<Button
												id='clear-filter-button'
												onClick={() => {
													resetForm();
													handleSubmit();
												}}
												paddingInline='10px'
												_focus={{ outline: 'none' }}
												color={theme?.primary_color || 'primary'}
												bgColor='transparent'
											>
												Limpar Filtros
											</Button>
										)}
									</Center>
								</Flex>

								<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
									{sales && (
										<SampleTable
											showSellerColumn={isBackoffice || isMerchant}
											data={sales}
											onRowClick={(row) => navigateToDetails(row.values.id)}
											setPagination={setPagination}
											pagination={pagination}
											totalPages={totalPages}
										/>
									)}
								</Flex>
							</Flex>
							<OmieConfirmModal
								confirmExportOmie={confirmExportOmieModalOpen}
								isLoading={salesOmieLoading}
								handleSubmit={handleSalesOmie}
							/>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default SalesHistoryPayment;
