import * as React from 'react';

import { InputProps, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';

import InputMask from "react-input-mask";
import Input from '../Input';

import { Field } from 'formik';

type FormCpfInputProps = InputProps & {
	onFilled?: (value: string) => void;
	onUnfilled?: (value: string) => void;
	isLoading?: boolean;
	id: string;
};

const FormCpfInput: React.FC<FormCpfInputProps> = (props: FormCpfInputProps) => {
	const [isFilled, setIsFilled] = React.useState(false);

	const { onFilled, onUnfilled, ...parsedProps } = props;

	return (
		<Field {...parsedProps}>
			{({ form, field }) => {
				return (
					<InputMask
						mask={"999.999.999-99"}
						maskChar={null}
						value={field.value}
						onBlur={form.handleBlur}
						onChange={(e) => {
							props.onChange && props.onChange(e);
							form.setFieldValue(field.name, e.target.value);

							const parsedValue = e.target.value.replace(/\D+/gi, '');

							if (parsedValue.length === 11) {
								if (! isFilled) {
									setIsFilled(true);

									props.onFilled && props.onFilled(parsedValue);
								}								
							} else {
								setIsFilled(false);

								props.onUnfilled && props.onUnfilled(parsedValue);
							}
						}}
						{...parsedProps}
					>
						{(inputProps) => {
							return (
								<InputGroup>
									<Input id={props.id} type="text" {...inputProps} />
									{ props.isLoading && (
										<InputRightElement pb={2} children={
											<Spinner thickness="1px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="sm" />
										} />
									) }						
								</InputGroup>								
							);
						}}
					</InputMask>
				)				
			}}			
		</Field>
	)
};

export default FormCpfInput;
