/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreatePosPrefixDto,
    CreatePosPrefixDtoFromJSON,
    CreatePosPrefixDtoToJSON,
    GetPosPrefixDto,
    GetPosPrefixDtoFromJSON,
    GetPosPrefixDtoToJSON,
    UpdatePosPrefixDto,
    UpdatePosPrefixDtoFromJSON,
    UpdatePosPrefixDtoToJSON,
} from '../models';

export interface CreatePosPrefixRequest {
    createPosPrefixDto: CreatePosPrefixDto;
}

export interface EditPosPrefixRequest {
    posPrefixId: string;
    updatePosPrefixDto: UpdatePosPrefixDto;
}

export interface GetPosPrefixRequest {
    posPrefixId: string;
}

/**
 * 
 */
export class PosPrefixApi extends runtime.BaseAPI {

    /**
     * Criar prefixo de máquina
     */
    async createPosPrefixRaw(requestParameters: CreatePosPrefixRequest): Promise<runtime.ApiResponse<GetPosPrefixDto>> {
        if (requestParameters.createPosPrefixDto === null || requestParameters.createPosPrefixDto === undefined) {
            throw new runtime.RequiredError('createPosPrefixDto','Required parameter requestParameters.createPosPrefixDto was null or undefined when calling createPosPrefix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos-prefix`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePosPrefixDtoToJSON(requestParameters.createPosPrefixDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPosPrefixDtoFromJSON(jsonValue));
    }

    /**
     * Criar prefixo de máquina
     */
    async createPosPrefix(requestParameters: CreatePosPrefixRequest): Promise<GetPosPrefixDto> {
        const response = await this.createPosPrefixRaw(requestParameters);
        return await response.value();
    }

    /**
     * Editar prefixo de máquina
     */
    async editPosPrefixRaw(requestParameters: EditPosPrefixRequest): Promise<runtime.ApiResponse<GetPosPrefixDto>> {
        if (requestParameters.posPrefixId === null || requestParameters.posPrefixId === undefined) {
            throw new runtime.RequiredError('posPrefixId','Required parameter requestParameters.posPrefixId was null or undefined when calling editPosPrefix.');
        }

        if (requestParameters.updatePosPrefixDto === null || requestParameters.updatePosPrefixDto === undefined) {
            throw new runtime.RequiredError('updatePosPrefixDto','Required parameter requestParameters.updatePosPrefixDto was null or undefined when calling editPosPrefix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos-prefix/{posPrefixId}`.replace(`{${"posPrefixId"}}`, encodeURIComponent(String(requestParameters.posPrefixId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePosPrefixDtoToJSON(requestParameters.updatePosPrefixDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPosPrefixDtoFromJSON(jsonValue));
    }

    /**
     * Editar prefixo de máquina
     */
    async editPosPrefix(requestParameters: EditPosPrefixRequest): Promise<GetPosPrefixDto> {
        const response = await this.editPosPrefixRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter prefixo pelo ID
     */
    async getPosPrefixRaw(requestParameters: GetPosPrefixRequest): Promise<runtime.ApiResponse<GetPosPrefixDto>> {
        if (requestParameters.posPrefixId === null || requestParameters.posPrefixId === undefined) {
            throw new runtime.RequiredError('posPrefixId','Required parameter requestParameters.posPrefixId was null or undefined when calling getPosPrefix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos-prefix/{posPrefixId}`.replace(`{${"posPrefixId"}}`, encodeURIComponent(String(requestParameters.posPrefixId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPosPrefixDtoFromJSON(jsonValue));
    }

    /**
     * Obter prefixo pelo ID
     */
    async getPosPrefix(requestParameters: GetPosPrefixRequest): Promise<GetPosPrefixDto> {
        const response = await this.getPosPrefixRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar prefixos de máquinas
     */
    async listPosPrefixesRaw(): Promise<runtime.ApiResponse<Array<GetPosPrefixDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos-prefix/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPosPrefixDtoFromJSON));
    }

    /**
     * Listar prefixos de máquinas
     */
    async listPosPrefixes(): Promise<Array<GetPosPrefixDto>> {
        const response = await this.listPosPrefixesRaw();
        return await response.value();
    }

}
