/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPaymentLinkSaleDto
 */
export interface GetPaymentLinkSaleDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDto
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentLinkSaleDto
     */
    original_amount: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentLinkSaleDto
     */
    amount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaymentLinkSaleDto
     */
    absorb_costs?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDto
     */
    type: GetPaymentLinkSaleDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDto
     */
    form_payment: GetPaymentLinkSaleDtoFormPaymentEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDto
     */
    formatted_form_payment: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSaleDto
     */
    status: GetPaymentLinkSaleDtoStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum GetPaymentLinkSaleDtoTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}/**
* @export
* @enum {string}
*/
export enum GetPaymentLinkSaleDtoFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Pending = 'pending',
    PixPos = 'pix_pos'
}/**
* @export
* @enum {string}
*/
export enum GetPaymentLinkSaleDtoStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}

export function GetPaymentLinkSaleDtoFromJSON(json: any): GetPaymentLinkSaleDto {
    return GetPaymentLinkSaleDtoFromJSONTyped(json, false);
}

export function GetPaymentLinkSaleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentLinkSaleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'original_amount': json['original_amount'],
        'amount': json['amount'],
        'absorb_costs': !exists(json, 'absorb_costs') ? undefined : json['absorb_costs'],
        'type': json['type'],
        'form_payment': json['form_payment'],
        'formatted_form_payment': json['formatted_form_payment'],
        'status': json['status'],
    };
}

export function GetPaymentLinkSaleDtoToJSON(value?: GetPaymentLinkSaleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'original_amount': value.original_amount,
        'amount': value.amount,
        'absorb_costs': value.absorb_costs,
        'type': value.type,
        'form_payment': value.form_payment,
        'formatted_form_payment': value.formatted_form_payment,
        'status': value.status,
    };
}


