import { Flex, FormControl, FormLabel, Input, Stack, Switch, useBreakpointValue } from '@chakra-ui/react';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import Checkout from '@parcelaexpress/checkout-react-component';
import React from 'react';
import { useToasts } from 'react-toast-notifications';

import * as ParcelaCheckout from '@parcelaexpress/checkout-component-3ds';
import { CheckoutConfiguration } from '@parcelaexpress/checkout-component-3ds/build/types/types';
import { Formik } from 'formik';

import { CreatePaymentWithSplitDto, CreatePaymentWithSplitRequest, CreateSplitRulesDto, PaymentsApi } from '../../clients';
import FormCurrencyInput from '../../components/Form/FormCurrencyInput';
import FormInput from '../../components/Form/FormInput';
import Select from '../../components/Select';
import Title from '../../components/Title';
import BackofficeAlertContainer from '../../containers/BackofficeAlertContainer';
import Loader from '../../containers/Loader';
import Paper from '../../containers/Paper';
import SplitRulesWrapper, { SplitSellerValue } from '../../containers/SplitRulesWrapper';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { getApiDefaultConfig } from '../../services/api.service';
import { roundMoneyToSend } from '../../services/masks.service';
import { validSplitRulesAmount } from '../../services/validations.service';
import yup from '../../services/yup.service';
import AddressForm from './components/AddressForm';
import ConfirmationModal from './components/ConfirmationModal';

const PaymentCheckoutTest: React.FC = () => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const apiConfig = getApiDefaultConfig();
	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [confirmPayment, setConfirmPayment] = React.useState<boolean>(false);
	const [splits, setSplits] = React.useState<SplitSellerValue[]>([]);
	const [hasSplitRules, setHasSplitRules] = React.useState<boolean>(false);
	const [cardCheckout, setCardCheckout] = React.useState({
		holder_name: '',
		number: '',
		expiration_month: '',
		expiration_year: '',
		security_code: '',
	});

	const paymentCheckoutInitialValues = {
		amount_cents: '',
		description: '',
		extract_identification: '',
		service_id: '',
		form_payment: 'credit',
		payer: {
			document: '',
			email: '',
			phone: '',
			address: {
				zipcode: '',
				street: '',
				district: '',
				city: '',
				state: '',
				complement: '',
			},
		},
		installments: 1,
		protocol: '',
		confirmation_required: 'false',
		active_3ds: 'false',
	};

	const schema = yup.object().shape({
		amount_cents: yup.string().required('Campo obrigatório'),
		description: yup.string().required('Campo obrigatório'),
		extract_identification: yup.string(),
		service_id: yup.string(),
		form_payment: yup.string(),
		payer: yup.object().shape({
			document: yup.string(),
			email: yup.string(),
			phone: yup.string(),
			address: yup.object().shape({
				zipcode: yup.string(),
				street: yup.string(),
				district: yup.string(),
				city: yup.string(),
				state: yup.string(),
				complement: yup.string(),
			}),
		}),
		installments: yup.number(),
		protocol: yup.string(),
		confirmation_required: yup.string(),
		active_3ds: yup.string(),
	});

	const checkoutSubmitHandle = (state) => {
		const { data } = state;
		const { paymentMethod } = data;

		setCardCheckout({
			holder_name: paymentMethod.holderName,
			number: paymentMethod.encryptedCardNumber,
			expiration_month: paymentMethod.encryptedExpiryMonth,
			expiration_year: paymentMethod.encryptedExpiryYear,
			security_code: paymentMethod.encryptedSecurityCode,
		});

		setConfirmPayment(!confirmPayment);
	};

	const submitForm = async (values, { resetForm }) => {
		if (!currentSeller) {
			return;
		}
		if (hasSplitRules && splits.length) {
			const isSplitsValid = validSplitRulesAmount(values.amount_cents, splits);
			if (!isSplitsValid) {
				addToast('A soma das divisões não podem ser maiores que o valor da venda', {
					appearance: 'error',
					autoDismiss: true,
				});
				return;
			}
		}
		const ip = await fetch('https://geolocation-db.com/json/7bad3e80-c704-11eb-a4bc-19a5c6a04c5d')
			.then((response) => response.json())
			.then((data) => data);

		const successReturnUrl = 'http://success-return-url.com.br';
		const errorReturnUrl = 'http://error-return-url.com.br';
		const customerIp = ip.IPv4;

		const createPaymentDto: CreatePaymentWithSplitDto = {
			amount_cents: roundMoneyToSend(values.amount_cents),
			card_attributes: {
				holder_name: cardCheckout.holder_name,
				number: cardCheckout.number,
				expiration_month: cardCheckout.expiration_month,
				expiration_year: cardCheckout.expiration_year,
				security_code: cardCheckout.security_code,
			},
			customer: {
				first_name: cardCheckout.holder_name.split(' ')[0],
				last_name: cardCheckout.holder_name.split(' ').pop() || 'Teste',
				document: values.payer.document?.replace('.', '').replace('.', '').replace('-', ''),
				ip: customerIp,
				email: values.payer.email,
				billing_address: {
					postal_code: values.payer.zipcode,
					street: values.payer.street,
					city: values.payer.city,
					state: values.payer.state,
					house_number_or_name: values.payer.number,
					country: 'BR',
				},
			},
			description: values.description,
			installment_plan: {
				number_installments: values.form_payment === 'debit' ? 1 : Number(values.installments),
			},
			active_3ds: values.active_3ds === 'true',
			confirmation_required: values.confirmation_required === 'true',
			error_return_url: errorReturnUrl,
			success_return_url: successReturnUrl,
			form_payment: values.form_payment,
			has_split_rules: !!(hasSplitRules && splits.length),
			split_rules: (splits as unknown) as Array<CreateSplitRulesDto>,
			protocol: values.protocol,
		};

		if (hasSplitRules && splits.length) {
			const parsedSplits: CreateSplitRulesDto[] = splits.map((split) => ({ seller_id: split.seller.id, amount: split.amount }));
			createPaymentDto.has_split_rules = true;
			createPaymentDto.split_rules = parsedSplits;
		}

		if (!values.payer.zipcode || values.payer.zipcode === '') {
			delete createPaymentDto.customer.billing_address;
		}

		if (values.extract_identification && values.extract_identification !== '') {
			createPaymentDto.extract_identification = values.extract_identification;
		}

		if (values.service_id && values.service_id !== '') {
			createPaymentDto.service_id = values.service_id;
		}

		const paymentsApi = new PaymentsApi(apiConfig);
		const createPaymentWithSplitRequest: CreatePaymentWithSplitRequest = {
			createPaymentWithSplitDto: createPaymentDto,
			sellerId: currentSeller?.id,
		};

		setIsLoading(!isLoading);
		try {
			const response = await paymentsApi.createPaymentWithSplit(createPaymentWithSplitRequest);

			const { action }: any = response;
			if (action) {
				const config: CheckoutConfiguration = {
					clientKey: `${process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_CLIENT_KEY}`,
					environment: `${process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_ENVIRONMENT}`,
					action,
				};

				const checkoutInstance = new ParcelaCheckout.Checkout(config);
				checkoutInstance.mount('checkout-container');
			}
			addToast('Venda realizada com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
			resetForm();
		} catch (error) {
			addToast('Erro ao realizar venda!', {
				appearance: 'error',
				autoDismiss: true,
			});
			console.log(error);
		} finally {
			setCardCheckout({
				holder_name: '',
				number: '',
				expiration_month: '',
				expiration_year: '',
				security_code: '',
			});
			setConfirmPayment(false);
			setIsLoading(false);
		}
	};

	const onChangeHandle = (values) => {
		console.log(values);
	};

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex w='100%' flexDirection='column'>
			<Formik enableReinitialize initialValues={paymentCheckoutInitialValues} validationSchema={schema} onSubmit={submitForm}>
				{({ handleSubmit, setFieldValue, resetForm, values, errors, isValid }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Loader isOpen={isLoading} />

							<Flex mb='4'>
								<Title id='checkout-test-title-text' fontSize={isMobile ? 'lg' : '2xl'}>Checkout de teste</Title>
							</Flex>

							<Flex flexDirection='column'>
								<Flex flexDir='column' p={10} bgColor='white' rounded='md' boxShadow='sm' mt='2' w='100%'>
									<Stack spacing={3} color='primary'>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
											<FormControl isRequired>
												<FormLabel id='payment-checkout-amount-cents-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Valor Total do Serviço
												</FormLabel>
												<FormCurrencyInput
													id='payment-checkout-amount-cents-form-currency-input'
													name='amount_cents'
													isDisabled={isLoading}
													borderColor='darkGrey'
												/>
												<FormErrorMessage id='payment-checout-amount-cents-form-error-text' name='amount_cents' />
											</FormControl>
											<FormControl isRequired>
												<FormLabel id='payment-description-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													N° do Pedido
												</FormLabel>
												<FormInput id='payment-description-form-input' name='description' isDisabled={isLoading} />
												<FormErrorMessage id='payment-description-form-error-text' name='description' />
											</FormControl>
										</Stack>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
											<FormControl color='darkGrey' id='form_payment'>
												<FormLabel id='payment-form-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Forma de Pagamento (form_payment)
												</FormLabel>
												<Select id='payment-form-select' onChange={(e) => setFieldValue('form_payment', e.target.value)}>
													<option value='credit'>Crédito</option>
													<option value='debit'>Débito</option>
												</Select>
											</FormControl>
											<FormControl color='darkGrey' id='installments'>
												<FormLabel id='payment-installments-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Parcelas
												</FormLabel>
												<Select id='payments-installments-select' onChange={(e) => setFieldValue('installments', e.target.value)}>
													<option value={1}>1</option>
													<option value={2}>2</option>
													<option value={3}>3</option>
													<option value={4}>4</option>
													<option value={5}>5</option>
													<option value={6}>6</option>
													<option value={7}>7</option>
													<option value={8}>8</option>
													<option value={9}>9</option>
													<option value={10}>10</option>
													<option value={11}>11</option>
													<option value={12}>12</option>
												</Select>
											</FormControl>
										</Stack>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
											<FormControl>
												<FormLabel id='payment-service-id-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Id Externo (service_id)
												</FormLabel>
												<FormInput id='payment-service-id-form-input' name='service_id' isDisabled={isLoading} />
												<FormErrorMessage id='payment-service-id-form-error-text' name='service_id' />
											</FormControl>
											<FormControl>
												<FormLabel id='payment-extract-identification-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Identificação no extrato (extract_identification)
												</FormLabel>
												<FormInput id='payment-extract-identification-form-input' name='extract_identification' isDisabled={isLoading} />
												<FormErrorMessage id='payment-extract-identification-form-error-text' name='extract_identification' />
											</FormControl>
										</Stack>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '2'}>
											<FormControl>
												<FormLabel id='payment-protocol-description-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Description (protocol)
												</FormLabel>
												<FormInput id='payment-protocol-form-input' name='protocol' isDisabled={isLoading} />
												<FormErrorMessage id='payment-protocol-form-error-text' name='protocol' />
											</FormControl>
											<FormControl color='darkGrey' id='payer.document'>
												<FormLabel id='payment-document-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													CPF / CNPJ
												</FormLabel>
												<FormInput id='payer-document-form-input' name='payer.document' isDisabled={isLoading} />
												<FormErrorMessage id='payer-document-form-error-text' name='payer.document' />
											</FormControl>
										</Stack>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '2'}>
											<FormControl color='darkGrey' id='payer.email'>
												<FormLabel id='payment-email-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													E-mail
												</FormLabel>
												<FormInput id='payer-email-form-input' name='payer.email' as={Input} />
												<FormErrorMessage id='payer-email-form-error-text' name='payer.email' />
											</FormControl>
										</Stack>
										{(values.form_payment === 'debit' || values.active_3ds === 'true') && <AddressForm setFieldValue={setFieldValue} />}
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '2'}>
											<FormControl color='darkGrey' id='confirmation_required'>
												<FormLabel id='payment-confirmation-required-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Confirmação Posterior (confirmation_required)
												</FormLabel>
												<Select
													id='payments-confirmation-required-select'
													onChange={(e) => setFieldValue('confirmation_required', e.target.value)}
												>
													<option value='false'>Desabilitado</option>
													<option value='true'>Habilitado</option>
												</Select>
											</FormControl>
											<FormControl color='darkGrey' id='active_3ds'>
												<FormLabel id='payments-active-3ds-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Ativar 3ds (active_3ds)
												</FormLabel>
												<Select id='payments-active-3ds-select' onChange={(e) => setFieldValue('active_3ds', e.target.value)}>
													<option value='false'>Desabilitado</option>
													<option value='true'>Habilitado</option>
												</Select>
											</FormControl>
										</Stack>
										<FormControl display='flex' alignItems='center'>
											<FormLabel id='payer-data-label' htmlFor='email-alerts' mb='0' color='primary' fontWeight='bold'>
												<FormLabel id='internal-payer-data-label' fontSize='md' fontWeight='medium'>
													Dividir valor da venda?
												</FormLabel>
											</FormLabel>
											<Switch
												id='payer-data'
												isChecked={hasSplitRules}
												onChange={() => {
													setHasSplitRules(!hasSplitRules);
													setFieldValue('hasSplitRules', !hasSplitRules);
												}}
												name='hasSplitRules'
											/>
										</FormControl>
										{hasSplitRules && (
											<FormControl flexDirection='column' display='flex' alignItems='center'>
												<FormLabel id='split-rules-label' color='darkGrey' fontSize='md' fontWeight='medium'>
													Regras de Divisão (split_rules)
												</FormLabel>
												<SplitRulesWrapper splits={splits} setSplits={setSplits} />
											</FormControl>
										)}
									</Stack>
									<ConfirmationModal confirmPayment={confirmPayment} isLoading={isLoading} handleSubmit={handleSubmit} />
								</Flex>
							</Flex>
						</form>
					);
				}}
			</Formik>
			<Paper id='checkout-paper-text'>
				<Checkout
					environment={process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_ENVIRONMENT}
					clientKey={process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_CLIENT_KEY}
					onSubmit={checkoutSubmitHandle}
					onChange={onChangeHandle}
					theme='outline'
				/>
			</Paper>
		</Flex>
	);
};

export default PaymentCheckoutTest;
