import VMasker from 'vanilla-masker';

import { format as formatTz, toDate, utcToZonedTime } from "date-fns-tz";

export enum DateFormatEnum {
	DayMonthYear = 'day_month_year',
	DayMonthYearTime = 'day_month_year_time',
	FullDate = 'full_date'
};

export const CEP_FORMAT = /^\d{5}[-|\s]?\d{3}$/;
export const CPF_FORMAT = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
export const CNPJ_FORMAT = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
export const PHONE_FORMAT = /^\(\d{2}\)[\s]\d{4,5}-\d{4}$/;

export const maskNumber = (value) => value.replace(/\D/g, '');

export const formatAmount = (amount) => VMasker.toMoney(amount);

export const maskDate = (date) => VMasker.toPattern(date, 'SS/SS/SSSS');

export const maskFullDate = (date) => {
	const dateParts = new Date(date)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

	return `${maskDate(dateParts[0].split('-').reverse().join('-'))} às ${dateParts[1] ? dateParts[1]?.substr(0, 5) : '-:-'}`;
};

export const formatToBrazilianTimezone = (
	dateStr: string, 
	expectedFormat: DateFormatEnum = DateFormatEnum.DayMonthYear
): string => {
	const utcDate = toDate(dateStr, { timeZone: 'UTC' });
	const zonedDate = utcToZonedTime(utcDate, 'America/Sao_Paulo');

	if (expectedFormat === DateFormatEnum.DayMonthYear) {
		return formatTz(zonedDate, 'dd/MM/yyyy', { timeZone: 'America/Sao_Paulo' });
	}

	if (expectedFormat === DateFormatEnum.DayMonthYearTime) {
		return formatTz(zonedDate, 'dd/MM/yyyy HH:mm:ss', { timeZone: 'America/Sao_Paulo' });
	}
	const onlyDate = formatTz(zonedDate, 'dd/MM/yyyy', { timeZone: 'America/Sao_Paulo' });
	const onlyTime = formatTz(zonedDate, 'HH:mm:ss', { timeZone: 'America/Sao_Paulo' });

	return `${onlyDate} à ${onlyTime}`;
}

export const maskZipcode = (zipcode) => VMasker.toPattern(zipcode, 'SS.SSS-SSS');

export const maskMoney = (value, float = true) => {
	if (!value) {
		return '0,00';
	}
	const toParse = !float ? value.replace(/\./g, '').replace(/,/, '.') : value;

	return parseFloat(toParse)
		.toFixed(2)
		.toString()
		.replace(/\./, ',')
		.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const roundMoneyToSend = (value, float = true) => {
	const toParse = !float ? value.replace(/\./g, '').replace(/,/, '.') : value;

	return Number(parseFloat(toParse).toFixed(2)) * 100;
};

export const unmaskMoney = (value) => parseFloat(value.replace(/\./g, '').replace(/,/, '.'));

export const maskCpf = (value) =>
	value
		.replace(/\D/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1-$2');

export const maskCnpj = (value) =>
	value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{4})/, '$1/$2')
		.replace(/(\d{4})(\d{1,2})/, '$1-$2');

export const maskCpfOrCnpj = (value) => {
	if (!value) {
		return value;
	}

	if (unmaskCpfOrCnpj(value).length <= 11) {
		return maskCpf(value);
	} else {
		return maskCnpj(value);
	}
};

export const unmaskCpfOrCnpj = (value) => value.replace(/\D/g, '');

export const maskPhoneNumber = (value) =>
	value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{4,5})(\d{4})/, '$1-$2');

export const unmaskPhoneNumber = (value) => value.replace(/\D/g, '');

export const unmaskZipcode = (value) => value.replace(/\D/g, '');

export const maskPorcent = (value: number) => {
	return `${value.toString().replace('.', ',')} %`
}

export const maskCns = (value: string) => {
	return value
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1-$2');
}