/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentCalculationRules
 */
export interface PaymentCalculationRules {
    /**
     * 
     * @type {string}
     * @memberof PaymentCalculationRules
     */
    payment_method: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentCalculationRules
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentCalculationRules
     */
    min?: number;
}

export function PaymentCalculationRulesFromJSON(json: any): PaymentCalculationRules {
    return PaymentCalculationRulesFromJSONTyped(json, false);
}

export function PaymentCalculationRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentCalculationRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payment_method': json['payment_method'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
    };
}

export function PaymentCalculationRulesToJSON(value?: PaymentCalculationRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_method': value.payment_method,
        'max': value.max,
        'min': value.min,
    };
}


