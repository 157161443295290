/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAcquirerDto,
    GetAcquirerDtoFromJSON,
    GetAcquirerDtoFromJSONTyped,
    GetAcquirerDtoToJSON,
    GetPaymentTypeDto,
    GetPaymentTypeDtoFromJSON,
    GetPaymentTypeDtoFromJSONTyped,
    GetPaymentTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMerchantSalePlanDto
 */
export interface GetMerchantSalePlanDto {
    /**
     * 
     * @type {string}
     * @memberof GetMerchantSalePlanDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantSalePlanDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantSalePlanDto
     */
    description: string;
    /**
     * 
     * @type {Array<GetPaymentTypeDto>}
     * @memberof GetMerchantSalePlanDto
     */
    payment_types: Array<GetPaymentTypeDto>;
    /**
     * 
     * @type {string}
     * @memberof GetMerchantSalePlanDto
     */
    type: GetMerchantSalePlanDtoTypeEnum;
    /**
     * 
     * @type {GetAcquirerDto}
     * @memberof GetMerchantSalePlanDto
     */
    acquirer: GetAcquirerDto;
}

/**
* @export
* @enum {string}
*/
export enum GetMerchantSalePlanDtoTypeEnum {
    Markup = 'markup',
    Spread = 'spread',
    Split = 'split'
}

export function GetMerchantSalePlanDtoFromJSON(json: any): GetMerchantSalePlanDto {
    return GetMerchantSalePlanDtoFromJSONTyped(json, false);
}

export function GetMerchantSalePlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMerchantSalePlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'payment_types': ((json['payment_types'] as Array<any>).map(GetPaymentTypeDtoFromJSON)),
        'type': json['type'],
        'acquirer': GetAcquirerDtoFromJSON(json['acquirer']),
    };
}

export function GetMerchantSalePlanDtoToJSON(value?: GetMerchantSalePlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'payment_types': ((value.payment_types as Array<any>).map(GetPaymentTypeDtoToJSON)),
        'type': value.type,
        'acquirer': GetAcquirerDtoToJSON(value.acquirer),
    };
}


