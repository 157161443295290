import React from 'react';

import { SimpleGrid, FormControl, FormLabel, Input } from '@chakra-ui/react';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import { Stack } from '@chakra-ui/layout';

type Props = {
	errors: any;
};

const CostPlanForm: React.FC<Props> = (props) => (
	<SimpleGrid color='black' spacing='2.5rem'>
		<Stack direction={['column', 'column', 'row']} spacing={4}>
			<FormControl id='name' isRequired>
				<FormLabel id='name-label' fontSize='sm' fontWeight='bold'>
					Nome
				</FormLabel>
				<FormInput id='cost-plan-name-form-input' as={Input} name='name' />
				<FormErrorMessage id='cost-plan-name-form-error-text' name='name' />
			</FormControl>

			<FormControl id='description' isRequired>
				<FormLabel id='description-label' fontSize='sm' fontWeight='bold'>
					Descrição
				</FormLabel>
				<FormInput id='cost-plan-description-form-input' as={Input} name='description' />
				<FormErrorMessage id='cost-plan-description-form-error-text' name='description' />
			</FormControl>
		</Stack>
	</SimpleGrid>
);

export default CostPlanForm;
