import React from 'react';

import { Flex, FormControl, Input, Center } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';

import * as yup from 'yup';

import NewModal from 'containers/NewModal';

import Button from 'components/Button';
import FormInput from 'components/Form/FormInput';
import FormPasswordInput from '../../../components/Form/FormPasswordInput';
import FormLabel from 'components/Form/FormLabel';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormSelect from 'components/Form/FormSelect';

import { getApiAuthConfig } from 'services/api.service';

import {
	MerchantsApi,
	RolesApi,
	ListRolesByTypeRequest,
	GetMerchantDto,
	ListByTypeDtoTypeEnum,
	CreateMerchantUserRequest,
	UpdateMerchantUserRequest,
} from 'clients';

import { user_status } from 'config/constants';
import { userStatus } from 'services/enums.service';
import Text from 'components/Text';

const createUserSchema = yup.object().shape({
	merchant: yup.string().required('Campo obrigatório'),
	name: yup.string().required('Campo obrigatório'),
	email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
	role: yup.string().required('Campo obrigatório'),
	password: yup.string().required('Campo obrigatório'),
});

const editUserSchema = yup.object().shape({
	name: yup.string(),
	role: yup.string(),
	status: yup.string(),
});

type UserModalProps = {
	openUserModal: boolean;
	merchant?: GetMerchantDto;
	showMerchantInput: boolean;
	handleOpenUserModal(id?: string): void;
	isLoading: boolean;
	setIsLoading(load: boolean): void;
	resetTable: boolean;
	setResetTable(load: boolean): void;
	userID?: string;
	selectedUser?: any;
};

const UserModal: React.FC<UserModalProps> = ({
	openUserModal,
	merchant,
	showMerchantInput,
	handleOpenUserModal,
	isLoading,
	setIsLoading,
	resetTable,
	setResetTable,
	userID,
	selectedUser,
}) => {
	const { addToast } = useToasts();
	const [roles, setRoles] = React.useState<any>();
	const [merchants, setMerchants] = React.useState<any>();

	const apiConfig = getApiAuthConfig();
	const merchantsApi = new MerchantsApi(apiConfig);
	const rolesApi = new RolesApi(apiConfig);

	const createUserInitialValues = {
		merchant: '',
		name: '',
		email: '',
		role: '',
		password: '',
	};

	const editUserInitialValues = {
		merchant: selectedUser?.merchant?.description,
		name: selectedUser?.name,
		email: selectedUser?.email,
		role: selectedUser?.role?.id,
		status: selectedUser?.status,
	};

	async function fetchRoles() {
		setIsLoading(true);

		try {
			const requestParams: ListRolesByTypeRequest = {
				listByTypeDto: {
					type: ListByTypeDtoTypeEnum.Merchant,
				},
			};

			const response = await rolesApi.listRolesByType(requestParams);

			setRoles(response);
		} catch (error) {
			addToast('Erro ao buscar perfis.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	async function fetchMerchants() {
		setIsLoading(true);

		try {
			const response = await merchantsApi.listMerchants();

			setMerchants(response);
		} catch (error) {
			addToast('Erro ao buscar Merchants.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const createUser = async (values, { resetForm }) => {
		setIsLoading(true);

		try {
			const requestParams: CreateMerchantUserRequest = {
				merchantId: values.merchant,
				createMerchantUserDto: {
					name: values.name,
					password: values.password,
					email: values.email,
					role_id: values.role,
				},
			};

			await merchantsApi.createMerchantUser(requestParams);

			resetForm({});
			setResetTable(!resetTable);
			handleOpenUserModal();
		} catch (error) {
			addToast('Erro ao criar usuário', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const editUser = async (values, { resetForm }) => {
		if (!userID) return;

		setIsLoading(true);

		try {
			const requestParams: UpdateMerchantUserRequest = {
				merchantId: selectedUser.merchant.id,
				userId: userID,
				updateMerchantUserDto: {
					name: values.name || undefined,
					role_id: values.role || undefined,
					status: values.status || undefined,
				},
			};

			await merchantsApi.updateMerchantUser(requestParams);

			resetForm({});
			setResetTable(!resetTable);
			handleOpenUserModal();
		} catch (error) {
			addToast('Erro ao editar usuário', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchRoles();
		fetchMerchants();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<NewModal
			isOpen={openUserModal}
			size={`lg`}
			onClose={() => {
				handleOpenUserModal();
			}}
		>
			<Flex px={12} py={8} flexDirection='column'>
				{!userID ? (
					<Formik enableReinitialize initialValues={createUserInitialValues} validationSchema={createUserSchema} onSubmit={createUser}>
						{({ handleSubmit, isValid }) => {
							return (
								<form onSubmit={handleSubmit} style={{ width: '100%' }}>
									<FormControl id='user-id-merchant-form-controll' my={2} isRequired>
										<FormLabel id='user-id-merchant-label' fontSize={`md`} fontWeight='medium'>
											Merchant
										</FormLabel>
										{showMerchantInput ? (
											<FormSelect id='user-modal-merchant-form-select' fontSize={`md`} size={`md`} name='merchant'>
												<option value='' />
												{merchants?.map((merchant) => (
													<option value={merchant.id} key={merchant.id}>
														{merchant.description}
													</option>
												))}
											</FormSelect>
										) : (
											<>
												<FormLabel id='input-merchant-label' fontSize='md' fontWeight='medium'>
													Merchant
												</FormLabel>
												<Input
													isDisabled
													size={`md`}
													_hover={{ outine: 'none' }}
													_focus={{ outline: 'none' }}
													value={merchant?.description}
													fontSize={`md`}
													fontWeight={`medium`}
													borderColor='darkGrey'
												/>
											</>
										)}
										<FormErrorMessage id='user-modal-merchant-form-error-text' name='merchant' />
									</FormControl>
									<FormControl id='user-id-name' my={2} isRequired>
										<FormLabel id='user-id-name-label' fontSize='md' fontWeight='medium'>
											Nome
										</FormLabel>
										<FormInput id='user-id-name-form-input' name='name' fontSize={`md`} fontWeight={`medium`} />
										<FormErrorMessage id='user-id-name-form-error-text' name='name' />
									</FormControl>
									<FormControl id='user-id-email' my={2} isRequired>
										<FormLabel id='user-id-email-label' fontSize={`md`} fontWeight='medium'>
											E-mail
										</FormLabel>
										<FormInput id='user-id-email-form-input' name='email' fontSize={`md`} fontWeight={`medium`} />
										<FormErrorMessage id='user-id-email-form-error-text' name='email' />
									</FormControl>
									<FormControl id='user-id-role' my={2} isRequired>
										<FormLabel id='user-id-profile-label' fontSize={`md`} fontWeight='medium'>
											Perfil
										</FormLabel>
										<FormSelect id='user-modal-role-form-select' fontSize={`md`} size={`md`} name='role'>
											<option value='' />
											{roles?.map((role) => (
												<option value={role.id} key={role.id}>
													{role.name}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage id='user-modal-role-form-error-text' name='role' />
									</FormControl>
									<FormControl id='user-id-password' my={2} isRequired>
										<FormLabel id='user-id-password-label' fontSize={`md`} fontWeight='medium'>
											Senha Inicial
										</FormLabel>
										<FormPasswordInput
											id='user-password-form-input'
											name='password'
											fontSize={`md`}
											fontWeight={`medium`}
											placeholder='Digite sua senha aqui'
											autoComplete='nope'
										/>
										<FormErrorMessage id='user-password-form-error-text' name='password' />
									</FormControl>

									<Text id='user-change-passord-text' my={2} align='center' fontSize='md' fontWeight='medium'>
										Sugere-se que o usuário troque a senha após o primeiro acesso
									</Text>
									<Center>
										<Button
											id='submit-user-creation-button'
											mt={4}
											backgroundColor='primary'
											fontSize={`md`}
											fontWeight={`semibold`}
											disabled={!isValid}
											type='submit'
											isLoading={isLoading}
										>
											Criar Usuário
										</Button>
									</Center>
								</form>
							);
						}}
					</Formik>
				) : (
					<Formik enableReinitialize initialValues={editUserInitialValues} validationSchema={editUserSchema} onSubmit={editUser}>
						{({ handleSubmit, isValid, values }) => {
							return (
								<form onSubmit={handleSubmit} style={{ width: '100%' }}>
									<FormControl id='no-user-id-merchant' my={2}>
										<FormLabel id='no-user-id-merchant-label' fontSize='md' fontWeight='medium'>
											Merchant
										</FormLabel>
										<Input
											id='values-merchant-input'
											isDisabled
											size={`md`}
											_hover={{ outine: 'none' }}
											_focus={{ outline: 'none' }}
											value={values?.merchant}
											fontSize={`md`}
											fontWeight={`medium`}
											borderColor='darkGrey'
										/>
									</FormControl>
									<FormControl id='no-user-id-name' my={2}>
										<FormLabel id='no-user-id-name-label' fontSize={`md`} fontWeight='medium'>
											Nome
										</FormLabel>
										<FormInput id='no-user-id-name-form-input' fontSize={`md`} fontWeight='medium' name='name' />
										<FormErrorMessage id='no-user-id-name-form-error-text' name='name' />
									</FormControl>
									<FormControl id='no-user-id-email' my={2}>
										<FormLabel id='no-user-id-email-label' fontSize={`md`} fontWeight='medium'>
											E-mail
										</FormLabel>
										<FormInput
											id='no-user-id-email-form-input'
											name='email'
											color={`darkGrey`}
											fontSize={`md`}
											fontWeight={`medium`}
											isDisabled
										/>
									</FormControl>
									<FormControl id='no-user-id-status' my={2}>
										<FormLabel id='no-user-id-status-label' fontSize={`md`} fontWeight='medium'>
											Status
										</FormLabel>
										<FormSelect id='user-modal-status-form-select' fontSize={`md`} name='status'>
											<option value='' />
											{user_status.map((state) => (
												<option value={state.value} key={state.value}>
													{userStatus[state.text]}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage id='user-modal-status-error-text' name='status' />
									</FormControl>
									<FormControl id='no-user-id-role' my={2}>
										<FormLabel id='no-user-id-profile-form-label' fontSize={`md`} fontWeight='medium'>
											Perfil
										</FormLabel>
										<FormSelect id='user-modal-profile-role-form-select' fontSize={`md`} size={`md`} name='role'>
											<option value='' />
											{roles?.map((role) => (
												<option value={role.id} key={role.id}>
													{role.name}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage id='user-modal-role-form-error-text' name='role' />
									</FormControl>

									<Center>
										<Button
											id='submit-user-update-button'
											mt={4}
											backgroundColor='primary'
											fontSize={`md`}
											fontWeight={`semibold`}
											disabled={!isValid}
											type='submit'
											isLoading={isLoading}
										>
											Atualizar Usuário
										</Button>
									</Center>
								</form>
							);
						}}
					</Formik>
				)}
			</Flex>
		</NewModal>
	);
};

export default UserModal;
