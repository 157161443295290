import React from 'react';
import ReactDOM from 'react-dom';

import 'assets/css/index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '@zach.codes/react-calendar/dist/calendar-tailwind.css';

import { Box, Icon, Stack } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { MdError } from 'react-icons/md';

import { BrowserRouter } from 'react-router-dom';
import { Provider as ModalProvider } from 'use-react-modal';
import { ToastProvider } from 'react-toast-notifications';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import ErrorBoundaryPage from './pages/ErrorBoundary';
import App from './pages/Home';

import * as serviceWorker from './serviceWorker';

import { ErrorBoundary } from './hocs/errorBoundary';
import { AuthProvider } from './contexts/AuthProvider';
import { ThemeProvider } from './contexts/ThemeProvider';
import { RefreshTokenProvider } from './contexts/RefreshTokenProvider';
import { InMemorySellersProvider } from './contexts/InMemorySellersProvider';
import Text from './components/Text';

const CustomToast = ({ appearance, children }) => (
	<Box className={appearance === 'error' ? 'toast__error' : 'toast__success'} rounded={`xl`} bg={appearance === 'error' ? '#E85642' : '#07C50E'} py={8} px={15}>
		<Stack isInline spacing={12} alignItems={`center`}>
			<Icon as={appearance === 'error' ? MdError : FaCheck} boxSize={22} color={`white`} />
			<Text id='custom-toast-text' color={`white`} fontSize={`xs`} fontWeight={`bold`}>
				{children}
			</Text>
		</Stack>
	</Box>
);

const pubNub = new PubNub({
	publishKey: `${process.env.REACT_APP_PUBNUB_PUBLISH_KEY}`,
	subscribeKey: `${process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY}`,
	uuid: `${process.env.REACT_APP_PUBNUB_UUID}`
});

ReactDOM.render(
	<ErrorBoundary fallbackRender={ErrorBoundaryPage}>
		<ThemeProvider>
			<PubNubProvider client={pubNub}>
				<InMemorySellersProvider>
					<AuthProvider>
						<RefreshTokenProvider>
							<BrowserRouter>
								<ModalProvider>
									<ToastProvider components={{ Toast: CustomToast }}>
										<App />
									</ToastProvider>
								</ModalProvider>
							</BrowserRouter>
						</RefreshTokenProvider>
					</AuthProvider>
				</InMemorySellersProvider>
			</PubNubProvider>
		</ThemeProvider>
	</ErrorBoundary>,
	document.getElementById('root')
);

serviceWorker.register();
