import React from 'react';

import { Flex, BreadcrumbItem, BreadcrumbLink, SimpleGrid, Center } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';

import { Configuration, GetPaymentTypeDto, SalesPlansApi } from 'clients';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';

import Loader from 'containers/Loader';
import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';
import Text from 'components/Text';

const MyPlan: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const { addToast } = useToasts();
	const { user } = useAuth();
	const { currentSeller } = useCurrentSeller();

	const [salesPlan, setSalesPlan] = React.useState<GetPaymentTypeDto[]>();

	async function fetchSalesPlan() {
		setIsLoading(true);

		try {
			const apiConfig = new Configuration({ basePath: process.env.REACT_APP_BASE_URL, accessToken: `bearer ${user.id_token}` });
			const salesPlanApi = new SalesPlansApi(apiConfig);

			const response = await salesPlanApi.listSalePlanPaymentTypes({ salePlanId: (currentSeller?.sale_plan_id! || currentSeller?.sale_plan?.id!) });

			setSalesPlan(response);
		} catch (error) {
			addToast('Plano de vendas inválido', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		if (!currentSeller) {
			return;
		}
		fetchSalesPlan();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<>
			<Loader isOpen={isLoading} />
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Minha Conta
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' fontSize='md' fontWeight={`extrabold`}>
							Meu Plano
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Flex mb='4'>
				<Title id='my-plan-title-text' fontSize={`2xl`}>MEU PLANO</Title>
			</Flex>

			<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='8' w='100%'>
				<SimpleGrid columns={4} spacing='4' w='100%' mt='2'>
					<Center>
						<Text id='payment-method-text' fontSize={`md`} fontWeight={`bold`}>
							Forma de Pagamento
						</Text>
					</Center>
					<Center>
						<Text id='tax-text' fontSize={`md`} fontWeight={`bold`}>
							Taxa
						</Text>
					</Center>
					<Center>
						<Text id='piso-text' fontSize={`md`} fontWeight={`bold`}>
							Piso
						</Text>
					</Center>
					<Center>
						<Text id='teto-text' fontSize={`md`} fontWeight={`bold`}>
							Teto
						</Text>
					</Center>
					{salesPlan?.map((salePlan) => (
						<>
							<Center>
								<Text id='sale-plan-description-text' fontSize={`md`} >
									{salePlan.description}
								</Text>
							</Center>
							<Center>
								<Text id='payment-method-text' fontSize={`md`} >
									{salePlan.code === 'billet' || salePlan.code === 'pix' || salePlan.code === 'pix_pos' || salePlan.code === 'billet_code'
									? 
									`${salePlan.teto && salePlan.piso ? `${salePlan.fee} %` : `R$ ${salePlan.fee}`}` 
									: 
									`${salePlan.fee} %`}
								</Text>
							</Center>
							<Center>
								<Text id='sale-plan-piso-text' fontSize={`md`} >
									{salePlan?.piso && `R$ ${salePlan.piso}`}
								</Text>
							</Center>
							<Center>
								<Text id='sale-plan-teto-text' fontSize={`md`} >
									{salePlan?.teto && `R$ ${salePlan.teto}`}
								</Text>
							</Center>
						</>
					))}
				</SimpleGrid>
			</Flex>
		</>
	);
};

export default MyPlan;
