/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAcquirerDto,
    GetAcquirerDtoFromJSON,
    GetAcquirerDtoToJSON,
    GetAcquirerSalePlanDto,
    GetAcquirerSalePlanDtoFromJSON,
    GetAcquirerSalePlanDtoToJSON,
} from '../models';

export interface ListAcquirerSalesPlansRequest {
    acquirerId: string;
}

/**
 * 
 */
export class AcquirersApi extends runtime.BaseAPI {

    /**
     * Listar planos de vendas da adquirente
     */
    async listAcquirerSalesPlansRaw(requestParameters: ListAcquirerSalesPlansRequest): Promise<runtime.ApiResponse<Array<GetAcquirerSalePlanDto>>> {
        if (requestParameters.acquirerId === null || requestParameters.acquirerId === undefined) {
            throw new runtime.RequiredError('acquirerId','Required parameter requestParameters.acquirerId was null or undefined when calling listAcquirerSalesPlans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/acquirers/{acquirerId}/sales-plans`.replace(`{${"acquirerId"}}`, encodeURIComponent(String(requestParameters.acquirerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAcquirerSalePlanDtoFromJSON));
    }

    /**
     * Listar planos de vendas da adquirente
     */
    async listAcquirerSalesPlans(requestParameters: ListAcquirerSalesPlansRequest): Promise<Array<GetAcquirerSalePlanDto>> {
        const response = await this.listAcquirerSalesPlansRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar adquirentes
     */
    async listAcquirersRaw(): Promise<runtime.ApiResponse<Array<GetAcquirerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/acquirers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAcquirerDtoFromJSON));
    }

    /**
     * Listar adquirentes
     */
    async listAcquirers(): Promise<Array<GetAcquirerDto>> {
        const response = await this.listAcquirersRaw();
        return await response.value();
    }

}
