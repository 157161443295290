/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateMerchantPermissionDto,
    CreateMerchantPermissionDtoFromJSON,
    CreateMerchantPermissionDtoFromJSONTyped,
    CreateMerchantPermissionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateMerchantTablesPermissionsDto
 */
export interface UpdateMerchantTablesPermissionsDto {
    /**
     * 
     * @type {CreateMerchantPermissionDto}
     * @memberof UpdateMerchantTablesPermissionsDto
     */
    sales_history?: CreateMerchantPermissionDto;
    /**
     * 
     * @type {CreateMerchantPermissionDto}
     * @memberof UpdateMerchantTablesPermissionsDto
     */
    settlement_batch_sale?: CreateMerchantPermissionDto;
}

export function UpdateMerchantTablesPermissionsDtoFromJSON(json: any): UpdateMerchantTablesPermissionsDto {
    return UpdateMerchantTablesPermissionsDtoFromJSONTyped(json, false);
}

export function UpdateMerchantTablesPermissionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMerchantTablesPermissionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sales_history': !exists(json, 'sales_history') ? undefined : CreateMerchantPermissionDtoFromJSON(json['sales_history']),
        'settlement_batch_sale': !exists(json, 'settlement_batch_sale') ? undefined : CreateMerchantPermissionDtoFromJSON(json['settlement_batch_sale']),
    };
}

export function UpdateMerchantTablesPermissionsDtoToJSON(value?: UpdateMerchantTablesPermissionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sales_history': CreateMerchantPermissionDtoToJSON(value.sales_history),
        'settlement_batch_sale': CreateMerchantPermissionDtoToJSON(value.settlement_batch_sale),
    };
}


