/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSellerRiskDto,
    CreateSellerRiskDtoFromJSON,
    CreateSellerRiskDtoToJSON,
    GetSellerRiskDto,
    GetSellerRiskDtoFromJSON,
    GetSellerRiskDtoToJSON,
    UpdateSellerRiskDto,
    UpdateSellerRiskDtoFromJSON,
    UpdateSellerRiskDtoToJSON,
} from '../models';

export interface CreateSellerRiskRequest {
    createSellerRiskDto: CreateSellerRiskDto;
}

export interface EditsellerRiskRequest {
    sellerRiskId: string;
    updateSellerRiskDto: UpdateSellerRiskDto;
}

export interface GetSellerRiskRequest {
    sellerRiskId: string;
}

export interface GetSellerRiskBySellerIdRequest {
    sellerId: string;
}

/**
 * 
 */
export class SellerRisksApi extends runtime.BaseAPI {

    /**
     * Criar um risco de estabelecimento
     */
    async createSellerRiskRaw(requestParameters: CreateSellerRiskRequest): Promise<runtime.ApiResponse<GetSellerRiskDto>> {
        if (requestParameters.createSellerRiskDto === null || requestParameters.createSellerRiskDto === undefined) {
            throw new runtime.RequiredError('createSellerRiskDto','Required parameter requestParameters.createSellerRiskDto was null or undefined when calling createSellerRisk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/seller-risks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerRiskDtoToJSON(requestParameters.createSellerRiskDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerRiskDtoFromJSON(jsonValue));
    }

    /**
     * Criar um risco de estabelecimento
     */
    async createSellerRisk(requestParameters: CreateSellerRiskRequest): Promise<GetSellerRiskDto> {
        const response = await this.createSellerRiskRaw(requestParameters);
        return await response.value();
    }

    /**
     * Editar risco do estabelecimento
     */
    async editsellerRiskRaw(requestParameters: EditsellerRiskRequest): Promise<runtime.ApiResponse<GetSellerRiskDto>> {
        if (requestParameters.sellerRiskId === null || requestParameters.sellerRiskId === undefined) {
            throw new runtime.RequiredError('sellerRiskId','Required parameter requestParameters.sellerRiskId was null or undefined when calling editsellerRisk.');
        }

        if (requestParameters.updateSellerRiskDto === null || requestParameters.updateSellerRiskDto === undefined) {
            throw new runtime.RequiredError('updateSellerRiskDto','Required parameter requestParameters.updateSellerRiskDto was null or undefined when calling editsellerRisk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/seller-risks/{sellerRiskId}`.replace(`{${"sellerRiskId"}}`, encodeURIComponent(String(requestParameters.sellerRiskId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSellerRiskDtoToJSON(requestParameters.updateSellerRiskDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerRiskDtoFromJSON(jsonValue));
    }

    /**
     * Editar risco do estabelecimento
     */
    async editsellerRisk(requestParameters: EditsellerRiskRequest): Promise<GetSellerRiskDto> {
        const response = await this.editsellerRiskRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter risco pelo ID
     */
    async getSellerRiskRaw(requestParameters: GetSellerRiskRequest): Promise<runtime.ApiResponse<GetSellerRiskDto>> {
        if (requestParameters.sellerRiskId === null || requestParameters.sellerRiskId === undefined) {
            throw new runtime.RequiredError('sellerRiskId','Required parameter requestParameters.sellerRiskId was null or undefined when calling getSellerRisk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/seller-risks/{sellerRiskId}`.replace(`{${"sellerRiskId"}}`, encodeURIComponent(String(requestParameters.sellerRiskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerRiskDtoFromJSON(jsonValue));
    }

    /**
     * Obter risco pelo ID
     */
    async getSellerRisk(requestParameters: GetSellerRiskRequest): Promise<GetSellerRiskDto> {
        const response = await this.getSellerRiskRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obter risco pelo sellerId
     */
    async getSellerRiskBySellerIdRaw(requestParameters: GetSellerRiskBySellerIdRequest): Promise<runtime.ApiResponse<GetSellerRiskDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSellerRiskBySellerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/seller-risks/seller/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerRiskDtoFromJSON(jsonValue));
    }

    /**
     * Obter risco pelo sellerId
     */
    async getSellerRiskBySellerId(requestParameters: GetSellerRiskBySellerIdRequest): Promise<GetSellerRiskDto> {
        const response = await this.getSellerRiskBySellerIdRaw(requestParameters);
        return await response.value();
    }

}
