/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetLogoDto,
    GetLogoDtoFromJSON,
    GetLogoDtoFromJSONTyped,
    GetLogoDtoToJSON,
    GetMerchantDto,
    GetMerchantDtoFromJSON,
    GetMerchantDtoFromJSONTyped,
    GetMerchantDtoToJSON,
    GetThemeDto,
    GetThemeDtoFromJSON,
    GetThemeDtoFromJSONTyped,
    GetThemeDtoToJSON,
    GetUserLoginSellerDto,
    GetUserLoginSellerDtoFromJSON,
    GetUserLoginSellerDtoFromJSONTyped,
    GetUserLoginSellerDtoToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserLoginResponseDto
 */
export interface UserLoginResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    email: string;
    /**
     * 
     * @type {GetUserLoginSellerDto}
     * @memberof UserLoginResponseDto
     */
    seller?: GetUserLoginSellerDto;
    /**
     * 
     * @type {GetMerchantDto}
     * @memberof UserLoginResponseDto
     */
    merchant?: GetMerchantDto;
    /**
     * 
     * @type {Role}
     * @memberof UserLoginResponseDto
     */
    role: Role;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    refresh_token: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    id_token: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    type: UserLoginResponseDtoTypeEnum;
    /**
     * 
     * @type {GetThemeDto}
     * @memberof UserLoginResponseDto
     */
    theme: GetThemeDto;
    /**
     * 
     * @type {GetLogoDto}
     * @memberof UserLoginResponseDto
     */
    logo: GetLogoDto;
}

/**
* @export
* @enum {string}
*/
export enum UserLoginResponseDtoTypeEnum {
    Merchant = 'merchant',
    Seller = 'seller',
    Backoffice = 'backoffice'
}

export function UserLoginResponseDtoFromJSON(json: any): UserLoginResponseDto {
    return UserLoginResponseDtoFromJSONTyped(json, false);
}

export function UserLoginResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLoginResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'created_at': json['created_at'],
        'email': json['email'],
        'seller': !exists(json, 'seller') ? undefined : GetUserLoginSellerDtoFromJSON(json['seller']),
        'merchant': !exists(json, 'merchant') ? undefined : GetMerchantDtoFromJSON(json['merchant']),
        'role': RoleFromJSON(json['role']),
        'refresh_token': json['refresh_token'],
        'id_token': json['id_token'],
        'type': json['type'],
        'theme': GetThemeDtoFromJSON(json['theme']),
        'logo': GetLogoDtoFromJSON(json['logo']),
    };
}

export function UserLoginResponseDtoToJSON(value?: UserLoginResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'created_at': value.created_at,
        'email': value.email,
        'seller': GetUserLoginSellerDtoToJSON(value.seller),
        'merchant': GetMerchantDtoToJSON(value.merchant),
        'role': RoleToJSON(value.role),
        'refresh_token': value.refresh_token,
        'id_token': value.id_token,
        'type': value.type,
        'theme': GetThemeDtoToJSON(value.theme),
        'logo': GetLogoDtoToJSON(value.logo),
    };
}


