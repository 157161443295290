import React from 'react';

import { Flex, Text, InputGroup, FormControl, InputRightElement, IconButton, Center, Stack, useBreakpointValue } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';
import BackofficePOSModal from './components/BackofficePOSModal';

import {
	SellersApi,
	PosApi,
	ListPosRequest,
	ListSellerPaginatedPosRequest,
	ListSellerPaginatedPosSortEnum,
	CreateExportPosListDtoExtensionEnum,
	CreateExportPosListDtoSourceEnum,
	PosPrefixApi,
	GetPosPrefixDto,
} from '../../clients';

import Button from '../../components/Button';
import Paper from '../../containers/Paper';
import SampleTable from './components/SampleTable';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { Formik } from 'formik';
import Input from '../../components/Input';
import { SearchIcon } from '@chakra-ui/icons';
import MultiSelect from '../../components/MultiSelect';
import { listPosStatus } from '../../services/enums.service';
import { BiExport } from 'react-icons/bi';
import PosPrefixModal from './components/PosPrefixModal';
import ExportFileSuccessModal from '../../containers/ExportFileSuccessModal';

const BackofficePOS: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const posApi = new PosApi(apiConfig);
	const sellerApi = new SellersApi(apiConfig);
	const posPrefixApi = new PosPrefixApi(apiConfig);

	const { isBackoffice } = useAuth();
	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();

	const [isLoading, setIsLoading] = React.useState(true);
	const [exportIsLoading, setExportIsLoading] = React.useState(false);
	const [resetTable, setResetTable] = React.useState(false);
	const [openPosModal, setOpenUserModal] = React.useState(false);
	const [posID, setPosID] = React.useState('');
	const [selectedPos, setSelectedPos] = React.useState(undefined);
	const [prefixModalOpen, setPrefixModalOpen] = React.useState(false);

	const [posList, setPosList] = React.useState<Array<string>>([]);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [filters, setFilters] = React.useState({
		status: [{ value: 'active', label: 'Ativo' }],
	});
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const [prefixes, setPrefixes] = React.useState<GetPosPrefixDto[]>([]);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);

	const initialValues = {
		search: '',
		status: [{ value: 'active', label: 'Ativo' }],
	};

	const handleFormSubmit = async (values) => {
		setFilters(values);

		await fetchListPosRequest(values, 1);
	};

	async function fetchListPosRequest(params, currentPage?: number) {
		setIsLoading(true);

		const { status, search } = params;

		if (currentSeller) {
			try {
				const requestParams: ListSellerPaginatedPosRequest = {
					sellerId: currentSeller?.id!,
					currentPage: currentPage ? currentPage : pagination.currentPage,
					limit: pagination.limit,
					status: status?.map((v) => v.value),
					sort: ListSellerPaginatedPosSortEnum.Asc,
					search: search,
				};

				const response = await sellerApi.listSellerPaginatedPos(requestParams);

				setPosList(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: ListPosRequest = {
					currentPage: currentPage ? currentPage : pagination.currentPage,
					limit: pagination.limit,
					status: status?.map((v) => v.value),
					search: search,
				};

				const response = await posApi.listPos(requestParams);

				setPosList(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleOpenPosModal = (pos?) => {
		if (pos) {
			setPosID(pos.id);
			setSelectedPos(pos);
			setOpenUserModal(!openPosModal);
			return;
		}

		setPosID('');
		setSelectedPos(undefined);
		setOpenUserModal(!openPosModal);
	};

	const handleOpenPrefixModal = () => {
		setPrefixModalOpen(!prefixModalOpen);
	};

	const handleExportReportFile = async (params) => {
		setExportIsLoading(true);

		try {
			const { status, search } = params;
			let parsedParams;

			if (search && search.length > 0) {
				const status = [{ value: '', label: '' }];

				parsedParams = {
					status: status?.map((v) => v.value),
					search: search,
				};
			}

			await posApi.createPosListExportRequest({
				createExportPosListDto: {
					extension: CreateExportPosListDtoExtensionEnum.Xls,
					status: status?.map((v) => v.value),
					seller_id: currentSeller?.id,
					source: CreateExportPosListDtoSourceEnum.PosBackoffice,
					...parsedParams,
				},
			});

			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportIsLoading(false);
		}
	};

	const fetchPrefixes = async () => {
		setIsLoading(true);

		try {
			const result = await posPrefixApi.listPosPrefixes();
			setPrefixes(result);
		} catch (error) {
			addToast('Ocorreu um erro ao carregar prefixos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchListPosRequest(filters);
		fetchPrefixes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, pagination, resetTable]);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />
			<Flex w='100%' mb={5}>
				<Text color={`gray.700`} fontSize={`lg`} fontWeight={`bold`}>
					Máquinas
				</Text>
			</Flex>

			<ExportFileSuccessModal
				showCloseButton
				isOpen={openExportSuccessModal}
				onOkClick={() => {
					setOpenExportSuccessModal(false);
				}}
				onClose={() => {
					setOpenExportSuccessModal(false);
				}}
			/>

			<Stack justify='space-between' direction={['column', 'column', 'column', 'column', 'row']} id='pos-text'>
				<Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
					{({ handleSubmit, handleChange, setFieldValue, values }) => {
						return (
							<form onSubmit={handleSubmit}>
								<Flex direction={['column', 'column', 'column', 'column', 'row']} gridGap={5}>
									<Flex>
										<FormControl minWidth='100px'>
											<InputGroup width='auto'>
												<Input
													id='input-search-pos-backoffice'
													borderColor='darkGrey'
													name='search'
													placeholder='Busca...'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={() => handleSubmit()}
													py='5'
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															handleSubmit();
														}
													}}
												/>

												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Search database'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</FormControl>
									</Flex>

									<Flex>
										<Center minW={isMobile ? '100%' : '13.2rem'}>
											<FormControl fontSize='12px'>
												<MultiSelect
													id='multiselect-pos-list-status'
													placeholder='Status'
													options={Object.entries(listPosStatus).map(([key, value]) => {
														return { value: key, label: value };
													})}
													value={values.status}
													onChange={(value) => {
														setFieldValue('status', value);
														handleSubmit();
													}}
													width='auto'
												/>
											</FormControl>
										</Center>
									</Flex>

									<Button
										{...(posList.length === 0 && { disabled: true })}
										id='button-export-pos-list'
										bgColor='primary'
										isDisabled={exportIsLoading}
										isLoading={exportIsLoading}
										onClick={() => handleExportReportFile(values)}
										leftIcon={<BiExport size={20} />}
										order={[0, 1]}
									>
										Exportar para Excel
									</Button>
								</Flex>
							</form>
						);
					}}
				</Formik>

				<Flex justify='flex-end' direction={['column', 'column', 'column', 'column', 'row']} gridGap={5}>
					<Button
						id='button-open-create-prefix-modal'
						bgColor='primary'
						onClick={() => {
							handleOpenPrefixModal();
						}}
					>
						Criar Prefixo
					</Button>

					<Button
						id='open-pos-modal-button'
						title={`Você deve selecionar um estabelecimento`}
						isDisabled={!currentSeller}
						bgColor='primary'
						onClick={() => {
							handleOpenPosModal();
						}}
					>
						Criar Solicitação
					</Button>
				</Flex>
			</Stack>

			<Paper id='backoffice-pagination-table-paper-text'>
				<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
					{posList && (
						<SampleTable
							data={posList}
							setPagination={setPagination}
							pagination={pagination}
							totalPages={totalPages}
							handleOpenPosModal={handleOpenPosModal}
						/>
					)}
				</Flex>
			</Paper>
			{
				<BackofficePOSModal
					seller={currentSeller}
					showSellerInput={isBackoffice}
					openPosModal={openPosModal}
					handleOpenPosModal={handleOpenPosModal}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					resetTable={resetTable}
					setResetTable={setResetTable}
					posID={posID}
					selectedPos={selectedPos}
					prefixes={prefixes}
				/>
			}
			{
				<PosPrefixModal
					open={prefixModalOpen}
					handleOpenPosPrefixModal={handleOpenPrefixModal}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					prefixes={prefixes}
				/>
			}
		</Flex>
	);
};

export default BackofficePOS;
