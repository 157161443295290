import React from 'react';

import { HStack } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillClockCircle } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';
import { formatWithoutTimezone } from '../../../services/date.service';
import { maskMoney } from '../../../services/masks.service';
import { protocolStatusEnum, protocolPaymentMethodEnum, protocolPaymentTypeEnum } from '../../../services/enums.service';

import Button from '../../../components/Button';
import Text from '../../../components/Text';

const iconStatusLiteral = {
	paid: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	alert: <AiFillCloseCircle color='tomato' size={16} />,
	overdued: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({ data, setPagination, pagination, totalPages, onRowClick }) => {
	const { addToast } = useToasts();
	const history = useHistory();

	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => (
					<HeaderTitle id='number-title' column={column}>
						N° do Protocolo
					</HeaderTitle>
				),
				accessor: 'number',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} w='200px' color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='created-at-title' column={column}>
						Data de Criação
					</HeaderTitle>
				),
				accessor: 'created_at',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='presenter-name-title' column={column}>
						Nome do Apresentante
					</HeaderTitle>
				),
				accessor: 'presenter_name',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='values-cents-title' column={column}>
						Valor
					</HeaderTitle>
				),
				accessor: 'value_cents',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>{`R$ ${maskMoney(value)}`}</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='status-title' column={column}>
						Status
					</HeaderTitle>
				),
				accessor: 'status',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} align='left'>
						<HStack>
							<Text id={`${row.index}-${column.id}-icon-text`} color={`darkGrey`}>
								{iconStatusLiteral[value]}
							</Text>
							<Text id={`${row.index}-${column.id}-text`} color={`darkGrey`}>
								{protocolStatusEnum[value]}
							</Text>
						</HStack>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='status-date-title' column={column}>
						Data do Status
					</HeaderTitle>
				),
				accessor: 'status_date',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='due-date-title' column={column}>
						Data de Vencimento
					</HeaderTitle>
				),
				accessor: 'due_date',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='alert-date-title' column={column}>
						Data de Alerta
					</HeaderTitle>
				),
				accessor: 'alert_date',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{value && formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-method-title' column={column}>
						Forma de Pagamento
					</HeaderTitle>
				),
				accessor: 'payment_method',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{protocolPaymentMethodEnum[value]}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-method-title' column={column}>
						Meio de Pagamento
					</HeaderTitle>
				),
				accessor: 'payment_type',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} color={`darkGrey`}>
						{protocolPaymentTypeEnum[value]}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='payment-link-title' column={column}>
						Venda
					</HeaderTitle>
				),
				accessor: 'payment_link',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} onClick={(e) => e.stopPropagation()}>
						{value?.sale?.id && (
							<Button
								id={`${row.index}-${column.id}-button`}
								onClick={() => {
									history.push(`/admin/sales-history/${value.sale.id}`);
								}}
								bgColor={`white`}
								border='1px solid'
								borderColor={`primary`}
								color={`primary`}
								fontWeight={`medium`}
								px='4'
								height='auto'
							>
								Ir Para Venda
							</Button>
						)}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='email-content-title' column={column}>
						Corpo do Email
					</HeaderTitle>
				),
				accessor: 'email_content',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`} onClick={(e) => e.stopPropagation()}>
						{value && (
							<CopyToClipboard
								id={`${row.index}-${column.id}-copy-clipboard`}
								text={value}
								onCopy={() => {
									addToast('Corpo do email copiado com sucesso', {
										appearance: 'success',
										autoDismiss: true,
									});
								}}
							>
								<Button
									id={`${row.index}-${column.id}-button`}
									bgColor={`white`}
									border='1px solid'
									borderColor={`primary`}
									color={`primary`}
									fontWeight={`medium`}
									px='4'
									height='auto'
								>
									Copiar Corpo do Email
								</Button>
							</CopyToClipboard>
						)}
					</CellContent>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			onRowClick={onRowClick}
			pl={2}
			isLimited={true}
			maxHeightTableWrapper={450}
		/>
	);
};
