/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Logo,
    LogoFromJSON,
    LogoFromJSONTyped,
    LogoToJSON,
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
    Theme,
    ThemeFromJSON,
    ThemeFromJSONTyped,
    ThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    type: UserTypeEnum;
    /**
     * 
     * @type {Role}
     * @memberof User
     */
    role?: Role;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role_id?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    status: UserStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id_token?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    refresh_token?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created_at?: string;
    /**
     * 
     * @type {Merchant}
     * @memberof User
     */
    merchant?: Merchant;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    merchant_id?: string;
    /**
     * 
     * @type {Seller}
     * @memberof User
     */
    seller?: Seller;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    seller_id?: string;
    /**
     * 
     * @type {Logo}
     * @memberof User
     */
    logo?: Logo;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    logo_id?: string;
    /**
     * 
     * @type {Theme}
     * @memberof User
     */
    theme?: Theme;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    theme_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    subjects?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum UserTypeEnum {
    Merchant = 'merchant',
    Seller = 'seller',
    Backoffice = 'backoffice'
}/**
* @export
* @enum {string}
*/
export enum UserStatusEnum {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending'
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'email': json['email'],
        'password': json['password'],
        'type': json['type'],
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'role_id': !exists(json, 'role_id') ? undefined : json['role_id'],
        'status': json['status'],
        'id_token': !exists(json, 'id_token') ? undefined : json['id_token'],
        'refresh_token': !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'merchant': !exists(json, 'merchant') ? undefined : MerchantFromJSON(json['merchant']),
        'merchant_id': !exists(json, 'merchant_id') ? undefined : json['merchant_id'],
        'seller': !exists(json, 'seller') ? undefined : SellerFromJSON(json['seller']),
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
        'logo': !exists(json, 'logo') ? undefined : LogoFromJSON(json['logo']),
        'logo_id': !exists(json, 'logo_id') ? undefined : json['logo_id'],
        'theme': !exists(json, 'theme') ? undefined : ThemeFromJSON(json['theme']),
        'theme_id': !exists(json, 'theme_id') ? undefined : json['theme_id'],
        'subjects': !exists(json, 'subjects') ? undefined : json['subjects'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'password': value.password,
        'type': value.type,
        'role': RoleToJSON(value.role),
        'role_id': value.role_id,
        'status': value.status,
        'id_token': value.id_token,
        'refresh_token': value.refresh_token,
        'created_at': value.created_at,
        'merchant': MerchantToJSON(value.merchant),
        'merchant_id': value.merchant_id,
        'seller': SellerToJSON(value.seller),
        'seller_id': value.seller_id,
        'logo': LogoToJSON(value.logo),
        'logo_id': value.logo_id,
        'theme': ThemeToJSON(value.theme),
        'theme_id': value.theme_id,
        'subjects': value.subjects,
    };
}


