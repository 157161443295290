/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetLogoDto,
    GetLogoDtoFromJSON,
    GetLogoDtoFromJSONTyped,
    GetLogoDtoToJSON,
    GetPaymentLinkPayerDto,
    GetPaymentLinkPayerDtoFromJSON,
    GetPaymentLinkPayerDtoFromJSONTyped,
    GetPaymentLinkPayerDtoToJSON,
    GetPaymentLinkSaleDto,
    GetPaymentLinkSaleDtoFromJSON,
    GetPaymentLinkSaleDtoFromJSONTyped,
    GetPaymentLinkSaleDtoToJSON,
    GetPaymentLinkSellerDto,
    GetPaymentLinkSellerDtoFromJSON,
    GetPaymentLinkSellerDtoFromJSONTyped,
    GetPaymentLinkSellerDtoToJSON,
    GetPaymentLinkUserDto,
    GetPaymentLinkUserDtoFromJSON,
    GetPaymentLinkUserDtoFromJSONTyped,
    GetPaymentLinkUserDtoToJSON,
    GetThemeDto,
    GetThemeDtoFromJSON,
    GetThemeDtoFromJSONTyped,
    GetThemeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPaymentLinkDto
 */
export interface GetPaymentLinkDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentLinkDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    payer_name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    payer_document: string;
    /**
     * 
     * @type {GetPaymentLinkPayerDto}
     * @memberof GetPaymentLinkDto
     */
    payer: GetPaymentLinkPayerDto;
    /**
     * 
     * @type {GetPaymentLinkUserDto}
     * @memberof GetPaymentLinkDto
     */
    user: GetPaymentLinkUserDto;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    status: GetPaymentLinkDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    formatted_status: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkDto
     */
    generated_by_payer: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    formatted_generated_by_payer: string;
    /**
     * 
     * @type {GetPaymentLinkSaleDto}
     * @memberof GetPaymentLinkDto
     */
    sale: GetPaymentLinkSaleDto;
    /**
     * 
     * @type {GetPaymentLinkSellerDto}
     * @memberof GetPaymentLinkDto
     */
    seller: GetPaymentLinkSellerDto;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    expires_in: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkDto
     */
    billet_payment_is_permitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkDto
     */
    qrcode_payment_is_permitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkDto
     */
    card_payment_is_permitted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    qrcode_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaymentLinkDto
     */
    payment_methods?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkDto
     */
    pre_capture: boolean;
    /**
     * 
     * @type {GetLogoDto}
     * @memberof GetPaymentLinkDto
     */
    logo: GetLogoDto;
    /**
     * 
     * @type {GetThemeDto}
     * @memberof GetPaymentLinkDto
     */
    theme: GetThemeDto;
    /**
     * 
     * @type {object}
     * @memberof GetPaymentLinkDto
     */
    metadata: object;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentLinkDto
     */
    service_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkDto
     */
    is_edit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkDto
     */
    formatted_pre_capture: string;
}

/**
* @export
* @enum {string}
*/
export enum GetPaymentLinkDtoStatusEnum {
    Active = 'active',
    PendingCapture = 'pending_capture',
    Expired = 'expired',
    Canceled = 'canceled',
    Paid = 'paid'
}

export function GetPaymentLinkDtoFromJSON(json: any): GetPaymentLinkDto {
    return GetPaymentLinkDtoFromJSONTyped(json, false);
}

export function GetPaymentLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'description': json['description'],
        'url': json['url'],
        'email': json['email'],
        'phone': json['phone'],
        'token': json['token'],
        'payer_name': json['payer_name'],
        'payer_document': json['payer_document'],
        'payer': GetPaymentLinkPayerDtoFromJSON(json['payer']),
        'user': GetPaymentLinkUserDtoFromJSON(json['user']),
        'status': json['status'],
        'formatted_status': json['formatted_status'],
        'generated_by_payer': json['generated_by_payer'],
        'formatted_generated_by_payer': json['formatted_generated_by_payer'],
        'sale': GetPaymentLinkSaleDtoFromJSON(json['sale']),
        'seller': GetPaymentLinkSellerDtoFromJSON(json['seller']),
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'expires_in': json['expires_in'],
        'billet_payment_is_permitted': !exists(json, 'billet_payment_is_permitted') ? undefined : json['billet_payment_is_permitted'],
        'qrcode_payment_is_permitted': !exists(json, 'qrcode_payment_is_permitted') ? undefined : json['qrcode_payment_is_permitted'],
        'card_payment_is_permitted': !exists(json, 'card_payment_is_permitted') ? undefined : json['card_payment_is_permitted'],
        'qrcode_url': !exists(json, 'qrcode_url') ? undefined : json['qrcode_url'],
        'payment_methods': !exists(json, 'payment_methods') ? undefined : json['payment_methods'],
        'pre_capture': json['pre_capture'],
        'logo': GetLogoDtoFromJSON(json['logo']),
        'theme': GetThemeDtoFromJSON(json['theme']),
        'metadata': json['metadata'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'service_value': !exists(json, 'service_value') ? undefined : json['service_value'],
        'is_edit': !exists(json, 'is_edit') ? undefined : json['is_edit'],
        'formatted_pre_capture': json['formatted_pre_capture'],
    };
}

export function GetPaymentLinkDtoToJSON(value?: GetPaymentLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'description': value.description,
        'url': value.url,
        'email': value.email,
        'phone': value.phone,
        'token': value.token,
        'payer_name': value.payer_name,
        'payer_document': value.payer_document,
        'payer': GetPaymentLinkPayerDtoToJSON(value.payer),
        'user': GetPaymentLinkUserDtoToJSON(value.user),
        'status': value.status,
        'formatted_status': value.formatted_status,
        'generated_by_payer': value.generated_by_payer,
        'formatted_generated_by_payer': value.formatted_generated_by_payer,
        'sale': GetPaymentLinkSaleDtoToJSON(value.sale),
        'seller': GetPaymentLinkSellerDtoToJSON(value.seller),
        'created_at': value.created_at,
        'updated_at': value.updated_at,
        'expires_in': value.expires_in,
        'billet_payment_is_permitted': value.billet_payment_is_permitted,
        'qrcode_payment_is_permitted': value.qrcode_payment_is_permitted,
        'card_payment_is_permitted': value.card_payment_is_permitted,
        'qrcode_url': value.qrcode_url,
        'payment_methods': value.payment_methods,
        'pre_capture': value.pre_capture,
        'logo': GetLogoDtoToJSON(value.logo),
        'theme': GetThemeDtoToJSON(value.theme),
        'metadata': value.metadata,
        'note': value.note,
        'service_value': value.service_value,
        'is_edit': value.is_edit,
        'formatted_pre_capture': value.formatted_pre_capture,
    };
}


