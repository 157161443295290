import React from 'react';

import Select from 'react-select';

import { Box, HStack, Flex } from '@chakra-ui/layout';

import Checkout from '@parcelaexpress/checkout-react-component';

import { maskMoney } from '../../../services/masks.service';
import { GetPaymentLinkSellerDto, GetSimulationDto } from '../../../clients';
import { CheckoutSaleInformationType } from '../types/checkout.type';

import CreditCardModal from './CreditCardModal';
import Checkbox from '../../../components/Checkbox';
import Text from '../../../components/Text';

type CreditCardProps = {
	seller: GetPaymentLinkSellerDto;
	hasPreCapture?: boolean;
	simulationValues: Array<GetSimulationDto>;
	saleInformation: CheckoutSaleInformationType | undefined;
	setLinkPayed(payed: boolean): void;
};

const paymentTypesLiterals = {
	credit: 'Crédito',
};

const CreditCard: React.FC<CreditCardProps> = (props: CreditCardProps) => {
	const [reset, setReset] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [checked, setChecked] = React.useState(false);
	const [confirmPayment, setConfirmPayment] = React.useState(false);
	const [value, setValue] = React.useState({
		simulated: 0,
		original: 0,
		installments: 1,
	});
	const [creditCheckout, setCreditCheckout] = React.useState({
		holder_name: '',
		number: '',
		expiration_month: '',
		expiration_year: '',
		security_code: '',
	});

	const checkoutSubmitHandle = (state) => {
		const { data } = state;
		const { paymentMethod } = data;

		setConfirmPayment(!confirmPayment);

		setCreditCheckout({
			holder_name: paymentMethod.holderName,
			number: paymentMethod.encryptedCardNumber,
			expiration_month: paymentMethod.encryptedExpiryMonth,
			expiration_year: paymentMethod.encryptedExpiryYear,
			security_code: paymentMethod.encryptedSecurityCode,
		});
	};

	const checkoutOnChangeHandle = (state) => {
		/*console.log('checkoutOnChangeHandle');
		console.log('state', state);*/
	};

	React.useEffect(() => {
		setReset(!reset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.simulated]);

	return (
		<Box>
			<Select
				label='Opções de simulação'
				placeholder='Selecione o número de parcelas...'
				placehol
				options={props.simulationValues
					.filter((installment) => installment.type === 'credit')
					.map((installment) => ({
						label: (
							<HStack justify='space-between'>
								<Text id='installment-amount-cents-text'>
									<strong>
										{installment.installment} x no {paymentTypesLiterals[installment.type]}
									</strong>{' '}
									de R$ {maskMoney(installment.installment_amount_cents / 100)}
								</Text>

								<Text id='mask-installment-total-amount-cents-text'>
									{' '}
									<strong>Total:</strong> R$ {maskMoney(installment.total_amount_cents / 100)}
								</Text>
							</HStack>
						),
						value: installment,
					}))}
				onChange={({ value }) => {
					setValue({
						installments: value.installment,
						simulated: value.total_amount_cents,
						original: value.original_amount_cents,
					});
				}}
			/>

			<Text id='sale-information-fee-text' align='center' mt={3}>
				Valor original da dívida destinado ao cartório: R${' '}
				<span style={{ fontWeight: 'bold' }}>{maskMoney((value.original ? value.original - props?.saleInformation?.fee! : 0) / 100)}</span>
			</Text>
			<Text id='addition-to-the-notary-debt-text' align='center'>
				* Acréscimo sobre a dívida do cartório: R${' '}
				<span style={{ fontWeight: 'bold' }}>{maskMoney((value.simulated - value.original) / 100)}</span>
			</Text>
			{props.saleInformation && (
				<Text id='fee-value-text' align='center'>
					Valor do Emolumento: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney(props.saleInformation.fee / 100)}</span>
				</Text>
			)}
			<Text id='increase-on-the-emolument-debt-text' align='center'>
				Acréscimo sobre a dívida de emolumento: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney(0)}</span>
			</Text>
			<Text id='amount-to-be-paid-in-addition-text' align='center'>
				Valor a ser pago com acréscimo: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney(value.simulated / 100)}</span>
			</Text>

			{!checked && (
				<Box border='1px solid #EE7D00' p={2} mt={2}>
					<Text id='payment-rules-parcela-text' align='center' fontSize='0.75rem'>
						O pagamento realizado por cartão será confirmado de forma instantânea no cartório.
					</Text>
					<Checkbox
						id='accept-terms-credit-card-checkbox'
						size='sm'
						colorScheme='orange'
						isChecked={checked}
						border='#EE7D00'
						onClick={() => {
							setChecked(!checked);
						}}
						mt={3}
					>
						Estou ciente
					</Checkbox>
				</Box>
			)}
			<Flex w='100%' borderWidth={1} borderColor='#ccc' rounded='xl' p={10} display={checked ? 'block' : 'none'}>
				<Checkout
					environment={process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_ENVIRONMENT}
					clientKey={process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_CLIENT_KEY}
					onSubmit={checkoutSubmitHandle}
					onChange={checkoutOnChangeHandle}
				/>
			</Flex>
			<Text id='payment-rule-tax-parcela-text' mt={2} fontSize='0.75rem'>
				* Sobre o valor original dos serviços do cartório, será acrescentada a taxa da administradora do cartão.
			</Text>
			<CreditCardModal
				hasPreCapture={props.hasPreCapture}
				confirmPayment={confirmPayment}
				setConfirmPayment={setConfirmPayment}
				isLoading={isLoading}
				creditCheckout={creditCheckout}
				saleInformation={props.saleInformation}
				setIsLoading={setIsLoading}
				simulatedAmount={value}
				seller={props.seller}
				setLinkPayed={props.setLinkPayed}
			/>
		</Box>
	);
};

export default CreditCard;
