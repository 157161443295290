/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BarCodeDTO,
    BarCodeDTOFromJSON,
    BarCodeDTOFromJSONTyped,
    BarCodeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuthorizeTransactionDTO
 */
export interface AuthorizeTransactionDTO {
    /**
     * 
     * @type {BarCodeDTO}
     * @memberof AuthorizeTransactionDTO
     */
    barCode: BarCodeDTO;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizeTransactionDTO
     */
    sameDayPayment?: boolean;
}

export function AuthorizeTransactionDTOFromJSON(json: any): AuthorizeTransactionDTO {
    return AuthorizeTransactionDTOFromJSONTyped(json, false);
}

export function AuthorizeTransactionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeTransactionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'barCode': BarCodeDTOFromJSON(json['barCode']),
        'sameDayPayment': !exists(json, 'sameDayPayment') ? undefined : json['sameDayPayment'],
    };
}

export function AuthorizeTransactionDTOToJSON(value?: AuthorizeTransactionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'barCode': BarCodeDTOToJSON(value.barCode),
        'sameDayPayment': value.sameDayPayment,
    };
}


