/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSalePlanPaymentTypeDto,
    CreateSalePlanPaymentTypeDtoFromJSON,
    CreateSalePlanPaymentTypeDtoFromJSONTyped,
    CreateSalePlanPaymentTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateSalePlanDto
 */
export interface CreateSalePlanDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanDto
     */
    description: string;
    /**
     * 
     * @type {Array<CreateSalePlanPaymentTypeDto>}
     * @memberof CreateSalePlanDto
     */
    payment_types?: Array<CreateSalePlanPaymentTypeDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanDto
     */
    type: CreateSalePlanDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanDto
     */
    payment_method: CreateSalePlanDtoPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanDto
     */
    merchant_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanDto
     */
    acquirer_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanDto
     */
    settlement_plan_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalePlanDto
     */
    cost_plan_id: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateSalePlanDtoTypeEnum {
    Markup = 'markup',
    Spread = 'spread',
    Split = 'split'
}/**
* @export
* @enum {string}
*/
export enum CreateSalePlanDtoPaymentMethodEnum {
    Pos = 'pos',
    Online = 'online'
}

export function CreateSalePlanDtoFromJSON(json: any): CreateSalePlanDto {
    return CreateSalePlanDtoFromJSONTyped(json, false);
}

export function CreateSalePlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSalePlanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'payment_types': !exists(json, 'payment_types') ? undefined : ((json['payment_types'] as Array<any>).map(CreateSalePlanPaymentTypeDtoFromJSON)),
        'type': json['type'],
        'payment_method': json['payment_method'],
        'merchant_id': json['merchant_id'],
        'acquirer_id': json['acquirer_id'],
        'settlement_plan_id': json['settlement_plan_id'],
        'cost_plan_id': json['cost_plan_id'],
    };
}

export function CreateSalePlanDtoToJSON(value?: CreateSalePlanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'payment_types': value.payment_types === undefined ? undefined : ((value.payment_types as Array<any>).map(CreateSalePlanPaymentTypeDtoToJSON)),
        'type': value.type,
        'payment_method': value.payment_method,
        'merchant_id': value.merchant_id,
        'acquirer_id': value.acquirer_id,
        'settlement_plan_id': value.settlement_plan_id,
        'cost_plan_id': value.cost_plan_id,
    };
}


