import { Flex, HStack, BreadcrumbItem, BreadcrumbLink, Box, Stack } from '@chakra-ui/react';
import Title from '../../components/Title';
import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import Paper from '../../containers/Paper';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import BackofficeAlertContainer from '../../containers/BackofficeAlertContainer';
import Button from '../../components/Button';
import { BiLock } from 'react-icons/bi';
import NewModal from '../../containers/NewModal';
import Text from '../../components/Text';
import * as Yup from 'yup';
import {
	PosPasswordApi,
	CreatePosPasswordRequest,
	GetPosPasswordRequest,
	UpdatePosPasswordRequest,
	ResponsePosPasswordDto,
	DeletePosPasswordRequest,
} from '../../clients';
import { getApiAuthConfig } from '../../services/api.service';
import { useToasts } from 'react-toast-notifications';
import Loader from '../../containers/Loader';
import FormPasswordInput from '../../components/Form/FormPasswordInput';
import { Formik } from 'formik';
import FormControl from '../../components/Form/FormControl';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import { useAuth } from '../../contexts/AuthProvider';

const validationUpdateSchema = Yup.object().shape({
	newPassword: Yup.string()
		.matches(/^\d{4}$/, 'Insira até 4 dígitos')
		.required('Campo obrigatório'),
	oldPassword: Yup.string()
		.matches(/^\d{4}$/, 'Insira até 4 dígitos')
		.required('Campo obrigatório'),
});

const validationCreateSchema = Yup.object().shape({
	password: Yup.string()
		.matches(/^\d{4}$/, 'Insira até 4 dígitos')
		.required('Campo obrigatório'),
});

const PosPassword: React.FC = () => {
	const { currentSeller } = useCurrentSeller();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [posPassword, setposPassword] = useState<ResponsePosPasswordDto>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const apiConfig = getApiAuthConfig();
	const posPasswordApi = new PosPasswordApi(apiConfig);
	const { addToast } = useToasts();
	const { isBackoffice } = useAuth();

	const initialUpdateValues = {
		newPassword: '',
		oldPassword: '',
	};

	const initialCreateValues = {
		password: '',
	};
	const fetchData = async () => {
		setIsLoading(true);
		try {
			if (currentSeller) {
				const params: GetPosPasswordRequest = {
					sellerId: currentSeller.id,
				};
				const response = await posPasswordApi.getPosPassword(params);
				if (response.id) {
					setposPassword(response);
				}
			}
		} catch (error) {
			addToast('Erro ao buscar dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, [currentSeller]);

	const close = () => {
		setIsOpen(false);
	};

	const fetchCreatePosPassword = async (value) => {
		setIsLoading(true);
		if (currentSeller) {
			try {
				const params: CreatePosPasswordRequest = {
					createPosPasswordDto: {
						password: value.password,
						seller_id: currentSeller?.id,
					},
				};
				await posPasswordApi.createPosPassword(params);
				addToast('Senha Cadastrada', {
					appearance: 'success',
					autoDismiss: true,
				});
			} catch (error) {
				addToast('Erro ao cadastrar senha', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
				close();
				fetchData();
			}
		}
	};

	const fetchUpdatePosPassword = async (values) => {
		setIsLoading(true);
		if (currentSeller && posPassword) {
			try {
				const params: UpdatePosPasswordRequest = {
					id: posPassword.id,
					updatePosPasswordDto: {
						old_password: values.oldPassword,
						password: values.newPassword,
						seller_id: currentSeller.id,
					},
				};
				await posPasswordApi.updatePosPassword(params);
				addToast('Senha Atualizada', {
					appearance: 'success',
					autoDismiss: true,
				});
			} catch (error) {
				addToast('Erro ao Atualizar senha', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
				close();
				fetchData();
			}
		}
	};

	const fetchDeletePosPassword = async () => {
		setIsLoading(true);
		if (currentSeller && posPassword) {
			try {
				const params: DeletePosPasswordRequest = {
					id: posPassword.id,
					sellerId: currentSeller.id,
				};
				await posPasswordApi.deletePosPassword(params);
				setposPassword(undefined);
				addToast('Senha Deletada', {
					appearance: 'success',
					autoDismiss: true,
				});
			} catch (error) {
				addToast('Erro ao Deletar senha', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
				fetchData();
			}
		}
	};

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />
			<HStack justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Minha Conta</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Senha Pos Android
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</HStack>
			<Flex flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
				<Title id='pos-password-title'>Senha Pos Android</Title>
				{!posPassword && (
					<Button
						id='Create-pos-password-button'
						bgColor='primary'
						onClick={() => setIsOpen(true)}
						leftIcon={<BiLock size={20} />}
						isLoading={isLoading}
					>
						Cadastrar senha
					</Button>
				)}

				{posPassword && isBackoffice && (
					<Button
						id='delete-pos-password-button'
						bgColor='error'
						onClick={fetchDeletePosPassword}
						isLoading={isLoading}
					>
						Deletar
					</Button>
				)}
			</Flex>
			<Paper id='pos-password-paper' p={6}>
				<Flex justifyContent='center'>
					{posPassword ? (
						<Stack w='100%' alignItems='flex-start' justifyContent='center'>
							<Flex w='100%' align='center' justifyContent='start' flexDirection='row'>
								<Text textAlign='center' fontWeight='bold' id='password-data-text' paddingEnd='2'>
									Senha:
								</Text>
								<Text textAlign='center' id='password-data-value-text'>
									****
								</Text>
							</Flex>

							<Flex w='100%' alignItems='center' justifyContent='start' flexDirection='row'>
								<Text textAlign='center' fontWeight='bold' id='create-by-data-text' paddingEnd='2'>
									Criado por:
								</Text>
								<Text textAlign='center' id='create-by-data-value-text'>
									{posPassword?.created_user_name}
								</Text>
							</Flex>

							{posPassword.updated_user_name && (
								<Flex w='100%' alignItems='center' justifyContent='start' flexDirection='row'>
									<Text textAlign='center' fontWeight='bold' id='updated-by-data-text' paddingEnd='2'>
										Atualizado por:
									</Text>
									<Text textAlign='center' id='updated-by-data-value-text'>
										{posPassword?.created_user_name}
									</Text>
								</Flex>
							)}
							<Stack w='100%' alignItems='center' mb='10px' justifyContent='center'>
								<Button
									id='update-pos-password-form-modal-button'
									bgColor='primary'
									onClick={() => setIsOpen(true)}
									isLoading={isLoading}
									leftIcon={<BiLock size={20} />}
								>
									Atualizar senha
								</Button>
							</Stack>
						</Stack>
					) : (
						<Text textAlign='center' fontWeight='bold' id='password-not-found-text-error' paddingEnd='2'>
							Senha não cadastrada
						</Text>
					)}
				</Flex>
			</Paper>
			<NewModal isOpen={isOpen} onClose={close} size='sm' showCloseButton>
				<Box borderRadius='md'>
					<Flex py={4} px={12} flexDirection='column' align='center'>
						<Stack w='100%' align='center' mb='10px' justifyContent='center'>
							{posPassword ? (
								<Formik
									padding={'20'}
									enableReinitialize
									initialValues={initialUpdateValues}
									validationSchema={validationUpdateSchema}
									onSubmit={fetchUpdatePosPassword}
								>
									{({ handleSubmit, isValid }) => {
										return (
											<form onSubmit={handleSubmit}>
												<Stack spacing={5}>
													<FormControl>
														<Text
															marginY='2'
															id='update-pos-text-title'
															color='darkGrey'
															fontSize='md'
															fontWeight='bold'
															textAlign='center'
														>
															Atualizar Senha Pos Android
														</Text>
														<FormPasswordInput
															id='old-password-form-input'
															type='number'
															name='oldPassword'
															placeholder='Senha Antiga'
															mb='1'
															autoComplete='nope'
														/>
														<FormErrorMessage id='update-old-password-form-error-text' name='oldPassword' />
													</FormControl>

													<FormControl>
														<FormPasswordInput
															id='new-password-form-input'
															type='number'
															name='newPassword'
															placeholder='Nova Senha'
															mb='1'
															autoComplete='nope'
														/>
														<FormErrorMessage id='update-new-password-form-error-text' name='newPassword' />
													</FormControl>
													<Flex flexDirection='column' alignItems='center' justifyContent='center'>
														<Button
															alignItems='center'
															id='update-pos-button'
															bgColor='primary'
															isLoading={isLoading}
															type='submit'
															disabled={!isValid}
														>
															Atualizar
														</Button>
													</Flex>
												</Stack>
											</form>
										);
									}}
								</Formik>
							) : (
								<Formik
									enableReinitialize
									initialValues={initialCreateValues}
									validationSchema={validationCreateSchema}
									onSubmit={fetchCreatePosPassword}
								>
									{({ handleSubmit, isValid }) => {
										return (
											<form onSubmit={handleSubmit}>
												<Stack spacing={5}>
													<FormControl>
														<Text
															marginY='2'
															id='create-pos-text-form-title'
															color='darkGrey'
															fontSize='md'
															fontWeight='bold'
															textAlign='center'
														>
															Cadastrar Senha Pos Android
														</Text>

														<FormPasswordInput
															id='create-password-form-input'
															type='number'
															name='password'
															placeholder='Senha'
															mb='1'
															autoComplete='nope'
														/>
														<FormErrorMessage id='create-password-form-error-text' name='password' />
													</FormControl>

													<Flex flexDirection='column' alignItems='center' justifyContent='center'>
														<Button
															alignItems='center'
															id='create-pos-password-form-modal-button'
															bgColor='primary'
															isLoading={isLoading}
															type='submit'
															disabled={!isValid}
														>
															Cadastrar
														</Button>
													</Flex>
												</Stack>
											</form>
										);
									}}
								</Formik>
							)}
						</Stack>
					</Flex>
				</Box>
			</NewModal>
		</Flex>
	);
};

export default PosPassword;
