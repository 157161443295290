import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';
import { Flex, Stack, BreadcrumbItem, BreadcrumbLink, FormControl } from '@chakra-ui/react';
import { Center } from '@chakra-ui/layout';

import { ResponsibleApi, CreateResponsibleRequest } from 'clients';

import Loader from 'containers/Loader';
import Paper from 'containers/Paper';
import { getApiAuthConfig } from 'services/api.service';
import Button from 'components/Button';
import Breadcrumb from 'components/Breadcrumb';
import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import { responsible_type } from 'config/constants';
import yup from 'services/yup.service';
import FormSelect from 'components/Form/FormSelect';
import FormLabel from '../../components/Form/FormLabel';
import FormPhoneInput from '../../components/Form/FormPhoneInput';
import { UploadImageForm } from './components/UploadImageForm';

const CreateResponsible: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const responsibleApi = new ResponsibleApi(apiConfig);
	const [file, setFile] = React.useState<File | undefined>(undefined);

	const [isLoading, setIsLoading] = React.useState(false);

	const { addToast } = useToasts();

	const createResponsibleInitialValues = {
		name: '',
		type: '',
		is_active: true,
		phone: '',
	};

	const createResponsibleSchema = yup.object().shape({
		name: yup.string().required('Campo obrigatório'),
		type: yup.string().required('Campo obrigatório'),
		is_active: yup.boolean(),
		phone: yup.string().required('Campo obrigatório'),
	});

	const createResponsible = async (values, { resetForm }) => {
		setIsLoading(true);

		const { is_active } = values;

		try {
			const requestParams: CreateResponsibleRequest = {
				name: values.name,
				type: values.type,
				phone: values.phone,
				isActive: typeof is_active === 'string' ? is_active === 'true' : is_active,
				file: file!!,
			};
			await responsibleApi.createResponsible(requestParams);

			addToast('Responsável criado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			if (error.mensage === 'Imagem não enviada.') {
				addToast('Error imagem não enviada.', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
			addToast('Erro ao criar Responsável', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			resetForm();
		}
	};

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink href='/admin/responsaveis'>Responsáveis</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontWeight='semibold'>Criar Novo Responsável</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper id='responsibles-formik-paper-text' p={6}>
				<Flex flexDirection='column'>
					<>
						<Formik initialValues={createResponsibleInitialValues} validationSchema={createResponsibleSchema} onSubmit={createResponsible}>
							{({ handleSubmit, isValid, setFieldValue, errors, values }) => {
								return (
									<form onSubmit={handleSubmit} style={{ width: '100%' }}>
										<FormControl id='image' isRequired>
											<UploadImageForm key={file?.name} file={file} setFile={setFile} isLoading={isLoading} />
										</FormControl>
										<Stack spacing={4}>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='name' isRequired>
													<FormLabel id='name-label' fontSize='md' fontWeight='bold'>
														Nome
													</FormLabel>
													<FormInput id='responsible-name-form-input' name='name' />
													<FormErrorMessage id='create-responsible-name-form-error-text' name='name' />
												</FormControl>

												<FormControl id='type' isRequired>
													<FormLabel id='type-label' fontSize='md' fontWeight='bold'>
														Tipo
													</FormLabel>
													<FormSelect id='create-responsible-type-form-select' name='type'>
														<option value='' />
														{responsible_type.map((type) => (
															<option value={type.key} key={type.key}>
																{type.text}
															</option>
														))}
													</FormSelect>
													<FormErrorMessage id='create-responsible-type-form-error-text' name='type' />
												</FormControl>
											</Stack>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='phone' isRequired>
													<FormLabel id='create-responsible-phone-label' fontSize='md' fontWeight='bold'>
														Telefone
													</FormLabel>

													<FormPhoneInput id='create-responsible-phone-input' borderColor='darkGrey' name='phone' />
												</FormControl>

												<FormControl id='is_active' isRequired>
													<FormLabel id='create-responsible-is-active-label' fontSize='md' fontWeight='bold'>
														Ativo
													</FormLabel>
													<FormSelect id='create-responsible-is-active-form-select' name='is_active'>
														<option value={'true'} key={1}>
															Sim
														</option>
														<option value={'false'} key={0}>
															Não
														</option>
													</FormSelect>
													<FormErrorMessage id='create-responsible-is-active-form-error-text' name='is_active' />
												</FormControl>
											</Stack>
										</Stack>

										<Center pt='6'>
											<Button
												id='create-responsible-button'
												bgColor='primary'
												size='md'
												disabled={!isValid}
												type='submit'
												isLoading={isLoading}
											>
												Criar Responsável
											</Button>
										</Center>
									</form>
								);
							}}
						</Formik>
					</>
				</Flex>
			</Paper>
		</Flex>
	);
};

export default CreateResponsible;
