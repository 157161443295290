import React from 'react';
import {
  SimpleGrid,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { GridItem } from '@chakra-ui/layout';

import {
  risk_level
} from 'config/constants';
import Attachments from 'components/Attachments';
import FormSelect from 'components/Form/FormSelect';
import Textarea from 'components/Form/FormTextarea';
import FormErrorMessage from 'components/Form/FormErrorMessage';


type Props = {
  values: any;
  setFieldValue: any;
};

const RiskForm: React.FC<Props> = ({
  values,
  setFieldValue,
}) => {
  return (
    <SimpleGrid
      columns={{base: 1, md: 6, xl: 12}}
      color='gray.700'
      gap={4}
    >
      <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 2, xl: 4}}>
        <FormControl id='risk_classification' isRequired>
          <FormLabel id='risk-label' fontSize='md' fontWeight='bold'>
            Risco
          </FormLabel>
          <FormSelect id='risk-classification' name='risk_classification'>
            <option value='' />
            {risk_level.map((level) => (
              <option value={level.value} key={level.key}>
                {level.text}
              </option>
            ))}
          </FormSelect>
          <FormErrorMessage id='risk-classinfication-form-error-text' name='risk_classification' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1, md: 3}} colSpan={{base: 3, md: 2, xl: 4}}>
        <FormControl id='negative_media' isRequired>
          <FormLabel id='negative-media-label' fontSize='md' fontWeight='bold'>
            Mídia Negativa
          </FormLabel>
          <FormSelect id='risk-negative-media-form-select' name='negative_media'>
            <option value='false'>Não</option>
            <option value='true'>Sim</option>
          </FormSelect>
          <FormErrorMessage id='risk-negative-media-form-error-text' name='negative_media' />
        </FormControl>
      </GridItem>

      <GridItem
        colStart={{base: 1, md: 5, xl: 9}}
        colSpan={{base: 3, md: 2, xl: 4}}
      >
        <FormControl id='lawsuits' isRequired>
          <FormLabel id='risk-lawsuits-label' fontSize='md' fontWeight='bold'>
            Processos Judiciais
          </FormLabel>
          <FormSelect id='risk-form-lawsuits-form-select' name='lawsuits'>
            <option value='false'>Não</option>
            <option value='true'>Sim</option>
          </FormSelect>
          <FormErrorMessage id='risk-form-lawsuits-form-error-text' name='lawsuits' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1, md: 1}} colSpan={{base: 3, md: 6, xl: 12}}>
        <FormControl id='note'>
          <FormLabel id='note-label' fontSize='md' fontWeight='bold'>
            Observação
          </FormLabel>
          <Textarea id='note-text-area' name='note' value={values.note}/>
          <FormErrorMessage id='note-form-error-text' name='note' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1, xl: 1}} colSpan={{base: 3, md: 6, xl: 12}}>
          <FormLabel id='attachements-label' fontSize='md' fontWeight='bold'>
            Anexos
          </FormLabel>
          <Attachments
            attachments={values.attachments}
            changeAttachments={(files) => setFieldValue('attachments', files)}
          />
      </GridItem>
    </SimpleGrid>
  );
}


export default RiskForm;