import React from 'react';

import InputMask from 'react-input-mask';

import { InputGroup, InputRightElement, Icon, InputProps } from '@chakra-ui/react';
import { Field } from 'formik';
import { GoCalendar } from 'react-icons/go';

import ReactDatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import ptBR from 'date-fns/locale/pt-BR';

import Input from '../Input';

registerLocale('pt_BR', ptBR);

type DatePickerProps = {
	dateFormat?: string;
	onDateChange?: (date: Date) => void;
	minDate?: Date;
	maxDate?: Date;
	id: string;
};

const FormDatePickerInput: React.FC<DatePickerProps & InputProps> = (props: DatePickerProps & InputProps) => {
	const { dateFormat, onDateChange, minDate, maxDate, ...parsedProps } = props;

	return (
		<Field {...parsedProps}>
			{({ form, field }) => {
				return (
					<ReactDatePicker
						selected={field.value}
						onChange={(date) => {
							if (null === date) {
								date = '';
							}
							form.setFieldValue(field.name, date);

							props.onDateChange && props.onDateChange(date);
						}}
						dateFormat={props.dateFormat ? props.dateFormat : `dd/MM/yyyy`}
						locale='pt_BR'
						value={field.value}
						name={field.name}
						onBlur={form.handleBlur}
						isClearable={field.value || field.value !== ''}
						minDate={minDate && minDate}
						maxDate={maxDate && maxDate}
						customInput={
							<InputMask
								mask={'99/99/9999'}
								formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
								maskChar={null}
								value={field.value}
								onBlur={form.handleBlur}
								{...field}
							>
								{(inputProps) => {
									return (
										<InputGroup size={props.size || `md`}>
											<Input type='text' onBlur={form.handleBlur} {...props} autoComplete="nope" />
											{(!field.value || field.value === '') && (
												<InputRightElement children={<Icon as={GoCalendar} color='green.500' size={`25px`} />} />
											)}
										</InputGroup>
									);
								}}
							</InputMask>
						}
					/>
				);
			}}
		</Field>
	);
};

export default FormDatePickerInput;
