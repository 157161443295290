/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateAttachmentDto,
    CreateAttachmentDtoFromJSON,
    CreateAttachmentDtoFromJSONTyped,
    CreateAttachmentDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateSellerRiskDto
 */
export interface CreateSellerRiskDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSellerRiskDto
     */
    seller_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerRiskDto
     */
    classification: CreateSellerRiskDtoClassificationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerRiskDto
     */
    negative_media: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerRiskDto
     */
    lawsuits: boolean;
    /**
     * 
     * @type {Array<CreateAttachmentDto>}
     * @memberof CreateSellerRiskDto
     */
    attachments: Array<CreateAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerRiskDto
     */
    note: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateSellerRiskDtoClassificationEnum {
    Lower = 'lower',
    Medium = 'medium',
    High = 'high'
}

export function CreateSellerRiskDtoFromJSON(json: any): CreateSellerRiskDto {
    return CreateSellerRiskDtoFromJSONTyped(json, false);
}

export function CreateSellerRiskDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSellerRiskDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seller_id': json['seller_id'],
        'classification': json['classification'],
        'negative_media': json['negative_media'],
        'lawsuits': json['lawsuits'],
        'attachments': ((json['attachments'] as Array<any>).map(CreateAttachmentDtoFromJSON)),
        'note': json['note'],
    };
}

export function CreateSellerRiskDtoToJSON(value?: CreateSellerRiskDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seller_id': value.seller_id,
        'classification': value.classification,
        'negative_media': value.negative_media,
        'lawsuits': value.lawsuits,
        'attachments': ((value.attachments as Array<any>).map(CreateAttachmentDtoToJSON)),
        'note': value.note,
    };
}


