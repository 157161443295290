import { Icon, Button, Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';
import Text from '../../../components/Text';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, confirmDelete, editCredential }) => {
	const columns = React.useMemo(() => {
		let cols: any = [];
		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled id='id-title' column={column}>ID</HeaderTitleStyled>,
				accessor: 'id',
				Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitle id='edit-credential-button-title' column={column}>Ações</HeaderTitle>,
				accessor: 'edit-credential-button',
				Cell: ({ cell: { row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>
						<Flex>
							<Tooltip label='Editar Credencial'>
								<Button
									id={`${row.index}-${column.id}-edit-button`}
									background={'transparent'}
									onClick={() => {
										editCredential(row.values.id)
									}}
								>
									<Icon id={`${row.index}-${column.id}-edit-icon`} as={FiEdit} boxSize={5} />
								</Button>
							</Tooltip>
							<Tooltip label='Excluir Credencial'>
								<Button
									id={`${row.index}-${column.id}-delete-button`}
									background={'transparent'}
									onClick={() => {
										confirmDelete(row.values.id);
									}}
								>
									<Icon id={`${row.index}-${column.id}-delete-icon`} as={FiTrash2} boxSize={5} />
								</Button>
							</Tooltip>
						</Flex>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled id='acquirer-title' column={column}>Adquirente</HeaderTitleStyled>,
				accessor: 'acquirer',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContentStyled id={`${row.index}-${column.id}-cell`}>
						<Text id={`${row.index}-${column.id}-text`}>{value.name ?? 'N/A'}</Text>
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled id='client-id-title' column={column}>Id do Cliente</HeaderTitleStyled>,
				accessor: 'client_id',
				Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled id='api-key-title' column={column}>Chave de api</HeaderTitleStyled>,
				accessor: 'api_key',
				Cell: ({ cell: { value, column, row } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value ?? 'N/A'}</CellContentStyled>,
			},
		]);
	}, [editCredential, confirmDelete]);

	return <SimpleTable columns={columns} data={data} pl={2} variant='unstyled' maxHeightTableWrapper='42vh' />;
};
