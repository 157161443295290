import React from 'react';

import { Formik } from 'formik';
import { format } from 'date-fns';
import { Center, FormControl, Tooltip, IconButton, Stack, useBreakpointValue } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

import IPeriod from '../../../interfaces/period.interface';

import { GetProtestLinkDtoStatusEnum, GetProtestLinkSaleDtoFormPaymentEnum } from '../../../clients';

import RangeDatePicker from '../../../containers/RangeDatePicker';
import MultiSelect from '../../../components/MultiSelect';
import Button from '../../../components/Button';

type ProtestLinksFiltersProps = {
	onChangeFilters: (filters) => void;
};

const ProtestLinksFilters: React.FC<ProtestLinksFiltersProps> = (props: ProtestLinksFiltersProps) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const pagination = { currentPage: 1, limit: 10 };

	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const initialValues = {
		status: [],
		form_payment: [],
		startDate: format(period[0].startDate, 'yyyy-MM-dd'),
		endDate: format(period[0].endDate, 'yyyy-MM-dd'),
	};

	const handleFormSubmit = async (values) => {
		let parsedFilters = {
			currentPage: pagination.currentPage,
			limit: pagination.limit,
			startDate: format(period[0].startDate, 'yyyy-MM-dd'),
			endDate: format(period[0].endDate, 'yyyy-MM-dd'),
			status: values.status?.map((v) => v.value),
			formProtest: values.form_payment?.map((v) => v.value),
		};

		if (parsedFilters.status && !parsedFilters.status.length) {
			delete parsedFilters.status;
		}

		if (parsedFilters.formProtest && !parsedFilters.formProtest.length) {
			delete parsedFilters.formProtest;
		}

		props.onChangeFilters(parsedFilters);
	};

	return (
		<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
			{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
				return (
					<form onSubmit={handleSubmit}>
						{/* <HStack spacing={5} mb={4}> */}
						<Stack direction={isMobile ? 'column' : 'row'} justify='space-between'>
							<Stack direction={isMobile ? 'column' : 'row'} spacing={5} mb={4}>
								<Center position='relative'>
									<RangeDatePicker period={period} setPeriod={setPeriod} setFieldValue={setFieldValue} handleSubmit={handleSubmit} />
								</Center>
								<Center pt={0} minWidth='250px'>
									<FormControl>
										<MultiSelect
											id='status'
											placeholder='Status'
											options={[
												{ value: GetProtestLinkDtoStatusEnum.Active, label: 'Pendente' },
												{ value: GetProtestLinkDtoStatusEnum.Expired, label: 'Expirado' },
												{ value: GetProtestLinkDtoStatusEnum.Canceled, label: 'Cancelado' },
												{ value: GetProtestLinkDtoStatusEnum.Paid, label: 'Pago' },
											]}
											value={values.status}
											onChange={(value) => {
												setFieldValue('status', value);
												handleSubmit();
											}}
										/>
									</FormControl>
								</Center>
								<Center pt={0} minWidth='250px' mr='2'>
									<FormControl>
										<MultiSelect
											id='form-payment'
											placeholder='Forma de Pagamento'
											options={[
												{ value: GetProtestLinkSaleDtoFormPaymentEnum.Credit, label: 'Crédito' },
												{ value: GetProtestLinkSaleDtoFormPaymentEnum.Debit, label: 'Débito' },
												{ value: GetProtestLinkSaleDtoFormPaymentEnum.Billet, label: 'Boleto' },
												{ value: GetProtestLinkSaleDtoFormPaymentEnum.Pix, label: 'Pix' },
											]}
											value={values.form_payment}
											onChange={(value) => {
												setFieldValue('form_payment', value);
												handleSubmit();
											}}
										/>
									</FormControl>
								</Center>
							</Stack>
							<Center>
								<Tooltip label={'Refazer a busca'} placement='top' hasArrow>
									<IconButton
										_hover={{ backgroundColor: 'transparent' }}
										_focus={{ outline: 'none' }}
										aria-label='Refazer a busca'
										icon={<RepeatIcon />}
										color='primary'
										backgroundColor='transparent'
										size='lg'
										onClick={() => handleSubmit()}
									/>
								</Tooltip>
								{(values.form_payment.length > 0 || values.status.length > 0) && (
									<Button
										id='clear-filters-button'
										onClick={() => {
											resetForm();
											handleSubmit();
										}}
										_focus={{ outline: 'none' }}
										color='secondary'
										bgColor='transparent'
									>
										Limpar Filtros
									</Button>
								)}
							</Center>
						</Stack>
					</form>
				);
			}}
		</Formik>
	);
};

export default ProtestLinksFilters;
