/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountBankAccount,
    DiscountBankAccountFromJSON,
    DiscountBankAccountFromJSONTyped,
    DiscountBankAccountToJSON,
    Logo,
    LogoFromJSON,
    LogoFromJSONTyped,
    LogoToJSON,
    MerchantReportsPermissions,
    MerchantReportsPermissionsFromJSON,
    MerchantReportsPermissionsFromJSONTyped,
    MerchantReportsPermissionsToJSON,
    MerchantTablesPermissions,
    MerchantTablesPermissionsFromJSON,
    MerchantTablesPermissionsFromJSONTyped,
    MerchantTablesPermissionsToJSON,
    Theme,
    ThemeFromJSON,
    ThemeFromJSONTyped,
    ThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof Merchant
     */
    is_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    payment_by_link_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    payment_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    accounting_account_spread_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Merchant
     */
    financial_emails?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Merchant
     */
    pre_capture?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Merchant
     */
    risk_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Merchant
     */
    min_risk?: number;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    merchant_api_key?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    merchant_account?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    discount_bank_account_id: string;
    /**
     * 
     * @type {DiscountBankAccount}
     * @memberof Merchant
     */
    discount_bank_account?: DiscountBankAccount;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    shipping_discount_bank_account_id: string;
    /**
     * 
     * @type {DiscountBankAccount}
     * @memberof Merchant
     */
    shipping_discount_bank_account?: DiscountBankAccount;
    /**
     * 
     * @type {Logo}
     * @memberof Merchant
     */
    logo?: Logo;
    /**
     * 
     * @type {Theme}
     * @memberof Merchant
     */
    theme?: Theme;
    /**
     * 
     * @type {Array<string>}
     * @memberof Merchant
     */
    merchant_subjects?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Merchant
     */
    seller_subjects?: Array<string>;
    /**
     * 
     * @type {MerchantReportsPermissions}
     * @memberof Merchant
     */
    reports_permissions?: MerchantReportsPermissions;
    /**
     * 
     * @type {MerchantTablesPermissions}
     * @memberof Merchant
     */
    tables_permissions?: MerchantTablesPermissions;
    /**
     * 
     * @type {boolean}
     * @memberof Merchant
     */
    three_ds_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Merchant
     */
    min_three_ds?: number;
    /**
     * 
     * @type {number}
     * @memberof Merchant
     */
    three_ds_value?: number;
    /**
     * 
     * @type {number}
     * @memberof Merchant
     */
    risk_value?: number;
    /**
     * 
     * @type {number}
     * @memberof Merchant
     */
    celcoin_value?: number;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    celcoin_fee_type?: MerchantCelcoinFeeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Merchant
     */
    is_simulation_pos?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    type?: MerchantTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum MerchantCelcoinFeeTypeEnum {
    Percentage = 'percentage',
    Real = 'real'
}/**
* @export
* @enum {string}
*/
export enum MerchantTypeEnum {
    Base = 'base',
    Central = 'central',
    Others = 'others'
}

export function MerchantFromJSON(json: any): Merchant {
    return MerchantFromJSONTyped(json, false);
}

export function MerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Merchant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'document': json['document'],
        'name': json['name'],
        'code': json['code'],
        'description': json['description'],
        'is_active': !exists(json, 'is_active') ? undefined : json['is_active'],
        'payment_by_link_url': !exists(json, 'payment_by_link_url') ? undefined : json['payment_by_link_url'],
        'payment_url': !exists(json, 'payment_url') ? undefined : json['payment_url'],
        'accounting_account_spread_id': !exists(json, 'accounting_account_spread_id') ? undefined : json['accounting_account_spread_id'],
        'financial_emails': !exists(json, 'financial_emails') ? undefined : json['financial_emails'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
        'risk_active': !exists(json, 'risk_active') ? undefined : json['risk_active'],
        'min_risk': !exists(json, 'min_risk') ? undefined : json['min_risk'],
        'merchant_api_key': !exists(json, 'merchant_api_key') ? undefined : json['merchant_api_key'],
        'merchant_account': !exists(json, 'merchant_account') ? undefined : json['merchant_account'],
        'discount_bank_account_id': json['discount_bank_account_id'],
        'discount_bank_account': !exists(json, 'discount_bank_account') ? undefined : DiscountBankAccountFromJSON(json['discount_bank_account']),
        'shipping_discount_bank_account_id': json['shipping_discount_bank_account_id'],
        'shipping_discount_bank_account': !exists(json, 'shipping_discount_bank_account') ? undefined : DiscountBankAccountFromJSON(json['shipping_discount_bank_account']),
        'logo': !exists(json, 'logo') ? undefined : LogoFromJSON(json['logo']),
        'theme': !exists(json, 'theme') ? undefined : ThemeFromJSON(json['theme']),
        'merchant_subjects': !exists(json, 'merchant_subjects') ? undefined : json['merchant_subjects'],
        'seller_subjects': !exists(json, 'seller_subjects') ? undefined : json['seller_subjects'],
        'reports_permissions': !exists(json, 'reports_permissions') ? undefined : MerchantReportsPermissionsFromJSON(json['reports_permissions']),
        'tables_permissions': !exists(json, 'tables_permissions') ? undefined : MerchantTablesPermissionsFromJSON(json['tables_permissions']),
        'three_ds_active': !exists(json, 'three_ds_active') ? undefined : json['three_ds_active'],
        'min_three_ds': !exists(json, 'min_three_ds') ? undefined : json['min_three_ds'],
        'three_ds_value': !exists(json, 'three_ds_value') ? undefined : json['three_ds_value'],
        'risk_value': !exists(json, 'risk_value') ? undefined : json['risk_value'],
        'celcoin_value': !exists(json, 'celcoin_value') ? undefined : json['celcoin_value'],
        'celcoin_fee_type': !exists(json, 'celcoin_fee_type') ? undefined : json['celcoin_fee_type'],
        'is_simulation_pos': !exists(json, 'is_simulation_pos') ? undefined : json['is_simulation_pos'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MerchantToJSON(value?: Merchant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'document': value.document,
        'name': value.name,
        'code': value.code,
        'description': value.description,
        'is_active': value.is_active,
        'payment_by_link_url': value.payment_by_link_url,
        'payment_url': value.payment_url,
        'accounting_account_spread_id': value.accounting_account_spread_id,
        'financial_emails': value.financial_emails,
        'pre_capture': value.pre_capture,
        'risk_active': value.risk_active,
        'min_risk': value.min_risk,
        'merchant_api_key': value.merchant_api_key,
        'merchant_account': value.merchant_account,
        'discount_bank_account_id': value.discount_bank_account_id,
        'discount_bank_account': DiscountBankAccountToJSON(value.discount_bank_account),
        'shipping_discount_bank_account_id': value.shipping_discount_bank_account_id,
        'shipping_discount_bank_account': DiscountBankAccountToJSON(value.shipping_discount_bank_account),
        'logo': LogoToJSON(value.logo),
        'theme': ThemeToJSON(value.theme),
        'merchant_subjects': value.merchant_subjects,
        'seller_subjects': value.seller_subjects,
        'reports_permissions': MerchantReportsPermissionsToJSON(value.reports_permissions),
        'tables_permissions': MerchantTablesPermissionsToJSON(value.tables_permissions),
        'three_ds_active': value.three_ds_active,
        'min_three_ds': value.min_three_ds,
        'three_ds_value': value.three_ds_value,
        'risk_value': value.risk_value,
        'celcoin_value': value.celcoin_value,
        'celcoin_fee_type': value.celcoin_fee_type,
        'is_simulation_pos': value.is_simulation_pos,
        'type': value.type,
    };
}


