/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoFromJSONTyped,
    CreateSplitRulesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentPosRequestDTOV2
 */
export interface PaymentPosRequestDTOV2 {
    /**
     * Id da máquina de pagamento
     * @type {string}
     * @memberof PaymentPosRequestDTOV2
     */
    terminal_id: string;
    /**
     * Valor
     * @type {number}
     * @memberof PaymentPosRequestDTOV2
     */
    amount: number;
    /**
     * É débito
     * @type {boolean}
     * @memberof PaymentPosRequestDTOV2
     */
    is_debit: boolean;
    /**
     * Parcelas
     * @type {number}
     * @memberof PaymentPosRequestDTOV2
     */
    instalments: number;
    /**
     * Identificador de até 10 caracteres alfanumérico
     * @type {string}
     * @memberof PaymentPosRequestDTOV2
     */
    service_id: string;
    /**
     * É pix
     * @type {boolean}
     * @memberof PaymentPosRequestDTOV2
     */
    is_pix: boolean;
    /**
     * Descrição
     * @type {string}
     * @memberof PaymentPosRequestDTOV2
     */
    description?: string;
    /**
     * Documento
     * @type {string}
     * @memberof PaymentPosRequestDTOV2
     */
    document?: string;
    /**
     * Há divisão de pagamento
     * @type {boolean}
     * @memberof PaymentPosRequestDTOV2
     */
    has_split_rules: boolean;
    /**
     * Divisão de pagamento
     * @type {Array<CreateSplitRulesDto>}
     * @memberof PaymentPosRequestDTOV2
     */
    split_rules: Array<CreateSplitRulesDto>;
    /**
     * Há confirmação de pagamento
     * @type {boolean}
     * @memberof PaymentPosRequestDTOV2
     */
    confirmation_required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPosRequestDTOV2
     */
    pre_capture?: boolean;
}

export function PaymentPosRequestDTOV2FromJSON(json: any): PaymentPosRequestDTOV2 {
    return PaymentPosRequestDTOV2FromJSONTyped(json, false);
}

export function PaymentPosRequestDTOV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPosRequestDTOV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'terminal_id': json['terminal_id'],
        'amount': json['amount'],
        'is_debit': json['is_debit'],
        'instalments': json['instalments'],
        'service_id': json['service_id'],
        'is_pix': json['is_pix'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'document': !exists(json, 'document') ? undefined : json['document'],
        'has_split_rules': json['has_split_rules'],
        'split_rules': ((json['split_rules'] as Array<any>).map(CreateSplitRulesDtoFromJSON)),
        'confirmation_required': !exists(json, 'confirmation_required') ? undefined : json['confirmation_required'],
        'pre_capture': !exists(json, 'pre_capture') ? undefined : json['pre_capture'],
    };
}

export function PaymentPosRequestDTOV2ToJSON(value?: PaymentPosRequestDTOV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'terminal_id': value.terminal_id,
        'amount': value.amount,
        'is_debit': value.is_debit,
        'instalments': value.instalments,
        'service_id': value.service_id,
        'is_pix': value.is_pix,
        'description': value.description,
        'document': value.document,
        'has_split_rules': value.has_split_rules,
        'split_rules': ((value.split_rules as Array<any>).map(CreateSplitRulesDtoToJSON)),
        'confirmation_required': value.confirmation_required,
        'pre_capture': value.pre_capture,
    };
}


