import React from 'react';
import { Box, Flex, Stack, Divider, VStack } from '@chakra-ui/react';
import { maskCpfOrCnpj } from '../../../services/masks.service';
import { GetSellerDto } from '../../../clients';
import Text from 'components/Text';

const SellerInfo: (data: Partial<GetSellerDto>) => JSX.Element = (data: Partial<GetSellerDto>) => {
	return (
		<Stack fontSize='md' color='primary' justifyContent='space-around'>
			<Flex justifyContent='space-around' mt={5}>
				<Stack bg='white' w='100%' spacing={3} p={5} px={8} pr={12} borderColor='gray.300' rounded='md' boxShadow='md'>
					<Text id='favored-text' fontSize='xl' fontWeight='bold'>
						Favorecido
					</Text>

					<Divider orientation='horizontal' mb={3} />

					<VStack alignItems='normal'>
						<Stack direction={['column', 'column', 'row']} spacing={['3', '3', '5', '10']} justifyContent='space-between'>
							<Box>
								<Text id='name-text' fontWeight='bold'>Nome</Text>
								<Text id='data-name-text'>{data?.name}</Text>
							</Box>

							<Box>
								<Text id='description-text' fontWeight='bold'>Descrição</Text>
								<Text id='data-description-text'>{data?.description}</Text>
							</Box>

							<Box>
								<Text id='cpf-cnpj-text' fontWeight='bold'>CPF/CNPJ</Text>
								<Text id='data-document-text'>{maskCpfOrCnpj(data.document)}</Text>
							</Box>

							<Box>
								<Text id='bank-text' fontWeight='bold'>Banco</Text>
								<Text id='data-bank-accoun-code-text'>{data?.bank_account?.bank_code}</Text>
							</Box>

							<Box>
								<Text id='agency-text' fontWeight='bold'>Agência</Text>
								<Text id='data-agency-text-text'>{data?.bank_account?.agency_number}</Text>
							</Box>

							<Box>
								<Text id='account-text' fontWeight='bold'>Conta</Text>
								<Text id='data-bank-account-number-text'>{data?.bank_account?.account_number}</Text>
							</Box>

							<Box>
								<Text id='pix-key-text' fontWeight='bold'>Chave PIX</Text>
								<Text id='data-pix-key-text'>{data?.bank_account?.pix_key ? data.bank_account.pix_key : 'N/A'}</Text>
							</Box>
						</Stack>
					</VStack>
				</Stack>
			</Flex>
		</Stack>
	);
};

export default SellerInfo;
