/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePosPrefixDto
 */
export interface UpdatePosPrefixDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePosPrefixDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePosPrefixDto
     */
    prefix: string;
}

export function UpdatePosPrefixDtoFromJSON(json: any): UpdatePosPrefixDto {
    return UpdatePosPrefixDtoFromJSONTyped(json, false);
}

export function UpdatePosPrefixDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePosPrefixDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'prefix': json['prefix'],
    };
}

export function UpdatePosPrefixDtoToJSON(value?: UpdatePosPrefixDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'prefix': value.prefix,
    };
}


