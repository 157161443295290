/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Permission,
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateRoleDto
 */
export interface UpdateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    type?: UpdateRoleDtoTypeEnum;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof UpdateRoleDto
     */
    permissions?: Array<Permission>;
}

/**
* @export
* @enum {string}
*/
export enum UpdateRoleDtoTypeEnum {
    Merchant = 'merchant',
    Seller = 'seller',
    Backoffice = 'backoffice'
}

export function UpdateRoleDtoFromJSON(json: any): UpdateRoleDto {
    return UpdateRoleDtoFromJSONTyped(json, false);
}

export function UpdateRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
    };
}

export function UpdateRoleDtoToJSON(value?: UpdateRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionToJSON)),
    };
}


