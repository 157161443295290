import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IconStatus } from './CalendarComponent';
import Title from 'components/Title';
import { useTheme } from 'contexts/ThemeProvider';

type Props = {
	totalMonth: string;
};

const Legend: React.FC<Props> = ({ totalMonth }): JSX.Element => {
	const { theme } = useTheme();
	return (
		<Flex direction={['column', 'column', 'column', 'row']} justifyContent='space-between' marginTop='4'>
			<Flex fontWeight='semibold' justifyContent={['center', 'center', 'flex-start']} flexWrap={['wrap', 'wrap', 'nowrap']}>
				<Box marginRight='8' mt={['2', '2', '0']}>
					<Flex alignItems='center'>
						{IconStatus.paid}
						<Title id='pay-title-text' fontSize='md'>
							Pago
						</Title>
					</Flex>
				</Box>

				<Box marginRight='8' mt={['2', '2', '0']}>
					<Flex alignItems='center'>
						{IconStatus.sent}
						<Title id='send-title-text' fontSize='md'>
							Enviado
						</Title>
					</Flex>
				</Box>

				<Box marginRight='8' mt={['2', '2', '0']}>
					<Flex alignItems='center'>
						{IconStatus.pending}
						<Title id='pending-title-text' fontSize='md'>
							Pendente
						</Title>
					</Flex>
				</Box>

				<Box marginRight='8' mt={['2', '2', '0']}>
					<Flex alignItems='center'>
						{IconStatus.canceled}
						<Title id='canceled-title-text' fontSize='md'>
							Cancelado
						</Title>
					</Flex>
				</Box>

				<Box marginRight='8' mt={['2', '2', '0']}>
					<Flex alignItems='center'>
						{IconStatus.failed}
						<Title id='failed-title-text' fontSize='md'>
							Falhado
						</Title>
					</Flex>
				</Box>
			</Flex>

			<Flex
				px='1.25rem'
				py='0.75rem'
				borderWidth={2}
				borderColor={theme?.primary_color || 'gray.400'}
				borderRadius='md'
				fontSize={['md', 'md', 'lg']}
				fontWeight='bold'
				mt={['3', '3', '3', '0']}
				justifyContent='center'
			>
				<Title id='total-month-title-text' mr='1.25rem'>
					Recebimento no mês:
				</Title>
				<Text id='total-month-text' color='primary'>{totalMonth}</Text>
			</Flex>
		</Flex>
	);
};

export default Legend;
