/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSaleSummaryChartDto
 */
export interface GetSaleSummaryChartDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSaleSummaryChartDto
     */
    sales_progression: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSaleSummaryChartDto
     */
    months_progression: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryChartDto
     */
    average_ticket: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryChartDto
     */
    total_sales: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryChartDto
     */
    total_payers: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryChartDto
     */
    financial_success_sale: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryChartDto
     */
    financial_canceled_sale: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryChartDto
     */
    financial_pending_sale: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSaleSummaryChartDto
     */
    sales_day_progression: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSaleSummaryChartDto
     */
    day_progression: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSaleSummaryChartDto
     */
    ticket_day_progression: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSaleSummaryChartDto
     */
    ticket_month_progression: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSaleSummaryChartDto
     */
    sales_total_day_progression: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSaleSummaryChartDto
     */
    sales_total_month_progression: Array<number>;
}

export function GetSaleSummaryChartDtoFromJSON(json: any): GetSaleSummaryChartDto {
    return GetSaleSummaryChartDtoFromJSONTyped(json, false);
}

export function GetSaleSummaryChartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSaleSummaryChartDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sales_progression': json['sales_progression'],
        'months_progression': json['months_progression'],
        'average_ticket': json['average_ticket'],
        'total_sales': json['total_sales'],
        'total_payers': json['total_payers'],
        'financial_success_sale': json['financial_success_sale'],
        'financial_canceled_sale': json['financial_canceled_sale'],
        'financial_pending_sale': json['financial_pending_sale'],
        'sales_day_progression': json['sales_day_progression'],
        'day_progression': json['day_progression'],
        'ticket_day_progression': json['ticket_day_progression'],
        'ticket_month_progression': json['ticket_month_progression'],
        'sales_total_day_progression': json['sales_total_day_progression'],
        'sales_total_month_progression': json['sales_total_month_progression'],
    };
}

export function GetSaleSummaryChartDtoToJSON(value?: GetSaleSummaryChartDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sales_progression': value.sales_progression,
        'months_progression': value.months_progression,
        'average_ticket': value.average_ticket,
        'total_sales': value.total_sales,
        'total_payers': value.total_payers,
        'financial_success_sale': value.financial_success_sale,
        'financial_canceled_sale': value.financial_canceled_sale,
        'financial_pending_sale': value.financial_pending_sale,
        'sales_day_progression': value.sales_day_progression,
        'day_progression': value.day_progression,
        'ticket_day_progression': value.ticket_day_progression,
        'ticket_month_progression': value.ticket_month_progression,
        'sales_total_day_progression': value.sales_total_day_progression,
        'sales_total_month_progression': value.sales_total_month_progression,
    };
}


