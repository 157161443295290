/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmountDTO,
    AmountDTOFromJSON,
    AmountDTOFromJSONTyped,
    AmountDTOToJSON,
    PaymentsSplitRulesDto,
    PaymentsSplitRulesDtoFromJSON,
    PaymentsSplitRulesDtoFromJSONTyped,
    PaymentsSplitRulesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePaymentResponseDTO
 */
export interface CreatePaymentResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentResponseDTO
     */
    pspReference?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentResponseDTO
     */
    resultCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentResponseDTO
     */
    merchantReference?: string;
    /**
     * 
     * @type {AmountDTO}
     * @memberof CreatePaymentResponseDTO
     */
    amount?: AmountDTO;
    /**
     * 
     * @type {object}
     * @memberof CreatePaymentResponseDTO
     */
    action?: object;
    /**
     * 
     * @type {PaymentsSplitRulesDto}
     * @memberof CreatePaymentResponseDTO
     */
    splitRules?: PaymentsSplitRulesDto;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePaymentResponseDTO
     */
    hasSplitRules?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentResponseDTO
     */
    sale_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentResponseDTO
     */
    session_id?: string;
}

export function CreatePaymentResponseDTOFromJSON(json: any): CreatePaymentResponseDTO {
    return CreatePaymentResponseDTOFromJSONTyped(json, false);
}

export function CreatePaymentResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pspReference': !exists(json, 'pspReference') ? undefined : json['pspReference'],
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'merchantReference': !exists(json, 'merchantReference') ? undefined : json['merchantReference'],
        'amount': !exists(json, 'amount') ? undefined : AmountDTOFromJSON(json['amount']),
        'action': !exists(json, 'action') ? undefined : json['action'],
        'splitRules': !exists(json, 'splitRules') ? undefined : PaymentsSplitRulesDtoFromJSON(json['splitRules']),
        'hasSplitRules': !exists(json, 'hasSplitRules') ? undefined : json['hasSplitRules'],
        'sale_id': !exists(json, 'sale_id') ? undefined : json['sale_id'],
        'session_id': !exists(json, 'session_id') ? undefined : json['session_id'],
    };
}

export function CreatePaymentResponseDTOToJSON(value?: CreatePaymentResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pspReference': value.pspReference,
        'resultCode': value.resultCode,
        'merchantReference': value.merchantReference,
        'amount': AmountDTOToJSON(value.amount),
        'action': value.action,
        'splitRules': PaymentsSplitRulesDtoToJSON(value.splitRules),
        'hasSplitRules': value.hasSplitRules,
        'sale_id': value.sale_id,
        'session_id': value.session_id,
    };
}


