import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react';

import { ListAllMerchantUsersRequest, ListMerchantUsersRequest, MerchantsApi } from 'clients';
import { getApiAuthConfig } from 'services/api.service';
import { useAuth } from 'contexts/AuthProvider';
import { useTheme } from 'contexts/ThemeProvider';

import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';
import Button from 'components/Button';

import Loader from 'containers/Loader';

import UserPermissionModal from './components/UserPermissionModal';
import SampleTable from './components/SampleTable';
import UserModal from './components/UserModal';

const MerchantUsers: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const merchantsApi = new MerchantsApi(apiConfig);
	const { theme } = useTheme();

	const { user, isBackoffice, isMerchant } = useAuth();

	const [isLoading, setIsLoading] = React.useState(true);
	const [resetTable, setResetTable] = React.useState(false);
	const [openUserModal, setOpenUserModal] = React.useState(false);
	const [userID, setUserID] = React.useState('');
	const [selectedUser, setSelectedUser] = React.useState(undefined);

	const [users, setUsers] = React.useState<Array<string>>([]);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [openUserPermissionModal, setOpenUserPermissionModal] = React.useState(false);
	const [userRole, setUserRole] = React.useState(undefined);

	const { addToast } = useToasts();

	async function fetchListMerchantUsersRequest() {
		setIsLoading(true);

		if (isMerchant && user.merchant) {
			try {
				const requestParams: ListMerchantUsersRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					merchantId: user.merchant.id,
				};

				const response = await merchantsApi.listMerchantUsers(requestParams);

				setUsers(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: ListAllMerchantUsersRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
				};

				const response = await merchantsApi.listAllMerchantUsers(requestParams);

				setUsers(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleOpenUserModal = (user?) => {
		if (user) {
			setUserID(user.id);
			setSelectedUser(user);
			setOpenUserModal(!openUserModal);
			return;
		}

		setUserID('');
		setSelectedUser(undefined);
		setOpenUserModal(!openUserModal);
	};

	const handleOpenUserPermissionModal = (role) => {
		setUserRole(role);
		setOpenUserPermissionModal(!openUserPermissionModal);
	};

	React.useEffect(() => {
		fetchListMerchantUsersRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, resetTable]);

	return (
		<>
			<Loader isOpen={isLoading} />
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Minha Conta
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' fontSize='md' fontWeight={`extrabold`}>
							Usuários Merchant
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<Flex mb='4' flexDirection='row' justify='space-between'>
				<Title id='merchant-user-title-text' fontSize={`2xl`}>USUÁRIOS MERCHANT</Title>
				<Button
					id='open-user-modal-button'
					bgColor='transparent'
					borderWidth={1}
					borderColor={theme?.primary_color || 'black'}
					color={theme?.primary_color || 'black'}
					rounded='md'
					onClick={() => {
						handleOpenUserModal();
					}}
				>
					Inserir novo usuário
				</Button>
			</Flex>

			<Flex flexDir={`column`} p={[4, 4, 10]} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
				{users && (
					<SampleTable
						currentUser={user}
						showMerchantColumn={isBackoffice}
						data={users}
						setPagination={setPagination}
						pagination={pagination}
						totalPages={totalPages}
						handleOpenUserModal={handleOpenUserModal}
						handleOpenUserPermissionModal={handleOpenUserPermissionModal}
					/>
				)}
			</Flex>
			<UserPermissionModal
				openUserPermissionModal={openUserPermissionModal}
				handleOpenUserPermissionModal={handleOpenUserPermissionModal}
				userRole={userRole}
			/>
			<UserModal
				showMerchantInput={isBackoffice}
				merchant={isMerchant ? user : null}
				openUserModal={openUserModal}
				handleOpenUserModal={handleOpenUserModal}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				resetTable={resetTable}
				setResetTable={setResetTable}
				userID={userID}
				selectedUser={selectedUser}
			/>
		</>
	);
};

export default MerchantUsers;
