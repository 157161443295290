/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateExportMonthlyAlertsListDto,
    CreateExportMonthlyAlertsListDtoFromJSON,
    CreateExportMonthlyAlertsListDtoToJSON,
    GetFileExportRequestDto,
    GetFileExportRequestDtoFromJSON,
    GetFileExportRequestDtoToJSON,
    GetTransactionAlertResponseDto,
    GetTransactionAlertResponseDtoFromJSON,
    GetTransactionAlertResponseDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface CreateMonthlyAlertsListExportRequest {
    createExportMonthlyAlertsListDto: CreateExportMonthlyAlertsListDto;
}

export interface GetTransactionAlertRequest {
    alertId: string;
}

export interface ListTransactionsAlertsRequest {
    limit: number;
    currentPage: number;
    startDate?: string;
    endDate?: string;
    sellerId?: string;
}

/**
 * 
 */
export class MonthlyTransactionAlertsApi extends runtime.BaseAPI {

    /**
     * Gerar uma requisição de exportação da lista de alertas mensais
     */
    async createMonthlyAlertsListExportRaw(requestParameters: CreateMonthlyAlertsListExportRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportMonthlyAlertsListDto === null || requestParameters.createExportMonthlyAlertsListDto === undefined) {
            throw new runtime.RequiredError('createExportMonthlyAlertsListDto','Required parameter requestParameters.createExportMonthlyAlertsListDto was null or undefined when calling createMonthlyAlertsListExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transactions-alerts/list/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportMonthlyAlertsListDtoToJSON(requestParameters.createExportMonthlyAlertsListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação da lista de alertas mensais
     */
    async createMonthlyAlertsListExport(requestParameters: CreateMonthlyAlertsListExportRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createMonthlyAlertsListExportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Buscar um alerta mensal
     */
    async getTransactionAlertRaw(requestParameters: GetTransactionAlertRequest): Promise<runtime.ApiResponse<GetTransactionAlertResponseDto>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling getTransactionAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transactions-alerts/monthly-alerts/{alertId}`.replace(`{${"alertId"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTransactionAlertResponseDtoFromJSON(jsonValue));
    }

    /**
     * Buscar um alerta mensal
     */
    async getTransactionAlert(requestParameters: GetTransactionAlertRequest): Promise<GetTransactionAlertResponseDto> {
        const response = await this.getTransactionAlertRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar alertas mensais paginado
     */
    async listTransactionsAlertsRaw(requestParameters: ListTransactionsAlertsRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listTransactionsAlerts.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listTransactionsAlerts.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['seller_id'] = requestParameters.sellerId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/transactions-alerts/monthly-alerts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar alertas mensais paginado
     */
    async listTransactionsAlerts(requestParameters: ListTransactionsAlertsRequest): Promise<PaginatedDto> {
        const response = await this.listTransactionsAlertsRaw(requestParameters);
        return await response.value();
    }

}
