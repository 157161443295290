import React from 'react';

import { HStack, Link, useTheme } from '@chakra-ui/react';
import { AiFillCloseCircle, AiFillClockCircle, AiOutlineDownload } from 'react-icons/ai';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';
import { formatToBrazilianTimezone, DateFormatEnum } from '../../../services/masks.service';

import { GetFileExportRequestDtoStatusEnum } from '../../../clients';
import Text from '../../../components/Text';

const iconStatusLiteral = {
	finished: <AiOutlineDownload color='blue.700' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
};

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages, showSellerColumn }) => {
	const { sizes } = useTheme();

	const reports = data?.results?.map((report) => {
		return {
			id: report?.id,
			description: report?.description,
			user: report?.user?.name,
			status: report?.status,
			formatted_status: report?.formatted_status,
			type: report?.type,
			formatted_type: report?.formatted_type,
			start_date: report?.start_date,
			end_date: report?.end_date,
			url: report?.url,
			created_at: formatToBrazilianTimezone(report?.created_at, DateFormatEnum.DayMonthYearTime),
			seller_name: report?.seller?.name || 'Todos os Estabelecimentos',
		};
	});

	const columns = React.useMemo(
		() => {
			let cols: any = [];

			if (showSellerColumn) {
				cols.push({
					Header: ({ column }) => (
						<HeaderTitleStyled id='seller-name-title' column={column}>
							Estabelecimento
						</HeaderTitleStyled>
					),
					accessor: 'seller_name',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				});
			}
			return cols.concat([
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='description-title' column={column}>
							Período Analisado
						</HeaderTitleStyled>
					),
					accessor: 'description',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='created-at-title' column={column}>
							Data de Criação
						</HeaderTitleStyled>
					),
					accessor: 'created_at',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='user-title-text' column={column}>
							Gerado por
						</HeaderTitleStyled>
					),
					accessor: 'user',
					Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled id='status-title' column={column}>
							Status
						</HeaderTitleStyled>
					),
					accessor: 'status',
					Cell: ({ cell: { row, column } }) => (
						<CellContentStyled id={`${row.index}-${column.id}-cell`} onClick={() => {}}>
							<HStack spacing={2}>
								<Text id={`${row.index}-${column.id}-icon-text`}>{iconStatusLiteral[row.original.status]}</Text>
								{row.original.status === GetFileExportRequestDtoStatusEnum.Finished ? (
									<Link id={`${row.index}-${column.id}-link`} href={row.original.url} isExternal fontWeight='bold'>
										Download
									</Link>
								) : (
									<Text id={`${row.index}-${column.id}-text`}>{row.original.formatted_status}</Text>
								)}
							</HStack>
						</CellContentStyled>
					),
				},
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			data={reports}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper={`calc(100vh - ${sizes.headerHeight} - 20rem)`}
		/>
	);
};
