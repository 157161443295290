/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePaymentLinkDto
 */
export interface UpdatePaymentLinkDto {
    /**
     * Data de vencimento
     * @type {boolean}
     * @memberof UpdatePaymentLinkDto
     */
    expiresIn: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentLinkDto
     */
    status?: UpdatePaymentLinkDtoStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdatePaymentLinkDtoStatusEnum {
    Active = 'active',
    PendingCapture = 'pending_capture',
    Expired = 'expired',
    Canceled = 'canceled',
    Paid = 'paid'
}

export function UpdatePaymentLinkDtoFromJSON(json: any): UpdatePaymentLinkDto {
    return UpdatePaymentLinkDtoFromJSONTyped(json, false);
}

export function UpdatePaymentLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePaymentLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiresIn': json['expiresIn'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function UpdatePaymentLinkDtoToJSON(value?: UpdatePaymentLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expiresIn': value.expiresIn,
        'status': value.status,
    };
}


