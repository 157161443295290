/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSaleSummaryTypeDto
 */
export interface GetSaleSummaryTypeDto {
    /**
     * 
     * @type {string}
     * @memberof GetSaleSummaryTypeDto
     */
    type_sale: string;
    /**
     * 
     * @type {string}
     * @memberof GetSaleSummaryTypeDto
     */
    type_name: string;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryTypeDto
     */
    financial_volume: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryTypeDto
     */
    gross_volume: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryTypeDto
     */
    number_sales: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryTypeDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof GetSaleSummaryTypeDto
     */
    percentage: number;
}

export function GetSaleSummaryTypeDtoFromJSON(json: any): GetSaleSummaryTypeDto {
    return GetSaleSummaryTypeDtoFromJSONTyped(json, false);
}

export function GetSaleSummaryTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSaleSummaryTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type_sale': json['type_sale'],
        'type_name': json['type_name'],
        'financial_volume': json['financial_volume'],
        'gross_volume': json['gross_volume'],
        'number_sales': json['number_sales'],
        'average': json['average'],
        'percentage': json['percentage'],
    };
}

export function GetSaleSummaryTypeDtoToJSON(value?: GetSaleSummaryTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type_sale': value.type_sale,
        'type_name': value.type_name,
        'financial_volume': value.financial_volume,
        'gross_volume': value.gross_volume,
        'number_sales': value.number_sales,
        'average': value.average,
        'percentage': value.percentage,
    };
}


