import { Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { FiFilePlus } from 'react-icons/fi';
import { GetSellerDto } from '../../../clients';
import Button from '../../../components/Button';
import HeaderModal from './HeaderModal';

type PaperRollOrderHeaderButtonProps = {
	seller: GetSellerDto;
};

const PaperRollOrderHeaderButton: React.FC<PaperRollOrderHeaderButtonProps> = ({ seller }) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			{!isMobile ? (
				<Button id='order-paper-roll-button' rounded='md' bgColor='primary' color='white' _hover={{ opacity: 0.7, cursor: 'pointer' }} mr='6' onClick={onOpen}>
					Solicitar Bobina
				</Button>
			) : (
				<Text id='order-paper-roll-text' fontWeight='normal' fontSize='md' _hover={{ opacity: 0.7, cursor: 'pointer' }} color='primary'>
					<FiFilePlus onClick={onOpen} />
				</Text>
			)}
			<HeaderModal isOpen={isOpen} onClose={onClose} seller={seller} />
		</>
	);
};

export default PaperRollOrderHeaderButton;
