import yup from 'services/yup.service';

const createCostPlanSchema = yup.object().shape({
	name: yup.string().required('Campo obrigatório'),
	description: yup.string().required('Campo obrigatório'),
	options: yup.array().of(
		yup.object().shape({
			fixed_amount: yup.number('Campo Numérico').required('Campo obrigatório').typeError('Campo Numérico'),
			bank_fixed_amount: yup.number('Campo Numérico'),
			description: yup.string().required('Campo obrigatório'),
			type: yup.string().required('Campo obrigatório'),
			type_status: yup.string(),
			brand: yup.string(),
			brand_fee_type: yup.string(),
			installments: yup.number('Campo Numérico').typeError('Campo Numérico'),
			exchange_fee_type: yup.string(),
			exchange_fee: yup.number('Campo Numérico').typeError('Campo Numérico'),
			brand_fee: yup.number('Campo Numérico').typeError('Campo Numérico'),
			anticipation_fee_type: yup.string(),
			anticipation_fee: yup.number('Campo Numérico').typeError('Campo Numérico'),
			markup_fee_type: yup.string(),
			markup_fee: yup.number('Campo Numérico').typeError('Campo Numérico'),
			is_cap: yup.string(),
			exchange_cap: yup.number('Campo Numérico').typeError('Campo Numérico'),
		})
	),
});

export default createCostPlanSchema;
