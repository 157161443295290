import React from 'react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';

import { Text, Icon, Button, Flex, Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data }) => {
	const columns = React.useMemo(() => {
		const history = useHistory();

		let cols: any = [];

		const goToEditPage = (merchantId: string) => {
			history.push(`/admin/merchants/${merchantId}`);
		};

		return cols.concat([
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='id-title' column={column}>
						ID
					</HeaderTitleStyled>
				),
				accessor: 'id',
				Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='edit-merchant-button-title' column={column}>
						Editar
					</HeaderTitle>
				),
				accessor: 'edit-merchant-button',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>
						<Flex>
							<Tooltip label='Editar Merchant'>
								<Button
									id={`${row.index}-${column.id}-button`}
									background={'transparent'}
									onClick={() => {
										goToEditPage(row.values.id);
									}}
								>
									<Icon id={`${row.index}-${column.id}-icon`} as={FiEdit} boxSize={5} />
								</Button>
							</Tooltip>
						</Flex>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='is-active-title' column={column}>
						Ativo
					</HeaderTitleStyled>
				),
				accessor: 'is_active',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContentStyled id={`${row.index}-${column.id}-cell`}>
						<Text id={`${row.index}-${column.id}-text`}>{value ? 'Sim' : 'Não'}</Text>
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='name-title' column={column}>
						Nome
					</HeaderTitleStyled>
				),
				accessor: 'name',
				Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='description-title' column={column}>
						Descrição
					</HeaderTitleStyled>
				),
				accessor: 'description',
				Cell: ({ cell: { value, row , column}  }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
			},
		]);
	}, []);

	return <SimpleTable columns={columns} data={data} pl={2} variant='unstyled' maxHeightTableWrapper='42vh' />;
};
