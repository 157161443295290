import React from 'react';

import { Select as DefaultSelect, SelectProps } from '@chakra-ui/react';

type DefaultSelectProps = SelectProps & {
	id: string;
};

const Select: React.FC<DefaultSelectProps> = (props: DefaultSelectProps) => {
	return (
		<DefaultSelect size={`sm`} rounded={`sm`} fontSize={`sm`} bg={`#F5F5F5`} {...props}>
			{props.children}
		</DefaultSelect>
	);
};

export default Select;
