/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSplitRulesSimulationDto,
    GetSplitRulesSimulationDtoFromJSON,
    GetSplitRulesSimulationDtoFromJSONTyped,
    GetSplitRulesSimulationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSimulationPayloadDto
 */
export interface GetSimulationPayloadDto {
    /**
     * 
     * @type {number}
     * @memberof GetSimulationPayloadDto
     */
    amount_cents: number;
    /**
     * 
     * @type {Array<GetSplitRulesSimulationDto>}
     * @memberof GetSimulationPayloadDto
     */
    splitRules?: Array<GetSplitRulesSimulationDto>;
}

export function GetSimulationPayloadDtoFromJSON(json: any): GetSimulationPayloadDto {
    return GetSimulationPayloadDtoFromJSONTyped(json, false);
}

export function GetSimulationPayloadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSimulationPayloadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount_cents': json['amount_cents'],
        'splitRules': !exists(json, 'splitRules') ? undefined : ((json['splitRules'] as Array<any>).map(GetSplitRulesSimulationDtoFromJSON)),
    };
}

export function GetSimulationPayloadDtoToJSON(value?: GetSimulationPayloadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_cents': value.amount_cents,
        'splitRules': value.splitRules === undefined ? undefined : ((value.splitRules as Array<any>).map(GetSplitRulesSimulationDtoToJSON)),
    };
}


